import { CONSTANTS } from '@epilogue/common'

export default [
  {
    value: CONSTANTS.relationshipValues.MY_PARTNER,
    label: 'Partner/Spouse',
    tKey: 'common:relationship.partnerSpouse',
  },
  {
    value: CONSTANTS.relationshipValues.MY_MOTHER,
    label: 'Mother',
    tKey: 'common:relationship.mother',
  },
  {
    value: CONSTANTS.relationshipValues.MY_FATHER,
    label: 'Father',
    tKey: 'common:relationship.father',
  },
  {
    value: CONSTANTS.relationshipValues.MY_SISTER,
    label: 'Sister',
    tKey: 'common:relationship.sister',
  },
  {
    value: CONSTANTS.relationshipValues.MY_BROTHER,
    label: 'Brother',
    tKey: 'common:relationship.brother',
  },
  {
    value: CONSTANTS.relationshipValues.MY_DAUGHTER,
    label: 'Daughter',
    tKey: 'common:relationship.daughter',
  },
  {
    value: CONSTANTS.relationshipValues.MY_SON,
    label: 'Son',
    tKey: 'common:relationship.son',
  },
  {
    value: CONSTANTS.relationshipValues.MY_STEPCHILD,
    label: 'Stepchild',
    tKey: 'common:relationship.stepchild',
  },
  {
    value: CONSTANTS.relationshipValues.MY_SISTER_IN_LAW,
    label: 'Sister-in-Law',
    tKey: 'common:relationship.sisterInLaw',
  },
  {
    value: CONSTANTS.relationshipValues.MY_BROTHER_IN_LAW,
    label: 'Brother-in-Law',
    tKey: 'common:relationship.brotherInLaw',
  },
  {
    value: CONSTANTS.relationshipValues.MY_MOTHER_IN_LAW,
    label: 'Mother-in-Law',
    tKey: 'common:relationship.motherInLaw',
  },
  {
    value: CONSTANTS.relationshipValues.MY_FATHER_IN_LAW,
    label: 'Father-in-Law',
    tKey: 'common:relationship.fatherInLaw',
  },
  {
    value: CONSTANTS.relationshipValues.MY_DAUGHTER_IN_LAW,
    label: 'Daughter-in-Law',
    tKey: 'common:relationship.daughterInLaw',
  },
  {
    value: CONSTANTS.relationshipValues.MY_SON_IN_LAW,
    label: 'Son-in-Law',
    tKey: 'common:relationship.sonInLaw',
  },
  {
    value: CONSTANTS.relationshipValues.MY_GRANDCHILD,
    label: 'Grandchild',
    tKey: 'common:relationship.grandchild',
  },
  {
    value: CONSTANTS.relationshipValues.MY_FRIEND,
    label: 'Friend',
    tKey: 'common:relationship.friend',
  },
  {
    value: CONSTANTS.relationshipValues.MY_AUNT,
    label: 'Aunt',
    tKey: 'common:relationship.aunt',
  },
  {
    value: CONSTANTS.relationshipValues.MY_UNCLE,
    label: 'Uncle',
    tKey: 'common:relationship.uncle',
  },
  {
    value: CONSTANTS.relationshipValues.MY_NIECE,
    label: 'Niece',
    tKey: 'common:relationship.niece',
  },
  {
    value: CONSTANTS.relationshipValues.MY_NEPHEW,
    label: 'Nephew',
    tKey: 'common:relationship.nephew',
  },
  {
    value: CONSTANTS.relationshipValues.MY_COUSIN,
    label: 'Cousin',
    tKey: 'common:relationship.cousin',
  },
  {
    value: CONSTANTS.relationshipValues.NONE,
    label: 'None of the above',
    tKey: 'common:relationship.noneOfTheAbove',
  },
]
