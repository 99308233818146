import React, { useState } from 'react'
import PropTypes from 'prop-types'

import _cloneDeep from 'lodash/cloneDeep'
import _flatten from 'lodash/flatten'
import _isEmpty from 'lodash/isEmpty'
import _partition from 'lodash/partition'
import _toString from 'lodash/toString'

import cn from 'classnames'
import { RadioButton, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import useResponsive, {
  breakpoints,
} from '../../../../../common/hooks/useResponsive'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'
import formatField from './utils/formatField'

const Predeceased = ({ handleFieldFragmentUpdate, answerCache, question }) => {
  const [field] = question.fields

  const [beneficiaries, setBeneficiaries] = useState(
    answerCache.remoteDistribution || [],
  )

  const charityBeneficiaries = answerCache.remoteDistribution.filter(
    (b) => b.type === 'charity',
  )

  // splits people and charities, then flattens (combines) into one array
  const sortedBeneficiaries = _flatten(
    _partition(beneficiaries, (b) => b.type === 'person'),
  )

  const handleUpdate = (id, value) => {
    const clonedBeneficiaries = _cloneDeep(beneficiaries)

    const humanBeneficiaries = clonedBeneficiaries
      .filter((b) => b.type === 'person')
      .map((b) => {
        if (b.id === id) {
          return { ...b, predeceasedSplit: value }
        }
        return b
      })

    setBeneficiaries([...humanBeneficiaries, ...charityBeneficiaries])
    handleFieldFragmentUpdate({
      remoteDistribution: [...humanBeneficiaries, ...charityBeneficiaries],
      [field.name]: formatField(humanBeneficiaries),
    })
  }

  const [viewportWidth] = useResponsive()

  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <div className="flex justify-center mb-3">
        <ValidationMessage question={question} />
      </div>

      {!_isEmpty(beneficiaries) &&
        sortedBeneficiaries.map((b) => {
          const isCharity = b.type === 'charity'

          return (
            <div
              key={b.id}
              className={cn(isCharity ? 'mb-6' : 'mb-10', 'flex flex-col')}
            >
              <div className="flex items-center mb-3">
                <Text weight="bold">{b.name}</Text>
                <div className="ml-2">
                  <Text size="small">{b.percentage}%</Text>
                </div>
              </div>

              <div>
                {!isCharity && (
                  <>
                    <div className="mb-3">
                      <RadioButton
                        name={b.name}
                        checked={b.predeceasedSplit === 'splitChildren'}
                        id={_toString(`${b.id}-splitChildren`)}
                        label={
                          <Text
                            size={
                              viewportWidth > breakpoints.sm
                                ? 'smedium'
                                : 'small'
                            }
                          >
                            {t(
                              'customQuestion:Predeceased.splitEquallyRadioLabel',
                              { name: b.name },
                            )}
                          </Text>
                        }
                        onChange={(e) =>
                          handleUpdate(
                            b.id,
                            e.target.checked && 'splitChildren',
                          )
                        }
                      />
                    </div>

                    <div>
                      <RadioButton
                        name={b.name}
                        checked={b.predeceasedSplit === 'splitOthers'}
                        id={_toString(`${b.id}-splitOthers`)}
                        label={
                          <Text
                            size={
                              viewportWidth > breakpoints.sm
                                ? 'smedium'
                                : 'small'
                            }
                          >
                            {t(
                              'customQuestion:Predeceased.splitProportionallyRadioLabel',
                            )}
                          </Text>
                        }
                        onChange={(e) =>
                          handleUpdate(b.id, e.target.checked && 'splitOthers')
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

Predeceased.propTypes = {
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  answerCache: PropTypes.shape({
    remoteDistribution: PropTypes.arrayOf(PropTypes.shape({})),
    predeceased: PropTypes.string,
  }).isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default Predeceased
