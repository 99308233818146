import React, { useMemo, useState } from 'react'

import { connect } from 'react-redux'

import CheckIcon from '@mui/icons-material/Check'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Tooltip, useMediaQuery } from '@mui/material'

import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../common/components/atoms/Heading'
import IconButton from '../../../../../../common/components/atoms/IconButton'
import Text from '../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import dashboardSelectors from '../../../../ducks/dashboard/dashboardSelectors'

interface Props {
  customerReferralCode?: string
}

const ReferralLink = ({ customerReferralCode = undefined }: Props) => {
  const { t } = useTranslation()

  const COPY_STATUS_TYPES = useMemo(
    () => ({
      initial: {
        type: 'INITIAL',
        label: t('documents:components.ReferralLink.copyStatusTypes.initial'),
      },
      success: {
        type: 'SUCCESS',
        label: t('documents:components.ReferralLink.copyStatusTypes.success'),
      },
      fail: {
        type: 'FAIL',
        label: t('documents:components.ReferralLink.copyStatusTypes.fail'),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language],
  )

  const [copyStatus, setCopyStatus] = useState(COPY_STATUS_TYPES.initial)

  const hasClipboard = !!navigator?.clipboard

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const referralLink = `https://epiloguewills.com/?ref=customer-referral&reftag=${customerReferralCode}`

  const handleCopyToClipboard = () =>
    navigator.clipboard.writeText(referralLink).then(
      () => {
        // ON SUCCESS
        setCopyStatus(COPY_STATUS_TYPES.success)
        setTimeout(() => setCopyStatus(COPY_STATUS_TYPES.initial), 1400)
        amplitude.sendEvent('CopiedReferralLink', {
          success: true,
        })
      },
      // ON FAIL
      () => {
        amplitude.sendEvent('CopiedReferralLink', {
          success: false,
        })
        setCopyStatus(COPY_STATUS_TYPES.fail)
      },
    )

  return (
    <>
      {customerReferralCode && (
        <Box
          data-testid="referral-link-wrapper"
          sx={{ p: isMobile ? '1rem' : '1.5rem', maxWidth: '44rem' }}
        >
          <Heading align="center" variant="h2">
            {t('documents:components.ReferralLink.label')}
          </Heading>

          <Box sx={{ mt: '1.4rem' }}>
            <Text align="center" variant="paragraph" size="md">
              {t('documents:components.ReferralLink.description1')}
            </Text>

            <Text
              size="md"
              align="center"
              variant="paragraph"
              sx={{ mt: '1rem', mb: '1.8rem' }}
            >
              {t('documents:components.ReferralLink.description2.item1')}{' '}
              <Box component="span" sx={{ textDecoration: 'underline' }}>
                {t('documents:components.ReferralLink.description2.item2')}
              </Box>
              .
            </Text>
          </Box>

          <Box
            sx={{
              mt: '1.35rem',
              cursor: 'pointer',
              pl: isMobile ? '0.8rem' : '1rem',
              pr: isMobile ? '0.1rem' : '0.4rem',
              py: isMobile ? '0.5rem' : '0.3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: `2px dashed ${
                themeColors[themeColorTypes.ACCENT_2][themeColorVariants.MAIN]
              }`,
              backgroundColor:
                themeColors[themeColorTypes.ACCENT_2][
                  themeColorVariants.LIGHTER
                ],
            }}
            data-testid="referral-link-clickable-copy-area"
            onClick={hasClipboard && handleCopyToClipboard}
          >
            <Text
              align={
                copyStatus.label &&
                copyStatus.type === COPY_STATUS_TYPES.success.type
                  ? 'center'
                  : 'left'
              }
              size="md"
              data-testid="referral-link-text"
            >
              {copyStatus.label &&
              copyStatus.type === COPY_STATUS_TYPES.success.type
                ? copyStatus.label
                : referralLink}
            </Text>

            {hasClipboard && (
              <Tooltip
                arrow
                placement="top"
                title={
                  copyStatus.label ||
                  t('documents:components.ReferralLink.copyStatusTypes.initial')
                }
                onClose={() =>
                  setTimeout(
                    () => setCopyStatus(COPY_STATUS_TYPES.initial),
                    190,
                  )
                }
              >
                <IconButton
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  color={themeColorTypes.ACCENT_2}
                  IconComponent={
                    copyStatus.type === COPY_STATUS_TYPES.success.type ? (
                      <CheckIcon />
                    ) : (
                      <ContentCopyIcon />
                    )
                  }
                  data-testid="referral-link-copy-button"
                  colorVariant={themeColorVariants.DARKEST}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  customerReferralCode: dashboardSelectors.getCustomerReferralCode(
    state.dashboard,
  ),
})

export default connect(mapStateToProps)(ReferralLink)
