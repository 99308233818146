import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import cn from 'classnames'
import { format } from 'date-fns'
import { Button, Paragraph } from 'grommet'
import { Download } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import InlineLoader from '../../../../common/components/atoms/InlineLoader'
import Text from '../../../../common/components/atoms/Text'
import commonActions from '../../../../common/ducks/commonActions'
import useDownloadSocialDoc from './hooks/useDownloadSocialDoc'

const SocialDocumentCard = ({
  document,
  answerStore,
  dispatchSetGlobalErrorMessage,
}) => {
  const { t } = useTranslation()

  const [downloadStatusStates, downloadStatus, handleDocDownload] =
    useDownloadSocialDoc({
      answerStore,
      dispatchSetGlobalErrorMessage,
    })

  const { created, _id: docId } = document
  const downloading = downloadStatus === downloadStatusStates.WORKING

  return (
    <div
      data-testid="social-document-card"
      className="bg-white p-5 rounded-lg w-full m-auto flex flex-col"
    >
      <div className="pb-2">
        <Text size="lg" weight="bold">
          Social Media Will
        </Text>
      </div>

      {created && (
        <Box sx={{ mt: '-0.15rem', mb: '0.5rem', letterSpacing: '0.1px' }}>
          <Text color="grey" size="xxs">
            {t('common:lastUpdated')}:{' '}
            {format(new Date(created), 'MMMM d, yyyy')}
          </Text>
        </Box>
      )}

      <div className={cn('w-full pt-1 pb-2', 'sm:w-5/6')}>
        <Paragraph size="smedium">
          Communicates your wishes about how you want your digital legacy
          managed when you are no longer here.
        </Paragraph>
      </div>

      <div
        className={cn(
          'flex justify-end items-center pt-6',
          'xs:pt-5',
          'sm:pt-0.5',
        )}
      >
        <Button
          reverse
          fill={false}
          disabled={downloading}
          secondary
          onClick={() => handleDocDownload({ docId })}
          label={
            <div className="flex items-center py-0.5">
              <Text size="xs" weight="bold">
                {downloading ? (
                  <InlineLoader paddingX="20.1" paddingY="0" color="brand" />
                ) : (
                  <span>Download</span>
                )}
              </Text>
            </div>
          }
          icon={
            !downloading ? (
              <div className="flex items-start -mt-0.5 -ml-1">
                <Download size="small" color="brand" />
              </div>
            ) : undefined
          }
        />
      </div>
    </div>
  )
}

SocialDocumentCard.propTypes = {
  document: PropTypes.shape({
    paid: PropTypes.bool,
    _id: PropTypes.string,
    created: PropTypes.string,
  }).isRequired,
  answerStore: PropTypes.shape({}).isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(null, mapDispatchToProps)(SocialDocumentCard)
