import paymentsTypes from './paymentsTypes'

const initialState = {
  payments: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case paymentsTypes.SET_PAYMENTS:
      return { ...state, payments: action.payload.payments }

    default:
      return state
  }
}
