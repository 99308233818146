import React from 'react'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import intercomIcon from '../../../../../../common/assets/images/intercom-icon.png'
import teamHorizontal from '../../../../../../common/assets/images/team-hoz.png'
import Link from '../../../../../../common/components/atoms/Link'
import Text from '../../../../../../common/components/atoms/Text'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'

const WereHereToHelp = () => {
  const { t } = useTranslation()

  return (
    <Box
      data-testid="were-here-to-help"
      sx={{
        mt: '1.2rem',
        width: '100%',
        padding: '1rem',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        borderRadius: (theme) => theme.shape.borderRadius,
        bgcolor: (theme) =>
          theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.LIGHTER],
        '::before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '" "',
          position: 'absolute',
          borderRadius: (theme) => theme.shape.borderRadiusMedium,
          borderBottom: (theme) =>
            `5px solid ${theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.MAIN]}`,
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mb: '0.5rem',
          alignItems: 'center',
        }}
      >
        <Text
          size="lg"
          color={themeColorTypes.GREY}
          colorVariant={themeColorVariants.DARK}
          weight="bold"
        >
          {t('questionnaire.misc:sidebar.hereToHelp')}
        </Text>
        <img src={teamHorizontal} alt="Team" width="75px" height="auto" />
      </Box>
      <Text
        sx={{
          lineHeight: 1.5,
          /*
           * Allows for text highlighting, otherwise, the ::before
           * places an unhighlightable element on top of the text
           */
          zIndex: (theme) => theme.zIndex.custom[1],
        }}
        variant="paragraph"
      >
        {t('questionnaire.misc:sidebar.contactSubLabel.part1')}{' '}
        <Box
          onClick={() => {
            if (window.Intercom) {
              window.Intercom('show')
            }
          }}
          sx={{ display: 'inline', cursor: 'pointer' }}
        >
          <img
            style={{
              bottom: '2px',
              display: 'inline',
              position: 'relative',
            }}
            src={intercomIcon}
            alt="Intercom Icon"
            width="17px"
            height="17px"
          />
        </Box>{' '}
        {t('questionnaire.misc:sidebar.contactSubLabel.part2')}{' '}
        <Link underline="hover" href="tel:+12896781689">
          (289) 678-1689
        </Link>
        .
      </Text>
    </Box>
  )
}

export default WereHereToHelp
