import { CONSTANTS } from '@epilogue/common'

export default (noticeConnectCode: string, languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
Le [Registre des Testaments du Canada](https://www.canadawillregistry.org/consumers) a été créé pour offrir aux gens un moyen d'enregistrer l'emplacement de leur testament.

Voici quelques points importants à savoir sur le Registre des Testaments du Canada :
- Le Registre des Testaments du Canada a été créé afin que les gens puissent enregistrer l'emplacement de leur testament. Il **n'est pas** un registre gouvernemental officiel. Il n'existe aucun registre officiel des testaments gouvernemental au Canada.
- Vous **n'avez pas** besoin de télécharger une copie de votre testament sur le Registre des Testaments du Canada. Ils stockent simplement l'**emplacement** de votre testament original et signé pour aider vos proches à le retrouver après votre décès.
- Il est important de conserver votre testament dans un endroit sûr accessible par votre exécuteur en cas de décès. Il est judicieux d'informer votre exécuteur de l'endroit où votre testament est conservé, même si vous vous enregistrez auprès du Registre des Testaments du Canada.

Voici les étapes à suivre pour enregistrer votre testament auprès du Registre des Testaments du Canada :
1. Rendez-vous sur le [site Web du Registre des Testaments du Canada](https://www.canadawillregistry.org/consumers) et sélectionnez "Enregistrer un testament".
2. Remplissez le formulaire d'inscription et cliquez sur "Enregistrer".
3. Saisissez votre code personnalisé sur l'écran de paiement et cliquez sur "Appliquer". Votre code personnalisé pour le Registre des Testaments du Canada est : **${noticeConnectCode}**.

Si vous rencontrez des problèmes lors de l'utilisation du site Web du Registre des Testaments du Canada pour enregistrer votre testament, vous pouvez les contacter à l'adresse info@NoticeConnect.com ou au 1 (866) 577-8509.

    `
    : `
The [Canada Will Registry](https://www.canadawillregistry.org/consumers) was created to give people a way to register the location of their Will. 

Here are some important things to know about the Canada Will Registry:
- The Canada Will Registry was created so that people can register the location of their Will. It is **not** an official government registry. There is no official government Will registry in Canada.
- You **do not** need to upload a copy of your Will to the Canada Will Registry. They simply store the **location** of your original, signed Will to help your loved ones find it after you pass away.
- It is important to keep your Will in a safe place that can be accessed by your executor in case of your death. It is a good idea to tell you executor where your Will is stored, even if your register with the Canada Will Registry.

Here are the steps to register your Will with the Canada Will Registry:
1. Go to the [Canada Will Registry website](https://www.canadawillregistry.org/consumers) and select "Register a Will".
2. Complete the registration form and click "Register".
3. Enter your custom code on the payment screen and click "Apply". Your custom Canada Will Registry code is: **${noticeConnectCode}**.

If you encounter any issues using the Canada Will Registry website to register your Will, you can contact them at info@NoticeConnect.com or 1 (866) 577-8509.
`
