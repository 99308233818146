import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, AlertTitle, Slide, Snackbar } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'

import commonActions from '../../../ducks/commonActions'
import commonSelectors from '../../../ducks/commonSelectors'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'

const SlideTransition = (props) => <Slide {...props} direction="up" />

const iconMap = {
  [CONSTANTS.toastTypes.INFO]: <InfoOutlinedIcon />,
  [CONSTANTS.toastTypes.SUCCESS]: <CheckCircleOutlinedIcon />,
  [CONSTANTS.toastTypes.WARNING]: <ErrorOutlineOutlinedIcon />,
  [CONSTANTS.toastTypes.ERROR]: <ErrorOutlineOutlinedIcon />,
}

const Toast = ({ toastProperties, dispatchUpdateToast }) => {
  const {
    type,
    label,
    message,
    isOpen: openStatus,
    hideDuration = 3000,
  } = toastProperties

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => setIsOpen(openStatus), [openStatus])

  return (
    <Snackbar
      open={isOpen}
      data-testid="toast"
      onClose={() => setIsOpen(false)}
      autoHideDuration={hideDuration}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
      TransitionProps={{
        onExited: () => dispatchUpdateToast({ isOpen: false }),
      }}
    >
      <Alert
        iconMapping={iconMap}
        severity={type}
        variant="filled"
        onClose={() => setIsOpen(false)}
        sx={{
          color: themeColors[themeColorTypes.WHITE][themeColorVariants.MAIN],
          alignItems: 'center',
          '& > .MuiAlert-action': {
            pt: 0,
          },
        }}
      >
        {label && (
          <AlertTitle sx={{ mb: message ? '0.35rem' : '0' }}>
            {label}
          </AlertTitle>
        )}
        {message}
      </Alert>
    </Snackbar>
  )
}

Toast.propTypes = {
  dispatchUpdateToast: PropTypes.func.isRequired,
  toastProperties: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(CONSTANTS.toastTypes)),
    label: PropTypes.string,
    message: PropTypes.string,
    hideDuration: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  toastProperties: commonSelectors.getToast(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateToast: (toastProperties) =>
    dispatch(commonActions.updateToast(toastProperties)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Toast)
