import React, { useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import _get from 'lodash/get'
import _has from 'lodash/has'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, ClickAwayListener, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import logo from '../../../../../assets/images/logo.svg'
import Text from '../../../../atoms/Text'
import HeaderContext from '../../context/HeaderContext'
import WorldMenu from '../WorldMenu'

const useStyles = makeStyles((theme) => ({
  logoAreaWrapper: {
    cursor: ({ isLogoAreaClickable }) =>
      isLogoAreaClickable ? 'pointer' : 'auto',

    '&:active, &:focus': {
      outline: 'none',
      border: 'none',
    },
  },

  worldMenuWrapper: {
    top: '3.5rem',
    zIndex: theme.zIndex.custom[1],
  },
}))

const Logo = () => {
  const {
    auth,
    world,
    onDashboard,
    onGetStarted,
    onQuestionnaire,
    worldMenuActive,
    setWorldMenuActive,
    worldSwitcherAvailable,
  } = useContext(HeaderContext)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const isLogoAreaClickable =
    worldSwitcherAvailable || onQuestionnaire || onGetStarted

  const handleClick = () => {
    if (worldSwitcherAvailable) {
      setWorldMenuActive((prev) => !prev)
    } else if (onQuestionnaire || onGetStarted) {
      if (auth) {
        const toCurrentWorldDashboard = `${_get(
          CONSTANTS.worldTypes,
          [world, 'dashboardPath'],
          '/login',
        )}?w=${world}`

        navigate(toCurrentWorldDashboard)
      } else {
        window.location.href = 'https://epiloguewills.com'
      }
    }
  }

  const classes = useStyles({ isLogoAreaClickable })
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (worldMenuActive) {
          setWorldMenuActive(false)
        }
      }}
    >
      <Box
        tabIndex={0}
        role="button"
        display="flex"
        alignItems="center"
        height="100%"
        onClick={handleClick}
        onKeyDown={handleClick}
        className={classes.logoAreaWrapper}
        aria-label="Clickable logo"
      >
        <Box
          data-testid="header-logo"
          width={isDesktop ? '100px' : '93px'}
          marginRight="0.25rem"
        >
          <img src={logo} alt="Epilogue logo" />
        </Box>

        {(onQuestionnaire || onDashboard) &&
          _has(CONSTANTS, ['worldTypes', world, 'label']) && (
            <Box display="flex" alignItems="flex-end" paddingLeft="0.375rem">
              <Text data-testid="logo-world-label" size="xs">
                {t(_get(CONSTANTS.worldTypes[world].label, 'tKey'))}
              </Text>
            </Box>
          )}

        {worldSwitcherAvailable && (
          <Box data-testid="world-menu-arrow">
            {worldMenuActive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        )}

        {worldMenuActive && (
          <Box
            left="0"
            position="absolute"
            width={isDesktop ? 'auto' : '100%'}
            display={worldMenuActive ? 'block' : 'hidden'}
            className={classes.worldMenuWrapper}
            data-testid="world-menu-wrapper"
          >
            <WorldMenu />
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default Logo
