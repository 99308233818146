import { combineReducers } from 'redux'

import addOns from './addOns/addOnsReducer'
import appointees from './appointees/appointeesReducer'
import appointments from './appointments/appointmentsReducer'
import bonusDocs from './bonusDocs/bonusDocsReducer'
import dashboard from './dashboard/dashboardReducer'
import documents from './documents/documentsReducer'
import payments from './payments/paymentsReducer'

export default combineReducers({
  addOns,
  appointees,
  appointments,
  bonusDocs,
  dashboard,
  documents,
  payments,
})
