import React from 'react'

import { Navigate, Route } from 'react-router-dom'

import Summary from '../../../common/components/Summary'
import GiftAddOn from '../components/views/AddOns/views/GiftAddOn'
import PrintingAddOn from '../components/views/AddOns/views/PrintingAddOn'
import WitnessingAddOn from '../components/views/AddOns/views/WitnessingAddOn'
import Dashboard from '../components/views/Dashboard'
import Documents from '../components/views/Documents'
import HowDidYouHearAboutUs from '../components/views/HowDidYouHearAboutUs'
import Payment from '../components/views/Payment'
import PaymentSuccess from '../components/views/Payment/views/PaymentSuccess'
import AnimatedRouter from './animations/AnimatedRouter'
import MountTransition from './animations/MountTransition'

const baseDashboardPadding = {
  px: { min: '0.75rem', xs: '1.5rem', md: '2rem', lg: '4rem' },
  py: { min: '6rem', md: '7rem', lg: '8rem' },
}

const Router = () => (
  <AnimatedRouter>
    <Route
      path="/"
      element={
        <MountTransition id="/" sx={baseDashboardPadding}>
          <Dashboard />
        </MountTransition>
      }
    />

    <Route
      path="/documents"
      element={
        <MountTransition id="/documents" sx={baseDashboardPadding}>
          <Documents />
        </MountTransition>
      }
    />

    <Route
      path="/summary"
      element={
        <MountTransition id="/summary" sx={baseDashboardPadding}>
          <Summary />
        </MountTransition>
      }
    />

    <Route
      path="/add-ons/printing"
      element={
        <MountTransition id="/add-ons/printing" sx={baseDashboardPadding}>
          <PrintingAddOn />
        </MountTransition>
      }
    />

    <Route
      path="/add-ons/witnessing"
      element={
        <MountTransition id="/add-ons/witnessing" sx={baseDashboardPadding}>
          <WitnessingAddOn />
        </MountTransition>
      }
    />

    <Route
      path="/add-ons/gift"
      element={
        <MountTransition id="/add-ons/gift" sx={baseDashboardPadding}>
          <GiftAddOn />
        </MountTransition>
      }
    />

    <Route
      path="/how-did-you-hear-about-us"
      element={
        <MountTransition
          id="/how-did-you-hear-about-us"
          sx={baseDashboardPadding}
        >
          <HowDidYouHearAboutUs />
        </MountTransition>
      }
    />

    <Route
      path="/payment"
      element={
        <MountTransition id="/payment">
          <Payment />
        </MountTransition>
      }
    />

    <Route
      path="/payment/success/:productId"
      element={
        <MountTransition
          id="/payment/success/:productId"
          sx={baseDashboardPadding}
        >
          <PaymentSuccess />
        </MountTransition>
      }
    />

    <Route path="*" element={<Navigate to="/dashboard" replace />} />
  </AnimatedRouter>
)

export default Router
