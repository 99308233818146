import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'

import _upperFirst from 'lodash/upperFirst'

import { Button, Heading, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import Modal from '../../../../../../common/components/atoms/Modal'
import amplitude from '../../../../../../common/lib/amplitude'

const PartnerModal = ({
  dispatchToQuestion,
  nextUnansweredQuestion = {},
  answerStore,
}) => {
  const [show, setShow] = useState(true)
  const { firstName, product } = answerStore

  const navigate = useNavigate()

  useEffect(() => {
    if (show)
      amplitude.sendEvent('ViewedPartnerWelcomeModal', {
        hasIncapacityDocs: product === 'willPoa',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const { t } = useTranslation()

  return (
    <Modal show={show} onClose={() => setShow(false)} fullBleed>
      <div className="p-10 max-w-xl m-auto">
        <div className="text-center">
          <Heading level={2}>
            {t('dashboard:components.PartnerModal.label', {
              name: _upperFirst(firstName),
            })}
          </Heading>
        </div>

        <div className="my-3 text-center">
          <Text>
            {product === 'willPoa'
              ? t('dashboard:components.PartnerModal.subLabel.case1')
              : t('dashboard:components.PartnerModal.subLabel.case2')}
          </Text>
        </div>

        <div className="mt-10 flex justify-center">
          <Button
            primary
            data-testid="partner-modal-button"
            onClick={() => {
              dispatchToQuestion(nextUnansweredQuestion)
              // Send user to questionnaire
              navigate('/questionnaire')
            }}
            label={
              <div className="px-4 py-1">
                <Text>
                  {t('dashboard:components.PartnerModal.buttonLabel')}
                </Text>
              </div>
            }
          />
        </div>
      </div>
    </Modal>
  )
}

PartnerModal.propTypes = {
  answerStore: PropTypes.shape({
    product: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
  nextUnansweredQuestion: PropTypes.shape({}),
}

export default PartnerModal
