import React, { useContext } from 'react'

import { alpha, Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../styles/muiTheme'
import Text from '../../../atoms/Text'
import handleLogout from '../api/handleLogout'
import HeaderContext from '../context/HeaderContext'

const LogoutButton = () => {
  const { t } = useTranslation()
  const { dispatchSetGlobalErrorMessage } = useContext(HeaderContext)

  return (
    <Box
      tabIndex={0}
      role="button"
      aria-label="Logout button"
      data-testid="logout-button"
      onClick={() => handleLogout(dispatchSetGlobalErrorMessage)}
      onKeyDown={() => handleLogout(dispatchSetGlobalErrorMessage)}
      sx={{
        pb: '1rem',
        pt: '0.85rem',
        pl: '1rem',
        color: alpha(
          themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
          0.61,
        ),
        ':active': {
          outline: 'none',
          border: 'none',
        },
        ':focus': {
          outline: 'none',
          border: 'none',
        },
      }}
    >
      <Text
        color="inherit"
        weight="medium"
        sx={{
          lineHeight: '22px',
          fontWeight: 500,
          fontSize: '0.98rem',
          transition: 'color 0.25s ease-out',

          ':hover': {
            color: (theme) =>
              theme.palette[themeColorTypes.BRAND][themeColorVariants.MAIN],
          },
        }}
      >
        {t('navigation:logout')}
      </Text>
    </Box>
  )
}

export default LogoutButton
