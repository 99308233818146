import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as WarningIcon } from '../../../../../../common/assets/images/warning-icon.svg'
import Button from '../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../common/components/atoms/Text'
import handleLogout from '../../../../../../common/components/molecules/Header/api/handleLogout'
import commonActions from '../../../../../../common/ducks/commonActions'
import amplitude from '../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../common/styles/muiTheme'
import paymentsSelectors from '../../../../../Dashboard/ducks/payments/paymentsSelectors'
import questionnaireSelectors from '../../../../ducks/questionnaire/questionnaireSelectors'

const NameMatchesPartnerModal = ({
  showModal,
  answerStore,
  setShowModal,
  partnerPaidFor,
  dispatchSetGlobalErrorMessage,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const { firstName, partnerFirstName, partnerGender = 'other' } = answerStore

  useEffect(() => {
    if (showModal) amplitude.sendEvent('ViewedNameMatchesPartnerModal')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const { t } = useTranslation()

  return (
    <Modal
      maxWidth="sm"
      show={showModal}
      onClose={(_, reason) => {
        amplitude.sendEvent('ClosedNameMatchesPartnerModal', { reason })
        setShowModal(false)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          p: isMobile ? '0' : '0.8rem 0',
          rowGap: '2rem',
        }}
        data-testid="name-matches-partner-modal"
      >
        <WarningIcon />

        <Heading variant="h3">
          {t('customQuestion:Name.components.NameMatchesPartnerModal.label', {
            partnerFirstName,
          })}
        </Heading>

        {partnerPaidFor ? (
          <>
            <Text size="md">
              {t(
                'customQuestion:Name.components.NameMatchesPartnerModal.partnerPaidFor.true.body1',
                {
                  firstName,
                  partnerFirstName,
                },
              )}
            </Text>

            <Text size="md">
              {t(
                `customQuestion:Name.components.NameMatchesPartnerModal.partnerPaidFor.true.body2.${partnerGender === 'male' ? 'male' : partnerGender === 'female' ? 'female' : 'other'}`,
                {
                  firstName,
                  partnerFirstName,
                },
              )}
            </Text>
          </>
        ) : (
          <>
            <Text size="md">
              {t(
                `customQuestion:Name.components.NameMatchesPartnerModal.partnerPaidFor.false.body1.${partnerGender === 'male' ? 'male' : partnerGender === 'female' ? 'female' : 'other'}`,
                { partnerFirstName },
              )}
            </Text>

            <Text size="md">
              {t(
                `customQuestion:Name.components.NameMatchesPartnerModal.partnerPaidFor.false.body2.${partnerGender === 'male' ? 'male' : partnerGender === 'female' ? 'female' : 'other'}`,
                {
                  firstName,
                  partnerFirstName,
                },
              )}
            </Text>
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.5rem',
          }}
        >
          <Button
            size="md"
            variant="contained"
            color={themeColorTypes.ACCENT_1}
            onClick={() => {
              amplitude.sendEvent('ClickedNameMatchesPartnerModalLogOutButton')
              handleLogout(dispatchSetGlobalErrorMessage)
            }}
            label={t(
              'customQuestion:Name.components.NameMatchesPartnerModal.button.primary',
              { firstName },
            )}
          />
          <Button
            size="xs"
            iconFontSize="1rem"
            color={themeColorTypes.GREY}
            onClick={() => {
              amplitude.sendEvent('ClickedNameMatchesPartnerModalCloseButton')
              setShowModal(false)
            }}
            endIcon={<ArrowForwardIcon />}
            label={t(
              'customQuestion:Name.components.NameMatchesPartnerModal.button.secondary',
              { firstName },
            )}
          />
        </Box>
      </Box>
    </Modal>
  )
}

NameMatchesPartnerModal.propTypes = {
  answerStore: PropTypes.shape({
    firstName: PropTypes.string,
    partnerGender: PropTypes.string,
    partnerFirstName: PropTypes.string,
  }).isRequired,
  partnerPaidFor: PropTypes.bool,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  partnerPaidFor: paymentsSelectors.getPartnerPaidFor(state.dashboard),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NameMatchesPartnerModal)
