import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Qu'est-ce qu'un Compte d'épargne libre d'impôt ?

Un Compte d'épargne libre d'impôt (CELI) est un type spécial de compte d'investissement conçu pour aider les Canadiens à épargner pour atteindre leurs objectifs financiers personnels.

#### Économiser des impôts avec un CELI

Un CELI fonctionne comme un compte d'investissement ordinaire avec une différence significative — lorsque les investissements dans un CELI croissent et génèrent des revenus, vous n'avez pas à payer d'impôts sur eux. Cela signifie que vos économies augmentent plus rapidement !

Contrairement à un REER, les cotisations à votre CELI ne sont pas déductibles d'impôt. Cependant, les retraits d'un CELI ne sont pas imposables (les retraits d'un REER le sont).

#### Cotisations au CELI

Chaque personne admissible à ouvrir un CELI reçoit un certain montant de nouvelle "marge de cotisation" chaque année (à partir de 2009). Pour 2023, chaque personne reçoit 6 500 $ de nouvelle marge de cotisation.

Si quelqu'un n'utilise pas toute sa marge de cotisation dans une année, le montant inutilisé s'accumule avec le temps et peut être utilisé l'année suivante. Ainsi, même si vous n'avez pas de CELI, vous pouvez déjà avoir beaucoup de marge de cotisation disponible une fois que vous décidez d'en ouvrir un.

#### Commencez à investir dans un CELI

Si vous économisez suffisamment pour commencer à investir dans un CELI, c'est certainement une option que vous voudrez explorer. Contactez votre institution financière et demandez à parler à un conseiller sur l'ouverture d'un CELI.
    `
    : `
#### What is a Tax-Free Savings Account?

A Tax-Free Savings Account (TFSA) is a special type of investment account designed to help Canadians save to reach their personal financial goals.

#### Saving Taxes with a TFSA

A TFSA works like a regular investment account with one significant difference — as the investments in a TFSA grow and earn income, you do not have to pay any tax on them. This means your savings grow faster!

Unlike an RRSP, contributions to your TFSA are not tax deductible. However, withdrawals from a TFSA are not taxable (RRSP withdrawals are).

#### TFSA Contributions

Every person who is eligible to open a TFSA receives a certain amount of new “contribution room” each year (starting in 2009). For 2023 each person receives $6,500 of new contribution room.

If someone doesn't use all their contribution room in a year, the unused amount accumulates over time and can be used in a subsequent year. So even if you don't have a TFSA, you may already have lots of contribution room available once you decide to open one.

#### Start Investing in a TFSA

If you're saving enough to start investing in a TFSA, it is definitely an option you'll want to check out. Contact your financial institution and ask to speak to an advisor about opening a TFSA.
`
