import React from 'react'

import _startCase from 'lodash/startCase'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { Grid } from '@mui/material'

import { CONSTANTS, Province } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Text from '../../../../../../../../common/components/atoms/Text'
import useProvinceDictionary from '../../../../../../../../common/hooks/useProvinceDictionary'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'

interface Props {
  items: string[]
  checkType: 'filled' | 'standard'

  province?: string
  partnerDocs?: boolean
}

const CheckedList = ({
  items,
  checkType,
  partnerDocs = false,
  province = undefined,
}: Props) => {
  const CheckIcon =
    checkType === 'filled' ? CheckCircleRoundedIcon : CheckRoundedIcon

  const { provinceDictionary } = useProvinceDictionary()

  const { t } = useTranslation()

  return (
    <Grid
      container
      rowSpacing={1}
      direction="column"
      data-testid={`documents-in-cart-wrapper${
        partnerDocs ? '-partner-docs' : ''
      }`}
    >
      {React.Children.toArray(
        items.map((item) => (
          <Grid item sx={{ lineHeight: '1', display: 'flex' }}>
            <CheckIcon sx={{ mr: '0.55rem', fontSize: '0.875em' }} />
            <Text
              color={themeColorTypes.GREY}
              colorVariant={themeColorVariants.DARK}
              sx={{ display: 'initial', fontSize: '0.83rem' }}
            >
              {province
                ? item === CONSTANTS.docTypes.WILL
                  ? t('common:lastWillTestament')
                  : _startCase(
                      provinceDictionary(item, province as Province),
                    ).replace('Poa', 'POA')
                : item}
            </Text>
          </Grid>
        )),
      )}
    </Grid>
  )
}

export default CheckedList
