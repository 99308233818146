import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import _remove from 'lodash/remove'

import { CONSTANTS } from '@epilogue/common'

/*
 * Users with children should never see partner, children, or grandchild as
 * relationship option in the RemoteDistribution.js component/question
 */

export default (relationshipOptions, answerCache) => {
  const isFamilyWill =
    !_isEmpty(answerCache) &&
    answerCache.hasChildren &&
    answerCache.hasChildren === 'yes'

  const clonedRelationshipOptions = _cloneDeep(relationshipOptions)

  if (isFamilyWill) {
    _remove(
      clonedRelationshipOptions,
      (opt) =>
        opt.value === CONSTANTS.relationshipValues.MY_PARTNER ||
        opt.value === CONSTANTS.relationshipValues.MY_DAUGHTER ||
        opt.value === CONSTANTS.relationshipValues.MY_SON ||
        opt.value === CONSTANTS.relationshipValues.MY_GRANDCHILD,
    )
  }

  return clonedRelationshipOptions
}
