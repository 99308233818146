import {
  ONTARIO_TERMS,
  provinceDictionary,
} from '../../../../common/utils/provinceDictionary'

export default [
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.propertyPoa:common.attorneyOutOfProvinceSidebar.item1.header',
    },
    children: [
      {
        type: 'text',
        body: ({ answerStore }) => {
          const { province } = answerStore

          const ontarioTerm = ONTARIO_TERMS.A_POWER_OF_ATTORNEY_FOR_PROPERTY
          const provinceSpecificTerm = provinceDictionary(ontarioTerm, province)

          return {
            tKey: 'questionnaire.propertyPoa:common.attorneyOutOfProvinceSidebar.item1.children.item1.body',
            options: { provinceSpecificTerm },
          }
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.propertyPoa:common.attorneyOutOfProvinceSidebar.item1.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.propertyPoa:common.attorneyOutOfProvinceSidebar.item1.children.item3.body',
        },
      },
    ],
  },
]
