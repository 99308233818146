import { CONSTANTS } from '@epilogue/common'

import {
  ONTARIO_TERMS,
  provinceDictionary,
} from '../../../../common/utils/provinceDictionary'

export default [
  {
    type: 'accordion',
    header: ({ answerStore }) => {
      const { province } = answerStore
      return `What will your ${provinceDictionary(
        ONTARIO_TERMS.POA_FOR_CARE,
        province,
      )} say if you select “Withhold/withdraw treatment”?`
    },
    children: [
      {
        type: 'text',
        body: ({ answerStore }) => {
          const { province } = answerStore

          return `If you choose this option, your ${provinceDictionary(
            ONTARIO_TERMS.POA_FOR_CARE,
            province,
          )} will include the following language:`
        },
      },
      {
        type: 'text',
        body: ({ answerStore }) => {
          const { province, firstName } = answerStore

          switch (province) {
            case CONSTANTS.provinces.SASKATCHEWAN:
            case CONSTANTS.provinces.MANITOBA:
              return '"It is my desire that should I have an incurable injury, disease or illness regarded as a terminal condition by my physician and if my physician has determined that the application of life-sustaining procedures would serve only artificially to prolong the dying process, and that the likely risks and burdens of such treatment would outweigh the expected benefits, I direct that such procedures be withheld or withdrawn and that I be permitted to die with only the administration of medication or the performance of any medical procedure deemed necessary to provide me with comfort care or to alleviate pain."'
            case CONSTANTS.provinces.BRITISH_COLUMBIA:
              return `"Without limiting the discretion of the Representative hereunder, it is ${firstName}'s wish that if ${firstName} should have an incurable injury, disease or illness regarded as a terminal condition by ${firstName}'s physician and if ${firstName}'s physician has determined that the application of life-sustaining procedures would serve only artificially to prolong the dying process, and that the likely risks and burdens of such treatment would outweigh the expected benefits, ${firstName}'s wish is that such procedures be withheld or withdrawn and that ${firstName} be permitted to die with only the administration of medication or the performance of any medical procedure deemed necessary to provide ${firstName} with comfort care or to alleviate pain."`
            default:
              return `"Without limiting the discretion of my ${provinceDictionary(
                ONTARIO_TERMS.ATTORNEY,
                province,
              )} hereunder, it is my desire that should I have an incurable injury, disease or illness regarded as a terminal condition by my physician and if my physician has determined that the application of life-sustaining procedures would serve only artificially to prolong the dying process, and that the likely risks and burdens of such treatment would outweigh the expected benefits, I direct that such procedures be withheld or withdrawn and that I be permitted to die with only the administration of medication or the performance of any medical procedure deemed necessary to provide me with comfort care or to alleviate pain."`
          }
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: ({ answerStore }) => {
      const { province } = answerStore
      return `What will your ${provinceDictionary(
        ONTARIO_TERMS.POA_FOR_CARE,
        province,
      )} say if you select “Apply/continue treatment”?`
    },
    children: [
      {
        type: 'text',
        body: ({ answerStore }) => {
          const { province } = answerStore

          return `If you choose this option, your ${provinceDictionary(
            ONTARIO_TERMS.POA_FOR_CARE,
            province,
          )} will include the following language:`
        },
      },
      {
        type: 'text',
        body: ({ answerStore }) => {
          const { province, firstName } = answerStore

          switch (province) {
            case CONSTANTS.provinces.SASKATCHEWAN:
            case CONSTANTS.provinces.MANITOBA:
              return '"It is my desire that procedures be applied to sustain and prolong my life, even under circumstances in which I have an incurable injury, disease, or illness regarded as a terminal condition by my physician, and notwithstanding that my physician may have determined that the application of such procedures would serve only artificially to prolong the dying process."'
            case CONSTANTS.provinces.BRITISH_COLUMBIA:
              return `"Without limiting the discretion of the Representative hereunder, it is ${firstName}'s wish that procedures be applied to sustain and prolong ${firstName}'s life, even under circumstances in which ${firstName} has an incurable injury, disease, or illness regarded as a terminal condition by ${firstName}'s physician, and notwithstanding that ${firstName}'s physician may have determined that the application of such procedures would serve only artificially to prolong the dying process."`
            default:
              return `"Without limiting the discretion of my ${provinceDictionary(
                ONTARIO_TERMS.ATTORNEY,
                province,
              )} hereunder, it is my desire that procedures be applied to sustain and prolong my life, even under circumstances in which I have an incurable injury, disease, or illness regarded as a terminal condition by my physician, and notwithstanding that my physician may have determined that the application of such procedures would serve only artificially to prolong the dying process."`
          }
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: 'What if none of these options reflects your wishes?',
    children: [
      {
        type: 'text',
        body: ({ answerStore }) => {
          const { province } = answerStore
          return `If none of these options reflects your wishes, you can select “I'm not sure”. Any specific instructions or wishes you have should be communicated to the people you've named in your ${provinceDictionary(
            ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PERSONAL_CARE,
            province,
          )}.`
        },
      },
    ],
  },
]
