import React from 'react'
import PropTypes from 'prop-types'

import makeStyles from '@mui/styles/makeStyles'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Google } from '../../../assets/images/google.svg'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'
import Button from '../../atoms/Button'

const useStyles = makeStyles({
  greyBorder: {
    border: `1px solid ${
      themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTER]
    }`,
  },
})

const OauthProviderButton = ({ onClick, type, provider, working = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Button
      data-testid={`oauth-button-${provider}-${type}`}
      label={t(
        type === CONSTANTS.oauth.types.LOGIN
          ? 'molecules:OauthProviderButton.logInWith'
          : 'molecules:OauthProviderButton.continueWith',
        { provider },
      )}
      working={working}
      spinnerPadding="0.35rem"
      rounded="normal"
      onClick={onClick}
      variant="contained"
      startIcon={<Google />}
      color={themeColorTypes.WHITE}
      className={classes.greyBorder}
    />
  )
}

OauthProviderButton.propTypes = {
  working: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(CONSTANTS.oauth.types)).isRequired,
  provider: PropTypes.oneOf(Object.values(CONSTANTS.oauth.providers))
    .isRequired,
}

export default OauthProviderButton
