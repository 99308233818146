import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Button, Image, Paragraph, Text } from 'grommet'
import { LinkPrevious } from 'grommet-icons'
import styled from 'styled-components'

import { ReactComponent as PhoneSignedWill } from '../../../../../common/assets/images/phone-signed-will.svg'
import StyledLabel from '../../../../../common/components/atoms/StyledLabel'
import tw from '../../../../../common/styles/tw'
import SocialEverythingButton from '../../../../SocialDashboard/components/SocialEverythingButton/SocialEverythingButton'
import navigationActions from '../../../ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'

const PaddedButton = styled(Button)`
  padding: ${tw.spacing['2']} ${tw.spacing['3']};
`
const SocialComplete = ({ questionnaireValid, dispatchPreviousQuestion }) => (
  <div className="flex flex-col items-center justify-center">
    <div className="text-center">
      {questionnaireValid ? (
        <>
          <div>
            <StyledLabel>Nicely done!</StyledLabel>
          </div>
          <StyledLabel>
            We&apos;re ready to generate your Social Media Will.
          </StyledLabel>
        </>
      ) : (
        <div>
          <StyledLabel>You&apos;re almost done!</StyledLabel>
          <div className="mt-3 max-w-2xl">
            <Paragraph size="smedium">
              You made it to the end of the questionnaire, but it looks like
              there are still a few questions you need to answer. Click the
              button below to go to your next unanswered question.
            </Paragraph>
          </div>
        </div>
      )}
    </div>

    <div className="mt-5 mb-10 w-64 sm:mb-12">
      {questionnaireValid ? (
        <PhoneSignedWill />
      ) : (
        <Image
          src="https://epilogue-assets-1.s3.ca-central-1.amazonaws.com/puzzle.svg"
          alt="Puzzle with one missing piece"
        />
      )}
    </div>

    <div className="flex flex-col items-center">
      <SocialEverythingButton />

      <div className="mt-4">
        <PaddedButton
          plain
          hoverIndicator
          onClick={dispatchPreviousQuestion}
          icon={<LinkPrevious className="-mr-2" color="grey-5" size="small" />}
          label={
            <Text color="grey-5" size="small">
              Back
            </Text>
          }
        />
      </div>
    </div>
  </div>
)

SocialComplete.propTypes = {
  questionnaireValid: PropTypes.bool,
  dispatchPreviousQuestion: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialComplete)
