import appointeesTypes from './appointeesTypes'

export default {
  getAppointees: () => ({ type: appointeesTypes.GET_APPOINTEES }),

  getAppointeesWorking: (working) => ({
    type: appointeesTypes.GET_APPOINTEES_WORKING,
    payload: { working },
  }),

  setAppointees: (appointees) => ({
    type: appointeesTypes.SET_APPOINTEES,
    payload: { appointees },
  }),
}
