import React from 'react'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as SuccessCheck } from '../../../../../../../../../common/assets/images/success-check.svg'
import Button from '../../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../../common/components/atoms/Text'
import commonActions from '../../../../../../../../../common/ducks/commonActions'
import { themeColorTypes } from '../../../../../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import dashboardSelectors from '../../../../../../../ducks/dashboard/dashboardSelectors'
import useEmailDocuments from '../../../../hooks/useEmailDocuments'

interface Props {
  userId: string
  answerStore: { [key: string]: any }
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const BookWitnessingEmailView = ({
  userId,
  answerStore,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { t } = useTranslation()

  const { EmailStatusTypes, status, handleSubmit } = useEmailDocuments({
    userId,
    answerStore,
    dispatchSetGlobalErrorMessage,
  })

  return (
    <Box data-testid="book-witnessing-email-view">
      {status === EmailStatusTypes.SUCCESS ? (
        <Box
          data-testid="book-witnessing-email-view-success"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: '1.5rem' }}>
            <SuccessCheck />
          </Box>

          <Heading align="center" variant="h3">
            {t(
              'documents:components.modals.BookWitnessingModal.components.BookWitnessingEmailView.successLabel',
            )}
          </Heading>

          <Text sx={{ my: '1rem' }} align="center">
            {t(
              'documents:components.modals.BookWitnessingModal.components.BookWitnessingEmailView.successSubLabel',
              { email: answerStore.email },
            )}
          </Text>
        </Box>
      ) : (
        <Box
          data-testid="book-witnessing-email-view-default"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Heading align="center" variant="h3">
            {t(
              'documents:components.modals.BookWitnessingModal.components.BookWitnessingEmailView.label',
            )}
          </Heading>

          <Text sx={{ my: '1rem' }} align="center">
            {t(
              'documents:components.modals.BookWitnessingModal.components.BookWitnessingEmailView.subLabel',
              { email: answerStore.email },
            )}
          </Text>

          <Button
            variant="contained"
            spinnerPadding="0.375rem 3.5rem"
            spinnerColor={themeColorTypes.GREY}
            working={status === EmailStatusTypes.WORKING}
            data-testid="book-witnessing-email-view-button"
            sx={{ mt: '1.8rem' }}
            label={t(
              'documents:components.modals.BookWitnessingModal.components.BookWitnessingEmailView.buttonLabel',
            )}
            onClick={async (e: React.MouseEvent<HTMLElement>) => {
              await handleSubmit(e)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  userId: dashboardSelectors.getUserId(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookWitnessingEmailView)
