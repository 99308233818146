import { createLogic } from 'redux-logic'

import commonActions from '../../../../../common/ducks/commonActions'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import documentsActions from '../documentsActions'
import documentsTypes from '../documentsTypes'

export default createLogic({
  name: 'fetchDocuments',
  type: documentsTypes.FETCH_DOCUMENTS,
  async process(_, dispatch, done) {
    dispatch(documentsActions.fetchDocumentsWorking(true))

    try {
      const res = await request({
        method: 'GET',
        url: PATHS.DOCUMENT_DOCUMENTS,
      })

      const { documents } = res.data.payload

      dispatch(documentsActions.setDocuments(documents))
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to fetch documents. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) =>
          dispatch(commonActions.setGlobalErrorMessage(resolvedErrorMessage)),
      })
    }

    dispatch(documentsActions.fetchDocumentsWorking(false))

    done()
  },
})
