/*
 * Keep in mind that store2 allows us to safely use window.sessionStorage
 * value-types like number and boolean, whereas using window.sessionStorage
 * without store2 (or a similar package), requires all values set in
 * sessionStorage to be of value string.
 */
import store from 'store2'

export default {
  get(key) {
    return store.session.get(key)
  },

  set(key, value) {
    return store.session.set(key, value)
  },

  remove(key) {
    if (typeof key === 'string') {
      return store.session.remove(key)
    }
    return key.map((value) => this.remove(value))
  },

  clear() {
    return store.session.clear()
  },
}
