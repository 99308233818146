import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

import { createLogic } from 'redux-logic'

import questionnaireSelectors from '../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import commonTypes from '../commonTypes'
import fillAvailableWorlds from './utils/fillAvailableWorlds'

export default createLogic({
  name: 'setAvailableWorlds',
  type: commonTypes.SET_AVAILABLE_WORLDS,
  transform({ action, getState }, next) {
    const actionClone = _cloneDeep(action)

    const questionnairesStarted =
      questionnaireSelectors.getQuestionnairesStarted(getState().questionnaire)

    const availableWorlds = fillAvailableWorlds({
      questionnairesStarted,
      answerCache: actionClone.payload.answerCache,
    })

    _set(actionClone, 'payload.availableWorlds', availableWorlds)

    next(actionClone)
  },

  process(_, __, done) {
    done()
  },
})
