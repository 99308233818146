import appointeesTypes from './appointeesTypes'

const initialState = {
  appointees: [],
  working: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case appointeesTypes.GET_APPOINTEES_WORKING:
      return { ...state, working: action.payload.working }

    case appointeesTypes.SET_APPOINTEES:
      return { ...state, appointees: action.payload.appointees }

    default:
      return state
  }
}
