import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Retraits d'un Fonds enregistré de revenu de retraite (FERR)

Une personne doit commencer à retirer des fonds de son Fonds enregistré de revenu de retraite (FERR) l'année suivant son ouverture. Il n'y a pas de montant maximal pouvant être retiré d'un FERR en une année, mais il y a un minimum basé sur l'âge de la personne. Le pourcentage du FERR devant être versé chaque année augmente progressivement avec le temps.

#### Que se passe-t-il avec un FERR en cas de décès ?

Le propriétaire d'un FERR désigne généralement un "annuitant successeur" ou un ou plusieurs "bénéficiaires désignés" du FERR lorsque le compte est ouvert. Si le propriétaire décède, le FERR est distribué directement à ces personnes et ne fait pas partie de la succession de la personne. C'est pourquoi votre FERR n'est pas mentionné dans votre testament.

S'il n'y a pas d'annuitant successeur ou de bénéficiaires désignés (ou s'ils sont tous décédés avant le propriétaire du FERR), le FERR est versé à la succession de la personne et est distribué selon les modalités du testament de l'individu, ainsi que les autres actifs.

#### FERR et impôts

Lorsqu'une personne retire des fonds de son FERR au cours de sa vie, ces montants sont inclus dans le revenu imposable de l'année où ils sont reçus. Pendant ce temps, tous les actifs qui ne sont pas retirés du FERR continuent de croître et de générer des revenus de manière différée sur le plan fiscal.

Lorsque le propriétaire d'un FERR décède, la valeur restante du FERR au moment du décès est incluse dans le revenu imposable de la personne pour l'année du décès. L'impôt est payable par la succession de la personne. Cependant, il existe des cas où l'impôt peut être reporté jusqu'à plus tard. La situation la plus courante est lorsque le conjoint ou le conjoint de fait est désigné comme annuitant successeur ou bénéficiaire désigné unique du FERR.

Si le conjoint/partenaire est un annuitant successeur, il/elle peut reprendre le FERR du conjoint/partenaire décédé. Si le conjoint/partenaire est un bénéficiaire désigné, il/elle peut transférer le solde du FERR dans son propre REER ou FERR. Ce transfert ne créera pas de conséquences fiscales immédiates et permettra de reporter l'impôt jusqu'au décès de ce conjoint/conjoint de fait.

#### Quelles démarches puis-je entreprendre ?

S'il n'y a pas d'annuitant successeur désigné sur votre FERR, il est important de savoir si les bénéficiaires désignés de votre FERR sont les mêmes ou différents des bénéficiaires de votre succession. C'est parce que c'est la succession - et non les bénéficiaires désignés - qui doit payer l'impôt associé au FERR. Si votre succession doit payer beaucoup d'impôts en raison de votre FERR, les bénéficiaires de votre succession peuvent se retrouver avec moins que prévu.

Si vous n'êtes pas sûr d'avoir nommé un annuitant successeur sur votre FERR, ou de qui vous avez nommé comme bénéficiaires désignés sur votre FERR, vous pouvez contacter votre institution financière pour vous renseigner.
    `
    : `
#### RRIF Withdrawals

A person must start withdrawing from their Registered Retirement Income Fund (RRIF) the year after they open it. There is no maximum amount that can be withdrawn from a RRIF in any year, but there is a minimum that is based on the person's age. The percentage of the RRIF that needs to be paid out each year gradually increases over time.

#### What Happens to a RRIF on Death?

The owner of an RRIF usually names a “successor annuitant” or one or more "designated beneficiaries" of the RRIF when the account is opened. If the owner dies, the RRSP is distributed directly to these individuals, and does not end up as part of the person's estate. That is why your RRIF is not referenced in your Will.

If there is no successor annuitant or designated beneficiaries (or if they all died before the owner of the RRIF), the RRIF is paid to the person's estate and is distributed according to the terms of the individual's Will, along with the other assets.

#### RRIFs and Taxes

As someone withdraws funds from their RRIF during their lifetime, these amounts are included as taxable income in the year they are received. In the meantime, any assets that are not withdrawn from the RRIF continue to grow and earn income on a tax-deferred basis.

When the owner of a RRIF dies, the remaining value of the RRIF at the time of death is included in the person's taxable income in their year of death. The tax is payable by the person's estate. However, there are some cases in which the tax can be deferred until later. The most common situation is where a spouse or common-law partner is named as the successor annuitant or the sole designated beneficiary of the RRIF.

If the spouse/partner is a successor annuitant, he/she can take over the deceased spouse/partner's RRIF. If the spouse/partner is a designated beneficiary, he/she can transfer the remainder of the RRIF to his/her own RRSP or RRIF. This transfer will not create any immediate tax consequences, and allow the deferral to continue until the death of that spouse/partner.

#### What Steps Can I Take?

If there is no successor annuitant named on your RRIF, it is important to know whether the designated beneficiaries of your RRIF are the same or different as the beneficiaries of your estate. This is because it is the estate — not the designated beneficiaries — that must pay the tax associated with the RRIF. If your estate has to pay lots of tax because of your RRIF, the beneficiaries of your estate may end up with less than you expect.

If you are unsure whether you've named a successor annuitant on your RRIF, or who you have named as the designated beneficiaries on your RRIF, you can contact your financial institution to inquire.
`
