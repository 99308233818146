/*
 * This hook doesn't guarantee that the images are loaded and cached
 * before they are rendered on the page, but it does immediately
 * begin preloading and caching the images on component mount so that
 * the images are available as quickly as possible.
 */

import { useEffect } from 'react'

const loadImage = (imageUrl: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.src = imageUrl
    image.onload = () => resolve(image)
    image.onerror = (err) => reject(err)
  })

interface Props {
  imageUrls: string[]
  onError?: (err: Error) => void
}

export default ({ imageUrls, onError }: Props) => {
  useEffect(() => {
    Promise.all(imageUrls.map((imageUrl: string) => loadImage(imageUrl))).catch(
      onError,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
