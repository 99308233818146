import React, { useState } from 'react'

import ButtonComponent from '../../../../../../../common/components/atoms/Button'
import PdfPreviewModal from '../../../../../../../common/components/PdfPreviewModal'
import usePdfPreviewDoc, {
  DownloadStatus as PdfPreviewDownloadStatus,
} from '../../../../../../../common/components/PdfPreviewModal/hooks/usePdfPreviewDoc'
import amplitude from '../../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import { InvestmentAccountTypes } from '../../../../../../Questionnaire/components/Question/InvestmentAccounts/utils/getInvestmentAccountsOptions'
import { Button } from '../../types/button.types'
import BonusDocQuestionnaireModal from '../modals/BonusDocQuestionnaireModal'
import ContactModal from '../modals/ContactModal'
import CustomModal from '../modals/CustomModal'
import InformationalModal from '../modals/InformationalModal'
import StaticDocDownloadModal from '../modals/StaticDocDownloadModal'

interface Props extends Button {
  triggerObj: any
  answerStore: any
  onClick?: () => void
  autoOpenModal?: boolean
  generatedDocument?: any
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const ModalActionButton = ({
  action,
  parentName,
  triggerObj,
  answerStore,
  onClick = undefined,
  generatedDocument = undefined,
  dispatchSetGlobalErrorMessage,
  ...props
}: Props) => {
  // TS-TODO: remove @ts-ignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [showModal, setShowModal] = useState(!!action?.autoOpenModal)
  const handleCloseModal = () => setShowModal(false)

  const {
    file: pdfPreviewFile,
    status: pdfPreviewStatus,
    handleDownloadPdfPreviewDoc,
  } = usePdfPreviewDoc({
    dispatchSetGlobalErrorMessage,
    onSuccess: () => setShowModal(true),
  })

  return (
    <>
      <ButtonComponent
        size="xs"
        iconFontSize="1.2em"
        sx={{ maxHeight: '37px' }}
        onClick={async () => {
          const {
            hasResps,
            hasLifeInsurance,
            hasForeignProperty,
            canadianRealEstate,
            investmentAccounts = [],
          } = answerStore

          const commonAmplitudeProps = {
            label: props.label,
            parentName,
            hasResps,
            hasLifeInsurance,
            hasForeignProperty,
            canadianRealEstate,
            hasRrsp: investmentAccounts.includes(InvestmentAccountTypes.RRSP),
            hasFhsa: investmentAccounts.includes(InvestmentAccountTypes.FHSA),
            hasTfsa: investmentAccounts.includes(InvestmentAccountTypes.TFSA),
            hasRrif: investmentAccounts.includes(InvestmentAccountTypes.RRIF),
          }

          if (action.type === 'modal' && action.modalType === 'preview') {
            amplitude.sendEvent('ClickedDocumentsModalActionButton', {
              modalType: action.modalType,
              ...commonAmplitudeProps,
            })

            await handleDownloadPdfPreviewDoc(
              action.previewModalType === 'generated'
                ? { docId: generatedDocument._id }
                : { docType: action.docType },
            )
          } else {
            amplitude.sendEvent('ClickedDocumentsModalActionButton', {
              actionType: action.type,
              ...commonAmplitudeProps,
            })

            setShowModal(true)
          }
          onClick?.()
        }}
        spinnerPadding="0.266rem 1.05rem"
        spinnerColor={themeColorTypes.GREY}
        working={pdfPreviewStatus === PdfPreviewDownloadStatus.WORKING}
        {...props}
      />

      <>
        {action.type === 'modal' &&
          (action.modalType === 'staticDocDownload' ? (
            <StaticDocDownloadModal
              show={showModal}
              docType={action.docType}
              answerStore={answerStore}
              onClose={handleCloseModal}
              dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
            />
          ) : action.modalType === 'bonusDocQuestionnaire' ? (
            <BonusDocQuestionnaireModal
              show={showModal}
              docType={action.docType}
              onClose={handleCloseModal}
            />
          ) : action.modalType === 'preview' ? (
            <PdfPreviewModal
              show={showModal}
              file={pdfPreviewFile}
              onClose={handleCloseModal}
              omitPageNumbers={action.omitPageNumbers}
            />
          ) : action.modalType === 'contact' ? (
            <ContactModal
              show={showModal}
              data={action.data}
              onClose={handleCloseModal}
            />
          ) : action.modalType === 'custom' ? (
            <CustomModal
              show={showModal}
              data={action.data}
              onClose={handleCloseModal}
              setShowModal={setShowModal}
            />
          ) : (
            <InformationalModal
              show={showModal}
              data={action.data}
              triggerObj={triggerObj}
              answerStore={answerStore}
              onClose={handleCloseModal}
            />
          ))}
      </>
    </>
  )
}

export default ModalActionButton
