// TS-TODO: type this file properly
import _flattenDeep from 'lodash/flattenDeep'
import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'

import mingo from 'mingo'

import { bonusDocFieldNameRegex } from '../../../../../../../Questionnaire/utils/validation/patterns'

// TS-TODO: type this properly
interface Question {
  [key: string]: any
}

type TestObj = Record<string, any>

const isTriggeredQuestion = ({
  question,
  testObj = {},
}: {
  question: Question
  testObj: TestObj
}) =>
  // If question does NOT have trigger it's considered auto-triggered, otherwise test the trigger
  !question.trigger || new mingo.Query(question?.trigger).test(testObj)

const cleansedBonusAnswerStore = ({
  answerStore,
  triggeredQuestions,
}: {
  answerStore: TestObj
  triggeredQuestions: Question[]
}) => {
  const theTriggeredFields = _flattenDeep(
    triggeredQuestions.map((question) => question.fields),
  )

  return _pickBy(answerStore, (_, key) =>
    theTriggeredFields.find((f) => f.name === key),
  )
}

export const triggeredCoreQuestions = ({
  questions,
  coreAnswerStore,
}: {
  questions: Question[]
  coreAnswerStore: TestObj
}) =>
  questions.filter((question) =>
    question.fields.some(
      (field: any) =>
        isTriggeredQuestion({ question, testObj: coreAnswerStore }) &&
        !bonusDocFieldNameRegex.test(field.name),
    ),
  )

export const triggeredAndCleansed = ({
  questions,
  testObj,
  initialTriggeredCoreQuestions,
}: {
  questions: Question[]
  testObj: TestObj
  initialTriggeredCoreQuestions: Question[]
}) => {
  const triggeredQuestions = questions.filter(
    (question) =>
      isTriggeredQuestion({ question, testObj }) ||
      initialTriggeredCoreQuestions.some(({ id }) => id === question.id),
  )

  return {
    triggeredQuestions,
    cleansedAnswerStore: cleansedBonusAnswerStore({
      answerStore: testObj,
      triggeredQuestions,
    }),
  }
}

/*
 * This validation algorithm only checks for the truthy existence
 * of a required field in the passed in answerStore. It doesn't
 * handle more complex validation logic.
 */
export const unansweredRequiredFields = (
  incomingTriggeredQuestions: Question[],
  answerStore: TestObj,
) =>
  _flattenDeep(
    incomingTriggeredQuestions.map((question: Question) => question.fields),
  )
    .filter(({ optional, name }) => !optional && !_get(answerStore, name))
    .map(({ name }) => name)

export const groupByUpsertAnswerStore = (incomingAnswerStore: TestObj) =>
  Object.keys(incomingAnswerStore).reduce(
    (acc, key) => {
      // If the field is a bonusDoc field (not 'core' answerStore)
      if (bonusDocFieldNameRegex.test(key)) {
        return {
          ...acc,
          bonusDocsAnswerStore: {
            ...acc.bonusDocsAnswerStore,
            [key]: incomingAnswerStore[key],
          },
        }
      }
      // Otherwise, we assume the field is a 'core' answerStore field
      return {
        ...acc,
        coreAnswerStore: {
          ...acc.coreAnswerStore,
          [key]: incomingAnswerStore[key],
        },
      }
    },
    { bonusDocsAnswerStore: {}, coreAnswerStore: {} },
  )
