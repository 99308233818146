import React from 'react'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import rbcLogo from '../../../assets/images/rbc-logo.png'
import ValueProposition from './ValueProposition'

const RbcValueProposition = () => {
  const { t } = useTranslation()

  return (
    <ValueProposition
      hideBottomBorder
      icon={
        <Box sx={{ width: '4.375rem' }}>
          <img
            src={rbcLogo}
            alt="RBC Logo"
            data-testid="get-started-rbc-logo"
          />
        </Box>
      }
      label={t('components:GetStarted.valueProps.rbc.valueProp1.label')}
      description={t(
        'components:GetStarted.valueProps.rbc.valueProp1.description',
      )}
    />
  )
}

export default RbcValueProposition
