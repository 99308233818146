import { Province } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import {
  ONTARIO_TERMS,
  OntarioTerm,
  provinceDictionary,
} from '../utils/provinceDictionary'

const useProvinceDictionary = () => {
  /*
   * We call useTranslation() here without actually using it because we
   * want to automatically force a re-render of any components using this
   * hook when the language changes. This allows us to use i18n's t() method
   * in provinceDictionary.ts and get 'in-place' translations without the
   * need for refreshing the page/app.
   */
  const { t } = useTranslation()

  return {
    provinceDictionary: (
      ontarioTerm: OntarioTerm | string,
      province: Province,
    ) => provinceDictionary(ontarioTerm, province),

    /*
     * Util function that takes a string and returns translated (province and language) result.
     * This useful for when a string/term needs to be translated based on province and language.
     */
    provinceLanguageTranslate: (
      translationKey: string,
      string: string,
      province: Province,
    ) => {
      /*
       * If the string is an ONTARIO_TERM, use the provinceDictionary output
       * which will translate based on both province and language.
       */
      if (
        province &&
        Object.values(ONTARIO_TERMS).some(
          (ontarioTerm) => ontarioTerm === string,
        )
      ) {
        return provinceDictionary(string, province)
      }
      // If the string is NOT an ONTARIO_TERM, translate using the translationKey
      if (translationKey) {
        return t(translationKey)
      }
      // If nothing above applies, return the string
      return string
    },
  }
}

export default useProvinceDictionary
