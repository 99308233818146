import React from 'react'

import { Box, CircularProgress, ListItem } from '@mui/material'

const LoadingListItem = () => (
  <ListItem>
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pb: '1rem',
        pt: '1.8rem',
      }}
    >
      <CircularProgress size="1.8rem" />
    </Box>
  </ListItem>
)

export default LoadingListItem
