import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { useGoogleLogin } from '@react-oauth/google'

import questionnaireActions from '../../../../scenes/Questionnaire/ducks/questionnaire/questionnaireActions'
import commonActions from '../../../ducks/commonActions'
import commonSelectors from '../../../ducks/commonSelectors'
import localStore from '../../../lib/localStore'
import afterLogin from '../../../utils/afterLogin'
import useGoogleAuth from './hooks/useGoogleAuth'

const withGoogleAuth = (WrappedComponent) => {
  const NewGoogleAuthComponent = ({
    type,
    mailingList,
    loginRedirect,
    questionnaireId,
    dispatchOauthSuccess,
    dispatchSetGlobalErrorMessage,
    ...props1
  }) => {
    const { mutate: handleGoogleAuth, isLoading } = useGoogleAuth({
      dispatchSetGlobalErrorMessage,
      onSuccess: ({ data }) => {
        if (type === 'createAccount') {
          dispatchOauthSuccess({ email: data?.payload?.email, mailingList })
        }

        afterLogin({
          loginRedirect,
          loginType: 'google',
          preventRedirect: type === 'createAccount',
        })
      },
    })

    const googleLogin = useGoogleLogin({
      flow: 'implicit',
      select_account: false,
      onSuccess({ access_token: accessToken }) {
        return handleGoogleAuth({ questionnaireId, accessToken })
      },
    })

    return (
      <WrappedComponent
        {...props1}
        type={type}
        onClick={() => {
          if (window.Cypress) {
            const accessToken = localStore.get('cypressGoogleAccessToken')
            handleGoogleAuth({ accessToken, questionnaireId })
          } else {
            googleLogin()
          }
        }}
        working={isLoading}
      />
    )
  }

  NewGoogleAuthComponent.propTypes = {
    mailingList: PropTypes.bool,
    loginRedirect: PropTypes.string,
    questionnaireId: PropTypes.string,
    type: PropTypes.oneOf(['login', 'createAccount']).isRequired,
    dispatchOauthSuccess: PropTypes.func.isRequired,
    dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  }

  const mapStateToProps = (state) => ({
    loginRedirect: commonSelectors.getLoginRedirect(state),
  })

  const mapDispatchToProps = (dispatch) => ({
    dispatchSetGlobalErrorMessage: (msg) =>
      dispatch(commonActions.setGlobalErrorMessage(msg)),
    dispatchOauthSuccess: ({ email, mailingList }) =>
      dispatch(questionnaireActions.oauthSuccess({ email, mailingList })),
  })

  return connect(mapStateToProps, mapDispatchToProps)(NewGoogleAuthComponent)
}

export default withGoogleAuth
