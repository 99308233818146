import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CONSTANTS, utils } from '@epilogue/common'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import cn from 'classnames'
import i18n from 'i18next'
import { nanoid } from 'nanoid'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Amex } from '../../../../../../../../common/assets/images/amex.svg'
import { ReactComponent as Mastercard } from '../../../../../../../../common/assets/images/mastercard.svg'
import PoweredByStripe from '../../../../../../../../common/assets/images/poweredByStripe.png'
import { ReactComponent as Visa } from '../../../../../../../../common/assets/images/visa.svg'
import Button from '../../../../../../../../common/components/atoms/Button'
import Text from '../../../../../../../../common/components/atoms/Text'
import TextInput from '../../../../../../../../common/components/atoms/TextInput'
import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../common/request'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'
import { postalOrZipCodeRegex } from '../../../../../../../Questionnaire/utils/validation/patterns'
import PaymentContext from '../../../context/PaymentContext'

const useStyles = makeStyles({
  form: {
    '& input, & label': {
      fontSize: '15.5px',
    },
  },

  payButton: {
    height: '2.75rem',
  },

  borderBottom: {
    borderBottom: `1px solid ${
      themeColors[themeColorTypes.GREY][themeColorVariants.MAIN]
    }`,
    '&:hover': {
      borderColor: themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
    },
  },

  cardLogo: {
    transition: 'filter 0.37s',

    '&.visa': {
      filter: ({ cardBrand }) =>
        cardBrand === 'mastercard' || cardBrand === 'amex'
          ? 'grayscale(1)'
          : 'none',
    },
    '&.mastercard': {
      filter: ({ cardBrand }) =>
        cardBrand === 'visa' || cardBrand === 'amex' ? 'grayscale(1)' : 'none',
    },
    '&.amex': {
      filter: ({ cardBrand }) =>
        cardBrand === 'visa' || cardBrand === 'mastercard'
          ? 'grayscale(1)'
          : 'none',
    },
  },
})

const stripeInputStyles = {
  style: {
    base: {
      fontFamily: 'roboto',
      fontSize: '15.5px',
      color: themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
      '::placeholder': {
        color: themeColors[themeColorTypes.GREY][themeColorVariants.DARK],
      },
    },
    invalid: { '::placeholder': { color: '#c33b1c' } },
  },
}

const errorMessageTypes = {
  NAME: 'name',
  PAYMENT: 'payment',
  POSTAL_CODE: 'postalCode',
}

const createIdempotencyKey = () => nanoid()

const PaymentForm = ({
  paymentOption,
  paymentPlanId,
  downPaymentAmount,
  installments = [],
}) => {
  const {
    promoCode,
    addOnCart,
    answerStore,
    dispatchSetPayments,
    chargeInfo: { displayTotal },
  } = useContext(PaymentContext)

  const { firstName, lastName } = answerStore

  const [displayError, setDisplayError] = useState(false)
  const [nameVal, setNameVal] = useState(
    firstName && lastName ? `${firstName} ${lastName}` : '',
  )
  const [cardBrand, setCardBrand] = useState()
  const [working, setWorking] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    type: undefined,
    value: '',
  })
  const [postalCodeVal, setPostalCodeVal] = useState('')
  const [idempotencyKey, setIdempotencyKey] = useState(createIdempotencyKey())

  const stripe = useStripe()
  const elements = useElements()

  const { t } = useTranslation()

  const handlePaymentSubmit = async (e) => {
    e.preventDefault()
    setDisplayError(true)

    // ensure that the name field is valid
    if (!nameVal) {
      setErrorMessage({
        type: errorMessageTypes.NAME,
        value: t(
          'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.validation.nameField',
        ),
      })
    }
    // ensure that the postal code field is valid
    else if (!postalOrZipCodeRegex.test(postalCodeVal)) {
      setErrorMessage({
        type: errorMessageTypes.POSTAL_CODE,
        value: t(
          'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.validation.postalCode',
        ),
      })
    } else {
      // flushSync prevents batching state updates on fast double-clicks (Cypress)
      flushSync(() => setWorking(true))

      const fallbackErrMsg =
        'Unknown payment error occured. Please refresh your browser and try again.'

      try {
        if (!stripe || !elements) {
          throw new Error(
            'Something went wrong. Please refresh you browser and try again.',
          )
        }

        // creates Stripe token to be passed to our backend
        const { token, error } = await stripe.createToken(
          elements.getElement(CardNumberElement),
          {
            name: nameVal,
            currency: 'cad',
            address_zip: postalCodeVal,
            address_country: 'CA', // Canada's country code
          },
        )

        if (token) {
          const baseData = {
            token,
            promoCode,
            answerStore,
            addOns: addOnCart,
          }

          const requestInfo = {
            [CONSTANTS.paymentOptions.ONE_TIME_PAYMENT]: {
              url: PATHS.PAYMENT,
              data: { ...baseData, idempotencyKey },
            },
            [CONSTANTS.paymentOptions.PAYMENT_PLAN]: {
              url: PATHS.PAYMENT_START_PAYMENT_PLAN,
              data: { ...baseData, paymentPlanId, installments },
            },
          }

          const res = await request({
            method: 'POST',
            ...requestInfo[paymentOption],
          })

          // updates user's payments array
          dispatchSetPayments(_get(res, ['data', 'payload', 'payments']))
        } else {
          setErrorMessage({
            type: errorMessageTypes.PAYMENT,
            value: error.message || fallbackErrMsg,
          })
        }
      } catch (error) {
        /*
         * Partial.ly sends 400 errors and Stripe sends 402 errors when they
         * decline a payment, which we do not need reported. For example,
         * Partial.ly/Stripe will send a 400/402 when the user's CVC code is
         * incorrect or the user has insufficient funds. We do not need to
         * know about these kinds of errors.
         */
        const reportError =
          error?.response?.status !==
          (paymentOption === CONSTANTS.paymentOptions.PAYMENT_PLAN ? 400 : 402)

        errorHandler({
          error,
          reportError,
          fallbackErrorMessage: fallbackErrMsg,
          onError: (resolvedErrorMessage) => {
            /*
             * Create new idempotency key here because if a user's
             * first request fails, we'll need a new key so that if
             * they're second request is valid, it doesn't fail because
             * we're sending the same idempotency key.
             */
            setIdempotencyKey(createIdempotencyKey())
            setErrorMessage({
              type: errorMessageTypes.PAYMENT,
              value: resolvedErrorMessage,
            })
          },
        })
      }
      setWorking(false)
      setDisplayError(false)
    }
  }

  const classes = useStyles({ cardBrand })

  return (
    <Box>
      <Box mb="1.1rem">
        {/* credit card logos */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Visa className={cn(classes.cardLogo, 'visa')} />
          <Mastercard className={cn(classes.cardLogo, 'mastercard')} />
          <Amex className={cn(classes.cardLogo, 'amex')} />
        </Box>

        {/* error message */}
        {errorMessage?.value && (
          <Box pt="1.4rem" pb="0.3rem">
            <Text align="center" display="block" color={themeColorTypes.RED}>
              {errorMessage.value}
            </Text>
          </Box>
        )}
      </Box>

      <form className={classes.form} onSubmit={handlePaymentSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <TextInput
              disabled={working}
              value={nameVal}
              placeholder={t(
                'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.nameOnCard',
              )}
              onInputChange={(value) => {
                setNameVal(value)
                if (displayError && value) {
                  setDisplayError(false)
                  setErrorMessage({})
                }
              }}
              validationMessage=""
              isInvalid={displayError && !nameVal}
            />
          </Box>

          <Box>
            <TextInput
              disabled={working}
              value={postalCodeVal}
              placeholder={t(
                'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.postalCode',
              )}
              onInputChange={(value) => {
                setPostalCodeVal(value)
                if (displayError && postalOrZipCodeRegex.test(value)) {
                  setDisplayError(false)
                  setErrorMessage({})
                }
              }}
              validationMessage=""
              isInvalid={
                displayError && !postalOrZipCodeRegex.test(postalCodeVal)
              }
            />
          </Box>
        </Box>

        <Box
          mt="1.2rem"
          mb="2.2rem"
          pb="0.4rem"
          className={classes.borderBottom}
        >
          <CardNumberElement
            onChange={({ brand }) => setCardBrand(brand)}
            options={{
              ...stripeInputStyles,
              disabled: working,
              placeholder: t(
                'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.ccNumber',
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Box
            sx={{ pb: '0.4rem', width: '45%' }}
            className={classes.borderBottom}
          >
            <CardExpiryElement
              options={{ ...stripeInputStyles, disabled: working }}
            />
          </Box>

          <Box
            sx={{ pb: '0.4rem', width: '30%' }}
            className={classes.borderBottom}
          >
            <CardCvcElement
              options={{ ...stripeInputStyles, disabled: working }}
            />
          </Box>
        </Box>

        <Box
          my="1.9rem"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" mb="-0.1rem">
            <Box mr="0.2rem" mb="0.2rem">
              <LockOutlinedIcon style={{ fontSize: '0.8rem' }} />
            </Box>
            <Text align="center" display="block" size="xxxs">
              {t(
                'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.securePayments',
              )}
            </Text>
          </Box>
          <Box width={80}>
            <img src={PoweredByStripe} alt="Stripe Logo" />
          </Box>
        </Box>

        <Button
          fullWidth
          type="submit"
          working={working}
          variant="contained"
          spinnerPadding="0.344rem 0"
          className={classes.payButton}
          color={themeColorTypes.ACCENT_1}
          spinnerColor={themeColorTypes.BRAND}
          data-testid="payment-form-pay-button"
          label={
            paymentOption === CONSTANTS.paymentOptions.PAYMENT_PLAN
              ? t(
                  'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.paymentButtonLabel.case1',
                  {
                    amount: utils.toDollar(downPaymentAmount, 2, i18n.language),
                  },
                )
              : t(
                  'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.paymentButtonLabel.case2',
                  {
                    amount: utils.toDollar(displayTotal, 2, i18n.language),
                  },
                )
          }
        />

        <Box maxWidth="290px" mt="1.2rem" mx="auto">
          <Text
            size="xxs"
            align="center"
            display="block"
            color={themeColorTypes.GREY}
          >
            {t(
              'payment:components.PaymentBlock.components.PaymentOptions.PaymentForm.securityDisclaimer',
            )}
          </Text>
        </Box>
      </form>
    </Box>
  )
}

PaymentForm.propTypes = {
  paymentOption: PropTypes.oneOf(Object.values(CONSTANTS.paymentOptions))
    .isRequired,
  paymentPlanId: PropTypes.string,
  downPaymentAmount: PropTypes.number,
  installments: PropTypes.arrayOf(
    PropTypes.shape({ amount: PropTypes.number, scheduled: PropTypes.string }),
  ),
}

export default PaymentForm
