import React from 'react'

import _isEmpty from 'lodash/isEmpty'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Divider, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as RbcInsuranceLogo } from '../../../../../../../../common/assets/images/rbc-insurance-logo.svg'
import Button from '../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../common/components/atoms/Heading'
import Link from '../../../../../../../../common/components/atoms/Link'
import Text from '../../../../../../../../common/components/atoms/Text'
import { themeColorTypes } from '../../../../../../../../common/styles/muiTheme'
import useFetchInsuranceQuotes from '../hooks/useFetchInsuranceQuotes'
import { InsuranceQuotesParams } from '../quote.types'
import QuoteCard from './QuoteCard'

const lineHeight = '1.45'

interface Props {
  sectionPaddingX: string
  handlePageForward: () => void
  insuranceQuotesParams: InsuranceQuotesParams
  dispatchSetGlobalErrorMessage: (message: string) => void
}

const LifeInsuranceIntro = ({
  sectionPaddingX,
  handlePageForward,
  insuranceQuotesParams,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { isInitialLoading: isWorking, data: quotes } = useFetchInsuranceQuotes(
    { insuranceQuotesParams, dispatchSetGlobalErrorMessage },
  )

  // Quote card comes from second quote item, if available
  const displayedQuoteCardIndex = quotes?.length > 1 ? 1 : 0
  const coverageAmount = quotes?.[displayedQuoteCardIndex]?.amount
  const pricePerMonth = quotes?.[displayedQuoteCardIndex]?.quote?.monthly

  const isMobile = useMediaQuery('(max-width:600px)')

  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{
          py: '2rem',
          display: 'flex',
          px: sectionPaddingX,
          flexDirection: 'column',
        }}
      >
        {/* Upper body */}
        <Box>
          <Text
            size="lg"
            variant="paragraph"
            sx={{ mb: '1.5rem', lineHeight }}
            align={isMobile ? 'center' : 'left'}
          >
            {t(
              'documents:components.LifeInsuranceQuotes.components.LifeInsuranceIntro.description1',
            )}
          </Text>

          <Text
            size="lg"
            variant="paragraph"
            sx={{ lineHeight }}
            align={isMobile ? 'center' : 'left'}
          >
            {t(
              'documents:components.LifeInsuranceQuotes.components.LifeInsuranceIntro.description2.item1',
            )}{' '}
            <Link
              openNewTab
              underline="always"
              href="https://epiloguewills.com/blog/lets-talk-term-life-insurance-a-financial-safety-net-for-the-future"
            >
              {t(
                'documents:components.LifeInsuranceQuotes.components.LifeInsuranceIntro.description2.item2',
              )}
            </Link>
          </Text>

          <Divider sx={{ my: '2rem' }} />
        </Box>

        {/* Lower body */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          {/* Lower body left */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pr: isMobile ? '0' : '2.5rem',
              width: isMobile ? '100%' : '60%',
            }}
          >
            <Heading
              variant="h3"
              sx={{ mb: '1.5rem' }}
              align={isMobile ? 'center' : 'left'}
            >
              {t(
                'documents:components.LifeInsuranceQuotes.components.LifeInsuranceIntro.label',
              )}
            </Heading>

            <Box sx={{ display: isMobile ? 'none' : 'block' }}>
              <RbcInsuranceLogo />
              <Text
                size="xxxs"
                sx={{ mt: '0.3rem' }}
                color={themeColorTypes.GREY}
              >
                {t(
                  'documents:components.LifeInsuranceQuotes.components.LifeInsuranceIntro.logoDescription',
                )}
              </Text>
            </Box>
          </Box>

          {/* Lower body right */}
          <Box
            sx={{
              display: 'flex',
              maxWidth: '197px',
              flexDirection: 'column',
              width: isMobile ? '100%' : '40%',
              alignItems: isMobile ? 'center' : 'flex-end',
            }}
          >
            <QuoteCard
              centerText={isMobile}
              onClick={handlePageForward}
              pricePerMonth={pricePerMonth}
              coverageAmount={coverageAmount}
              isWorking={isWorking || _isEmpty(quotes)}
            />

            <Box
              sx={{
                mt: '1.75rem',
                maxWidth: '150px',
                alignItems: 'center',
                flexDirection: 'column',
                display: isMobile ? 'flex' : 'none',
              }}
            >
              <RbcInsuranceLogo />
              <Text
                size="xxxs"
                align="center"
                sx={{ mt: '0.3rem' }}
                color={themeColorTypes.GREY}
              >
                {t(
                  'documents:components.LifeInsuranceQuotes.components.LifeInsuranceIntro.logoDescription',
                )}
              </Text>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mb: '0.5rem',
            width: '100%',
            display: 'flex',
            mt: isMobile ? '2rem' : '3rem',
            justifyContent: isMobile ? 'center' : 'flex-end',
          }}
        >
          <Button
            variant="contained"
            onClick={handlePageForward}
            endIcon={<ArrowForwardIcon />}
            label={t(
              'documents:components.LifeInsuranceQuotes.components.LifeInsuranceIntro.buttonLabel',
            )}
            data-testid="life-insurance-to-page-two-button"
          />
        </Box>
      </Box>
    </>
  )
}

export default LifeInsuranceIntro
