import _isEmpty from 'lodash/isEmpty'

import { createLogic } from 'redux-logic'

import navigationActions from '../navigationActions'
import navigationSelectors from '../navigationSelectors'
import navigationTypes from '../navigationTypes'

export default createLogic({
  name: 'navNextAutomatic',
  type: navigationTypes.NAV_NEXT_AUTOMATIC,

  // eslint-disable-next-line consistent-return
  validate({ getState, action }, allow, reject) {
    const SPECIAL_NAV_NEXT_AUTOMATIC_QUESTION_TYPES = [
      'inheritanceAge',
      'quickRadioWithLink',
    ]

    const question = navigationSelectors.getCurrentQuestion(
      getState().questionnaire,
    )
    const { fields, type } = question
    const { fragment } = action.payload

    if (!fields || _isEmpty(fields) || _isEmpty(fragment)) return reject()

    const withoutPreventCleanseFields = fields.filter(
      (field) => field.type !== 'preventCleanse',
    )
    /*
     * Automatically navigate to next question if all the following are true:
     * 1) there is ONLY one field (after filtering out fields with type:'preventCleanse')
     * 2) question isn't custom question OR is an identified question type
     */
    if (
      withoutPreventCleanseFields.length === 1 &&
      (!type || SPECIAL_NAV_NEXT_AUTOMATIC_QUESTION_TYPES.includes(type))
    ) {
      allow(action)
    } else {
      reject()
    }
  },

  process({ action }, dispatch, done) {
    const { fragment } = action.payload

    dispatch(navigationActions.nextQuestion({ questionFragments: fragment }))
    done()
  },
})
