import React from 'react'

import { Box, Skeleton } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../../../common/components/atoms/Heading'
import DatePicker from '../../../../../../../../../common/components/DatePicker'
import { SelectedDateInfo } from '../../../../../../../../../common/components/DatePicker/datePicker.types'
import useFetchAvailableAppointments from '../hooks/useFetchAvailableAppointments'

interface Props {
  timezone: string
  selectedDate: Date | undefined
  onSelect: (selectedDateInfo: SelectedDateInfo) => void
  dispatchSetGlobalErrorMessage: (message: string) => void
}

const defaultWidth = 350

const BookWitnessingDatePickerView = ({
  timezone,
  onSelect,
  selectedDate,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const maxDaysInFuture = 27

  const { data: appointments, isLoading: isWorking } =
    useFetchAvailableAppointments({
      dispatchSetGlobalErrorMessage,
      appointmentType: 'witnessing',
    })

  const { t } = useTranslation()

  return (
    <Box data-testid="book-witnessing-date-picker-view">
      <Heading align="center" variant="h3" sx={{ mb: '1.7rem' }}>
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingDatePickerView.label',
        )}
      </Heading>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: `${defaultWidth}px`,
        }}
      >
        {!appointments || isWorking ? (
          <Box>
            <Skeleton variant="rectangular" width={defaultWidth} height={25} />
            <Skeleton
              height={15}
              width={defaultWidth}
              variant="rectangular"
              sx={{ mt: '0.93rem' }}
            />
            <Skeleton
              height={37}
              width={defaultWidth}
              variant="rectangular"
              sx={{ mt: '0.93rem' }}
            />
            <Skeleton
              height={37}
              width={defaultWidth}
              variant="rectangular"
              sx={{ mt: '0.93rem' }}
            />
            <Skeleton
              height={37}
              width={defaultWidth}
              variant="rectangular"
              sx={{ mt: '0.93rem' }}
            />
            <Skeleton
              height={37}
              width={defaultWidth}
              variant="rectangular"
              sx={{ mt: '0.93rem' }}
            />
            <Skeleton
              height={37}
              width={defaultWidth}
              variant="rectangular"
              sx={{ mt: '0.93rem' }}
            />
          </Box>
        ) : (
          <DatePicker
            onSelect={onSelect}
            timezone={timezone}
            selectedDate={selectedDate}
            availableTimes={appointments}
            maxDaysInFuture={maxDaysInFuture}
          />
        )}
      </Box>
    </Box>
  )
}

export default BookWitnessingDatePickerView
