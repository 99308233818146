import { useLayoutEffect, useState } from 'react'

import tw from '../styles/tw'

const mediaQueries = [
  {
    query: `(min-width: ${tw.screens.xl})`,
    name: 'xl',
  },
  {
    query: `(min-width: ${tw.screens.lg})`,
    name: 'lg',
  },
  {
    query: `(min-width: ${tw.screens.md})`,
    name: 'md',
  },
  {
    query: `(min-width: ${tw.screens.sm})`,
    name: 'sm',
  },
  {
    query: `(min-width: ${tw.screens.xs})`,
    name: 'xs',
  },
  {
    query: '(min-width: 0px)',
    name: 'xxs',
  },
]

export default () => {
  const [viewportWidth, setViewportWidth] = useState([undefined, undefined])

  useLayoutEffect(() => {
    const updateWidth = () => {
      const matches = mediaQueries.map((mq) => window.matchMedia(mq.query))
      const index = matches.findIndex((m) => m.matches)

      setViewportWidth([window.innerWidth, mediaQueries[index].name])
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return viewportWidth
}

export const breakpoints = {
  xl: Number.parseInt(tw.screens.xl.split('px')[0]),
  lg: Number.parseInt(tw.screens.lg.split('px')[0]),
  md: Number.parseInt(tw.screens.md.split('px')[0]),
  sm: Number.parseInt(tw.screens.sm.split('px')[0]),
  xs: Number.parseInt(tw.screens.xs.split('px')[0]),
}
