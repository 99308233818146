import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import Autocomplete from '@mui/material/Autocomplete'
// import _sortBy from 'lodash/sortBy'

import TextField from '@mui/material/TextField'

const ComboBox = ({
  value = '',
  options,
  placeholder,
  isInvalid,
  onInputChange,
}) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    onInputChange(inputValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <Autocomplete
      data-testid="combo-box"
      fullWidth
      openOnFocus
      autoHighlight
      options={options}
      // options={_sortBy(options, 'highlighted')}
      // groupBy={(option) => (option.highlighted ? '' : '——')}
      getOptionLabel={(option) => option.label}
      onInputChange={(_, theValue) => setInputValue(theValue)}
      onChange={(e, selectedOption) => {
        setInputValue(_get(selectedOption, 'label'))
      }}
      isOptionEqualToValue={(option, selected) =>
        option.value === selected.value
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={isInvalid}
          variant="outlined"
          label={placeholder}
          inputProps={{
            ...params.inputProps,
            value: inputValue || '',
          }}
        />
      )}
    />
  )
}

ComboBox.propTypes = {
  value: PropTypes.string,
  isInvalid: PropTypes.bool,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default ComboBox
