import LogRocket from 'logrocket'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { createLogicMiddleware } from 'redux-logic'

import dashboardOperations from '../../scenes/Dashboard/ducks/operationsMiddleware'
import dashboardReducers from '../../scenes/Dashboard/ducks/reducers'
import questionnaireOperations from '../../scenes/Questionnaire/ducks/operationsMiddleware'
import questionnaireReducers from '../../scenes/Questionnaire/ducks/reducers'
import commonOperations from '../ducks/commonOperations'
import commonReducers from '../ducks/reducers'
import featureFlag, { flagTypes } from '../utils/featureFlag'

const composeEnhancers = featureFlag(flagTypes.REDUX_DEV_TOOLS)
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose

export default (initialState = {}) => {
  const logicMiddleware = createLogicMiddleware([
    ...commonOperations,
    ...dashboardOperations,
    ...questionnaireOperations,
  ])

  return createStore(
    combineReducers({
      common: commonReducers,
      dashboard: dashboardReducers,
      questionnaire: questionnaireReducers,
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(logicMiddleware, LogRocket.reduxMiddleware()),
    ),
  )
}
