import { useState } from 'react'

import download from 'downloadjs'

import amplitude from '../../../../../common/lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import buildSocialDocFilename from './utils/buildSocialDocFilename'

const statusStates = {
  ERROR: 'error',
  SUCCESS: 'success',
  WORKING: 'working',
  INACTIVE: 'inactive',
}

export default ({ answerStore, dispatchSetGlobalErrorMessage }) => {
  const [status, setStatus] = useState(statusStates.INACTIVE)

  const handleDocDownload = async ({ docId }) => {
    setStatus(statusStates.WORKING)

    try {
      const res = await request({
        method: 'GET',
        responseType: 'arraybuffer',
        url: `${PATHS.SOCIAL_DOCUMENT_DOWNLOAD_DOC}/${docId}`,
      })

      download(res.data, buildSocialDocFilename({ answerStore }))
      setStatus(statusStates.SUCCESS)
      // eslint-disable-next-line no-console
      console.log('social media will download success 👍')
      amplitude.sendEvent('DownloadedDocument', { docType: 'social' })
    } catch (error) {
      setStatus(statusStates.ERROR)
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to download document. Please try again later.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    }
  }

  return [statusStates, status, handleDocDownload]
}
