import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { Paragraph } from 'grommet'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Oranges } from '../../../../../common/assets/images/oranges.svg'
import StyledLabel from '../../../../../common/components/atoms/StyledLabel/StyledLabel'
import navigationActions from '../../../ducks/navigation/navigationActions'
import navigationSelectors from '../../../ducks/navigation/navigationSelectors'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import NavigationButtons from '../../molecules/NavigationButtons/NavigationButtons'

const AccountCreated = ({
  dispatchNextQuestion,
  dispatchPreviousQuestion,
  navDirection,
  accountCreated,
  answerStore,
}) => {
  const shouldAutoNav = !!(accountCreated && answerStore.product)

  useEffect(() => {
    if (shouldAutoNav) {
      if (navDirection === 'forward') {
        dispatchNextQuestion()
      } else {
        dispatchPreviousQuestion()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  return (
    <>
      {!shouldAutoNav && (
        <Box display="flex" flexDirection="column">
          <div className="m-auto mb-6 w-28 md:w-32">
            <Oranges />
          </div>

          <div className="max-w-xl flex flex-col items-center m-auto text-center">
            <div className="mb-2">
              <div className="mb-3">
                <StyledLabel>
                  {t('customQuestion:AccountCreated.label')}
                </StyledLabel>
              </div>
              <Paragraph size="smedium">
                {t('customQuestion:AccountCreated.subLabel')}
              </Paragraph>
            </div>

            <NavigationButtons
              nextLabel={t('common:continue')}
              handleButtonClick={dispatchNextQuestion}
            />
          </div>
        </Box>
      )}
    </>
  )
}

AccountCreated.propTypes = {
  accountCreated: PropTypes.bool.isRequired,
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  dispatchNextQuestion: PropTypes.func.isRequired,
  navDirection: PropTypes.oneOf(['forward', 'backward']).isRequired,
  answerStore: PropTypes.shape({ product: PropTypes.string }).isRequired,
}

const mapStateToProps = (state) => ({
  navDirection: navigationSelectors.getNavDirection(state.questionnaire),
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchNextQuestion: () =>
    dispatch(navigationActions.nextQuestion({ questionFragments: {} })),
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreated)
