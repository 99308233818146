import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _omit from 'lodash/omit'

export default ({ fields, prevAnswerStore, currentAnswerStore }) => {
  const prevProvince = _get(prevAnswerStore, 'province')
  const nextProvince = _get(currentAnswerStore, 'province')

  if (!_isEmpty(fields) && prevProvince && prevProvince !== nextProvince) {
    /*
     * creates an array of field names where
     * field.clearAfterProvinceChange is true
     * and then omits those fields, essentially wiping
     * removing any previous answer from the answerStore
     */
    const fieldsToClearAfterProvinceChange = fields
      .filter((f) => f.clearAfterProvinceChange)
      .map((f) => f.name)

    return _omit(currentAnswerStore, fieldsToClearAfterProvinceChange)
  }

  return currentAnswerStore
}
