import { CONSTANTS } from '@epilogue/common'

export default {
  label: ({ answerStore: { partnerFirstName } }) => ({
    tKey: 'interceptModals:forgotPartnerInterceptModal.label',
    options: { partnerFirstName },
  }),

  subLabel: ({ answerStore: { province } }) => ({
    tKey: 'interceptModals:forgotPartnerInterceptModal.subLabel',
    options: { province },
  }),

  primaryButtonLabel: ({ answerStore: { partnerFirstName } }) => ({
    tKey: 'interceptModals:forgotPartnerInterceptModal.primaryButtonLabel',
    options: { partnerFirstName },
  }),

  primaryButtonAction: { type: CONSTANTS.interceptActionTypes.CLOSE_MODAL },
  trigger: {
    allToPartner: 'no',
    remoteDistribution: {
      $not: {
        $elemMatch: { relationship: CONSTANTS.relationshipValues.MY_PARTNER },
      },
    },
  },
  secondaryButtonIcon: true,

  secondaryButtonLabel: {
    tKey: 'interceptModals:forgotPartnerInterceptModal.secondaryButtonLabel',
  },

  secondaryButtonAction: { type: CONSTANTS.interceptActionTypes.NEXT_QUESTION },
}
