import _isEmpty from 'lodash/isEmpty'

import mingo from 'mingo'
import { createLogic } from 'redux-logic'

import differenceObj from '../../../../../common/utils/differenceObj'
import questionnaireSelectors from '../../questionnaire/questionnaireSelectors'
import navigationActions from '../navigationActions'
import navigationSelectors from '../navigationSelectors'
import navigationTypes from '../navigationTypes'

export default createLogic({
  name: 'interceptModal',
  /*
   * We're watching NEXT_QUESTION here and not INTERCEPT_MODAL
   * because we always want to determine whether or not to display
   * the interceptModal before we move to the next question.
   *
   * If the interceptModal does NOT need to be displayed (passes 'validate'),
   * we simply pass the action along without any intervention.
   * However, if the interceptModal DOES need to be displayed,
   * we prevent the nextQuestion action from running and instead
   * display the interceptModal by dispatching the interceptModal
   * action and passing that action the interceptModal data from
   * the current-question.
   */
  type: navigationTypes.NEXT_QUESTION,

  validate({ getState, action }, allow, reject) {
    const state = getState()

    const { questionFragments: incomingQuestionFragments } = action.payload

    const answerStore = questionnaireSelectors.getAnswerStore(
      state.questionnaire,
    )
    const currentQuestion = navigationSelectors.getCurrentQuestion(
      state.questionnaire,
    )

    const answerStoreWithNewFragment = {
      ...answerStore,
      ...incomingQuestionFragments,
    }

    const { interceptModal: currentQuestionInterceptModal } = currentQuestion

    // Get first triggered interceptModal object or return undefined
    const triggeredInterceptModal = currentQuestionInterceptModal?.find(
      ({ trigger }) =>
        new mingo.Query(trigger).test(answerStoreWithNewFragment),
    )

    if (triggeredInterceptModal) {
      // Split triggered interceptModal object into its trigger and data
      const {
        trigger: currentQuestionInterceptModalTrigger,
        ...currentQuestionInterceptModalData
      } = triggeredInterceptModal

      // Get current redux interceptModal data
      const interceptModalData = navigationSelectors.getInterceptModal(
        state.questionnaire,
      )

      if (
        differenceObj(answerStore, incomingQuestionFragments) &&
        _isEmpty(interceptModalData)
      ) {
        // DISPLAY INTERCEPT MODAL
        return reject(
          navigationActions.interceptModal(currentQuestionInterceptModalData),
        )
      }
    }

    // NO INTERCEPT MODAL
    return allow(action)
  },
})
