import React from 'react'
import PropTypes from 'prop-types'

import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'

import { ReactComponent as ErrorIcon } from '../../../assets/images/bubble-error.svg'
import { ReactComponent as Lightbulb } from '../../../assets/images/lightbulb.svg'
import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import Text from '../Text'

const Icon = (type) => {
  switch (type) {
    case 'tip':
      return Lightbulb
    case 'alert':
      return ErrorIcon
    default:
      return Lightbulb
  }
}

const HighlightBubble = ({ variant = 'tip', text, textAlign = 'left' }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const colours = {
    tip: theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.LIGHTER],
    alert: theme.palette[themeColorTypes.ACCENT_1][themeColorVariants.LIGHTEST],
  }

  const accentColours = {
    tip: theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.MAIN],
    alert: theme.palette[themeColorTypes.ACCENT_1][themeColorVariants.LIGHT],
  }

  const StyledIcon = styled(Icon(variant))({
    position: 'absolute',
    right: '5px',
    top: '-20px',
    maxHeight: '30px',
    maxWidth: '30px',
  })

  return (
    <Box
      sx={{
        bgcolor: colours[variant],
        p: isMobile ? '0.5rem' : '1rem',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        marginTop: '25px',
        mt: '25px',
        mb: '1.4rem',
        ':before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '" "',
          position: 'absolute',
          borderRadius: theme.shape.borderRadiusMedium,
          borderBottom: `5px solid ${accentColours[variant]}`,
        },
      }}
    >
      <StyledIcon />
      <Text variant="paragraph" size="xs" align={textAlign}>
        {text}
      </Text>
    </Box>
  )
}

HighlightBubble.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(CONSTANTS.sidebar.bubbleTypes)),
  textAlign: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
}

export default HighlightBubble
