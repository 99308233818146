import i18n from 'i18next'
import { createLogic } from 'redux-logic'

import commonSelectors from '../../../../../common/ducks/commonSelectors'
import data from '../../../../Questionnaire/data'
import questionnaireSelectors from '../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { appointees } from '../../../../Questionnaire/engine'
import dashboardSelectors from '../../dashboard/dashboardSelectors'
import documentsSelectors from '../../documents/documentsSelectors'
import appointeesActions from '../appointeesActions'
import appointeesSelectors from '../appointeesSelectors'
import appointeesTypes from '../appointeesTypes'

export default createLogic({
  name: 'getAppointees',
  type: appointeesTypes.GET_APPOINTEES,
  async process({ getState }, dispatch, done) {
    const state = getState()

    const referrer = questionnaireSelectors.getReferrer(state.questionnaire)
    const appointeesWorking = appointeesSelectors.getWorking(state.dashboard)

    if (!appointeesWorking) {
      dispatch(appointeesActions.getAppointeesWorking(true))
    }

    const theAppointees = appointees({
      data,
      referrer,
      t: i18n.t,
      abTest: questionnaireSelectors.getAbTest(state.questionnaire),
      documents: documentsSelectors.getDocuments(state.dashboard),
      isPartner: dashboardSelectors.getIsPartner(state.dashboard),
      charityPartner: commonSelectors.getCharityPartner(state.common),
      answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
    })

    dispatch(appointeesActions.setAppointees(theAppointees))

    dispatch(appointeesActions.getAppointeesWorking(false))

    done()
  },
})
