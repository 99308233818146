/*
 * Using both 'qs' and 'query-string' libraries because they each handle
 * certain tasks better than the other.
 *
 * The 'qs' library handles nested objects better, and the 'query-string'
 * library handles the parsing of a URL better
 */

import _isString from 'lodash/isString'

import qs from 'qs'
import queryString from 'query-string'

export default {
  parse: (str) => qs.parse(str, { ignoreQueryPrefix: true, depth: 10 }),

  stringify: (obj) => qs.stringify(obj),

  stringifyUrl: ({ url, query }) => queryString.stringifyUrl({ url, query }),

  parseUrl: (url) => {
    if (!_isString(url)) {
      return {}
    }

    const { query } = queryString.parseUrl(url)
    return query
  },
}
