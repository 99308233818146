import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'
import _intersection from 'lodash/intersection'
import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Checkbox from '../../../../../common/components/atoms/Checkbox'

const MultiSelectField = ({
  field,
  answerCache,
  handleFieldFragmentUpdate,
}) => {
  const { name, options } = field

  // Creates an array of values from each of the field's options objects
  const fieldOptionValues = options.map((o) => o.value)

  /*
   * Set initial state using items from the answerCache that are in the
   * field's current list of potential options.
   *
   * Without this cleansing, the answerCache could contain items that are
   * no longer part of the field's options, which would cause the "Save & Continue"
   * button to remain permanently disabled. For example, if the answerCache array was
   * ['rrsp', 'home'], but the current options array no longer contained 'home', this
   * would cause the issue.
   */
  const cleansedAnswerCache = _isEmpty(answerCache[name])
    ? []
    : _intersection(answerCache[name], fieldOptionValues)

  const [checkedArr, setCheckedArr] = useState(cleansedAnswerCache)

  useEffect(() => {
    handleFieldFragmentUpdate({ [name]: checkedArr })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedArr])

  const handleSetFieldValue = (val) => {
    if (checkedArr.includes(val)) {
      const withoutVal = checkedArr.filter((item) => item !== val)
      setCheckedArr(withoutVal)
    } else {
      setCheckedArr([...checkedArr, val])
    }
  }

  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {options?.map(({ value, label }, i) => (
        <Box
          key={value}
          sx={{ pb: options.length - 1 !== i ? '0.75rem' : '0' }}
        >
          <Checkbox
            /*
             * If the label object has a tKey property, use it to translate the label.
             * Otherwise, use the label as is.
             */
            label={t(_get(label, 'tKey', label))}
            checked={checkedArr.includes(value)}
            onChange={() => handleSetFieldValue(value)}
            data-testid={`multi-select-field-value-${value}`}
          />
        </Box>
      ))}
    </Box>
  )
}

MultiSelectField.propTypes = {
  field: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

export default MultiSelectField
