import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Box, useMediaQuery, useTheme } from '@mui/material'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useTranslation } from 'react-i18next'

import { validators } from '../../../scenes/Questionnaire/utils/validation/validation'
import commonActions from '../../ducks/commonActions'
import { errorHandler, PATHS, request } from '../../request'
import { themeColorTypes } from '../../styles/muiTheme'
import Button from '../atoms/Button'
import Heading from '../atoms/Heading'
import Text from '../atoms/Text'
import TextInput from '../atoms/TextInput'
import Header from '../molecules/Header'

const debounceTime = 450

const checkEmailApi = (emailInput) =>
  request({
    method: 'POST',
    url: PATHS.USER_EMAIL_AVAIL,
    data: { email: emailInput },
  })

const debouncedCheckEmail = AwesomeDebouncePromise(checkEmailApi, debounceTime)

const ForgotPassword = ({ dispatchSetGlobalErrorMessage }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [email, setEmail] = useState('')
  const [sendSuccess, setSendSuccess] = useState()
  const [isWorking, setIsWorking] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [emailExists, setEmailExists] = useState()
  const [emailExistsWorking, setEmailExistsWorking] = useState(false)

  const checkValidEmail = async (val) => {
    if (validators.email(val)) {
      setEmailExistsWorking(true)

      try {
        const res = await debouncedCheckEmail(val)

        if (res.data.payload.emailAvailable) {
          setEmailExists(false)
        } else {
          setEmailExists(true)
        }
      } catch (error) {
        errorHandler({
          error,
          reportError: true,
          onError: (resolvedErrorMessage) =>
            dispatchSetGlobalErrorMessage(resolvedErrorMessage),
        })
      } finally {
        setEmailExistsWorking(false)
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsWorking(true)

    try {
      await request({
        method: 'POST',
        url: PATHS.USER_FORGOT_PASSWORD,
        data: { email },
      })

      setSendSuccess(true)
    } catch (error) {
      setSendSuccess(false)
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to send reset password email. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    }
    setIsWorking(false)
  }

  const emailValidAndNotExists = emailValid && emailExists === false

  const { t } = useTranslation()

  return (
    <>
      <Header />

      <Box
        display="flex"
        maxWidth="36rem"
        alignItems="center"
        padding="0 1.25rem"
        margin="2.5rem auto"
        flexDirection="column"
      >
        <Box my="2rem" textAlign="center">
          <Heading variant="h1">{t('pages:ForgotPassword.label')}</Heading>

          <Box marginTop="1rem">
            <Text variant="paragraph" size="md">
              {t('pages:ForgotPassword.subLabel')}
            </Text>
          </Box>
        </Box>

        <Box
          width="100%"
          display="flex"
          component="form"
          alignItems="center"
          position="relative"
          bgcolor="common.white"
          flexDirection="column"
          onSubmit={handleSubmit}
          padding={isMobile ? '3rem 1.8rem' : '3rem 2.5rem'}
          borderRadius={theme.shape.borderRadius}
        >
          {!emailExistsWorking && emailValidAndNotExists && !sendSuccess && (
            <Box
              lineHeight="1"
              position="absolute"
              top={isMobile ? '1.1rem' : '1.7rem'}
              padding={isMobile ? '0 1.2rem' : '0 2rem'}
            >
              <Text
                color="red"
                size="xs"
                align="center"
                data-testid="forgot-password-no-account-warning"
              >
                {t('validation:validation.noAccount')}
              </Text>
            </Box>
          )}

          <Box margin="1.5rem 0" width="100%">
            <TextInput
              autoFocus
              type="email"
              value={email}
              placeholder={t('common:email')}
              disabled={sendSuccess}
              working={emailExistsWorking}
              isInvalid={!emailValid && email.length >= 6}
              validationMessage={t('validation:validation.enterValidEmail')}
              onInputChange={(val) => {
                setEmail(val)
                setEmailValid(validators.email(val))
                checkValidEmail(val)
              }}
            />
          </Box>

          {sendSuccess ? (
            <Text align="center" color="green" size="md">
              {t('pages:ForgotPassword.successMessage', { email })}
            </Text>
          ) : (
            <Box width="9.375rem">
              <Button
                fullWidth
                type="submit"
                label={t('common:submit')}
                variant="contained"
                working={isWorking}
                spinnerPadding="0.375rem"
                spinnerColor={themeColorTypes.WHITE}
                data-testid="forgot-password-submit-button"
                disabled={!emailExists || !emailValid || emailExistsWorking}
              />
            </Box>
          )}
        </Box>

        <Box marginTop="1.5rem">
          <Link to="/login">
            <Button
              size="xs"
              label={t('pages:ForgotPassword.rememberMyPassword')}
            />
          </Link>
        </Box>
      </Box>
    </>
  )
}

ForgotPassword.propTypes = {
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(null, mapDispatchToProps)(ForgotPassword)
