import React, { useState } from 'react'

import { Box, ListItem, useMediaQuery } from '@mui/material'

import { Virtualizer } from '@tanstack/react-virtual'
import { useTranslation } from 'react-i18next'

import Text from '../../atoms/Text'
import TextInput from '../../atoms/TextInput'
import { RecommendedCharity } from '../hooks/useRecommendedCharityQuery'
import AddCharityButton from './AddCharityButton'
import CharityCategoryAvatar from './CharityCategoryAvatar'

interface CustomCharityListItemProps {
  index: number
  virtualizer: Virtualizer<HTMLDivElement, Element>
  searchQuery: string
  handleAddCharity: (charity: Pick<RecommendedCharity, 'name'>) => void
}

const CustomCharityListItem = (props: CustomCharityListItemProps) => {
  const { index, virtualizer, searchQuery, handleAddCharity } = props

  const [customCharityName, setCustomCharityName] = useState(searchQuery)
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const { t } = useTranslation()

  return (
    <ListItem
      disableGutters
      divider
      data-index={index}
      ref={virtualizer.measureElement}
      sx={{
        py: '1rem',
      }}
    >
      {!isMobile && <CharityCategoryAvatar category="custom" />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          mb: 'unset',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              maxWidth: '30rem',
            }}
          >
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault()
                handleAddCharity({ name: customCharityName })
              }}
              sx={{
                '& div': {
                  mb: '0',
                },
              }}
            >
              <TextInput
                variant="outlined"
                value={customCharityName}
                onInputChange={setCustomCharityName}
                placeholder={t(
                  `components:CharityFinder.customCharityPlaceholder`,
                )}
              />
            </Box>
            <Text size="xs">
              {t(`components:CharityFinder.customCharityHelp`)}
            </Text>
          </Box>
          <Box sx={{ flex: 1 }} />
          <AddCharityButton
            charity={{ name: customCharityName }}
            handleAddCharity={handleAddCharity}
          />
        </Box>
      </Box>
    </ListItem>
  )
}

export default CustomCharityListItem
