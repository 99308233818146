import React from 'react'

import { Box, Skeleton } from '@mui/material'

import { utils } from '@epilogue/common'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'

const labelFontSize = '0.75rem'
const valueFontSize = '1.18rem'

const LabelSkeleton = () => (
  <Skeleton width="100%" variant="text" sx={{ fontSize: labelFontSize }} />
)
const ValueSkeleton = () => (
  <Skeleton width="100%" variant="text" sx={{ fontSize: valueFontSize }} />
)

const LabelText = ({
  centerText,
  children,
}: {
  centerText: boolean
  children: string
}) => (
  <Text
    size="xxs"
    color={themeColorTypes.GREY}
    sx={{ fontSize: labelFontSize }}
    align={centerText ? 'center' : 'left'}
  >
    {children}
  </Text>
)

const ValueText = ({
  centerText,
  children,
}: {
  centerText: boolean
  children: string
}) => (
  <Heading
    variant="h5"
    sx={{ fontSize: valueFontSize }}
    align={centerText ? 'center' : 'left'}
  >
    {children}
  </Heading>
)

type Props =
  // If isWorking is true, pricePerMonth and coverageAmount are not required
  | {
      isWorking: true
      onClick: () => void
      isSelected?: boolean
      pricePerMonth?: number
      coverageAmount?: number
      centerText?: boolean
    }
  // If isWorking is false, pricePerMonth and coverageAmount are required
  | {
      isWorking: false
      onClick: () => void
      isSelected?: boolean
      pricePerMonth: number
      coverageAmount: number
      centerText?: boolean
    }

const QuoteCard = ({
  onClick,
  isWorking,
  pricePerMonth,
  coverageAmount,
  centerText = false,
  isSelected = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Box
      tabIndex={0}
      role="button"
      data-testid="quote-card"
      onClick={isWorking ? undefined : onClick}
      onKeyDown={isWorking ? undefined : onClick}
      sx={{
        p: '1.15rem',
        width: '100%',
        height: '134px',
        display: 'flex',
        cursor: 'pointer',
        borderRadius: '10px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: `2px solid ${
          isSelected && !isWorking
            ? themeColors[themeColorTypes.BRAND][themeColorVariants.LIGHT]
            : themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTER]
        }`,
        ...(!isSelected &&
          !isWorking && {
            '&:hover': {
              border: `2px solid ${themeColors[themeColorTypes.ACCENT_2][themeColorVariants.MAIN]}`,
            },
          }),
      }}
    >
      <Box>
        {isWorking ? (
          <LabelSkeleton />
        ) : (
          <LabelText centerText={centerText}>
            {t(
              'documents:components.LifeInsuranceQuotes.components.QuoteCard.planPrice',
            )}
          </LabelText>
        )}

        {isWorking ? (
          <ValueSkeleton />
        ) : (
          <ValueText centerText={centerText}>
            {`${utils.toDollar(pricePerMonth, 2, i18n.language)}/${t('documents:components.LifeInsuranceQuotes.components.QuoteCard.month')}`}
          </ValueText>
        )}
      </Box>

      <Box>
        {isWorking ? (
          <LabelSkeleton />
        ) : (
          <LabelText centerText={centerText}>
            {t(
              'documents:components.LifeInsuranceQuotes.components.QuoteCard.coverageAmount',
            )}
          </LabelText>
        )}

        {isWorking ? (
          <ValueSkeleton />
        ) : (
          <ValueText centerText={centerText}>
            {utils.toDollar(coverageAmount, 0, i18n.language)}
          </ValueText>
        )}
      </Box>
    </Box>
  )
}

export default QuoteCard
