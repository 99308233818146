import React from 'react'

import { connect } from 'react-redux'

import ButtonComponent from '../../../../../../../common/components/atoms/Button'
import amplitude from '../../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import { InvestmentAccountTypes } from '../../../../../../Questionnaire/components/Question/InvestmentAccounts/utils/getInvestmentAccountsOptions'
import dashboardSelectors from '../../../../../ducks/dashboard/dashboardSelectors'
import useGeneratedDocDownload from '../../hooks/useGeneratedDocDownload'
import { Button } from '../../types/button.types'

interface Props extends Button {
  userId: string
  answerStore: any
  generatedDocument: any
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const DownloadActionButton = ({
  userId,
  parentName,
  answerStore,
  generatedDocument,
  dispatchSetGlobalErrorMessage,
  ...props
}: Props) => {
  const { requestDocDownload, status, DownloadStatus } =
    useGeneratedDocDownload({
      userId,
      answerStore,
      generatedDocument,
      dispatchSetGlobalErrorMessage,
    })
  return (
    <ButtonComponent
      size="xs"
      iconFontSize="1.2em"
      sx={{ maxHeight: '37px' }}
      onClick={() => {
        const {
          hasResps,
          hasLifeInsurance,
          hasForeignProperty,
          canadianRealEstate,
          investmentAccounts = [],
        } = answerStore

        amplitude.sendEvent('ClickedDocumentsDownloadActionButton', {
          label: props.label,
          parentName,
          hasResps,
          hasLifeInsurance,
          hasForeignProperty,
          canadianRealEstate,
          hasRrsp: investmentAccounts.includes(InvestmentAccountTypes.RRSP),
          hasFhsa: investmentAccounts.includes(InvestmentAccountTypes.FHSA),
          hasTfsa: investmentAccounts.includes(InvestmentAccountTypes.TFSA),
          hasRrif: investmentAccounts.includes(InvestmentAccountTypes.RRIF),
        })

        requestDocDownload()
      }}
      spinnerPadding="0.266rem 1.448rem"
      spinnerColor={themeColorTypes.GREY}
      working={status === DownloadStatus.WORKING}
      {...props}
    />
  )
}

const mapStateToProps = (state: any) => ({
  userId: dashboardSelectors.getUserId(state.dashboard),
})

export default connect(mapStateToProps)(DownloadActionButton)
