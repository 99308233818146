export default (beneficiary) => {
  let backupStr
  const { name, predeceasedSplit, alternateName } = beneficiary

  switch (predeceasedSplit) {
    case undefined:
      return undefined

    case 'splitChildren':
      backupStr = `Split among ${name}'s children`
      break

    case 'skip':
      backupStr = 'None'
      break

    default:
      backupStr = alternateName ? `${alternateName}` : undefined
      break
  }

  return backupStr
}
