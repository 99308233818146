import { utils } from '@epilogue/common'
import { differenceInCalendarYears } from 'date-fns'
import mingo from 'mingo'

export enum InvestmentAccountTypes {
  RRSP = 'rrsp',
  RRIF = 'rrif',
  TFSA = 'tfsa',
  FHSA = 'fhsa',
}

export const investmentAccountOptions = [
  {
    value: InvestmentAccountTypes.RRSP,
    label: {
      tKey: 'customQuestion:InvestmentAccounts.utils.getInvestmentAccountsOptions.rrsp.label',
    },
    reviewLabel: {
      tKey: 'customQuestion:InvestmentAccounts.utils.getInvestmentAccountsOptions.rrsp.reviewLabel',
    },
    trigger: { calendarAge: { $lte: 71 } },
  },
  {
    value: InvestmentAccountTypes.RRIF,
    label: {
      tKey: 'customQuestion:InvestmentAccounts.utils.getInvestmentAccountsOptions.rrif.label',
    },
    reviewLabel: {
      tKey: 'customQuestion:InvestmentAccounts.utils.getInvestmentAccountsOptions.rrif.reviewLabel',
    },
    trigger: { calendarAge: { $gte: 65 } },
  },
  {
    value: InvestmentAccountTypes.TFSA,
    label: {
      tKey: 'customQuestion:InvestmentAccounts.utils.getInvestmentAccountsOptions.tfsa.label',
    },
    reviewLabel: {
      tKey: 'customQuestion:InvestmentAccounts.utils.getInvestmentAccountsOptions.tfsa.reviewLabel',
    },
  },
  {
    value: InvestmentAccountTypes.FHSA,
    label: {
      tKey: 'customQuestion:InvestmentAccounts.utils.getInvestmentAccountsOptions.fhsa.label',
    },
    reviewLabel: {
      tKey: 'customQuestion:InvestmentAccounts.utils.getInvestmentAccountsOptions.fhsa.reviewLabel',
    },
    trigger: { naturalAge: { $lt: 71 } },
  },
]

export default (dateOfBirth?: string, compareDate: Date = new Date()) => {
  if (!dateOfBirth) return []

  const naturalAge = utils.dobToAge(dateOfBirth, compareDate)
  const calendarAge = differenceInCalendarYears(
    compareDate,
    new Date(dateOfBirth),
  )

  return (
    investmentAccountOptions
      .filter(
        ({ trigger }) =>
          !trigger ||
          new mingo.Query(trigger).test({ naturalAge, calendarAge }),
      )
      // Only return the value and label from the investmentAccountOptions objects
      .map(({ value, label }) => ({ value, label }))
  )
}
