import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import CreateAccountForm from '../../molecules/CreateAccountForm'

const CreateAccount = ({ handleFieldFragmentUpdate, question: { fields } }) => {
  const { t } = useTranslation()

  return (
    <>
      <CreateAccountForm
        context="flow"
        fields={fields}
        label={t('customQuestion:CreateAccount.label')}
        subLabel={t('customQuestion:CreateAccount.subLabel')}
        handleFieldFragmentUpdate={handleFieldFragmentUpdate}
      />
    </>
  )
}

CreateAccount.propTypes = {
  answerCache: PropTypes.shape({}).isRequired,
  question: PropTypes.shape({ fields: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

export default CreateAccount
