import { createLogic } from 'redux-logic'

import amplitude from '../../../../../common/lib/amplitude'
import questionnaireActions from '../../questionnaire/questionnaireActions'
import navigationSelectors from '../navigationSelectors'
import navigationTypes from '../navigationTypes'

export default createLogic({
  name: 'previousQuestion',
  type: navigationTypes.PREVIOUS_QUESTION,

  validate({ getState, action }, allow, reject) {
    const preventPrevious = navigationSelectors.getPreventPrevious(
      getState().questionnaire,
    )

    const questionStack = navigationSelectors.getQuestionStack(
      getState().questionnaire,
    )

    // only allow if the current question is not the first question
    if (!preventPrevious && questionStack.length > 1) {
      amplitude.sendEvent('WentBackQuestion')
      return allow(action)
    }

    return reject()
  },

  process({ action }, dispatch, done) {
    const { clearFrags } = action.payload

    /*
     * We want to clear the question fragments everytime previousQuestion is run
     * to prevent the questionFragments from building up over many questions.
     * clearFrags will only be false when the user triggers previousQuestion by
     * clicking the browser 'back' button. In this case, the fragments get
     * cleared before the dispatching of previousQuestion in NativeNavHoc.js
     */
    if (clearFrags) {
      dispatch(questionnaireActions.clearQuestionFragments())
    }

    done()
  },
})
