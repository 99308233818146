import React from 'react'

import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import { ReactComponent as RolledScroll } from '../../../../../../../common/assets/images/rolled-scroll.svg'
import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import RadioGroup from '../../../../../../../common/components/atoms/RadioGroup'
import Select from '../../../../../../../common/components/atoms/Select'
import Text from '../../../../../../../common/components/atoms/Text'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import addOnsActions from '../../../../../ducks/addOns/addOnsActions'
import whichGiftAddOnType from './utils/whichGiftAddOnType'

interface Props {
  dispatchAddAddOnToCart: ({
    type,
    amount,
  }: {
    type: string
    amount: number
  }) => void
}

const GiftAddOn = ({ dispatchAddAddOnToCart }: Props) => {
  const navigate = useNavigate()

  const [giftQuantity, setGiftQuantity] = React.useState<number>(1)
  const [packageType, setPackageType] = React.useState<string>('individual')
  const [productType, setProductType] = React.useState<string>(
    CONSTANTS.productTypes.WILL,
  )

  const handleSetRadioValue =
    (setter: React.Dispatch<React.SetStateAction<string>>) => (value: string) =>
      setter(value)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    dispatchAddAddOnToCart({
      type: whichGiftAddOnType({ packageType, productType }),
      amount: giftQuantity,
    })
    navigate('/dashboard/payment')
  }

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        mx: 'auto',
        maxWidth: '42rem',
        mt: { min: '0', md: '-0.8rem', lg: '-1.8rem' },
      }}
    >
      <Link to="/dashboard/documents">
        <Button
          size="xs"
          label="Back"
          iconFontSize="1rem"
          startIcon={<ArrowBackIcon />}
        />
      </Link>

      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <RolledScroll />

        <Heading variant="h2" sx={{ mt: '2.1rem', mb: '1.4rem' }}>
          {t('pages:GiftAddOn.label')}
        </Heading>

        <Text variant="paragraph" align="center">
          {t('pages:GiftAddOn.subLabel1')}
        </Text>
        <Text sx={{ mt: '1rem' }} variant="paragraph" align="center">
          {t('pages:GiftAddOn.subLabel2')}
        </Text>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            my: '2.9rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: { min: '100%', sm: '28rem', md: '35rem' },
          }}
        >
          <Heading sx={{ mb: '0.95rem' }} align="center" variant="h5">
            {t('pages:GiftAddOn.questions.question1.label')}
          </Heading>
          <RadioGroup
            name="packageType"
            defaultActive={packageType}
            sx={{ width: { min: '100%', md: '25rem' } }}
            onChange={handleSetRadioValue(setPackageType)}
            radioButtons={[
              {
                value: 'individual',
                label: t(
                  'pages:GiftAddOn.questions.question1.radioButtons.radioButton1.label',
                ),
                dataTestId: 'gift-type-radio-individual',
              },
              {
                value: 'couples',
                label: t(
                  'pages:GiftAddOn.questions.question1.radioButtons.radioButton2.label',
                ),
                dataTestId: 'gift-type-radio-couples',
              },
            ]}
          />

          <Box sx={{ my: '3.55rem' }}>
            <Heading sx={{ mb: '0.95rem' }} align="center" variant="h5">
              {t('pages:GiftAddOn.questions.question2.label')}
            </Heading>
            <RadioGroup
              name="productType"
              defaultActive={productType}
              sx={{ width: { min: '100%', md: '25rem' } }}
              onChange={handleSetRadioValue(setProductType)}
              radioButtons={[
                {
                  value: CONSTANTS.productTypes.WILL,
                  dataTestId: 'gift-product-radio-will-only',
                  label:
                    packageType === 'individual'
                      ? t(
                          'pages:GiftAddOn.questions.question2.radioButtons.radioButton1.label.case1',
                          {
                            amount:
                              CONSTANTS.addOnTypes.GIFT_WILL_ONLY_INDIVIDUAL
                                .price,
                          },
                        )
                      : t(
                          'pages:GiftAddOn.questions.question2.radioButtons.radioButton1.label.case2',
                          {
                            amount:
                              CONSTANTS.addOnTypes.GIFT_WILL_ONLY_COUPLES.price,
                          },
                        ),
                },
                {
                  value: CONSTANTS.productTypes.WILL_POA,
                  dataTestId: 'gift-product-radio-will-poa',
                  label:
                    packageType === 'individual'
                      ? t(
                          'pages:GiftAddOn.questions.question2.radioButtons.radioButton2.label.case1',
                          {
                            amount:
                              CONSTANTS.addOnTypes.GIFT_WILL_POA_INDIVIDUAL
                                .price,
                          },
                        )
                      : t(
                          'pages:GiftAddOn.questions.question2.radioButtons.radioButton2.label.case2',
                          {
                            amount:
                              CONSTANTS.addOnTypes.GIFT_WILL_POA_COUPLES.price,
                          },
                        ),
                },
              ]}
            />
          </Box>

          <Heading sx={{ mb: '0.95rem' }} align="center" variant="h5">
            {t('pages:GiftAddOn.questions.question3.label')}
          </Heading>

          <Select
            sx={{ width: '6rem', mx: 'auto' }}
            options={[
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
              { value: 6, label: '6' },
              { value: 7, label: '7' },
              { value: 8, label: '8' },
              { value: 9, label: '9' },
              { value: 10, label: '10' },
            ]}
            value={giftQuantity}
            selectId="giftQuantity"
            onInputChange={(value: string) => setGiftQuantity(parseInt(value))}
          />

          <Box
            sx={{
              mt: '3.55rem',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Button
              type="submit"
              label={t('pages:GiftAddOn.submitButtonLabel')}
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              color={themeColorTypes.ACCENT_1}
              sx={{ width: '15rem', mb: '0.6rem' }}
            />

            <Link to="/dashboard/documents">
              <Button label={t('common:cancel')} size="xs" />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  dispatchAddAddOnToCart: ({
    type,
    amount,
  }: {
    type: string
    amount: number
  }) => dispatch(addOnsActions.addAddOnToCart({ type, amount })),
})

export default connect(null, mapDispatchToProps)(GiftAddOn)
