// TAG: Will Power
export default [
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.willPowerSidebarFragments.item1.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.willPowerSidebarFragments.item1.children.item1.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.willPowerSidebarFragments.item2.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.willPowerSidebarFragments.item2.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.willPowerSidebarFragments.item2.children.item2.body',
        },
      },
    ],
  },
  {
    type: 'willPowerCalculator',
    trigger: {
      'referrer.ref': 'willpower',
    },
  },
]
