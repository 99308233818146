import _get from 'lodash/get'

import { useQuery } from '@tanstack/react-query'

import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../../../../common/utils/queryKeys'

export default () =>
  useQuery({
    queryKey: queryKeys[queryKeysBase.printCreditsUsed](),
    queryFn: () =>
      request({ method: 'GET', url: PATHS.EVENT_PRINT_CREDITS_USED }),
    retry: false,
    select: (res) => _get(res, ['data', 'payload'], []),
    onError: (error) => errorHandler({ error, reportError: true }),
  })
