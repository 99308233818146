import React from 'react'

import { Link } from 'react-router-dom'

import { Box, Rating } from '@mui/material'

import { useTranslation } from 'react-i18next'

import googleLogo from '../../../../../../common/assets/images/google-logo.png'
import Text from '../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'

const GoogleReview = () => {
  const { t } = useTranslation()

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      to="https://www.google.com/maps/place/Epilogue/@43.6569658,-79.3804789,15z/data=!3m1!5s0x882b34c51bbe1e97:0x6a8c2ebac3a9ebf8!4m7!3m6!1s0x0:0x6ccffba8d5de074a!8m2!3d43.6569658!4d-79.3804789!9m1!1b1"
    >
      <Box>
        <Box
          sx={{
            mb: '0.375rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            '.MuiSvgIcon-root': { fontSize: '0.9rem' },
          }}
        >
          <Rating
            readOnly
            value={5}
            size="small"
            sx={{
              color:
                themeColors[themeColorTypes.BRAND][themeColorVariants.LIGHT],
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Text
            size="xxs"
            variant="display"
            color={themeColorTypes.BRAND}
            colorVariant={themeColorVariants.LIGHT}
          >
            {t('molecules:GoogleReview')}
          </Text>
          <Box
            component="img"
            src={googleLogo}
            alt="Google Logo"
            sx={{ width: '52px', ml: '0.25rem' }}
          />
        </Box>
      </Box>
    </Link>
  )
}

export default GoogleReview
