import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../styles/muiTheme'
import Heading from '../../../atoms/Heading'

const SettingsWrapper = ({ children, label }) => (
  <Box
    sx={{
      py: '3.5rem',
      width: '100%',
      borderBottom: `1px solid ${
        themeColors[themeColorTypes.NEUTRAL][themeColorVariants.MAIN]
      }`,
    }}
  >
    <Heading variant="h3" align="center">
      {label}
    </Heading>

    <Box>{children}</Box>
  </Box>
)

SettingsWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default SettingsWrapper
