import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import commonSelectors from '../../../ducks/commonSelectors'
import strToKebab from '../../../formatting/strToKebab'
import useProvinceDictionary from '../../../hooks/useProvinceDictionary'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'
import Text from '../../atoms/Text'
import SummarySection from './SummarySection'

const SummaryCard = ({ label, world, section, answerStore }) => {
  const { province } = answerStore
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { t } = useTranslation()
  const { provinceLanguageTranslate } = useProvinceDictionary()

  const tKey = Object.values(CONSTANTS.questionnaireSectionTypes).find(
    ({ id }) => id === label,
  )?.tKey

  return (
    <>
      {(!_isEmpty(section) || (label && label.includes('POA'))) && (
        <Box
          key={section.id}
          id={strToKebab(label)}
          data-testid={`summary-card-${strToKebab(label)}`}
          sx={{
            mb: '2rem',
            width: '100%',
            borderRadius: '0.5rem',
            padding: isDesktop ? '1.25rem' : '1.5rem',
            backgroundColor:
              themeColors[themeColorTypes.WHITE][themeColorVariants.MAIN],
          }}
        >
          <Box sx={{ mb: '1.25rem' }}>
            <Text size="2xl" weight="bold">
              {/*
               * Hack for Social world only, to use Summary
               * as card heading as opposed to the section's label
               */}
              {world === CONSTANTS.worldTypes.social.type
                ? t('common:summary')
                : provinceLanguageTranslate(tKey, label, province)}
            </Text>
          </Box>

          <Box as="main">
            {_isEmpty(section) ? (
              // locked POA section
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0.5rem 0 1.5rem',
                }}
              >
                <LockOutlinedIcon />
                <Box sx={{ ml: '0.25rem' }}>
                  <Text>{t('summary:components.SummaryCard.locked')}</Text>
                </Box>
              </Box>
            ) : (
              <SummarySection section={section} answerStore={answerStore} />
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

SummaryCard.propTypes = {
  world: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.type),
  ).isRequired,
  section: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string.isRequired,
  answerStore: PropTypes.shape({
    province: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    stepChildren: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

const mapStateToProps = (state) => ({ world: commonSelectors.getWorld(state) })

export default connect(mapStateToProps)(SummaryCard)
