import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Assurance-vie et décès

La raison principale pour laquelle vous avez une assurance-vie est de vous assurer que les personnes qui vous sont le plus chères sont protégées en cas d'imprévu. Il est donc important de savoir ce qui se passe avec votre police d'assurance en cas de décès.

L'exécuteur de votre testament devrait connaître les détails de toutes les polices d'assurance que vous possédez, y compris les noms de vos assureurs, vos numéros de police, les montants des prestations de décès et les coordonnées de vos courtiers en assurance. Une des premières choses que l'exécuteur devrait faire est de contacter les courtiers en assurance qui les aideront à traverser le processus de dépôt d'une réclamation.

#### Qui reçoit la prestation de décès ?

Les bénéficiaires d'une police d'assurance sont généralement désignés dans le contrat d'assurance au moment où la police est souscrite. Lorsque la personne assurée décède, la compagnie d'assurance versera la prestation de décès directement aux bénéficiaires. Les produits d'assurance ne font généralement pas partie de la succession, c'est pourquoi votre assurance-vie n'est pas mentionnée dans votre testament.

S'il n'y a pas de bénéficiaires désignés (ou s'ils sont tous décédés avant la personne dont la vie était assurée), les produits d'assurance sont versés à la succession de la personne assurée et sont traités conformément à son testament, ainsi qu'aux autres actifs de la personne. Il est également possible de désigner la succession de la personne assurée comme bénéficiaire de la police.

Si le bénéficiaire est mineur, la prestation de décès est versée à la personne désignée comme fiduciaire pour les bénéficiaires mineurs. Cette personne serait responsable de gérer la prestation d'assurance de chaque bénéficiaire mineur jusqu'à ce qu'ils atteignent l'âge de la majorité.

En règle générale, les prestations de décès de l'assurance sont exonérées d'impôt, de sorte que les bénéficiaires peuvent utiliser ces fonds de la manière qu'ils jugent la meilleure.

#### Quelles démarches puis-je entreprendre ?

Contactez votre courtier en assurance si vous souhaitez confirmer l'un des détails de votre police d'assurance, y compris vos paiements de primes, votre prestation de décès et les bénéficiaires.
`
    : `
#### Life Insurance and Death

The whole reason you have life insurance is to make sure the people that matter most to you are protected in case the unexpected happens. So it's important to know what happens with your insurance policy if you pass away.

The executor of your Will should know the details of any insurance policies you own, including the names of your insurers, your policy numbers, the death benefit amounts, and the contact information of your insurance brokers. One of the first things the executor should do is contact the insurance brokers who will help guide them through the process of filing a claim.

#### Who Receives the Death Benefit?

The beneficiaries of an insurance policy are usually named in the insurance contract at the time the policy is taken out. When the insured person dies, the insurance company will pay the death benefit directly to the beneficiaries. The insurance proceeds usually do not form part of the estate, and that is why your life insurance is not referenced in your Will.

If there are no designated beneficiaries (or if they all died before the person whose life was insured) the insurance proceeds are paid to the estate of the insured person and are dealt with according to their Will, along with the person's other assets. It is also possible to name the insured person's estate as the beneficiary of the policy.

If the beneficiary is minor, the death benefit is paid to  the person who is named as the trustee for minor beneficiaries. This person would be responsible for managing each minor beneficiary's insurance benefit until they reach the age of majority.

Generally speaking, insurance death benefits are tax-free, so the beneficiaries can use these funds in whatever way the think is best.

#### What Steps Can I Take?

Contact your insurance broker if you want to confirm any of the details of your insurance policy, including your premium payments, your death benefit, and the beneficiaries.
`
