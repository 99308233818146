import { useEffect, useState } from 'react'

import { CONSTANTS } from '@epilogue/common'

import amplitude from '../../../../../../common/lib/amplitude'
import event from '../../../../../../common/lib/event'
import { errorHandler, PATHS, request } from '../../../../../../common/request'

export enum EmailStatusTypes {
  IDLE = 'idle',
  ERROR = 'error',
  WORKING = 'working',
  SUCCESS = 'success',
}

export default ({
  userId,
  answerStore,
  dispatchSetGlobalErrorMessage,
}: {
  userId: string
  answerStore: any
  dispatchSetGlobalErrorMessage: (msg: string) => void
}) => {
  const { firstName, lastName, province } = answerStore

  const [status, setStatus] = useState<EmailStatusTypes>(EmailStatusTypes.IDLE)

  // status will be set to inactive on unmount
  useEffect(() => () => setStatus(EmailStatusTypes.IDLE), [])

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setStatus(EmailStatusTypes.WORKING)

    try {
      await request({
        method: 'POST',
        url: PATHS.DOCUMENT_EMAIL,
        data: { firstName, lastName, province },
      })

      amplitude.sendEvent('EmailedDocuments')
      event.send({
        metadata: { userId },
        name: CONSTANTS.eventNames.EMAIL_DOCUMENTS,
        action: CONSTANTS.eventActionTypes.SUCCESS,
      })

      setStatus(EmailStatusTypes.SUCCESS)
    } catch (error) {
      setStatus(EmailStatusTypes.ERROR)
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to email documents. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    }
  }

  return { EmailStatusTypes, status, setStatus, handleSubmit }
}
