import _isEmpty from 'lodash/isEmpty'

import { useQuery } from '@tanstack/react-query'

import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../../../common/utils/queryKeys'
import { InsuranceQuotesParams, InsuranceQuotesResponse } from '../quote.types'

interface Params {
  insuranceQuotesParams: InsuranceQuotesParams
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

export default ({
  insuranceQuotesParams,
  dispatchSetGlobalErrorMessage,
}: Params) => {
  const handleError = (error = new Error()) =>
    errorHandler({
      error,
      reportError: true,
      fallbackErrorMessage:
        'Unable to retrieve life insurance quotes. Please refresh your browser and try again.',
      onError: (resolvedErrorMessage: string) =>
        dispatchSetGlobalErrorMessage(resolvedErrorMessage),
    })

  return useQuery({
    queryKey: queryKeys[queryKeysBase.insurance](insuranceQuotesParams),
    queryFn: () =>
      request({
        method: 'GET',
        timeout: 20000, // 20 second timeout
        url: PATHS.INSURANCE_QUOTE,
        shouldSerializeParams: true,
        params: insuranceQuotesParams,
      }),
    staleTime: 60 * 60 * 1000,
    select: (resData) => {
      const filteredQuotes = resData?.data?.payload?.filter(
        (quoteItem: InsuranceQuotesResponse) =>
          quoteItem.status === 'available',
      )

      if (_isEmpty(filteredQuotes)) {
        handleError()
      }

      return filteredQuotes
    },
    retry: process.env.REACT_APP_ENV === 'production' ? 2 : 0,
    onError: handleError,
  })
}
