import { CONSTANTS } from '@epilogue/common'

const section = CONSTANTS.questionnaireSectionTypes.COMPLETE.id

export default {
  id: section,
  description: { tKey: 'questionnaire.complete:description' },
  questions: [
    {
      id: 1574114669,
      pathname: 'complete',
      type: 'complete',
      altProgressText: '',
      section,
    },
  ],
}
