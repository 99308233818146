import _isEmpty from 'lodash/isEmpty'

import amplitude from 'amplitude-js'

const environment = process.env.REACT_APP_ENV || 'production'

const isStaging = environment === 'staging'
const isProd = environment === 'production'

const setGroup = (groupType, groupName) => {
  if (isStaging || isProd) {
    amplitude.getInstance().setGroup(groupType, groupName)
  }
}

const init = () => {
  if (isStaging || isProd) {
    amplitude.getInstance().init(
      isProd
        ? 'f96d3da7ed84f0694d915d3d24fa8249' // Production Amplitude API
        : '6f80bb745596b7a3df61b2131c1ffd7c', // Staging Amplitude API
    )
    setGroup('environment', environment)
  }
}

const setUserDevice = (installationToken) => {
  if (isStaging || isProd) {
    amplitude.getInstance().setDeviceId(installationToken)
  }
}

const setUserId = (userId) => {
  if (isStaging || isProd) {
    amplitude.getInstance().setUserId(userId)
  }
}

const setUserProperties = (properties) => {
  if (isStaging || isProd) {
    amplitude.getInstance().setUserProperties(properties)
  }
}

const sendEvent = (eventType, eventProperties = {}) => {
  if (isStaging || isProd) {
    amplitude.getInstance().logEvent(eventType, {
      environment,
      url_path: window.location.pathname,
      url_params: window.location.search,
      ...eventProperties,
    })
  }
}

const sendRevenueEvent = (payment) => {
  if (isStaging || isProd) {
    const {
      _id,
      price,
      addOns,
      chargeId,
      discount,
      docTypes,
      productId,
      partnerPaidFor,
    } = payment

    let productName

    if (productId === 0) {
      productName = addOns[0].type
    } else {
      productName = `${docTypes.join(', ')}${
        !_isEmpty(addOns) ? ' + printing' : ''
      }${partnerPaidFor ? ' (x2)' : ''}`
    }

    amplitude.getInstance().logRevenueV2(
      new amplitude.Revenue()
        .setProductId(productName)
        .setPrice(price)
        .setEventProperties({
          _id,
          discount,
          chargeId,
          partnerPaidFor,
        }),
    )
  }
}

export default {
  init,
  setUserDevice,
  setUserId,
  setUserProperties,
  sendEvent,
  setGroup,
  sendRevenueEvent,
}
