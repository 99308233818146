import React from 'react'

import { Provider } from 'react-redux'

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Grommet } from 'grommet'
import LogRocket from 'logrocket'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga4'

import ErrorBoundary from './common/components/ErrorBoundary/ErrorBoundary'
import amplitude from './common/lib/amplitude'
import Router from './common/routers'
import store from './common/store'
import grommetTheme from './common/styles/grommetTheme'

import './common/styles/output.css'
// Fires facebook pixel
import './common/lib/facebook'
// Starts up intercom
import './common/lib/intercom'
// Starts up GTM
import './common/lib/gtm'
// Starts up sentry
import './common/lib/sentry'

import GlobalError from './common/components/molecules/GlobalError'
import muiTheme from './common/styles/muiTheme'
import featureFlag, { flagTypes } from './common/utils/featureFlag'
// Initialize i18n
import './i18n'

import LoadingSpinner from './common/components/atoms/LoadingSpinner'

// Initialize Google Analytics
ReactGA.initialize([
  {
    trackingId: 'G-Q7965DM54E', // New GA4 tracking ID
  },
  {
    trackingId: 'UA-150591167-3', // Legacy UA tracking ID
  },
])

// Initialize Amplitude
amplitude.init()

if (featureFlag(flagTypes.LOG_ROCKET)) {
  if (process.env.REACT_APP_ENV === 'production') {
    LogRocket.init('mp5zv7/epilogue-prod', {
      network: {
        requestSanitizer: (request) => {
          /*
           * If the body of the request has the string 'password' anywhere in it,
           * AND the request relates to a /user route
           * scrub the body before sending to LogRocket
           */
          if (
            typeof request?.body === 'string' &&
            typeof request?.url === 'string' &&
            request.body.toLowerCase().includes('password') &&
            request.url.includes('api/user')
          ) {
            request.body = undefined
          }

          return request
        },
      },
    })
  } else {
    LogRocket.init('epilogue-staging/epilogue-staging')
  }
}

const queryClient = new QueryClient()
const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('No root element found')
const root = createRoot(rootElement)

root.render(
  <>
    <Provider store={store()}>
      <Grommet theme={grommetTheme}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <QueryClientProvider client={queryClient}>
              <GoogleOAuthProvider clientId="558229269073-f2of1q7kk3lfb1ilo8grpdhgmdppf3kg.apps.googleusercontent.com">
                <React.Suspense fallback={<LoadingSpinner />}>
                  <ErrorBoundary>
                    <GlobalError />
                    <Router />
                  </ErrorBoundary>
                </React.Suspense>
              </GoogleOAuthProvider>
              {/* UNCOMMENT BELOW AS NEEDED WHEN WORKING IN DEVELOPMENT ENV */}
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grommet>
    </Provider>
  </>,
)

// Gives Cypress tests access to the Redux store
// if (window.Cypress) {
//   window.store = store()
// }
