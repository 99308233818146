import AccountCreated from '../AccountCreated'
import CharitableGiftDetails from '../CharitableGiftDetails'
import CharityPartnerGiftAmount from '../CharityPartnerGiftAmount'
import Children from '../Children'
import Complete from '../Complete'
import CorporateExecutorDisqualifiers from '../CorporateExecutorDisqualifiers'
import CorporateExecutorFeeAgreement from '../CorporateExecutorFeeAgreement'
import CreateAccount from '../CreateAccount'
import DateOfBirth from '../DateOfBirth'
import DropOff from '../DropOff'
import Email from '../Email'
import FamilyWill from '../FamilyWill'
import Gifts from '../Gifts'
import Info from '../Info'
import InheritanceAge from '../InheritanceAge'
import Intro from '../Intro'
import InvestmentAccounts from '../InvestmentAccounts'
import Name from '../Name'
import PartnerDropOff from '../PartnerDropOff'
import Pets from '../Pets'
import Predeceased from '../Predeceased'
import PredeceasedBackup from '../PredeceasedBackup'
import QuickRadioWithLink from '../QuickRadioWithLink'
import RemoteDistribution from '../RemoteDistribution'
import SectionReview from '../SectionReview'
import SocialComplete from '../SocialComplete'
import SocialCreateAccount from '../SocialCreateAccount'
import StepChildrenDistribution from '../StepChildrenDistribution'
import UnsupportedProvince from '../UnsupportedProvince'

export default {
  info: Info,
  pets: Pets,
  name: Name,
  gifts: Gifts,
  intro: Intro,
  email: Email,
  dropOff: DropOff,
  complete: Complete,
  children: Children,
  familyWill: FamilyWill,
  predeceased: Predeceased,
  dateOfBirth: DateOfBirth,
  createAccount: CreateAccount,
  sectionReview: SectionReview,
  inheritanceAge: InheritanceAge,
  accountCreated: AccountCreated,
  socialComplete: SocialComplete,
  partnerDropOff: PartnerDropOff,
  predeceasedBackup: PredeceasedBackup,
  investmentAccounts: InvestmentAccounts,
  quickRadioWithLink: QuickRadioWithLink,
  remoteDistribution: RemoteDistribution,
  unsupportedProvince: UnsupportedProvince,
  socialCreateAccount: SocialCreateAccount,
  charitableGiftDetails: CharitableGiftDetails,
  charityPartnerGiftAmount: CharityPartnerGiftAmount,
  stepChildrenDistribution: StepChildrenDistribution,
  corporateExecutorFeeAgreement: CorporateExecutorFeeAgreement,
  corporateExecutorDisqualifiers: CorporateExecutorDisqualifiers,
}
