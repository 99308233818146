import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import { alpha, Box } from '@mui/material'

import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import listItemAnimation from '../../../../../common/animations/listItemAnimation'
import Checkbox from '../../../../../common/components/atoms/Checkbox'
import TextInput from '../../../../../common/components/atoms/TextInput'
import useDebounce from '../../../../../common/hooks/useDebounce'
import useInjectDynamic from '../../../../../common/hooks/useInjectDynamic'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import paymentsSelectors from '../../../../Dashboard/ducks/payments/paymentsSelectors'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import NameMatchesPartnerModal from './components/NameMatchesPartnerModal'
import NamePreview from './components/NamePreview'

const Name = ({
  payments,
  question = {},
  answerCache,
  answerStore,
  invalidFields = [],
  displayValidation,
  handleFieldFragmentUpdate,
}) => {
  const [firstNameField, middleNameField, lastNameField, commonNameField] =
    question.fields

  const [nameValues, setNameValues] = useState({
    first: answerCache[firstNameField.name],
    middle: answerCache[middleNameField.name],
    last: answerCache[lastNameField.name],
    common: answerCache[commonNameField.name],
  })
  const debouncedNameValues = useDebounce(nameValues, 500)

  const [hasCommonName, setHasCommonName] = useState(
    !!answerCache.hasCommonName,
  )
  const [hasDismissedNameWarning, setHasDismissedNameWarning] = useState(false)
  const [showNameMatchesPartnerModal, setShowNameMatchesPartnerModal] =
    useState(false)

  const { [firstNameField.name]: initialFirstName } = answerStore
  const { partnerFirstName } = answerStore

  useEffect(() => {
    handleFieldFragmentUpdate({
      [firstNameField.name]: nameValues.first,
      [middleNameField.name]: nameValues.middle,
      [lastNameField.name]: nameValues.last,
      [commonNameField.name]: hasCommonName ? nameValues.common : undefined,
      hasCommonName,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameValues, hasCommonName])

  useEffect(() => {
    const isNameSameAsPartner = debouncedNameValues.first === partnerFirstName
    const userHasChangedName = debouncedNameValues.first !== initialFirstName
    const showModal =
      isNameSameAsPartner &&
      userHasChangedName &&
      !hasDismissedNameWarning &&
      !_isEmpty(payments)

    setShowNameMatchesPartnerModal(showModal)
    setHasDismissedNameWarning(hasDismissedNameWarning || showModal) // Never toggle back to false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFirstName, debouncedNameValues, partnerFirstName])

  const { t } = useTranslation()
  const { resolveValue } = useInjectDynamic()

  return (
    <>
      <NameMatchesPartnerModal
        showModal={showNameMatchesPartnerModal}
        setShowModal={setShowNameMatchesPartnerModal}
      />
      <Box
        sx={{
          width: '100%',
          maxWidth: '28rem',
          pb: '0.2rem',
        }}
      >
        <TextInput
          validationMessage={`*${t('common:required')}`}
          placeholder={resolveValue(firstNameField.placeholder)}
          value={nameValues.first}
          isInvalid={
            displayValidation && invalidFields.includes(firstNameField.name)
          }
          onInputChange={(first) => setNameValues({ ...nameValues, first })}
        />
        <TextInput
          placeholder={resolveValue(middleNameField.placeholder)}
          value={nameValues.middle}
          onInputChange={(middle) => setNameValues({ ...nameValues, middle })}
        />
        <TextInput
          validationMessage={`*${t('common:required')}`}
          placeholder={resolveValue(lastNameField.placeholder)}
          value={nameValues.last}
          isInvalid={
            displayValidation && invalidFields.includes(lastNameField.name)
          }
          onInputChange={(last) => setNameValues({ ...nameValues, last })}
        />

        <Box
          sx={{
            borderRadius: (theme) => theme.shape.borderRadius,
            padding: '1rem',
            margin: '2rem 0 0.8rem',
            bgcolor: (theme) =>
              alpha(
                theme.palette[themeColorTypes.GREY][themeColorVariants.LIGHTER],
                0.5,
              ),
          }}
        >
          <Checkbox
            labelSize="xs"
            checked={hasCommonName}
            onChange={(checked) => setHasCommonName(checked)}
            label={
              !nameValues.first || !nameValues.last
                ? t('customQuestion:Name.checkboxLabel.fallback')
                : t('customQuestion:Name.checkboxLabel.case1', {
                    firstName: nameValues.first,
                    lastName: nameValues.last,
                  })
            }
          />
        </Box>

        <AnimatePresence>
          {hasCommonName && (
            <motion.div
              style={{ marginTop: '1.6rem' }}
              {...listItemAnimation()}
              key="commonNameInput"
            >
              <TextInput
                validationMessage={`*${t('common:required')}`}
                placeholder={resolveValue(commonNameField.placeholder)}
                value={nameValues.common}
                isInvalid={
                  displayValidation &&
                  invalidFields.includes(`${question.type}-QUESTION`)
                }
                onInputChange={(common) =>
                  setNameValues({ ...nameValues, common })
                }
              />
            </motion.div>
          )}

          {nameValues.first && nameValues.last && (
            <motion.div
              style={{ marginTop: '1.6rem' }}
              {...listItemAnimation()}
              key="namePreview"
            >
              <NamePreview
                nameValues={nameValues}
                hasCommonName={hasCommonName}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </>
  )
}

Name.propTypes = {
  displayValidation: PropTypes.bool.isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  answerCache: PropTypes.shape({
    commonlyKnownAsName: PropTypes.string,
    hasCommonName: PropTypes.bool,
  }).isRequired,
  answerStore: PropTypes.shape({
    partnerFirstName: PropTypes.string,
  }).isRequired,
  question: PropTypes.shape({
    type: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
}

const mapStateToProps = (state) => ({
  payments: paymentsSelectors.getPayments(state.dashboard),
  answerCache: questionnaireSelectors.getAnswerCache(state.questionnaire),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps)(Name)
