import React, { useContext, useEffect } from 'react'

import _isEmpty from 'lodash/isEmpty'

import { Box, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { utils } from '@epilogue/common'
import i18n from 'i18next'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import Text from '../../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import PaymentContext from '../../context/PaymentContext'
import PromoCode from './components/PromoCode'

const useStyles = makeStyles((theme) => ({
  purpleBackground: {
    borderRadius: '4px 4px 0 0',
    backgroundColor:
      themeColors[themeColorTypes.ACCENT_2][themeColorVariants.MAIN],
  },

  contentWrapper: {
    padding: '2rem 1rem',

    [theme.breakpoints.up('xs')]: {
      padding: '2rem 1.4rem',
    },

    [theme.breakpoints.up('sm')]: {
      padding: '2rem 2.2rem',
    },
  },
}))

const PaymentBreakdown = () => {
  const classes = useStyles()

  const { chargeInfo, promoCode } = useContext(PaymentContext)
  const {
    tax,
    price,
    addOns,
    taxRate,
    discount,
    docTypes,
    displayTotal,
    promoCodeObj,
    product: { productId },
  } = chargeInfo

  /*
   * We can assume that if the user never typed in a
   * promo-code (!promoCode), but has a discount amount
   * being returned from the chargeInfo, that code must
   * have been automatically applied.
   */
  const autoAppliedPromoCode = discount > 0 && !promoCode

  /* 
    If productId is 0 OR addOns has a value in its array, and docTypes
    does not, the user is purchasing only add ons and not documents
  */
  const isAddOnOnly =
    productId === 0 || (!_isEmpty(addOns) && _isEmpty(docTypes))

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'Initiate Checkout', {
        value: displayTotal,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  return (
    <Paper elevation={0}>
      <Box py="0.7rem" className={classes.purpleBackground}>
        <Text size="xl" align="center" display="block">
          {t('payment:components.PaymentBreakdown.total')}
        </Text>
      </Box>

      <Box className={classes.contentWrapper}>
        <Box
          display="flex"
          justifyContent="space-between"
          data-testid="payment-breakdown-sub-total"
        >
          <Text>{t('payment:components.PaymentBreakdown.subTotal')}</Text>
          <Text>{utils.toDollar(price, 2, i18n.language)}</Text>
        </Box>
        {(promoCode || autoAppliedPromoCode) && (
          <motion.div
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.4 }}
          >
            <Box
              my="0.4rem"
              display="flex"
              columnGap="0.4rem"
              justifyContent="space-between"
              data-testid="payment-breakdown-discount"
            >
              <Text color={themeColorTypes.GREEN}>
                {promoCodeObj?.description ||
                  t('payment:components.PaymentBreakdown.discount')}
              </Text>
              <Text color={themeColorTypes.GREEN}>
                -{utils.toDollar(discount, 2, i18n.language)}
              </Text>
            </Box>
          </motion.div>
        )}
        <Box
          my="0.4rem"
          display="flex"
          justifyContent="space-between"
          data-testid="payment-breakdown-sales-tax"
        >
          <Text>
            {t('payment:components.PaymentBreakdown.salesTax')} ({taxRate * 100}
            %)
          </Text>
          <Text>{utils.toDollar(tax, 2, i18n.language)}</Text>
        </Box>
        <Box
          my="0.4rem"
          display="flex"
          justifyContent="space-between"
          data-testid="payment-breakdown-total"
        >
          <Text weight="bold">
            {t('payment:components.PaymentBreakdown.total')}
          </Text>
          <Text weight="bold">
            {utils.toDollar(displayTotal, 2, i18n.language)}
          </Text>
        </Box>

        <Text sx={{ mt: '0.5rem', fontSize: '0.77rem', textAlign: 'center' }}>
          *{t('payment:components.PaymentBreakdown.totalDisclaimer')}
        </Text>

        {!isAddOnOnly && (
          <Box pt="1.4rem">
            <PromoCode />
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default PaymentBreakdown
