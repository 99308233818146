import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import paymentsSelectors from '../../../scenes/Dashboard/ducks/payments/paymentsSelectors'
import hasCoreProduct from '../../../scenes/Dashboard/utils/hasCoreProduct'
import questionnaireSelectors from '../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import commonSelectors from '../../ducks/commonSelectors'
import amplitude from '../../lib/amplitude'
import Button from '../atoms/Button'
import Heading from '../atoms/Heading'
import Header from '../molecules/Header'
import ChangeEmailAddress from './components/ChangeEmailAddress'
import ChangePartnerEmailAddress from './components/ChangePartnerEmailAddress'
import ChangePassword from './components/ChangePassword'
import DeleteAccount from './components/DeleteAccount'
import SettingsWrapper from './components/SettingsWrapper'

interface Props {
  payments: any
  answerStore: any
  world: keyof typeof CONSTANTS.worldTypes
}

const AccountSettings = ({ world, answerStore, payments }: Props) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  useEffect(() => {
    amplitude.sendEvent('ViewedAccountSettings')
  }, [])

  const partnerEmail = answerStore?.partnerEmail
  const isPaid = hasCoreProduct(payments)

  const { t } = useTranslation()

  return (
    <>
      <Header />

      <Box
        sx={{
          m: '0 auto',
          px: '1.5rem',
          pt: '1.5rem',
          pb: '7rem',
          maxWidth: '600px',
        }}
      >
        <Button
          label={t('common:back')}
          sx={{ mb: '1rem' }}
          iconFontSize="1.05rem"
          size={isMobile ? 'sm' : 'md'}
          startIcon={<ArrowBackIcon />}
          data-testid="account-settings-back-button"
          onClick={() =>
            navigate(
              `${CONSTANTS.worldTypes[world].dashboardPath}?w=${CONSTANTS.worldTypes[world].type}`,
            )
          }
        />

        <Heading variant="h2" align="center" sx={{ mb: '1rem' }}>
          {t('pages:AccountSettings.title')}
        </Heading>

        <SettingsWrapper
          label={t('pages:AccountSettings.changeEmailAddress.label')}
        >
          <ChangeEmailAddress />
        </SettingsWrapper>

        <SettingsWrapper
          label={t('pages:AccountSettings.changePassword.label')}
        >
          <ChangePassword />
        </SettingsWrapper>

        {isPaid && partnerEmail && (
          <SettingsWrapper
            label={t('pages:AccountSettings.changePartnerEmailAddress.label')}
          >
            <ChangePartnerEmailAddress partnerEmail={partnerEmail} />
          </SettingsWrapper>
        )}

        <SettingsWrapper label={t('pages:AccountSettings.deleteAccount.label')}>
          <DeleteAccount />
        </SettingsWrapper>
      </Box>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  world: commonSelectors.getWorld(state),
  payments: paymentsSelectors.getPayments(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps)(AccountSettings)
