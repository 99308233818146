import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'
import { Button, Text } from 'grommet'
import { FormNextLink } from 'grommet-icons'

import LoadingSpinner from '../../../../common/components/atoms/LoadingSpinner'
import commonActions from '../../../../common/ducks/commonActions'
import amplitude from '../../../../common/lib/amplitude'
import documentsActions from '../../../Dashboard/ducks/documents/documentsActions'
import data from '../../../Questionnaire/data'
import navigationActions from '../../../Questionnaire/ducks/navigation/navigationActions'
import navigationSelectors from '../../../Questionnaire/ducks/navigation/navigationSelectors'
import questionnaireActions from '../../../Questionnaire/ducks/questionnaire/questionnaireActions'
import questionnaireSelectors from '../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import useFetchSocialDocuments from '../../hooks/useFetchSocialDocuments'
import UpdateAnswersModal from './components/UpdateAnswersModal'
import useGenerateSocialDoc from './hooks/useGenerateSocialDoc'

const buttonStates = {
  UNSTARTED: 'unstarted',
  INCOMPLETE: 'incomplete',
  GENERATE: 'generate',
  RE_GENERATE: 're-generate',
  COMPLETE: 'complete',
}

const firstQuestion = _get(data, ['0', 'questions', '0'])

const SocialEverythingButton = ({
  answerStore,
  questionnaireValid,
  dispatchUpdateToast,
  dispatchToQuestion,
  nextUnansweredQuestion = {},
  socialQuestionnaireStarted,
  dispatchSetGlobalErrorMessage,
  answerStoreUpdatedSinceGenerate,
  dispatchGenerateDocumentsWorking,
  dispatchSetAnswerStoreUpdatedSinceGenerate,
}) => {
  const [buttonState, setButtonState] = useState()
  const [showUpdateAnswersModal, setShowUpdateAnswersModal] = useState(false)

  const [documents, fetchStatus, fetchStatusStates] = useFetchSocialDocuments({
    dispatchSetGlobalErrorMessage,
  })

  const [handleGenerateSocialDoc] = useGenerateSocialDoc({
    dispatchSetGlobalErrorMessage,
    dispatchGenerateDocumentsWorking,
  })

  const socialDocument = useMemo(() => documents?.[0] || [], [documents])

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const onDashboard = pathname.includes('dashboard')

  useEffect(() => {
    const theButtonState = () => {
      if (!socialQuestionnaireStarted) {
        return buttonStates.UNSTARTED
      }
      if (!questionnaireValid) {
        return buttonStates.INCOMPLETE
      }
      if (_isEmpty(socialDocument) && questionnaireValid) {
        return buttonStates.GENERATE
      }
      if (!_isEmpty(socialDocument) && answerStoreUpdatedSinceGenerate) {
        return buttonStates.RE_GENERATE
      }
      return buttonStates.COMPLETE
    }

    setButtonState(theButtonState())
  }, [
    socialDocument,
    questionnaireValid,
    socialQuestionnaireStarted,
    answerStoreUpdatedSinceGenerate,
  ])

  return (
    <>
      {fetchStatus === fetchStatusStates.SUCCESS && buttonState ? (
        <Button
          data-testid="social-everything-button"
          reverse
          primary
          color={
            buttonState === buttonStates.COMPLETE ? 'neutral-2' : 'accent-1'
          }
          icon={
            <div className="-ml-2">
              <FormNextLink
                color={
                  buttonState === buttonStates.COMPLETE ? 'brand' : 'white'
                }
              />
            </div>
          }
          label={
            <div className="py-1">
              <Text
                size="smedium"
                color={
                  buttonState === buttonStates.COMPLETE ? 'brand' : 'white'
                }
              >
                {buttonState === buttonStates.UNSTARTED
                  ? 'Get Started'
                  : buttonState === buttonStates.INCOMPLETE
                    ? 'Continue Questionnaire'
                    : buttonState === buttonStates.GENERATE
                      ? 'Generate Document'
                      : buttonState === buttonStates.RE_GENERATE
                        ? 'Re-Generate Document'
                        : 'Update Answers'}
              </Text>
            </div>
          }
          onClick={() => {
            switch (buttonState) {
              case buttonStates.UNSTARTED: {
                amplitude.sendEvent('ClickedSocialEverythingButton', {
                  state: 'Get Started',
                })

                dispatchToQuestion(firstQuestion)
                navigate(`/questionnaire?w=${CONSTANTS.worldTypes.social.type}`)

                break
              }
              case buttonStates.INCOMPLETE: {
                amplitude.sendEvent('ClickedSocialEverythingButton', {
                  state: 'Continue Questionnaire',
                })

                dispatchToQuestion(nextUnansweredQuestion)
                navigate(`/questionnaire?w=${CONSTANTS.worldTypes.social.type}`)

                break
              }
              case buttonStates.COMPLETE: {
                amplitude.sendEvent('ClickedSocialEverythingButton', {
                  state: 'Update Answers',
                })

                setShowUpdateAnswersModal(true)

                break
              }
              default: {
                handleGenerateSocialDoc(
                  { answerStore },
                  {
                    onSuccess: () => {
                      amplitude.sendEvent('ClickedSocialEverythingButton', {
                        state:
                          buttonState === buttonStates.RE_GENERATE
                            ? 'Re-Generate Document'
                            : 'Update Answers',
                      })

                      dispatchSetAnswerStoreUpdatedSinceGenerate()
                      if (!onDashboard) {
                        navigate(
                          `/social-dashboard?w=${CONSTANTS.worldTypes.social.type}`,
                        )
                      }

                      setTimeout(() => {
                        dispatchUpdateToast({
                          type: CONSTANTS.toastTypes.SUCCESS,
                          label: 'Success!',
                          message: 'Your Social Media Will has been generated.',
                        })
                      }, 400)
                    },
                  },
                )
              }
            }
          }}
        />
      ) : (
        <LoadingSpinner />
      )}

      <UpdateAnswersModal
        firstQuestion={firstQuestion}
        show={showUpdateAnswersModal}
        dispatchToQuestion={dispatchToQuestion}
        handleSetShow={(bool) => setShowUpdateAnswersModal(bool)}
      />
    </>
  )
}

SocialEverythingButton.propTypes = {
  questionnaireValid: PropTypes.bool,
  answerStore: PropTypes.shape({}).isRequired,
  nextUnansweredQuestion: PropTypes.shape({}),
  dispatchUpdateToast: PropTypes.func.isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
  socialQuestionnaireStarted: PropTypes.bool.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  answerStoreUpdatedSinceGenerate: PropTypes.bool.isRequired,
  dispatchGenerateDocumentsWorking: PropTypes.func.isRequired,
  dispatchSetAnswerStoreUpdatedSinceGenerate: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  nextUnansweredQuestion: navigationSelectors.getNextUnansweredQuestion(
    state.questionnaire,
  ),
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
  socialQuestionnaireStarted: questionnaireSelectors.getQuestionnaireStarted(
    state.questionnaire,
    CONSTANTS.worldTypes.social.type,
  ),
  answerStoreUpdatedSinceGenerate:
    questionnaireSelectors.getAnswerStoreUpdatedSinceGenerate(
      state.questionnaire,
    ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchToQuestion: (question) =>
    dispatch(navigationActions.toQuestion({ question, fromDashboard: true })),
  dispatchGenerateDocumentsWorking: (bool) =>
    dispatch(documentsActions.generateDocumentsWorking(bool)),
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
  dispatchSetAnswerStoreUpdatedSinceGenerate: () =>
    dispatch(
      questionnaireActions.setAnswerStoreUpdatedSinceGenerate({
        bool: false,
      }),
    ),
  dispatchUpdateToast: (toastProperties) =>
    dispatch(commonActions.updateToast(toastProperties)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SocialEverythingButton)
