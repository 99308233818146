import React, { useContext } from 'react'

import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'

import LoadingSpinner from '../../../../../../../common/components/atoms/LoadingSpinner'
import LawyerReferralContext from '../../context/LawyerReferralContext'
import LawyerCards from './components/LawyerCards'

const StandardLawyerReferral = () => {
  const { lawyers } = useContext(LawyerReferralContext)

  return (
    <>
      {!lawyers ? (
        // TODO: add an actual empty state as opposed to just a spinner
        <LoadingSpinner />
      ) : (
        <>
          {!_isEmpty(lawyers) && (
            <Box sx={{ pt: '1rem' }}>
              <LawyerCards lawyers={lawyers} />
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default StandardLawyerReferral
