import React from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import {
  fontFamilies,
  themeColors,
  themeColorTypes,
  themeColorVariants,
  themeTextSizes,
} from '../../../styles/muiTheme'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: ({ variant }) => {
      if (variant === 'text') {
        return fontFamilies.text
      }

      if (variant === 'display') {
        return fontFamilies.heading
      }

      return fontFamilies.paragraph
    },
    fontWeight: ({ weight }) => (weight === 'medium' ? 500 : weight),
    fontSize: ({ size, sx }) => sx?.fontSize || themeTextSizes[size],
    color: ({ color, colorVariant }) =>
      color === 'inherit'
        ? color
        : _get(theme, [
            'palette',
            color,
            colorVariant || [themeColorVariants.MAIN],
          ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
  },
}))

const Text = ({
  sx = {},
  size = 'md',
  align = 'inherit',
  color = themeColorTypes.BRAND,
  noWrap = false,
  weight = 'normal',
  display = 'block',
  variant = 'text',
  children,
  className,
  elementType = 'span',
  gutterBottom = false,
  colorVariant = themeColorVariants.MAIN,
  dangerouslySetInnerHTML,
  'data-testid': dataTestId,
}) => {
  const classes = useStyles({ color, colorVariant, size, weight, variant, sx })

  const themeTextSizeKeys = Object.keys(themeTextSizes)
  const smallThanMd = themeTextSizeKeys.slice(
    0,
    themeTextSizeKeys.indexOf('md'),
  )

  return (
    <Typography
      sx={sx}
      align={align}
      noWrap={noWrap}
      display={display}
      className={className}
      data-testid={dataTestId}
      component={elementType}
      gutterBottom={gutterBottom}
      classes={{ root: classes.root }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      variant={smallThanMd.includes(size) ? 'body2' : 'body1'}
    >
      {children}
    </Typography>
  )
}

Text.propTypes = {
  noWrap: PropTypes.bool,
  sx: PropTypes.shape({}),
  className: PropTypes.string,
  gutterBottom: PropTypes.bool,
  'data-testid': PropTypes.string,
  children: (props, propName, componentName) => {
    if (_isEmpty(props.dangerouslySetInnerHTML) && _isNil(props[propName])) {
      return new Error(
        `'${propName}' prop is required on ${componentName} component`,
      )
    }
    return undefined
  },
  dangerouslySetInnerHTML: PropTypes.shape({}),
  weight: PropTypes.oneOf(['normal', 'medium', 'bold']),
  variant: PropTypes.oneOf(['text', 'paragraph', 'display']),
  elementType: PropTypes.oneOf(['span', 'p', 'div']),
  size: PropTypes.oneOf(Object.keys(themeTextSizes)),
  color: PropTypes.oneOf(['inherit', ...Object.values(themeColorTypes)]),
  display: PropTypes.oneOf(['initial', 'block', 'inline']),
  colorVariant: PropTypes.oneOf(Object.values(themeColorVariants)),
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
}

export default Text
