export default [
  { value: 'January', label: { tKey: 'common:month.january' } },
  { value: 'February', label: { tKey: 'common:month.february' } },
  { value: 'March', label: { tKey: 'common:month.march' } },
  { value: 'April', label: { tKey: 'common:month.april' } },
  { value: 'May', label: { tKey: 'common:month.may' } },
  { value: 'June', label: { tKey: 'common:month.june' } },
  { value: 'July', label: { tKey: 'common:month.july' } },
  { value: 'August', label: { tKey: 'common:month.august' } },
  { value: 'September', label: { tKey: 'common:month.september' } },
  { value: 'October', label: { tKey: 'common:month.october' } },
  { value: 'November', label: { tKey: 'common:month.november' } },
  { value: 'December', label: { tKey: 'common:month.december' } },
]
