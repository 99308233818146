import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import documentsSelectors from '../../../../scenes/Dashboard/ducks/documents/documentsSelectors'
import amplitude from '../../../lib/amplitude'
import Heading from '../../atoms/Heading'
import LoadingSpinner from '../../atoms/LoadingSpinner'
import Modal from '../../atoms/Modal'
import Text from '../../atoms/Text'

const GenerateDocsModal = ({ generateDocumentsWorking }) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  useEffect(() => {
    if (generateDocumentsWorking) amplitude.sendEvent('ViewedGenerateDocsModal')
  }, [generateDocumentsWorking])

  const { t } = useTranslation()

  return (
    <>
      {generateDocumentsWorking && (
        <Modal maxWidth="sm" show preventClose>
          <Box
            p={isDesktop ? '1.2rem' : '0'}
            display="flex"
            alignItems="center"
            flexDirection="column"
            data-testid="generate-docs-modal"
          >
            <Heading align="center" variant="h3">
              {t('molecules:GenerateDocsModal.label')}
            </Heading>

            <Box py="2.8rem">
              <LoadingSpinner delay="0" />
            </Box>

            <Text align="center" variant="paragraph">
              {t('molecules:GenerateDocsModal.subLabel')}
            </Text>
          </Box>
        </Modal>
      )}
    </>
  )
}

GenerateDocsModal.propTypes = {
  generateDocumentsWorking: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  generateDocumentsWorking: documentsSelectors.getGenerateDocumentsWorking(
    state.dashboard,
  ),
})

export default connect(mapStateToProps)(GenerateDocsModal)
