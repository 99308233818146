import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Paragraph, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import Modal from '../../../../../../common/components/atoms/Modal'

const PortionsHelpModal = ({ toggleHasDisplayedPortionsHelpModal }) => {
  const [show, setShow] = useState(true)

  useEffect(
    () => () => {
      toggleHasDisplayedPortionsHelpModal()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      onClose={() => {
        setShow(false)
      }}
      fullBleed
    >
      <div className="p-10 max-w-xl m-auto">
        <div className="mb-2">
          <Text weight="bold">
            {t(
              'customQuestion:RemoteDistribution.components.PortionsHelpModal.label',
            )}
          </Text>
        </div>
        <Paragraph fill size="smedium">
          {t(
            'customQuestion:RemoteDistribution.components.PortionsHelpModal.paragraph1',
          )}
        </Paragraph>
        <div className="mt-2">
          <Paragraph fill size="smedium">
            {t(
              'customQuestion:RemoteDistribution.components.PortionsHelpModal.paragraph2',
            )}
          </Paragraph>
        </div>
      </div>
    </Modal>
  )
}

PortionsHelpModal.propTypes = {
  toggleHasDisplayedPortionsHelpModal: PropTypes.func.isRequired,
}

export default PortionsHelpModal
