import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Qu'est-ce qu'un Régime enregistré d'épargne-études (REEE) ?

Un Régime enregistré d'épargne-études (REEE) est un compte d'investissement spécial conçu pour aider les Canadiens à économiser de l'argent pour les études postsecondaires de leurs enfants.

#### Avantages des REEE

Il existe plusieurs avantages à épargner pour les études d'un enfant à l'aide d'un REEE. Voici quelques-uns d'entre eux :

1. Aucun impôt n'est payable lorsque les investissements croissent et génèrent des revenus dans un REEE. Cela signifie que plus d'argent peut être conservé dans le REEE et que les économies peuvent s'accumuler plus rapidement.
2. Lorsque les fonds d'un REEE sont utilisés pour les études postsecondaires d'un enfant, ils sont inclus dans le revenu imposable de l'enfant. Comme les étudiants ont généralement peu ou pas d'autres revenus, il n'y a souvent aucun impôt à payer.
3. Le gouvernement fédéral (et certains gouvernements provinciaux) offre des programmes de subventions qui fournissent des fonds supplémentaires pour les REEE.

#### Subventions gouvernementales

La subvention REEE gouvernementale la plus largement utilisée est la Subvention canadienne pour l'épargne-études (SCEE).

La SCEE accorde des subventions équivalentes à 20 % des cotisations annuelles au REEE effectuées pour chaque bénéficiaire du REEE, jusqu'à un maximum de 500 $. Si un parent a deux enfants et peut cotiser 5 000 $ à leurs REEE (2 500 $ chacun), la SCEE ajoutera 1 000 $ supplémentaires (500 $ pour chaque bénéficiaire) au REEE. Chaque bénéficiaire peut recevoir jusqu'à 7 200 $ de SCEE au cours de sa vie.

Les subventions SCEE inutilisées s'accumulent avec le temps et il est possible de "rattraper" les cotisations manquées (et les subventions SCEE) des années précédentes.

#### Commencez à investir dans un REEE

Pour ouvrir un REEE, contactez votre institution financière et demandez à parler à un conseiller concernant l'ouverture d'un REEE.
    `
    : `
#### What is a Registered Education Savings Plan?

A Registered Education Savings Plan (RESP) is a special investment account designed to help Canadians save money for their children's post-secondary education.

#### Benefits of RESPs

There are several benefits to saving for a child's education using an RESP. Here are just some of them:

1. No tax is payable as investments grow and earn income in an RESP. This means more money can be kept in the RESP and savings can accumulate faster.
2. When RESP funds are applied to a child's post-secondary education they are included in the child's taxable income. Since students usually have little or no other income, there is often no tax payable.
3. The federal government (and some provincial governments) have grant programs that provide additional funds for RESPs.

#### Government Grants

The most widely used government RESP grant is the Canada Education Savings Grant (CESG).

The CESG funds grants equal to 20% of annual RESP contributions made for each RESP beneficiary, up to a maximum of $500. If a parent has two children and is able to contribute $5,000 to their RESPs ($2,500 each), the CESG will add an additional $1,000 ($500 for each beneficiary) to the RESP. Each beneficiary can receive up to $7,200 of CESG over their lifetime.

Unused CESG grants accumulate over time and it's possible to “catch up” for missed contributions (and CESG grants) from prior years.

#### Start Investing in a RESP

To open an RESP contact your financial institution and ask to speak to an advisor about opening an RESP.
`
