import React, { memo, useEffect, useState } from 'react'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box } from '@mui/material'

import { Timezone } from '@epilogue/common'
import { differenceInDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../common/components/atoms/Button'
import Modal from '../../../../../../../../common/components/atoms/Modal'
import {
  SelectedDateInfo,
  SelectedTimeslot,
  Timeslot,
} from '../../../../../../../../common/components/DatePicker/datePicker.types'
import commonActions from '../../../../../../../../common/ducks/commonActions'
import getUserTimezone from '../../../../../../../../common/utils/getUserTimezone'
import BookWitnessingAgreeView from './components/BookWitnessingAgreeView'
import BookWitnessingConfirmView from './components/BookWitnessingConfirmView'
import BookWitnessingDatePickerView from './components/BookWitnessingDatePickerView'
import BookWitnessingEmailView from './components/BookWitnessingEmailView'
import BookWitnessingMailingAddressView from './components/BookWitnessingMailingAddressView'
import BookWitnessingRoadblockView from './components/BookWitnessingRoadblockView'
import BookWitnessingSuccessView from './components/BookWitnessingSuccessView'
import BookWitnessingSwitchboardView from './components/BookWitnessingSwitchboardView'
import BookWitnessingTimePickerView from './components/BookWitnessingTimePickerView'
import usePrintCreditsUsed from './hooks/usePrintCreditsUsed'

interface Props {
  show: boolean
  onClose: () => void
  hasPrintCredit: boolean
  dispatchSetGlobalErrorMessage: (message: string) => void
}

interface MailingAddress {
  [key: string]: string
}

type ViewType =
  | 'roadblock'
  | 'switchboard'
  | 'agree'
  | 'email'
  | 'datePicker'
  | 'timePicker'
  | 'mailingAddress'
  | 'confirm'
  | 'success'

const BookWitnessingModal = memo(
  ({ show, onClose, hasPrintCredit, dispatchSetGlobalErrorMessage }: Props) => {
    const [selectedDate, setSelectedDate] = useState<Date>()
    const [timeslot, setTimeslot] = useState<SelectedTimeslot>()
    const [mailingAddress, setMailingAddress] = useState<MailingAddress>()
    const [availableTimeslots, setAvailableTimeslots] = useState<Timeslot[]>()
    const [viewStack, setViewStack] = useState<ViewType[]>(['switchboard'])
    const [usedPrintCreditRecently, setUsedPrintCreditRecently] =
      useState<boolean>(false)

    const { t } = useTranslation()

    const timezone: Timezone = getUserTimezone()

    const { data: printCreditsUsed } = usePrintCreditsUsed()

    useEffect(() => {
      if (!_isEmpty(printCreditsUsed)) {
        // Check if user has used print credit within the last 30 days
        const printCreditUsedWithin30Days = printCreditsUsed.some(
          (credit: any) => {
            const today = new Date()
            const diffInDays = differenceInDays(
              today,
              new Date(credit.createdAt),
            )
            return diffInDays <= 30
          },
        )
        setUsedPrintCreditRecently(printCreditUsedWithin30Days)
      }
    }, [printCreditsUsed])

    useEffect(() => {
      if (show) {
        // When user opens modal, reset view to first view
        setViewStack([hasPrintCredit ? 'roadblock' : 'switchboard'])
      }
    }, [hasPrintCredit, show])

    const viewForward = (newViewType: ViewType) => {
      setViewStack((prevStack) => [...prevStack, newViewType])
    }

    const viewBack = () => {
      setViewStack((prevStack) => prevStack.slice(0, -1))
    }

    const renderView = () => {
      switch (viewStack[viewStack.length - 1]) {
        case 'roadblock':
          return (
            <BookWitnessingRoadblockView
              onClose={onClose}
              hasPrintCredit={hasPrintCredit}
              usedPrintCreditRecently={usedPrintCreditRecently}
            />
          )

        case 'switchboard':
          return (
            <BookWitnessingSwitchboardView
              onSelection={(value) => {
                if (value === 'yes') {
                  viewForward('agree')
                } else if (usedPrintCreditRecently) {
                  viewForward('roadblock')
                } else {
                  viewForward('email')
                }
              }}
            />
          )

        case 'agree':
          return (
            <BookWitnessingAgreeView
              onSelection={(value) => {
                if (value === 'yes') {
                  viewForward('datePicker')
                } else {
                  viewForward('email')
                }
              }}
            />
          )

        case 'email':
          return <BookWitnessingEmailView />

        case 'datePicker':
          return (
            <BookWitnessingDatePickerView
              timezone={timezone}
              selectedDate={selectedDate}
              onSelect={({
                selected,
                availableTimeslots: incomingAvailableTimeslots,
              }: SelectedDateInfo) => {
                setSelectedDate(selected)
                setAvailableTimeslots(incomingAvailableTimeslots)
                viewForward('timePicker')
              }}
              dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
            />
          )

        case 'timePicker':
          return (
            <>
              {availableTimeslots && selectedDate && (
                <BookWitnessingTimePickerView
                  timezone={timezone}
                  selectedDate={selectedDate}
                  availableTimeslots={availableTimeslots}
                  onSelect={(selectedTimeslot: SelectedTimeslot) => {
                    setTimeslot(selectedTimeslot)
                    viewForward('mailingAddress')
                  }}
                />
              )}
            </>
          )

        case 'mailingAddress':
          return (
            <BookWitnessingMailingAddressView
              initialMailingAddress={mailingAddress}
              onSubmit={(formData) => {
                setMailingAddress(formData)
                viewForward('confirm')
              }}
            />
          )

        case 'confirm':
          return (
            <>
              {timeslot && mailingAddress && (
                <BookWitnessingConfirmView
                  timeslot={timeslot}
                  mailingAddress={mailingAddress}
                  onSuccess={() => viewForward('success')}
                />
              )}
            </>
          )

        default:
          return <BookWitnessingSuccessView />
      }
    }

    return (
      <Modal
        show={show}
        maxWidth="sm"
        onClose={onClose}
        data-testid="book-witnessing-modal"
      >
        {!['switchboard', 'roadblock', 'success'].includes(
          viewStack[viewStack.length - 1],
        ) && (
          <Button
            size="xs"
            onClick={viewBack}
            iconFontSize="1.05rem"
            label={t('common:back')}
            startIcon={<ArrowBackIcon />}
            data-testid="book-witnessing-modal-back-button"
          />
        )}
        <Box sx={{ px: '0.75rem', py: '2rem' }}>{renderView()}</Box>
      </Modal>
    )
  },
  (prevProps, nextProps) => prevProps.show === nextProps.show,
)

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(null, mapDispatchToProps)(BookWitnessingModal)
