import beforeQuestionnaire from './beforeQuestionnaire'
import createAccount from './createAccount'
import createAccountSuccess from './createAccountSuccess'
import createQuestionnaire from './createQuestionnaire'
import questionnaireFirstCompleted from './questionnaireFirstCompleted'
import updateAnswerStore from './updateAnswerStore'
import updateQuestionnaire from './updateQuestionnaire'

export default [
  beforeQuestionnaire,
  createQuestionnaire,
  createAccount,
  updateAnswerStore,
  updateQuestionnaire,
  createAccountSuccess,
  questionnaireFirstCompleted,
]
