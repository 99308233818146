import documentsTypes from './documentsTypes'

const initialState = {
  documents: [],
  fetchDocumentsWorking: false,
  generateDocumentsWorking: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case documentsTypes.FETCH_DOCUMENTS_WORKING:
      return { ...state, fetchDocumentsWorking: action.payload.working }

    case documentsTypes.GENERATE_DOCUMENTS_WORKING:
      return { ...state, generateDocumentsWorking: action.payload.working }

    case documentsTypes.SET_DOCUMENTS:
      return { ...state, documents: action.payload.documents }

    default:
      return state
  }
}
