import React from 'react'

import { Box } from '@mui/material'

import Modal from '../../../../../../../../common/components/atoms/Modal'
import { Appointment } from '../../../../../../../../types/appointment.types'
import WitnessingAppointmentDetails from './components/WitnessingAppointmentDetails'

interface Props {
  show: boolean
  onClose: () => void
  pendingWitnessingAppointments: Appointment[]
}

const PendingWitnessingAppointmentModal = ({
  show,
  onClose,
  pendingWitnessingAppointments,
}: Props) => (
  <Modal
    show={show}
    maxWidth="sm"
    onClose={onClose}
    data-testid="pending-witnessing-appointment-modal"
  >
    <Box sx={{ p: '0.75rem' }}>
      <WitnessingAppointmentDetails
        onClose={onClose}
        pendingWitnessingAppointments={pendingWitnessingAppointments}
      />
    </Box>
  </Modal>
)

export default PendingWitnessingAppointmentModal
