import _get from 'lodash/get'

import { useQuery } from '@tanstack/react-query'

import { errorHandler, PATHS, request } from '../../../common/request'
import { queryKeys, queryKeysBase } from '../../../common/utils/queryKeys'

const statusStates = {
  ERROR: 'error',
  LOADING: 'loading',
  SUCCESS: 'success',
  INACTIVE: 'inactive',
}

export default ({ dispatchSetGlobalErrorMessage }) => {
  const { status, data: document } = useQuery({
    queryKey: queryKeys[queryKeysBase.socialDocuments](),

    queryFn: async () => {
      const res = await request({
        method: 'GET',
        url: PATHS.SOCIAL_DOCUMENT,
      })
      return _get(res, 'data.payload.documents', [])
    },

    onError: (error) => {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to retrieve your document(s). Please try again later.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    },
  })

  return [document, status, statusStates]
}
