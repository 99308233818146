export default {
  executorResponsibilities: {
    type: 'accordion',
    header: { tKey: 'sidebar:executorResponsibilities.header' },
    children: [
      {
        type: 'text',
        body: { tKey: 'sidebar:executorResponsibilities.children.item1.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:executorResponsibilities.children.item2.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:executorResponsibilities.children.item3.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:executorResponsibilities.children.item4.body' },
      },
    ],
  },
  executorHireProfessional: {
    type: 'accordion',
    header: { tKey: 'sidebar:executorHireProfessional.header' },
    children: [
      {
        type: 'text',
        body: { tKey: 'sidebar:executorHireProfessional.children.item1.body' },
      },
      {
        // Only show this text if the referrer is not TD
        type: 'text',
        body: { tKey: 'sidebar:executorHireProfessional.children.item2.body' },
        // TAG: TD setup
        trigger: { 'referrer.ref': { $ne: 'td' } },
      },
      {
        // Only show this text if the referrer is TD
        type: 'text',
        body: { tKey: 'sidebar:executorHireProfessional.children.item3.body' },
        // TAG: TD setup
        trigger: { 'referrer.ref': 'td' },
      },
    ],
  },
  executorCompensation: {
    type: 'accordion',
    header: { tKey: 'sidebar:executorCompensation.header' },
    children: [
      {
        type: 'text',
        body: { tKey: 'sidebar:executorCompensation.children.item1.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:executorCompensation.children.item2.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:executorCompensation.children.item3.body' },
      },
    ],
  },
  executorOutsideProvince: {
    type: 'accordion',
    header: { tKey: 'sidebar:executorOutsideProvince.header' },
    children: [
      {
        type: 'text',
        body: { tKey: 'sidebar:executorOutsideProvince.children.item1.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:executorOutsideProvince.children.item2.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:executorOutsideProvince.children.item3.body' },
      },
    ],
  },
  multipleExecutors: {
    type: 'accordion',
    header: { tKey: 'sidebar:multipleExecutors.header' },
    children: [
      {
        type: 'text',
        body: { tKey: 'sidebar:multipleExecutors.children.item1.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:multipleExecutors.children.item2.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:multipleExecutors.children.item3.body' },
      },
    ],
  },
}
