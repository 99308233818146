import React, { useState } from 'react'

import _get from 'lodash/get'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { enCA, frCA } from 'date-fns/locale'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../../common/components/atoms/Text'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../../common/styles/muiTheme'
import { Appointment } from '../../../../../../../../../types/appointment.types'
import CancelAppointmentButton from '../../../buttons/CancelAppointmentButton'
import CancelWitnessingSuccess from './CancelWitnessingSuccess'

interface Props {
  onClose: () => void
  pendingWitnessingAppointments: Appointment[]
}

const WitnessingAppointmentDetails = ({
  onClose,
  pendingWitnessingAppointments,
}: Props) => {
  const [cancelAppointmentSuccess, setCancelAppointmentSuccess] =
    useState(false)

  const [witnessingAppointment] = pendingWitnessingAppointments

  const dateTime = _get(witnessingAppointment, 'dateTime')
  const date = dateTime
    ? format(new Date(dateTime), 'EEEE, MMMM d, yyyy', {
        locale:
          i18n.language === CONSTANTS.languageTypes.FR_CA.id ? frCA : enCA,
      })
    : ''
  const time = dateTime
    ? format(new Date(dateTime), 'h:mmaaa', {
        locale:
          i18n.language === CONSTANTS.languageTypes.FR_CA.id ? frCA : enCA,
      })
    : ''

  const { t } = useTranslation()

  return (
    <>
      {cancelAppointmentSuccess ? (
        <CancelWitnessingSuccess />
      ) : (
        <Box data-testid="witnessing-appointment-details-view">
          <Heading align="center" variant="h3" sx={{ mb: '2.2rem' }}>
            {t(
              'documents:components.modals.PendingWitnessingAppointmentModal.components.WitnessingAppointmentDetails.label',
            )}
          </Heading>

          <Box>
            <Text
              size="xs"
              color={themeColorTypes.GREY}
              colorVariant={themeColorVariants.DARK}
              sx={{ display: 'block', mb: '0.35rem' }}
            >
              {t(
                'documents:components.modals.PendingWitnessingAppointmentModal.components.WitnessingAppointmentDetails.date',
              )}
            </Text>
            <Text weight="medium">{date}</Text>
          </Box>

          <Box sx={{ mt: '1.3rem' }}>
            <Text
              size="xs"
              color={themeColorTypes.GREY}
              colorVariant={themeColorVariants.DARK}
              sx={{ display: 'block', mb: '0.35rem' }}
            >
              {t(
                'documents:components.modals.PendingWitnessingAppointmentModal.components.WitnessingAppointmentDetails.startTime',
              )}
            </Text>
            <Text weight="medium">{time}</Text>
          </Box>

          <Box
            sx={{
              mt: '2.1rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <CancelAppointmentButton
              onSuccess={() => setCancelAppointmentSuccess(true)}
            />

            <Button
              size="xs"
              label={t('common:close')}
              onClick={onClose}
              sx={{ mt: '0.5rem' }}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default WitnessingAppointmentDetails
