import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'

import { ClickAwayListener } from '@mui/material'
import Popper from '@mui/material/Popper'

import styled from 'styled-components'

import useResponsive from '../../../../hooks/useResponsive'
import tw from '../../../../styles/tw'
import scrollIntoViewFallback from '../../../../utils/scrollIntoViewFallback'
import AutocompleteItem from './AutocompleteItem'

const StyledPopper = styled(Popper)`
  max-height: 256px;
  overflow-y: scroll;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px ${tw.colors['grey-2']};
`

const AutocompleteBox = ({
  value,
  onSelect,
  anchorEl,
  autocompleteOptions = [],
  displayAllAutocompleteOptions,
}) => {
  const anchorElWidth = anchorEl.getBoundingClientRect().width

  const [open, setOpen] = useState(false)
  const [boxWidth, setBoxWidth] = useState(anchorElWidth)
  const [highlightedIndex, setHighlightedIndex] = useState()

  const listWrapperRef = useRef(null)

  const optionsToDisplay = displayAllAutocompleteOptions
    ? autocompleteOptions
    : autocompleteOptions.filter(
        ({ label }) =>
          label && label.toLowerCase().includes(value.toLowerCase()),
      )

  const handleKeyPress = (e) => {
    if (listWrapperRef.current) {
      const allItems = [...listWrapperRef.current.children]

      if (!_isEmpty(allItems) && open) {
        const key = e.key || e.keyCode

        const enterKey = key === 'Enter' || key === 13
        const arrowUpKey = key === 'ArrowUp' || key === 38
        const arrowDownKey = key === 'ArrowDown' || key === 40
        const escapeKey =
          e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27

        if (arrowDownKey || arrowUpKey || enterKey || escapeKey) {
          e.preventDefault()
        }

        switch (true) {
          case escapeKey:
            setOpen(false)
            break

          case arrowUpKey:
            if (!_isUndefined(highlightedIndex) && highlightedIndex > 0) {
              setHighlightedIndex(highlightedIndex - 1)
            }
            break

          case arrowDownKey:
            if (_isUndefined(highlightedIndex)) {
              setHighlightedIndex(0)
            } else if (highlightedIndex < allItems.length - 1) {
              setHighlightedIndex(highlightedIndex + 1)
            }
            break

          case enterKey:
            if (
              !_isUndefined(highlightedIndex) &&
              highlightedIndex < allItems.length
            ) {
              onSelect(optionsToDisplay[highlightedIndex])
            }
            break

          default:
            break
        }
      }
    }
  }

  const scrollToHighlighted = () => {
    if (listWrapperRef.current) {
      const allItems = [...listWrapperRef.current.children]

      if (!_isEmpty(allItems) && allItems[highlightedIndex]) {
        scrollIntoViewFallback(allItems[highlightedIndex], { block: 'nearest' })
      }
    }
  }

  useEffect(() => {
    scrollToHighlighted()
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  })

  useEffect(() => {
    setBoxWidth(anchorElWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useResponsive()])

  useEffect(() => {
    /*
     * We want to remove the highlighted item because as the
     * user types, the formerly highlighted item may no longer exist
     */
    setHighlightedIndex()

    setOpen(
      optionsToDisplay.every(
        (o) => o?.label?.toLowerCase() !== value.toLowerCase(),
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <StyledPopper
        placement="bottom"
        anchorEl={anchorEl}
        style={{ width: boxWidth }}
        open={open && !_isEmpty(optionsToDisplay)}
        sx={{ zIndex: (theme) => theme.zIndex.tooltip }}
      >
        <div ref={listWrapperRef} className="bg-white w-full dropdown-wrapper">
          {React.Children.toArray(
            optionsToDisplay.map((option, index) => (
              <AutocompleteItem
                option={option}
                onSelect={onSelect}
                highlighted={index === highlightedIndex}
              />
            )),
          )}
        </div>
      </StyledPopper>
    </ClickAwayListener>
  )
}

AutocompleteBox.propTypes = {
  value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  autocompleteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
          tKey: PropTypes.string,
        }),
      ]),
    }).isRequired,
  ),
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]).isRequired,
  displayAllAutocompleteOptions: PropTypes.bool.isRequired,
}

export default AutocompleteBox
