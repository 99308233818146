import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { Box, Tooltip, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../common/components/atoms/Button'
import Checkbox from '../../../../../common/components/atoms/Checkbox'
import Text from '../../../../../common/components/atoms/Text'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import defaultCorpExec from '../../../data/corporateExecutors'
import HelpWithThisQuestionSublabel from '../../molecules/HelpWithThisQuestionSublabel'
import MultiOptionNavButton from '../../molecules/MultiOptionNavButton/MultiOptionNavButton'
import SidebarTextToggle from '../../molecules/SidebarTextToggle'
import RbcFeeDoc from './components/FeeDocs/rbc'

const CorporateExecutorFeeAgreement = ({
  question,
  answerCache,
  answerStore,
  displayValidation,
  handleFieldFragmentUpdate,
}) => {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const { fields, corporateExecutorType } = question
  const [{ name: fieldName, options: fieldOptions }] = fields
  const [
    { value: primaryOptionValue, label: primaryOptionLabel },
    { value: secondaryOptionValue, label: secondaryOptionLabel },
  ] = fieldOptions

  const { firstName, middleName, lastName } = answerStore

  const {
    hasCheckedCorpExecFeeAgreement: cacheHasCheckedCorpExecFeeAgreement,
    corpTrusteeDetails: { agreeDate } = {},
  } = answerCache

  const bottomOfFeeDoc = useRef(null)

  const [hasCheckedCorpExecFeeAgreement, setHasCheckedCorpExecFeeAgreement] =
    useState(!!cacheHasCheckedCorpExecFeeAgreement)
  const [areAllInitialsSigned, setAreAllInitialsSigned] = useState(
    !!cacheHasCheckedCorpExecFeeAgreement,
  )

  const [initialsInfo, setInitialsInfo] = useState([
    {
      isSigned: !!cacheHasCheckedCorpExecFeeAgreement,
      ref: useRef(null),
    },
    {
      isSigned: !!cacheHasCheckedCorpExecFeeAgreement,
      ref: useRef(null),
    },
    {
      isSigned: !!cacheHasCheckedCorpExecFeeAgreement,
      ref: useRef(null),
    },
    {
      isSigned: !!cacheHasCheckedCorpExecFeeAgreement,
      ref: useRef(null),
    },
  ])

  useEffect(() => {
    if (corporateExecutorType === CONSTANTS.corporateExecutorTypes.EXECUTOR) {
      handleFieldFragmentUpdate({
        hasCheckedCorpExecFeeAgreement,
        /*
         * When corporateExecutorType is 'executor', the 'corpTrusteeFees' field is required.
         * Because it's required, we need to force set the value to 'continue' when
         * hasCheckedCorpExecFeeAgreement is true.
         */
        corpTrusteeFees: hasCheckedCorpExecFeeAgreement
          ? 'continue'
          : undefined,
      })
    } else {
      /*
       * For non-primary executor corporateExecutorTypes, the fields
       * 'nonPartnerCorpAltTrusteeFees', 'corpCoTrusteeFees', and 'corpAltTrusteeFees'
       * are optional, we do not need to force-answer them here.
       */
      handleFieldFragmentUpdate({
        hasCheckedCorpExecFeeAgreement,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCheckedCorpExecFeeAgreement])

  useEffect(() => {
    const allInitialsSigned = initialsInfo.every((initial) => initial.isSigned)

    setAreAllInitialsSigned(allInitialsSigned)

    if (!allInitialsSigned) {
      setHasCheckedCorpExecFeeAgreement(false)
    }
  }, [initialsInfo])

  useEffect(() => {
    if (displayValidation && !areAllInitialsSigned) {
      initialsInfo
        .find((initial) => !initial.isSigned)
        .ref.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayValidation])

  const handleInitialsCheck = (initialIndex) => (isSigned) =>
    setInitialsInfo(
      initialsInfo.map((initialsObj, index) =>
        index === initialIndex ? { ...initialsObj, isSigned } : initialsObj,
      ),
    )

  const feeDocTypes = {
    rbc: RbcFeeDoc,
  }

  const FeeDoc = _get(feeDocTypes, defaultCorpExec.ref)

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HelpWithThisQuestionSublabel>
        {t('customQuestion:CorporateExecutorFeeAgreement.subLabel.part1', {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        })}{' '}
        <SidebarTextToggle>
          {t('customQuestion:CorporateExecutorFeeAgreement.subLabel.part2')}
        </SidebarTextToggle>
        .
      </HelpWithThisQuestionSublabel>

      <Button
        size="xxs"
        rounded="normal"
        variant="contained"
        disabled={areAllInitialsSigned}
        color={themeColorTypes.ACCENT_2}
        data-testid="scroll-to-initial-button"
        colorVariant={themeColorVariants.LIGHTER}
        label={
          <Text
            size="sm"
            variant="display"
            sx={{ p: '0.25rem' }}
            color={areAllInitialsSigned ? 'inherit' : undefined}
          >
            {t(
              'customQuestion:CorporateExecutorFeeAgreement.button.nextInitial',
            )}
          </Text>
        }
        onClick={() => {
          initialsInfo
            .find((initial) => !initial.isSigned)
            .ref.current?.scrollIntoView({
              inline: 'start',
              block: 'nearest',
              behavior: 'smooth',
            })
        }}
        sx={{
          mb: '0.5rem',
          alignSelf: isMobile ? 'middle' : 'flex-end',
          color: (theme) =>
            theme.palette[themeColorTypes.BRAND][themeColorVariants.MAIN],
        }}
      />
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette[themeColorTypes.WHITE][themeColorVariants.MAIN],
          borderRadius: (theme) => theme.shape.borderRadiusLarge,
          height: '30rem',
          width: isTablet ? '85vw' : '36.5rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            px: '0.8rem',
            py: '0.5rem',
            overflowY: 'scroll',
            transform: isMobile
              ? 'scale(0.90)'
              : isTablet
                ? 'scale(0.95)'
                : undefined,
          }}
          data-testid="fee-document-area"
        >
          <FeeDoc
            ref={bottomOfFeeDoc}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            initialsInfo={initialsInfo}
            handleInitialsCheck={handleInitialsCheck}
            hasSigned={hasCheckedCorpExecFeeAgreement}
            signatureDateStamp={agreeDate || format(new Date(), 'MMMM d, yyyy')}
          />
        </Box>

        <Tooltip
          disableFocusListener={areAllInitialsSigned}
          disableHoverListener={areAllInitialsSigned}
          disableTouchListener={areAllInitialsSigned}
          title={t('customQuestion:CorporateExecutorFeeAgreement.tooltip')}
          placement="top"
        >
          <Box
            sx={{
              pl: '1.75rem',
              pr: '0.3rem',
              py: '1rem',
              display: 'flex',
              backgroundColor: (theme) =>
                areAllInitialsSigned && !hasCheckedCorpExecFeeAgreement
                  ? theme.palette[themeColorTypes.YELLOW][
                      themeColorVariants.LIGHT
                    ]
                  : theme.palette[themeColorTypes.WHITE][
                      themeColorVariants.MAIN
                    ],

              borderTop: (theme) =>
                `solid 1px ${
                  theme.palette[themeColorTypes.GREY][
                    themeColorVariants.LIGHTEST
                  ]
                }`,

              '& input, & svg': {
                color: (theme) =>
                  displayValidation
                    ? theme.palette[themeColorTypes.RED][
                        themeColorVariants.MAIN
                      ]
                    : areAllInitialsSigned
                      ? undefined
                      : theme.palette[themeColorTypes.GREY][
                          themeColorVariants.LIGHT
                        ],
              },
            }}
          >
            <Checkbox
              isInvalid={displayValidation}
              checked={hasCheckedCorpExecFeeAgreement}
              disabled={!areAllInitialsSigned}
              onChange={(isChecked) => {
                if (isChecked && areAllInitialsSigned) {
                  bottomOfFeeDoc.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                  })
                }
                setHasCheckedCorpExecFeeAgreement(isChecked)
              }}
              label={
                <Text
                  size="xs"
                  color={
                    displayValidation || areAllInitialsSigned
                      ? 'inherit'
                      : themeColorTypes.GREY
                  }
                  colorVariant={
                    displayValidation || areAllInitialsSigned
                      ? undefined
                      : themeColorVariants.LIGHT
                  }
                  sx={{
                    lineHeight: '1.25',
                    ml: '0.5rem',
                    my: isMobile ? '1rem' : undefined,
                  }}
                >
                  {corporateExecutorType ===
                  CONSTANTS.corporateExecutorTypes.EXECUTOR
                    ? t(
                        'customQuestion:CorporateExecutorFeeAgreement.checkbox.executor',
                        {
                          name: _get(
                            defaultCorpExec.displayName,
                            'tKey',
                            defaultCorpExec.displayName,
                          ),
                        },
                      )
                    : corporateExecutorType ===
                        CONSTANTS.corporateExecutorTypes.CO_EXECUTOR
                      ? t(
                          'customQuestion:CorporateExecutorFeeAgreement.checkbox.coExecutor',
                          {
                            name: _get(
                              defaultCorpExec.displayName,
                              'tKey',
                              defaultCorpExec.displayName,
                            ),
                          },
                        )
                      : t(
                          'customQuestion:CorporateExecutorFeeAgreement.checkbox.altExecutor',
                          {
                            name: _get(
                              defaultCorpExec.displayName,
                              'tKey',
                              defaultCorpExec.displayName,
                            ),
                          },
                        )}
                </Text>
              }
            />
          </Box>
        </Tooltip>
      </Box>

      <MultiOptionNavButton
        primaryLabel={primaryOptionLabel}
        primaryQuestionFragment={{
          [fieldName]: primaryOptionValue,
          hasCheckedCorpExecFeeAgreement,
        }}
        secondaryLabel={secondaryOptionLabel}
        secondaryQuestionFragment={{
          [fieldName]: secondaryOptionValue,
          hasCheckedCorpExecFeeAgreement: undefined,
        }}
      />
    </Box>
  )
}

CorporateExecutorFeeAgreement.propTypes = {
  displayValidation: PropTypes.bool.isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  question: PropTypes.shape({
    corporateExecutorType: PropTypes.oneOf(
      Object.values(CONSTANTS.corporateExecutorTypes),
    ),
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  answerCache: PropTypes.shape({
    hasCheckedCorpExecFeeAgreement: PropTypes.bool,
    corpTrusteeDetails: PropTypes.shape({
      agreeDate: PropTypes.string,
    }),
  }).isRequired,
  answerStore: PropTypes.shape({
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
}

export default CorporateExecutorFeeAgreement
