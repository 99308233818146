import { useEffect, useState } from 'react'

import { flushSync } from 'react-dom'

/*
 *
 * To determine the correct value for the 'targetKey' argument,
 * visit: https://keycode.info/ and use the key's event.key value
 *
 */

export default (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false)

  const handleKeyDown = (e) => {
    if (e.key === targetKey) {
      // Use flushSync to prevent React from batching
      // the state updates on a fast keypress (Cypress)
      return flushSync(() => setKeyPressed(true))
    }
    return undefined
  }

  const handleKeyUp = (e) => {
    if (e.key === targetKey) {
      // Use flushSync to prevent React from batching
      // the state updates on a fast keypress (Cypress)
      return flushSync(() => setKeyPressed(false))
    }
    return undefined
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return keyPressed
}
