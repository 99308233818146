import { CONSTANTS } from '@epilogue/common'

import { AGE_OF_MAJORITY, PROVINCES } from '../../../../common/utils/provinces'
import appointeeTypes from '../appointeeTypes'
import tKeyRelationshipOptions from '../utils/tKeyRelationshipOptions'

const section = CONSTANTS.questionnaireSectionTypes.GUARDIANS.id

export default {
  id: section,
  description: { tKey: 'questionnaire.guardians:description' },
  trigger: {
    $or: [{ minorChildren: 'yes' }, { hasPets: 'yes' }],
  },
  questions: [
    {
      id: 1572359378,
      pathname: 'intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.guardians:intro.highlightPillText',
      },
      label: { tKey: 'questionnaire.guardians:intro.label' },
      label2: { tKey: 'questionnaire.guardians:intro.label2' },
    },

    {
      id: 1572358559,
      pathname: 'guardian',
      section,
      label: ({ answerStore: { province } }) => {
        const provinceTKey = PROVINCES.find(
          (provinceObj) => provinceObj.value === province,
        )?.tKey

        return [
          { tKey: 'questionnaire.guardians:guardian.label.part1' },
          {
            term: { tKey: 'questionnaire.guardians:guardian.label.part2.term' },
            definition: {
              tKey: 'questionnaire.guardians:guardian.label.part2.definition',
              options: {
                province: { tKey: provinceTKey },
                ageOfMajority: AGE_OF_MAJORITY[province],
              },
            },
          },
          { tKey: 'questionnaire.guardians:guardian.label.part3' },
        ]
      },

      subLabel: { tKey: 'questionnaire.guardians:guardian.subLabel' },

      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.guardians:guardian.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.guardians:guardian.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item2.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.guardians:guardian.sidebar.item3.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item3.children.item1.body',
              },
            },
            {
              type: 'list',
              variant: 'unordered',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item3.children.item2.body',
              },
              children: [
                {
                  tKey: 'questionnaire.guardians:guardian.sidebar.item3.children.item2.children.item1',
                },
                {
                  tKey: 'questionnaire.guardians:guardian.sidebar.item3.children.item2.children.item2',
                },
                {
                  tKey: 'questionnaire.guardians:guardian.sidebar.item3.children.item2.children.item3',
                },
              ],
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.guardians:guardian.sidebar.item4.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item4.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item4.children.item2.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.guardians:guardian.sidebar.item5.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item5.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item5.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item5.children.item3.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.guardians:guardian.sidebar.item5.children.item4.body',
              },
            },
          ],
        },
      ],

      fields: [
        {
          name: 'guardianName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.guardians:guardian.fields.guardianName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.guardians:guardian.fields.guardianName.reviewLabel',
          },
          appointeeType: appointeeTypes.GUARDIAN,
        },
        {
          name: 'guardianRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: { province: { $exists: true }, minorChildren: 'yes' },
    },

    {
      id: 1572359626,
      pathname: 'alternate-guardian',
      section,
      optional: true,
      label: ({ answerStore: { guardianName } }) => ({
        tKey: 'questionnaire.guardians:alternate-guardian.label',
        options: { guardianName },
      }),
      fields: [
        {
          name: 'alternateGuardianName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.guardians:alternate-guardian.fields.alternateGuardianName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.guardians:alternate-guardian.fields.alternateGuardianName.reviewLabel',
          },
          appointeeType: appointeeTypes.ALTERNATE_GUARDIAN,
        },
        {
          name: 'alternateGuardianRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [{ guardianName: { $exists: true } }, { minorChildren: 'yes' }],
      },
    },

    {
      id: 1572360248,
      pathname: 'pet-guardian',
      section,
      label: { tKey: 'questionnaire.guardians:pet-guardian.label' },
      optional: true,
      fields: [
        {
          name: 'petGuardianName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.guardians:pet-guardian.fields.petGuardianName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.guardians:pet-guardian.fields.petGuardianName.reviewLabel',
          },
          appointeeType: appointeeTypes.PET_GUARDIAN,
        },
        {
          name: 'petGuardianRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: { hasPets: 'yes' },
    },

    {
      id: 1572360249,
      pathname: 'alternate-pet-guardian',
      section,
      optional: true,
      label: ({ answerStore: { petGuardianName } }) => ({
        tKey: 'questionnaire.guardians:alternate-pet-guardian.label',
        options: { petGuardianName },
      }),
      fields: [
        {
          name: 'alternatePetGuardianName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.guardians:alternate-pet-guardian.fields.alternatePetGuardianName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.guardians:alternate-pet-guardian.fields.alternatePetGuardianName.reviewLabel',
          },
          appointeeType: appointeeTypes.ALTERNATE_PET_GUARDIAN,
        },
        {
          name: 'alternatePetGuardianRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [{ hasPets: 'yes' }, { petGuardianName: { $exists: true } }],
      },
    },

    {
      id: 1568476736,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: { tKey: 'questionnaire.guardians:review.label' },
      subLabel: { tKey: 'questionnaire.guardians:review.subLabel' },
    },
  ],
}
