import { ReactElement } from 'react'

import { RawObject } from 'mingo/types'

import { Buttons } from './button.types'

export enum StatusTypes {
  COMPLETE = 'complete',
  CONTACT_US = 'contactUs',
  GET_STARTED = 'getStarted',
  IN_PROGRESS = 'inProgress',
  PAYMENT_REQUIRED = 'paymentRequired',
}

export interface Status {
  documentPaid?: boolean
  documentCreated?: boolean
  staticDocumentStatusOverride?: StatusTypes
}

type HighlightPills = {
  [key in StatusTypes]?: string
}

export interface DocumentCardInfo {
  name: string
  buttons: Buttons
  description: string[]
  status: (obj: Status) => StatusTypes
  label: string | ((resolvedStatus: StatusTypes) => ReactElement)

  icon?: string
  paid?: boolean
  // TS-TODO: update this when documents/docTypes are typed
  docType?: string
  created?: string
  stripeColor?: string
  trigger?: RawObject
  highlightPills?: HighlightPills
}

export interface DocumentCardsInfo {
  label: string
  cards: DocumentCardInfo[]
  topBanner?: ReactElement
}
