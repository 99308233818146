import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Cotisations au REER

Les cotisations au Régime enregistré d'épargne-retraite (REER) sont déductibles d'impôt, ce qui signifie qu'elles peuvent réellement réduire le montant d'impôt sur le revenu que vous devez payer.

Tout le monde peut faire des cotisations à son REER chaque année, jusqu'à leur limite de cotisation personnelle. La limite de cotisation de chaque personne est basée sur le montant du revenu qu'elle a gagné l'année précédente, mais elle ne peut pas dépasser 30 780 $ (le maximum pour 2023). Les cotisations au REER pour une année d'imposition peuvent être effectuées à partir du 61e jour de l'année civile (généralement le 2 mars) jusqu'au 60e jour de l'année civile suivante (généralement le 1er mars).

Si vous n'utilisez pas toute votre place de cotisation une année, elle est reportée à l'année suivante. Si vous voulez savoir combien de place de cotisation au REER vous avez, consultez l'avis de cotisation que vous recevez de l'ARC après avoir produit votre déclaration de revenus.

Les cotisations au REER peuvent être effectuées jusqu'à la fin de l'année civile au cours de laquelle vous atteignez l'âge de 71 ans. À ce moment-là, le REER doit être converti en Fonds enregistré de revenu de retraite (FERR) ou utilisé pour acheter une rente.

#### Que se passe-t-il avec un REER en cas de décès ?

Le propriétaire d'un REER désigne généralement un ou plusieurs "bénéficiaires désignés" du REER lorsque le compte est ouvert. Si le propriétaire décède, le REER est distribué directement à ces personnes et ne fait pas partie de la succession de la personne. C'est pourquoi votre REER n'est pas mentionné dans votre testament.

S'il n'y a pas de bénéficiaires désignés (ou s'ils sont tous décédés avant le propriétaire du REER), le REER est versé à la succession et est distribué selon les modalités du testament de la personne, ainsi que les autres actifs.

#### REER et impôts

Pendant la vie du propriétaire d'un REER, il bénéficie de cotisations déductibles d'impôt au compte et d'une croissance différée d'impôt dans le REER. Ces avantages fiscaux prennent fin lorsque le propriétaire d'un REER décède. La valeur totale du REER à la date du décès est incluse dans le revenu imposable de la personne au cours de l'année du décès et l'impôt doit être payé par sa succession.

Il existe quelques situations dans lesquelles les avantages fiscaux du REER peuvent se poursuivre. La plus courante est lorsque le conjoint ou le conjoint de fait est désigné comme seul bénéficiaire désigné du REER. Dans ce cas, le conjoint/partenaire peut transférer le REER dans son propre REER (ou certains autres comptes enregistrés) sans conséquences fiscales immédiates. Cela permet de reporter l'impôt jusqu'au décès de ce conjoint/conjoint de fait.

#### Quelles démarches puis-je entreprendre ?

Il est important de savoir si les bénéficiaires de votre REER sont les mêmes ou différents des bénéficiaires de votre succession. C'est parce que c'est la succession - et non les bénéficiaires désignés - qui doit payer l'impôt associé au REER. Si votre succession doit payer beaucoup d'impôts en raison de votre REER, les bénéficiaires de votre succession peuvent se retrouver avec moins que prévu.

Si vous n'êtes pas sûr des bénéficiaires désignés de votre REER, vous pouvez contacter votre institution financière pour vous renseigner.
    `
    : `
#### RRSP Contributions

Registered Retirement Savings Plan (RRSP) contributions are tax-deductible, meaning that they can actually reduce the amount of income tax you have to pay.

Everyone can make contributions to their RRSP each year, up to their personal contribution limit. Each person's contribution limit is based on the amount of income they earned in the prior year but it cannot exceed $30,780 (the maximum for 2023). RRSP contributions for a taxation year can be made starting on the 61st day of the calendar year (usually March 2) up until the 60th day of the following calendar year (usually March 1).

If you don't use all your contribution room in one year, it carries over to the following year. If you want to know how much RRSP contribution room you have, check the Notice of Assessment you receive from CRA after you file your income taxes.

RRSP contributions can be made until the end of the calendar year in which you turn 71. By that time, the RRSP needs to be converted to a Registered Retirement Income Fund (RRIF) or used to buy an annuity.

#### What Happens to an RRSP on Death?

The owner of an RRSP usually names one or more "designated beneficiaries" of the RRSP when the account is opened. If the owner dies, the RRSP is distributed directly to these individuals, and does not end up as part of the person's estate. That is why your RRSP is not referenced in your Will.

If there are no designated beneficiaries (or if they all died before the owner of the RRSP), the RRSP is paid to the estate and is distributed according to the terms of the person's Will, along with the other assets.

#### RRSPs and Taxes

While an RRSP owner is alive, they benefit from tax-deductible contributions to the account and tax-deferred growth in the RRSP. These tax advantages end when an RRSP owner dies. The entire value of the RRSP on the date of death is included in the person's taxable income in the year of death and the tax needs to be paid by their estate. 

There are a few situations in which the RRSP's tax advantages can continue. The most common is where a spouse or common-law partner is named as the sole designated beneficiary of the RRSP. In this case, the spouse/partner can transfer the RRSP to his/her own RRSP (or certain other registered accounts) with no immediate tax consequences. This allows the deferral to continue until the death of that spouse/partner.

#### What Steps Can I Take?

It is important to know whether the beneficiaries of your RRSP are the same or different as the beneficiaries of your estate. This is because it is the estate — not the designated beneficiaries — that must pay the tax associated with the RRSP. If your estate has to pay lots of tax because of your RRSP, the beneficiaries of your estate may end up with less than you expect.

If you are unsure who you have named as the designated beneficiaries on your RRSP, you can contact your financial institution to inquire.
`
