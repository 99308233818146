import React from 'react'

import { Link } from 'react-router-dom'

import ButtonComponent from '../../../../../../../common/components/atoms/Button'
import amplitude from '../../../../../../../common/lib/amplitude'
import { Button } from '../../types/button.types'

const NavigateActionButton = ({
  action,
  fullWidth,
  parentName,
  ...props
}: Button) => (
  <>
    {action.type === 'navigate' && (
      <Link
        to={action.path}
        target={action?.openInNewTab ? '_blank' : '_self'}
        style={{ width: fullWidth ? '100%' : 'fit-content' }}
        onClick={() => {
          amplitude.sendEvent('ClickedNavigateActionButton', {
            label: props.label,
            path: action.path,
            parentName,
          })
        }}
      >
        <ButtonComponent
          size="xs"
          iconFontSize="1.2em"
          sx={{
            maxHeight: '37px',
            width: fullWidth ? '100%' : 'fit-content',
          }}
          {...props}
        />
      </Link>
    )}
  </>
)

export default NavigateActionButton
