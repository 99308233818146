import { CONSTANTS } from '@epilogue/common'

import queryString from '../lib/queryString'
import validWorld from './validWorld'

export default () => {
  const { w: queryWorld } = queryString.parse(window.location.search)
  // fallback to 'will' world-type if not explicitly set in query params
  return validWorld(queryWorld) ? queryWorld : CONSTANTS.worldTypes.will.type
}
