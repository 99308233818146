import _isEqual from 'lodash/isEqual'
import _toPairs from 'lodash/toPairs'

/*
 * Checks if all the key-value pairs from object2 'fit' into object1.
 * If not, return true.
 * If so, return false.
 */
export default (object1, object2) =>
  _toPairs(object2).some((entry) => {
    const [key, value] = entry
    return !_isEqual(object1[key], value)
  })
