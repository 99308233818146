import { useState } from 'react'

import { CONSTANTS } from '@epilogue/common'
import download from 'downloadjs'

import useProvinceDictionary from '../../../../../../common/hooks/useProvinceDictionary'
import amplitude from '../../../../../../common/lib/amplitude'
import event from '../../../../../../common/lib/event'
import { errorHandler, PATHS, request } from '../../../../../../common/request'
import buildFilename from '../utils/buildFilename'

export enum DownloadStatus {
  IDLE = 'idle',
  ERROR = 'error',
  WORKING = 'working',
  SUCCESS = 'success',
}

interface Props {
  userId: string
  answerStore: any
  generatedDocument: any
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

export default ({
  userId,
  dispatchSetGlobalErrorMessage,
  generatedDocument: { _id: docId, docType },
  answerStore: { province, firstName, lastName },
}: Props) => {
  const [status, setStatus] = useState(DownloadStatus.IDLE)

  const { provinceDictionary } = useProvinceDictionary()

  const requestDocDownload = async () => {
    setStatus(DownloadStatus.WORKING)

    try {
      /*
       * We need to send the 'download document' event before the request even
       * begins because on Safari iOS the native iOS download alert kills the event
       * request before the response arrives.
       */
      event.send({
        name: CONSTANTS.eventNames.DOWNLOAD_CORE_DOCUMENT,
        action: CONSTANTS.eventActionTypes.SUCCESS,
        metadata: { userId, docType },
      })

      const res = await request({
        method: 'GET',
        responseType: 'arraybuffer',
        params: {
          type: CONSTANTS.documentDownloadEventTypes.GENERATED_INDIVIDUAL,
        },
        url: `${PATHS.DOCUMENT_DOWNLOAD_DOC}/${docId}`,
      })

      download(
        res.data,
        buildFilename({
          firstName,
          lastName,
          docType: provinceDictionary(docType, province),
        }),
      )
      // eslint-disable-next-line no-console
      console.log(`${docType} download success ✅`)
      setStatus(DownloadStatus.SUCCESS)
      amplitude.sendEvent('DownloadedDocument', { docType })
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to download document. Please refresh and try again.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
      setStatus(DownloadStatus.ERROR)
    }
  }

  return { requestDocDownload, DownloadStatus, status, setStatus }
}
