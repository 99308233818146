import React, { useContext } from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../common/components/atoms/Text'
import HighlightPill from '../../../../../../../../common/components/molecules/HighlightPill'
import { themeColorTypes } from '../../../../../../../../common/styles/muiTheme'
import i18n from '../../../../../../../../i18n'
import LawyerReferralContext from '../../../context/LawyerReferralContext'

const FlatFeeLawyerInfo = () => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('2xl'))

  const {
    /*
     * For now, for flat fee referral pages, we only take the
     * first lawyer in the array
     */
    lawyers: [lawyer],
  } = useContext(LawyerReferralContext)

  const { worksRemote, flatFeePricing, highlights } = lawyer

  const { t } = useTranslation()

  return (
    <Box
      flex="1"
      alignSelf="center"
      marginRight={isDesktop ? '5rem' : 0}
      marginBottom={isDesktop ? 0 : '2.8rem'}
    >
      <Box
        display="flex"
        rowGap="0.7rem"
        flexDirection="column"
        alignItems={isDesktop ? 'flex-start' : 'center'}
      >
        <Heading variant="h5" color={themeColorTypes.ACCENT_1}>
          {t(
            'molecules:LawyerReferral.components.FlatFeeLawyerReferral.components.FlatFeeLawyerInfo.label',
          )}
        </Heading>

        <Heading variant="h3">
          {t(
            'molecules:LawyerReferral.components.FlatFeeLawyerReferral.components.FlatFeeLawyerInfo.label2',
            { val: flatFeePricing },
            { lng: i18n.language },
          )}
        </Heading>
        {worksRemote && (
          <HighlightPill
            label={t('molecules:LawyerReferral.virtualMeetingPillLabel')}
          />
        )}
      </Box>

      <Box
        display="flex"
        rowGap="2rem"
        marginTop="2.5rem"
        flexDirection="column"
        alignItems="flex-start"
      >
        {/* Every highlight must at least have an English version */}
        {highlights.every((h) => h[CONSTANTS.languageTypes.EN_CA.id]) &&
          React.Children.toArray(
            highlights.map((highlight) => (
              <Box display="flex" alignItems="center" columnGap="0.8rem">
                <CheckCircleIcon color="secondary" />
                <Text size="sm" variant="paragraph" align="left">
                  {/*
                   * If highlight exists in current selected language, use it.
                   * If not, fallback to English.
                   */}
                  {highlight[i18n.language] ||
                    highlight[CONSTANTS.languageTypes.EN_CA.id]}
                </Text>
              </Box>
            )),
          )}
      </Box>
    </Box>
  )
}

export default FlatFeeLawyerInfo
