import _has from 'lodash/has'
import _isEmpty from 'lodash/isEmpty'

export default (theData, questions) => {
  const obj = {}

  for (const section of theData) {
    if (!_has(obj, section.id)) {
      obj[section.id] = questions.filter(
        (q) =>
          q.section === section.id && !q.hideOnReview && !_isEmpty(q.fields),
      )
    }
  }

  return obj
}
