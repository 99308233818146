import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'

import { createLogic } from 'redux-logic'

import commonSelectors from '../../../../../common/ducks/commonSelectors'
import dashboardSelectors from '../../../../Dashboard/ducks/dashboard/dashboardSelectors'
import documentsSelectors from '../../../../Dashboard/ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../../Dashboard/ducks/payments/paymentsSelectors'
import data from '../../../data'
import {
  nextUnansweredQuestion,
  questionnaireProgress,
  sectionProgress,
} from '../../../engine'
import questionnaireActions from '../../questionnaire/questionnaireActions'
import questionnaireSelectors from '../../questionnaire/questionnaireSelectors'
import validationActions from '../../validation/validationActions'
import validationSelectors from '../../validation/validationSelectors'
import navigationActions from '../navigationActions'
import navigationSelectors from '../navigationSelectors'
import navigationTypes from '../navigationTypes'

export default createLogic({
  name: 'updateProgress',
  type: [
    navigationTypes.UPDATE_QUESTION_STACK,
    navigationTypes.PUSH_QUESTION,
    navigationTypes.PREVIOUS_QUESTION,
  ],

  process({ getState }, dispatch, done) {
    const preventAnimation = navigationSelectors.getPreventAnimation(
      getState().questionnaire,
    )

    const displayValidation = validationSelectors.getDisplayValidation(
      getState().questionnaire,
    )

    if (displayValidation) {
      dispatch(validationActions.displayValidation(false))
    }

    if (preventAnimation) {
      dispatch(navigationActions.preventAnimation(false))
    }

    const answerStore = questionnaireSelectors.getAnswerStore(
      getState().questionnaire,
    )
    const isPartner = dashboardSelectors.getIsPartner(getState().dashboard)
    const accountCreated = questionnaireSelectors.getAccountCreated(
      getState().questionnaire,
    )
    const abTest = questionnaireSelectors.getAbTest(getState().questionnaire)
    const payments = paymentsSelectors.getPayments(getState().dashboard)
    const referrer = questionnaireSelectors.getReferrer(
      getState().questionnaire,
    )

    const charityPartner = commonSelectors.getCharityPartner(getState())
    const prevNextUnansweredQuestion =
      navigationSelectors.getNextUnansweredQuestion(getState().questionnaire)

    const documents = documentsSelectors.getDocuments(getState().dashboard)

    const interceptModalData = navigationSelectors.getInterceptModal(
      getState().questionnaire,
    )

    const currentNextUnansweredQuestion = nextUnansweredQuestion({
      data,
      abTest,
      payments,
      referrer,
      isPartner,
      documents,
      answerStore,
      accountCreated,
      charityPartner,
    })

    // update nextUnansweredQuestion when the value changes from previous value
    if (!_isEqual(prevNextUnansweredQuestion, currentNextUnansweredQuestion)) {
      dispatch(
        navigationActions.setNextUnansweredQuestion(
          currentNextUnansweredQuestion,
        ),
      )
      /*
       * If the nextUnansweredQuestion changes, we check if there is still at least
       * another question. Until nextUnansweredQuestion is empty, we assume the
       * questionnaire is not complete.
       */
      dispatch(
        questionnaireActions.setQuestionnaireValid(
          _isEmpty(currentNextUnansweredQuestion),
        ),
      )
    }

    const currentQuestion = navigationSelectors.getCurrentQuestion(
      getState().questionnaire,
    )

    const theSectionProgress = sectionProgress(currentQuestion, data)
    dispatch(
      navigationActions.updateSectionProgress({
        progress: theSectionProgress,
      }),
    )

    const theQuestionnaireProgress = questionnaireProgress({
      data,
      abTest,
      referrer,
      payments,
      isPartner,
      documents,
      answerStore,
      accountCreated,
      charityPartner,
    })
    dispatch(
      navigationActions.updateQuestionnaireProgress({
        progress: theQuestionnaireProgress,
      }),
    )

    /*
     * When moving to a new question, the interceptModal
     * should always be an empty object
     */
    if (!_isEmpty(interceptModalData)) {
      dispatch(navigationActions.interceptModal({}))
    }

    done()
  },
})
