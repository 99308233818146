import _get from 'lodash/get'

import { useQuery } from '@tanstack/react-query'

import { errorHandler, PATHS, request } from '../request'
import { queryKeys, queryKeysBase } from '../utils/queryKeys'

interface Props {
  token: string
  questionnaireId: string
}

export default ({ questionnaireId, token }: Props) =>
  useQuery({
    queryKey: queryKeys[queryKeysBase.toggles](),

    queryFn: () =>
      request({
        method: 'GET',
        params: { token },
        url: `${PATHS.TOGGLE}/${questionnaireId}`,
      }),

    select: (res) => _get(res, ['data', 'payload'], {}),
    retry: process.env.REACT_APP_ENV === 'production' ? 2 : 0,
    onError: (error) => errorHandler({ error, reportError: true }),
  })
