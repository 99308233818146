import React from 'react'
import PropTypes from 'prop-types'

import Heading from '../Heading'

const StyledLabel = ({ children, 'data-testid': dataTestId = undefined }) => (
  <Heading
    variant="h2"
    data-testid={dataTestId}
    sx={{
      fontSize: '24.4px',
      lineHeight: '33.5px',
      letterSpacing: '-0.92px',

      '@media (max-width: 700px)': {
        fontSize: '22.8px',
        lineHeight: '31px',
      },
    }}
  >
    {children}
  </Heading>
)

StyledLabel.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default StyledLabel
