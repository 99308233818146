import bonusDocsTypes from './bonusDocsTypes'

export default {
  setBonusDocsAnswerStore: (bonusDocsAnswerStore) => ({
    type: bonusDocsTypes.SET_BONUS_DOCS_ANSWER_STORE,
    payload: bonusDocsAnswerStore,
  }),

  updateBonusDocsAnswerStoreType: ({ type, fragment }) => ({
    type: bonusDocsTypes.UPDATE_BONUS_DOCS_ANSWER_STORE_TYPE,
    payload: { type, fragment },
  }),
}
