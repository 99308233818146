import React from 'react'

import _camelCase from 'lodash/camelCase'
import _isFunction from 'lodash/isFunction'

import { Box } from '@mui/material'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Text from '../../../../../../common/components/atoms/Text'
import HighlightPill from '../../../../../../common/components/molecules/HighlightPill'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import documentCardStyleProperties from '../data/documentCardStyleProperties'
import useStaticDocumentDownloadEvents from '../hooks/useStaticDocumentDownloadEvents'
import { ButtonActionType, ButtonTypes } from '../types/button.types'
import { DocumentCardInfo, StatusTypes } from '../types/documentCards.types'
import DownloadActionButton from './buttons/DownloadActionButton'
import ModalActionButton from './buttons/ModalActionButton'
import NavigateActionButton from './buttons/NavigateActionButton'

const STRIPE_WIDTH = '10px'
const TOP_SECTION_HEIGHT = '47.5px'
const CARD_MAIN_SECTION_PADDING = '1.2rem'
const CARD_OUTER_SECTION_PADDING = '0.75rem'

// TS-TODO: consider moving these types to a types.ts file
type ButtonActionTypes = Pick<ButtonActionType, 'type'>['type']

type ButtonActionComponent = {
  [key in ButtonActionTypes]: any
}

const buttonComponentTypes: ButtonActionComponent = {
  modal: ModalActionButton,
  download: DownloadActionButton,
  navigate: NavigateActionButton,
}

interface Props extends DocumentCardInfo {
  answerStore: any
  triggerObj: any
  generatedDocument?: any
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const DocumentCard = ({
  name,
  icon,
  paid,
  label,
  status,
  buttons,
  docType,
  created,
  triggerObj,
  description,
  answerStore,
  stripeColor,
  highlightPills,
  generatedDocument = {},
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { t } = useTranslation()

  // data returns a Set of static docTypes that have been downloaded by the user
  const { data: staticDocumentDownloadEventsData = new Set() } =
    useStaticDocumentDownloadEvents()

  const cardStatus = status({
    documentPaid: paid,
    documentCreated: !!created,
    staticDocumentStatusOverride: staticDocumentDownloadEventsData.has(docType)
      ? StatusTypes.COMPLETE
      : StatusTypes.GET_STARTED,
  })
  const {
    outline,
    opacity,
    displayStripe,
    backgroundColor,
    pillBackgroundColor,
  } = documentCardStyleProperties[cardStatus]

  const showStripe = displayStripe && stripeColor
  const highlightPillLabel = highlightPills && highlightPills[cardStatus]
  const resolvedLabel = _isFunction(label) ? label(cardStatus) : label

  const primaryButton = buttons[cardStatus]?.[ButtonTypes.PRIMARY]
  const secondaryButton = buttons[cardStatus]?.[ButtonTypes.SECONDARY]
  const tertiaryButton = buttons[cardStatus]?.[ButtonTypes.TERTIARY]

  const PrimaryButtonComponent =
    primaryButton && buttonComponentTypes[primaryButton.action.type]

  const SecondaryButtonComponent =
    secondaryButton && buttonComponentTypes[secondaryButton.action.type]

  const TertiaryButtonComponent =
    tertiaryButton && buttonComponentTypes[tertiaryButton.action.type]

  const baseButtonComponentProps = {
    triggerObj,
    answerStore,
    generatedDocument,
    dispatchSetGlobalErrorMessage,
    parentName: name,
  }

  return (
    <Box
      sx={{
        outline,
        width: '270px',
        height: '323px',
        backgroundColor,
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '0.75rem',
      }}
      data-testid={`document-card-${_camelCase(name)}`}
    >
      <Box sx={{ opacity, height: '83%' }}>
        {showStripe && (
          <Box
            sx={{
              top: '0',
              left: '0',
              height: '100%',
              width: STRIPE_WIDTH,
              position: 'absolute',
              backgroundColor: stripeColor,
            }}
          />
        )}

        <Box
          sx={{
            pb: '0.4rem',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* CARD TOP SECTION */}
          <Box
            sx={{
              pb: '0',
              display: 'flex',
              minHeight: TOP_SECTION_HEIGHT,
              pl: CARD_MAIN_SECTION_PADDING,
              pr: STRIPE_WIDTH,
              pt: icon ? CARD_OUTER_SECTION_PADDING : '0.4rem',
              justifyContent: tertiaryButton?.label ? 'flex-end' : 'flex-start',
            }}
          >
            <>
              {icon ? (
                <Box
                  alt=""
                  src={icon}
                  component="img"
                  sx={{
                    height: `calc(${TOP_SECTION_HEIGHT} - ${CARD_OUTER_SECTION_PADDING} /1.1)`,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {highlightPillLabel && (
                    <Box
                      sx={{
                        alignSelf: 'flex-end',
                        pl: showStripe ? STRIPE_WIDTH : '0',
                      }}
                    >
                      <HighlightPill
                        label={highlightPillLabel}
                        dataTestIdModifier={_camelCase(name)}
                        textColorTypeOverride={themeColorTypes.GREY}
                        backgroundColorOverride={pillBackgroundColor}
                        textColorVariantOverride={themeColorVariants.DARK}
                      />
                    </Box>
                  )}

                  {TertiaryButtonComponent && (
                    <Box>
                      <TertiaryButtonComponent
                        {...baseButtonComponentProps}
                        {...tertiaryButton}
                        data-testid={`document-card-${_camelCase(
                          name,
                        )}-tertiary-button`}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </>
          </Box>

          {/* CARD MAIN/BODY SECTION */}
          <Box
            sx={{
              pt: '0.9rem',
              height: '100%',
              pr: CARD_MAIN_SECTION_PADDING,
              pl: showStripe
                ? `calc(${CARD_MAIN_SECTION_PADDING} + ${STRIPE_WIDTH})`
                : CARD_MAIN_SECTION_PADDING,
            }}
          >
            <Text
              weight="medium"
              sx={{ mb: '0.6rem', fontSize: '1.2rem', lineHeight: '1.3' }}
            >
              {resolvedLabel}
            </Text>

            {created && (
              <Box
                sx={{ mt: '-0.15rem', mb: '0.5rem', letterSpacing: '0.1px' }}
              >
                <Text color="grey" size="xs">
                  {t('common:lastUpdated')}:{' '}
                  {format(new Date(created), 'MMMM d, yyyy')}
                </Text>
              </Box>
            )}

            {React.Children.toArray(
              description.map((item, i) => (
                <Text
                  variant="paragraph"
                  sx={{
                    lineHeight: '1.25',
                    fontSize: '1.03125rem',
                    mt: i === 0 ? '0' : '0.7rem',
                  }}
                >
                  {item}
                </Text>
              )),
            )}
          </Box>
        </Box>
      </Box>

      {/* CARD BOTTOM SECTION */}
      <Box
        sx={{
          pt: '0',
          right: '0',
          bottom: '0',
          height: '17%',
          width: '100%',
          display: 'flex',
          position: 'absolute',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          pr: SecondaryButtonComponent
            ? STRIPE_WIDTH
            : CARD_MAIN_SECTION_PADDING,
          pl: SecondaryButtonComponent
            ? CARD_MAIN_SECTION_PADDING
            : showStripe
              ? `calc(${STRIPE_WIDTH} + ${CARD_MAIN_SECTION_PADDING})`
              : CARD_MAIN_SECTION_PADDING,
          pb:
            !primaryButton?.variant && !secondaryButton?.variant
              ? '0.4rem'
              : CARD_OUTER_SECTION_PADDING,
        }}
      >
        {PrimaryButtonComponent && (
          <PrimaryButtonComponent
            {...baseButtonComponentProps}
            {...primaryButton}
            data-testid={`document-card-${_camelCase(name)}-primary-button`}
          />
        )}

        <Box sx={{ ml: '0.3rem' }}>
          {SecondaryButtonComponent && (
            <SecondaryButtonComponent
              {...baseButtonComponentProps}
              {...secondaryButton}
              data-testid={`document-card-${_camelCase(name)}-secondary-button`}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default DocumentCard
