import { isFieldValidOrOptional } from '../utils/validation/validation'
import isTriggered from './isTriggered'

export default ({
  abTest,
  referrer,
  question,
  isPartner,
  answerStore,
  charityPartner,
}) => {
  const {
    fields,
    trigger: questionTrigger,
    optional: isOptionalQuestion,
  } = question
  /*
   * Will skip any question without fields (such as 'sectionReview', 'intro', etc...)
   * or an optional question
   */

  if (!fields || isOptionalQuestion) {
    return true
  }

  /*
   * Every field of the question must be either optional or answered
   * for the question to be considered 'triggered and answered'
   */
  const allRequiredFieldsAnsweredOrOptional = fields.every((field) =>
    isFieldValidOrOptional(field, answerStore[field.name]),
  )

  return (
    // allRequiredFieldsAnsweredOrOptional must always be true if we make it this far
    allRequiredFieldsAnsweredOrOptional &&
    /*
     * Either the question must not have a trigger at all to be true, or it
     * has a trigger and that trigger is true
     */
    (!questionTrigger ||
      isTriggered(questionTrigger, {
        abTest,
        referrer,
        isPartner,
        answerStore,
        charityPartner,
      }))
  )
}
