import React from 'react'

import { styled } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as Coins } from '../../../assets/images/coins.svg'
import { getReferrerFromCookies } from '../../../utils/referrer'
import ValueProposition from './ValueProposition'

const StyledCoins = styled(Coins)({
  height: '50.79px',
  width: '56px',
})

const CharityValueProposition = () => {
  const reftag = getReferrerFromCookies()?.reftag

  // TAG: STOLLERY
  const displayStolleryFreeWillCopy =
    reftag === 'stollery-childrens-hospital-foundation-122073158rr0001-gift8380'

  const { t } = useTranslation()

  return (
    <ValueProposition
      hideBottomBorder
      icon={<StyledCoins />}
      label={
        // TAG: STOLLERY
        displayStolleryFreeWillCopy
          ? t(
              'components:GetStarted.valueProps.charity.stollery.valueProp1.label',
            )
          : t(
              'components:GetStarted.valueProps.charity.default.valueProp1.label',
            )
      }
      description={
        // TAG: STOLLERY
        displayStolleryFreeWillCopy
          ? t(
              'components:GetStarted.valueProps.charity.stollery.valueProp1.description',
            )
          : t(
              'components:GetStarted.valueProps.charity.default.valueProp1.description',
            )
      }
    />
  )
}
export default CharityValueProposition
