import React from 'react'
import PropTypes from 'prop-types'

import { Link as MaterialLink } from '@mui/material'

const Link = ({
  sx,
  href,
  onClick,
  children,
  underline = 'always',
  className,
  openNewTab = false,
}) => (
  <MaterialLink
    sx={sx}
    href={href}
    onClick={onClick}
    className={className}
    underline={underline}
    target={openNewTab ? '_blank' : '_self'}
  >
    {children}
  </MaterialLink>
)

Link.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
  openNewTab: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  underline: PropTypes.oneOf(['none', 'hover', 'always']),
}

export default Link
