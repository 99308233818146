import React from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import Button from '../../../../../../../../../common/components/atoms/Button'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../../common/styles/muiTheme'

interface SecondaryButtonProps {
  label: string
  disabled?: boolean
  forwardArrow?: boolean
  onClick: (e?: any) => any
}

const SecondaryButton = ({
  label,
  onClick,
  disabled,
  forwardArrow = true,
}: SecondaryButtonProps) => (
  <Button
    size="xs"
    label={label}
    onClick={onClick}
    disabled={disabled}
    iconFontSize="1rem"
    color={themeColorTypes.GREY}
    colorVariant={themeColorVariants.DARK}
    data-testid="documents-guide-modal-secondary-button"
    endIcon={forwardArrow && <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />}
  />
)

export default SecondaryButton
