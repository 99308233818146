import React from 'react'
import PropTypes from 'prop-types'

import { Avatar as MuiAvatar } from '@mui/material'

const Avatar = ({ src, className, alt }) => (
  <MuiAvatar src={src} className={className} alt={alt} />
)

Avatar.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default Avatar
