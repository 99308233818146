import React, { Fragment, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import HeaderContext from '../../context/HeaderContext'
import AccountAvatar from '../AccountAvatar'
import LogoutButton from '../LogoutButton'
import NavItem from '../NavItem'
import navItemsByWorld from './data/navItemsByWorld'

const NavItems = ({ isMobile = false }) => {
  const { t } = useTranslation()

  const [navItems, setNavItems] = useState()

  const { world, isDesktop, accountCreated, onAccountSettings } =
    useContext(HeaderContext)

  useEffect(() => setNavItems(navItemsByWorld(t)[world]), [world, t])

  return (
    <>
      {onAccountSettings ? (
        /*
         * We only give the user a logout button, and not all of the
         * other navigation options because we're unsure which world
         * they are coming from
         */
        <LogoutButton />
      ) : (
        <div className="inline-flex">
          {accountCreated && (
            <div
              data-testid="nav-items-wrapper"
              className={cn('flex', {
                'flex-col': !isDesktop,
                'items-center': !isMobile,
              })}
            >
              {navItems && (
                <>
                  {Object.values(navItems).map(({ id, label, to }) => (
                    <Fragment key={id}>
                      <NavItem id={id} to={to} label={label} />
                    </Fragment>
                  ))}
                </>
              )}

              <>
                <Box sx={{ pl: isMobile ? '0' : '0.5rem' }}>
                  <AccountAvatar isMobile={isMobile} />
                </Box>
              </>
            </div>
          )}
        </div>
      )}
    </>
  )
}

NavItems.propTypes = { isMobile: PropTypes.bool }

export default NavItems
