// TAG: notify-appointees
import React, { useEffect, useMemo } from 'react'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'
import _uniq from 'lodash/uniq'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../common/components/atoms/Text'
import commonActions from '../../../../../../../common/ducks/commonActions'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { Appointee } from '../../../../../ducks/appointees/appointees.types'
import appointeesActions from '../../../../../ducks/appointees/appointeesActions'
import appointeesSelectors from '../../../../../ducks/appointees/appointeesSelectors'
import dashboardSelectors from '../../../../../ducks/dashboard/dashboardSelectors'
import NotifyAppointee from './components/NotifyAppointee'
import batchAppointees, { BatchedAppointee } from './utils/batchAppointees'

interface Props {
  answerStore: any
  isEmbedded?: boolean
  appointees: Appointee[]
  customerReferralCode?: string
  dispatchGetAppointees: () => void
  onAllAppointeesNotified?: () => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const NotifyAppointees = ({
  appointees,
  isEmbedded = false,
  dispatchGetAppointees,
  onAllAppointeesNotified,
  dispatchSetGlobalErrorMessage,
  customerReferralCode = undefined,
  answerStore: { firstName: userFirstName, lastName: userLastName },
}: Props) => {
  const [notifiedAppointees, setNotifiedAppointees] = React.useState<string[]>(
    [],
  )

  useEffect(() => {
    dispatchGetAppointees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()
  const batchedAppointees = useMemo(
    () => batchAppointees(appointees),
    [appointees],
  )

  // Check if all appointees have been notified and call callback
  useEffect(() => {
    if (
      onAllAppointeesNotified &&
      batchedAppointees.length &&
      _uniq(notifiedAppointees).length === batchedAppointees.length
    ) {
      onAllAppointeesNotified()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchedAppointees, notifiedAppointees])

  return (
    <Box
      data-testid="notify-appointees-wrapper"
      sx={{
        minWidth: { min: 'unset', lg: '33rem' },
        maxWidth: { min: 'unset', lg: '35rem' },
        p: { min: '1.1rem 0 4rem', md: '1.1rem 0.3rem 4rem' },
      }}
    >
      <Heading
        sx={{ display: isEmbedded ? 'none' : 'block' }}
        align="center"
        variant={_isEmpty(appointees) ? 'h3' : 'h2'}
      >
        {_isEmpty(appointees)
          ? t('documents:components.NotifyAppointees.label.empty')
          : t('documents:components.NotifyAppointees.label.default')}
      </Heading>

      {!_isEmpty(appointees) && (
        <>
          <Text
            align="center"
            variant="paragraph"
            sx={{
              mt: '0.9rem',
              mb: '2.4rem',
              display: isEmbedded ? 'none' : 'block',
            }}
          >
            {t('documents:components.NotifyAppointees.subLabel')}
          </Text>

          {React.Children.toArray(
            batchedAppointees.map((appointee: BatchedAppointee, index) => (
              <Box
                sx={{
                  px: '1.3rem',
                  py: '0.35rem',
                  borderRadius: '9px',
                  mt: index !== 0 ? '0.8rem' : '0',
                  border: `1.5px solid ${
                    themeColors[themeColorTypes.ACCENT_2][
                      themeColorVariants.MAIN
                    ]
                  }`,
                }}
              >
                <NotifyAppointee
                  index={index}
                  appointee={appointee}
                  userLastName={userLastName}
                  userFirstName={userFirstName}
                  customerReferralCode={customerReferralCode}
                  dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
                  onNotify={(appointeeId) => {
                    setNotifiedAppointees([...notifiedAppointees, appointeeId])
                  }}
                />
              </Box>
            )),
          )}
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  appointees: appointeesSelectors.getAppointees(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  customerReferralCode: dashboardSelectors.getCustomerReferralCode(
    state.dashboard,
  ),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetAppointees: () => dispatch(appointeesActions.getAppointees()),
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotifyAppointees)
