import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../../../../common/components/atoms/Checkbox'

const CheckboxField = ({ field, answerCache, handleFieldFragmentUpdate }) => {
  const { name, checkboxLabel } = field

  const [isChecked, setIsChecked] = useState(!!answerCache[name])

  useEffect(() => {
    handleFieldFragmentUpdate({ [name]: isChecked })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked])

  return (
    <Checkbox
      checked={isChecked}
      onChange={(checked) => setIsChecked(checked)}
      label={checkboxLabel}
    />
  )
}

CheckboxField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    checkboxLabel: PropTypes.string.isRequired,
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

export default CheckboxField
