import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'

import { themeColorTypes } from '../../../styles/muiTheme'
import Text from '../Text'
import NumberFormatCustom from './components/NumberFormatCustom'

const NumberInput = ({
  'data-testid': dataTestId,
  validationMessage = 'Required',
  onInputChange,
  placeholder2,
  placeholder,
  maxLength,
  isInvalid,
  disabled = false,
  variant = 'standard',
  format,
  value = '',
  name,
  sx = {},
}) => {
  const [inputVal, setInputVal] = useState(value)

  return (
    <TextField
      fullWidth
      name={name}
      format={format}
      value={inputVal}
      error={isInvalid}
      label={placeholder}
      variant={variant}
      disabled={disabled}
      placeholder={placeholder2}
      type={format ? 'text' : 'number'}
      helperText={
        <>
          {!disabled && (
            <>
              {isInvalid && validationMessage && (
                <Text color={themeColorTypes.RED} size="xxs">
                  {validationMessage}
                </Text>
              )}
            </>
          )}
        </>
      }
      onChange={(e) => {
        const val = e.target.value
        setInputVal(val)
        onInputChange(val)
      }}
      InputLabelProps={{
        shrink: !!inputVal,
      }}
      InputProps={{
        value,
        inputComponent: format ? NumberFormatCustom : undefined,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ format, maxLength, sx, 'data-testid': dataTestId }}
    />
  )
}

NumberInput.propTypes = {
  name: PropTypes.string,
  sx: PropTypes.shape({}),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
  placeholder2: PropTypes.string,
  'data-testid': PropTypes.string,
  validationMessage: PropTypes.node,
  format: PropTypes.oneOf(['currency', 'phone', 'percent']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
}

export default NumberInput
