import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { connect } from 'react-redux'

import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS, utils } from '@epilogue/common'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import { ReactComponent as UmbrellaIconNavy } from '../../../../../../../common/assets/images/umbrella-icon-navy.svg'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../common/components/atoms/Text'
import commonActions from '../../../../../../../common/ducks/commonActions'
import event from '../../../../../../../common/lib/event'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import dashboardSelectors from '../../../../../ducks/dashboard/dashboardSelectors'
import LifeInsuranceIntro from './components/LifeInsuranceIntro'
import LifeInsuranceMultiQuotes from './components/LifeInsuranceMultiQuotes'
import { InsuranceQuotesParams } from './quote.types'

interface Props {
  userId: string
  setShowModal?: Dispatch<SetStateAction<boolean>>
  initialInsuranceQuotesParams: InsuranceQuotesParams
  modalContentRef?: React.RefObject<HTMLDivElement> | null
  dispatchSetGlobalErrorMessage: (message: string) => void
}

const LifeInsuranceQuotes = ({
  userId,
  setShowModal = undefined,
  modalContentRef = null,
  initialInsuranceQuotesParams,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const [page, setPage] = useState<number>(0)
  const [viewedPageTwo, setViewedPageTwo] = useState<boolean>(false)
  const [insuranceQuotesParams, setInsuranceQuotesParams] =
    useState<InsuranceQuotesParams>(initialInsuranceQuotesParams)

  const { coverageAmounts, term, gender, age, smoker } = insuranceQuotesParams

  const { t } = useTranslation()

  const pageContent = [
    {
      header: {
        label: t('documents:components.LifeInsuranceQuotes.page1.label'),
        subLabel: t('documents:components.LifeInsuranceQuotes.page1.subLabel'),
      },
      footer: {
        text: [
          t(
            `documents:components.LifeInsuranceQuotes.page1.footer.item1.${gender === 'female' ? 'female' : 'male'}.${smoker ? 'smoker' : 'nonSmoker'}`,
            {
              term,
              age,
              amount: utils.toDollar(coverageAmounts[1], 0, i18n.language),
            },
          ),
          t('documents:components.LifeInsuranceQuotes.page1.footer.item2'),
        ],
      },
    },

    {
      header: {
        label: t('documents:components.LifeInsuranceQuotes.page2.label'),
        subLabel: t('documents:components.LifeInsuranceQuotes.page2.subLabel'),
      },
      footer: {
        text: [
          t('documents:components.LifeInsuranceQuotes.page2.footer.item1'),
        ],
      },
    },
  ]

  useEffect(() => {
    if (modalContentRef?.current) {
      // Scroll to top of modal on modal-page change
      modalContentRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    if (page === 1 && !viewedPageTwo) {
      setViewedPageTwo(true)
      event.send({
        name: CONSTANTS.eventNames.LIFE_INSURANCE_MODAL,
        metadata: { userId, page: 2 },
        action: CONSTANTS.eventActionTypes.VIEWED,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContentRef, page])

  const handlePageForward = () => setPage(page + 1)
  const handlePageBackward = () => setPage(page - 1)

  const isMobile = useMediaQuery('(max-width:600px)')
  const sectionPaddingX = isMobile ? '2rem' : '3.5rem'

  return (
    <Box
      data-testid="life-insurance-quotes"
      sx={{ maxWidth: { min: 'unset', lg: '43rem' } }}
    >
      {/* Beige header */}
      <Box
        sx={{
          py: '2rem',
          display: 'flex',
          px: sectionPaddingX,
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor:
            themeColors[themeColorTypes.NEUTRAL][themeColorVariants.LIGHTER],
        }}
      >
        <Box sx={{ width: '72px', mb: '1.1rem' }}>
          <UmbrellaIconNavy />
        </Box>

        <Heading align="center" variant="h2" sx={{ mb: '0.5rem' }}>
          {pageContent[page].header.label}
        </Heading>

        <Text size="lg" align="center" variant="paragraph">
          {pageContent[page].header.subLabel}
        </Text>
      </Box>

      {page === 0 ? (
        <LifeInsuranceIntro
          sectionPaddingX={sectionPaddingX}
          handlePageForward={handlePageForward}
          insuranceQuotesParams={insuranceQuotesParams}
          dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
        />
      ) : (
        <LifeInsuranceMultiQuotes
          userId={userId}
          setShowModal={setShowModal}
          sectionPaddingX={sectionPaddingX}
          handlePageBackward={handlePageBackward}
          insuranceQuotesParams={insuranceQuotesParams}
          setInsuranceQuotesParams={setInsuranceQuotesParams}
          dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
        />
      )}

      {/* Beige footer */}
      <Box
        sx={{
          p: `1.1rem ${sectionPaddingX}`,
          backgroundColor:
            themeColors[themeColorTypes.NEUTRAL][themeColorVariants.LIGHTER],
        }}
      >
        {React.Children.toArray(
          pageContent[page].footer.text.map((text) => (
            <Text
              size="xxxs"
              align="center"
              color={themeColorTypes.GREY}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )),
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  userId: dashboardSelectors.getUserId(state.dashboard),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LifeInsuranceQuotes)
