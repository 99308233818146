import { CONSTANTS } from '@epilogue/common'

export default {
  label: ({ answerStore: { partnerFirstName } }) => ({
    tKey: 'interceptModals:incorrectAllToPartnerModal.label',
    options: { partnerFirstName },
  }),
  subLabel: { tKey: 'interceptModals:incorrectAllToPartnerModal.subLabel' },
  primaryButtonLabel: {
    tKey: 'interceptModals:incorrectAllToPartnerModal.primaryButtonLabel',
  },
  primaryButtonAction: {
    type: CONSTANTS.interceptActionTypes.PREVIOUS_QUESTION,
  },
  secondaryButtonLabel: { tKey: 'common:close' },
  secondaryButtonAction: { type: CONSTANTS.interceptActionTypes.CLOSE_MODAL },
  trigger: {
    allToPartner: 'no',
    remoteDistribution: {
      // Only 1 item in remoteDistribution array
      $size: 1,
      // The only item is a partner/spouse
      $elemMatch: { relationship: CONSTANTS.relationshipValues.MY_PARTNER },
    },
  },
}
