import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Cotiser à un CEPL

Chaque année, vous pouvez cotiser jusqu'à 8 000 $ (déductibles d'impôt) à votre CEPL, sous réserve d'une limite de cotisation à vie de 40 000 $. Comme les cotisations sont déductibles d'impôt, elles peuvent réellement réduire le montant total de l'impôt que vous devez payer. Ne vous inquiétez pas si vous ne pouvez pas utiliser toute votre marge de cotisation en une seule année, car la marge de cotisation inutilisée peut être reportée à l'année suivante.

#### Retrait d'un CEPL

Les retraits d'un CEPL sont exempts d'impôt tant qu'ils sont utilisés pour acheter une maison admissible. Si des fonds sont retirés d'un CEPL pour une raison autre que l'achat d'une maison admissible, ces retraits sont probablement imposables et doivent être inclus dans votre revenu pour l'année.

#### Clôture d'un CEPL

Un CEPL ne peut rester ouvert que pendant un maximum de 15 ans ou jusqu'à ce que le propriétaire atteigne l'âge de 71 ans (selon la première éventualité). De plus, une fois qu'une personne fait un retrait d'un CEPL pour acheter sa première maison, le compte doit être fermé d'ici l'année suivante.

#### Que se passe-t-il avec un CEPL en cas de décès ?

Votre CEPL n'est mentionné nulle part dans votre testament. C'est parce que le propriétaire d'un CEPL nomme généralement un « titulaire successeur » ou des « bénéficiaires désignés » du CEPL lorsque le compte est ouvert. Si le propriétaire décède, le CEPL est distribué directement à ces personnes et ne fait pas partie de la succession de la personne.

Seul un conjoint ou un partenaire de fait peut être titulaire successeur d'un CEPL. Si le conjoint/partenaire remplit les conditions pour être propriétaire d'un CEPL (c'est-à-dire est également un acheteur de première maison), il peut reprendre le CEPL si le propriétaire décède. Si le titulaire successeur ne remplit pas les conditions pour être propriétaire d'un CEPL, il peut soit transférer les fonds à son REER/REER immobilisé en franchise d'impôt, soit recevoir une distribution imposable du compte.

S'il n'y a pas de titulaire successeur du CEPL, les produits du compte du titulaire du CEPL décédé sont répartis entre les bénéficiaires désignés (s'il y en a). Tout montant qu'un bénéficiaire désigné (autre qu'un conjoint/partenaire) reçoit d'un CEPL est imposable.

S'il n'y a pas de titulaire successeur ni de bénéficiaires désignés (ou s'ils sont tous décédés avant le titulaire du compte du CEPL), alors le CEPL est versé à la succession du propriétaire et est distribué selon les termes de leur testament, avec les autres actifs de la personne.

#### Quelles démarches puis-je entreprendre ?

Si vous n'êtes pas sûr de qui vous avez désigné comme titulaire successeur ou bénéficiaires désignés sur votre CEPL, vous pouvez contacter votre institution financière pour vous renseigner.
`
    : `
#### Contributing to an FHSA

Every year you can contribute up to $8,000 (tax-deductible) to your FHSA, subject to a lifetime contribution limit of $40,000. Because contributions are tax-deductible, they can actually lower the overall amount of tax you have to pay. Don't worry if you can't use all your contribution room in one year, because unused contribution room can carry over to the following year.

#### Withdrawing from an FHSA

FHSA withdrawals are tax-free as long as they are used to purchase a qualifying home. If funds are withdrawn from an FHSA for a reason other than to purchase a qualifying home, those withdrawals are likely taxable, and must be included in your income for the year.

#### Closing an FHSA

An FHSA can only stay open for a maximum of 15 years or until the owner turns 71 years old (whichever comes first). In addition, once someone makes a withdrawal from an FHSA to buy their first home, the account needs to be closed by the following year.

#### What Happens to an FHSA on Death?

Your FHSA is not referred to anywhere in your Will. This is because the owner of an FHSA usually names a "successor holder" or "designated beneficiaries" of the FHSA when the account is opened. If the owner dies, the FHSA is distributed directly to these individuals, and does not end up as part of the person's estate.

Only a spouse or common-law partner can be a successor holder of an FHSA. If the spouse/partner qualifies to own an FHSA (i.e., is also a first-time home buyer), they can take over the FHSA if the owner dies. If the successor holder does not qualify to own an FHSA, they can either transfer the funds to their RRSP/RRIF tax-free, or receive a taxable distribution from the account.

If there is no successor holder of the FHSA, the proceeds from the deceased FHSA holder's account are distributed among the designated beneficiaries (if any). Any amounts a designated beneficiary (other than a spouse/partner) receives from an FHSA are taxable.

If there is no successor holder or designated beneficiaries (or if they all died before the FHSA account holder), then the FHSA is paid to the owner's estate and is distributed according to the terms of their Will, along with the person's other assets.

#### What Steps Can I Take?

If you are unsure who you have named as successor holder or designated beneficiaries on your FHSA, you can contact your financial institution to inquire.
`
