/*
 * This component is used for user's who are resetting their password
 * It is also used for partners who are setting their password for the first time
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link as RRLink, useLocation, useParams } from 'react-router-dom'

import _get from 'lodash/get'

import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useTranslation } from 'react-i18next'

import commonActions from '../../ducks/commonActions'
import commonSelectors from '../../ducks/commonSelectors'
import queryString from '../../lib/queryString'
import { errorHandler, PATHS, request } from '../../request'
import afterLogin from '../../utils/afterLogin'
import Button from '../atoms/Button'
import Checkbox from '../atoms/Checkbox'
import Heading from '../atoms/Heading'
import Link from '../atoms/Link'
import Text from '../atoms/Text'
import TextInput from '../atoms/TextInput'
import Header from '../molecules/Header'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    padding: '2.5rem',
    marginTop: '2.5rem',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadiusLarge,
  },
}))

const ResetPassword = ({ auth, dispatchSetGlobalErrorMessage }) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validPassword, setValidPassword] = useState('')
  const [isWorking, setIsWorking] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [mailingList, setMailingList] = useState(false)

  const classes = useStyles()

  const location = useLocation()
  const { id, resetToken } = useParams()

  // if partner is coming to set their password for the first time
  const isPartner = queryString.parse(location.search).partner === 'true'

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsWorking(true)

    try {
      await request({
        method: 'POST',
        url: `${PATHS.USER_FORGOT_PASSWORD}/${id}/${resetToken}`,
        /*
         * We send the mailingList value here because this is when a partner
         * makes a decision on whether or not to join the mailing list
         */
        data: {
          password,
          confirmPassword,
          mailingList,
          partnerPasswordCreation: isPartner,
        },
      })

      afterLogin({
        loginType: 'reset',
        loginRedirect: `/dashboard${isPartner ? '?partner=true' : ''}`,
      })

      // will update the 'auth' prop
      // the line below is commented because the reload prevents this code from running
      // dispatchCheckAuth()
    } catch (error) {
      const is400 = _get(error, ['response', 'status']) === 400
      errorHandler({
        error,
        reportError: !is400,
        fallbackErrorMessage:
          'Unable to reset your password. Please try again later.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
      setIsWorking(false)
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <Header />

      <Box
        sx={{
          margin: 'auto',
          display: 'flex',
          maxWidth: '42rem',
          alignItems: 'center',
          padding: '4rem 2rem',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ marginBottom: '0.25rem', textAlign: 'center' }}>
          <Heading variant="h1">
            {isPartner
              ? t('pages:ResetPassword.label.partner')
              : t('pages:ResetPassword.label.default')}
          </Heading>
        </Box>

        {isPartner && (
          <Box sx={{ marginBottom: '0.75rem', textAlign: 'center' }}>
            <Heading variant="h4">
              {t('pages:ResetPassword.subLabel.partner')}
            </Heading>
          </Box>
        )}

        {isPartner && (
          <Box sx={{ textAlign: 'center' }}>
            <Text variant="paragraph">
              {t('pages:ResetPassword.paragraph')}
            </Text>
          </Box>
        )}

        <form onSubmit={handleSubmit} className={classes.form}>
          <Box
            sx={{
              marginBottom: '0.75rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextInput
              type="password"
              isInvalid={password.length > 0 && !validPassword}
              helperText={t('pages:ResetPassword.textInput.input1.helperText')}
              validationMessage={t(
                'pages:ResetPassword.textInput.input1.validationMessage',
              )}
              placeholder={
                isPartner
                  ? t('common:password')
                  : t('pages:ResetPassword.textInput.input1.placeholder')
              }
              disabled={auth}
              value={password}
              autoFocus
              onInputChange={(val) => {
                setPassword(val)
                setValidPassword(val.length >= 8)
              }}
            />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextInput
              type="password"
              placeholder={t(
                'pages:ResetPassword.textInput.input2.placeholder',
              )}
              isInvalid={
                password !== confirmPassword && confirmPassword.length >= 8
              }
              validationMessage={t(
                'pages:ResetPassword.textInput.input2.validationMessage',
              )}
              disabled={auth}
              value={confirmPassword}
              onInputChange={(val) => {
                setConfirmPassword(val)
              }}
            />
          </Box>

          {isPartner && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ display: 'flex', marginTop: '1.25rem' }}>
                  <Checkbox
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms((prev) => !prev)}
                    label={
                      <Link
                        sx={{ fontSize: '14px' }}
                        openNewTab
                        underline="hover"
                        href="https://www.epiloguewills.com/user-agreement"
                      >
                        {t('pages:ResetPassword.checkbox.checkbox1.part1')}{' '}
                        <Text size="xs" weight="bold" display="inline">
                          {t('pages:ResetPassword.checkbox.checkbox1.part2')}
                        </Text>
                      </Link>
                    }
                  />
                </Box>

                <Box sx={{ display: 'flex' }}>
                  <Checkbox
                    checked={mailingList}
                    onChange={() => setMailingList((prev) => !prev)}
                    label={
                      <Link
                        sx={{ fontSize: '14px' }}
                        openNewTab
                        underline="hover"
                        href="https://www.epiloguewills.com/casl"
                      >
                        <Text size="xs" weight="bold" display="inline">
                          {t('pages:ResetPassword.checkbox.checkbox2.part1')}
                        </Text>{' '}
                        {t('pages:ResetPassword.checkbox.checkbox2.part2')}
                      </Link>
                    }
                  />
                </Box>
              </Box>
            </>
          )}

          <Box sx={{ marginTop: '2rem' }}>
            <Button
              type="submit"
              working={isWorking}
              fullWidth
              disabled={
                isWorking ||
                !validPassword ||
                password !== confirmPassword ||
                (isPartner && !agreeToTerms)
              }
              variant="contained"
              spinnerPadding="0.375rem"
              label={t('common:submit')}
            />
          </Box>
        </form>

        <Box marginTop="1.5rem">
          <RRLink to="/login">
            <Button
              size="xs"
              label={
                isPartner
                  ? t('pages:ResetPassword.alreadyHaveAccount')
                  : t('pages:ResetPassword.rememberMyPassword')
              }
            />
          </RRLink>
        </Box>
      </Box>
    </>
  )
}

ResetPassword.propTypes = {
  auth: PropTypes.bool.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({ auth: commonSelectors.getAuth(state) })

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
