import React from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import i18n from 'i18next'

import balloon from '../../../../common/assets/images/balloon.svg'
import clouds from '../../../../common/assets/images/clouds.svg'
import oranges from '../../../../common/assets/images/oranges.svg'
import umbrella from '../../../../common/assets/images/umbrella.svg'

const getImageSrc = (providedGraphic) => {
  const { standardGraphics } = CONSTANTS

  switch (providedGraphic) {
    case standardGraphics.BALLOON:
      return balloon
    case standardGraphics.CLOUDS:
      return clouds
    case standardGraphics.ORANGES:
      return oranges
    case standardGraphics.UMBRELLA:
      return umbrella
    default:
      return providedGraphic
  }
}

const QuestionGraphic = ({ question, charityPartner }) => {
  const { highlightPillText, graphic } = question

  return (
    // This creates an img element in the end,
    // but allows us to use MUI's styling tools
    <>
      {graphic?.image && (
        <Box
          alt=""
          component="img"
          data-testid="question-graphic"
          src={
            graphic.image === CONSTANTS.standardGraphics.CHARITY_LOGO
              ? /*
                 * Use active language logo if available, otherwise default to English logo
                 */
                charityPartner.logo?.[CONSTANTS.languageTypes.EN_CA.id]
                ? _get(
                    charityPartner,
                    ['logo', i18n.language],
                    charityPartner.logo[CONSTANTS.languageTypes.EN_CA.id],
                  )
                : charityPartner.logo
              : getImageSrc(graphic.image)
          }
          sx={{
            mx: 'auto',
            mb: '2.5rem',
            maxHeight:
              graphic.maxHeight ||
              graphic.image === CONSTANTS.standardGraphics.CHARITY_LOGO
                ? '4rem'
                : '10rem',
            mt: highlightPillText ? '2.5rem' : undefined, // Add top padding to push away from highlight pills on intro pages
          }}
        />
      )}
    </>
  )
}

QuestionGraphic.propTypes = {
  question: PropTypes.shape({
    highlightPillText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    graphic: PropTypes.shape({
      image: PropTypes.string,
      maxHeight: PropTypes.string,
    }),
  }).isRequired,
  charityPartner: PropTypes.shape({
    logo: PropTypes.shape({}),
  }).isRequired,
}

export default QuestionGraphic
