import _flatten from 'lodash/flatten'
import _uniq from 'lodash/uniq'

import { allQuestions } from '../../../engine'

export default (data: any) =>
  // _uniq removes duplicate field names
  _uniq(
    // _flatten creates a single array of all fields
    _flatten(
      allQuestions(data)
        // Filter out questions with no fields
        .filter((q) => q.fields)
        // Create an array of just the fields
        .map((q) => q.fields),
    )
      // Only return fields with preventAnswerStoreUpdatedSinceGenerate set to true
      .filter((f) => f.preventAnswerStoreUpdatedSinceGenerate)
      // Create array of just field names
      .map((f) => f.name),
  )
