import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Cotisations et subventions au REEE

Un des principaux avantages de cotiser à votre REEE est de pouvoir bénéficier de la Subvention canadienne pour l'épargne-études (SCEE).

Grâce à la SCEE, le gouvernement fédéral peut ajouter un montant supplémentaire équivalant à 20 % des cotisations versées à chaque compte REEE (jusqu'à 500 $ par année pour chaque bénéficiaire et 7 200 $ au total).

Si vous avez manqué de cotiser les années précédentes, il est possible de "rattraper" et de recevoir les paiements de la SCEE des années précédentes.

#### Que se passe-t-il avec un REEE en cas de décès ?

Vous pouvez être le seul "abonné" (propriétaire) de votre REEE, ou vous pouvez être un "abonné conjoint" avec quelqu'un d'autre (comme votre conjoint/conjoint de fait). Si un abonné conjoint décède, l'autre personne continue en tant qu'unique abonné et le REEE continue comme avant.

La situation est différente lorsque seul un abonné décède. Même si les enfants ou les petits-enfants de la personne sont les bénéficiaires du REEE, l'abonné reste le propriétaire. Cela signifie que, par défaut, le compte fait partie de la succession du propriétaire et doit être distribué selon son testament.

Ceci n'est pas idéal pour deux raisons :

1. L'objectif d'un REEE est de financer les études postsecondaires. Cet objectif pourrait être compromis si les fonds sont simplement distribués selon le testament.
2. Les avantages fiscaux et les subventions qui étaient accordés au REEE devraient être remboursés.

Avec une planification adéquate, ce résultat peut être évité.

#### Quelles démarches puis-je entreprendre ?

Lorsque vous avez rédigé votre testament, vous avez probablement vu une question vous demandant si vous souhaitez permettre que votre REEE soit maintenu après votre décès. Si vous avez sélectionné "Oui", alors votre testament contient une clause relative au REEE qui désigne un "abonné successeur" — c'est-à-dire quelqu'un pour prendre le relais et continuer le REEE.

Continuer le REEE permet d'éviter les conséquences négatives mentionnées ci-dessus. Le REEE peut se poursuivre au-delà de votre décès pour aider à financer les études postsecondaires des bénéficiaires. Assurez-vous de passer en revue la clause relative au REEE dans votre testament avant de le signer.
    `
    : `
#### RESP Contributions and Grants

One of the major benefits of contributing to your RESP, is that it will enable you to take advantage of the Canada Education Savings Grant (CESG).

Through the CESG, the federal government can add an additional amount equal to 20% of the contributions made to each RESP account (up to $500 per year for each beneficiary and $7,200 lifetime).

If you have missed contributions in prior years, it is possible to “catch-up” and receive CESG payments from prior years.

#### What Happens to an RESP on Death?


You may be the sole “subscriber” (owner) of your RESP, or you may be a “joint subscriber” together with someone else (like your spouse/partner). If a joint subscriber dies, the other person continues on as the sole subscriber and the RESP continues as it did before.

The situation is different when a sole subscriber passes away. Even though the person's children or grandchildren may by the beneficiaries of the RESP, the subscriber is still the owner. This means that, by default, the account becomes part of the owner's estate and should be distributed according to their Will.

This is not ideal for two reasons:

1. The intent of an RESP is to help fund post-secondary education. This intent could be lost if the funds are simply distributed according to the Will. 
2. The tax benefits and grant money that previously accrued to the RESP would have to be repaid.

With proper planning, this outcome can be avoided.

#### What Steps Can I Take?

When you created your Will, you would have seen a question asking if you want to allow your RESP to be maintained after your death. If you selected “Yes”, then your Will contains an RESP clause which appoints a “successor subscriber”  — that is, someone to take over and continue the RESP.

Continuing the RESP helps avoid the negative consequences referred to above. The RESP can continue beyond your death to help fund the beneficiaries' post-secondary education. Be sure to review the RESP clause in your Will before you sign it.
`
