import { CONSTANTS } from '@epilogue/common'

import { getCookie, removeCookie, setCookie } from '../../lib/cookies'
import queryString from '../../lib/queryString'

const AUTHORITATIVE_REF_ORDER = ['charity', 'rbc']

export const getReferrerFromCookies = () => ({
  ref: getCookie(CONSTANTS.cookieNames.REF),
  reftag: getCookie(CONSTANTS.cookieNames.REFTAG),
})

const getRefAuthority = (ref) => {
  if (!ref) return -1

  const lowerRef = ref.toLowerCase()
  if (AUTHORITATIVE_REF_ORDER.includes(lowerRef)) {
    return (
      AUTHORITATIVE_REF_ORDER.length - AUTHORITATIVE_REF_ORDER.indexOf(lowerRef)
    )
  }

  return 0
}

const setRefCookies = ({ ref, reftag }) => {
  const { ref: existingRef } = getReferrerFromCookies()

  // Only bother setting the ref if one exists AND
  // the user doesn't already have an authoritative ref (excluding charity refs)
  if (ref && getRefAuthority(ref) >= getRefAuthority(existingRef)) {
    setCookie(CONSTANTS.cookieNames.REF, ref)

    if (reftag) {
      setCookie(CONSTANTS.cookieNames.REFTAG, reftag)
    } else if (getCookie(CONSTANTS.cookieNames.REFTAG)) {
      removeCookie(CONSTANTS.cookieNames.REFTAG)
    }
  }
}

export const setReferrerCookies = () => {
  const { ref, reftag } = queryString.parseUrl(window.location.href)

  /*
   * Checking for a ref of 'epilogue' or 'social' is for a legacy
   * implementation of something. Just leave it unless there is a
   * good reason to change it.
   */
  if (ref && ref !== 'epilogue' && ref !== 'social') {
    setRefCookies({ ref, reftag })
  }
}
