export default [
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionWithChildrenIntroSidebar.item1.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionWithChildrenIntroSidebar.item1.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionWithChildrenIntroSidebar.item1.children.item2.body',
        },
      },
    ],
  },
]
