import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import LawyerCard from './LawyerCard'

const WrapperLawyerCards = styled.div`
  display: grid;
  grid-gap: 18px;
  justify-content: space-evenly;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      ${({ numOfLawyers }) =>
        numOfLawyers % 3 === 0 || numOfLawyers < 3 ? '244px' : '248px'},
      ${({ numOfLawyers }) => (numOfLawyers === 1 ? '390px' : '1fr')}
    )
  );
`
const LawyerCards = ({ lawyers }) => (
  <WrapperLawyerCards
    numOfLawyers={lawyers.length}
    data-testid="lawyer-cards-wrapper"
  >
    {lawyers
      .sort((a, b) => b.worksRemote - a.worksRemote)
      .map((lawyer) => (
        <React.Fragment key={lawyer._id}>
          <LawyerCard lawyer={lawyer} />
        </React.Fragment>
      ))}
  </WrapperLawyerCards>
)

LawyerCards.propTypes = {
  lawyers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default LawyerCards
