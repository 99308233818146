import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, ClickAwayListener, Fade, Tooltip } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import Text from '../../../../../common/components/atoms/Text'
import useScrollPosition from '../../../../../common/hooks/useScrollPosition'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'

const useStyles = makeStyles((theme) => ({
  touch: {
    /*
     * TODO: I don't love using !important here, but it works.
     * - Adam
     */
    margin: '9px 0 !important',
  },
  tooltip: {
    maxWidth: '325px',
    padding: '0.8rem',
    color: theme.palette.brand.main,
    backgroundColor: theme.palette['accent-2'].light,
    boxShadow: `0 1px 4px ${alpha(theme.palette.grey.light, 0.65)}`,
  },
}))

const HoverDefinition = ({ incoming }) => {
  const { y: currentScrollY } = useScrollPosition()

  const [scrollYOnOpen, setScrollYOnOpen] = useState()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
    setScrollYOnOpen(currentScrollY)
  }
  const handleTooltipClose = () => {
    setTooltipOpen(false)
    setScrollYOnOpen()
  }

  const classes = useStyles()

  useEffect(() => {
    if (tooltipOpen && currentScrollY !== scrollYOnOpen) {
      handleTooltipClose()
    }
  }, [currentScrollY, tooltipOpen, scrollYOnOpen])

  // Checks if incoming is a hover definition
  if (
    Array.isArray(incoming) &&
    incoming.some((item) => item.term && item.definition)
  ) {
    return React.Children.toArray(
      incoming.map((item) => {
        const { term, definition } = item

        if (term && definition) {
          return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                open={tooltipOpen}
                placement="bottom-start"
                TransitionComponent={Fade}
                classes={{
                  touch: classes.touch,
                  tooltip: classes.tooltip,
                }}
                title={
                  <Text
                    data-testid="hover-definition-tooltip"
                    variant="paragraph"
                    sx={{ lineHeight: '1.5', letterSpacing: '0.019rem' }}
                  >
                    {definition}
                  </Text>
                }
              >
                <Box
                  data-testid="hover-definition-term"
                  onClick={handleTooltipOpen}
                  onMouseEnter={handleTooltipOpen}
                  onMouseLeave={handleTooltipClose}
                  sx={{
                    cursor: 'help',
                    display: 'inline',
                    borderBottom: `2px dashed ${
                      themeColors[themeColorTypes.ACCENT_2][
                        themeColorVariants.DARKER
                      ]
                    }`,
                  }}
                >
                  {term}
                </Box>
              </Tooltip>
            </ClickAwayListener>
          )
        }
        return item
      }),
    )
  }
  // If incoming is NOT a hover definition, assume it's a string and return as-is
  return incoming
}

HoverDefinition.propTypes = {
  incoming: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          term: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
          definition: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape(),
          ]),
        }),
      ]),
    ),
  ]).isRequired,
}

export default React.memo(HoverDefinition)
