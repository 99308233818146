/*
 * USAGE:
 * const { x, y } = useScrollPosition()
 */

import { useEffect, useState } from 'react'

const isBrowser = typeof window !== 'undefined'

const getScrollPosition = () => ({
  x: isBrowser ? window.pageXOffset : 0,
  y: isBrowser ? window.pageYOffset : 0,
})

const useWindowScroll = () => {
  const [scroll, setScroll] = useState(getScrollPosition())

  useEffect(() => {
    const handleScroll = () => setScroll(getScrollPosition())

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return scroll
}

export default useWindowScroll
