import React, { useContext } from 'react'

import _isEmpty from 'lodash/isEmpty'

import { Box, useMediaQuery, useTheme } from '@mui/material'

import LoadingSpinner from '../../../../../../../common/components/atoms/LoadingSpinner'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import LawyerReferralContext from '../../context/LawyerReferralContext'
import FlatFeeLawyerContactForm from './components/FlatFeeLawyerContactForm'
import FlatFeeLawyerHeading from './components/FlatFeeLawyerHeading'
import FlatFeeLawyerInfo from './components/FlatFeeLawyerInfo'

const FlatFeeLawyerReferral = () => {
  const { lawyers } = useContext(LawyerReferralContext)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('2xl'))

  return (
    <>
      {!lawyers || _isEmpty(lawyers) ? (
        // TODO: add an actual empty state as opposed to just a spinner
        <LoadingSpinner />
      ) : (
        <>
          <FlatFeeLawyerHeading />
          {/*
           * The styles in the Box below allow the child component to
           * extend beyond the parent component (form, in this case)
           * https://stackoverflow.com/questions/5581034/is-there-are-way-to-make-a-child-divs-width-wider-than-the-parent-div-using-css
           */}
          <Box
            position="relative"
            width={isDesktop ? '100vw' : '100%'}
            left={isDesktop ? '50%' : 'initial'}
            right={isDesktop ? '50%' : 'initial'}
            margin={isDesktop ? '0 -50vw' : 'auto'}
          >
            <Box
              data-testid="flat-fee-lawyer-card"
              padding={isDesktop ? '2.5rem' : '1.5rem'}
              display="flex"
              margin="auto"
              maxWidth={isDesktop ? '56rem' : '23rem'}
              flexDirection={isDesktop ? 'row' : 'column'}
              borderRadius={theme.shape.borderRadiusLarge}
              bgcolor={
                theme.palette[themeColorTypes.NEUTRAL][themeColorVariants.MAIN]
              }
            >
              <FlatFeeLawyerInfo />
              <FlatFeeLawyerContactForm />
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default FlatFeeLawyerReferral
