import React from 'react'
import PropTypes from 'prop-types'

import { Heading, Text } from 'grommet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import HighlightPill from '../../../../../../../../../../common/components/molecules/HighlightPill'
import useResponsive, {
  breakpoints,
} from '../../../../../../../../../../common/hooks/useResponsive'

const StyledHeading = styled(Heading)`
  line-height: 1.3;
  font-size: ${({ isMobile }) => (isMobile ? '22px' : '28.3px')};
`
const FirmText = styled(Text)`
  font-size: ${({ isMobile }) => (isMobile ? '15px' : '16px')};
`
const LocationText = styled(Text)`
  font-size: 13px;
`
const LawyerName = ({ lawyer }) => {
  const [viewportWidth] = useResponsive()
  const isMobile = viewportWidth < breakpoints.sm

  const { firm, city, province, worksRemote, lawyerLastName, lawyerFirstName } =
    lawyer

  const { t } = useTranslation()

  return (
    <>
      <div className="mb-0.5">
        <StyledHeading isMobile={isMobile} level={3}>
          {lawyerFirstName ? `${lawyerFirstName} ${lawyerLastName}` : firm}
        </StyledHeading>
      </div>

      {lawyerFirstName && firm && (
        <div className="text-accent-1-darker">
          <FirmText isMobile={isMobile}>{firm}</FirmText>
        </div>
      )}

      <div className="text-accent-1-darker -mt-1">
        <LocationText>
          {city}, {province}
        </LocationText>
      </div>

      {worksRemote && (
        <div className="mt-2">
          <HighlightPill
            label={t('molecules:LawyerReferral.virtualMeetingPillLabel')}
          />
        </div>
      )}
    </>
  )
}

LawyerName.propTypes = {
  lawyer: PropTypes.shape({
    firm: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    worksRemote: PropTypes.bool,
    lawyerLastName: PropTypes.string,
    lawyerFirstName: PropTypes.string,
  }).isRequired,
}

export default LawyerName
