// RBC Life Insurance quotes have the following rules:
export default (gender: string, age: number) => {
  // Gender must be male or female to request quotes
  if (!['male', 'female'].includes(gender)) {
    return []
  }

  switch (true) {
    // Age 18-54 can request quotes up to $1,000,000
    case age >= 18 && age <= 54:
      return [50000, 100000, 200000, 500000, 750000, 1000000]

    // Age 55-69 can request quotes up to $499,999
    case age >= 55 && age <= 69:
      return [50000, 100000, 225000, 350000, 475000]

    // Less than 18 years old get no quotes
    // OR
    // Age 70+ get no quotes
    default:
      return []
  }
}
