import { useQuery } from '@tanstack/react-query'

import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../../../common/utils/queryKeys'

export default ({
  addOnCart,
  promoCode,
  answerStore,
  displayTotal,
  dispatchSetGlobalErrorMessage,
}) =>
  useQuery({
    queryKey: queryKeys[queryKeysBase.createPaymentPlan](displayTotal),
    queryFn: async () => {
      const res = await request({
        method: 'POST',
        url: PATHS.PAYMENT_CREATE_PAYMENT_PLAN,
        data: {
          promoCode,
          answerStore,
          addOns: addOnCart,
        },
      })
      return res.data.payload
    },

    enabled: false,
    keepPreviousData: true,
    retry: process.env.REACT_APP_ENV === 'production' ? 1 : 0,

    onError: (error) =>
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to retrieve payment plan pricing. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      }),
  })
