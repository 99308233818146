import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _repeat from 'lodash/repeat'

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import Checkbox from '../../../../../common/components/atoms/Checkbox'
import Link from '../../../../../common/components/atoms/Link'
import Text from '../../../../../common/components/atoms/Text'
import TextInput from '../../../../../common/components/atoms/TextInput'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import navigationActions from '../../../ducks/navigation/navigationActions'
import navigationSelectors from '../../../ducks/navigation/navigationSelectors'
import questionnaireActions from '../../../ducks/questionnaire/questionnaireActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import validationSelectors from '../../../ducks/validation/validationSelectors'
import NavigationButtons from '../../molecules/NavigationButtons/NavigationButtons'

const useStyles = makeStyles({
  redOnInvalid: {
    color: ({ displayValidation, agreeToTerms }) => {
      if (displayValidation && !agreeToTerms) {
        return themeColors[themeColorTypes.RED][themeColorVariants.MAIN]
      }
      return themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN]
    },
  },
})

const SocialCreateAccount = ({
  dispatchStartCreateAccount,
  question,
  handleFieldFragmentUpdate,
  answerCache,
  accountCreated,
  dispatchNextQuestion,
  working,
  displayValidation,
  invalidFields,
}) => {
  const { current: initialAccountCreated } = useRef(accountCreated)

  const [passwordField] = question.fields

  const [passwordInput, setPasswordInput] = useState(
    answerCache[passwordField.name] || '',
  )
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [mailingList, setMailingList] = useState(false)

  useEffect(() => {
    /*
     * initialAccountCreated holds the value of accountCreated when the
     * component mounted. We only want to dispatch the next question
     * if the accountCreated value changes from false to true while the
     * component is mounted (AKA: the user created an account)
     */
    if (!initialAccountCreated && accountCreated) {
      dispatchNextQuestion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountCreated])

  const handleSubmit = async () => {
    dispatchStartCreateAccount({
      email: answerCache?.email,
      password: passwordInput,
      mailingList,
    })
  }

  const classes = useStyles({ displayValidation, agreeToTerms })

  return (
    <Box
      width="100%"
      display="flex"
      maxWidth="350px"
      alignItems="center"
      flexDirection="column"
    >
      <TextInput
        passwordVisibility
        type={passwordField.type}
        disabled={accountCreated}
        value={passwordInput}
        isInvalid={
          displayValidation &&
          invalidFields &&
          invalidFields.includes(passwordField.name)
        }
        helperText="Must be at least 8 characters"
        validationMessage="Password must be at least 8 characters in length"
        placeholder={passwordField.placeholder}
        onInputChange={(val) => {
          setPasswordInput(val)
          handleFieldFragmentUpdate({ password: _repeat('*', val.length) })
        }}
      />

      {!accountCreated && (
        <Box pt="0.3rem" mb="-0.2rem" display="flex" flexDirection="column">
          <Box alignItems="center" display="flex">
            <Checkbox
              checked={agreeToTerms}
              onChange={() => setAgreeToTerms((prev) => !prev)}
              label={
                <Box>
                  <Text
                    display="initial"
                    size="xs"
                    color={
                      displayValidation && !agreeToTerms
                        ? themeColorTypes.RED
                        : themeColorTypes.BRAND
                    }
                  >
                    {"I agree to Epilogue's "}
                    <Link
                      openNewTab
                      underline="hover"
                      className={classes.redOnInvalid}
                      href="https://www.epiloguewills.com/user-agreement"
                    >
                      <Text
                        size="xs"
                        weight="bold"
                        className={classes.redOnInvalid}
                        display="inline"
                      >
                        User Agreement
                      </Text>
                    </Link>
                  </Text>
                </Box>
              }
            />
          </Box>

          <Box mt="-0.1rem" display="flex" alignItems="center">
            <Checkbox
              checked={mailingList}
              onChange={() => setMailingList((prev) => !prev)}
              label={
                <Box>
                  <Text size="xs">
                    <Link
                      openNewTab
                      underline="hover"
                      href="https://www.epiloguewills.com/casl"
                    >
                      <Text display="inline" size="xs" weight="bold">
                        Send me
                      </Text>
                    </Link>{' '}
                    helpful news and account info
                  </Text>
                </Box>
              }
            />
          </Box>
        </Box>
      )}

      <NavigationButtons
        nextLabel={accountCreated ? 'Save & Continue' : 'Create Account'}
        nextDisabled={!accountCreated && !agreeToTerms}
        /*
         * if account has already been created, the user
         * cannot update password here, only in the dashboard
         * let the user click next to move on
         */
        working={working}
        handleButtonClick={() => {
          if (accountCreated) {
            dispatchNextQuestion()
          } else {
            handleSubmit()
          }
        }}
      />
    </Box>
  )
}

SocialCreateAccount.propTypes = {
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  answerCache: PropTypes.shape({ email: PropTypes.string }).isRequired,
  dispatchStartCreateAccount: PropTypes.func.isRequired,
  dispatchNextQuestion: PropTypes.func.isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  accountCreated: PropTypes.bool.isRequired,
  working: PropTypes.bool.isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  displayValidation: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
  working: navigationSelectors.getWorking(state.questionnaire),
  invalidFields: validationSelectors.getInvalidFields(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchNextQuestion: () =>
    dispatch(navigationActions.nextQuestion({ questionFragments: {} })),
  dispatchStartCreateAccount: ({ password, email, mailingList }) =>
    dispatch(
      questionnaireActions.startCreateAccount({ password, email, mailingList }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialCreateAccount)
