import React from 'react'

import { Box } from '@mui/material'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'

interface Props {
  steps: number
  activeStep: number
  activeDotColor?: string
  preventDotClick?: boolean
  activeDotColorVariant?: string
  onDotClick?: (dotIndex: number) => void
}

const DOT_SIZE = '8px'

const StepDots = ({
  steps,
  activeStep,
  preventDotClick = false,
  onDotClick = () => undefined,
  activeDotColor = themeColorTypes.ACCENT_2,
  activeDotColorVariant = themeColorVariants.DARK,
}: Props) => (
  <Box sx={{ display: 'flex' }}>
    {React.Children.toArray(
      Array.from(Array(steps)).map((_, index) => (
        <Box
          onClick={() => {
            if (preventDotClick) return
            onDotClick(index)
          }}
          sx={{
            mx: '0.3rem',
            width: DOT_SIZE,
            height: DOT_SIZE,
            borderRadius: '9999px',
            cursor: preventDotClick ? 'default' : 'pointer',
            backgroundColor:
              activeStep === index
                ? themeColors[activeDotColor][activeDotColorVariant]
                : themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTER],
          }}
        />
      )),
    )}
  </Box>
)
export default StepDots
