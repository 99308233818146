import React from 'react'

import { Link } from 'react-router-dom'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, useMediaQuery } from '@mui/material'

import rbcLogo from '../../../../../../../common/assets/images/rbc-logo.png'
import Button from '../../../../../../../common/components/atoms/Button'
import Text from '../../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'

const RbcAdvisorBanner = () => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.down('xl'))

  return (
    <Box
      sx={{
        p: '1.5rem',
        display: 'flex',
        borderRadius: '6px',
        flexDirection: 'row',
        alignItems: 'center',
        border: `2px solid ${
          themeColors[themeColorTypes.NEUTRAL][themeColorVariants.DARK]
        }`,
        backgroundColor:
          themeColors[themeColorTypes.NEUTRAL][themeColorVariants.LIGHT],
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: { min: 'column', xl: 'row' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: { min: '1.4rem', xl: '0' },
            flexDirection: { min: 'column', md: 'row' },
          }}
        >
          <Box
            sx={{
              mb: { min: '0.9rem', md: '0' },
              width: { min: '3.35rem', md: '3rem' },
            }}
          >
            <img
              src={rbcLogo}
              alt="RBC Logo"
              data-testid="get-started-rbc-logo"
            />
          </Box>

          <Box sx={{ ml: '1rem' }}>
            <Text
              size="lg"
              weight="medium"
              sx={{ mb: '0.2rem' }}
              align={isTablet || isMobile ? 'center' : 'left'}
            >
              Need advice to help you reach your financial goals?
            </Text>
            <Text
              size="md"
              variant="paragraph"
              align={isTablet || isMobile ? 'center' : 'left'}
            >
              Connecting with an RBC advisor is quick, easy, and free.
            </Text>
          </Box>
        </Box>

        <Link
          target="_blank"
          to="https://www.rbcroyalbank.com/book-an-appointment/book-an-appointment.html"
        >
          <Button
            variant="contained"
            label="Book an appointment"
            size={isMobile ? 'sm' : 'md'}
            endIcon={<ArrowForwardIcon />}
          />
        </Link>
      </Box>
    </Box>
  )
}

export default RbcAdvisorBanner
