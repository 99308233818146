import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import { StatusTypes } from '../types/documentCards.types'

interface DocumentCardStyles {
  outline: string
  displayStripe: boolean

  opacity?: string
  backgroundColor?: string
  pillBackgroundColor?: string
}

type DocumentCardStyleProperties = {
  [key in StatusTypes]: DocumentCardStyles
}

const CARD_OPACITY = '0.65'

const documentCardStyleProperties: DocumentCardStyleProperties = {
  [StatusTypes.GET_STARTED]: {
    displayStripe: false,
    opacity: CARD_OPACITY,
    pillBackgroundColor: themeColors[themeColorTypes.GREY][
      themeColorVariants.LIGHTER
    ] as string,
    outline: `2px dashed ${
      themeColors[themeColorTypes.GREY][themeColorVariants.LIGHT]
    }`,
  },

  [StatusTypes.IN_PROGRESS]: {
    displayStripe: false,
    opacity: CARD_OPACITY,
    pillBackgroundColor: themeColors[themeColorTypes.GREY][
      themeColorVariants.LIGHTER
    ] as string,
    outline: `2px dashed ${
      themeColors[themeColorTypes.GREY][themeColorVariants.LIGHT]
    }`,
  },

  [StatusTypes.PAYMENT_REQUIRED]: {
    displayStripe: true,
    opacity: CARD_OPACITY,
    outline: `2px dashed transparent`,
    backgroundColor: themeColors[themeColorTypes.WHITE][
      themeColorVariants.MAIN
    ] as string,
  },

  [StatusTypes.COMPLETE]: {
    displayStripe: true,
    outline: `2px dashed transparent`,
    backgroundColor: themeColors[themeColorTypes.WHITE][
      themeColorVariants.MAIN
    ] as string,
  },

  [StatusTypes.CONTACT_US]: {
    displayStripe: false,
    opacity: CARD_OPACITY,
    pillBackgroundColor: themeColors[themeColorTypes.GREY][
      themeColorVariants.LIGHTER
    ] as string,
    outline: `2px dashed ${
      themeColors[themeColorTypes.GREY][themeColorVariants.LIGHT]
    }`,
  },
}

export default documentCardStyleProperties
