import { useQueryClient } from '@tanstack/react-query'

import { PATHS, request } from '../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../common/utils/queryKeys'
import { InsuranceQuotesParams } from '../components/LifeInsuranceQuotes/quote.types'

export default ({
  insuranceQuotesParams,
}: {
  insuranceQuotesParams: InsuranceQuotesParams
}) => {
  const queryClient = useQueryClient()

  return queryClient.prefetchQuery({
    queryKey: queryKeys[queryKeysBase.insurance](insuranceQuotesParams),
    queryFn: () =>
      request({
        method: 'GET',
        timeout: 20000, // 20 second timeout
        url: PATHS.INSURANCE_QUOTE,
        shouldSerializeParams: true,
        params: insuranceQuotesParams,
      }),
    staleTime: 60 * 60 * 1000, // 60 mins
  })
}
