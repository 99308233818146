import corporateExecutorSidebarFragments from './corporateExecutorSidebarFragments'

export default [
  corporateExecutorSidebarFragments.corporateExecutorBenefits,
  corporateExecutorSidebarFragments.whyChargeFees,
  corporateExecutorSidebarFragments.howMuchWillTrustCompanyCharge,
  corporateExecutorSidebarFragments.whenDoesTrustCompanyGetPaid,
  corporateExecutorSidebarFragments.changeMindOnTrustCompany,
  corporateExecutorSidebarFragments.contact,
]
