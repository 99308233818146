import { AvailableDates, Timeslot } from '../../datePicker.types'
import availableTimeslotsToUserTimezone from '../availableTimeslotsToUserTimezone'

export default (timeslots: Timeslot[], timezone: string) => {
  const timezoneAdjustedAvailableTimeslots = availableTimeslotsToUserTimezone(
    timeslots,
    timezone,
  )

  return timezoneAdjustedAvailableTimeslots.reduce((acc, timeslot) => {
    // Extracts just the date from the timeslot's start time
    const timeslotStartDate = timeslot.start.split('T')[0]

    if (timeslot.available) {
      if (acc[timeslotStartDate]) {
        acc[timeslotStartDate].availableTimeslots.push(timeslot)
      } else {
        acc[timeslotStartDate] = { availableTimeslots: [timeslot] }
      }
    }

    return acc
  }, {} as AvailableDates)
}
