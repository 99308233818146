import React, { useContext, useRef, useState } from 'react'

import _get from 'lodash/get'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../../common/components/atoms/Text'
import TextInput from '../../../../../../../../../common/components/atoms/TextInput'
import amplitude from '../../../../../../../../../common/lib/amplitude'
import { errorHandler } from '../../../../../../../../../common/request'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../../common/styles/muiTheme'
import { promoCodeRegex } from '../../../../../../../../Questionnaire/utils/validation/patterns'
import PaymentContext from '../../../../context/PaymentContext'
import usePromoCode from './hooks/usePromoCode'

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  plusIconWrapper: {
    padding: '0.2rem',
    borderRadius: '9999px',
    backgroundColor:
      themeColors[themeColorTypes.NEUTRAL][themeColorVariants.LIGHT],
  },

  applyButton: {
    padding: '0.17rem 0',
  },

  inactiveApplyPromoCodeWrapper: {
    cursor: 'pointer',

    '&:hover > h6': {
      textDecoration: 'underline',
    },
  },

  promoCodeInput: {
    '& input, & label': {
      fontSize: '0.85rem',
    },
  },

  checkIcon: {
    fontSize: '0.95rem',
    marginRight: '0.09rem',
    marginBottom: '0.18rem',
  },
}))

const ApplyPromoCode = () => {
  const { promoCode, setPromoCode, chargeInfo, dispatchSetGlobalErrorMessage } =
    useContext(PaymentContext)

  const classes = useStyles()

  const [codeVal, setCodeVal] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [active, setActive] = useState(promoCode || errorMessage)

  const inputBoxRef = useRef()

  const { product } = chargeInfo

  const { handleApplyPromoCode, isLoading } = usePromoCode({
    productId: product.productId,
    dispatchSetGlobalErrorMessage,
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    handleApplyPromoCode(codeVal, {
      onSuccess: () => {
        amplitude.sendEvent('AppliedValidPromoCode', {
          codeVal,
        })
        setPromoCode(codeVal)
        setErrorMessage('')
      },
      onError: (error) => {
        const is404 = _get(error, ['response', 'status']) === 404
        errorHandler({
          error,
          reportError: !is404,
          fallbackErrorMessage:
            'Unknown error while applying promo code. Please try again later.',
          onError: (resolvedErrorMessage) => {
            if (is404 && resolvedErrorMessage) {
              setErrorMessage(resolvedErrorMessage)
              amplitude.sendEvent('AppliedInvalidPromoCode', {
                codeVal,
                resolvedErrorMessage,
              })
            } else {
              dispatchSetGlobalErrorMessage(resolvedErrorMessage)
            }
          },
        })
      },
    })
  }

  const { t } = useTranslation()

  return (
    <Box display="flex" justifyContent="center">
      {active ? (
        <Box className={classes.formWrapper}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Box width="100%">
              <TextInput
                autoFocus={!promoCode}
                value={codeVal}
                placeholder={t(
                  'payment:components.PaymentBreakdown.components.PromoCode.placeholder',
                )}
                isInvalid={!!errorMessage}
                validationMessage={errorMessage}
                className={classes.promoCodeInput}
                ref={inputBoxRef}
                onInputChange={(val) => {
                  let cursorLocation = inputBoxRef.current?.selectionStart
                  /*
                   * Only allow an empty string or the characters
                   * a-Z, 0-9, "&" and "-" in the promo code input
                   */
                  if (!val || promoCodeRegex.test(val)) {
                    setCodeVal(val.toUpperCase())
                  } else {
                    // Last added character has been removed, so remove 1 from the cursorLocation to match
                    cursorLocation -= 1
                  }
                  // at the end of the operation, reset the cursor back to where the user last had it
                  setTimeout(() => {
                    inputBoxRef.current?.setSelectionRange(
                      cursorLocation,
                      cursorLocation,
                    )
                  }, 0)
                }}
              />

              {promoCode && !errorMessage && (
                <Box
                  sx={{
                    display: 'flex',
                    mt: '-0.8rem',
                    color: (theme) =>
                      theme.palette[themeColorTypes.GREEN][
                        themeColorVariants.MAIN
                      ],
                  }}
                >
                  <CheckRoundedIcon classes={{ root: classes.checkIcon }} />
                  <Text
                    size="xxs"
                    color="inherit"
                    data-testid="promo-code-success-message"
                  >
                    {t(
                      'payment:components.PaymentBreakdown.components.PromoCode.successMessage',
                      { promoCode },
                    )}
                  </Text>
                </Box>
              )}
            </Box>
            <Box ml="0.5rem">
              <Button
                working={isLoading}
                spinnerPadding="0.25rem"
                spinnerColor={themeColorTypes.ACCENT_1}
                spinnerColorVariant={themeColorVariants.LIGHT}
                size="sm"
                type="submit"
                label={t('common:apply')}
                disabled={!codeVal}
                className={classes.applyButton}
              />
            </Box>
          </form>
        </Box>
      ) : (
        <Box
          data-testid="promo-code-inactive-button"
          mx="auto"
          alignItems="center"
          display="inline-flex"
          justifyContent="center"
          onClick={() => setActive(true)}
          className={classes.inactiveApplyPromoCodeWrapper}
        >
          <Heading variant="h6">
            {t(
              'payment:components.PaymentBreakdown.components.PromoCode.label',
            )}
          </Heading>

          <Box
            ml="0.5rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.plusIconWrapper}
          >
            <AddRoundedIcon fontSize="small" />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ApplyPromoCode
