import React from 'react'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../../common/components/atoms/Text'

interface Props {
  onClose: () => void
  hasPrintCredit: boolean
  usedPrintCreditRecently: boolean
}

const BookWitnessingRoadblockView = ({
  onClose,
  hasPrintCredit,
  usedPrintCreditRecently,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      data-testid="book-witnessing-roadblock-view"
    >
      <Heading align="center" variant="h3">
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingRoadblockView.label',
        )}
      </Heading>

      <Text
        size="lg"
        align="center"
        variant="paragraph"
        sx={{ mt: '0.9rem', mb: '2rem' }}
      >
        {!hasPrintCredit && usedPrintCreditRecently
          ? t(
              'documents:components.modals.BookWitnessingModal.components.BookWitnessingRoadblockView.subLabel.case1',
            )
          : t(
              'documents:components.modals.BookWitnessingModal.components.BookWitnessingRoadblockView.subLabel.case2',
            )}
      </Text>

      <Button
        fullWidth
        onClick={onClose}
        variant="contained"
        label={t('common:close')}
        data-testid="book-witnessing-roadblock-view-close-button"
      />
    </Box>
  )
}

export default BookWitnessingRoadblockView
