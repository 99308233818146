export const flagTypes = {
  QUEBEC: 'quebec',
  SENTRY: 'sentry',
  INTERCOM: 'intercom',
  LOG_ROCKET: 'logRocket',
  SCOTIA_DEMO: 'scotiaDemo',
  REDUX_DEV_TOOLS: 'reduxDevTools',
  ENCOURAGE_CHARITABLE_DONATION: 'encourageCharitableDonation',
}

const flags = {
  [flagTypes.REDUX_DEV_TOOLS]: {
    production: false,
    staging: true,
    development: true,
    test: false,
  },

  [flagTypes.SENTRY]: {
    production: true,
    staging: true,
    development: false,
    test: false,
  },

  [flagTypes.INTERCOM]: {
    production: true,
    staging: false,
    development: false,
    test: false,
  },

  [flagTypes.LOG_ROCKET]: {
    production: true,
    staging: true,
    development: false,
    test: false,
  },

  [flagTypes.ENCOURAGE_CHARITABLE_DONATION]: {
    production: false,
    staging: true,
    development: true,
    test: true,
  },

  [flagTypes.SCOTIA_DEMO]: {
    production: false,
    staging: true,
    development: true,
    test: false,
  },

  [flagTypes.QUEBEC]: {
    production: false,
    staging: true,
    development: true,
    test: true,
  },
}

const reactEnvironment = process.env.REACT_APP_ENV || 'production'

export default (flagType) => flags[flagType][reactEnvironment]
