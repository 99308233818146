import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import SummarySection from '../../../../../common/components/Summary/components/SummarySection'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import dashboardSelectors from '../../../../Dashboard/ducks/dashboard/dashboardSelectors'
import documentsSelectors from '../../../../Dashboard/ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../../Dashboard/ducks/payments/paymentsSelectors'
import data from '../../../data'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import { triggeredQuestions } from '../../../engine'

const SectionReview = ({
  abTest,
  question,
  referrer,
  payments,
  documents,
  isPartner = false,
  answerStore,
  accountCreated,
  charityPartner,
}) => {
  const theRef = useRef(null)

  const theTriggeredQuestions = triggeredQuestions({
    abTest,
    payments,
    referrer,
    isPartner,
    documents,
    answerStore,
    theData: data,
    accountCreated,
    charityPartner,
  })
  /*
   * returns only the triggered questions from the specific
   * section and excludes the sectionReview question
   */
  const sectionQuestions = theTriggeredQuestions.filter(
    (q) =>
      q.section === question.section && !_isEmpty(q.fields) && !q.hideOnReview,
  )

  return (
    <div
      ref={theRef}
      className="bg-white w-full max-w-xl rounded-lg mb-2 p-4 sm:p-5"
    >
      <SummarySection section={sectionQuestions} answerStore={answerStore} />
    </div>
  )
}

SectionReview.propTypes = {
  abTest: PropTypes.string.isRequired,
  isPartner: PropTypes.bool,
  question: PropTypes.shape({ section: PropTypes.string.isRequired })
    .isRequired,
  referrer: PropTypes.shape({}).isRequired,
  answerStore: PropTypes.shape({}).isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  accountCreated: PropTypes.bool.isRequired,
  charityPartner: PropTypes.shape({}).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  payments: paymentsSelectors.getPayments(state.dashboard),
  charityPartner: commonSelectors.getCharityPartner(state),
  isPartner: dashboardSelectors.getIsPartner(state.dashboard),
  abTest: questionnaireSelectors.getAbTest(state.questionnaire),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
  documents: documentsSelectors.getDocuments(state.dashboard),
})

export default connect(mapStateToProps)(SectionReview)
