import React, { Suspense, useState } from 'react'
import PropTypes from 'prop-types'

import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Text from '../../../../../../../../../common/components/atoms/Text'
import PdfPreviewModal from '../../../../../../../../../common/components/PdfPreviewModal'
import useProvinceDictionary from '../../../../../../../../../common/hooks/useProvinceDictionary'
import { ONTARIO_TERMS } from '../../../../../../../../../common/utils/provinceDictionary/provinceDictionary'
import usePreviewDoc from './hooks/usePreviewDoc'

const PreviewDocButton = ({
  document,
  province,
  dispatchSetGlobalErrorMessage,
}) => {
  const [showModal, setShowModal] = useState(false)

  const { provinceDictionary } = useProvinceDictionary()

  const {
    isInitialLoading,
    data: previewFile,
    refetch: handleDownloadDocPreview,
  } = usePreviewDoc({
    docId: document._id,
    dispatchSetGlobalErrorMessage,
    onSuccess: () => setShowModal(true),
  })

  const { t } = useTranslation()

  const docTypeStr = {
    will: t('common:will'),
    propertyPoa: provinceDictionary(ONTARIO_TERMS.PROPERTY_POA, province),
    personalPoa: provinceDictionary(ONTARIO_TERMS.POA_FOR_CARE, province),
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',

          '&:hover span': {
            textDecoration: isInitialLoading ? 'none' : 'underline',
          },
        }}
        onClick={() => {
          if (!isInitialLoading) {
            handleDownloadDocPreview()
          }
        }}
        data-testid={`preview-doc-type-${document.docType}`}
      >
        <VisibilityIcon
          size="small"
          sx={{ mr: '0.25rem', fontSize: '0.93rem' }}
        />

        <Text weight="medium" sx={{ fontSize: '0.85rem', display: 'initial' }}>
          {isInitialLoading
            ? t('common:loading')
            : docTypeStr[document.docType] || t('common:preview')}
        </Text>
      </Box>

      {previewFile && (
        <Suspense fallback={<span />}>
          <PdfPreviewModal
            show={showModal}
            file={previewFile}
            onClose={() => setShowModal(false)}
          />
        </Suspense>
      )}
    </>
  )
}

PreviewDocButton.propTypes = {
  document: PropTypes.shape({
    _id: PropTypes.string,
    docType: PropTypes.string,
  }).isRequired,
  province: PropTypes.string.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

export default PreviewDocButton
