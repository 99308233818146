import React, { useState } from 'react'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../../atoms/Button'
import Text from '../../../atoms/Text'
import ChangePartnerEmailAddressModal from './components/ChangePartnerEmailAddressModal'

interface Props {
  partnerEmail: string
}

const ChangePartnerEmailAddress = ({ partnerEmail }: Props) => {
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)

  const { t } = useTranslation()

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Text
        size="xl"
        align="center"
        variant="paragraph"
        sx={{ mt: '1.3rem', mb: '2.4rem' }}
      >
        {partnerEmail}
      </Text>

      <Box>
        <Button
          variant="contained"
          label={t(
            'pages:AccountSettings.changePartnerEmailAddress.button.label',
          )}
          onClick={() => setShowChangeEmailModal(true)}
          data-testid="change-partners-email-address-button"
        />

        <ChangePartnerEmailAddressModal
          partnerEmail={partnerEmail}
          show={showChangeEmailModal}
          onClose={() => setShowChangeEmailModal(false)}
        />
      </Box>
    </Box>
  )
}

export default ChangePartnerEmailAddress
