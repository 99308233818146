import _get from 'lodash/get'

import { TFunction } from 'i18next'

import { HoverDefinitionArray } from '../../../../types/questionnaire.types'
import isHoverDefinition from './isHoverDefinition'
import isTranslateObj from './isTranslateObj'

export default (
  incoming: HoverDefinitionArray,
  t: TFunction<'translation', undefined>,
) =>
  incoming.map((item) => {
    // If the item value is a translation object, translate it
    if (isTranslateObj(item)) {
      return t(item.tKey, item?.options)
    }

    // If array item is a hover definition
    if (isHoverDefinition(item)) {
      /*
       * Translate the term if it is a translation object,
       * otherwise store the term as is.
       */
      const term = isTranslateObj(item.term) ? t(item.term.tKey) : item.term

      // Set definition to whatever is in the definition property
      let { definition } = item

      if (isTranslateObj(item.definition)) {
        // Set options to whatever is in the definition property
        let options = _get(item.definition, 'options')

        /*
         * If options exists (not undefined), iterate over each key-value pair
         * and translate if it is a translation object or leave as is if it is
         * not a translation object.
         */
        if (options) {
          options = Object.entries(options).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: isTranslateObj(value) ? t(value.tKey) : value,
            }),
            {} as Record<string, string>,
          )
        }
        /*
         * Reassign definition value to the translated string that uses the
         * options object that was transformed above
         */
        definition = t(item.definition.tKey, options)
      }

      return { term, definition }
    }

    // Otherwise (likely a string), return the item
    return item
  })
