"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const timezones = [
    {
        code: 'Pacific/Honolulu',
        label: 'Hawaii-Aleutian Time - Honolulu',
    },
    {
        code: 'Pacific/Rarotonga',
        label: 'Cook Islands Time - Rarotonga',
    },
    {
        code: 'Pacific/Tahiti',
        label: 'Tahiti Time - Tahiti',
    },
    {
        code: 'Pacific/Marquesas',
        label: 'Marquesas Time - Marquesas',
    },
    {
        code: 'America/Anchorage',
        label: 'Alaska Time - Anchorage',
    },
    {
        code: 'America/Juneau',
        label: 'Alaska Time - Juneau',
    },
    {
        code: 'America/Metlakatla',
        label: 'Alaska Time - Metlakatla',
    },
    {
        code: 'America/Nome',
        label: 'Alaska Time - Nome',
    },
    {
        code: 'America/Sitka',
        label: 'Alaska Time - Sitka',
    },
    {
        code: 'America/Yakutat',
        label: 'Alaska Time - Yakutat',
    },
    {
        code: 'Pacific/Gambier',
        label: 'Gambier Islands Time - Gambier',
    },
    {
        code: 'America/Los_Angeles',
        label: 'Pacific Time - Los Angeles',
    },
    {
        code: 'America/Tijuana',
        label: 'Pacific Time - Tijuana',
    },
    {
        code: 'America/Vancouver',
        label: 'Pacific Time - Vancouver',
    },
    {
        code: 'America/Boise',
        label: 'Mountain Time - Boise',
    },
    {
        code: 'America/Cambridge_Bay',
        label: 'Mountain Time - Cambridge Bay',
    },
    {
        code: 'America/Denver',
        label: 'Mountain Time - Denver',
    },
    {
        code: 'America/Edmonton',
        label: 'Mountain Time - Edmonton',
    },
    {
        code: 'America/Inuvik',
        label: 'Mountain Time - Inuvik',
    },
    {
        code: 'America/Ojinaga',
        label: 'Mountain Time - Ojinaga',
    },
    {
        code: 'America/Yellowknife',
        label: 'Mountain Time - Yellowknife',
    },
    {
        code: 'America/Belize',
        label: 'Central Time - Belize',
    },
    {
        code: 'America/Chicago',
        label: 'Central Time - Chicago',
    },
    {
        code: 'America/Costa_Rica',
        label: 'Central Time - Costa Rica',
    },
    {
        code: 'America/El_Salvador',
        label: 'Central Time - El Salvador',
    },
    {
        code: 'America/Guatemala',
        label: 'Central Time - Guatemala',
    },
    {
        code: 'America/Managua',
        label: 'Central Time - Managua',
    },
    {
        code: 'America/Mexico_City',
        label: 'Central Time - Mexico City',
    },
    {
        code: 'America/Regina',
        label: 'Central Time - Regina',
    },
    {
        code: 'America/Tegucigalpa',
        label: 'Central Time - Tegucigalpa',
    },
    {
        code: 'America/Winnipeg',
        label: 'Central Time - Winnipeg',
    },
    {
        code: 'America/Bahia_Banderas',
        label: 'Central Time - Bahia Banderas',
    },
    {
        code: 'America/Atikokan',
        label: 'Eastern Standard Time - Atikokan',
    },
    {
        code: 'America/Cancun',
        label: 'Eastern Standard Time - Cancun',
    },
    {
        code: 'America/Jamaica',
        label: 'Eastern Standard Time - Jamaica',
    },
    {
        code: 'America/Nassau',
        label: 'Eastern Standard Time - Nassau',
    },
    {
        code: 'America/New_York',
        label: 'Eastern Time - New York',
    },
    {
        code: 'America/Toronto',
        label: 'Eastern Time - Toronto',
    },
    {
        code: 'America/Detroit',
        label: 'Eastern Time - Detroit',
    },
    {
        code: 'America/Grand_Turk',
        label: 'Eastern Time - Grand Turk',
    },
    {
        code: 'America/Havana',
        label: 'Cuba Standard Time - Havana',
    },
    {
        code: 'America/Indiana/Indianapolis',
        label: 'Eastern Time - Indianapolis',
    },
    {
        code: 'America/Indiana/Marengo',
        label: 'Eastern Time - Marengo',
    },
    {
        code: 'America/Indiana/Petersburg',
        label: 'Eastern Time - Petersburg',
    },
    {
        code: 'America/Indiana/Vevay',
        label: 'Eastern Time - Vevay',
    },
    {
        code: 'America/Indiana/Vincennes',
        label: 'Eastern Time - Vincennes',
    },
    {
        code: 'America/Indiana/Winamac',
        label: 'Eastern Time - Winamac',
    },
    {
        code: 'America/Iqaluit',
        label: 'Eastern Time - Iqaluit',
    },
    {
        code: 'America/Kentucky/Louisville',
        label: 'Eastern Time - Louisville',
    },
    {
        code: 'America/Kentucky/Monticello',
        label: 'Eastern Time - Monticello',
    },
    {
        code: 'America/Montreal',
        label: 'Eastern Time - Montreal',
    },
    {
        code: 'America/Nipigon',
        label: 'Eastern Time - Nipigon',
    },
    {
        code: 'America/Pangnirtung',
        label: 'Eastern Time - Pangnirtung',
    },
    {
        code: 'America/Port-au-Prince',
        label: 'Eastern Time - Port-au-Prince',
    },
    {
        code: 'America/Thunder_Bay',
        label: 'Eastern Time - Thunder Bay',
    },
    {
        code: 'America/Toronto',
        label: 'Eastern Time - Toronto',
    },
    {
        code: 'America/Barbados',
        label: 'Atlantic Standard Time - Barbados',
    },
    {
        code: 'America/Blanc-Sablon',
        label: 'Atlantic Standard Time - Blanc-Sablon',
    },
    {
        code: 'America/Curacao',
        label: 'Atlantic Standard Time - Curacao',
    },
    {
        code: 'America/Dominica',
        label: 'Atlantic Standard Time - Dominica',
    },
    {
        code: 'America/Grenada',
        label: 'Atlantic Standard Time - Grenada',
    },
    {
        code: 'America/Guadeloupe',
        label: 'Atlantic Standard Time - Guadeloupe',
    },
    {
        code: 'America/Guyana',
        label: 'Guyana Time - Guyana',
    },
    {
        code: 'America/Kralendijk',
        label: 'Atlantic Standard Time - Kralendijk',
    },
    {
        code: 'America/Lower_Princes',
        label: 'Atlantic Standard Time - Lower Princes',
    },
    {
        code: 'America/Marigot',
        label: 'Atlantic Standard Time - Marigot',
    },
    {
        code: 'America/Martinique',
        label: 'Atlantic Standard Time - Martinique',
    },
    {
        code: 'America/Montserrat',
        label: 'Atlantic Standard Time - Montserrat',
    },
    {
        code: 'America/Port_of_Spain',
        label: 'Atlantic Standard Time - Port of Spain',
    },
    {
        code: 'America/Puerto_Rico',
        label: 'Atlantic Standard Time - Puerto Rico',
    },
    {
        code: 'America/Santo_Domingo',
        label: 'Atlantic Standard Time - Santo Domingo',
    },
    {
        code: 'America/St_Barthelemy',
        label: 'Atlantic Standard Time - St. Barthelemy',
    },
    {
        code: 'America/St_Kitts',
        label: 'Atlantic Standard Time - St. Kitts',
    },
    {
        code: 'America/St_Lucia',
        label: 'Atlantic Standard Time - St. Lucia',
    },
    {
        code: 'America/St_Thomas',
        label: 'Atlantic Standard Time - St. Thomas',
    },
    {
        code: 'America/St_Vincent',
        label: 'Atlantic Standard Time - St. Vincent',
    },
    {
        code: 'America/Tortola',
        label: 'Atlantic Standard Time - Tortola',
    },
    {
        code: 'Antarctica/Palmer',
        label: 'Chile Standard Time - Palmer',
    },
    {
        code: 'Antarctica/Rothera',
        label: 'Rothera Time - Rothera',
    },
    {
        code: 'America/Araguaina',
        label: 'Brasilia Time - Araguaina',
    },
    {
        code: 'America/Belem',
        label: 'Brasilia Time - Belem',
    },
    {
        code: 'America/Fortaleza',
        label: 'Brasilia Time - Fortaleza',
    },
    {
        code: 'America/Maceio',
        label: 'Brasilia Time - Maceio',
    },
    {
        code: 'America/Recife',
        label: 'Brasilia Time - Recife',
    },
    {
        code: 'America/Santarem',
        label: 'Brasilia Time - Santarem',
    },
    {
        code: 'America/Sao_Paulo',
        label: 'Brasilia Time - Sao Paulo',
    },
    {
        code: 'Atlantic/Stanley',
        label: 'Falkland Islands Time - Stanley',
    },
    {
        code: 'America/Cayenne',
        label: 'French Guiana Time - Cayenne',
    },
    {
        code: 'America/Paramaribo',
        label: 'Suriname Time - Paramaribo',
    },
    {
        code: 'Antarctica/Palmer',
        label: 'Chile Standard Time - Palmer',
    },
    {
        code: 'Antarctica/Rothera',
        label: 'Rothera Time - Rothera',
    },
    {
        code: 'America/Araguaina',
        label: 'Brasilia Time - Araguaina',
    },
    {
        code: 'America/Belem',
        label: 'Brasilia Time - Belem',
    },
    {
        code: 'America/Fortaleza',
        label: 'Brasilia Time - Fortaleza',
    },
    {
        code: 'America/Maceio',
        label: 'Brasilia Time - Maceio',
    },
    {
        code: 'America/Recife',
        label: 'Brasilia Time - Recife',
    },
    {
        code: 'America/Santarem',
        label: 'Brasilia Time - Santarem',
    },
    {
        code: 'America/Sao_Paulo',
        label: 'Brasilia Time - Sao Paulo',
    },
    {
        code: 'Atlantic/Stanley',
        label: 'Falkland Islands Time - Stanley',
    },
    {
        code: 'America/Cayenne',
        label: 'French Guiana Time - Cayenne',
    },
    {
        code: 'America/Paramaribo',
        label: 'Suriname Time - Paramaribo',
    },
    {
        code: 'America/Godthab',
        label: 'Greenland Time - Godthab',
    },
    {
        code: 'America/Miquelon',
        label: 'Saint Pierre and Miquelon Time - Miquelon',
    },
    {
        code: 'America/Noronha',
        label: 'Fernando de Noronha Time - Noronha',
    },
    {
        code: 'Atlantic/South_Georgia',
        label: 'South Georgia Time - South Georgia',
    },
    {
        code: 'America/Scoresbysund',
        label: 'East Greenland Time - Scoresbysund',
    },
    {
        code: 'Atlantic/Azores',
        label: 'Azores Time - Azores',
    },
    {
        code: 'Atlantic/Cape_Verde',
        label: 'Cape Verde Time - Cape Verde',
    },
    {
        code: 'Africa/Abidjan',
        label: 'Greenwich Mean Time - Abidjan',
    },
    {
        code: 'Africa/Accra',
        label: 'Ghana Mean Time - Accra',
    },
    {
        code: 'Africa/Bamako',
        label: 'Greenwich Mean Time - Bamako',
    },
    {
        code: 'Africa/Banjul',
        label: 'Greenwich Mean Time - Banjul',
    },
    {
        code: 'Africa/Bissau',
        label: 'Greenwich Mean Time - Bissau',
    },
    {
        code: 'Africa/Conakry',
        label: 'Greenwich Mean Time - Conakry',
    },
    {
        code: 'Africa/Dakar',
        label: 'Greenwich Mean Time - Dakar',
    },
    {
        code: 'Africa/Freetown',
        label: 'Greenwich Mean Time - Freetown',
    },
    {
        code: 'Africa/Lome',
        label: 'Greenwich Mean Time - Lome',
    },
    {
        code: 'Africa/Monrovia',
        label: 'Greenwich Mean Time - Monrovia',
    },
    {
        code: 'Africa/Nouakchott',
        label: 'Greenwich Mean Time - Nouakchott',
    },
    {
        code: 'Africa/Ouagadougou',
        label: 'Greenwich Mean Time - Ouagadougou',
    },
    {
        code: 'Africa/Sao_Tome',
        label: 'Greenwich Mean Time - Sao Tome',
    },
    {
        code: 'America/Danmarkshavn',
        label: 'Greenwich Mean Time - Danmarkshavn',
    },
    {
        code: 'Atlantic/Canary',
        label: 'Western European Time - Canary',
    },
    {
        code: 'Atlantic/Faroe',
        label: 'Western European Time - Faroe',
    },
    {
        code: 'Atlantic/Madeira',
        label: 'Western European Time - Madeira',
    },
    {
        code: 'Atlantic/Reykjavik',
        label: 'Greenwich Mean Time - Reykjavik',
    },
    {
        code: 'Atlantic/St_Helena',
        label: 'Greenwich Mean Time - St. Helena',
    },
    {
        code: 'Etc/GMT',
        label: 'GMT',
    },
    {
        code: 'Etc/UTC',
        label: 'Coordinated Universal Time (UTC)',
    },
    {
        code: 'Europe/Belfast',
        label: 'Greenwich Mean Time - Belfast',
    },
    {
        code: 'Europe/Dublin',
        label: 'Greenwich Mean Time - Dublin',
    },
    {
        code: 'Europe/Guernsey',
        label: 'Greenwich Mean Time - Guernsey',
    },
    {
        code: 'Europe/Isle_of_Man',
        label: 'Greenwich Mean Time - Isle of Man',
    },
    {
        code: 'Europe/Jersey',
        label: 'Greenwich Mean Time - Jersey',
    },
    {
        code: 'Europe/Lisbon',
        label: 'Western European Time - Lisbon',
    },
    {
        code: 'Europe/London',
        label: 'GMT - London',
    },
    {
        code: 'Africa/Algiers',
        label: 'Central European Time - Algiers',
    },
    {
        code: 'Africa/Bangui',
        label: 'West Africa Time - Bangui',
    },
    {
        code: 'Africa/Brazzaville',
        label: 'West Africa Time - Brazzaville',
    },
    {
        code: 'Africa/Casablanca',
        label: 'Western European Time - Casablanca',
    },
    {
        code: 'Africa/Douala',
        label: 'West Africa Time - Douala',
    },
    {
        code: 'Africa/El_Aaiun',
        label: 'Western European Time - El Aaiun',
    },
    {
        code: 'Africa/Kinshasa',
        label: 'West Africa Time - Kinshasa',
    },
    {
        code: 'Africa/Lagos',
        label: 'West Africa Time - Lagos',
    },
    {
        code: 'Africa/Libreville',
        label: 'West Africa Time - Libreville',
    },
    {
        code: 'Africa/Luanda',
        label: 'West Africa Time - Luanda',
    },
    {
        code: 'Africa/Malabo',
        label: 'West Africa Time - Malabo',
    },
    {
        code: 'Africa/Ndjamena',
        label: 'West Africa Time - Ndjamena',
    },
    {
        code: 'Africa/Niamey',
        label: 'West Africa Time - Niamey',
    },
    {
        code: 'Africa/Porto-Novo',
        label: 'West Africa Time - Porto-Novo',
    },
    {
        code: 'Africa/Tunis',
        label: 'Central European Time - Tunis',
    },
    {
        code: 'Africa/Windhoek',
        label: 'Central Africa Time - Windhoek',
    },
    {
        code: 'Europe/Amsterdam',
        label: 'Central European Time - Amsterdam',
    },
    {
        code: 'Europe/Andorra',
        label: 'Central European Time - Andorra',
    },
    {
        code: 'Europe/Belgrade',
        label: 'Central European Time - Belgrade',
    },
    {
        code: 'Europe/Berlin',
        label: 'Central European Time - Berlin',
    },
    {
        code: 'Europe/Bratislava',
        label: 'Central European Time - Bratislava',
    },
    {
        code: 'Europe/Brussels',
        label: 'Central European Time - Brussels',
    },
    {
        code: 'Europe/Budapest',
        label: 'Central European Time - Budapest',
    },
    {
        code: 'Europe/Busingen',
        label: 'Central European Time - Busingen',
    },
    {
        code: 'Europe/Copenhagen',
        label: 'Central European Time - Copenhagen',
    },
    {
        code: 'Europe/Gibraltar',
        label: 'Central European Time - Gibraltar',
    },
    {
        code: 'Europe/Ljubljana',
        label: 'Central European Time - Ljubljana',
    },
    {
        code: 'Europe/Luxembourg',
        label: 'Central European Time - Luxembourg',
    },
    {
        code: 'Europe/Madrid',
        label: 'Central European Time - Madrid',
    },
    {
        code: 'Europe/Malta',
        label: 'Central European Time - Malta',
    },
    {
        code: 'Europe/Monaco',
        label: 'Central European Time - Monaco',
    },
    {
        code: 'Europe/Oslo',
        label: 'Central European Time - Oslo',
    },
    {
        code: 'Europe/Paris',
        label: 'Central European Time - Paris',
    },
    {
        code: 'Europe/Podgorica',
        label: 'Central European Time - Podgorica',
    },
    {
        code: 'Europe/Prague',
        label: 'Central European Time - Prague',
    },
    {
        code: 'Europe/Rome',
        label: 'Central European Time - Rome',
    },
    {
        code: 'Europe/San_Marino',
        label: 'Central European Time - San Marino',
    },
    {
        code: 'Europe/Sarajevo',
        label: 'Central European Time - Sarajevo',
    },
    {
        code: 'Europe/Skopje',
        label: 'Central European Time - Skopje',
    },
    {
        code: 'Europe/Stockholm',
        label: 'Central European Time - Stockholm',
    },
    {
        code: 'Europe/Tirane',
        label: 'Central European Time - Tirane',
    },
    {
        code: 'Europe/Vaduz',
        label: 'Central European Time - Vaduz',
    },
    {
        code: 'Europe/Vatican',
        label: 'Central European Time - Vatican',
    },
    {
        code: 'Europe/Vienna',
        label: 'Central European Time - Vienna',
    },
    {
        code: 'Europe/Warsaw',
        label: 'Central European Time - Warsaw',
    },
    {
        code: 'Europe/Zagreb',
        label: 'Central European Time - Zagreb',
    },
    {
        code: 'Europe/Zurich',
        label: 'Central European Time - Zurich',
    },
    {
        code: 'Africa/Blantyre',
        label: 'Central Africa Time - Blantyre',
    },
    {
        code: 'Africa/Bujumbura',
        label: 'Central Africa Time - Bujumbura',
    },
    {
        code: 'Africa/Cairo',
        label: 'Eastern European Time - Cairo',
    },
    {
        code: 'Africa/Gaborone',
        label: 'Central Africa Time - Gaborone',
    },
    {
        code: 'Africa/Harare',
        label: 'Central Africa Time - Harare',
    },
    {
        code: 'Africa/Johannesburg',
        label: 'South Africa Standard Time - Johannesburg',
    },
    {
        code: 'Africa/Kigali',
        label: 'Central Africa Time - Kigali',
    },
    {
        code: 'Africa/Lubumbashi',
        label: 'Central Africa Time - Lubumbashi',
    },
    {
        code: 'Africa/Lusaka',
        label: 'Central Africa Time - Lusaka',
    },
    {
        code: 'Africa/Maputo',
        label: 'Central Africa Time - Maputo',
    },
    {
        code: 'Africa/Maseru',
        label: 'South Africa Standard Time - Maseru',
    },
    {
        code: 'Africa/Mbabane',
        label: 'South Africa Standard Time - Mbabane',
    },
    {
        code: 'Africa/Tripoli',
        label: 'Eastern European Time - Tripoli',
    },
    {
        code: 'Asia/Amman',
        label: 'Eastern European Time - Amman',
    },
    {
        code: 'Asia/Beirut',
        label: 'Eastern European Time - Beirut',
    },
    {
        code: 'Asia/Damascus',
        label: 'Eastern European Time - Damascus',
    },
    {
        code: 'Asia/Gaza',
        label: 'Eastern European Time - Gaza',
    },
    {
        code: 'Asia/Jerusalem',
        label: 'Israel Standard Time - Jerusalem',
    },
    {
        code: 'Asia/Nicosia',
        label: 'Eastern European Time - Nicosia',
    },
    {
        code: 'Asia/Tel_Aviv',
        label: 'Israel Standard Time - Tel Aviv',
    },
    {
        code: 'Europe/Athens',
        label: 'Eastern European Time - Athens',
    },
    {
        code: 'Europe/Bucharest',
        label: 'Eastern European Time - Bucharest',
    },
    {
        code: 'Europe/Chisinau',
        label: 'Eastern European Time - Chisinau',
    },
    {
        code: 'Europe/Helsinki',
        label: 'Eastern European Time - Helsinki',
    },
    {
        code: 'Europe/Istanbul',
        label: 'Turkey Standard Time - Istanbul',
    },
    {
        code: 'Europe/Kaliningrad',
        label: 'Eastern European Time - Kaliningrad',
    },
    {
        code: 'Europe/Kiev',
        label: 'Eastern European Time - Kiev',
    },
    {
        code: 'Europe/Mariehamn',
        label: 'Eastern European Time - Mariehamn',
    },
    {
        code: 'Europe/Minsk',
        label: 'Eastern European Time - Minsk',
    },
    {
        code: 'Europe/Riga',
        label: 'Eastern European Time - Riga',
    },
    {
        code: 'Europe/Sofia',
        label: 'Eastern European Time - Sofia',
    },
    {
        code: 'Europe/Tallinn',
        label: 'Eastern European Time - Tallinn',
    },
    {
        code: 'Europe/Uzhgorod',
        label: 'Eastern European Time - Uzhgorod',
    },
    {
        code: 'Europe/Vilnius',
        label: 'Eastern European Time - Vilnius',
    },
    {
        code: 'Europe/Zaporozhye',
        label: 'Eastern European Time - Zaporozhye',
    },
    {
        code: 'Africa/Addis_Ababa',
        label: 'East Africa Time - Addis Ababa',
    },
    {
        code: 'Africa/Asmara',
        label: 'East Africa Time - Asmara',
    },
    {
        code: 'Africa/Dar_es_Salaam',
        label: 'East Africa Time - Dar es Salaam',
    },
    {
        code: 'Africa/Djibouti',
        label: 'East Africa Time - Djibouti',
    },
    {
        code: 'Africa/Kampala',
        label: 'East Africa Time - Kampala',
    },
    {
        code: 'Africa/Khartoum',
        label: 'Central Africa Time - Khartoum',
    },
    {
        code: 'Africa/Mogadishu',
        label: 'East Africa Time - Mogadishu',
    },
    {
        code: 'Africa/Nairobi',
        label: 'East Africa Time - Nairobi',
    },
    {
        code: 'Antarctica/Syowa',
        label: 'Syowa Time - Syowa',
    },
    {
        code: 'Asia/Aden',
        label: 'Arabian Standard Time - Aden',
    },
    {
        code: 'Asia/Baghdad',
        label: 'Arabian Standard Time - Baghdad',
    },
    {
        code: 'Asia/Bahrain',
        label: 'Arabian Standard Time - Bahrain',
    },
    {
        code: 'Asia/Kuwait',
        label: 'Arabian Standard Time - Kuwait',
    },
    {
        code: 'Asia/Qatar',
        label: 'Arabian Standard Time - Qatar',
    },
    {
        code: 'Asia/Riyadh',
        label: 'Arabian Standard Time - Riyadh',
    },
    {
        code: 'Europe/Moscow',
        label: 'Moscow Standard Time - Moscow',
    },
    {
        code: 'Indian/Antananarivo',
        label: 'East Africa Time - Antananarivo',
    },
    {
        code: 'Indian/Comoro',
        label: 'East Africa Time - Comoro',
    },
    {
        code: 'Indian/Mayotte',
        label: 'East Africa Time - Mayotte',
    },
    {
        code: 'Asia/Tehran',
        label: 'Iran Standard Time - Tehran',
    },
    {
        code: 'Asia/Baku',
        label: 'Azerbaijan Standard Time - Baku',
    },
    {
        code: 'Asia/Dubai',
        label: 'Gulf Standard Time - Dubai',
    },
    {
        code: 'Asia/Muscat',
        label: 'Gulf Standard Time - Muscat',
    },
    {
        code: 'Asia/Tbilisi',
        label: 'Georgia Standard Time - Tbilisi',
    },
    {
        code: 'Asia/Yerevan',
        label: 'Armenia Standard Time - Yerevan',
    },
    {
        code: 'Europe/Astrakhan',
        label: 'Astrakhan Time - Astrakhan',
    },
    {
        code: 'Europe/Samara',
        label: 'Samara Time - Samara',
    },
    {
        code: 'Europe/Saratov',
        label: 'Saratov Time - Saratov',
    },
    {
        code: 'Europe/Ulyanovsk',
        label: 'Ulyanovsk Time - Ulyanovsk',
    },
    {
        code: 'Indian/Mahe',
        label: 'Seychelles Time - Mahe',
    },
    {
        code: 'Indian/Mauritius',
        label: 'Mauritius Time - Mauritius',
    },
    {
        code: 'Indian/Reunion',
        label: 'Reunion Time - Reunion',
    },
    {
        code: 'Asia/Kabul',
        label: 'Afghanistan Time - Kabul',
    },
    {
        code: 'Antarctica/Mawson',
        label: 'Mawson Time - Mawson',
    },
    {
        code: 'Asia/Aqtau',
        label: 'Aqtau Time - Aqtau',
    },
    {
        code: 'Asia/Aqtobe',
        label: 'Aqtobe Time - Aqtobe',
    },
    {
        code: 'Asia/Ashgabat',
        label: 'Turkmenistan Standard Time - Ashgabat',
    },
    {
        code: 'Asia/Atyrau',
        label: 'Atyrau Time - Atyrau',
    },
    {
        code: 'Asia/Dushanbe',
        label: 'Tajikistan Time - Dushanbe',
    },
    {
        code: 'Asia/Oral',
        label: 'Oral Time - Oral',
    },
    {
        code: 'Asia/Samarkand',
        label: 'Uzbekistan Time - Samarkand',
    },
    {
        code: 'Asia/Tashkent',
        label: 'Uzbekistan Time - Tashkent',
    },
    {
        code: 'Asia/Yekaterinburg',
        label: 'Yekaterinburg Time - Yekaterinburg',
    },
    {
        code: 'Indian/Kerguelen',
        label: 'French Southern and Antarctic Time - Kerguelen',
    },
    {
        code: 'Indian/Maldives',
        label: 'Maldives Time - Maldives',
    },
    {
        code: 'Asia/Karachi',
        label: 'Pakistan Standard Time - Karachi',
    },
    {
        code: 'Asia/Colombo',
        label: 'Sri Lanka Standard Time - Colombo',
    },
    {
        code: 'Asia/Kolkata',
        label: 'India Standard Time - Kolkata',
    },
    {
        code: 'Asia/Kathmandu',
        label: 'Nepal Time - Kathmandu',
    },
    {
        code: 'Asia/Almaty',
        label: 'Alma-Ata Time - Almaty',
    },
    {
        code: 'Asia/Bishkek',
        label: 'Kyrgyzstan Time - Bishkek',
    },
    {
        code: 'Asia/Qyzylorda',
        label: 'Qyzylorda Time - Qyzylorda',
    },
    {
        code: 'Asia/Thimphu',
        label: 'Bhutan Time - Thimphu',
    },
    {
        code: 'Asia/Urumqi',
        label: 'China Standard Time - Urumqi',
    },
    {
        code: 'Indian/Chagos',
        label: 'Indian Ocean Time - Chagos',
    },
    {
        code: 'Asia/Dhaka',
        label: 'Bangladesh Time - Dhaka',
    },
    {
        code: 'Asia/Novosibirsk',
        label: 'Novosibirsk Time - Novosibirsk',
    },
    {
        code: 'Asia/Omsk',
        label: 'Omsk Time - Omsk',
    },
    {
        code: 'Asia/Barnaul',
        label: 'Barnaul Time - Barnaul',
    },
    {
        code: 'Asia/Hovd',
        label: 'Hovd Time - Hovd',
    },
    {
        code: 'Asia/Krasnoyarsk',
        label: 'Krasnoyarsk Time - Krasnoyarsk',
    },
    {
        code: 'Asia/Novokuznetsk',
        label: 'Novokuznetsk Time - Novokuznetsk',
    },
    {
        code: 'Asia/Tomsk',
        label: 'Tomsk Time - Tomsk',
    },
    {
        code: 'Asia/Ulaanbaatar',
        label: 'Ulaanbaatar Time - Ulaanbaatar',
    },
    {
        code: 'Asia/Chita',
        label: 'Yakutsk Time - Chita',
    },
    {
        code: 'Asia/Irkutsk',
        label: 'Irkutsk Time - Irkutsk',
    },
    {
        code: 'Asia/Khandyga',
        label: 'Yakutsk Time - Khandyga',
    },
    {
        code: 'Asia/Pyongyang',
        label: 'Korea Standard Time - Pyongyang',
    },
    {
        code: 'Asia/Seoul',
        label: 'Korea Standard Time - Seoul',
    },
    {
        code: 'Asia/Tokyo',
        label: 'Japan Standard Time - Tokyo',
    },
    {
        code: 'Asia/Yakutsk',
        label: 'Yakutsk Time - Yakutsk',
    },
    {
        code: 'Pacific/Palau',
        label: 'Palau Time - Palau',
    },
    {
        code: 'Australia/Adelaide',
        label: 'Australian Central Standard Time - Adelaide',
    },
    {
        code: 'Australia/Darwin',
        label: 'Australian Central Standard Time - Darwin',
    },
    {
        code: 'Antarctica/DumontDUrville',
        label: "Dumont-d'Urville Time - Dumont-d'Urville",
    },
    {
        code: 'Asia/Vladivostok',
        label: 'Vladivostok Time - Vladivostok',
    },
    {
        code: 'Australia/Brisbane',
        label: 'Australian Eastern Standard Time - Brisbane',
    },
    {
        code: 'Australia/Hobart',
        label: 'Australian Eastern Standard Time - Hobart',
    },
    {
        code: 'Australia/Melbourne',
        label: 'Australian Eastern Standard Time - Melbourne',
    },
    {
        code: 'Australia/Sydney',
        label: 'Australian Eastern Standard Time - Sydney',
    },
    {
        code: 'Pacific/Chuuk',
        label: 'Chuuk Time - Chuuk',
    },
    {
        code: 'Pacific/Guam',
        label: 'Chamorro Standard Time - Guam',
    },
    {
        code: 'Pacific/Port_Moresby',
        label: 'Papua New Guinea Time - Port Moresby',
    },
    {
        code: 'Pacific/Saipan',
        label: 'Chamorro Standard Time - Saipan',
    },
    {
        code: 'Pacific/Truk',
        label: 'Chuuk Time - Truk',
    },
    {
        code: 'Pacific/Efate',
        label: 'Vanuatu Time - Efate',
    },
    {
        code: 'Pacific/Guadalcanal',
        label: 'Solomon Islands Time - Guadalcanal',
    },
    {
        code: 'Pacific/Kosrae',
        label: 'Kosrae Time - Kosrae',
    },
    {
        code: 'Pacific/Norfolk',
        label: 'Norfolk Time - Norfolk',
    },
    {
        code: 'Pacific/Noumea',
        label: 'New Caledonia Time - Noumea',
    },
    {
        code: 'Pacific/Pohnpei',
        label: 'Pohnpei Time - Pohnpei',
    },
    {
        code: 'Antarctica/Macquarie',
        label: 'Macquarie Island Time - Macquarie Island',
    },
    {
        code: 'Asia/Magadan',
        label: 'Magadan Time - Magadan',
    },
    {
        code: 'Asia/Sakhalin',
        label: 'Sakhalin Time - Sakhalin',
    },
    {
        code: 'Asia/Ust-Nera',
        label: 'Vladivostok Time - Ust-Nera',
    },
    {
        code: 'Asia/Vladivostok',
        label: 'Vladivostok Time - Vladivostok',
    },
    {
        code: 'Pacific/Bougainville',
        label: 'Bougainville Time - Bougainville',
    },
    {
        code: 'Pacific/Chatham',
        label: 'Chatham Islands Time - Chatham Islands',
    },
    {
        code: 'Pacific/Fakaofo',
        label: 'Tokelau Time - Fakaofo',
    },
    {
        code: 'Pacific/Funafuti',
        label: 'Tuvalu Time - Funafuti',
    },
    {
        code: 'Pacific/Kwajalein',
        label: 'Marshall Islands Time - Kwajalein',
    },
    {
        code: 'Pacific/Majuro',
        label: 'Marshall Islands Time - Majuro',
    },
    {
        code: 'Pacific/Nauru',
        label: 'Nauru Time - Nauru',
    },
    {
        code: 'Pacific/Tarawa',
        label: 'Gilbert Islands Time - Tarawa',
    },
    {
        code: 'Pacific/Wake',
        label: 'Wake Time - Wake',
    },
    {
        code: 'Pacific/Wallis',
        label: 'Wallis and Futuna Time - Wallis',
    },
    {
        code: 'Pacific/Auckland',
        label: 'New Zealand Standard Time - Auckland',
    },
    {
        code: 'Pacific/Enderbury',
        label: 'Phoenix Islands Time - Enderbury',
    },
    {
        code: 'Pacific/Fakaofo',
        label: 'Tokelau Time - Fakaofo',
    },
    {
        code: 'Pacific/Tongatapu',
        label: 'Tonga Time - Tongatapu',
    },
    {
        code: 'Pacific/Kiritimati',
        label: 'Line Islands Time - Kiritimati',
    },
];
exports.default = timezones;
