export default [
  {
    type: 'accordion',
    header: { tKey: 'sidebar:corporateExecutorIntroSidebar.item1.header' },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item1.children.item1.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: { tKey: 'sidebar:corporateExecutorIntroSidebar.item2.header' },
    children: [
      {
        type: 'list',
        variant: 'unordered',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item1.body',
        },
        children: [
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item1.children.item1',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item1.children.item2',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item1.children.item3',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item1.children.item4',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item1.children.item5',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item1.children.item6',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item1.children.item7',
          },
        ],
      },
      {
        type: 'bubble',
        variant: 'alert',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item2.children.item3.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: { tKey: 'sidebar:corporateExecutorIntroSidebar.item3.header' },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item3.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item3.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item3.children.item3.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item3.children.item4.body',
        },
      },
      {
        type: 'youtube',
        youtubeId: 'k7_6riQ5yFw',
        caption: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item3.children.item5.caption',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: { tKey: 'sidebar:corporateExecutorIntroSidebar.item4.header' },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item4.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item4.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item4.children.item3.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item4.children.item4.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: { tKey: 'sidebar:corporateExecutorIntroSidebar.item5.header' },
    children: [
      {
        type: 'list',
        variant: 'unordered',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item5.children.item1.body',
        },
        children: [
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item5.children.item1.children.item1',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item5.children.item1.children.item2',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item5.children.item1.children.item3',
          },
          {
            tKey: 'sidebar:corporateExecutorIntroSidebar.item5.children.item1.children.item4',
          },
        ],
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item5.children.item2.body',
        },
      },
      {
        type: 'youtube',
        youtubeId: 'roCLraqm8hg',
        caption: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item5.children.item3.caption',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: { tKey: 'sidebar:corporateExecutorIntroSidebar.item6.header' },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item6.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorIntroSidebar.item6.children.item2.body',
        },
      },
      {
        type: 'corporateExecutorContactForm',
      },
    ],
  },
]
