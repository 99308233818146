import appointmentsTypes from './appointmentsTypes'

interface Action {
  type: (typeof appointmentsTypes)[keyof typeof appointmentsTypes]
  payload?: any
}

const initialState = {
  appointments: [],
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case appointmentsTypes.SET_APPOINTMENTS:
      return { ...state, appointments: action.payload.appointments }

    default:
      return state
  }
}
