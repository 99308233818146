import React from 'react'

import _get from 'lodash/get'

import { Box, ListItem, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { Virtualizer } from '@tanstack/react-virtual'
import i18n from 'i18next'

import Text from '../../atoms/Text'
import { RecommendedCharity } from '../hooks/useRecommendedCharityQuery'
import AddCharityButton, { CharityLike } from './AddCharityButton'
import CharityCategoryAvatar from './CharityCategoryAvatar'

interface CharityListItemProps {
  index: number
  virtualizer: Virtualizer<HTMLDivElement, Element>
  charity: RecommendedCharity
  handleAddCharity: (charity: CharityLike) => void
}

const CharityListItem = (props: CharityListItemProps) => {
  const { index, virtualizer, charity, handleAddCharity } = props
  const { name, category, location, description, number } = charity

  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.down('xl'))

  return (
    <ListItem
      disableGutters
      divider
      data-index={index}
      ref={virtualizer.measureElement}
      sx={{
        [!isTablet || description ? 'py' : 'pt']: '1rem',
      }}
    >
      {!isTablet && <CharityCategoryAvatar category={category} />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          mb: description ? '0.5rem' : 'unset',
          flexGrow: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          {isTablet && <CharityCategoryAvatar category={category} />}
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Text size="md" variant="display">
              {name}
            </Text>
            <Text size="sm">{location ?? 'CANADA'}</Text>
            {number && <Text size="xs">{number}</Text>}
          </Box>
        </Box>

        {isTablet && (
          <Box sx={{ width: '100%' }}>
            <AddCharityButton
              charity={charity}
              handleAddCharity={handleAddCharity}
            />
          </Box>
        )}

        {description && (
          <Text size="xs" sx={{ mt: isTablet ? '0rem' : '1rem' }}>
            {/*
             * Use active language if available, otherwise default to English
             */}
            {description?.[CONSTANTS.languageTypes.EN_CA.id]
              ? _get(
                  description,
                  i18n.language,
                  description[CONSTANTS.languageTypes.EN_CA.id],
                )
              : description}
          </Text>
        )}
      </Box>
      {!isTablet && (
        <AddCharityButton
          charity={charity}
          handleAddCharity={handleAddCharity}
        />
      )}
    </ListItem>
  )
}

export default CharityListItem
