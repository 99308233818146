import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import _isEmpty from 'lodash/isEmpty'

import { Box, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CONSTANTS } from '@epilogue/common'
import { format } from 'date-fns'
import { AnimatePresence, motion } from 'motion/react'

import OneThird from '../../../../../../../../../common/assets/images/1-3.png'
import TwoThirds from '../../../../../../../../../common/assets/images/2-3.png'
import ThreeThirds from '../../../../../../../../../common/assets/images/3-3.png'
import LoadingSpinner from '../../../../../../../../../common/components/atoms/LoadingSpinner'
import RadioGroup from '../../../../../../../../../common/components/atoms/RadioGroup'
import Text from '../../../../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../../common/styles/muiTheme'

const useStyles = makeStyles(() => ({
  paymentOptionWrapper: {
    width: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
    border: `1px solid ${
      themeColors[themeColorTypes.GREY][themeColorVariants.LIGHT]
    }`,

    '& label': {
      margin: '0',
      padding: '0.3rem 1.2rem 0.3rem 0.3rem',

      '&.checked': {
        backgroundColor:
          themeColors[themeColorTypes.ACCENT_2][themeColorVariants.LIGHTER],
      },
    },
  },

  borderBottom: {
    borderBottom: `1px solid ${
      themeColors[themeColorTypes.GREY][themeColorVariants.LIGHT]
    }`,
  },

  borderTop: {
    borderTop: `1px solid ${
      themeColors[themeColorTypes.GREY][themeColorVariants.LIGHT]
    }`,
    padding: '1rem 0 0',
    margin: '1rem 0 0',
  },
}))

const animationVariants = {
  unmounted: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },

  mounted: {
    opacity: 1,
    height: 'auto',

    transition: {
      opacity: {
        duration: 0.35,
        delay: 0.03,
      },
      height: { duration: 0.35 },
    },
  },

  unmounting: {
    height: 0,
    opacity: 0,

    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.3 * 1.15 },
    },
  },
}

const radioButtons = [
  {
    value: CONSTANTS.paymentOptions.ONE_TIME_PAYMENT,
    label: 'One-time payment',
  },
  {
    value: CONSTANTS.paymentOptions.PAYMENT_PLAN,
    label: 'Pay in 3 interest-free installments',
  },
]

const PaymentOptions = ({
  onChange,
  isLoading,
  installments = [],
  paymentOption,
  downPaymentAmount,
}) => {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    if (paymentOption === CONSTANTS.paymentOptions.PAYMENT_PLAN) {
      amplitude.sendEvent('ViewedPlanPayment')
    } else {
      amplitude.sendEvent('ViewedStandardPayment')
    }
  }, [paymentOption])

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box width="100%" data-testid="payment-options-radio-buttons-wrapper">
        <RadioGroup
          onChange={onChange}
          name="paymentOption"
          radioButtons={radioButtons}
          defaultActive={paymentOption}
          className={classes.paymentOptionWrapper}
        />
      </Box>

      <AnimatePresence>
        {paymentOption === CONSTANTS.paymentOptions.PAYMENT_PLAN && (
          <motion.div
            exit="unmounting"
            animate="mounted"
            initial="unmounted"
            style={{ width: '100%' }}
            variants={animationVariants}
          >
            <Box
              py="2rem"
              width="100%"
              display="flex"
              className={classes.borderBottom}
            >
              <Box display="flex" flexDirection="column" width="100%">
                <Box pb="1.5rem">
                  <Text align="center" size="xl">
                    Payment Terms
                  </Text>
                </Box>

                <Box>
                  {isLoading || !downPaymentAmount || _isEmpty(installments) ? (
                    <Box py="2rem">
                      <LoadingSpinner delay="1" size="42px" />
                    </Box>
                  ) : (
                    <>
                      {downPaymentAmount && (
                        <Box display="flex" alignItems="center" width="100%">
                          <Box width="20px" mr="1rem">
                            <img src={OneThird} alt="one-third pie" />
                          </Box>
                          <Text>
                            <Text weight="bold" display="inline">
                              ${downPaymentAmount.toFixed(2)}
                            </Text>{' '}
                            charged today
                          </Text>
                        </Box>
                      )}

                      {installments.map((installment, index) => {
                        const { scheduled, amount: installmentAmount } =
                          installment

                        return (
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            key={scheduled}
                            className={classes.borderTop}
                          >
                            <Box width="20px" mr="1rem">
                              {index === 0 ? (
                                <img src={TwoThirds} alt="two-thirds pie" />
                              ) : (
                                <img src={ThreeThirds} alt="three-thirds pie" />
                              )}
                            </Box>
                            <Text>
                              <Text display="inline" weight="bold">
                                ${installmentAmount.toFixed(2)}
                              </Text>{' '}
                              charged {isDesktop ? 'on' : ''}{' '}
                              {format(
                                new Date(scheduled),
                                `${isDesktop ? 'MMMM d, yyyy' : 'MM/dd/yy'}`,
                              )}
                            </Text>
                          </Box>
                        )
                      })}
                    </>
                  )}
                </Box>

                <Box pt="1.5rem">
                  <Text color={themeColorTypes.GREY} size="xxs">
                    By opting to pay in installments, you authorize Epilogue to
                    charge your credit card in accordance with the schedule
                    shown here.
                  </Text>
                </Box>
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}

PaymentOptions.propTypes = {
  downPaymentAmount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  paymentOption: PropTypes.oneOf(Object.values(CONSTANTS.paymentOptions))
    .isRequired,
  installments: PropTypes.arrayOf(
    PropTypes.shape({ amount: PropTypes.number, scheduled: PropTypes.string }),
  ),
}

export default React.memo(PaymentOptions)
