import { ReactElement } from 'react'

import { StatusTypes } from './documentCards.types'
import { InformationalModalData } from './informationalModalData.types'

export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

interface DownloadButtonAction {
  type: 'download'
}

interface ModalButtonAction {
  type: 'modal'
  autoOpenModal?: boolean
  modalType:
    | 'staticDocDownload'
    | 'bonusDocQuestionnaire'
    | 'informational'
    | 'contact'
    | 'preview'
    | 'custom'
}

interface StaticDocDownloadModalButtonAction extends ModalButtonAction {
  // TS-TODO: update this when documents/docTypes are typed
  docType: string
  modalType: 'staticDocDownload'
}

interface BonusDocQuestionnaireModalButtonAction extends ModalButtonAction {
  // TS-TODO: update this when documents/docTypes are typed
  docType: string
  modalType: 'bonusDocQuestionnaire'
}

interface InformationalModalButtonAction extends ModalButtonAction {
  modalType: 'informational'
  data: InformationalModalData
}

export interface PreviewModalButtonAction extends ModalButtonAction {
  modalType: 'preview'
  omitPageNumbers: Array<number>
  previewModalType: 'generated' | 'static'
}

interface GeneratedPreviewModalButtonAction extends PreviewModalButtonAction {
  previewModalType: 'generated'
}

interface StaticPreviewModalButtonAction extends PreviewModalButtonAction {
  docType: string
  previewModalType: 'static'
}

export interface ContactModalData {
  icon?: ReactElement
  label?: string
  body?: string[]
}

interface ContactModalButtonAction extends ModalButtonAction {
  modalType: 'contact'
  data: ContactModalData
}

export interface CustomModalData {
  element: ReactElement
  fullBleed?: boolean
}

interface CustomModalButtonAction extends ModalButtonAction {
  modalType: 'custom'
  data: CustomModalData
}

interface NavigateButtonAction {
  type: 'navigate'
  path: string
  openInNewTab?: boolean
}

export type ButtonActionType =
  | DownloadButtonAction
  | ContactModalButtonAction
  | InformationalModalButtonAction
  | StaticDocDownloadModalButtonAction
  | BonusDocQuestionnaireModalButtonAction
  | StaticPreviewModalButtonAction
  | GeneratedPreviewModalButtonAction
  | NavigateButtonAction
  | CustomModalButtonAction

export interface Button {
  label: string
  color: string
  action: ButtonActionType

  fullWidth?: boolean
  onClick?: () => void
  colorVariant?: string
  endIcon?: ReactElement
  startIcon?: ReactElement
  variant?: 'contained' | 'outlined'
  parentName?: string
}

type ButtonType = {
  [key in ButtonTypes]?: Button
}

export type Buttons = {
  [key in StatusTypes]?: ButtonType
}
