import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { Box, CircularProgress, Button as MuiButton } from '@mui/material'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'

const buttonFontSizes = {
  xxs: '0.8125rem', // 13px
  xs: '0.875rem', // 14px
  sm: '0.9375rem', // 15px
  md: '1rem', // 16px
  lg: '1.0625rem', // 17px
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: ({ weight }) => (weight === 'medium' ? '500' : weight),
    padding: '0.25rem 0.6rem',
    fontSize: ({ size }) => buttonFontSizes[size],

    color: ({ color, colorVariant }) =>
      _get(theme, [
        'palette',
        color,
        colorVariant || [themeColorVariants.MAIN],
      ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],

    '&:hover': {
      backgroundColor: ({ color, colorVariant }) =>
        alpha(
          _get(theme, [
            'palette',
            color,
            colorVariant || [themeColorVariants.MAIN],
          ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
          0.05,
        ),
    },
  },

  icon: {
    '& > *:first-child': {
      fontSize: ({ size, iconFontSize }) => {
        if (iconFontSize) {
          return iconFontSize
        }

        if (size === 'xxsmall') {
          return '0.875rem'
        }

        if (size === 'xsmall') {
          return '1rem'
        }

        if (size === 'small') {
          return '1.1rem'
        }

        if (size === 'medium') {
          return '1.125rem'
        }

        // size === 'large'
        return '1.2rem'
      },
    },
  },

  label: { textTransform: 'none' },

  contained: {
    borderRadius: ({ rounded }) => (rounded === 'full' ? '9999px' : '4px'),
    padding: '0.4rem 1.3rem',
    fontSize: ({ size }) => buttonFontSizes[size],

    backgroundColor: ({ color, colorVariant }) =>
      _get(theme, [
        'palette',
        color,
        colorVariant || [themeColorVariants.MAIN],
      ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],

    color: ({ color, colorVariant }) => {
      if (
        color === themeColorTypes.ACCENT_2 &&
        [
          themeColorVariants.LIGHTEST,
          themeColorVariants.LIGHTER,
          themeColorVariants.LIGHT,
          themeColorVariants.MAIN,
          themeColorVariants.DARK,
          themeColorVariants.DARKER,
        ].includes(colorVariant)
      ) {
        return themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN]
      }

      if (
        color === themeColorTypes.GREY &&
        [themeColorVariants.LIGHTEST, themeColorVariants.LIGHTER].includes(
          colorVariant,
        )
      ) {
        return themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN]
      }

      if ([themeColorTypes.NEUTRAL, themeColorTypes.WHITE].includes(color)) {
        return themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN]
      }
      // All fall-through colours get white text
      return themeColors[themeColorTypes.WHITE][themeColorVariants.MAIN]
    },

    '&:hover': {
      backgroundColor: ({ color, colorVariant }) =>
        _get(theme, [
          'palette',
          color,
          themeColorVariants.BUTTON_HOVER,
          colorVariant,
        ]) ||
        theme.palette[themeColorTypes.BRAND][themeColorVariants.BUTTON_HOVER][
          themeColorVariants.MAIN
        ],
    },
  },

  outlined: {
    borderRadius: ({ rounded }) => (rounded === 'full' ? '9999px' : '4px'),
    padding: '0.4rem 1.3rem',

    border: ({ color, colorVariant }) =>
      `1px solid ${alpha(
        _get(theme, [
          'palette',
          color,
          colorVariant || [themeColorVariants.MAIN],
        ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
        0.4,
      )}`,

    color: ({ color, colorVariant }) =>
      _get(theme, [
        'palette',
        color,
        colorVariant || [themeColorVariants.MAIN],
      ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],

    '&:hover': {
      border: ({ color, colorVariant }) =>
        `1px solid ${
          _get(theme, [
            'palette',
            color,
            colorVariant || [themeColorVariants.MAIN],
          ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN]
        }`,

      backgroundColor: ({ color, colorVariant }) =>
        alpha(
          _get(theme, [
            'palette',
            color,
            colorVariant || [themeColorVariants.MAIN],
          ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
          0.045,
        ),
    },
  },

  spinnerSvg: {
    color: ({ spinnerColor, spinnerColorVariant }) =>
      _get(theme, [
        'palette',
        spinnerColor,
        spinnerColorVariant || [themeColorVariants.MAIN],
      ]) || themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
  },
}))

const Button = ({
  sx = {},
  type = 'button',
  size = 'md',
  color = themeColorTypes.BRAND,
  label,
  weight = 'medium',
  endIcon,
  rounded = 'full',
  onClick,
  variant,
  className,
  disabled = false,
  startIcon,
  fullWidth = false,
  colorVariant = themeColorVariants.MAIN,
  iconFontSize,
  onDisabledClick,
  'data-testid': dataTestId,

  working = false,
  spinnerSize = '1rem',
  spinnerColor = themeColorTypes.BRAND,
  spinnerDelay = 120,
  spinnerPadding = '0',
  spinnerColorVariant = themeColorVariants.MAIN,
}) => {
  const [isWorking, setIsWorking] = useState(false)

  const classes = useStyles({
    size,
    color,
    weight,
    rounded,
    colorVariant,
    iconFontSize,

    isWorking,
    spinnerColor,
    spinnerPadding,
    spinnerColorVariant,
  })

  useEffect(() => {
    // delay spinner to avoid flash
    let timeout
    if (working) {
      timeout = setTimeout(() => {
        setIsWorking(true)
      }, spinnerDelay)
    } else {
      setIsWorking(false)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [working])

  return (
    <Box
      component="span"
      onClick={() => {
        if (disabled) {
          onDisabledClick?.()
        }
      }}
    >
      <MuiButton
        classes={{
          root: classes.root,
          label: classes.label,
          endIcon: classes.icon,
          startIcon: classes.icon,
          outlined: classes.outlined,
          contained: classes.contained,
        }}
        data-testid={dataTestId}
        className={className}
        type={type}
        disableRipple
        disableFocusRipple
        disableElevation
        variant={variant}
        onClick={onClick}
        disabled={disabled || working}
        startIcon={startIcon}
        endIcon={endIcon}
        fullWidth={fullWidth}
        sx={sx}
      >
        {isWorking ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ padding: spinnerPadding }}
          >
            <CircularProgress
              size={spinnerSize}
              classes={{ root: classes.spinnerRoot, svg: classes.spinnerSvg }}
            />
          </Box>
        ) : (
          label
        )}
      </MuiButton>
    </Box>
  )
}

Button.propTypes = {
  sx: PropTypes.shape({}),
  onClick: PropTypes.func,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.node,
  className: PropTypes.string,
  iconFontSize: PropTypes.string,
  onDisabledClick: PropTypes.func,
  'data-testid': PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  rounded: PropTypes.oneOf(['full', 'normal']),
  variant: PropTypes.oneOf(['contained', 'outlined']),
  size: PropTypes.oneOf(Object.keys(buttonFontSizes)),
  weight: PropTypes.oneOf(['normal', 'medium', 'bold']),
  color: PropTypes.oneOf(Object.values(themeColorTypes)),
  colorVariant: PropTypes.oneOf(Object.values(themeColorVariants)),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

  working: PropTypes.bool,
  spinnerSize: PropTypes.string,
  spinnerDelay: PropTypes.number,
  spinnerPadding: PropTypes.string,
  spinnerColor: PropTypes.oneOf(Object.values(themeColorTypes)),
  spinnerColorVariant: PropTypes.oneOf(Object.values(themeColorVariants)),
}

export default Button
