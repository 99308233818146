import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'

import { CONSTANTS } from '@epilogue/common'
import cn from 'classnames'
import { Image } from 'grommet'
import styled from 'styled-components'

import Modal from '../../../../../../../../../common/components/atoms/Modal'
import useResponsive, {
  breakpoints,
} from '../../../../../../../../../common/hooks/useResponsive'
import LawyerForm from '../../../LawyerForm'
import AboutLawyer from './components/AboutLawyer'
import LawyerName from './components/LawyerName'

const InnerModalWrapper = styled.div`
  max-width: 59rem;
  overflow-x: hidden;
`
const ImageWrapper = styled.div`
  top: 5.2rem;
`
const StyledImage = styled(Image)`
  border: 4px solid white;
`

const useStyles = makeStyles({
  modal: {
    '& .MuiIconButton-root': {
      backgroundColor: 'unset',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'rgba(34, 28, 84, 0.08)',
    },
  },
})

const LawyerModal = ({ show, lawyer, handleSetShow }) => {
  const { imageUrl } = lawyer
  const [viewportWidth] = useResponsive()
  const classes = useStyles()

  return (
    <Modal
      fullBleed
      show={show}
      className={classes.modal}
      contentWrapperDisplay="block"
      onClose={() => handleSetShow(false)}
    >
      <InnerModalWrapper
        className={cn(viewportWidth < breakpoints.lg ? 'w-full' : 'w-screen')}
      >
        <div
          className={cn(
            'bg-neutral-2 p-6 py-10 flex flex-col-reverse',
            'sm:p-10',
            'md:p-12 md:-mt-16 md:flex-row',
          )}
        >
          <div
            className={cn(
              'mr-0 flex flex-col items-center text-center',
              'md:w-3/5 md:justify-end md:items-start md:text-left md:mr-10',
            )}
          >
            <LawyerName lawyer={lawyer} />
          </div>

          <ImageWrapper
            className={cn(
              'flex static justify-center w-full mb-4 ml-0',
              'sm:mb-6',
              'md:relative md:w-2/5 md:mb-0 md:ml-6',
              'lg:ml-14',
              'xl:ml-18',
            )}
          >
            <div className={cn('w-32', 'xs:w-40', 'sm:w-56')}>
              <StyledImage
                fit="contain"
                src={imageUrl}
                className={cn('rounded-full')}
              />
            </div>
          </ImageWrapper>
        </div>

        <div
          className={cn(
            'p-6 py-10 flex flex-col justify-between',
            'sm:p-10',
            'md:p-12 md:flex-row',
          )}
        >
          <div className={cn('w-full mr-0', 'md:w-7/12 md:mr-18')}>
            <AboutLawyer lawyer={lawyer} />
          </div>

          <div
            className={cn(
              'bg-white pb-2 pt-6 w-full mt-8 mb-4 ml-0 flex flex-col items-center justify-center',
              'md:w-5/12 md:my-0 md:ml-6',
            )}
          >
            <LawyerForm lawyer={lawyer} />
          </div>
        </div>
      </InnerModalWrapper>
    </Modal>
  )
}

LawyerModal.propTypes = {
  lawyer: PropTypes.shape({
    _id: PropTypes.string,
    bio: PropTypes.shape({
      ...Object.values(CONSTANTS.languageTypes).reduce((acc, { id }) => {
        acc[id] = PropTypes.string
        return acc
      }, {}),
    }),
    firm: PropTypes.string,
    city: PropTypes.string,
    imageUrl: PropTypes.string,
    province: PropTypes.string,
    lawyerLastName: PropTypes.string,
    lawyerFirstName: PropTypes.string,
    highlights: PropTypes.arrayOf(
      PropTypes.shape({
        ...Object.values(CONSTANTS.languageTypes).reduce((acc, { id }) => {
          acc[id] = PropTypes.string
          return acc
        }, {}),
      }),
    ),
  }).isRequired,
  show: PropTypes.bool.isRequired,
  handleSetShow: PropTypes.func.isRequired,
}

export default LawyerModal
