import _get from 'lodash/get'

import { useMutation } from '@tanstack/react-query'

import { errorHandler, PATHS, request } from '../../../../request'

interface RequestPayload {
  accessToken: string
  questionnaireId: string
}

interface ErrorResponse {
  response: {
    data: {
      report: boolean
    }
  }
}

export default ({
  onSuccess,
  dispatchSetGlobalErrorMessage,
}: {
  onSuccess: () => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}) =>
  useMutation<unknown, ErrorResponse, RequestPayload>({
    mutationFn: ({ accessToken, questionnaireId }) =>
      request({
        method: 'POST',
        url: PATHS.USER_AUTH_GOOGLE,
        data: { access_token: accessToken, questionnaireId },
      }),

    onSuccess,
    onError: (error) => {
      /*
       * If the server specifically sends report: false, use that.
       * Otherwise, default to report: true
       */
      const report = _get(error, ['response', 'data', 'report'], true)
      errorHandler({
        error,
        reportError: report,
        fallbackErrorMessage:
          'Unable to log in with Google. Please try again later.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    },
  })
