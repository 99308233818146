import navigationTypes from './navigationTypes'

export default {
  nextQuestion: ({ questionFragments }) => ({
    type: navigationTypes.NEXT_QUESTION,
    payload: { questionFragments },
  }),

  previousQuestion: ({ clearFrags = true } = {}) => ({
    type: navigationTypes.PREVIOUS_QUESTION,
    payload: { clearFrags },
  }),

  pushQuestion: ({ cleansedQuestionStack = [], nextQuestion }) => ({
    type: navigationTypes.PUSH_QUESTION,
    payload: { cleansedQuestionStack, nextQuestion },
  }),

  toQuestion: ({ question, questionId, fromDashboard }) => ({
    type: navigationTypes.TO_QUESTION,
    payload: { question, questionId, fromDashboard },
  }),

  updateQuestionStack: ({ questionStack }) => ({
    type: navigationTypes.UPDATE_QUESTION_STACK,
    payload: { questionStack },
  }),

  navNextAutomatic: ({ fragment }) => ({
    type: navigationTypes.NAV_NEXT_AUTOMATIC,
    payload: { fragment },
  }),

  updateSectionProgress: ({ progress }) => ({
    type: navigationTypes.UPDATE_SECTION_PROGRESS,
    payload: { progress },
  }),

  updateQuestionnaireProgress: ({ progress }) => ({
    type: navigationTypes.UPDATE_QUESTIONNAIRE_PROGRESS,
    payload: { progress },
  }),

  preventNext: (prevent) => ({
    type: navigationTypes.PREVENT_NEXT,
    payload: { prevent },
  }),

  preventPrevious: (prevent) => ({
    type: navigationTypes.PREVENT_PREVIOUS,
    payload: { prevent },
  }),

  dashboardRedirect: (redirect) => ({
    type: navigationTypes.DASHBOARD_REDIRECT,
    payload: { redirect },
  }),

  preventAnimation: (prevent) => ({
    type: navigationTypes.PREVENT_ANIMATION,
    payload: { prevent },
  }),

  working: (working) => ({
    type: navigationTypes.WORKING,
    payload: { working },
  }),

  setNextUnansweredQuestion: (question) => ({
    type: navigationTypes.SET_NEXT_UNANSWERED_QUESTION,
    payload: { question },
  }),

  editQuestionnaireFromDashboard: () => ({
    type: navigationTypes.EDIT_QUESTIONNAIRE_FROM_DASHBOARD,
  }),

  interceptModal: (interceptModalData) => ({
    type: navigationTypes.INTERCEPT_MODAL,
    payload: { ...interceptModalData },
  }),
}
