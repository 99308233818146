import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'
import _toString from 'lodash/toString'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MaterialSelect from '@mui/material/Select'

import { useTranslation } from 'react-i18next'

const Select = ({
  disabled = false,
  native = true,
  selectId,
  value,
  options,
  onInputChange,
  placeholder,
  isInvalid,
  validationMessage,
  sx = {},
  'data-testid': dataTestId,
}) => {
  const inputLabel = React.useRef(null)
  const [inputValue, setInputValue] = useState(value || '')

  const OptionTagName = native ? 'option' : MenuItem

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [inputValue, value])

  const { t } = useTranslation()

  const translatedPlaceholder = t(_get(placeholder, 'tKey', placeholder))
  const translatedDefaultValidationMessage =
    validationMessage || t('common:required')

  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      error={isInvalid}
      fullWidth
    >
      <InputLabel disabled={disabled} ref={inputLabel} id={selectId}>
        {translatedPlaceholder}
      </InputLabel>

      <MaterialSelect
        sx={sx}
        native={native}
        id={selectId}
        value={inputValue}
        disabled={disabled}
        variant="outlined"
        data-testid={dataTestId}
        label={translatedPlaceholder}
        onChange={(e) => {
          const val = e.target.value
          setInputValue(val)
          onInputChange(val)
        }}
      >
        {/*
         * In order for the placeholder to be displayed when a value has not
         * been selected (for a native style Select), an option with a falsy
         * value must be the first option
         */}
        {native && translatedPlaceholder && (
          <option aria-label="None" value={undefined} />
        )}
        {options.map((item) => (
          <OptionTagName
            key={item.value || _toString(Date.now() + Math.random())}
            value={item.value}
          >
            {t(_get(item, 'label.tKey', item.label))}
          </OptionTagName>
        ))}
      </MaterialSelect>

      {isInvalid && (
        <div className="-ml-2">
          <FormHelperText>{translatedDefaultValidationMessage}</FormHelperText>
        </div>
      )}
    </FormControl>
  )
}

Select.propTypes = {
  native: PropTypes.bool,
  selectId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onInputChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ tKey: PropTypes.string }),
  ]),
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  validationMessage: PropTypes.string,
  sx: PropTypes.shape({}),
  'data-testid': PropTypes.string,
}

export default Select
