import React from 'react'
import PropTypes from 'prop-types'

import featureFlag, { flagTypes } from '../../utils/featureFlag'
import CatastrophicError from '../molecules/CatastrophicError'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: null }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (featureFlag(flagTypes.SENTRY)) {
      window.captureException(error, errorInfo)
    }
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <CatastrophicError />
    }
    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
