import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorHandler, PATHS, request } from '../request'
import { queryKeys, queryKeysBase } from '../utils/queryKeys'

interface RequestProps {
  token: string
  questionnaireId: string
  // TS-TODO: update when CONSTANTS.togglesTypes is typed
  toggleOn?: string[]
  overwrite?: boolean
}

export default () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      token,
      questionnaireId,
      toggleOn,
      overwrite = true,
    }: RequestProps) =>
      request({
        method: 'PATCH',
        params: { token },
        data: { toggleOn, overwrite },
        url: `${PATHS.TOGGLE}/${questionnaireId}`,
      }),

    onError: (error) => errorHandler({ error, reportError: true }),
    onSuccess: () =>
      queryClient.invalidateQueries(queryKeys[queryKeysBase.toggles]()),
  })
}
