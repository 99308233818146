import _get from 'lodash/get'

import { Appointment } from '../../../../types/appointment.types'
import appointmentsName from './appointmentsName'

export default {
  getAppointments: (state: any): Appointment[] =>
    _get(state[appointmentsName], 'appointments'),

  getPendingWitnessingAppointments: (state: any): Appointment[] => {
    const appointments = _get(state[appointmentsName], 'appointments')
    return appointments.filter(
      (appointment: Appointment) =>
        appointment.status === 'pending' && appointment.type === 'witnessing',
    )
  },
}
