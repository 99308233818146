import React, { useEffect } from 'react'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, useMediaQuery } from '@mui/material'

import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'

import { InsuranceQuotesResponse } from '../quote.types'
import QuoteCard from './QuoteCard'

interface Props {
  isWorking: boolean
  quotes: InsuranceQuotesResponse[]
  selectedCoverageAmount: number | undefined
  handleSelectCoverageAmount: (coverageAmount: number) => void
}

const QuoteCardCarousel = ({
  isWorking,
  selectedCoverageAmount,
  quotes: insuranceQuotes,
  handleSelectCoverageAmount,
}: Props) => {
  const isMobile = useMediaQuery('(max-width:560px)')
  const isTablet = useMediaQuery('(max-width:740px)')

  const quotes: InsuranceQuotesResponse[] = isWorking
    ? Array.from({ length: 5 }).map(() => ({
        amount: 0,
        term: 10,
        status: 'unavailable',
        quote: { monthly: 0, annual: 0 },
      }))
    : insuranceQuotes

  const slidesPerView = isMobile
    ? 1
    : isTablet && quotes.length >= 2
      ? 2
      : quotes.length >= 3
        ? 3
        : quotes.length

  /*
   * We want to start the carousel on the second quote, if possible, because the
   * second quote is what the user sees on the 1st page (LifeInsuranceIntro.tsx)
   * of the modal.
   */
  const initialSlide = quotes.length - slidesPerView >= slidesPerView ? 1 : 0
  const [carouselIndex, setCarouselIndex] = React.useState(initialSlide)

  useEffect(() => {
    // Auto-select the displayed quote if it is visible and available
    if (slidesPerView === 1 && quotes[carouselIndex]?.amount) {
      handleSelectCoverageAmount(quotes[carouselIndex].amount)
    }
  }, [carouselIndex, handleSelectCoverageAmount, quotes, slidesPerView])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Box
        role="button"
        className="slide-back"
        sx={{
          top: '50%',
          left: '-35px',
          position: 'absolute',
          transform: 'translateY(-50%)',
          display: carouselIndex <= 0 ? 'none' : 'block',
        }}
      >
        <ArrowBackIosNewIcon />
      </Box>

      <Swiper
        modules={[Navigation]}
        initialSlide={initialSlide}
        slidesPerView={slidesPerView}
        spaceBetween={isTablet ? 16 : 10}
        navigation={{ prevEl: '.slide-back', nextEl: '.slide-forward' }}
        onActiveIndexChange={({ activeIndex }) => setCarouselIndex(activeIndex)}
      >
        {React.Children.toArray(
          quotes.map(({ amount, quote: { monthly } }) => (
            <SwiperSlide>
              <QuoteCard
                centerText={isMobile}
                isWorking={isWorking}
                pricePerMonth={monthly}
                coverageAmount={amount}
                isSelected={selectedCoverageAmount === amount}
                onClick={() => {
                  if (selectedCoverageAmount !== amount) {
                    handleSelectCoverageAmount(amount)
                  }
                }}
              />
            </SwiperSlide>
          )),
        )}
      </Swiper>

      <Box
        role="button"
        className="slide-forward"
        sx={{
          top: '50%',
          right: '-35px',
          position: 'absolute',
          transform: 'translateY(-50%)',
          display:
            carouselIndex + slidesPerView >= quotes.length ? 'none' : 'block',
        }}
      >
        <ArrowForwardIosIcon />
      </Box>
    </Box>
  )
}

export default QuoteCardCarousel
