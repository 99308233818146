// checks if there is a trigger on the section and if it's valid

import isTriggered from './isTriggered'

export default ({
  data,
  abTest,
  referrer,
  question,
  isPartner,
  answerStore,
  charityPartner,
}) => {
  const section = data.find((s) => s.id === question.section)
  return (
    !section.trigger ||
    isTriggered(section.trigger, {
      abTest,
      referrer,
      isPartner,
      answerStore,
      charityPartner,
    })
  )
}
