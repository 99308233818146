import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'

import { createLogic } from 'redux-logic'

import commonSelectors from '../../../../../common/ducks/commonSelectors'
import differenceObj from '../../../../../common/utils/differenceObj'
import dashboardSelectors from '../../../../Dashboard/ducks/dashboard/dashboardSelectors'
import documentsSelectors from '../../../../Dashboard/ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../../Dashboard/ducks/payments/paymentsSelectors'
import data from '../../../data'
import {
  cleansedQuestionStack,
  currentQuestionIndex,
  nextTriggeredQuestion,
} from '../../../engine'
import { validate } from '../../../utils/validation/validation'
import questionnaireActions from '../../questionnaire/questionnaireActions'
import questionnaireSelectors from '../../questionnaire/questionnaireSelectors'
import validationActions from '../../validation/validationActions'
import validationSelectors from '../../validation/validationSelectors'
import navigationActions from '../navigationActions'
import navigationSelectors from '../navigationSelectors'
import navigationTypes from '../navigationTypes'

export default createLogic({
  name: 'nextQuestion',
  type: navigationTypes.NEXT_QUESTION,

  // eslint-disable-next-line consistent-return
  validate({ getState, action }, allow, reject) {
    const state = getState()

    const currentQuestion = navigationSelectors.getCurrentQuestion(
      state.questionnaire,
    )

    const { questionFragments: incomingQuestionFragments } = action.payload
    const UNVALIDATED_QUESTIONS = ['sectionReview', 'intro']

    const noValuesInQuestionFragments = Object.values(
      incomingQuestionFragments,
    ).every((value) => _isNil(value))

    /*
     * When a optional, or unvalidate question does NOT have
     * questionFragments, we don't need to validate the questionFragments.
     * Otherwise (below the if statement), questionFragments have to pass
     * validation before moving to process()
     */
    if (
      (currentQuestion.optional ||
        UNVALIDATED_QUESTIONS.includes(currentQuestion.type)) &&
      noValuesInQuestionFragments
    ) {
      return allow(action)
    }

    const answerCache = questionnaireSelectors.getAnswerCache(
      state.questionnaire,
    )

    const isValid = _isEmpty(
      validate(currentQuestion, incomingQuestionFragments, answerCache),
    )

    if (isValid) {
      allow(action)
    } else {
      const displayValidation = validationSelectors.getDisplayValidation(
        state.questionnaire,
      )
      // if validation fails, set the displayValidation flag to true
      reject(!displayValidation && validationActions.displayValidation(true))
    }
  },

  async process({ getState, action }, dispatch, done) {
    const state = getState()

    const questionStack = navigationSelectors.getQuestionStack(
      getState().questionnaire,
    )
    const currentQuestion = navigationSelectors.getCurrentQuestion(
      state.questionnaire,
    )
    const isPartner = dashboardSelectors.getIsPartner(getState().dashboard)
    const accountCreated = questionnaireSelectors.getAccountCreated(
      state.questionnaire,
    )
    const abTest = questionnaireSelectors.getAbTest(getState().questionnaire)
    const answerStoreBeforeUpdate = questionnaireSelectors.getAnswerStore(
      state.questionnaire,
    )

    const { questionFragments: incomingQuestionFragments } = action.payload

    if (differenceObj(answerStoreBeforeUpdate, incomingQuestionFragments)) {
      /*
       * the update action also saves the questionnaire and
       * clears fragments in questionnaireOperations.js
       * */
      dispatch(
        questionnaireActions.updateAnswerStore({
          answerStoreFragment: incomingQuestionFragments,
        }),
      )
    } else {
      // if not updating, we still want to clear fragments out
      dispatch(questionnaireActions.clearQuestionFragments())
    }

    /*
     * this getAnswerStore must be here becauase it uses the
     * updated answerStore from updateAnswerStore above.
     *
     * Do NOT change the 'getState()' below to 'state'. We need to
     * re-fetch the state with getState() to get the most up-to-date
     * answerStore
     */
    const answerStore = questionnaireSelectors.getAnswerStore(
      getState().questionnaire,
    )
    const payments = paymentsSelectors.getPayments(getState().dashboard)
    const charityPartner = commonSelectors.getCharityPartner(getState())
    const documents = documentsSelectors.getDocuments(getState().dashboard)
    const referrer = questionnaireSelectors.getReferrer(
      getState().questionnaire,
    )

    // recursive function to find next triggered question
    const nextQuestion = nextTriggeredQuestion({
      data,
      abTest,
      referrer,
      payments,
      isPartner,
      documents,
      answerStore,
      accountCreated,
      charityPartner,
      theCurrentQuestionIndex: currentQuestionIndex(currentQuestion, data),
    })

    if (nextQuestion) {
      const theCleansedQuestionStack = cleansedQuestionStack({
        data,
        abTest,
        referrer,
        payments,
        isPartner,
        documents,
        answerStore,
        questionStack,
        accountCreated,
        charityPartner,
      })

      /*
       * Update questionStack by cleansing and then adding nextQuestion.
       * This is what changes the question in the app.
       */
      dispatch(
        navigationActions.pushQuestion({
          nextQuestion,
          cleansedQuestionStack: theCleansedQuestionStack,
        }),
      )
    } else {
      // TODO: DO SOMETHING HERE WHEN NO MORE QUESTIONS!
    }

    done()
  },
})
