import React from 'react'

import { Box } from '@mui/material'

import { CONSTANTS, data, Timezone } from '@epilogue/common'
// eslint-disable-next-line import/no-duplicates
import { differenceInMinutes, format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { enCA, frCA } from 'date-fns/locale'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../../common/components/atoms/Text'
import {
  SelectedTimeslot,
  Timeslot,
} from '../../../../../../../../../common/components/DatePicker/datePicker.types'
import TimePicker from '../../../../../../../../../common/components/TimePicker'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../../common/styles/muiTheme'

interface Props {
  timezone: Timezone
  selectedDate: Date
  availableTimeslots: Timeslot[]
  onSelect: (selectedTimeslot: SelectedTimeslot) => void
}

const BookWitnessingTimePickerView = ({
  onSelect,
  timezone,
  selectedDate,
  availableTimeslots,
}: Props) => {
  const timezoneLabel =
    data.timezones.find(({ code }) => code === timezone)?.label || timezone

  const { t } = useTranslation()

  return (
    <Box data-testid="book-witnessing-time-picker-view">
      <Heading align="center" variant="h3">
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingTimePickerView.label',
        )}
      </Heading>

      <Text
        size="lg"
        align="center"
        sx={{ mt: '0.85rem' }}
        color={themeColorTypes.BRAND}
        colorVariant={themeColorVariants.LIGHTER}
      >
        {format(selectedDate, 'EEEE, MMMM d, yyyy', {
          locale:
            i18n.language === CONSTANTS.languageTypes.FR_CA.id ? frCA : enCA,
        })}
      </Text>

      <Text
        align="center"
        color={themeColorTypes.BRAND}
        sx={{ mt: '0.75rem', mb: '0.6rem' }}
        colorVariant={themeColorVariants.LIGHTER}
      >
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingTimePickerView.duration',
        )}{' '}
        {differenceInMinutes(
          new Date(availableTimeslots[0].end),
          new Date(availableTimeslots[0].start),
        )}{' '}
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingTimePickerView.minutes',
        )}
      </Text>

      <Text
        align="center"
        sx={{ mb: '1.2rem' }}
        color={themeColorTypes.BRAND}
        colorVariant={themeColorVariants.LIGHTER}
      >
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingTimePickerView.timezone',
        )}{' '}
        {`${timezoneLabel} (${availableTimeslots[0].start.slice(-6)}) `}
      </Text>

      <TimePicker
        onSelect={onSelect}
        timezone={timezone}
        timeslots={availableTimeslots}
      />
    </Box>
  )
}

export default BookWitnessingTimePickerView
