export default {
  animate: { scale: 2 },
  transition: {
    delay: 0.22,
    type: 'spring',
    mass: 0.4,
    damping: 6,
    velocity: 5,
    stiffness: 115,
  },
}
