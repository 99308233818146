import React, { useState } from 'react'

import { alpha, Box, Fade, Slider } from '@mui/material'

import { utils } from '@epilogue/common'

import { ReactComponent as ErrorIcon } from '../../../../../../common/assets/images/bubble-error.svg'
import Text from '../../../../../../common/components/atoms/Text'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'

export const calculateFees = (estateValue) => {
  let remainingEstateValue = 0
  let calculatedFees = 0

  if (estateValue <= 3000000) {
    calculatedFees += estateValue * 0.0375 // 3.75%
  } else {
    calculatedFees = 3000000 * 0.0375
    remainingEstateValue = estateValue - 3000000
  }

  if (remainingEstateValue <= 2000000) {
    calculatedFees += remainingEstateValue * 0.0125 // 1.25%
    remainingEstateValue = 0
  } else {
    calculatedFees += 2000000 * 0.0125
    remainingEstateValue -= 2000000
  }

  if (remainingEstateValue <= 5000000) {
    calculatedFees += remainingEstateValue * 0.0075 // 0.75%
    remainingEstateValue = 0
  } else {
    calculatedFees += 5000000 * 0.0075
    remainingEstateValue -= 5000000
  }

  if (remainingEstateValue <= 10000000) {
    calculatedFees += remainingEstateValue * 0.0025 // 0.25%
    remainingEstateValue = 0
  } else {
    calculatedFees += 10000000 * 0.0025
    remainingEstateValue -= 10000000
  }

  if (remainingEstateValue > 0) {
    calculatedFees += remainingEstateValue * 0.001 // 0.10%
  }

  if (calculatedFees < 25000) {
    return 25000
  }

  return calculatedFees
}

const invaluableEstateBreakpoint = 750000

const RtCalculator = () => {
  const [value, setValue] = useState(1000000)

  return (
    <Box data-testid="royal-trust-calculator">
      <Box
        sx={{
          rowGap: '1.25rem',
          p: '1.25rem',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: (theme) => theme.shape.borderRadius,
          backgroundColor: (theme) =>
            alpha(
              theme.palette[themeColorTypes.ACCENT_2][
                themeColorVariants.LIGHTER
              ],
              0.3,
            ),
        }}
      >
        <Text size="lg" variant="display">
          Fee Calculator
        </Text>

        <Text size="md">
          Estate Value:{' '}
          <Text
            size="md"
            weight="bold"
            display="inline"
            elementType="span"
            data-testid="estate-value"
          >
            {utils.toDollar(value.toString())}
          </Text>
        </Text>

        <Slider
          min={500000}
          step={10000}
          max={5000000}
          value={Number.isNaN(value) ? 0 : value}
          track={false}
          onChange={(_, newValue) => {
            setValue(newValue)
          }}
          sx={{
            '& .MuiSlider-rail': {
              height: '0.5rem',
              backgroundColor: (theme) =>
                theme.palette[themeColorTypes.ACCENT_2][
                  themeColorVariants.LIGHT
                ],
            },
          }}
        />

        <Box
          sx={{
            pt: '1.5rem',
            borderTop: (theme) =>
              `solid 1px ${
                theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.MAIN]
              }`,
          }}
        >
          <Text size="md">
            *Estimated Fees:{' '}
            <Text
              size="md"
              weight="bold"
              display="inline"
              elementType="span"
              data-testid="estimated-fees"
            >
              {utils.toDollar(calculateFees(value).toString())}
            </Text>
          </Text>
        </Box>

        <Fade in={value <= invaluableEstateBreakpoint} unmountOnExit>
          <Box
            sx={{
              display: 'flex',
              columnGap: '0.5rem',
            }}
          >
            <Box
              sx={{
                '& svg path': {
                  fill: (theme) =>
                    theme.palette[themeColorTypes.BRAND][
                      themeColorVariants.MAIN
                    ],
                },
              }}
            >
              <ErrorIcon />
            </Box>

            <Text
              size="xs"
              sx={{ lineHeight: '1.5', mt: '-0.25rem' }}
              data-testid="low-value-estate-warning"
            >
              For smaller estates, the fees associated with naming a
              professional trust company may outweigh the benefits.
            </Text>
          </Box>
        </Fade>
      </Box>

      <Text size="xs" sx={{ fontStyle: 'italic', my: '1.25rem' }}>
        *Estimated fees are based only on the estate value provided and RBC
        Royal Trust&apos;s current fee schedule. Discounts may apply and fees
        are subject to change. This fee calculator is not associated with RBC,
        RBC Royal Trust, or their affiliates.
      </Text>
    </Box>
  )
}

export default RtCalculator
