import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Alert, Slide, Snackbar } from '@mui/material'

import commonActions from '../../../ducks/commonActions'
import commonSelectors from '../../../ducks/commonSelectors'
import amplitude from '../../../lib/amplitude'
import { themeColorTypes } from '../../../styles/muiTheme'
import Text from '../../atoms/Text'

const Transition = (props) => (
  <Slide {...props} timeout={{ enter: 400, exit: 250 }} />
)

const GlobalError = ({ errorMsg, dispatchClearErrorMessage }) => {
  const [isOpen, setIsOpen] = useState(!!errorMsg)

  useEffect(() => setIsOpen(!!errorMsg), [errorMsg])

  useEffect(() => {
    if (errorMsg) amplitude.sendEvent('TriggeredGlobalError', { errorMsg })
  }, [errorMsg])

  return (
    <Snackbar
      open={isOpen}
      TransitionComponent={Transition}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        width: {
          sm: '90%',
          md: '95%',
          xl: '100%',
        },
        maxWidth: '48rem',
      }}
      TransitionProps={{
        onExited: dispatchClearErrorMessage,
      }}
    >
      <Alert
        icon={false}
        severity="error"
        variant="filled"
        data-testid="global-error"
        onClose={() => setIsOpen(false)}
        sx={{
          width: '100%',
          borderRadius: (theme) => theme.shape.borderRadius,
          '& > .MuiAlert-message': {
            flex: 1,
          },
          '& > .MuiAlert-action': {
            pl: '0.2rem',
            pt: '2px',
          },
        }}
      >
        {errorMsg && (
          <Text
            sx={{
              mx: {
                min: 0,
                md: '1rem',
              },
            }}
            size="sm"
            align="center"
            color={themeColorTypes.WHITE}
          >
            {errorMsg}
          </Text>
        )}
      </Alert>
    </Snackbar>
  )
}

GlobalError.propTypes = {
  errorMsg: PropTypes.string,
  dispatchClearErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  errorMsg: commonSelectors.getGlobalErrorMessage(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchClearErrorMessage: () =>
    dispatch(commonActions.setGlobalErrorMessage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalError)
