/*
 *
 * This component is used in both SectionReview.js and SummaryCard.js
 *
 */
import React from 'react'
import PropTypes from 'prop-types'

import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'

import { Box, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useTranslation } from 'react-i18next'

import useInjectDynamic from '../../../hooks/useInjectDynamic'
import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import formattedAnswerStoreData from '../../../utils/formattedAnswerStoreData/formattedAnswerStoreData'
import Text from '../../atoms/Text'

const useStyles = makeStyles({
  fieldWrapper: { wordWrap: 'break-word' },
  extraLineHeight: { lineHeight: '1.375rem' },
})

const CardField = ({ field, answerStore, optionalQuestion = undefined }) => {
  const { resolveValue } = useInjectDynamic()

  const isAnswered = !_isNil(answerStore[field.name])
  const isOptional = !!(optionalQuestion || field.optional)

  const isChildOrStepAnswered =
    (!_isEmpty(answerStore.children) || !_isEmpty(answerStore.stepChildren)) &&
    (field.name === 'children' || field.name === 'stepChildren')

  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  /*
   * Can return true in 3 scenarios:
   *
   * 1. If the field is NOT 'children' or 'stepChildren'.
   *
   * 2. If the field IS 'children' or 'stepChildren', but one
   * of 'children' or 'stepChildren' and the focused field has a
   * non-empty (filled) array.
   *
   * 3. If the field IS 'children' or 'stepChildren', but neither 'children'
   * or 'stepChildren' is answered.
   *
   */
  const isNotEmptyChildrenOrStepChildren =
    (field.name !== 'children' && field.name !== 'stepChildren') ||
    ((field.name === 'children' || field.name === 'stepChildren') &&
      !isChildOrStepAnswered) ||
    (isChildOrStepAnswered && !_isEmpty(answerStore[field.name]))

  const { t } = useTranslation()

  return (
    <>
      {isNotEmptyChildrenOrStepChildren && (
        <Box py="0.55rem">
          <Grid
            container
            spacing={isDesktop ? 4 : 0}
            justifyContent="space-between"
            className={classes.fieldWrapper}
            direction={isDesktop ? 'row' : 'column'}
          >
            <Grid item xs={12} md={5}>
              <Box pb={isDesktop ? '0' : '0.13rem'}>
                <Text
                  className={classes.extraLineHeight}
                  color={
                    isOptional && !isAnswered
                      ? themeColorTypes.GREY
                      : themeColorTypes.BRAND
                  }
                  colorVariant={
                    isOptional && !isAnswered
                      ? themeColorVariants.LIGHT
                      : themeColorTypes.MAIN
                  }
                  size="xs"
                  weight="bold"
                >
                  {resolveValue(field.reviewLabel)}
                </Text>
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              {isAnswered ? (
                /*
                 * If a field has an immutableValue property, that value always
                 * gets precedent to be displayed.
                 */
                <Text size="xs">
                  {field.immutableValue
                    ? resolveValue(field.immutableValue)
                    : formattedAnswerStoreData({
                        field,
                        answerStore,
                        resolveValue,
                      })}
                </Text>
              ) : /*
               * When user has either children OR stepChildren, fill
               * in the empty value with N/A so that it's not empty.
               * If both are unanswered, it'll be passed to the [ awaiting answer ]
               * condition
               */
              isChildOrStepAnswered ? (
                <Text size="xs">{t('summary:components.CardField.na')}</Text>
              ) : isOptional ? (
                <Text
                  size="xs"
                  color={themeColorTypes.GREY}
                  colorVariant={themeColorVariants.LIGHT}
                  data-testid="summary-optional-value"
                >
                  [ {t('summary:components.CardField.optional')} ]
                </Text>
              ) : (
                <Text
                  size="xs"
                  color={themeColorTypes.ACCENT_1}
                  colorVariant={themeColorVariants.LIGHT}
                  data-testid="summary-awaiting-answer"
                >
                  [ {t('summary:components.CardField.awaitingAnswer')} ]
                </Text>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

CardField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    optional: PropTypes.bool,
    reviewLabel: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    immutableValue: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
  }).isRequired,
  answerStore: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
    stepChildren: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  optionalQuestion: PropTypes.bool,
}

export default CardField
