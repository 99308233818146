import _isEmpty from 'lodash/isEmpty'

export default ({
  validators,
  forbiddenKeys,
  fragments = [],
  question: { forbiddenTerms = [] },
}) => {
  if (_isEmpty(forbiddenTerms) || _isEmpty(fragments)) {
    return false
  }

  return fragments.some((f) =>
    forbiddenKeys.some((fk) => {
      /*
       * For some forbidden terms, we just want to warn the user that
       * they're doing something dangerous, but still let them proceed.
       *
       * For other forbidden terms, we prevent the user from continuing on.
       *
       * If the validator returns an object with the property of 'reject',
       * it means there is at least one rejectable term, and we return the
       * truthy or falsy based on the existence of the 'reject' property
       */
      const { reject } = validators.forbiddenTerms(f[fk], forbiddenTerms)
      return !!reject
    }),
  )
}
