import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { CONSTANTS } from '@epilogue/common'
import { Meter, Text } from 'grommet'

import useProvinceDictionary from '../../../../../common/hooks/useProvinceDictionary'
import navigationSelectors from '../../../ducks/navigation/navigationSelectors'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'

const SectionProgress = ({
  value,
  answerStore = {},
  currentSection,
  altProgressText,
}) => {
  const { province } = answerStore
  const { provinceLanguageTranslate } = useProvinceDictionary()

  const tKey = Object.values(CONSTANTS.questionnaireSectionTypes).find(
    ({ id }) => id === currentSection,
  )?.tKey

  return (
    <>
      {altProgressText === undefined ? (
        <div className="flex flex-col items-center">
          <div className="mb-1.5">
            <Text size="small" data-testid="section-label">
              {provinceLanguageTranslate(tKey, currentSection, province)}
            </Text>
          </div>
          <Meter
            type="bar"
            background="grey-2"
            size="small"
            round
            thickness="xsmall"
            values={[{ value }]}
          />
        </div>
      ) : (
        <Text size="small">{altProgressText}</Text>
      )}
    </>
  )
}

SectionProgress.propTypes = {
  value: PropTypes.number,
  currentSection: PropTypes.string,
  altProgressText: PropTypes.string,
  answerStore: PropTypes.shape({ province: PropTypes.string }),
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  currentSection: navigationSelectors.getCurrentSection(state.questionnaire),
})

export default connect(mapStateToProps)(SectionProgress)
