import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Qu'est-ce qu'un Régime enregistré d'épargne-retraite (REER) ?

Un Régime enregistré d'épargne-retraite (REER) est un type spécial de compte d'investissement conçu pour aider les Canadiens à épargner en vue de leur retraite.

#### Économiser des impôts avec un REER

Contribuer à un REER aide les gens à économiser des impôts de deux manières significatives.

1. Les cotisations à un REER sont déductibles d'impôt. Cela signifie que les cotisations au REER peuvent réellement réduire le montant d'impôt que vous devez payer pour l'année !
2. Comme les investissements dans votre REER croissent et génèrent des revenus, vous ne payez aucun impôt sur eux. Cela vous permet de conserver plus d'argent investi, et les actifs dans votre REER peuvent croître beaucoup plus rapidement !

Les impôts que vous économisez grâce aux cotisations et à la croissance des investissements dans un REER sont payés éventuellement, mais uniquement au fur et à mesure que vous retirez des fonds du compte. Dans la plupart des cas, les retraits ne commencent qu'après de nombreuses années, une fois que quelqu'un est à la retraite et a un taux d'imposition plus faible.

#### Cotisations et retraits d'un REER

Tout le monde peut faire des cotisations à son REER chaque année, jusqu'à leur limite de cotisation personnelle. La limite de cotisation de chaque personne est basée sur le montant du revenu qu'elle a gagné l'année précédente, mais elle ne peut pas dépasser 30 780 $ (le maximum pour 2023). La place de cotisation inutilisée peut s'accumuler chaque année et augmenter le montant de la place de cotisation disponible les années suivantes.

La plupart des retraits des REER sont imposables. Cependant, jusqu'à 35 000 $ peuvent être retirés sans impôt pour acheter une maison dans le cadre du Régime d'accession à la propriété (RAP) du Canada, et jusqu'à 20 000 $ peuvent être retirés sans impôt pour les études dans le cadre du Régime d'épargne-études (REE) permanent. Ces montants doivent être remboursés avec le temps.

#### Commencer à investir dans un REER

Pour ouvrir un REER, contactez votre institution financière et demandez à parler à un conseiller concernant l'ouverture d'un REER.
    
`
    : `
#### What is a Registered Retirement Savings Plan?

A Registered Retirement Savings Plan (RRSP) is a special type of investment account designed to help Canadians save for their retirement.

#### Saving Taxes with an RRSP

Contributing to an RRSP helps people save taxes in two significant ways.

1. Contributions to an RRSP are tax deductible. This means that RRSP contributions can actually lower the amount of tax you have to pay in the year!
2. As the investments in your RRSP grow and earn income, you do not pay any tax on them. This lets you keep more money invested, and the assets in your RRSP can grow much faster!

Taxes you save from contributing to and growing investments an RRSP are paid eventually, but only as you with draw funds from the account. In most cases, withdrawals only begin after many years, once someone is retired and has a lower tax rate.

#### RRSP Contributions & Withdrawals

Everyone can make contributions to their RRSP each year, up to their personal contribution limit. Each person's contribution limit is based on the amount of income they earned in the prior year but it cannot exceed $30,780 (the maximum for 2023). Unused contribution room can accumulate each year and increase the amount of contribution room available in future years.

Most withdrawals from RRSPs are taxable. However, up to $35,000 can be withdrawn tax-free to purchase a home under Canada's Home Buyer's Plan (HBP), and up to $20,000 can be withdrawn tax-free for education under the Lifelong Learning Plan (LPP). These amounts need to be repaid over time.

#### Start Investing in a RRSP

To open an RRSP contact your financial institution and ask to speak to an advisor about opening an RRSP.
`
