import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import YoutubePlayer from '../../../../../../common/components/molecules/YoutubePlayer'
import SidebarCaption from './SidebarCaption'

const SidebarYoutube = ({ youtubeId, caption }) => (
  <Box sx={{ my: '1rem' }}>
    <YoutubePlayer youtubeId={youtubeId} />
    {caption && <SidebarCaption text={caption} />}
  </Box>
)

SidebarYoutube.propTypes = {
  youtubeId: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
}

export default SidebarYoutube
