export default [
  {
    value: 'continue',
    label: { tKey: 'common:continue' },
    reviewLabel: { tKey: 'common:yes' },
  },
  {
    value: 'no',
    label: { tKey: 'common:doesNotLookRightForMe' },
    reviewLabel: { tKey: 'common:no' },
  },
]
