import _mapValues from 'lodash/mapValues'

import toCapitalCase from '../../../../../common/formatting/toCapitalCase'

export const CAPITAL_CASE_FIELDS = [
  'cityTown',
  'firstName',
  'middleName',
  'lastName',
  'partnerFirstName',
  'partnerMiddleName',
  'partnerLastName',
]

export default (answerStoreFragment) =>
  _mapValues(answerStoreFragment, (val, key) =>
    CAPITAL_CASE_FIELDS.includes(key) && typeof val === 'string'
      ? toCapitalCase(val)
      : val,
  )
