import questionnaireTypes from './questionnaireTypes'

export default {
  beforeQuestionnaire: () => ({
    type: questionnaireTypes.BEFORE_QUESTIONNAIRE,
  }),

  updateAnswerStore: ({
    answerStoreFragment,
    fromBonusDocQuestionnaire = false,
  }) => ({
    type: questionnaireTypes.UPDATE_ANSWER_STORE,
    payload: { answerStoreFragment, fromBonusDocQuestionnaire },
  }),

  updateQuestionnaire: ({
    allIncomingFieldsPreventAnswerStoreUpdatedSinceGenerate,
  }) => ({
    type: questionnaireTypes.UPDATE_QUESTIONNAIRE,
    payload: { allIncomingFieldsPreventAnswerStoreUpdatedSinceGenerate },
  }),

  updateQuestionnairesStarted: ({ world }) => ({
    type: questionnaireTypes.UPDATE_QUESTIONNAIRES_STARTED,
    payload: { world },
  }),

  setQuestionnaireProperties: (properties) => ({
    type: questionnaireTypes.SET_QUESTIONNAIRE_PROPERTIES,
    payload: properties,
  }),

  setAnswerStoreUpdatedSinceGenerate: ({ bool }) => ({
    type: questionnaireTypes.SET_ANSWER_STORE_UPDATED_SINCE_GENERATE,
    payload: { bool },
  }),

  startCreateAccount: ({ email, password, mailingList }) => ({
    type: questionnaireTypes.START_CREATE_ACCOUNT,
    payload: { password, email, mailingList },
  }),

  createAccountSuccess: ({ mailingList, email }) => ({
    type: questionnaireTypes.CREATE_ACCOUNT_SUCCESS,
    payload: { mailingList, email },
  }),

  oauthSuccess: ({ email, mailingList }) => ({
    type: questionnaireTypes.OAUTH_SUCCESS,
    payload: { email, mailingList },
  }),

  createQuestionnaire: () => ({
    type: questionnaireTypes.CREATE_QUESTIONNAIRE,
  }),

  setQuestionFragments: ({ fragments }) => ({
    type: questionnaireTypes.SET_QUESTION_FRAGMENTS,
    payload: { fragments },
  }),

  clearQuestionFragments: () => ({
    type: questionnaireTypes.CLEAR_QUESTION_FRAGMENTS,
  }),

  setQuestionnaireValid: (isValid) => ({
    type: questionnaireTypes.SET_QUESTIONNAIRE_VALID,
    payload: isValid,
  }),

  setNameStore: (nameStore) => ({
    type: questionnaireTypes.SET_NAME_STORE,
    payload: { nameStore },
  }),
}
