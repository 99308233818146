import React from 'react'
import PropTypes from 'prop-types'

import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'
import cn from 'classnames'
import { Heading, Paragraph, Text } from 'grommet'
import { Checkmark } from 'grommet-icons'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledCheckmark = styled.div`
  padding: 5px;
`
const StyledCheckmarkSVG = styled(Checkmark)`
  width: 10.5px;
  height: 10.5px;
`
const AboutLawyer = ({ lawyer }) => {
  const { highlights, bio } = lawyer

  const { t } = useTranslation()

  return (
    <>
      {/* Must at least have English bio */}
      {bio[CONSTANTS.languageTypes.EN_CA.id] && (
        <>
          <div className={cn('mb-4 text-center', 'md:text-left')}>
            <Heading level={3} size="small">
              {t('common:about')}
            </Heading>
          </div>

          <div className="mb-8">
            <Paragraph size="smedium">
              {/*
               * If bio exists in current selected language, use it.
               * If not, fallback to English bio.
               */}
              {bio[i18n.language] || bio[CONSTANTS.languageTypes.EN_CA.id]}
            </Paragraph>
          </div>
        </>
      )}

      <ul>
        {!_isEmpty(highlights) &&
          // Every highlight must at least have an English version
          highlights.every((h) => h[CONSTANTS.languageTypes.EN_CA.id]) &&
          React.Children.toArray(
            highlights.map((highlight) => (
              <li className="flex items-center mb-2">
                <StyledCheckmark className="bg-accent-1 rounded-full flex justify-center items-center mr-3">
                  <StyledCheckmarkSVG color="white" />
                </StyledCheckmark>
                <Text size="small">
                  {/*
                   * If highlight exists in current selected language, use it.
                   * If not, fallback to English.
                   */}
                  {highlight[i18n.language] ||
                    highlight[CONSTANTS.languageTypes.EN_CA.id]}
                </Text>
              </li>
            )),
          )}
      </ul>
    </>
  )
}

AboutLawyer.propTypes = {
  lawyer: PropTypes.shape({
    bio: PropTypes.shape({
      ...Object.values(CONSTANTS.languageTypes).reduce((acc, { id }) => {
        acc[id] = PropTypes.string
        return acc
      }, {}),
    }),
    highlights: PropTypes.arrayOf(
      PropTypes.shape({
        ...Object.values(CONSTANTS.languageTypes).reduce((acc, { id }) => {
          acc[id] = PropTypes.string
          return acc
        }, {}),
      }),
    ),
  }).isRequired,
}

export default AboutLawyer
