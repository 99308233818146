import _get from 'lodash/get'
import _upperFirst from 'lodash/upperFirst'

import { CONSTANTS } from '@epilogue/common'

import {
  ONTARIO_TERMS,
  provinceDictionary,
} from '../../../../common/utils/provinceDictionary'
import { ageOfMajority, PROVINCES } from '../../../../common/utils/provinces'
import appointeeTypes from '../appointeeTypes'
import attorneyOutOfProvinceSidebar from '../sidebarContent/attorneyOutOfProvinceSidebar'
import tKeyRelationshipOptions from '../utils/tKeyRelationshipOptions'

const adultSameProvincePropertyPoa = ({ answerStore }) => {
  const { province } = answerStore

  if (province === CONSTANTS.provinces.SASKATCHEWAN) {
    return {
      tKey: 'questionnaire.propertyPoa:common.adultSameProvincePropertyPoa.saskatchewan',
      options: { ageOfMajority: ageOfMajority(province) },
    }
  }

  if (province === CONSTANTS.provinces.NEW_BRUNSWICK) {
    return {
      tKey: 'questionnaire.propertyPoa:common.adultSameProvincePropertyPoa.newBrunswick',
      options: { ageOfMajority: ageOfMajority(province) },
    }
  }

  const translatedProvince = _get(
    Object.values(PROVINCES).find(({ value }) => value === province),
    'tKey',
    province,
  )

  return {
    tKey: 'questionnaire.propertyPoa:common.adultSameProvincePropertyPoa.default',
    options: {
      ageOfMajority: ageOfMajority(province),
      province: translatedProvince,
    },
  }
}

const rbcForbiddenTerms = [
  {
    allow: false,
    terms: ['rbc'],
    errMsg: { tKey: 'common:rbcForbiddenTermMessage' },
  },
  {
    allow: false,
    terms: ['royal trust', 'royal bank'],
    errMsg:
      'RBC Royal Trust cannot accept this type of appointment. You must appoint an individual in this role.',
  },
  {
    allow: false,
    terms: ['banque royale', 'trust royal'],
    errMsg:
      'RBC Trust Royal ne peut pas accepter ce type de nomination. Vous devez nommer un particulier à ce poste.',
  },
]

const section = CONSTANTS.questionnaireSectionTypes.PROPERTY_POA.id

export default {
  id: section,
  trigger: { product: 'willPoa' },

  description: ({ answerStore: { province } }) =>
    province === CONSTANTS.provinces.SASKATCHEWAN
      ? { tKey: 'questionnaire.propertyPoa:description.saskatchewan' }
      : { tKey: 'questionnaire.propertyPoa:description.default' },

  questions: [
    {
      id: 1578413562,
      pathname: 'poa-intro',
      type: 'info',
      section,
      altProgressText: '',
      highlightPillText: '',

      label: ({ answerStore: { province } }) => ({
        tKey: 'questionnaire.propertyPoa:poa-intro.label',
        options: {
          term: provinceDictionary(ONTARIO_TERMS.POWER_OF_ATTORNEY, province),
        },
      }),

      label2: ({ answerStore: { province } }) =>
        province === CONSTANTS.provinces.ONTARIO
          ? { tKey: 'questionnaire.propertyPoa:poa-intro.label2' }
          : '',

      subLabel: ({ answerStore: { province } }) => {
        if (province === CONSTANTS.provinces.ONTARIO) {
          return { tKey: 'questionnaire.propertyPoa:poa-intro.subLabel.case1' }
        }

        return { tKey: 'questionnaire.propertyPoa:poa-intro.subLabel.case2' }
      },
    },

    {
      id: 1572385723,
      pathname: 'intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.propertyPoa:intro.highlightPillText',
      },

      label: ({ answerStore }) => {
        const { province } = answerStore
        const ontarioTerm = ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PROPERTY
        return provinceDictionary(ontarioTerm, province)
      },

      subLabel: ({ answerStore: { province } }) =>
        province === CONSTANTS.provinces.ONTARIO
          ? {
              tKey: 'questionnaire.propertyPoa:intro.subLabel.ontario',
            }
          : province === CONSTANTS.provinces.SASKATCHEWAN
            ? {
                tKey: 'questionnaire.propertyPoa:intro.subLabel.saskatchewan',
              }
            : {
                tKey: 'questionnaire.propertyPoa:intro.subLabel.default',
              },
    },

    {
      id: 1572386100,
      pathname: 'partner-property-poa',
      section,

      label: ({
        answerStore: { province, partnerFirstName, partnerLastName },
      }) => ({
        tKey: `questionnaire.propertyPoa:partner-property-poa.label.${province === CONSTANTS.provinces.SASKATCHEWAN ? 'saskatchewan' : 'default'}`,
        options: { partnerFirstName, partnerLastName },
      }),

      subLabel: ({ answerStore: { province, partnerFirstName } }) =>
        province !== CONSTANTS.provinces.MANITOBA
          ? {
              tKey: 'questionnaire.propertyPoa:partner-property-poa.subLabel.notManitoba',
              options: { partnerFirstName },
            }
          : '',

      fields: [
        {
          type: 'quickRadio',
          name: 'partnerAsPropertyPoa',

          reviewLabel: ({
            answerStore: { province, partnerFirstName, partnerLastName },
          }) => ({
            tKey: 'questionnaire.propertyPoa:partner-property-poa.fields.partnerAsPropertyPoa.reviewLabel',
            options: {
              partnerFirstName,
              partnerLastName,
              term: provinceDictionary(
                ONTARIO_TERMS.ATTORNEY_FOR_PROPERTY,
                province,
              ),
            },
          }),

          appointeeType: appointeeTypes.PARTNER_PROPERTY_POA,
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
        ],
      },
    },

    {
      id: 1572386303,
      pathname: 'partner-co-property-poa',
      section,
      optional: true,

      label: ({
        answerStore: { province, partnerFirstName, partnerLastName },
      }) => ({
        tKey: `questionnaire.propertyPoa:partner-co-property-poa.label.${province === CONSTANTS.provinces.SASKATCHEWAN ? 'saskatchewan' : 'default'}`,
        options: { partnerFirstName, partnerLastName },
      }),

      label2: {
        tKey: 'questionnaire.propertyPoa:partner-co-property-poa.label2',
      },

      subLabel: adultSameProvincePropertyPoa,

      sidebar: attorneyOutOfProvinceSidebar,

      fields: [
        {
          name: 'partnerCoPropertyPoaName',
          type: 'text',
          placeholder: { tKey: 'common:fullName' },
          reviewLabel: ({ answerStore }) => {
            const { province } = answerStore
            return {
              tKey: 'questionnaire.propertyPoa:partner-co-property-poa.fields.partnerCoPropertyPoaName.reviewLabel',
              options: {
                term: provinceDictionary(
                  ONTARIO_TERMS.ATTORNEY_FOR_PROPERTY,
                  province,
                ),
              },
            }
          },
          appointeeType: appointeeTypes.CO_PROPERTY_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'partnerCoPropertyPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { province: { $exists: true } },
          { province: { $ne: CONSTANTS.provinces.MANITOBA } },
          { partnerAsPropertyPoa: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
        ],
      },
    },

    /*
     * There are two partner-alt-property-poa questions below.
     * One is for users from Manitoba and the other is for all other provinces.
     * Because this question is required for users from Manitoba and optional
     * for all other provinces, it was decided that two questions should be
     * created, but only one gets served to for any user, of course.
     */

    // partner-alt-property-poa question for all NON-Manitobans
    {
      id: 1572386558,
      pathname: 'partner-alt-property-poa',
      section,
      optional: true,

      label: ({
        answerStore: {
          partnerFirstName,
          partnerLastName,
          partnerCoPropertyPoaName,
        },
      }) => ({
        tKey: `questionnaire.propertyPoa:partner-alt-property-poa.label.${partnerCoPropertyPoaName ? 'case1' : 'case2'}`,
        options: {
          partnerFirstName,
          partnerLastName,
          partnerCoPropertyPoaName,
        },
      }),

      label2: {
        tKey: 'questionnaire.propertyPoa:partner-alt-property-poa.label2',
      },

      subLabel: adultSameProvincePropertyPoa,

      sidebar: attorneyOutOfProvinceSidebar,

      fields: [
        {
          name: 'altPartnerPropertyPoaName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.propertyPoa:partner-alt-property-poa.fields.altPartnerPropertyPoaName.placeholder',
          },

          reviewLabel: ({ answerStore }) => {
            const { province } = answerStore
            return {
              tKey: 'questionnaire.propertyPoa:partner-alt-property-poa.fields.altPartnerPropertyPoaName.reviewLabel',
              options: {
                term: provinceDictionary(
                  ONTARIO_TERMS.ATTORNEY_FOR_PROPERTY,
                  province,
                ),
              },
            }
          },

          appointeeType: appointeeTypes.ALTERNATE_PROPERTY_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'altPartnerPropertyPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { province: { $exists: true } },
          { province: { $ne: CONSTANTS.provinces.MANITOBA } },
          { partnerAsPropertyPoa: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
        ],
      },
    },

    // partner-alt-property-poa question for Manitobans only
    {
      id: 1610480307,
      pathname: 'partner-alt-property-poa-manitoba',
      section,
      optional: false,

      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.propertyPoa:partner-alt-property-poa-manitoba.label',
        options: { partnerFirstName },
      }),

      label2: ({ answerStore }) => {
        const { partnerFirstName, partnerGender } = answerStore

        const partnerGenderKey =
          partnerGender === 'male'
            ? 'male'
            : partnerGender === 'female'
              ? 'female'
              : 'other'

        return {
          tKey: `questionnaire.propertyPoa:partner-alt-property-poa-manitoba.label2.${partnerGenderKey}`,
          options: { partnerFirstName },
        }
      },

      subLabel: adultSameProvincePropertyPoa,
      sidebar: attorneyOutOfProvinceSidebar,
      fields: [
        {
          name: 'altPartnerPropertyPoaName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.propertyPoa:partner-alt-property-poa-manitoba.fields.altPartnerPropertyPoaName.placeholder',
          },

          reviewLabel: ({ answerStore }) => {
            const { province } = answerStore
            return {
              tKey: 'questionnaire.propertyPoa:partner-alt-property-poa-manitoba.fields.altPartnerPropertyPoaName.reviewLabel',
              options: {
                term: provinceDictionary(
                  ONTARIO_TERMS.ATTORNEY_FOR_PROPERTY,
                  province,
                ),
              },
            }
          },

          appointeeType: appointeeTypes.ALTERNATE_PROPERTY_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'altPartnerPropertyPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { province: CONSTANTS.provinces.MANITOBA },
          { partnerAsPropertyPoa: 'yes' },
          { partnerGender: { $exists: true } },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
        ],
      },
    },

    {
      id: 1572386668,
      pathname: 'non-partner-property-poa',
      section,

      label: ({ answerStore: { province } }) => ({
        tKey: `questionnaire.propertyPoa:non-partner-property-poa.label.${province === CONSTANTS.provinces.SASKATCHEWAN ? 'saskatchewan' : 'default'}`,
      }),

      subLabel: adultSameProvincePropertyPoa,

      sidebar: attorneyOutOfProvinceSidebar,

      interceptModal: [
        {
          label: ({ answerStore: { province } }) => ({
            tKey: 'questionnaire.propertyPoa:non-partner-property-poa.interceptModal.item1.label',
            options: {
              term: provinceDictionary(
                ONTARIO_TERMS.ATTORNEY_FOR_PROPERTY,
                province,
              ).toLowerCase(),
            },
          }),

          subLabel: {
            tKey: 'questionnaire.propertyPoa:non-partner-property-poa.interceptModal.item1.subLabel',
          },
          primaryButtonLabel: {
            tKey: 'questionnaire.propertyPoa:non-partner-property-poa.interceptModal.item1.primaryButtonLabel',
          },
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.TO_QUESTION,
            questionId: 1572386100,
          },
          secondaryButtonLabel: { tKey: 'common:close' },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
          },

          trigger: {
            $and: [
              {
                nonPartnerPropertyPoaRelationship:
                  CONSTANTS.relationshipValues.MY_PARTNER,
              },
              { partnerAsPropertyPoa: 'no' },
            ],
          },
        },
      ],

      fields: [
        {
          name: 'nonPartnerPropertyPoaName',
          type: 'text',
          optional: false,
          placeholder: { tKey: 'common:fullName' },
          reviewLabel: ({ answerStore: { province } }) =>
            _upperFirst(
              provinceDictionary(ONTARIO_TERMS.ATTORNEY_FOR_PROPERTY, province),
            ),
          appointeeType: appointeeTypes.PROPERTY_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'nonPartnerPropertyPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { province: { $exists: true } },
          { $or: [{ partnerAsPropertyPoa: 'no' }, { hasPartner: 'no' }] },
        ],
      },
    },

    {
      id: 1572386861,
      pathname: 'non-partner-alt-property-poa',
      section,
      optional: true,

      label: ({ answerStore: { nonPartnerPropertyPoaName } }) => ({
        tKey: 'questionnaire.propertyPoa:non-partner-alt-property-poa.label',
        options: { nonPartnerPropertyPoaName },
      }),

      label2: {
        tKey: 'questionnaire.propertyPoa:non-partner-alt-property-poa.label2',
      },

      subLabel: adultSameProvincePropertyPoa,

      sidebar: attorneyOutOfProvinceSidebar,

      fields: [
        {
          name: 'altNonPartnerPropertyPoaName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.propertyPoa:non-partner-alt-property-poa.fields.altNonPartnerPropertyPoaName.placeholder',
          },
          reviewLabel: ({ answerStore }) => {
            const { province } = answerStore
            return {
              tKey: 'questionnaire.propertyPoa:non-partner-alt-property-poa.fields.altNonPartnerPropertyPoaName.reviewLabel',
              options: {
                term: provinceDictionary(
                  ONTARIO_TERMS.ATTORNEY_FOR_PROPERTY,
                  province,
                ),
              },
            }
          },
          appointeeType: appointeeTypes.ALTERNATE_PROPERTY_POA,
          forbiddenTerms: rbcForbiddenTerms,
        },
        {
          name: 'altNonPartnerPropertyPoaRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { province: { $exists: true } },
          { nonPartnerPropertyPoaName: { $exists: true } },
          {
            $or: [{ partnerAsPropertyPoa: 'no' }, { hasPartner: 'no' }],
          },
        ],
      },
    },

    // This question only displays for BC users
    {
      id: 1602683801,
      pathname: 'land-title-certification',
      section,
      label: {
        tKey: 'questionnaire.propertyPoa:land-title-certification.label',
      },
      label2: {
        tKey: 'questionnaire.propertyPoa:land-title-certification.label2',
      },
      subLabel: {
        tKey: 'questionnaire.propertyPoa:land-title-certification.subLabel',
      },
      fields: [
        {
          type: 'quickRadio',
          name: 'dealWithRealEstate',
          reviewLabel: {
            tKey: 'questionnaire.propertyPoa:land-title-certification.fields.dealWithRealEstate.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      interceptModal: [
        {
          label: {
            tKey: 'questionnaire.propertyPoa:land-title-certification.interceptModal.item1.label',
          },

          subLabel: ({ meta: { hasCoreProductPayment } }) => ({
            tKey: `questionnaire.propertyPoa:land-title-certification.interceptModal.item1.subLabel.${hasCoreProductPayment ? 'case1' : 'case2'}`,
          }),

          primaryButtonIcon: true,
          primaryButtonLabel: { tKey: 'common:iUnderstand' },
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
          },
          secondaryButtonLabel: {
            tKey: 'questionnaire.propertyPoa:land-title-certification.interceptModal.item1.secondaryButtonLabel',
          },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
            questionFragments: { product: 'will' },
          },
          secondaryButtonTrigger: { 'meta.hasCoreProductPayment': false },
          trigger: { dealWithRealEstate: 'yes' },
        },
      ],
      trigger: { province: CONSTANTS.provinces.BRITISH_COLUMBIA },
    },

    {
      id: 1572385740,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: { tKey: 'questionnaire.propertyPoa:review.label' },
      subLabel: { tKey: 'questionnaire.propertyPoa:review.subLabel' },
    },
  ],
}
