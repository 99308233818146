import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Select from '../../../../../common/components/atoms/Select'
import {
  getDate,
  getMonth,
  getYear,
} from '../../../../../common/formatting/parseDate'
import dates from './utils/dates'
import months from './utils/months'
import years from './utils/years'

const yearsOptions = years()

const DateDropdowns = ({
  defaultDate = '',
  onDateChange,
  displayValidation,
}) => {
  const [month, setMonth] = useState(
    (defaultDate && getMonth(new Date(defaultDate))) || '',
  )
  const [date, setDate] = useState(
    (defaultDate && getDate(new Date(defaultDate))) || '',
  )
  const [year, setYear] = useState(
    (defaultDate && getYear(new Date(defaultDate))) || '',
  )

  useEffect(() => {
    if (month && date && year) {
      /*
       * By converting to JS date, we can ensure the date is valid, aka: no leap year issues.
       * The UI might show an invalid date, but the date from onDateChange will always be a valid date string.
       */
      const updatedJsDate = new Date(`${month} ${date}, ${year}`)
      onDateChange(
        `${getMonth(updatedJsDate)} ${getDate(updatedJsDate)}, ${getYear(
          updatedJsDate,
        )}`,
      )
    } else {
      onDateChange('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, date, year])

  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center flex-wrap sm:flex-row sm:justify-center">
      {/* month */}
      <div className="mb-4 w-full max-w-xxs sm:w-2/5">
        <Select
          selectId="month"
          value={month}
          isInvalid={displayValidation && !month}
          validationMessage=""
          options={months}
          placeholder={t('molecules:DateDropdowns.month')}
          onInputChange={(val) => setMonth(val)}
        />
      </div>

      {/* date */}
      <div className="mb-4 w-full max-w-xxs sm:w-1/5 sm:mx-2">
        <Select
          selectId="date"
          value={date}
          isInvalid={displayValidation && !date}
          validationMessage=""
          options={dates}
          placeholder={t('molecules:DateDropdowns.day')}
          onInputChange={(val) => setDate(val)}
        />
      </div>

      {/* year */}
      <div className="mb-4 w-full max-w-xxs sm:w-1/4">
        <Select
          selectId="year"
          value={year}
          isInvalid={displayValidation && !year}
          validationMessage=""
          options={yearsOptions}
          placeholder={t('molecules:DateDropdowns.year')}
          onInputChange={(val) => setYear(val)}
        />
      </div>
    </div>
  )
}

DateDropdowns.propTypes = {
  defaultDate: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  displayValidation: PropTypes.bool,
}

export default DateDropdowns
