import React from 'react'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../../common/components/atoms/Text'
import MailingAddressForm from '../../../../../../../../../common/components/MailingAddressForm'

interface KeyValuePairs {
  [key: string]: string
}

interface Props {
  initialMailingAddress?: KeyValuePairs
  onSubmit: (formData: KeyValuePairs) => void
}

const BookWitnessingMailingAddressView = ({
  onSubmit,
  initialMailingAddress = undefined,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Box
      data-testid="book-witnessing-mailing-address-view"
      sx={{ width: { min: '100%', sm: '322px', md: '375px' } }}
    >
      <Heading align="center" variant="h3">
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingMailingAddressView.label',
        )}
      </Heading>

      <Text align="center" variant="paragraph" sx={{ my: '0.7rem' }}>
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingMailingAddressView.subLabel',
        )}
      </Text>

      <MailingAddressForm
        onSubmit={onSubmit}
        initialFormData={initialMailingAddress}
      />
    </Box>
  )
}

export default BookWitnessingMailingAddressView
