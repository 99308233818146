import React, { memo, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import _isFunction from 'lodash/isFunction'

import PersonIcon from '@mui/icons-material/Person'
import { Box, Menu, MenuItem, useTheme } from '@mui/material'
import { alpha } from '@mui/material/styles'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import commonSelectors from '../../../../../ducks/commonSelectors'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../styles/muiTheme'
import handleLogout from '../../api/handleLogout'
import HeaderContext from '../../context/HeaderContext'
import LogoutButton from '../LogoutButton'
import NavItem from '../NavItem'

const AccountAvatar = memo(({ isMobile, world }) => {
  const { dispatchSetGlobalErrorMessage } = useContext(HeaderContext)

  const [anchorEl, setAnchorEl] = useState(null)
  const dropdownOpen = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)
  const handleClick = (e) => setAnchorEl(e.currentTarget)

  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const logoutLabel = t('navigation:logout')

  const menuItems = useMemo(
    () => [
      {
        id: 'accountSettings',
        to: '/account-settings',
        label: t('navigation:accountSettings'),
        worlds: [
          CONSTANTS.worldTypes.will.type,
          CONSTANTS.worldTypes.social.type,
        ],
        borderRadius: `${theme.shape.borderRadiusLarge}px ${theme.shape.borderRadiusLarge}px 0 0`,
      },
      {
        to: () => handleLogout(dispatchSetGlobalErrorMessage),
        label: logoutLabel,
        worlds: [
          CONSTANTS.worldTypes.will.type,
          CONSTANTS.worldTypes.social.type,
        ],
        borderRadius: `0 0 ${theme.shape.borderRadiusLarge}px ${theme.shape.borderRadiusLarge}px`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  const worldMenuItems = menuItems.filter((menuItem) =>
    menuItem.worlds.includes(world),
  )

  return (
    <>
      {isMobile ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {React.Children.toArray(
            worldMenuItems
              .filter(({ label }) => label !== logoutLabel)
              .map(({ to, label, id }) => (
                <NavItem id={id} to={to} label={label}>
                  {label}
                </NavItem>
              )),
          )}
          <LogoutButton />
        </Box>
      ) : (
        <>
          <Box
            as="button"
            onClick={handleClick}
            data-testid="account-avatar-button"
            sx={{
              display: 'flex',
              width: '2.2rem',
              height: '2.2rem',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'border 0.17s',
              borderRadius: theme.shape.borderRadiusFull,
              border: `1px solid ${
                dropdownOpen
                  ? themeColors[themeColorTypes.ACCENT_2][
                      themeColorVariants.DARKER
                    ]
                  : themeColors[themeColorTypes.ACCENT_2][
                      themeColorVariants.MAIN
                    ]
              }`,
              backgroundColor:
                themeColors[themeColorTypes.ACCENT_2][themeColorVariants.LIGHT],

              '&:hover': {
                border: `1px solid ${
                  themeColors[themeColorTypes.ACCENT_2][
                    themeColorVariants.DARKER
                  ]
                }`,
              },
            }}
          >
            <PersonIcon
              fontSize="xsmall"
              sx={{
                color:
                  themeColors[themeColorTypes.ACCENT_2][
                    themeColorVariants.DARKEST
                  ],
              }}
            />
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={dropdownOpen}
            onClose={handleClose}
            onClick={handleClose}
            MenuListProps={{ sx: { py: 0 } }}
            data-testid="account-avatar-dropdown-menu"
            PaperProps={{
              elevation: 0,
              sx: {
                mt: 1.5,
                overflow: 'visible',
                borderRadius: theme.shape.borderRadiusLarge,
                filter: 'drop-shadow(0px 1px 8px rgba(0,0,0,0.17))',

                '&:before': {
                  top: 0,
                  zIndex: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {React.Children.toArray(
              worldMenuItems.map(({ to, label, borderRadius }, index) => (
                <MenuItem
                  disableRipple
                  sx={{
                    p: '1.2rem',
                    borderRadius,
                    fontWeight: 500,
                    fontSize: '0.92rem',
                    color: alpha(
                      themeColors[themeColorTypes.BRAND][
                        themeColorVariants.LIGHT
                      ],
                      0.88,
                    ),

                    '&:hover': {
                      color:
                        themeColors[themeColorTypes.BRAND][
                          themeColorVariants.MAIN
                        ],
                      backgroundColor:
                        themeColors[themeColorTypes.ACCENT_2][
                          themeColorVariants.LIGHTER
                        ],
                    },
                  }}
                  divider={index !== worldMenuItems.length - 1}
                  onClick={_isFunction(to) ? to : () => navigate(to)}
                >
                  {label}
                </MenuItem>
              )),
            )}
          </Menu>
        </>
      )}
    </>
  )
})

AccountAvatar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  world: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.type),
  ).isRequired,
}

const mapStateToProps = (state) => ({ world: commonSelectors.getWorld(state) })

export default connect(mapStateToProps)(AccountAvatar)
