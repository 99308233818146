import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { CONSTANTS } from '@epilogue/common'
import i18n from 'i18next'
import { NumericFormat, PatternFormat } from 'react-number-format'

const configurationTypes = {
  PHONE: 'phone',
  PERCENT: 'percent',
  CURRENCY: 'currency',
}

const configurations = (languageType) => ({
  //
  // PatternFormat configurations
  //
  [configurationTypes.PHONE]: {
    mask: '_',
    format: '(###) ###-####',
  },

  //
  // NumericFormat configurations
  //
  [configurationTypes.CURRENCY]: {
    prefix: languageType === CONSTANTS.languageTypes.FR_CA.id ? undefined : '$',
    suffix:
      languageType === CONSTANTS.languageTypes.FR_CA.id ? ' $' : undefined,
    thousandSeparator:
      languageType === CONSTANTS.languageTypes.FR_CA.id ? ' ' : ',',
    decimalScale: 0,
    isAllowed: ({ floatValue, value }) => floatValue >= 1 || value === '',
  },
  [configurationTypes.PERCENT]: {
    prefix: undefined,
    decimalScale: 2,
    isAllowed: ({ floatValue, value }) =>
      (floatValue >= 0 && floatValue <= 100) || value === '',
  },
})

const NumberFormatCustom = forwardRef(
  ({ onChange, format, ...props }, inputRef) => {
    const commonProps = {
      ...props,
      getInputRef: inputRef.current,
    }
    const {
      mask,
      prefix,
      suffix,
      isAllowed,
      decimalScale,
      thousandSeparator,
      format: theFormat,
    } = configurations(i18n.language)[format]

    if (format === configurationTypes.PHONE) {
      return (
        <PatternFormat
          {...commonProps}
          mask={mask}
          format={theFormat}
          onValueChange={(values) => {
            onChange({
              target: { name: props.name, value: values.formattedValue },
            })
          }}
        />
      )
    }

    return (
      <NumericFormat
        {...commonProps}
        valueIsNumericString
        thousandSeparator={thousandSeparator}
        thousandsGroupStyle="thousand"
        prefix={prefix}
        suffix={suffix}
        isAllowed={isAllowed}
        decimalScale={decimalScale}
        onValueChange={(values) => {
          onChange({ target: { name: props.name, value: values.value } })
        }}
      />
    )
  },
)

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.oneOf(Object.values(configurationTypes)).isRequired,
}

export default NumberFormatCustom
