import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _toNumber from 'lodash/toNumber'
import _toString from 'lodash/toString'

import { Box, Slider } from '@mui/material'

import { utils } from '@epilogue/common'
import i18n from 'i18next'

import Text from '../../../../../common/components/atoms/Text'

const formatValue = (format, value) => {
  switch (format) {
    case '$':
      return utils.toDollar(value, 0, i18n.language)

    default:
      return value
  }
}

const RangeField = ({ field, answerCache, handleFieldFragmentUpdate }) => {
  const { name, min, max, step, format } = field

  const [fieldValue, setFieldValue] = useState(
    answerCache[name] || _toString(min),
  )

  useEffect(() => {
    handleFieldFragmentUpdate({ [name]: fieldValue })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      mb="1.5rem"
      width="100%"
      display="flex"
      maxWidth="600px"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box mb="1.5rem">
        <Text size="4xl" align="center">
          {formatValue(format, fieldValue)}
        </Text>
      </Box>

      <Slider
        min={min}
        max={max}
        step={step}
        value={_toNumber(fieldValue)}
        onChange={(_, newValue) => setFieldValue(_toString(newValue))}
        onChangeCommitted={(_, newValue) =>
          handleFieldFragmentUpdate({ [name]: _toString(newValue) })
        }
      />
    </Box>
  )
}

RangeField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    format: PropTypes.string,
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

export default RangeField
