import { formatInTimeZone } from 'date-fns-tz'

import { Timeslot } from '../../datePicker.types'

export default (timeslots: Timeslot[], timezone: string): Timeslot[] => {
  const formatPattern = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"

  return timeslots.map((timeslot) => {
    const userTimezoneStart = formatInTimeZone(
      new Date(timeslot.start),
      timezone,
      formatPattern,
    )

    const userTimezoneEnd = formatInTimeZone(
      new Date(timeslot.end),
      timezone,
      formatPattern,
    )

    return { ...timeslot, start: userTimezoneStart, end: userTimezoneEnd }
  })
}
