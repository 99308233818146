/*
 * It's 2020, so basically every browser supports
 * scrollIntoView with options, but just in case...
 */
export default (element, options) => {
  if (element) {
    try {
      element.scrollIntoView(options)
    } catch {
      element.scrollIntoView(false)
    }
  }
}
