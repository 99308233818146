import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CONSTANTS } from '@epilogue/common'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Text from '../../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import PaymentContext from '../../context/PaymentContext'
import PaymentForm from './components/PaymentForm'
// eslint-disable-next-line no-unused-vars
import PaymentOptions from './components/PaymentOptions'
import useCreatePaymentPlan from './hooks/useCreatePaymentPlan'
import stripeProvider from './utils/stripeProvider'

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: '2rem 1rem',

    [theme.breakpoints.up('xs')]: {
      padding: '2rem 1.4rem',
    },

    [theme.breakpoints.up('sm')]: {
      padding: '2rem 2.2rem',
    },
  },

  purpleBackground: {
    borderRadius: '4px 4px 0 0',
    backgroundColor:
      themeColors[themeColorTypes.ACCENT_2][themeColorVariants.MAIN],
  },
}))

const PaymentBlock = () => {
  const cachedFullAmount = useRef()
  // eslint-disable-next-line no-unused-vars
  const [paymentOption, setPaymentOption] = useState(
    CONSTANTS.paymentOptions.ONE_TIME_PAYMENT,
  )

  const {
    promoCode,
    addOnCart,
    chargeInfo,
    answerStore,
    dispatchSetGlobalErrorMessage,
  } = useContext(PaymentContext)

  const {
    displayTotal,
    // eslint-disable-next-line no-unused-vars
    product: { productId },
  } = chargeInfo

  const {
    data: paymentPlanData,
    refetch: handleCreatePaymentPlan,
    // eslint-disable-next-line no-unused-vars
    isInitialLoading: paymentPlanLoading,
  } = useCreatePaymentPlan({
    promoCode,
    addOnCart,
    answerStore,
    displayTotal,
    dispatchSetGlobalErrorMessage,
  })

  const stripePromise = useMemo(
    () => stripeProvider(paymentOption),
    [paymentOption],
  )

  useEffect(() => {
    if (
      displayTotal !== cachedFullAmount.current &&
      paymentOption === CONSTANTS.paymentOptions.PAYMENT_PLAN
    ) {
      handleCreatePaymentPlan()
      cachedFullAmount.current = displayTotal
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOption, chargeInfo])

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Paper elevation={0}>
      <Box py="0.7rem" className={classes.purpleBackground}>
        <Text size="xl" align="center" display="block">
          {t('payment:components.PaymentBlock.label')}
        </Text>
      </Box>

      <Box className={classes.contentWrapper}>
        {/* Comment back in + update eslint-disable-next-line above to get installments back */}
        {/* {productId > 0 && (
          <PaymentOptions
            onChange={setPaymentOption}
            paymentOption={paymentOption}
            isLoading={paymentPlanLoading}
            installments={paymentPlanData?.installments}
            downPaymentAmount={paymentPlanData?.downPaymentAmount}
          />
        )} */}

        <Box pt="0.7rem">
          <StripeElements
            key={paymentOption}
            stripe={stripePromise}
            options={{
              locale: i18n.language,
              fonts: [
                {
                  cssSrc:
                    'https://fonts.googleapis.com/css?family=Roboto:400&display=swap',
                },
              ],
            }}
          >
            <PaymentForm
              paymentOption={paymentOption}
              installments={paymentPlanData?.installments}
              paymentPlanId={paymentPlanData?.paymentPlanId}
              downPaymentAmount={paymentPlanData?.downPaymentAmount}
            />
          </StripeElements>
        </Box>
      </Box>
    </Paper>
  )
}

export default PaymentBlock
