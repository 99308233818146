import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import { Alert, Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import growAnimation from '../../../../../../../../common/animations/growAnimation'
import { ReactComponent as SuccessCheck } from '../../../../../../../../common/assets/images/success-check.svg'
import Heading from '../../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../../common/components/atoms/Text'
import MailingAddressForm from '../../../../../../../../common/components/MailingAddressForm'
import amplitude from '../../../../../../../../common/lib/amplitude'
import event from '../../../../../../../../common/lib/event'
import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../common/request'
import dashboardSelectors from '../../../../../../ducks/dashboard/dashboardSelectors'

enum MailingStatus {
  IDLE = 'idle',
  ERROR = 'error',
  WORKING = 'working',
  SUCCESS = 'success',
}

interface Props {
  show: boolean
  userId: string
  answerStore: any
  onClose: () => void
  dispatchFetchAddOns: () => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const MailingAddressModal = ({
  show,
  userId,
  onClose,
  answerStore,
  dispatchFetchAddOns,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const [status, setStatus] = useState<MailingStatus>(MailingStatus.IDLE)

  // status will be set to inactive on unmount
  useEffect(() => () => setStatus(MailingStatus.IDLE), [])
  useEffect(() => amplitude.sendEvent('ViewedMailingForm'), [])

  const { firstName, lastName, province } = answerStore

  const { t } = useTranslation()

  return (
    <Modal
      size="lg"
      show={show}
      onClose={onClose}
      data-testid="mailing-address-modal"
      preventClose={status === MailingStatus.WORKING}
    >
      {status === 'success' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: { xxs: '1rem', md: '3.5rem' },
          }}
        >
          <motion.div {...growAnimation}>
            <Box width="55px" display="flex" justifyContent="center">
              <SuccessCheck />
            </Box>
          </motion.div>

          <Heading align="center" variant="h3">
            {t('documents:components.modals.MailingAddressModal.success.label')}
          </Heading>
          <Text align="center" sx={{ mt: '0.6rem' }} variant="paragraph">
            {t(
              'documents:components.modals.MailingAddressModal.success.subLabel',
            )}
          </Text>
        </Box>
      ) : (
        <Box
          sx={{
            py: '1rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            px: { xxs: '0', md: '1rem' },
            width: {
              xxs: '100%',
              sm: '360px',
              md: '480px',
              lg: '600px',
            },
          }}
        >
          <Heading variant="h3">
            {t('documents:components.modals.MailingAddressModal.label')}
          </Heading>

          {/* Remove alert below when Canada Post strike is over */}
          <Alert sx={{ mt: '1.3rem' }} severity="error">
            Due to the Canada Post strike, document delivery is temporarily
            postponed. You can still submit your mailing address and we&apos;ll
            send your documents when postal service resumes.
          </Alert>

          <MailingAddressForm
            working={status === MailingStatus.WORKING}
            onSubmit={async (formData) => {
              setStatus(MailingStatus.WORKING)

              try {
                await request({
                  method: 'POST',
                  url: PATHS.ADD_ON_PRINT_REQUEST,
                  data: {
                    mailingAddress: formData,
                    firstName,
                    lastName,
                    province,
                  },
                })

                amplitude.sendEvent('SubmittedMailingForm')
                event.send({
                  metadata: { userId },
                  name: CONSTANTS.eventNames.PRINT_CREDIT,
                  action: CONSTANTS.eventActionTypes.USED,
                })

                setStatus(MailingStatus.SUCCESS)
                dispatchFetchAddOns()
              } catch (error) {
                setStatus(MailingStatus.ERROR)
                errorHandler({
                  error,
                  reportError: true,
                  fallbackErrorMessage: t(
                    'documents:components.modals.MailingAddressModal.fallbackErrorMessage',
                  ),
                  onError: (resolvedErrorMessage: string) =>
                    dispatchSetGlobalErrorMessage(resolvedErrorMessage),
                })
              }
            }}
          />
        </Box>
      )}
    </Modal>
  )
}

const mapStateToProps = (state: any) => ({
  userId: dashboardSelectors.getUserId(state.dashboard),
})

export default connect(mapStateToProps)(MailingAddressModal)
