import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Text from '../../../../../common/components/atoms/Text'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import defaultCorpExec from '../../../data/corporateExecutors'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import HelpWithThisQuestionSublabel from '../../molecules/HelpWithThisQuestionSublabel'
import MultiOptionNavButton from '../../molecules/MultiOptionNavButton'
import SidebarTextToggle from '../../molecules/SidebarTextToggle'

const Disqualifier = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      columnGap: '1.56rem',
      alignItems: 'baseline',
    }}
  >
    <Box
      sx={{
        flexShrink: 0,
        width: '0.7rem',
        height: '0.7rem',
        display: 'block',
        borderRadius: (theme) => theme.shape.borderRadiusFull,
        backgroundColor: (theme) =>
          theme.palette[themeColorTypes.ACCENT_1][themeColorVariants.MAIN],
      }}
    />

    <Text variant="paragraph" sx={{ lineHeight: '1.5', fontSize: '1.07rem' }}>
      {children}
    </Text>
  </Box>
)

Disqualifier.propTypes = {
  children: PropTypes.node.isRequired,
}

const CorporateExecutorDisqualifiers = ({
  referrer,
  question,
  answerCache,
  answerStore,
  handleFieldFragmentUpdate,
}) => {
  const { fields, corporateExecutorType } = question
  const [{ name: fieldName, options: fieldOptions }] = fields
  const [{ value: primaryOptionValue }, { value: secondaryOptionValue }] =
    fieldOptions
  const { partnerFirstName } = answerStore

  const disqualifiers = useMemo(
    () =>
      defaultCorpExec.disqualifiers(
        corporateExecutorType,
        partnerFirstName,
        referrer,
      ),
    [corporateExecutorType, partnerFirstName, referrer],
  )

  useEffect(() => {
    handleFieldFragmentUpdate({
      [fieldName]: answerCache[fieldName] || primaryOptionValue,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  return (
    <Box>
      <HelpWithThisQuestionSublabel>
        {t('customQuestion:CorporateExecutorDisqualifiers.subLabel.part1')}{' '}
        <SidebarTextToggle>
          {t('customQuestion:CorporateExecutorDisqualifiers.subLabel.part2')}
        </SidebarTextToggle>{' '}
        {t('customQuestion:CorporateExecutorDisqualifiers.subLabel.part3')}
      </HelpWithThisQuestionSublabel>
      <Box
        sx={{
          px: '2.25rem',
          py: '2.75rem',
          backgroundColor: (theme) =>
            theme.palette[themeColorTypes.NEUTRAL][themeColorVariants.LIGHT],
          borderRadius: (theme) => theme.shape.borderRadiusLarge,
          maxWidth: '45rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1.8rem',
        }}
      >
        {React.Children.toArray(
          disqualifiers.map((disqualifierContent) => (
            <Disqualifier>
              {t(_get(disqualifierContent, 'tKey', disqualifierContent), {
                ..._get(disqualifierContent, 'options', {}),
              })}
            </Disqualifier>
          )),
        )}
      </Box>
      <MultiOptionNavButton
        primaryLabel={t('common:continue')}
        secondaryLabel={t('common:doesNotLookRightForMe')}
        primaryQuestionFragment={{ [fieldName]: primaryOptionValue }}
        secondaryQuestionFragment={{ [fieldName]: secondaryOptionValue }}
      />
    </Box>
  )
}

CorporateExecutorDisqualifiers.propTypes = {
  referrer: PropTypes.shape({}).isRequired,
  question: PropTypes.shape({
    corporateExecutorType: PropTypes.oneOf(
      Object.values(CONSTANTS.corporateExecutorTypes),
    ),
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  answerStore: PropTypes.shape({ partnerFirstName: PropTypes.string })
    .isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
})

export default connect(mapStateToProps)(CorporateExecutorDisqualifiers)
