import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { CONSTANTS } from '@epilogue/common'

import commonActions from '../../../../../common/ducks/commonActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import FlatFeeLawyerReferral from './components/FlatFeeLawyerReferral'
import StandardLawyerReferral from './components/StandardLawyerReferral'
import LawyerReferralContext from './context/LawyerReferralContext'
import useLawyers from './hooks/useLawyers'

const flatFeeProvinces = new Set([
  CONSTANTS.provinces.ONTARIO,
  CONSTANTS.provinces.MANITOBA,
])

const LawyerReferral = ({
  referrer,
  dropOffType,
  answerStore,
  dispatchSetGlobalErrorMessage,
}) => {
  const [lawyerReferralData, setLawyerReferralData] = useState({
    name: '',
    email: '',
    phone: '',
    referredLawyerIds: [],
  })

  const { province } = answerStore
  const isFlatFee = flatFeeProvinces.has(province)

  const { data: lawyers } = useLawyers({
    referrer,
    province,
    dropOffType,
    flatFee: isFlatFee,
    dispatchSetGlobalErrorMessage,
  })

  const contextData = useMemo(
    () => ({
      lawyers,
      answerStore,
      lawyerReferralData,
      setLawyerReferralData,
      dispatchSetGlobalErrorMessage,
    }),
    [answerStore, dispatchSetGlobalErrorMessage, lawyerReferralData, lawyers],
  )

  return (
    <LawyerReferralContext.Provider value={contextData}>
      {isFlatFee ? <FlatFeeLawyerReferral /> : <StandardLawyerReferral />}
    </LawyerReferralContext.Provider>
  )
}

LawyerReferral.propTypes = {
  dropOffType: PropTypes.oneOf(Object.values(CONSTANTS.dropOffTypes))
    .isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
  referrer: PropTypes.shape({}).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
})

export default connect(mapStateToProps, mapDispatchToProps)(LawyerReferral)
