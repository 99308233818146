/*
 *
 * This component is used in both SectionReview.js and SummaryCard.js
 *
 */
import React from 'react'
import PropTypes from 'prop-types'

import { useLocation, useNavigate } from 'react-router-dom'

import _isEmpty from 'lodash/isEmpty'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'

import { CONSTANTS } from '@epilogue/common'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { isFieldValidOrOptional } from '../../../../scenes/Questionnaire/utils/validation/validation'
import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import Button from '../../atoms/Button'
import IconButton from '../../atoms/IconButton/IconButton'

const useStyles = makeStyles({
  desktopRoot: {
    padding: '0.23rem 0.55rem',
  },
  mobileRoot: {
    '& svg': {
      fontSize: '1.3rem',
    },
  },
})

const CardButton = ({ world, question, answerStore, dispatchToQuestion }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleClickEdit = () => {
    // update questionStack
    dispatchToQuestion({
      question,
      fromDashboard: !location.pathname.includes('/questionnaire'),
    })
    // send user to questionnaire
    navigate(`/questionnaire?w=${world}`)
  }

  /*
   * There may be certain questions that we automatically answer for the user
   * where the user doesn't have an option to change the value. For this type
   * of question, we do not want to show the "Edit" button, so we identify those
   * questions here.
   */
  const isUneditable = question.type === 'intro' || question.type === 'info'

  /*
   * The children question is a bit of a special case.
   * We want to know if either children OR stepChildren is answered.
   * If either is answered, the question is considered answered.
   */
  const isChildrenQuestionAnswered =
    question.type === 'children' &&
    question.fields.some(
      (f) =>
        (!_isEmpty(answerStore.children) ||
          !_isEmpty(answerStore.stepChildren)) &&
        (f.name === 'children' || f.name === 'stepChildren'),
    )

  const awaitingAnswer =
    !_isEmpty(question.fields) &&
    !question.optional &&
    question.fields.some(
      (field) => !isFieldValidOrOptional(field, answerStore[field.name]),
    )

  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className="flex justify-end items-start align-top">
      <div className={cn({ invisible: isUneditable })}>
        {isDesktop ? (
          <Button
            className={classes.desktopRoot}
            data-testid={`summary-edit-button-${question.id}`}
            label={t('common:edit')}
            size="xxs"
            color={
              awaitingAnswer && !isChildrenQuestionAnswered
                ? themeColorTypes.ACCENT_1
                : themeColorTypes.BRAND
            }
            colorVariant={
              awaitingAnswer && !isChildrenQuestionAnswered
                ? themeColorVariants.LIGHT
                : themeColorVariants.MAIN
            }
            rounded="normal"
            startIcon={<EditOutlinedIcon />}
            variant="outlined"
            onClick={handleClickEdit}
          />
        ) : (
          <IconButton
            className={classes.mobileRoot}
            data-testid={`summary-edit-button-${question.id}`}
            color={
              awaitingAnswer && !isChildrenQuestionAnswered
                ? themeColorTypes.ACCENT_1
                : themeColorTypes.BRAND
            }
            colorVariant={
              awaitingAnswer && !isChildrenQuestionAnswered
                ? themeColorVariants.LIGHT
                : themeColorVariants.MAIN
            }
            IconComponent={<EditOutlinedIcon />}
            onClick={handleClickEdit}
          />
        )}
      </div>
    </div>
  )
}

CardButton.propTypes = {
  world: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.type),
  ).isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    optional: PropTypes.bool,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
  answerStore: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
    stepChildren: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default CardButton
