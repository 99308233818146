import { useMutation } from '@tanstack/react-query'

import { PATHS, request } from '../../../../../../../../../../common/request'

export default ({ productId }) => {
  const { mutate: handleApplyPromoCode, isLoading } = useMutation({
    mutationFn: (promoCode) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            request({
              method: 'GET',
              url: `${PATHS.PROMO_CODE_CHECK_VALID}/${productId}/${promoCode}`,
            }),
          )
          // create artificial load with timeout
        }, 820)
      }),
  })
  return { handleApplyPromoCode, isLoading }
}
