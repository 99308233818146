import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Text } from 'grommet'
import { LinkNext } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import questionnaireSelectors from '../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import amplitude from '../../lib/amplitude'
import tw from '../../styles/tw'
import EverythingButton from '../molecules/EverythingButton'
import SummaryCards from './components/SummaryCards'
import SummaryHeading from './components/SummaryHeading'

const PaddedButton = styled(Button)`
  padding: ${tw.spacing['1']} ${tw.spacing['3']};
`

const Summary = ({
  answerStore,
  questionnaireValid,
  willQuestionnaireStarted,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    try {
      // If there is a hash in the url
      if (location.hash) {
        // see if that hash matches an id for an element on the page
        const el = document.querySelector(location.hash)

        // if the element is found, scroll to it
        if (el) {
          /*
           * Theres a known bug in chromium/react where triggering a scroll action in a useEffect
           * works inconsistently. Wrapping the scroll in a setTimeout with a delay of 0 will force
           * the scroll to the next Javascript tick, and scroll the window consistently.
           */
          setTimeout(() => {
            el.scrollIntoView()
          }, 0)
        }
      }
    } catch {
      // eslint-disable-next-line no-console
      console.log('Oops, something went wrong here')
    }

    amplitude.sendEvent('ViewedSummary', {
      questionnaireValid,
      willQuestionnaireStarted,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  return (
    <div className="max-w-xl m-auto">
      <div className="mb-8">
        <SummaryHeading
          questionnaireValid={questionnaireValid}
          answerStore={answerStore}
        />
      </div>

      <SummaryCards />

      {willQuestionnaireStarted && (
        <div className="mt-5 flex flex-col items-center">
          <EverythingButton />

          <div className="mt-5">
            <PaddedButton
              plain
              hoverIndicator
              reverse
              onClick={() => {
                navigate('/dashboard/documents')
                window.scrollTo(0, 0)
              }}
              icon={<LinkNext color="grey-5" size="small" />}
              label={
                <Text weight={500} color="grey-5" size="small">
                  {t('summary:seeMyDocuments')}
                </Text>
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

Summary.propTypes = {
  answerStore: PropTypes.shape({}).isRequired,
  questionnaireValid: PropTypes.bool,
  willQuestionnaireStarted: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
  willQuestionnaireStarted: questionnaireSelectors.getQuestionnaireStarted(
    state.questionnaire,
  ),
})

export default connect(mapStateToProps)(Summary)
