import dashboardTypes from './dashboardTypes'

export default {
  beforeDashboard: () => ({
    type: dashboardTypes.BEFORE_DASHBOARD,
  }),

  setUserProperties: (properties) => ({
    type: dashboardTypes.SET_USER_PROPERTIES,
    payload: properties,
  }),
}
