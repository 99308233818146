import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import _isEmpty from 'lodash/isEmpty'

import { Box, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { utils } from '@epilogue/common'
import cn from 'classnames'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../common/components/atoms/Button'
import Text from '../../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import PaymentContext from '../../context/PaymentContext'

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    margin: '0 auto',

    [theme.breakpoints.up('md')]: {
      width: '480px',
    },
  },

  greyBorderColor: {
    borderColor: themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTER],
  },

  contentWrapper: {
    padding: '1.7rem 1rem 1.3rem',

    [theme.breakpoints.up('xs')]: {
      padding: '2rem 1.4rem 1.4rem',
    },

    [theme.breakpoints.up('sm')]: {
      padding: '2rem 2rem 1.5rem',
    },

    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.5rem',
    },
  },

  underlineButton: {
    padding: '0',
    justifyContent: 'flex-end',
    fontSize: '0.8125rem',

    '& span': {
      padding: '0',
      display: 'flex',
      justifyContent: 'flex-end',
    },

    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
}))

const AddOnDetails = ({ sendToDashboardPage }) => {
  const classes = useStyles()

  const { chargeInfo, dispatchRemoveAddOnFromCart, answerStore } =
    useContext(PaymentContext)

  const { firstName, partnerFirstName } = answerStore

  const {
    addOns,
    docTypes,
    product: { productId },
  } = chargeInfo

  const { t } = useTranslation()

  return (
    <Paper
      data-testid="add-on-details-wrapper"
      className={cn(
        _isEmpty(docTypes) && productId === 0 && classes.cardWrapper,
      )}
      elevation={0}
    >
      <Box className={classes.greyBorderColor} borderBottom={1} py="0.7rem">
        <Text size="xl" align="center" display="block">
          {t('payment:components.AddOnDetails.label')}
        </Text>
      </Box>

      <Box className={classes.contentWrapper}>
        {addOns.map(
          (
            { price, priceTotal, type, label, partnerPaidFor, amount, tKey },
            index,
          ) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: index > 0 ? '1.5rem' : '0',
              }}
              key={type}
            >
              <Box>
                <Text weight="bold">{t(tKey || label)}</Text>
                {partnerPaidFor && firstName && partnerFirstName && (
                  <Text size="sm">
                    {t('payment:components.AddOnDetails.names', {
                      firstName,
                      partnerFirstName,
                    })}
                  </Text>
                )}
              </Box>

              <Box
                pl="0.9rem"
                display="flex"
                minWidth="7rem"
                alignItems="flex-end"
                flexDirection="column"
              >
                <Text weight="bold">
                  {utils.toDollar(priceTotal, 2, i18n.language)}
                </Text>
                {amount > 1 && (
                  <Text
                    size="xxs"
                    color={themeColorTypes.GREY}
                    sx={{ mt: '0.25rem', mb: '0.2rem' }}
                  >
                    {utils.toDollar(price, 2, i18n.language)}/QTY {amount}
                  </Text>
                )}
                <Button
                  data-testid={`add-on-details-remove-button-${index}`}
                  size="sm"
                  label={t('common:remove')}
                  color={themeColorTypes.ACCENT_1}
                  className={classes.underlineButton}
                  onClick={() => {
                    dispatchRemoveAddOnFromCart({ type })
                    /*
                     * If the user is purchasing an add-on only product
                     * and they remove the last remaining add-on, we need to
                     * send the user back to the dashboard page because there
                     * is nothing left to purchase
                     */
                    if (
                      _isEmpty(docTypes) &&
                      productId === 0 &&
                      addOns.length < 2
                    ) {
                      sendToDashboardPage()
                    }
                  }}
                />
              </Box>
            </Box>
          ),
        )}
      </Box>
    </Paper>
  )
}

AddOnDetails.propTypes = {
  sendToDashboardPage: PropTypes.func.isRequired,
}

export default AddOnDetails
