import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as WarningIcon } from '../../../../../../common/assets/images/warning-icon.svg'
import Button from '../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../common/components/atoms/Text'
import handleLogout from '../../../../../../common/components/molecules/Header/api/handleLogout'
import commonActions from '../../../../../../common/ducks/commonActions'
import amplitude from '../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'

const PartnerOnOriginalAccountModal = ({
  answerStore,
  dispatchSetGlobalErrorMessage,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [showModal, setShowModal] = useState(true)

  const { firstName, partnerFirstName, partnerGender = 'other' } = answerStore

  useEffect(() => {
    if (showModal) {
      amplitude.sendEvent('ViewedPartnerOnOriginalAccountModal')
    }
  }, [showModal])

  const { t } = useTranslation()

  return (
    <Modal
      maxWidth="sm"
      show={showModal}
      onClose={(_, reason) => {
        amplitude.sendEvent('ClosedPartnerOnOriginalAccountModal', { reason })
        setShowModal(false)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          p: isMobile ? '0' : '0.8rem 0',
          rowGap: '2rem',
        }}
        data-testid="partner-on-original-account-modal"
      >
        <WarningIcon />

        <Heading variant="h3">
          {t('dashboard:components.PartnerOnOriginalAccountModal.label', {
            name: firstName,
          })}
        </Heading>

        <Text size="md">
          {t(
            `dashboard:components.PartnerOnOriginalAccountModal.subLabel1.${partnerGender === 'male' ? 'male' : partnerGender === 'female' ? 'female' : 'other'}`,
            {
              partnerFirstName,
            },
          )}
        </Text>

        <Text size="md">
          {t(
            `dashboard:components.PartnerOnOriginalAccountModal.subLabel2.${partnerGender === 'male' ? 'male' : partnerGender === 'female' ? 'female' : 'other'}`,
            {
              firstName,
              partnerFirstName,
            },
          )}
        </Text>

        <Button
          size="md"
          variant="contained"
          color={themeColorTypes.ACCENT_1}
          onClick={() => {
            amplitude.sendEvent(
              'ClickedPartnerOnOriginalAccountModalLogOutButton',
            )
            handleLogout(dispatchSetGlobalErrorMessage)
          }}
          label={t(
            'dashboard:components.PartnerOnOriginalAccountModal.buttonLabel',
            {
              firstName,
            },
          )}
        />
      </Box>
    </Modal>
  )
}

PartnerOnOriginalAccountModal.propTypes = {
  answerStore: PropTypes.shape({
    firstName: PropTypes.string,
    partnerGender: PropTypes.string,
    partnerFirstName: PropTypes.string,
  }).isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartnerOnOriginalAccountModal)
