import React from 'react'
import PropTypes from 'prop-types'

import { CONSTANTS } from '@epilogue/common'

import agreeModalTypes from '../utils/agreeModalTypes'
import GoogleAuthAgreeButton from './GoogleAuthAgreeButton'
import UserNamePasswordAgreeButton from './UsernamePasswordAgreeButton'

const AgreeButton = ({ type, oauthType, ...props }) => {
  let ButtonComponent

  switch (type) {
    case CONSTANTS.oauth.providers.GOOGLE:
      ButtonComponent = GoogleAuthAgreeButton
      break
    case agreeModalTypes.USERNAME_PASSWORD:
      ButtonComponent = UserNamePasswordAgreeButton
      break
    default:
      break
  }

  return (
    <>{ButtonComponent && <ButtonComponent type={oauthType} {...props} />}</>
  )
}

AgreeButton.propTypes = {
  type: PropTypes.oneOf(Object.values(agreeModalTypes)),
  oauthType: PropTypes.oneOf(Object.values(CONSTANTS.oauth.types)),
}

export default AgreeButton
