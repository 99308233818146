import React from 'react'
import PropTypes from 'prop-types'

import { Box, useMediaQuery } from '@mui/material'

import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import Heading from '../../atoms/Heading'
import Text from '../../atoms/Text'

const GetStarted = ({ icon, label, description, hideBottomBorder = false }) => {
  const isXlDesktop = useMediaQuery('(min-width:1577px)')
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  return (
    <Box
      data-testid="value-proposition"
      sx={{
        py: isXlDesktop ? '1.9rem' : '1.6rem',
        width: '100%',
        display: 'flex',
        maxWidth: isMobile ? '28rem' : '33rem',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        borderBottom: hideBottomBorder
          ? undefined
          : (theme) =>
              `solid 1px ${
                theme.palette[themeColorTypes.NEUTRAL][themeColorVariants.MAIN]
              }`,
      }}
    >
      <Box
        sx={{
          width: '8rem',
          display: 'flex',
          flexShrink: 0,
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: isMobile ? '0.7rem' : undefined,
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ml: isMobile ? 0 : '1rem',
        }}
      >
        <Heading
          align={isMobile ? 'center' : 'left'}
          sx={{ mb: isMobile ? '0.7rem' : '0.2rem' }}
          variant="h5"
        >
          {label}
        </Heading>
        <Text align={isMobile ? 'center' : 'left'} variant="paragraph">
          <Box
            as="span"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
            sx={{
              '& a': {
                textDecoration: 'underline',
                color: (theme) =>
                  theme.palette[themeColorTypes.ACCENT_1][
                    themeColorVariants.MAIN
                  ],
              },
            }}
          />
        </Text>
      </Box>
    </Box>
  )
}

GetStarted.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  hideBottomBorder: PropTypes.bool,
  description: PropTypes.string.isRequired,
}

export default GetStarted
