import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'

import { ReactComponent as SocialMediaLogosGroup } from '../../common/assets/images/social-media-logos-group.svg'
import Heading from '../../common/components/atoms/Heading'
import Text from '../../common/components/atoms/Text'
import Header from '../../common/components/molecules/Header'
import SummaryCards from '../../common/components/Summary/components/SummaryCards'
import commonActions from '../../common/ducks/commonActions'
import amplitude from '../../common/lib/amplitude'
import dashboardActions from '../Dashboard/ducks/dashboard/dashboardActions'
import navigationSelectors from '../Questionnaire/ducks/navigation/navigationSelectors'
import questionnaireSelectors from '../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import SocialDocumentCard from './components/SocialDocumentCard/SocialDocumentCard'
import SocialEverythingButton from './components/SocialEverythingButton/SocialEverythingButton'
import useFetchSocialDocuments from './hooks/useFetchSocialDocuments'

const SocialDashboard = ({
  answerStore,
  dispatchBeforeDashboard,
  socialQuestionnaireStarted,
  dispatchSetGlobalErrorMessage,
}) => {
  const { social_firstName } = answerStore

  useEffect(() => {
    dispatchBeforeDashboard()

    amplitude.sendEvent('ViewedSocialDashboard')

    // prevent back button from anywhere in the social-dashboard
    window.history.pushState(null, null, '')
    window.addEventListener('popstate', () => {
      window.history.pushState(null, null, '')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [documents] = useFetchSocialDocuments({
    dispatchSetGlobalErrorMessage,
  })

  const socialDocument = documents?.[0] || []

  return (
    <>
      <Box
        sx={{
          top: 0,
          width: '100%',
          position: 'absolute',
        }}
      >
        <Header />
      </Box>

      <Box
        sx={{
          py: '8rem',
          px: '1.5rem',
          margin: 'auto',
          display: 'flex',
          maxWidth: '36rem',
          alignItems: 'center',
          justifyItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Heading sx={{ pb: '0.375rem' }} variant="h2">
            {social_firstName ? `${social_firstName}'s ` : 'Your '}
            Social Media Dashboard
          </Heading>

          {socialQuestionnaireStarted ? (
            <Text size="md" variant="paragraph">
              You can review and edit any of your answers here.
            </Text>
          ) : (
            <Box
              sx={{
                mt: '2.5rem',
                mb: '1.25rem',
                margin: 'auto',
                width: '16rem',
              }}
            >
              <SocialMediaLogosGroup />
            </Box>
          )}
        </Box>

        <Box sx={{ mt: '2rem' }}>
          <SocialEverythingButton />
        </Box>

        {socialQuestionnaireStarted && (
          <>
            {!_isEmpty(socialDocument) && (
              <Box sx={{ pt: '2.5rem' }}>
                <SocialDocumentCard
                  answerStore={answerStore}
                  document={socialDocument}
                />
              </Box>
            )}

            <Box sx={{ pt: '2rem', width: '100%' }}>
              <SummaryCards />
            </Box>

            <div>
              <SocialEverythingButton />
            </div>
          </>
        )}
      </Box>
    </>
  )
}

SocialDashboard.propTypes = {
  dispatchBeforeDashboard: PropTypes.func.isRequired,
  socialQuestionnaireStarted: PropTypes.bool.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  answerStore: PropTypes.shape({ social_firstName: PropTypes.string })
    .isRequired,
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  nextUnansweredQuestion: navigationSelectors.getNextUnansweredQuestion(
    state.questionnaire,
  ),
  socialQuestionnaireStarted: questionnaireSelectors.getQuestionnaireStarted(
    state.questionnaire,
    CONSTANTS.worldTypes.social.type,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchBeforeDashboard: () => dispatch(dashboardActions.beforeDashboard()),
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialDashboard)
