import { CONSTANTS } from '@epilogue/common'

import commonTypes from './commonTypes'

const initialState = {
  auth: false,
  world: undefined,
  authWorking: true,
  charityPartner: {},
  loginRedirect: undefined,
  globalErrorMessage: undefined,
  availableWorlds: [CONSTANTS.worldTypes.social.type],
  toast: { isOpen: false, type: undefined, message: undefined },
  sidebar: { isOpen: false },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.SET_AUTH:
      return { ...state, auth: action.payload.auth }

    case commonTypes.AUTH_WORKING:
      return { ...state, authWorking: action.payload.working }

    case commonTypes.SET_GLOBAL_ERROR_MESSAGE:
      return { ...state, globalErrorMessage: action.payload.errorMsg }

    case commonTypes.SET_WORLD:
      return { ...state, world: action.payload.world }

    case commonTypes.SET_AVAILABLE_WORLDS:
      return { ...state, availableWorlds: action.payload.availableWorlds }

    case commonTypes.SET_LOGIN_REDIRECT:
      return { ...state, loginRedirect: action.payload.loginRedirect }

    case commonTypes.UPDATE_TOAST:
      return {
        ...state,
        toast: { ...state.toast, ...action.payload.toastProperties },
      }

    case commonTypes.SET_CHARITY_PARTNER:
      return { ...state, charityPartner: { ...action.payload } }

    case commonTypes.UPDATE_SIDEBAR:
      return {
        ...state,
        sidebar: { ...state.sidebar, ...action.payload.sidebarProperties },
      }

    default:
      return state
  }
}
