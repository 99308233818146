import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import validationSelectors from '../../../ducks/validation/validationSelectors'

const fieldValidationMessage = {
  // special validation message by question.type
  dateOfBirth: { tKey: 'validation:validation.dateOfBirth' },
  gifts: { tKey: 'validation:validation.allFieldsRequired' },
  pets: { tKey: 'validation:validation.missingInformation' },
  remoteDistribution: { tKey: 'validation:validation.allFieldsRequired' },
  predeceased: { tKey: 'validation:validation.predeceased' },
  predeceasedBackup: { tKey: 'validation:validation.predeceasedBackup' },
  // stepChildrenDistribution: 'Please select an option for all step-children',

  // special validation messages by field.name
  // children: 'Name and age required',
  // stepChildren: 'Name and age required',

  // standard fields
  text: { tKey: 'common:required' },
  number: { tKey: 'common:required' },
  select: { tKey: 'validation:validation.pleaseSelectOption' },
  radio: { tKey: 'validation:validation.pleaseSelectOption' },
  date: { tKey: 'validation:validation.date' },
  quickRadio: { tKey: 'validation:validation.pleaseSelectOption' },
}

const ValidationMessage = ({
  field = {},
  message,
  invalidFields,
  isInvalid,
  question = {},
  displayValidation,
  displayValidationOverride,
}) => {
  const [validationMessage, setValidationMessage] = useState(message)

  useEffect(() => {
    if (message) {
      setValidationMessage(message)
    } else if (fieldValidationMessage[question.type]) {
      setValidationMessage(fieldValidationMessage[question.type])
    } else if (fieldValidationMessage[field.name]) {
      setValidationMessage(fieldValidationMessage[field.name])
    } else if (fieldValidationMessage[field.type]) {
      setValidationMessage(fieldValidationMessage[field.type])
    } else {
      setValidationMessage('Required')
    }
  }, [field, message, question])

  const invalid =
    isInvalid ||
    (!_isEmpty(invalidFields) &&
      // checks if fieldName or questionType is in the invalidFields array
      (invalidFields.includes(field.name) ||
        invalidFields.includes(`${question.type}-QUESTION`)))

  const { t } = useTranslation()

  return (
    <>
      {((displayValidation && invalid) || displayValidationOverride) && (
        <span className="mb-1 block text-center">
          <Text color="status-critical" size="small">
            *{t(_get(validationMessage, 'tKey', validationMessage))}
          </Text>
        </span>
      )}
    </>
  )
}

ValidationMessage.propTypes = {
  displayValidationOverride: PropTypes.bool,
  displayValidation: PropTypes.bool.isRequired,
  message: PropTypes.string,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  isInvalid: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  question: PropTypes.shape({
    type: PropTypes.string,
  }),
}

const mapStateToProps = (state) => ({
  invalidFields: validationSelectors.getInvalidFields(state.questionnaire),
  displayValidation: validationSelectors.getDisplayValidation(
    state.questionnaire,
  ),
})

export default connect(mapStateToProps)(ValidationMessage)
