import documentsTypes from './documentsTypes'

export default {
  fetchDocuments: () => ({
    type: documentsTypes.FETCH_DOCUMENTS,
  }),

  generateDocumentsWorking: (working) => ({
    type: documentsTypes.GENERATE_DOCUMENTS_WORKING,
    payload: { working },
  }),

  fetchDocumentsWorking: (working) => ({
    type: documentsTypes.FETCH_DOCUMENTS_WORKING,
    payload: { working },
  }),

  setDocuments: (documents) => ({
    type: documentsTypes.SET_DOCUMENTS,
    payload: { documents },
  }),
}
