import _get from 'lodash/get'

import addOnsName from './addOnsName'

export default {
  getPaidAddOns: (state) => _get(state[addOnsName], 'paidAddOns'),
  getAddOnCart: (state) => _get(state[addOnsName], 'addOnCart'),
  getFetchAddOnsWorking: (state) =>
    _get(state[addOnsName], 'fetchAddOnsWorking'),
}
