import React, { useContext } from 'react'

import { Box, useMediaQuery, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import Heading from '../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../common/components/atoms/Text'
import Avatar from '../../../../../../../../common/components/molecules/Avatar'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'
import LawyerReferralContext from '../../../context/LawyerReferralContext'
import LawyerForm from '../../LawyerForm'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '5.8rem',
    height: '5.8rem',
    border: '0.18rem solid',
    borderColor: theme.palette.primary[themeColorVariants.MAIN],
  },
}))

const FlatFeeLawyerContactForm = () => {
  const theme = useTheme()
  const classes = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('2xl'))

  const {
    /*
     * For now, for flat fee referral pages, we only take the
     * first lawyer in the array
     */
    lawyers: [lawyer],
  } = useContext(LawyerReferralContext)

  const { imageUrl, lawyerFirstName, lawyerLastName, city, province, firm } =
    lawyer

  return (
    <>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        padding={isDesktop ? '1.9rem' : '1.6rem'}
        borderRadius={theme.shape.borderRadiusLarge}
        bgcolor={
          theme.palette[themeColorTypes.NEUTRAL][themeColorVariants.LIGHTER]
        }
      >
        <Box
          display="flex"
          rowGap="0.7rem"
          marginBottom={isDesktop ? '1.9rem' : '3rem'}
          flexDirection={isDesktop ? 'row' : 'column'}
          alignItems={isDesktop ? 'flex-start' : 'center'}
        >
          <Avatar
            src={imageUrl}
            className={classes.avatar}
            alt={`${lawyerFirstName} ${lawyerLastName}`}
          />
          <Box
            display="flex"
            alignSelf="center"
            rowGap="0.1rem"
            flexDirection="column"
            marginLeft={isDesktop ? '1rem' : 0}
            alignItems={isDesktop ? 'flex-start' : 'center'}
          >
            <Heading variant="h3" align="center">
              {lawyerFirstName} {lawyerLastName}
            </Heading>

            {firm && (
              <Text
                size="xs"
                variant="text"
                weight="medium"
                sx={{ mt: '0.1rem' }}
                color={themeColorTypes.ACCENT_1}
                colorVariant={themeColorVariants.DARK}
              >
                {firm}
              </Text>
            )}
            <Text
              size="xs"
              variant="text"
              color={themeColorTypes.ACCENT_1}
              colorVariant={themeColorVariants.DARK}
            >
              {city}, {province}
            </Text>
          </Box>
        </Box>

        <LawyerForm lawyer={lawyer} />
      </Box>
    </>
  )
}

export default FlatFeeLawyerContactForm
