import isHoverDefinition from './isHoverDefinition'
import isTranslateObj from './isTranslateObj'

export default (incoming: any) =>
  Array.isArray(incoming) &&
  incoming.length > 0 &&
  incoming.every(
    (item) =>
      isHoverDefinition(item) ||
      typeof item === 'string' ||
      isTranslateObj(item),
  )
