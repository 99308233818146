import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import _isEmpty from 'lodash/isEmpty'

import { Button, Text } from 'grommet'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import listItemAnimation from '../../../../../common/animations/listItemAnimation'
import listQuestionReducer from '../../../utils/listQuestionReducer'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'
import Pet from './components/Pet'

const StyledButton = styled(Button)`
  height: 38px;
`
const newPet = () => ({ id: Date.now().toString() })

const Pets = ({
  question,
  answerCache,
  displayValidation,
  handleFieldFragmentUpdate,
}) => {
  const [field] = question.fields
  const initialState = answerCache.pets || [newPet()]

  const [pets, dispatch] = useReducer(listQuestionReducer, initialState)

  useEffect(() => {
    handleFieldFragmentUpdate({
      [field.name]: pets,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pets])

  const { t } = useTranslation()

  return (
    <div className="max-w-sm w-full">
      <div className="mb-3 flex justify-center">
        <ValidationMessage question={question} field={field} />
      </div>

      <AnimatePresence initial={false}>
        {!_isEmpty(pets) &&
          pets.map((pet) => (
            <motion.div key={pet.id} {...listItemAnimation()}>
              <Pet
                pet={pet}
                displayValidation={displayValidation}
                multiplePets={pets.length > 1}
                update={(updatedPet) => {
                  dispatch({ type: 'UPDATE', payload: updatedPet })
                }}
                remove={(removedPetId) => {
                  dispatch({ type: 'REMOVE', payload: removedPetId })
                }}
              />
            </motion.div>
          ))}
      </AnimatePresence>

      <div className="w-full flex justify-center mb-8">
        <StyledButton
          primary
          color="neutral-3"
          data-testid="add-another-pet-button"
          onClick={() => dispatch({ type: 'ADD', payload: newPet() })}
          label={
            <div className="text-brand">
              <Text size="small" weight="bold">
                <span className="mr-1">+</span>{' '}
                {t('customQuestion:Pets.addAnotherPet')}
              </Text>
            </div>
          }
        />
      </div>
    </div>
  )
}

Pets.propTypes = {
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  answerCache: PropTypes.shape({
    pets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  displayValidation: PropTypes.bool.isRequired,
}

export default Pets
