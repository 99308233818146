import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import CheckIcon from '@mui/icons-material/Check'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { LinearProgress } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { Heading, Paragraph } from 'grommet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import HighlightPill from '../../../../../../common/components/molecules/HighlightPill'
import strToKebab from '../../../../../../common/formatting/strToKebab'
import useInjectDynamic from '../../../../../../common/hooks/useInjectDynamic'
import useProvinceDictionary from '../../../../../../common/hooks/useProvinceDictionary'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import navigationActions from '../../../../../Questionnaire/ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import SectionCardButton from './SectionCardButton'

const StyledBrandCircle = styled.div`
  width: 21px;
  height: 21px;
`
const sectionStatusStuff = ({
  sectionTime,
  totalRequired,
  totalOptional,
  unansweredRequired,
  unansweredOptional,
}) => {
  // locked
  if (totalRequired === 0 && totalOptional === 0) {
    return {
      icon: <LockOutlinedIcon sx={{ fontSize: 25 }} />,
      highlightPillLabel: '',
      status: 'locked',
    }
  }
  // optional
  if (
    totalRequired === 0 &&
    totalOptional > 0 &&
    totalOptional === unansweredOptional
  ) {
    return {
      icon: (
        <StyledBrandCircle className="rounded-full bg-brand flex items-center justify-center">
          <CheckIcon sx={{ fill: 'white', fontSize: 18 }} />
        </StyledBrandCircle>
      ),
      highlightPillLabel: {
        tKey: 'dashboard:components.SectionCard.highlightPills.optional.label',
      },
      status: 'optional',
    }
  }
  // unstarted
  if (
    totalRequired === unansweredRequired &&
    totalOptional === unansweredOptional
  ) {
    return {
      icon: (
        <StyledBrandCircle className="rounded-full border-2 border-solid border-brand" />
      ),
      highlightPillLabel: sectionTime,
      status: 'unstarted',
    }
  }
  // complete
  if (
    (totalRequired > 0 && unansweredRequired === 0) ||
    (totalRequired === 0 && totalOptional > unansweredOptional)
  ) {
    return {
      icon: (
        <StyledBrandCircle className="rounded-full bg-brand flex items-center justify-center">
          <CheckIcon sx={{ fill: 'white', fontSize: 18 }} />
        </StyledBrandCircle>
      ),
      highlightPillLabel: {
        tKey: 'dashboard:components.SectionCard.highlightPills.complete.label',
      },
      status: 'complete',
    }
  }
  // in progress
  return {
    icon: (
      <StyledBrandCircle className="rounded-full bg-brand flex items-center justify-center">
        <MoreHorizIcon sx={{ fill: 'white', fontSize: 19 }} />
      </StyledBrandCircle>
    ),
    highlightPillLabel: {
      tKey: 'dashboard:components.SectionCard.highlightPills.inProgress.label',
    },
    status: 'inProgress',
  }
}

const SectionCard = ({ answerStore, label, data, dispatchToQuestion }) => {
  const { t } = useTranslation()
  const { provinceLanguageTranslate } = useProvinceDictionary()
  const { resolveValue } = useInjectDynamic()

  const {
    sectionTime,
    description,
    totalRequired,
    totalOptional,
    unansweredRequired,
    unansweredOptional,
    nextQuestion,
    sectionIntroQuestion,
  } = data

  // If tKey exists on sectionTime, translate it, otherwise, return sectionTime as is
  const translatedSectionTime = t(_get(sectionTime, 'tKey', sectionTime))
  const { province } = answerStore

  const { icon, highlightPillLabel, status } = sectionStatusStuff({
    totalRequired,
    totalOptional,
    unansweredRequired,
    unansweredOptional,
    sectionTime: translatedSectionTime,
  })

  const tKey = Object.values(CONSTANTS.questionnaireSectionTypes).find(
    ({ id }) => id === label,
  )?.tKey

  return (
    <>
      {/* Do not show untriggereed sections, except for the POA sections */}
      {!(
        totalRequired === 0 &&
        totalOptional === 0 &&
        label &&
        !label.includes('POA')
      ) && (
        <div
          data-testid={`section-card-${strToKebab(label)}`}
          className="p-5 md:p-4 lg:p-5 bg-white mb-2 rounded-lg"
        >
          <div
            data-testid={`section-card-${strToKebab(label)}-pill`}
            className="mb-4"
          >
            {highlightPillLabel && (
              <HighlightPill
                label={t(_get(highlightPillLabel, 'tKey', highlightPillLabel))}
              />
            )}
          </div>

          <div className="flex flex-col mb-3">
            <div className="flex items-center">
              <div className="mr-2">{icon}</div>
              <Heading
                data-testid="section-card-label"
                level={4}
                size="smedium"
              >
                {provinceLanguageTranslate(tKey, label, province)}
              </Heading>
            </div>

            {status === 'inProgress' && (
              <div className="mt-4 mb-1">
                <LinearProgress
                  sx={{
                    height: '0.35rem',
                    borderRadius: '9999px',
                    backgroundColor:
                      themeColors[themeColorTypes.GREY][
                        themeColorVariants.LIGHTER
                      ],
                    '& .MuiLinearProgress-bar1Determinate': {
                      borderRadius: '9999px',
                    },
                  }}
                  variant="determinate"
                  value={
                    ((totalRequired - unansweredRequired) / totalRequired) * 100
                  }
                />
              </div>
            )}

            {description && (
              <div className="mt-3">
                <Paragraph size="smedium">
                  {resolveValue(description)}
                </Paragraph>
              </div>
            )}
          </div>

          {nextQuestion && (
            <div className="mt-5">
              <SectionCardButton
                label={label}
                dispatchToQuestion={dispatchToQuestion}
                sectionIntroQuestion={sectionIntroQuestion}
                nextQuestion={nextQuestion}
                status={status}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

SectionCard.propTypes = {
  data: PropTypes.shape({
    sectionTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    totalRequired: PropTypes.number,
    totalOptional: PropTypes.number,
    unansweredRequired: PropTypes.number,
    unansweredOptional: PropTypes.number,
    nextQuestion: PropTypes.shape({}),
    sectionIntroQuestion: PropTypes.shape({}),
    description: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
  }).isRequired,
  label: PropTypes.string.isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchToQuestion: ({ question }) =>
    dispatch(
      navigationActions.toQuestion({
        question,
        fromDashboard: true,
        questionId: undefined,
      }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SectionCard)
