import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import _isEmpty from 'lodash/isEmpty'
import _toString from 'lodash/toString'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { CONSTANTS, utils } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Balloon } from '../../../../../../common/assets/images/balloon.svg'
import Button from '../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import addOnsActions from '../../../../ducks/addOns/addOnsActions'
import addOnsSelectors from '../../../../ducks/addOns/addOnsSelectors'
import paymentsSelectors from '../../../../ducks/payments/paymentsSelectors'
import hasCoreProduct from '../../../../utils/hasCoreProduct'

const PrintingAddOn = ({
  payments = [],
  addOnCart = [],
  answerStore,
  dispatchAddAddOnToCart,
  dispatchRemoveAddOnFromCart,
}) => {
  const navigate = useNavigate()

  let coreProduct
  if (!_isEmpty(payments)) {
    coreProduct = hasCoreProduct(payments)
  }

  const { includePartner } = answerStore
  const cartContainsPrintingAddOn = utils.containsAddOn({
    addOns: addOnCart,
    addOnType: CONSTANTS.addOnTypes.PRINTING.type,
  })

  const isCouplesAddOn =
    includePartner && includePartner === 'yes' && !coreProduct

  useEffect(() => {
    amplitude.sendEvent('ViewedAddOns', {
      isCouplesAddOn,
      coreProduct: coreProduct || 'None',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          maxWidth: '40rem',
          textAlign: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ mb: '1.5rem', width: '16rem' }}>
          <Balloon />
        </Box>

        <Box sx={{ mb: '0.75rem' }}>
          <Heading variant="h3">{t('pages:PrintingAddOn.label')}</Heading>
        </Box>

        <Text variant="paragraph" size="lg">
          {isCouplesAddOn
            ? t('pages:PrintingAddOn.subLabel.case1', {
                partnerFirstName: answerStore.partnerFirstName,
              })
            : t('pages:PrintingAddOn.subLabel.case2')}
        </Text>
      </Box>

      <Box
        sx={{
          mt: '3rem',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Button
          size="md"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          color={themeColorTypes.ACCENT_1}
          data-testid="add-on-no-thanks-button"
          label={t('pages:PrintingAddOn.primaryButtonLabel')}
          onClick={() => {
            amplitude.sendEvent('SkippedPrinting', {
              isCouplesAddOn,
              coreProduct: coreProduct || 'None',
            })

            if (cartContainsPrintingAddOn) {
              dispatchRemoveAddOnFromCart({
                type: CONSTANTS.addOnTypes.PRINTING.type,
              })
            }

            if (coreProduct) {
              navigate('/dashboard/documents')
            } else {
              navigate('/dashboard/payment')
            }
          }}
        />

        <Box sx={{ mt: '1rem' }}>
          <Button
            size="xs"
            endIcon={<ArrowForwardIcon />}
            data-testid="add-on-page-add-printing-button"
            label={
              isCouplesAddOn
                ? t('pages:PrintingAddOn.secondaryButtonLabel.case1', {
                    amount: _toString(
                      CONSTANTS.addOnTypes.PRINTING.price.couples,
                    ),
                  })
                : t('pages:PrintingAddOn.secondaryButtonLabel.case2', {
                    amount: _toString(
                      CONSTANTS.addOnTypes.PRINTING.price.individual,
                    ),
                  })
            }
            onClick={() => {
              amplitude.sendEvent('SelectedPrinting', {
                isCouplesAddOn,
                coreProduct: coreProduct || 'None',
              })

              if (!cartContainsPrintingAddOn) {
                dispatchAddAddOnToCart({
                  type: CONSTANTS.addOnTypes.PRINTING.type,
                  amount: 1,
                })
              }
              navigate('/dashboard/payment')
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

PrintingAddOn.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.shape({})),
  dispatchAddAddOnToCart: PropTypes.func.isRequired,
  dispatchRemoveAddOnFromCart: PropTypes.func.isRequired,
  answerStore: PropTypes.shape({
    province: PropTypes.string,
    includePartner: PropTypes.string,
    partnerFirstName: PropTypes.string,
  }).isRequired,
  addOnCart: PropTypes.arrayOf(PropTypes.shape()),
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  payments: paymentsSelectors.getPayments(state.dashboard),
  addOnCart: addOnsSelectors.getAddOnCart(state.dashboard),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchAddAddOnToCart: ({ type, amount }) =>
    dispatch(addOnsActions.addAddOnToCart({ type, amount })),
  dispatchRemoveAddOnFromCart: ({ type }) =>
    dispatch(addOnsActions.removeAddOnFromCart({ type })),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrintingAddOn)
