import React, { useEffect, useState } from 'react'

import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Checkbox from '../../../../../../../common/components/atoms/Checkbox'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import useUpdateToggles from '../../../../../../../common/hooks/useUpdateToggles'
import amplitude from '../../../../../../../common/lib/amplitude'

interface Props {
  show: boolean
  token: string
  onClose: () => void
  questionnaireId: string
}

const SignReminderModal = ({
  show,
  token,
  onClose,
  questionnaireId,
}: Props) => {
  const { t } = useTranslation()

  const { mutate: handleUpdateToggles } = useUpdateToggles()

  const [agree, setAgree] = useState(false)

  useEffect(() => {
    if (show) amplitude.sendEvent('ViewedSignReminderModal')
  }, [show])

  return (
    <Modal
      show={show}
      preventClose
      maxWidth="sm"
      onClose={onClose}
      data-testid="sign-reminder-modal"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ fontSize: '3.3rem' }}>
            <AssignmentTurnedInOutlinedIcon fontSize="inherit" />
          </Box>

          <Box sx={{ mt: '0.6rem', mb: '1.25rem', textAlign: 'center' }}>
            <Heading variant="h4">
              {t('components:modal.SignReminderModal.label')}
            </Heading>
          </Box>
        </Box>

        <Text align="center" sx={{ display: 'initial' }}>
          {t('components:modal.SignReminderModal.subLabel')}
        </Text>

        <Box sx={{ mt: '1.9rem', display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            checked={agree}
            label={t('common:iUnderstand')}
            onChange={() => {
              amplitude.sendEvent('CheckedSignReminder')
              setAgree((prev) => !prev)
              handleUpdateToggles({
                token,
                questionnaireId,
                toggleOn: [CONSTANTS.toggleTypes.HAS_ACCEPTED_SIGN_REMINDER],
              })
              onClose()
            }}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default SignReminderModal
