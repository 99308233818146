import _filter from 'lodash/filter'
import _groupBy from 'lodash/groupBy'
import _has from 'lodash/has'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isNumber from 'lodash/isNumber'
import _isString from 'lodash/isString'
import _toNumber from 'lodash/toNumber'

import { differenceInYears } from 'date-fns'

import { ageOfMajority } from '../../../../common/utils/provinces'
import { dateRegex, emailRegex } from './patterns'
import filterValidValues from './utils/filterValidValues'
import questionHasForbiddenTerm from './utils/questionHasForbiddenTerm'

const SPECIAL_VALIDATION_QUESTIONS = new Set([
  'name',
  'children',
  'dateOfBirth',
  'gifts',
  'pets',
  'charitableGiftDetails',
  'remoteDistribution',
  'predeceased',
  'predeceasedBackup',
  'stepChildrenDistribution',
  'corporateExecutorFeeAgreement',
])
const SPECIAL_VALIDATION_FIELDS = new Set([
  'children',
  'stepChildren',
  'charitableGiftDetails',
])

export const validators = {
  // --- question validators ---//
  corporateExecutorFeeAgreementQuestion(questionFragments) {
    return (
      questionFragments.hasCheckedCorpExecFeeAgreement === true ||
      questionFragments.corpTrusteeFees === 'no' ||
      questionFragments.corpCoTrusteeFees === 'no' ||
      questionFragments.corpAltTrusteeFees === 'no' ||
      questionFragments.nonPartnerCorpAltTrusteeFees === 'no'
    )
  },

  nameQuestion(questionFragments) {
    return !!(
      !questionFragments.hasCommonName || questionFragments.commonlyKnownAsName
    )
  },

  childrenQuestion(questionFragments, answerCache) {
    return !(
      _isEmpty(answerCache.children) &&
      _isEmpty(answerCache.stepChildren) &&
      _isEmpty(questionFragments)
    )
  },

  dateOfBirthQuestion(questionFragments, answerCache) {
    const { dateOfBirth } = questionFragments
    const { province } = answerCache

    const yearsOld = differenceInYears(new Date(), new Date(dateOfBirth))

    return !!(
      (!_isEmpty(province) &&
        !_isEmpty(dateOfBirth) &&
        _isNumber(yearsOld) &&
        !!dateRegex.test(dateOfBirth) &&
        yearsOld >= ageOfMajority(province)) ||
      (answerCache.dateOfBirth && _isEmpty(dateOfBirth))
    )
  },

  giftsQuestion(question, questionFragments, answerCache) {
    // check if questionFragments contains a non-empty giftDetails array
    // AND
    // check if there is at least one invalid gift object
    const invalidGifts =
      !_isEmpty(questionFragments.giftDetails) &&
      !_isEmpty(
        questionFragments.giftDetails.find(
          (gift) =>
            _isEmpty(gift.description) ||
            _isEmpty(gift.recipient) ||
            _isEmpty(gift.relationship),
        ),
      )

    const hasForbiddenTerm = questionHasForbiddenTerm({
      question,
      validators,
      forbiddenKeys: ['description'],
      fragments: questionFragments.giftDetails,
    })

    if (
      hasForbiddenTerm ||
      (_isEmpty(answerCache.giftDetails) &&
        _isEmpty(questionFragments.giftDetails))
    ) {
      return false
    }

    return (
      /*
       * this first condition will be true if user
       * returns to the question after previously answering
       */
      (!_isEmpty(answerCache.giftDetails) &&
        _isEmpty(questionFragments.giftDetails)) ||
      !invalidGifts
    )
  },

  petsQuestion(questionFragments, answerCache) {
    // check if questionFragments contains a non-empty pets array
    // AND
    // check if there is at least one invalid pet object
    const invalidPets =
      !_isEmpty(questionFragments.pets) &&
      !_isEmpty(
        questionFragments.pets.find(
          (pet) => _isEmpty(pet.name) || _isEmpty(pet.type),
        ),
      )

    if (_isEmpty(answerCache.pets) && _isEmpty(questionFragments.pets)) {
      return false
    }

    return (
      /*
       * this first condition will be true if user
       * returns to the question after previously answering
       */
      (!_isEmpty(answerCache.pets) && _isEmpty(questionFragments.pets)) ||
      !invalidPets
    )
  },

  charitableGiftDetailsQuestion(questionFragments, answerCache) {
    // check if questionFragments contains non-empty charitableGiftDetails array
    // AND
    // check if there is at least one invalid charitableGiftDetails object
    const invalidCharitableGiftDetails =
      !_isEmpty(questionFragments.charitableGiftDetails) &&
      !_isEmpty(
        questionFragments.charitableGiftDetails.find(
          (gift) => _isEmpty(gift.name) || _isEmpty(gift.amount),
        ),
      )

    const percentageDonationSum =
      questionFragments.charitableGiftDetails?.reduce(
        (total, current) => total + (current.isPercent ? +current.amount : 0),
        0,
      )

    if (
      _isEmpty(answerCache.charitableGiftDetails) &&
      _isEmpty(questionFragments.charitableGiftDetails)
    ) {
      return false
    }

    if (percentageDonationSum > 100) {
      return false
    }

    return (
      /*
       * this first condition will be true if user
       * returns to the question after previously answering
       */
      (!_isEmpty(answerCache.charitableGiftDetails) &&
        _isEmpty(questionFragments.charitableGiftDetails)) ||
      !invalidCharitableGiftDetails
    )
  },

  remoteDistributionQuestion(questionFragments, answerCache) {
    // check if questionFragments contains a non-empty remoteDistribution array
    // AND
    // check if there is at least one invalid beneficiary object
    const invalidBeneficiary =
      !_isEmpty(questionFragments.remoteDistribution) &&
      !_isEmpty(
        questionFragments.remoteDistribution.find(
          (b) =>
            _isEmpty(b.type) ||
            _isEmpty(b.name) ||
            _isEmpty(b.relationship) ||
            _isEmpty(b.portions) ||
            _isEmpty(b.percentage),
        ),
      )

    if (
      _isEmpty(answerCache.remoteDistribution) &&
      _isEmpty(questionFragments.remoteDistribution)
    ) {
      return false
    }

    return (
      /*
       * this first condition will be true if user
       * returns to the question after previously answering
       */
      (!_isEmpty(answerCache.remoteDistribution) &&
        _isEmpty(questionFragments.remoteDistribution)) ||
      !invalidBeneficiary
    )
  },

  predeceasedQuestion(questionFragments, answerCache) {
    const allHumanBeneficiariesHaveSplit = (obj) => {
      if (_isEmpty(obj) || _isEmpty(obj.remoteDistribution)) {
        return false
      }

      return obj.remoteDistribution.every(
        (b) => b.predeceasedSplit || b.type === 'charity',
      )
    }

    if (
      _isEmpty(answerCache.predeceased) &&
      _isEmpty(questionFragments.remoteDistribution)
    ) {
      return false
    }

    return (
      /*
       * this first condition will be true if user
       * returns to the question after previously answering
       */

      (!!answerCache.predeceased &&
        !_isEmpty(answerCache.remoteDistribution) &&
        allHumanBeneficiariesHaveSplit(answerCache) &&
        _isEmpty(questionFragments.remoteDistribution)) ||
      !!(
        allHumanBeneficiariesHaveSplit(questionFragments) &&
        questionFragments.predeceased
      )
    )
  },

  predeceasedBackupQuestion(questionFragments) {
    if (!_isEmpty(questionFragments.remoteDistribution)) {
      const [beneficiary] = questionFragments.remoteDistribution

      const { predeceasedSplit, alternateName, alternateRelationship } =
        beneficiary

      if (
        predeceasedSplit === 'splitChildren' ||
        predeceasedSplit === 'skip' ||
        (alternateName && alternateRelationship)
      ) {
        return true
      }
      return false
    }

    return false
  },

  stepChildrenDistributionQuestion(questionFragments, answerCache) {
    const allStepsHaveDistribution = (obj) => {
      if (_isEmpty(obj) || _isEmpty(obj.stepChildren)) {
        return false
      }

      return obj.stepChildren.every((stepChild) => stepChild.distribution)
    }

    return (
      /*
       * this first condition will be true if user
       * returns to the question after previously answering
       */
      (!!answerCache.stepChildrenDistribution &&
        !_isEmpty(answerCache.stepChildren) &&
        allStepsHaveDistribution(answerCache) &&
        !!(
          !!allStepsHaveDistribution(questionFragments) &&
          questionFragments.stepChildrenDistribution
        )) ||
      !!(
        !!allStepsHaveDistribution(questionFragments) &&
        questionFragments.stepChildrenDistribution
      )
    )
  },

  // --- field validators ---//
  isBoolean(fieldValue) {
    return typeof fieldValue === 'boolean'
  },

  children(fieldValue) {
    let invalidChildren = []

    if (!_isEmpty(fieldValue)) {
      invalidChildren = fieldValue.filter(
        (childObj) => _isEmpty(childObj.name) || !this.date(childObj.dob),
      )
    }

    return _isEmpty(invalidChildren)
  },

  charitableGiftDetailsField(fieldValue) {
    if (!fieldValue || _isEmpty(fieldValue) || !_isArray(fieldValue)) {
      return false
    }

    return fieldValue.every(
      ({ type, name, amount, id, relationship }) =>
        id &&
        name &&
        amount &&
        type === 'charity' &&
        relationship === 'charitableOrg',
    )
  },

  exists(fieldValue) {
    return !_isEmpty(fieldValue)
  },

  forbiddenTerms(fieldValue = '', forbiddenTerms) {
    if (_isEmpty(forbiddenTerms)) {
      return {}
    }

    const theForbiddenTerms = _filter(forbiddenTerms, ({ terms }) =>
      terms.some((term) =>
        fieldValue.toLowerCase().includes(term.toLowerCase()),
      ),
    )

    /*
     * For some forbidden terms, we just want to warn the user that
     * they're doing something dangerous, but still let them proceed.
     *
     * For other forbidden terms, we prevent the user from continuing on.
     *
     * RETURNS an object, with properties: 'allow' and 'reject'.
     * Each property has an array of the forbidden terms that match the grouping
     */
    const groupedForbiddenTerms = _groupBy(
      theForbiddenTerms,
      ({ allow = false }) => (allow ? 'allow' : 'reject'),
    )

    return groupedForbiddenTerms
  },

  email(fieldValue) {
    return !!(fieldValue && emailRegex.test(fieldValue))
  },

  password(fieldValue) {
    return fieldValue && fieldValue.length >= 8
  },

  number(fieldValue) {
    const regex = /^\d+$/

    return fieldValue && fieldValue.match(regex)
  },

  validOption(fieldOptions, fieldValue) {
    return fieldOptions.some((o) => o.value === fieldValue)
  },

  emptyAllowed(fieldOptions, fieldValue) {
    if (!_isArray(fieldValue)) return false

    const currentValidValues = filterValidValues(fieldOptions, fieldValue)

    return (
      // can be empty
      _isEmpty(currentValidValues) ||
      // if not empty, all values in array must be from fieldOptions
      (!_isEmpty(currentValidValues) &&
        currentValidValues.every((item) =>
          fieldOptions.find((opt) => opt.value === item),
        ))
    )
  },

  range(fieldValue, min, max) {
    return (
      /*
       * fieldValue should be a string, but gets converted to a number
       * in order to compare again min and max
       */
      _isString(fieldValue) &&
      _toNumber(fieldValue) >= min &&
      _toNumber(fieldValue) <= max
    )
  },

  date(fieldValue) {
    return fieldValue && fieldValue.match(dateRegex)
  },
}

const questionValidation = (question, questionFragments, answerCache) => {
  switch (question.type) {
    case 'name':
      return validators.nameQuestion(questionFragments)

    case 'children':
      return validators.childrenQuestion(questionFragments, answerCache)

    case 'dateOfBirth':
      return validators.dateOfBirthQuestion(questionFragments, answerCache)

    case 'gifts':
      return validators.giftsQuestion(question, questionFragments, answerCache)

    case 'pets':
      return validators.petsQuestion(questionFragments, answerCache)

    case 'charitableGiftDetails':
      return validators.charitableGiftDetailsQuestion(
        questionFragments,
        answerCache,
      )

    case 'remoteDistribution':
      return validators.remoteDistributionQuestion(
        questionFragments,
        answerCache,
      )

    case 'predeceased':
      return validators.predeceasedQuestion(questionFragments, answerCache)

    case 'predeceasedBackup':
      return validators.predeceasedBackupQuestion(questionFragments)

    case 'stepChildrenDistribution':
      return validators.stepChildrenDistributionQuestion(
        questionFragments,
        answerCache,
      )

    case 'corporateExecutorFeeAgreement':
      return validators.corporateExecutorFeeAgreementQuestion(questionFragments)

    default:
      return true
  }
}

export const fieldValidation = (field, fieldValue) => {
  const switchVal = SPECIAL_VALIDATION_FIELDS.has(field.name)
    ? field.name
    : field.type

  switch (switchVal) {
    // special validation fields
    case 'children':
    case 'stepChildren':
      return validators.children(fieldValue)

    case 'charitableGiftDetails':
      return validators.charitableGiftDetailsField(fieldValue)

    // standard fields
    case 'text': {
      const forbiddenTerms = validators.forbiddenTerms(
        fieldValue,
        field.forbiddenTerms || [],
      )

      return (
        validators.exists(fieldValue) &&
        /*
         * If the forbiddenTerms object is empty, we consider it valid
         *
         * But if the forbiddenTerms object is not empty, we want to check
         * if it has the property 'reject', because that contains terms that
         * we want to prevent the user from using.
         * The forbiddenTerms object may also contain the property, 'allow', but
         * those are terms that we will warn the user about, but will not affect
         * the field's validation.
         */
        (_isEmpty(forbiddenTerms) || !_has(forbiddenTerms, 'reject'))
      )
    }

    case 'email':
      return validators.email(fieldValue)

    case 'password':
      return validators.password(fieldValue)

    case 'number':
      return validators.number(fieldValue)

    case 'radio':
    case 'select':
    case 'comboBox':
    case 'quickRadio':
      return validators.validOption(field.options, fieldValue)

    case 'multiSelect':
      return validators.emptyAllowed(field.options, fieldValue)

    case 'range':
      return validators.range(fieldValue, field.min, field.max)

    case 'date':
      return validators.date(fieldValue)

    case 'checkbox':
      return validators.isBoolean(fieldValue)

    case 'preventCleanse':
      return true

    default:
      return validators.exists(fieldValue)
  }
}

const questionValidated = (question, questionFragments, answerCache) => {
  const isSpecialValidatedQuestion = SPECIAL_VALIDATION_QUESTIONS.has(
    question.type,
  )

  if (isSpecialValidatedQuestion) {
    return questionValidation(question, questionFragments, answerCache)
      ? []
      : [`${question.type}-QUESTION`]
  }

  return []
}

export const isFieldValidOrOptional = (field, fieldValue) => {
  const isOptional = !!field.optional
  return isOptional || fieldValidation(field, fieldValue)
}

const validate = (question, questionFragments, answerCache) => {
  // if question exists and it has fields
  if (!_isEmpty(question) && !_isEmpty(question.fields)) {
    const fragments = _isEmpty(questionFragments)
      ? answerCache
      : { ...answerCache, ...questionFragments }

    // returns an array of the question's invalid fields
    const invalidFields = question.fields.filter(
      (f) => !isFieldValidOrOptional(f, fragments[f.name]),
    )

    // returns an array of the invalid question
    const invalidQuestion = questionValidated(
      question,
      questionFragments,
      answerCache,
    )

    return [...invalidFields.map((f) => f.name), ...invalidQuestion]
  }
  return undefined
}

export { validate }
