// TS-TODO: type this file properly
import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { connect } from 'react-redux'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import WarningIcon from '@mui/icons-material/Warning'
import { Box } from '@mui/material'

import { BonusDocType, CONSTANTS } from '@epilogue/common'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../common/components/atoms/Text'
import commonActions from '../../../../../../../common/ducks/commonActions'
import commonSelectors from '../../../../../../../common/ducks/commonSelectors'
import useInjectDynamic from '../../../../../../../common/hooks/useInjectDynamic'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../common/request'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import Field from '../../../../../../Questionnaire/components/Field/Field'
import HoverDefinition from '../../../../../../Questionnaire/components/molecules/HoverDefinition'
import questionnaireActions from '../../../../../../Questionnaire/ducks/questionnaire/questionnaireActions'
import questionnaireSelectors from '../../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import bonusDocsActions from '../../../../../ducks/bonusDocs/bonusDocsActions'
import bonusDocsSelectors from '../../../../../ducks/bonusDocs/bonusDocsSelectors'
import documentsActions from '../../../../../ducks/documents/documentsActions'
import bonusDocData from './data/bonusDocData'
import {
  groupByUpsertAnswerStore,
  triggeredAndCleansed,
  triggeredCoreQuestions,
  unansweredRequiredFields,
} from './utils/bonusQuestionnaireEngine'

// TS-TODO: type this properly
type AnswerStore = Record<string, any>

interface Props {
  docType: BonusDocType
  referrer: any
  charityPartner: any
  onClose: () => void
  dispatchFetchDocuments: () => void
  coreAnswerStore: AnswerStore
  onWorking: (working: boolean) => void
  bonusDocsAnswerStore: AnswerStore
  dispatchUpdateBonusDocsAnswerStoreType: ({
    docType,
    fragment,
  }: {
    docType: string
    fragment: AnswerStore
  }) => void
  dispatchUpdateToast: (toastProperties: any) => void
  dispatchUpdateAnswerStore: (incomingAnswerStoreFragments: AnswerStore) => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const BonusDocQuestionnaire = ({
  docType,
  onClose,
  referrer,
  onWorking,
  charityPartner,
  coreAnswerStore,
  dispatchUpdateToast,
  bonusDocsAnswerStore,
  dispatchFetchDocuments,
  dispatchUpdateAnswerStore,
  dispatchSetGlobalErrorMessage,
  dispatchUpdateBonusDocsAnswerStoreType,
}: Props) => {
  const { t } = useTranslation()
  const { resolveValue } = useInjectDynamic()
  const { questions, description, subLabel } = bonusDocData[docType]

  /*
   * This will run once on-mount to produce necessary initial data.
   *
   * The questions in initialTriggeredCoreQuestions, if trigged
   * initially, will never be untriggered.
   */
  const {
    initialTriggeredQuestions,
    initialCleansedAnswerStore,
    initialTriggeredCoreQuestions,
  } = useMemo(() => {
    const theInitialTriggeredCoreQuestions = triggeredCoreQuestions({
      questions,
      coreAnswerStore,
    })

    const { triggeredQuestions, cleansedAnswerStore } = triggeredAndCleansed({
      questions,
      initialTriggeredCoreQuestions: theInitialTriggeredCoreQuestions,
      testObj: {
        referrer,
        charityPartner,
        ...coreAnswerStore,
        ...bonusDocsAnswerStore[docType],
      },
    })

    return {
      initialTriggeredQuestions: triggeredQuestions,
      initialCleansedAnswerStore: cleansedAnswerStore,
      initialTriggeredCoreQuestions: theInitialTriggeredCoreQuestions,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [working, setWorking] = useState(false)
  const [invalidFields, setInvalidFields] = useState<any[]>([])
  const [displayValidation, setDisplayValidation] = useState(false)
  const [localAnswerStore, setLocalAnswerStore] = useState(
    initialCleansedAnswerStore,
  )

  const [theTriggeredQuestions, setTheTriggeredQuestions] = useState(
    initialTriggeredQuestions,
  )

  useEffect(() => onWorking?.(working), [working, onWorking])

  useEffect(() => {
    setInvalidFields(
      unansweredRequiredFields(theTriggeredQuestions, localAnswerStore),
    )
  }, [localAnswerStore, theTriggeredQuestions])

  useEffect(() => {
    if (displayValidation && _isEmpty(invalidFields)) {
      setDisplayValidation(false)
    }
  }, [displayValidation, invalidFields])

  const handleFieldUpdate = (fragment: any) => {
    const { triggeredQuestions, cleansedAnswerStore } = triggeredAndCleansed({
      questions,
      initialTriggeredCoreQuestions,
      testObj: {
        referrer,
        charityPartner,
        ...coreAnswerStore,
        ...localAnswerStore,
        ...fragment,
      },
    })

    setLocalAnswerStore(cleansedAnswerStore)
    setTheTriggeredQuestions(triggeredQuestions)
  }

  const { mutate: handleGenerate } = useMutation({
    mutationFn: ({
      groupedCoreAnswerStore,
      groupedBonusDocsAnswerStore,
    }: {
      groupedCoreAnswerStore: AnswerStore
      groupedBonusDocsAnswerStore: AnswerStore
    }) =>
      request({
        method: 'POST',
        timeout: 30000, // 30 second timeout
        url: PATHS.DOCUMENT_GENERATE_DOC,
        data: {
          docType,
          bonusDocsAnswerStore: {
            ...bonusDocsAnswerStore,
            [docType]: groupedBonusDocsAnswerStore,
          },
          answerStore: { ...coreAnswerStore, ...groupedCoreAnswerStore },
        },
      }),

    retry: process.env.REACT_APP_ENV === 'production' ? 2 : 0,
    onMutate: () => setWorking(true),
    onSettled: () => setWorking(false),
    onError: (error) => {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage: t(
          'bonusDocs:BonusDocQuestionnaire.fallbackErrorMessage',
        ),
        onError: (resolvedErrorMessage: string) => {
          dispatchSetGlobalErrorMessage(resolvedErrorMessage)
        },
      })
    },
  })

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '675px',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ mb: '3.5rem' }}>
        {description && (
          <Heading align="center" variant="h2">
            {t(_get(description, 'tKey'))}
          </Heading>
        )}

        {subLabel && (
          <Text sx={{ mt: '1.15rem' }} align="center" variant="paragraph">
            {t(_get(subLabel, 'tKey'))}
          </Text>
        )}
      </Box>

      <>
        {theTriggeredQuestions.map((question: any) => (
          <Box
            key={question.id}
            sx={{
              py: '2.9rem',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ mb: '1.7rem' }}>
              {question.label && (
                <Heading align="center" variant="h4">
                  <HoverDefinition
                    incoming={
                      resolveValue(question.label) as Array<string | object>
                    }
                  />
                </Heading>
              )}
              {question.subLabel && (
                <Text align="center" variant="paragraph" sx={{ mt: '0.75rem' }}>
                  {resolveValue(question.subLabel)}
                </Text>
              )}
            </Box>

            {question.fields.map((field: any) => (
              <Fragment key={field.name}>
                <Field
                  field={field}
                  answerStoreOverride={localAnswerStore}
                  handleFieldFragmentUpdate={handleFieldUpdate}
                  isInvalidOverride={
                    displayValidation && invalidFields.includes(field.name)
                  }
                />
              </Fragment>
            ))}
          </Box>
        ))}
      </>

      <Box
        sx={{
          mt: '1.3rem',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {displayValidation && (
          <Text
            align="center"
            data-testid="bonus-doc-questionnaire-validation-warning"
            color={themeColorTypes.RED}
            sx={{ mb: '1.8rem', display: 'flex', alignItems: 'center' }}
          >
            <WarningIcon fontSize="small" sx={{ mr: '0.4rem' }} />
            {t('bonusDocs:BonusDocQuestionnaire.validationMessage')}
          </Text>
        )}
        <Button
          size="lg"
          working={working}
          variant="contained"
          onClick={() => {
            if (_isEmpty(invalidFields)) {
              /*
               * Split the localAnswerStore into the fragments being sent to the
               * coreAnswerStore and the fragments being sent to the bonusDocsAnswerStore
               */
              const {
                coreAnswerStore: groupedCoreAnswerStore,
                bonusDocsAnswerStore: groupedBonusDocsAnswerStore,
              } = groupByUpsertAnswerStore(localAnswerStore)

              handleGenerate(
                { groupedCoreAnswerStore, groupedBonusDocsAnswerStore },
                {
                  onSuccess() {
                    // Update bonusDocsAnswerStore in Redux
                    dispatchUpdateBonusDocsAnswerStoreType({
                      docType,
                      fragment: groupedBonusDocsAnswerStore,
                    })

                    if (!_isEmpty(groupedCoreAnswerStore)) {
                      dispatchUpdateAnswerStore(groupedCoreAnswerStore)
                    }

                    // fetch all documents, including the newly created document
                    dispatchFetchDocuments()

                    dispatchUpdateToast({
                      type: CONSTANTS.toastTypes.SUCCESS,
                      label: t('bonusDocs:BonusDocQuestionnaire.toast.label'),
                      message: t(
                        'bonusDocs:BonusDocQuestionnaire.toast.message',
                      ),
                    })

                    // eslint-disable-next-line no-console
                    console.log(`Generation of ${docType} document success ✅`)
                    amplitude.sendEvent('GeneratedBonusDocument', { docType })
                  },
                },
              )
            } else {
              setDisplayValidation(true)
            }
          }}
          color={
            _isEmpty(invalidFields)
              ? themeColorTypes.BRAND
              : themeColorTypes.GREY
          }
          label={t('bonusDocs:BonusDocQuestionnaire.button1Label')}
          data-testid="bonus-doc-questionnaire-button"
          spinnerPadding="0.44rem 6rem"
          spinnerColor={themeColorTypes.GREY}
          spinnerColorVariant={themeColorVariants.DARK}
        />

        <Button
          size="xs"
          onClick={onClose}
          disabled={working}
          sx={{ mt: '0.95rem' }}
          label={t('bonusDocs:BonusDocQuestionnaire.button2Label')}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  charityPartner: commonSelectors.getCharityPartner(state),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
  bonusDocsAnswerStore: bonusDocsSelectors.getBonusDocsAnswerStore(
    state.dashboard,
  ),
  coreAnswerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchUpdateAnswerStore: (incomingAnswerStoreFragments: AnswerStore) =>
    dispatch(
      questionnaireActions.updateAnswerStore({
        fromBonusDocQuestionnaire: true,
        answerStoreFragment: incomingAnswerStoreFragments,
      }),
    ),
  dispatchUpdateBonusDocsAnswerStoreType: ({
    docType,
    fragment,
  }: {
    docType: string
    fragment: AnswerStore
  }) =>
    dispatch(
      bonusDocsActions.updateBonusDocsAnswerStoreType({
        fragment,
        type: docType,
      }),
    ),
  dispatchUpdateToast: (toastProperties: any) =>
    dispatch(commonActions.updateToast(toastProperties)),
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
  dispatchFetchDocuments: () => dispatch(documentsActions.fetchDocuments()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BonusDocQuestionnaire)
