import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import { useDispatch } from 'react-redux'

import _last from 'lodash/last'

import {
  Box,
  CircularProgress,
  List,
  Theme,
  useMediaQuery,
} from '@mui/material'

import { useVirtualizer } from '@tanstack/react-virtual'
import { useTranslation } from 'react-i18next'

import commonActions from '../../ducks/commonActions'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../styles/muiTheme'
import Modal from '../atoms/Modal'
import Text from '../atoms/Text'
import CharityFinderSearchBar from './components/CharityFinderSearchBar'
import CharityListItem from './components/CharityListItem'
import CustomCharityListItem from './components/CustomChartyListItem'
import LoadingListItem from './components/LoadingListItem'
import useCharityFinderQueryReducer from './hooks/useCharityFinderQueryReducer'
import useRecommendedCharityQuery, {
  RecommendedCharity,
} from './hooks/useRecommendedCharityQuery'

// Estimate the largest possible size, in px, of the list item element
// Partner's include a description so the size is larger
const estimateListItemSize = (data: RecommendedCharity[], index: number) =>
  data[index]?.description ? 230 : 115

interface CharityFinderProps {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  handleAddCharity: (
    charity: Pick<RecommendedCharity, 'name' | 'number'>,
  ) => void
}

const CharityFinder = ({
  show,
  setShow,
  handleAddCharity,
}: CharityFinderProps) => {
  const dispatch = useDispatch()
  const scrollableAreaRef = useRef<HTMLDivElement>(null)

  const [queryState, dispatchUpdateQuery] = useCharityFinderQueryReducer()

  const { query, province, category, queryInputValue } = queryState

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useRecommendedCharityQuery({
    query,
    province,
    category,

    dispatchSetGlobalErrorMessage: (errorMessage) =>
      dispatch(commonActions.setGlobalErrorMessage(errorMessage)),
  })
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const dataRows = useMemo(
    () => (data ? data.pages.map((d) => d.items).flat() : []),
    [data],
  )

  const virtualizer = useVirtualizer({
    count: dataRows.length + 1,
    getScrollElement: () => scrollableAreaRef.current,
    estimateSize: (index) => estimateListItemSize(dataRows, index),
    overscan: 10,
  })

  const virtualItems = virtualizer.getVirtualItems()

  useEffect(() => {
    const lastItem = _last(virtualItems)

    if (
      lastItem &&
      lastItem.index >= dataRows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage()
    }
  }, [
    dataRows.length,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    virtualItems,
  ])

  useEffect(() => {
    virtualizer.scrollToOffset(0)
  }, [query, province, category, virtualizer])

  const { t } = useTranslation()

  return (
    <Modal
      maxWidth="2xl"
      keepMounted
      show={show}
      onClose={() => setShow(false)}
      data-testid="charity-finder-modal"
      fullBleed
      sx={{
        // @ts-expect-error Custom theme option
        borderRadius: (theme: Theme) => theme.shape.borderRadiusLarge,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          width: '100%',
          py: { min: '1rem', md: '1rem', xl: '2.5rem' },
          px: { min: '1rem', md: '2rem', xl: '10rem' },
          backgroundColor:
            themeColors[themeColorTypes.NEUTRAL][themeColorVariants.LIGHTER],
        }}
      >
        <Text size="3xl" variant="display" align="center">
          {t('components:CharityFinder.heading')}
        </Text>
        <Text size="md" align="center">
          {t('components:CharityFinder.subheading')}
        </Text>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '2rem',
          pt: '1.5rem',
          rowGap: '1rem',
          width: '100vw',
          maxWidth: '100%',
          height: isMobile ? '100%' : undefined,
        }}
      >
        <CharityFinderSearchBar
          queryState={queryState}
          dispatchUpdateQuery={dispatchUpdateQuery}
        />
        <Box
          ref={scrollableAreaRef}
          sx={{
            height: isMobile ? '100%' : '30rem',
            width: '100%',
            overflowX: 'visible',
            overflowY: 'auto',
            borderTop: `solid 1px ${
              themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTER]
            }`,
          }}
        >
          {status === 'loading' ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : status === 'error' ? (
            <Box>
              {t('components:CharityFinder.error')}: {error.message}
            </Box>
          ) : (
            <Box
              style={{
                height: `${virtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              <List
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
                }}
              >
                {virtualItems.map((virtualItem) => {
                  const { index, key } = virtualItem
                  const charity = dataRows[index]
                  const isLastItem = index > dataRows.length - 1

                  if (isLastItem && hasNextPage) {
                    return <LoadingListItem key="loader" />
                  }

                  if (isLastItem && !hasNextPage) {
                    return (
                      <CustomCharityListItem
                        key="customCharity"
                        index={index}
                        virtualizer={virtualizer}
                        searchQuery={queryInputValue}
                        handleAddCharity={handleAddCharity}
                      />
                    )
                  }

                  return (
                    <CharityListItem
                      handleAddCharity={handleAddCharity}
                      key={key}
                      index={index}
                      virtualizer={virtualizer}
                      charity={charity}
                    />
                  )
                })}
              </List>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default CharityFinder
