import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useTranslation } from 'react-i18next'

import TextInput from '../../../../../common/components/atoms/TextInput'
import commonActions from '../../../../../common/ducks/commonActions'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import dashboardSelectors from '../../../../Dashboard/ducks/dashboard/dashboardSelectors'
import paymentsSelectors from '../../../../Dashboard/ducks/payments/paymentsSelectors'
import navigationActions from '../../../ducks/navigation/navigationActions'
import navigationSelectors from '../../../ducks/navigation/navigationSelectors'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import { validators } from '../../../utils/validation/validation'

const checkEmailApi = (emailInput) =>
  request({
    method: 'POST',
    url: PATHS.USER_EMAIL_AVAIL,
    data: { email: emailInput },
  })

const debouncedCheckEmail = AwesomeDebouncePromise(checkEmailApi, 450)

const Email = ({
  question,
  answerCache,
  accountCreated,
  handleFieldFragmentUpdate,
  dispatchSetGlobalErrorMessage,
  invalidFields,
  dispatchPreventNext,
  displayValidation,
  partnerPaidFor,
  preventNext,
  userEmail,
}) => {
  const [field] = question.fields

  const [emailInput, setEmailInput] = useState(answerCache[field.name] || '')
  const [isPartner, setisPartner] = useState()
  const [isEmailAvail, setIsEmailAvail] = useState()
  const [validationMessage, setValidationMessage] = useState()
  const [hasCoreProduct, setHasCoreProduct] = useState()
  const [isTldValid, setIsTldValid] = useState(answerCache[field.name])
  const [working, setWorking] = useState(false)

  const emailIsValid = invalidFields && !invalidFields.includes(field.name)

  const isGeneralEmailField = field.name === 'email'
  const isPartnerEmailField = field.name === 'partnerEmail'

  const { t } = useTranslation()

  // on unmount, always change preventNext to false
  useEffect(
    () => () => {
      dispatchPreventNext(false)
    },
    [dispatchPreventNext],
  )

  const handleCheckValidEmail = async (input) => {
    if (!preventNext) {
      dispatchPreventNext(true)
    }

    if (
      /*
       * For field name 'email', account must not have been created to check emailAvail
       * For field name 'partnerEmail', none of the payments in the user's payments array
       * should have the property partnerPaidFor: true to check emailAvail
       */
      (!accountCreated && isGeneralEmailField) ||
      (!partnerPaidFor && isPartnerEmailField)
    ) {
      if (!working) {
        setWorking(true)
      }

      try {
        const res = await debouncedCheckEmail(input)

        const {
          tldValid,
          emailAvailable,
          isPartner: isAPartner,
          hasCoreProduct: userHasCoreProduct,
        } = res.data.payload

        if (
          /*
           *
           * ACCEPT
           *
           */
          // if partner email question, user entered must not have core product
          // on any other email question, the email just needs to be available
          ((isPartnerEmailField && !userHasCoreProduct) || emailAvailable) &&
          // tldValid should never be false
          tldValid !== false &&
          // the input email should never be the same as the user's email
          (!userEmail ||
            (userEmail && input.toLowerCase() !== userEmail.toLowerCase()))
        ) {
          dispatchPreventNext(false)
          setIsEmailAvail(
            (isPartnerEmailField && !userHasCoreProduct) || emailAvailable,
          )
        } else {
          setIsEmailAvail(false)
          dispatchPreventNext(true)
          setValidationMessage(
            _get(
              res,
              'data.message',
              t('validation:validation.emailNotAvailable'),
            ),
          )
        }

        setisPartner(isAPartner)
        setIsTldValid(tldValid !== false)
        setHasCoreProduct(userHasCoreProduct)
      } catch (error) {
        dispatchPreventNext(true)

        errorHandler({
          error,
          reportError: true,
          onError: (resolvedErrorMessage) =>
            dispatchSetGlobalErrorMessage(resolvedErrorMessage),
        })
      }

      setWorking(false)
    }
  }

  return (
    <div className="w-full flex flex-col items-center max-w-sm">
      <TextInput
        type={field.type}
        /*
         * Disabled the input if:
         * Field name is 'email' and the user has already created and account
         * OR field name is 'partnerEmail' and the user has already paid for their partner
         */
        disabled={
          (accountCreated && isGeneralEmailField) ||
          (partnerPaidFor && isPartnerEmailField)
        }
        value={emailInput}
        working={working}
        isInvalid={
          emailInput.length > 6 &&
          ((userEmail &&
            emailInput.toLowerCase() === userEmail.toLowerCase()) ||
            (isPartnerEmailField && hasCoreProduct) ||
            (!emailIsValid && displayValidation) ||
            isEmailAvail === false ||
            !isTldValid)
        }
        validationMessage={
          !emailIsValid
            ? t('validation:validation.enterValidEmail')
            : /*
               * Displaying this message is conditional on whether the user has
               * created an account yet or not because we only want to display this error
               * message on the Account Creation question, not the partner email question.
               * If the user has not created an account, we can assume this is being
               * displayed in the account creation question.
               */
              isEmailAvail === false && isPartner && !accountCreated
              ? t('validation:validation.spouseAccountCreated')
              : validationMessage
        }
        placeholder={field.placeholder}
        onInputChange={(val) => {
          if (validators.email(val) && val !== emailInput) {
            handleCheckValidEmail(val)
          }

          setEmailInput(val)

          handleFieldFragmentUpdate({
            [field.name]: val,
          })
        }}
      />
    </div>
  )
}

Email.propTypes = {
  userEmail: PropTypes.string,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  accountCreated: PropTypes.bool.isRequired,
  partnerPaidFor: PropTypes.bool,
  displayValidation: PropTypes.bool.isRequired,
  preventNext: PropTypes.bool.isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  dispatchPreventNext: PropTypes.func.isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
  partnerPaidFor: paymentsSelectors.getPartnerPaidFor(state.dashboard),
  preventNext: navigationSelectors.getPreventNext(state.questionnaire),
  userEmail: dashboardSelectors.getEmail(state.dashboard),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchPreventNext: (prevent) =>
    dispatch(navigationActions.preventNext(prevent)),
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Email)
