import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _flatMapDeep from 'lodash/flatMapDeep'
import _flatten from 'lodash/flatten'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import MaterialTextInput from '@mui/material/TextField'

import { useTranslation } from 'react-i18next'

import { validators } from '../../../../scenes/Questionnaire/utils/validation/validation'
import { themeColorTypes } from '../../../styles/muiTheme'
import IconButton from '../IconButton/IconButton'
import Text from '../Text'
import AutocompleteBox from './components/AutocompleteBox'

/** @type {React.ForwardRefRenderFunction<?, TextInput.propTypes> */
const TextInput = forwardRef(
  (
    {
      sx = {},
      name,
      type = 'text',
      rows,
      value = '',
      variant = 'standard',
      working = false,
      maxRows,
      disabled = false,
      className,
      autoFocus,
      maxLength = 999999999,
      isInvalid,
      helperText,
      placeholder,
      placeholder2,
      onInputChange,
      forbiddenTerms = [],
      characterCount = false,
      validationMessage,
      passwordVisibility = false,
      autocompleteOptions = [],
      displayAllAutocompleteOptions = false,
    },
    forwardInputRef,
  ) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [forbiddenTermMsg, setForbiddenTermMsg] = useState()

    useEffect(() => {
      if (!_isEmpty(forbiddenTerms)) {
        const theForbiddenTerms = validators.forbiddenTerms(
          value,
          forbiddenTerms,
        )

        if (!_isEmpty(theForbiddenTerms)) {
          const flattenedTerms = _flatMapDeep(theForbiddenTerms, (terms) =>
            _flatten(terms),
          )

          const lastTerm = flattenedTerms[flattenedTerms.length - 1]

          setForbiddenTermMsg(_get(lastTerm, 'errMsg'))
        } else {
          setForbiddenTermMsg()
        }
      }
    }, [value, forbiddenTermMsg, forbiddenTerms])

    const handleInputChange = (e) => {
      /*
       * We only need to set the anchorEl that gets passed to AutocompleteBox
       * after a character is typed. There is no need to set this any sooner.
       */
      if (!anchorEl) {
        setAnchorEl(e.target.parentNode)
      }
      onInputChange(e.target.value)
    }

    const inputInvalid = !!(isInvalid || forbiddenTermMsg)

    const { t } = useTranslation()

    const translatedDefaultValidationMessage =
      validationMessage || t('common:required')

    return (
      <Box width="100%" mb="1rem">
        <MaterialTextInput
          fullWidth
          name={name}
          rows={rows}
          value={value}
          maxRows={maxRows}
          variant={variant}
          disabled={disabled}
          autoFocus={autoFocus}
          className={className}
          inputRef={forwardInputRef}
          multiline={type === 'textarea'}
          InputLabelProps={{ 'data-testid': 'text-input-label' }}
          type={passwordVisibility && passwordVisible ? 'text' : type}
          /* eslint-disable react/jsx-no-duplicate-props */
          inputProps={{ 'data-testid': 'text-input', maxLength }}
          InputProps={{
            sx,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  {working ? (
                    <CircularProgress color="primary" size={18} />
                  ) : passwordVisibility ? (
                    <IconButton
                      data-testid="password-visible-button"
                      IconComponent={
                        passwordVisible ? <Visibility /> : <VisibilityOff />
                      }
                      onClick={() => setPasswordVisible((prev) => !prev)}
                    />
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              </>
            ),
          }}
          /* eslint-enable react/jsx-no-duplicate-props */
          error={inputInvalid}
          /*
           * If the placeholder is an object with a tKey property, translate it.
           * Otherwise, use the string as is.
           */
          label={t(_get(placeholder, 'tKey', placeholder))}
          placeholder={t(_get(placeholder2, 'tKey', placeholder2))}
          helperText={
            <>
              {!disabled && (
                <>
                  {inputInvalid ||
                  (forbiddenTermMsg && translatedDefaultValidationMessage) ? (
                    <Text
                      size="xxs"
                      color={themeColorTypes.RED}
                      data-testid="helper-text-error"
                    >
                      {t(_get(forbiddenTermMsg, 'tKey', forbiddenTermMsg)) ||
                        translatedDefaultValidationMessage}
                    </Text>
                  ) : helperText ? (
                    <Text size="xxs" color={themeColorTypes.GREY}>
                      {helperText}
                    </Text>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          }
          onChange={handleInputChange}
          onBlur={(e) => {
            // checks for whitespace at start or end of string
            const regex = /^\s|\s$/

            // we only want to trim if the string isn't already trimmed
            if (regex.test(e.target.value)) {
              onInputChange(e.target.value.trim())
            }
          }}
        />

        {anchorEl && !_isEmpty(autocompleteOptions) && (
          <AutocompleteBox
            value={value}
            anchorEl={anchorEl}
            autocompleteOptions={autocompleteOptions}
            onSelect={(o) => onInputChange(o.label, o)}
            displayAllAutocompleteOptions={displayAllAutocompleteOptions}
          />
        )}

        {characterCount && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Text
                size="xs"
                color={
                  maxLength - value.length < 50 && maxLength - value.length > 10
                    ? themeColorTypes.YELLOW
                    : maxLength - value.length <= 10 &&
                        maxLength !== value.length
                      ? themeColorTypes.RED
                      : themeColorTypes.BRAND
                }
              >
                {t('common:maxChars', { maxLength })}
              </Text>
            </Box>

            <Text
              size="xs"
              color={
                maxLength - value.length < 50 && maxLength - value.length > 10
                  ? themeColorTypes.YELLOW
                  : maxLength - value.length <= 10 && maxLength !== value.length
                    ? themeColorTypes.RED
                    : themeColorTypes.BRAND
              }
            >
              {value.length}/{maxLength.toString()}
            </Text>
          </Box>
        )}
      </Box>
    )
  },
)

TextInput.propTypes = {
  sx: PropTypes.shape({}),
  name: PropTypes.string,
  rows: PropTypes.string,
  working: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ tKey: PropTypes.string }),
  ]),
  onInputChange: PropTypes.func,
  placeholder2: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ tKey: PropTypes.string }),
  ]),
  characterCount: PropTypes.bool,
  validationMessage: PropTypes.node,
  forbiddenTerms: PropTypes.arrayOf(
    PropTypes.shape({
      terms: PropTypes.arrayOf(PropTypes.string).isRequired,
      errMsg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ tKey: PropTypes.string }),
      ]).isRequired,
    }),
  ),
  passwordVisibility: PropTypes.bool,
  displayAllAutocompleteOptions: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  type: PropTypes.oneOf(['text', 'textarea', 'password', 'email']),
  autocompleteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
          tKey: PropTypes.string,
        }),
      ]),
    }).isRequired,
  ),
}

export default TextInput
