import { CONSTANTS } from '@epilogue/common'
import Cookies from 'js-cookie'

const path = '/'
const expires = 30 // 30 days
const prefix =
  process.env.REACT_APP_ENV === 'production'
    ? CONSTANTS.cookiePrefixes.PRODUCTION
    : process.env.REACT_APP_ENV === 'staging'
      ? CONSTANTS.cookiePrefixes.STAGING
      : CONSTANTS.cookiePrefixes.DEVELOPMENT

// defining the domain allows us to use the same cookies across subdomains
// Production and staging use '.epiloguewills.com' domain, all else use default
const domain =
  process.env.REACT_APP_ENV === 'production' ? '.epiloguewills.com' : ''

const cookieOptions = { expires, path, domain }

export const setCookie = (name, value) =>
  Cookies.set(`${prefix}${name}`, value, cookieOptions)

export const getCookie = (name) => {
  if (name) {
    return Cookies.get(`${prefix}${name}`)
  }
  return Cookies.get()
}

export const removeCookie = (name) =>
  Cookies.remove(`${prefix}${name}`, cookieOptions)
