import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'
import cn from 'classnames'
import { Heading, Paragraph, Text } from 'grommet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { breakpoints } from '../../../../../../hooks/useResponsive'
import amplitude from '../../../../../../lib/amplitude'
import tw from '../../../../../../styles/tw'
import HeaderContext from '../../../context/HeaderContext'

const stripeColors = {
  [CONSTANTS.worldTypes.will.type]: tw.colors.mustard,
  [CONSTANTS.worldTypes.social.type]: tw.colors['neutral-3'],
}

const ColoredStripe = styled.div`
  position: absolute;
  border-radius: 0 0 0 6px;
  width: 7px;
  height: 100%;
  background-color: ${({ type }) => stripeColors[type]};

  @media (min-width: ${breakpoints.sm}px) {
    width: 9px;
  }
`
const StyledHeading = styled(Heading)`
  line-height: 29px;
`
const WorldMenuItem = ({
  path,
  type,
  label,
  started,
  dashboardDescription,
  questionnaireDescription,
}) => {
  const { t } = useTranslation()

  const { setWorldMenuActive, isDesktop } = useContext(HeaderContext)

  const translatedLabel = t(_get(label, 'tKey', label))
  const translatedDashboardDescription = t(
    _get(dashboardDescription, 'tKey', dashboardDescription),
  )
  const translatedQuestionnaireDescription = t(
    _get(questionnaireDescription, 'tKey', questionnaireDescription),
  )

  const handleClick = () => {
    setWorldMenuActive(false)
    window.location.href = `${path}/?w=${type}`

    amplitude.sendEvent('SwitchedWorld', { switchedToWorld: type })
  }

  return (
    <div
      data-testid={`world-menu-item-${type}`}
      tabIndex={0}
      role="button"
      onClick={handleClick}
      onKeyDown={handleClick}
      aria-label="World Menu Item"
      className={cn(
        'cursor-pointer rounded-b-xl flex items-center justify-between',
        'active:outline-none active:border-none',
        'focus:outline-none focus:border-none',
        'hover:bg-grey-1',
      )}
    >
      <ColoredStripe
        type={type} // for styling
      />

      <div
        className={cn(
          'flex items-center justify-between w-full',
          isDesktop ? 'p-8' : cn('p-6', '3sx:p-8'),
        )}
      >
        <div className={cn('flex flex-col pr-10 pl-1.5', '3xs:pr-16')}>
          <StyledHeading level={5} size="smedium">
            {translatedLabel}
          </StyledHeading>

          <Paragraph size="small" color="grey-5">
            {started
              ? translatedDashboardDescription
              : translatedQuestionnaireDescription}
          </Paragraph>
        </div>

        <span
          data-testid="world-menu-item-status"
          className="whitespace-nowrap"
        >
          <Text size="small" weight="bold" color="accent-2-darkest">
            {started
              ? t(
                  'molecules:Header.components.WorldMenu.components.WorldMenuItem.view',
                )
              : t(
                  'molecules:Header.components.WorldMenu.components.WorldMenuItem.getStarted',
                )}
          </Text>
        </span>
      </div>
    </div>
  )
}

WorldMenuItem.propTypes = {
  started: PropTypes.bool.isRequired,
  label: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.label),
  ).isRequired,
  path: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map(
      (worldType) => worldType.dashboardPath,
    ),
  ).isRequired,
  type: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.type),
  ).isRequired,
  questionnaireDescription: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map(
      (worldType) => worldType.questionnaireDescription,
    ),
  ).isRequired,
  dashboardDescription: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map(
      (worldType) => worldType.dashboardDescription,
    ),
  ).isRequired,
}

export default WorldMenuItem
