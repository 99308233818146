import { loadStripe } from '@stripe/stripe-js'

const stripeProviders = {
  // stripe
  oneTimePayment: {
    prod: 'pk_live_vWIiOeBC6V5xqBYvhgZKvOtH00hgwtptLt',
    dev: 'pk_test_yt2wWCMwcl8mDMr6g6LFeHYo00GSMfaM08',
  },
  // partial.ly
  paymentPlan: {
    prod: 'pk_live_huSEiMMI7gSK7ORKzZB448xr',
    dev: 'pk_test_eV3vdXbE4SrfLjJYn9XUSUwx',
  },
}

export default (paymentOption) => {
  const env = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'

  return loadStripe(stripeProviders[paymentOption][env])
}
