import { ONTARIO_TERMS } from '../../../common/utils/provinceDictionary'

const appointeeTypes = {
  // GUARDIAN //
  GUARDIAN: {
    isPartner: false,
    label: { tKey: 'appointees:GUARDIAN' },
  },
  ALTERNATE_GUARDIAN: {
    isPartner: false,
    label: { tKey: 'appointees:ALTERNATE_GUARDIAN' },
  },
  PET_GUARDIAN: {
    isPartner: false,
    label: { tKey: 'appointees:PET_GUARDIAN' },
  },
  ALTERNATE_PET_GUARDIAN: {
    isPartner: false,
    label: { tKey: 'appointees:ALTERNATE_PET_GUARDIAN' },
  },

  // EXECUTOR //
  TRUSTEE: {
    isPartner: false,
    label: { tKey: 'appointees:TRUSTEE' },
  },
  PARTNER_TRUSTEE: {
    isPartner: true,
    label: { tKey: 'appointees:PARTNER_TRUSTEE' },
  },
  CO_TRUSTEE: {
    isPartner: false,
    label: { tKey: 'appointees:CO_TRUSTEE' },
  },
  ALTERNATE_TRUSTEE: {
    isPartner: false,
    label: { tKey: 'appointees:ALTERNATE_TRUSTEE' },
  },

  // PROPERTY POA //
  PROPERTY_POA: {
    isPartner: false,
    label: {
      tKey: 'appointees:PROPERTY_POA',
      options: { category: ONTARIO_TERMS.POA_FOR_PROPERTY },
    },
  },
  PARTNER_PROPERTY_POA: {
    isPartner: true,
    label: {
      tKey: 'appointees:PARTNER_PROPERTY_POA',
      options: { category: ONTARIO_TERMS.POA_FOR_PROPERTY },
    },
  },
  CO_PROPERTY_POA: {
    isPartner: false,
    label: {
      tKey: 'appointees:CO_PROPERTY_POA',
      options: { category: ONTARIO_TERMS.POA_FOR_PROPERTY },
    },
  },
  ALTERNATE_PROPERTY_POA: {
    isPartner: false,
    label: {
      tKey: 'appointees:ALTERNATE_PROPERTY_POA',
      options: { category: ONTARIO_TERMS.POA_FOR_PROPERTY },
    },
  },

  // PERSONAL POA //
  PERSONAL_POA: {
    isPartner: false,
    label: {
      tKey: 'appointees:PERSONAL_POA',
      options: { category: ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE },
    },
  },
  PARTNER_PERSONAL_POA: {
    isPartner: true,
    label: {
      tKey: 'appointees:PARTNER_PERSONAL_POA',
      options: { category: ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE },
    },
  },
  CO_PERSONAL_POA: {
    isPartner: false,
    label: {
      tKey: 'appointees:CO_PERSONAL_POA',
      options: { category: ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE },
    },
  },
  ALTERNATE_PERSONAL_POA: {
    isPartner: false,
    label: {
      tKey: 'appointees:ALTERNATE_PERSONAL_POA',
      options: { category: ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE },
    },
  },
}

export default appointeeTypes
