import React from 'react'
import PropTypes from 'prop-types'

import DateDropdowns from '../../molecules/DateDropdowns/DateDropdowns'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'

const DateOfBirth = ({
  question,
  answerCache,
  displayValidation,
  handleFieldFragmentUpdate,
}) => {
  const [field] = question.fields

  return (
    <div className="w-full max-w-sm flex flex-col">
      <div className="mb-2 m-auto">
        <ValidationMessage question={question} />
      </div>

      <DateDropdowns
        displayValidation={displayValidation}
        defaultDate={answerCache[field.name] || ''}
        onDateChange={(dateStr) =>
          handleFieldFragmentUpdate({ [field.name]: dateStr })
        }
      />
    </div>
  )
}

DateOfBirth.propTypes = {
  answerCache: PropTypes.shape({}).isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  displayValidation: PropTypes.bool.isRequired,
}

export default DateOfBirth
