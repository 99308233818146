export default () => {
  const currentYear = new Date().getFullYear()
  const yearsOptions = []

  for (let i = currentYear; i > currentYear - 111; i -= 1) {
    yearsOptions.push({ value: `${i}`, label: `${i}` })
  }

  return yearsOptions
}
