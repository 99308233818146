import tw from './tw'

const fontSizes = {
  xsmall: {
    size: '12px',
    height: '18px',
    maxWidth: '100%',
  },
  small: {
    size: '14px',
    height: '20px',
    maxWidth: '100%',
  },
  smedium: {
    size: '16.5px',
    height: '22px',
    maxWidth: '100%',
  },
  medium: {
    size: '18px',
    height: '24px',
    maxWidth: '100%',
  },
  smarge: {
    size: '20px',
    height: '26px',
    maxWidth: '100%',
  },
  large: {
    size: '22px',
    height: '28px',
    maxWidth: '100%',
  },
  xlarge: {
    size: '26px',
    height: '32px',
    maxWidth: '100%',
  },
  xxlarge: {
    size: '34px',
    height: '40px',
    maxWidth: '100%',
  },
}

export default {
  global: {
    colors: {
      text: tw.colors.brand,
      placeholder: tw.colors['grey-3'],

      transparent: tw.colors.transparent,

      black: tw.colors.black,
      white: tw.colors.white,

      brand: tw.colors.brand,

      'accent-1-lightest': tw.colors['accent-1-lightest'],
      'accent-1-lighter': tw.colors['accent-1-lighter'],
      'accent-1-light': tw.colors['accent-1-light'],
      'accent-1': tw.colors['accent-1'],
      'accent-1-dark': tw.colors['accent-1-dark'],
      'accent-1-darker': tw.colors['accent-1-darker'],
      'accent-1-darkest': tw.colors['accent-1-darkest'],

      'accent-2-lighter': tw.colors['accent-2-lighter'],
      'accent-2-light': tw.colors['accent-2-light'],
      'accent-2': tw.colors['accent-2'],
      'accent-2-dark': tw.colors['accent-2-dark'],
      'accent-2-darker': tw.colors['accent-2-darker'],
      'accent-2-darkest': tw.colors['accent-2-darkest'],

      'accent-3': tw.colors['accent-3'],

      'neutral-1': tw.colors['neutral-1'],
      'neutral-2': tw.colors['neutral-2'],
      'neutral-3': tw.colors['neutral-3'],

      'grey-1': tw.colors['grey-1'],
      'grey-2': tw.colors['grey-2'],
      'grey-3': tw.colors['grey-3'],
      'grey-4': tw.colors['grey-4'],
      'grey-5': tw.colors['grey-5'],

      background: tw.colors.background,

      'status-critical': tw.colors['status-critical'],
      'status-ok': tw.colors['status-ok'],
      'status-warning': tw.colors['status-warning'],
      'status-unknown': tw.colors['status-unknown'],

      mustard: '#C5822B',
    },

    font: {
      family: 'roboto',
    },

    focus: {
      border: {
        color: tw.colors['grey-2'],
      },
    },
  },

  button: {
    border: {
      radius: tw.borderRadius.full,
      width: '1px',
    },
    color: tw.colors.white,
    primary: { color: tw.colors.brand },
  },

  heading: {
    responsiveBreakpoint: 'small',
    extend: "font-family: 'ff-mark', sans-serif;  letter-spacing: -0.84px;",
    weight: 500,
    level: {
      1: {
        small: {
          size: '34px',
          height: '37px',
          maxWidth: '100%',
        },
        medium: {
          size: '50px',
          height: '40px',
          maxWidth: '1100%',
        },
        large: {
          size: '82px',
          height: '42px',
          maxWidth: '1100%',
        },
        xlarge: {
          size: '114px',
          height: '133px',
          maxWidth: '2100%',
        },
      },
      2: {
        small: {
          size: '26px',
          height: '33px',
          maxWidth: '100%',
        },
        medium: {
          size: '34px',
          height: '45px',
          maxWidth: '100%',
        },
        large: {
          size: '50px',
          height: '48px',
          maxWidth: '1100%',
        },
        xlarge: {
          size: '66px',
          height: '50px',
          maxWidth: '1100%',
        },
      },
      3: {
        small: {
          size: '22px',
          height: '29px',
          maxWidth: '100%',
        },
        medium: {
          size: '26px',
          height: '33px',
          maxWidth: '100%',
        },
        large: {
          size: '34px',
          height: '48px',
          maxWidth: '100%',
        },
        xlarge: {
          size: '42px',
          height: '50px',
          maxWidth: '1100%',
        },
      },
      4: {
        small: {
          size: '18px',
          height: '28px',
          maxWidth: '100%',
        },
        smedium: {
          size: '22px',
          height: '28px',
          maxWidth: '100%',
        },
        medium: {
          size: '24px',
          height: '32px',
          maxWidth: '100%',
        },
        large: {
          size: '32px',
          height: '44px',
          maxWidth: '100%',
        },
        xlarge: {
          size: '40px',
          height: '47px',
          maxWidth: '100%',
        },
      },
      5: {
        small: {
          size: '16px',
          height: '24px',
          maxWidth: '100%',
        },
        smedium: {
          size: '20px',
          height: '27px',
          maxWidth: '100%',
        },
        medium: {
          size: '22px',
          height: '33px',
          maxWidth: '100%',
        },
        large: {
          size: '30px',
          height: '43px',
          maxWidth: '100%',
        },
        xlarge: {
          size: '38px',
          height: '44px',
          maxWidth: '100%',
        },
      },
      6: {
        small: {
          size: '14px',
          height: '35px',
          maxWidth: '100%',
        },
        medium: {
          size: '20px',
          height: '36px',
          maxWidth: '100%',
        },
        large: {
          size: '28px',
          height: '37px',
          maxWidth: '100%',
        },
        xlarge: {
          size: '36px',
          height: '43px',
          maxWidth: '100%',
        },
      },
    },
  },

  input: {
    weight: 400,
  },

  meter: {
    color: tw.colors.brand,
  },

  paragraph: {
    extend: "font-family: 'freight', serif;",
    ...fontSizes,
  },

  rangeInput: {
    track: {
      color: tw.colors['grey-2'],
    },
  },

  radioButton: {
    extend: 'min-width: 28px',
  },

  text: {
    ...fontSizes,
  },
}
