import linkOut from '../../../../../../../common/assets/images/link-out.svg'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'

const linkStyle = {
  '& > span > a': {
    textDecoration: 'underline',
    fontFamily: (theme) => theme.typography.fontFamily,
    color: (theme) =>
      theme.palette[themeColorTypes.ACCENT_1][themeColorVariants.MAIN],
  },
  '& > span > a:after': {
    top: '1px',
    left: '6px',
    mr: '0.4rem',
    width: '14px',
    content: "' '",
    height: '14px',
    position: 'relative',
    display: 'inline-block',
    backgroundSize: '14px 14px',
    backgroundImage: `url(${linkOut})`,
  },
}

export default linkStyle
