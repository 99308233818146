import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import TextInput from '../../../../../common/components/atoms/TextInput'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'

const TextField = ({
  field,
  answerCache,
  handleFieldFragmentUpdate,
  isInvalid = false,
  validationMessage,
  nameStore = [],
}) => {
  const {
    name,
    placeholder,
    placeholder2,
    type,
    optional,
    appointeeType,
    rows,
    variant,
    forbiddenTerms,
  } = field

  const [fieldValue, setFieldValue] = useState(answerCache[name] || '')

  const isAppointeeField = !!appointeeType

  const { t } = useTranslation()

  return (
    <div className="w-full max-w-md">
      <TextInput
        type={type}
        validationMessage={validationMessage}
        optional={optional}
        autocompleteOptions={
          /*
           * If the field is an input and an appointee field, we want
           * to show the items in the nameStore as the autoComplete options.
           */
          isAppointeeField && fieldValue.length > 1 ? nameStore : []
        }
        value={fieldValue}
        rows={rows}
        variant={variant}
        isInvalid={isInvalid}
        forbiddenTerms={forbiddenTerms}
        /*
         * If the placeholder is an object with a tKey property, translate it.
         * Otherwise, use the string as is.
         */
        placeholder={t(_get(placeholder, 'tKey', placeholder))}
        placeholder2={t(_get(placeholder2, 'tKey', placeholder2))}
        onInputChange={(value, autocompleteOption) => {
          // every fragment update includes the incoming input value by default
          let fragmentUpdate = { [name]: value.trim() }

          if (isAppointeeField) {
            const relationshipValue = _get(autocompleteOption, 'subLabel.value')

            /*
             * If the relationship value is a valid relationship type, we can use it to auto-update the
             * relationship field of the appointee when the user selects the autocomplete option.
             */
            if (
              Object.values(CONSTANTS.relationshipValues).includes(
                relationshipValue,
              )
            ) {
              /*
               * Because every question that contains an appointee name field also
               * has a corresponding relationship field (this is tested in question.test.js),
               * we can write the seemingly hacky code below to get the relationship field name.
               */
              const relationshipField = `${name.split('Name')[0]}Relationship`

              fragmentUpdate = {
                ...fragmentUpdate,
                [relationshipField]: relationshipValue,
              }
            }
          }

          setFieldValue(value)
          handleFieldFragmentUpdate(fragmentUpdate)
        }}
      />
    </div>
  )
}

TextField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    placeholder2: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    type: PropTypes.string.isRequired,
    optional: PropTypes.bool,
    appointeeType: PropTypes.shape({}),
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    rows: PropTypes.string,
    forbiddenTerms: PropTypes.arrayOf(
      PropTypes.shape({
        errMsg: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({ tKey: PropTypes.string }),
        ]).isRequired,
        terms: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ),
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  validationMessage: PropTypes.node.isRequired,
  nameStore: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        tKey: PropTypes.string,
      }),
    }).isRequired,
  ),
}

const mapStateToProps = (state) => ({
  nameStore: questionnaireSelectors.getNameStore(state.questionnaire),
})

export default connect(mapStateToProps)(TextField)
