import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import _omit from 'lodash/omit'

import { Button, CheckBox, Text } from 'grommet'
import { FormClose } from 'grommet-icons'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import listItemAnimation from '../../../../../common/animations/listItemAnimation'
import TextInput from '../../../../../common/components/atoms/TextInput'
import featureFlag, { flagTypes } from '../../../../../common/utils/featureFlag'
import validationSelectors from '../../../ducks/validation/validationSelectors'
import DateDropdowns from '../../molecules/DateDropdowns/DateDropdowns'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'
import isMinor from './utils/isMinor'

const StyledButton = styled(Button)`
  height: 38px;
`
const newChild = () => ({ isStep: false, id: Date.now().toString() })

const Children = ({
  handleFieldFragmentUpdate,
  answerCache,
  displayValidation,
  invalidFields = [],
}) => {
  const existingChildrenArr = () => {
    try {
      const children = answerCache.children || []
      const stepChildren = answerCache.stepChildren || []
      return [...children, ...stepChildren]
    } catch (error) {
      const message =
        'This happened because there were either no children or stepChildren to spread into the existingChildrenArr in Children.js'

      if (featureFlag(flagTypes.SENTRY)) {
        window.captureException(error, { message })
      }
      return [newChild()]
    }
  }

  const [children, setChildren] = useState(
    !_isEmpty(answerCache.children) || !_isEmpty(answerCache.stepChildren)
      ? /*
         * if either children or stepChildren fields is populated
         * in the answerCache combine the two fields into one
         */
        existingChildrenArr()
      : [newChild()],
  )

  const handleUpdate = (theChildren) => {
    setChildren(theChildren)

    const childrenArr = theChildren.filter((c) => !c.isStep)

    const stepChildrenArr = theChildren.filter((c) => c.isStep)

    const hasMinorChildren = theChildren.some(
      (c) => !c.isStep && isMinor(answerCache.province, c.dob),
    )

    handleFieldFragmentUpdate({
      /*
       * omitting the 'distribution' property for each child
       * is for the StepChildrenDistribution question
       */
      children: childrenArr.map((c) => _omit(c, 'distribution')),
      /*
       * Adding default value for distribution is for the
       * StepChildrenDistribution question.
       * All stepChildren start as exlcuded from will by default
       */
      stepChildren: stepChildrenArr.map((c) => ({
        ...c,
        distribution: 'exclude',
      })),
      minorChildren: hasMinorChildren ? 'yes' : 'no',
      hasChildren: _isEmpty(childrenArr) ? 'no' : 'yes',
      hasStepChildren: _isEmpty(stepChildrenArr) ? 'no' : 'yes',
      /* Any changes to the Children question will force user to
       * Re-respond to the StepChildrenDistribution question
       */
      stepChildrenDistribution: undefined,
    })
  }

  const handleAddChild = () => {
    handleUpdate([...children, newChild()])
  }

  const handleRemoveChild = (index) => {
    const childrenClone = _cloneDeep(children)
    childrenClone.splice(index, 1)
    handleUpdate(childrenClone)
  }

  const { t } = useTranslation()

  return (
    <div
      className="max-w-lg w-full"
      data-testid="children-details-question-wrapper"
    >
      <div className="flex justify-center mb-3">
        <ValidationMessage
          isInvalid={displayValidation && !_isEmpty(invalidFields)}
          message={t('validation:validation.missingInformation')}
        />
      </div>

      <AnimatePresence initial={false}>
        {!_isEmpty(children) &&
          children.map((child, index) => (
            <motion.div
              className="flex"
              key={child.id}
              {...listItemAnimation()}
            >
              {children.length > 1 && (
                <div className="mr-2 mt-4">
                  <Button
                    plain
                    data-testid="children-details-close-button"
                    hoverIndicator={{ color: 'grey-2', opacity: 0.3 }}
                    onClick={() => handleRemoveChild(index)}
                    label={
                      <div className="p-1">
                        <FormClose color="grey-3" />
                      </div>
                    }
                  />
                </div>
              )}

              <div className="w-full">
                {/* name */}
                <div className="mb-3">
                  <TextInput
                    className="max-w-full"
                    value={child.name}
                    isInvalid={displayValidation && !child.name}
                    validationMessage=""
                    placeholder={t(
                      'customQuestion:Children.textInputPlaceholder',
                    )}
                    onInputChange={(value) => {
                      const childrenClone = _cloneDeep(children)
                      childrenClone[index].name = value
                      handleUpdate(childrenClone)
                    }}
                  />
                </div>

                {/* DOB */}
                <div className="w-full flex flex-col items-center justify-between mb-3 sm:mb-2 sm:flex-row">
                  <div className="mt-1 mb-2 text-center w-full sm:w-1/5 sm:my-0">
                    <Text size="small">
                      {t('customQuestion:Children.dateOfBirth')}
                    </Text>
                  </div>
                  <div className="mt-2 w-full sm:w-4/5">
                    <DateDropdowns
                      displayValidation={displayValidation}
                      defaultDate={child.dob || ''}
                      onDateChange={(value) => {
                        const childrenClone = _cloneDeep(children)
                        childrenClone[index].dob = value
                        handleUpdate(childrenClone)
                      }}
                    />
                  </div>
                </div>

                {/* isStep */}
                <div className="mb-16">
                  <CheckBox
                    checked={!!child.isStep}
                    label={
                      <Text size="small">
                        {t('customQuestion:Children.stepchildCheckboxText')}
                      </Text>
                    }
                    onChange={(e) => {
                      const childrenClone = _cloneDeep(children)
                      childrenClone[index].isStep = e.target.checked
                      handleUpdate(childrenClone)
                    }}
                  />
                </div>
              </div>
            </motion.div>
          ))}
      </AnimatePresence>

      <div className="w-full flex justify-center mb-8">
        <StyledButton
          primary
          color="neutral-3"
          onClick={handleAddChild}
          data-testid="add-another-child-button"
          label={
            <div className="text-brand">
              <Text size="small" weight="bold">
                <span className="mr-1">+</span>{' '}
                {t('customQuestion:Children.addAnotherChild')}
              </Text>
            </div>
          }
        />
      </div>
    </div>
  )
}

Children.propTypes = {
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  answerCache: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
    stepChildren: PropTypes.arrayOf(PropTypes.shape({})),
    province: PropTypes.string.isRequired,
  }).isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  displayValidation: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  invalidFields: validationSelectors.getInvalidFields(state.questionnaire),
})

export default connect(mapStateToProps)(Children)
