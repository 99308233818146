import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

import { createLogic } from 'redux-logic'

import getWorldFromUrl from '../../utils/getWorldFromUrl'
import commonTypes from '../commonTypes'

export default createLogic({
  name: 'setWorld',
  type: commonTypes.SET_WORLD,
  transform({ action }, next) {
    const actionClone = _cloneDeep(action)

    const worldType = getWorldFromUrl()

    _set(actionClone, 'payload', { world: worldType })
    next(actionClone)
  },
})
