import interceptModal from './interceptModal'
import navNextAutomatic from './navNextAutomatic'
import nextQuestion from './nextQuestion'
import previousQuestion from './previousQuestion'
import toQuestion from './toQuestion'
import updateProgress from './updateProgress'

export default [
  toQuestion,
  // interceptModal must come before nextQuestion
  interceptModal,
  nextQuestion,
  updateProgress,
  previousQuestion,
  navNextAutomatic,
]
