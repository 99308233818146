export default [
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item1.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item1.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item1.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item1.children.item3.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item2.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item2.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item2.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item2.children.item3.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item3.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item3.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item3.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item3.children.item3.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item3.children.item4.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item4.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item4.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item4.children.item2.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item5.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item5.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionInheritanceAgeSidebar.item5.children.item2.body',
        },
      },
    ],
  },
]
