import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import MaterialRadioGroup from '@mui/material/RadioGroup'

import cn from 'classnames'

import {
  themeColorTypes,
  themeColorVariants,
  themeTextSizes,
} from '../../styles/muiTheme'
import Text from './Text'

const RadioGroup = ({
  sx = {},
  name,
  onChange,
  className,
  radioButtons,
  defaultActive,
}) => {
  const [active, setActive] = useState(defaultActive)

  return (
    <FormControl className={className} component="fieldset">
      <MaterialRadioGroup
        sx={sx}
        name={name}
        value={active}
        aria-label={name}
        onChange={(e) => {
          const { value } = e.target
          onChange(value)
          setActive(value)
        }}
      >
        {radioButtons.length > 0 &&
          radioButtons.map(
            ({
              value,
              label,
              dataTestId,
              disabled = false,
              labelSize = 'md',
              radioSize = 'medium',
            }) => {
              const checked = active === value

              return (
                <FormControlLabel
                  sx={{
                    '& input:disabled': { display: 'none' },
                    '& span:last-child': { fontWeight: 'normal' },
                  }}
                  key={value}
                  value={value}
                  label={
                    <Text
                      size={labelSize}
                      color={
                        disabled ? themeColorTypes.GREY : themeColorTypes.BRAND
                      }
                      colorVariant={
                        disabled
                          ? themeColorVariants.LIGHT
                          : themeColorVariants.MAIN
                      }
                    >
                      {label}
                    </Text>
                  }
                  className={cn(`${checked ? 'checked' : ''}`)}
                  control={
                    <Radio
                      disableRipple
                      color="primary"
                      size={radioSize}
                      disabled={disabled}
                      inputProps={{ 'data-testid': dataTestId }}
                    />
                  }
                />
              )
            },
          )}
      </MaterialRadioGroup>
    </FormControl>
  )
}

RadioGroup.propTypes = {
  sx: PropTypes.shape({}),
  onChange: PropTypes.func,
  className: PropTypes.string,
  defaultActive: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      dataTestId: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
      labelSize: PropTypes.oneOf(Object.keys(themeTextSizes)),
      radioSize: PropTypes.oneOf(['small', 'medium']),
    }),
  ).isRequired,
}

export default RadioGroup
