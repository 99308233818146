import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Navigate, useLocation } from 'react-router-dom'

import ReactGA from 'react-ga4'

const LocationHandler = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    // send pageview updates to Google Analytics

    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: document.title,
    })
  }, [location.pathname, location.search])

  if (location.pathname === '/') {
    return <Navigate to="/login" replace />
  }
  return children
}

LocationHandler.propTypes = { children: PropTypes.node.isRequired }

export default LocationHandler
