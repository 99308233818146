import React from 'react'

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../common/components/atoms/Button'
import withGoogleAuth from '../../../../../../../../common/components/hoc/withGoogleAuth'

const GoogleAuthAgreeButton = withGoogleAuth(
  ({ onClick, working, closeModal, ...props }) => {
    const { t } = useTranslation()

    return (
      <Button
        {...props}
        type="button"
        label={t(
          'molecules:CreateAccountForm.components.AgreeModal.components.AgreeButton',
        )}
        variant="contained"
        disabled={working}
        data-testid="google-agree-button"
        endIcon={<ArrowForwardRoundedIcon />}
        onClick={() => {
          onClick()
          closeModal()
        }}
      />
    )
  },
)

export default GoogleAuthAgreeButton
