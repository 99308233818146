import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import tw from '../../../styles/tw'

const StyledLoader = styled.div`
  padding: ${({ paddingY }) => paddingY}px ${({ paddingX }) => paddingX}px;

  & > div {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    background-color: ${({ color }) => tw.colors[color]};

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  & .bounce1 {
    animation-delay: -0.32s;
  }

  & .bounce2 {
    margin: 0 7.5px;
    animation-delay: -0.16s;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`

const InlineLoader = ({
  color = 'white',
  size = '9',
  paddingX = '1',
  paddingY = '1',
}) => (
  <StyledLoader
    className="spinner"
    color={color} // for styling
    size={size} // for styling
    paddingX={paddingX} // for styling
    paddingY={paddingY} // for styling
  >
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </StyledLoader>
)

InlineLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  paddingX: PropTypes.string,
  paddingY: PropTypes.string,
}

export default InlineLoader
