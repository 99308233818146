const listItemVariants = {
  unmounted: {
    y: -115,
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },

  mounted: ({ transitionInDuration }) => ({
    y: 0,
    opacity: 1,
    height: 'auto',

    transition: {
      y: {
        mass: 1,
        damping: 16,
        type: 'spring',
        stiffness: 100,
      },
      opacity: {
        duration: transitionInDuration,
        delay: transitionInDuration * 0.36,
      },
      height: { duration: transitionInDuration },
    },
  }),

  unmounting: ({ transitionOutDuration }) => ({
    height: 0,
    opacity: 0,
    visibility: 'hidden',

    transition: {
      height: { duration: transitionOutDuration },
    },
  }),
}

export default (
  { transitionInDuration, transitionOutDuration } = {
    transitionInDuration: 0.35,
    transitionOutDuration: 0.3,
  },
) => ({
  exit: 'unmounting',
  animate: 'mounted',
  initial: 'unmounted',
  variants: listItemVariants,
  custom: { transitionInDuration, transitionOutDuration },
})
