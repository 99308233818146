export const queryKeysBase = {
  checkAuth: 'checkAuth',
  userId: 'userId',
  toggles: 'toggles',
  insurance: 'insurance',
  socialDocuments: 'socialDocuments',
  previewDoc: 'previewDoc',
  lawyers: 'lawyers',
  downloadDoc: 'downloadDoc',
  appointments: 'appointments',
  productCharge: 'productCharge',
  printCreditsUsed: 'printCreditsUsed',
  createPaymentPlan: 'createPaymentPlan',
  staticDocumentDownloadEvents: 'staticDocumentDownloadEvents',
  recommendedCharities: 'recommendedCharities',
}

// Structure queryKeys from most generic values to more specific values
export const queryKeys = {
  [queryKeysBase.toggles]: () => [queryKeysBase.toggles],
  [queryKeysBase.toggles]: () => [queryKeysBase.toggles],
  [queryKeysBase.checkAuth]: () => [queryKeysBase.checkAuth],
  [queryKeysBase.appointments]: () => [queryKeysBase.appointments],
  [queryKeysBase.userId]: (userId) => [queryKeysBase.userId, userId],
  [queryKeysBase.socialDocuments]: () => [queryKeysBase.socialDocuments],
  [queryKeysBase.printCreditsUsed]: () => [queryKeysBase.printCreditsUsed],
  [queryKeysBase.previewDoc]: (docId) => [queryKeysBase.previewDoc, docId],
  [queryKeysBase.downloadDoc]: (docId) => [queryKeysBase.downloadDoc, docId],
  [queryKeysBase.lawyers]: (province) => [queryKeysBase.lawyers, province],
  [queryKeysBase.productCharge]: (promoCode, addOnCart, answerStore) => [
    queryKeysBase.productCharge,
    promoCode,
    addOnCart,
    answerStore,
  ],
  [queryKeysBase.createPaymentPlan]: (fullAmount) => [
    queryKeysBase.createPaymentPlan,
    fullAmount,
  ],
  [queryKeysBase.staticDocumentDownloadEvents]: () => [
    queryKeysBase.staticDocumentDownloadEvents,
  ],
  [queryKeysBase.recommendedCharities]: (query, province, category) => [
    queryKeysBase.recommendedCharities,
    query,
    province,
    category,
  ],
  [queryKeysBase.insurance]: ({
    coverageAmounts,
    term,
    gender,
    smoker,
    age,
  }) => [queryKeysBase.insurance, coverageAmounts, term, gender, smoker, age],
}
