import { postalCodeRegex } from '../../../../scenes/Questionnaire/utils/validation/patterns'

export interface Field {
  id: string
  pattern?: RegExp
  optional: boolean
  placeholder: { tKey: string }
}

export interface FieldTypes {
  [key: string]: Field
}

const fieldTypes: FieldTypes = {
  name: {
    id: 'name',
    placeholder: {
      tKey: 'components:MailingAddressForm.data.mailingAddressFieldTypes.name.placeholder',
    },
    optional: false,
    pattern: undefined,
  },
  city: {
    id: 'city',
    placeholder: {
      tKey: 'components:MailingAddressForm.data.mailingAddressFieldTypes.city.placeholder',
    },
    optional: false,
    pattern: undefined,
  },
  province: {
    id: 'province',
    placeholder: {
      tKey: 'components:MailingAddressForm.data.mailingAddressFieldTypes.province.placeholder',
    },
    optional: false,
    pattern: undefined,
  },
  address1: {
    id: 'address1',
    placeholder: {
      tKey: 'components:MailingAddressForm.data.mailingAddressFieldTypes.address1.placeholder',
    },
    optional: false,
    pattern: undefined,
  },
  address2: {
    id: 'address2',
    placeholder: {
      tKey: 'components:MailingAddressForm.data.mailingAddressFieldTypes.address2.placeholder',
    },
    optional: true,
    pattern: undefined,
  },
  postalCode: {
    id: 'postalCode',
    placeholder: {
      tKey: 'components:MailingAddressForm.data.mailingAddressFieldTypes.postalCode.placeholder',
    },
    optional: false,
    pattern: postalCodeRegex,
  },
}

export default fieldTypes
