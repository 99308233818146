import _get from 'lodash/get'

import documentsName from './documentsName'

export default {
  getDocuments: (state) => _get(state[documentsName], 'documents'),
  getFetchDocumentsWorking: (state) =>
    _get(state[documentsName], 'fetchDocumentsWorking'),
  getGenerateDocumentsWorking: (state) =>
    _get(state[documentsName], 'generateDocumentsWorking'),
}
