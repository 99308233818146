import React, { useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import { Badge, Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../common/components/atoms/Button'
import amplitude from '../../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import MailingAddressModal from '../modals/MailingAddressModal'

const compareCreationDates = (a: any, b: any) =>
  a.created < b.created ? -1 : a.created > b.created ? 1 : 0

interface Props {
  payments: any[]
  answerStore: any
  autoOpen: boolean
  hasPrintCredit: boolean
  generatedDocuments: any[]
  dispatchFetchAddOns: () => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const PrintDocumentsButton = ({
  autoOpen,
  payments,
  answerStore,
  hasPrintCredit,
  generatedDocuments,
  dispatchFetchAddOns,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const initialAutoOpenModal = hasPrintCredit && autoOpen

  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(initialAutoOpenModal)

  // Get the latest purchase containing a print credit,
  // will be undefined if none have ever been purchased
  const [latestPrintingPurchase] = useMemo(
    () =>
      payments
        .filter((payment) =>
          payment?.addOns?.some(
            (addOn: any) => addOn.type === CONSTANTS.addOnTypes.PRINTING.type,
          ),
        )
        .sort(compareCreationDates)
        .slice(-1),
    [payments],
  )

  // Get the latest document creation date,
  // will be undefined if no documents have been generated
  const [latestDocument] = useMemo(
    () =>
      generatedDocuments
        .filter((doc) => doc.created) // Remove any documents without a creation date/time
        .sort(compareCreationDates)
        .slice(-1),
    [generatedDocuments],
  )

  const preventReprint =
    !hasPrintCredit && // doesn't already have a print credit
    !!latestPrintingPurchase && // has purchased a print credit previously
    !!latestDocument && // has a document to compare against (just to be safe)
    latestDocument.created < latestPrintingPurchase.created // hasn't updated their documents since they last purchased a print credit

  const handleClick = () => {
    if (hasPrintCredit) {
      amplitude.sendEvent('ClickedPrintButton', {
        attemptingReprint: false,
        openingModal: true,
        purchasingPrinting: false,
      })

      setShowModal(true)
    } else {
      amplitude.sendEvent('ClickedPrintButton', {
        attemptingReprint: false,
        openingModal: false,
        purchasingPrinting: true,
      })

      navigate('/dashboard/add-ons/printing')
    }
  }

  const { t } = useTranslation()

  return (
    <Box sx={{ position: 'relative' }}>
      {hasPrintCredit && (
        <Badge
          badgeContent="1"
          color="secondary"
          data-testid="printing-badge"
          sx={{ position: 'absolute', top: '5.5px', right: '5.5px' }}
        />
      )}
      <Button
        variant="contained"
        onClick={handleClick}
        disabled={preventReprint}
        data-testid="printing-button"
        color={themeColorTypes.NEUTRAL}
        startIcon={<PrintOutlinedIcon />}
        label={
          hasPrintCredit
            ? t('documents:components.buttons.PrintDocumentsButton.label.case1')
            : t('documents:components.buttons.PrintDocumentsButton.label.case2')
        }
        sx={preventReprint ? { cursor: 'not-allowed' } : undefined}
        onDisabledClick={() => {
          amplitude.sendEvent('ClickedPrintButton', {
            attemptingReprint: true,
            openingModal: false,
            purchasingPrinting: false,
          })

          dispatchSetGlobalErrorMessage(
            t(
              'documents:components.buttons.PrintDocumentsButton.globalErrorMessage',
            ),
          )
        }}
      />

      {showModal && (
        <MailingAddressModal
          show={showModal}
          answerStore={answerStore}
          onClose={() => setShowModal(false)}
          dispatchFetchAddOns={dispatchFetchAddOns}
          dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
        />
      )}
    </Box>
  )
}

export default PrintDocumentsButton
