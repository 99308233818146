import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import _omitBy from 'lodash/omitBy'

import { CONSTANTS } from '@epilogue/common'

import { getCookie, removeCookie, setCookie } from '../lib/cookies'
import queryString from '../lib/queryString'

export interface Utms {
  source?: string
  medium?: string
  campaign?: string
  content?: string
  id?: string
  term?: string
}

const getUtmsFromUrl = (): Utms => {
  const {
    utm_source: source,
    utm_medium: medium,
    utm_campaign: campaign,
    utm_content: content,
    utm_id: id,
    utm_term: term,
  } = queryString.parseUrl(window.location.href) as { [key: string]: string }

  return _omitBy({ source, medium, campaign, content, id, term }, _isUndefined)
}

export const setUtmCookies = () => {
  const urlUtms = getUtmsFromUrl()

  // If there are no UTMs in the URL, don't overwrite any existing cookies
  if (!_isEmpty(urlUtms)) {
    const { source, medium, campaign, content, term, id } = urlUtms

    // Delete old cookies
    removeCookie(CONSTANTS.cookieNames.UTM_SOURCE)
    removeCookie(CONSTANTS.cookieNames.UTM_MEDIUM)
    removeCookie(CONSTANTS.cookieNames.UTM_CAMPAIGN)
    removeCookie(CONSTANTS.cookieNames.UTM_CONTENT)
    removeCookie(CONSTANTS.cookieNames.UTM_ID)
    removeCookie(CONSTANTS.cookieNames.UTM_TERM)

    // Set new cookies
    if (source) setCookie(CONSTANTS.cookieNames.UTM_SOURCE, source)
    if (medium) setCookie(CONSTANTS.cookieNames.UTM_MEDIUM, medium)
    if (campaign) setCookie(CONSTANTS.cookieNames.UTM_CAMPAIGN, campaign)
    if (content) setCookie(CONSTANTS.cookieNames.UTM_CONTENT, content)
    if (id) setCookie(CONSTANTS.cookieNames.UTM_ID, id)
    if (term) setCookie(CONSTANTS.cookieNames.UTM_TERM, term)
  }
}

export const getUtms = (): Utms =>
  _omitBy(
    {
      source: getCookie(CONSTANTS.cookieNames.UTM_SOURCE),
      medium: getCookie(CONSTANTS.cookieNames.UTM_MEDIUM),
      campaign: getCookie(CONSTANTS.cookieNames.UTM_CAMPAIGN),
      content: getCookie(CONSTANTS.cookieNames.UTM_CONTENT),
      id: getCookie(CONSTANTS.cookieNames.UTM_ID),
      term: getCookie(CONSTANTS.cookieNames.UTM_TERM),
    },
    _isUndefined,
  )
