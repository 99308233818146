import React, { useEffect } from 'react'

import { Box } from '@mui/material'

import Heading from '../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../../common/lib/amplitude'
import { ContactModalData } from '../../types/button.types'

interface Props {
  show: boolean
  onClose: () => void
  data: ContactModalData
}

const ContactModal = ({
  show,
  onClose,
  data: { icon, label, body },
}: Props) => {
  useEffect(() => {
    if (show)
      amplitude.sendEvent('ViewedContactModal', {
        label,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Modal
      show={show}
      maxWidth="sm"
      onClose={onClose}
      data-testid="document-card-contact-modal"
    >
      <Box
        sx={{
          p: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box component="span" sx={{ mb: '1.3rem' }}>
          {icon}
        </Box>

        {label && (
          <Heading variant="h3" align="center">
            {label}
          </Heading>
        )}
      </Box>

      <>
        {body &&
          React.Children.toArray(
            body.map((text, i) => (
              <Text
                size="md"
                align="center"
                sx={{ lineHeight: '1.4', mt: i !== 0 ? '1.2rem' : '0' }}
              >
                {text}
              </Text>
            )),
          )}
      </>
    </Modal>
  )
}

export default ContactModal
