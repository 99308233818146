import { CONSTANTS } from '@epilogue/common'
import mingo from 'mingo'

import { SUPPORTED_PROVINCES } from '../../../utils/provinces'

export default ({ answerCache, questionnairesStarted = [] }) => {
  // 'social' world is available to everybody, all the time
  const availableWorlds = [CONSTANTS.worldTypes.social.type]

  const willWorldAvailable =
    new mingo.Query({
      $or: [
        { province: { $exists: true, $in: SUPPORTED_PROVINCES } },
        { social_province: { $exists: true, $in: SUPPORTED_PROVINCES } },
      ],
    }).test(answerCache) ||
    questionnairesStarted.includes(CONSTANTS.worldTypes.will.type)

  if (willWorldAvailable) {
    availableWorlds.push(CONSTANTS.worldTypes.will.type)
  }

  return availableWorlds
}
