import validationTypes from './validationTypes'

export default {
  updateValidation: ({ invalidFields }) => ({
    type: validationTypes.UPDATE_VALIDATION,
    payload: { invalidFields },
  }),

  displayValidation: (displayValidation) => ({
    type: validationTypes.DISPLAY_VALIDATION,
    payload: { displayValidation },
  }),
}
