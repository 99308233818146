import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'

import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import MultiOptionNavButton from '../../molecules/MultiOptionNavButton'
import FamilyWillContent from './components/FamilyWillContent'

const FamilyWill = ({ question, answerStore, accountCreated }) => {
  const { t } = useTranslation()
  const [field] = question.fields

  return (
    <>
      <FamilyWillContent answerStore={answerStore} />
      <MultiOptionNavButton
        hideSecondary={accountCreated}
        primaryLabel={t('common:continue')}
        primaryQuestionFragment={{ [field.name]: 'yes' }}
        secondaryLabel={t('common:doesNotLookRightForMe')}
        secondaryQuestionFragment={{ [field.name]: 'no' }}
      />
    </>
  )
}

FamilyWill.propTypes = {
  accountCreated: PropTypes.bool.isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  answerStore: PropTypes.shape({ hasPartner: PropTypes.string }).isRequired,
}

const mapStateToProps = (state) => ({
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
})

export default connect(mapStateToProps)(FamilyWill)
