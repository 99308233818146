import { CONSTANTS, utils } from '@epilogue/common'
import { createLogic } from 'redux-logic'

import commonActions from '../../../../../common/ducks/commonActions'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import sessionStore from '../../../../../common/lib/sessionStore'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import addOnsSelectors from '../../../../Dashboard/ducks/addOns/addOnsSelectors'
import dashboardSelectors from '../../../../Dashboard/ducks/dashboard/dashboardSelectors'
import questionnaireActions from '../questionnaireActions'
import questionnaireSelectors from '../questionnaireSelectors'
import questionnaireTypes from '../questionnaireTypes'
import capitalCaseAnswerStoreNames from '../utils/capitalCaseAnswerStoreNames'

export default createLogic({
  name: 'updateQuestionnaire',
  type: questionnaireTypes.UPDATE_QUESTIONNAIRE,
  warnTimeout: 45000, // 45 seconds
  async process({ getState, action }, dispatch, done) {
    const state = getState()
    const answerStore = questionnaireSelectors.getAnswerStore(
      state.questionnaire,
    )
    const answerCache = questionnaireSelectors.getAnswerCache(
      state.questionnaire,
    )
    const questionnaireId = questionnaireSelectors.getQuestionnaireId(
      state.questionnaire,
    )
    const token = questionnaireSelectors.getToken(state.questionnaire)
    const accountCreated = questionnaireSelectors.getAccountCreated(
      state.questionnaire,
    )
    const world = commonSelectors.getWorld(state)
    const userId = dashboardSelectors.getUserId(state.dashboard)
    const addOns = addOnsSelectors.getPaidAddOns(state.dashboard)
    const isPartner = dashboardSelectors.getIsPartner(state.dashboard)
    const mailingList = dashboardSelectors.getMailingList(state.dashboard)

    /*
     * Boolean for what value to set answerStoreUpdatedSinceGenerate to.
     *
     * If the world is not 'will', always set to true.
     *
     * Otherwise, only returns true if the current answerStoreUpdatedSinceGenerate value is false and
     * all incoming field fragments do NOT have key/value preventAnswerStoreUpdatedSinceGenerate:true
     */
    const setAnswerStoreUpdatedSinceGenerate =
      world !== CONSTANTS.worldTypes.will.type ||
      !(
        action.payload
          .allIncomingFieldsPreventAnswerStoreUpdatedSinceGenerate &&
        !questionnaireSelectors.getAnswerStoreUpdatedSinceGenerate(
          state.questionnaire,
        )
      )

    try {
      const { answerStoreType, answerStoreUpdatedSinceGenerateType } =
        CONSTANTS.worldTypes[world]

      await request({
        url: `${PATHS.QUESTIONNAIRE}/${questionnaireId}`,
        method: 'PUT',
        timeout: 20000, // timeout after 20s
        data: {
          world,
          token,
          userId,
          isPartner,
          mailingList,
          accountCreated,
          [answerStoreType]: answerStore,
          printingAddOn: utils.containsAddOn({
            addOns,
            addOnType: CONSTANTS.addOnTypes.PRINTING.type,
          }),
          witnessingAddOn: utils.containsAddOn({
            addOns,
            addOnCategory:
              CONSTANTS.addOnTypes.VIRTUAL_WITNESSING_WILL.category,
          }),
          [answerStoreUpdatedSinceGenerateType]:
            setAnswerStoreUpdatedSinceGenerate,
          answerCache: capitalCaseAnswerStoreNames(answerCache),
        },
      })

      if (
        !questionnaireSelectors.getQuestionnaireStarted(
          state.questionnaire,
          world,
        )
      ) {
        dispatch(questionnaireActions.updateQuestionnairesStarted({ world }))
      }

      if (setAnswerStoreUpdatedSinceGenerate) {
        dispatch(
          questionnaireActions.setAnswerStoreUpdatedSinceGenerate({
            bool: true,
          }),
        )
      }
    } catch (error) {
      const updateQuestionnaireErrorCount = sessionStore.get(
        CONSTANTS.sessionStorageKeys.UPDATE_QUESTIONNAIRE_ERROR_COUNT,
      )

      /*
       * Only when the updateQuestionnaireErrorCount hits 3 do
       * we reset the session-storage counter and report the
       * error internally and to the user
       */
      if (!updateQuestionnaireErrorCount) {
        sessionStore.set(
          CONSTANTS.sessionStorageKeys.UPDATE_QUESTIONNAIRE_ERROR_COUNT,
          1,
        )
      } else if (updateQuestionnaireErrorCount < 2) {
        sessionStore.set(
          CONSTANTS.sessionStorageKeys.UPDATE_QUESTIONNAIRE_ERROR_COUNT,
          updateQuestionnaireErrorCount + 1,
        )
      } else {
        errorHandler({
          error,
          reportError: true,
          fallbackErrorMessage:
            'Unable to save your last answer. Please refresh your browser and try again.',
          onError: (resolvedErrorMessage) => {
            dispatch(commonActions.setGlobalErrorMessage(resolvedErrorMessage))

            // Reset session-storage counter to 0
            sessionStore.set(
              CONSTANTS.sessionStorageKeys.UPDATE_QUESTIONNAIRE_ERROR_COUNT,
              0,
            )
          },
        })
      }
    } finally {
      done()
    }
  },
})
