import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'

import _isEmpty from 'lodash/isEmpty'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Grid, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as RbcInsuranceLogo } from '../../../../../../../../common/assets/images/rbc-insurance-logo.svg'
import Button from '../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../common/components/atoms/Heading'
import LoadingSpinner from '../../../../../../../../common/components/atoms/LoadingSpinner'
import RadioGroup from '../../../../../../../../common/components/atoms/RadioGroup'
import Text from '../../../../../../../../common/components/atoms/Text'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'
import useFetchInsuranceQuotes from '../hooks/useFetchInsuranceQuotes'
import { InsuranceQuotesParams, InsuranceQuotesResponse } from '../quote.types'
import InfoTooltip from './InfoTooltip'
import QuoteCardCarousel from './QuoteCardCarousel'
import QuoteSubmissionForm from './QuoteSubmissionForm'

const RadioLabel = ({ children }: { children: string }) => (
  <Text
    size="sm"
    color={themeColorTypes.GREY}
    colorVariant={themeColorVariants.DARK}
  >
    {children}
  </Text>
)

interface Props {
  userId: string
  sectionPaddingX: string
  handlePageBackward: () => void
  setInsuranceQuotesParams: React.Dispatch<
    React.SetStateAction<InsuranceQuotesParams>
  >
  insuranceQuotesParams: InsuranceQuotesParams
  setShowModal?: Dispatch<SetStateAction<boolean>>
  dispatchSetGlobalErrorMessage: (message: string) => void
}

const LifeInsuranceMultiQuotes = ({
  userId,
  sectionPaddingX,
  handlePageBackward,
  insuranceQuotesParams,
  setShowModal = undefined,
  setInsuranceQuotesParams,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { isInitialLoading: isWorking, data: quotes } = useFetchInsuranceQuotes(
    {
      insuranceQuotesParams,
      dispatchSetGlobalErrorMessage,
    },
  )
  const isMobile = useMediaQuery('(max-width:706px)')
  const { gender, term, smoker, age } = insuranceQuotesParams

  const [selectedCoverage, setSelectedCoverage] = useState<number | undefined>(
    undefined,
  )

  const selectedQuote = useMemo(
    () =>
      quotes?.find(
        (quote: InsuranceQuotesResponse) => quote.amount === selectedCoverage,
      ) as InsuranceQuotesResponse | undefined,
    [quotes, selectedCoverage],
  )

  const baseRadioLabelWrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    mb: isMobile ? '-0.3rem' : '0',
    width: isMobile ? 'width' : 'fit-content',
  }

  const baseInfoTooltipStyles = {
    cursor: 'help',
    top: isMobile ? 'unset' : '50%',
    right: isMobile ? 'unset' : '-36px',
    position: isMobile ? 'relative' : 'absolute',
    transform: isMobile ? 'unset' : 'translateY(-50%)',
  }

  const { t } = useTranslation()

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* Upper body */}
        <Grid
          container
          rowSpacing={3}
          alignItems="center"
          direction={isMobile ? 'column' : 'row'}
          columnSpacing={isMobile ? 0 : sectionPaddingX}
          justifyContent={isMobile ? 'center' : 'space-between'}
          sx={{
            mx: 'auto',
            width: '100%',
            mt: isMobile ? '0' : '0.9rem',
          }}
        >
          {/* Radio buttons */}
          <Grid item xs={12} md={6}>
            <Box sx={baseRadioLabelWrapperStyles}>
              <RadioLabel>
                {t(
                  'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.selectTerm',
                )}
              </RadioLabel>
            </Box>
            <RadioGroup
              name="term"
              defaultActive={insuranceQuotesParams.term}
              sx={{ display: 'flex', flexDirection: 'row' }}
              onChange={(value: string) =>
                setInsuranceQuotesParams({
                  ...insuranceQuotesParams,
                  term: Number(value),
                })
              }
              radioButtons={[
                {
                  value: 10,
                  labelSize: 'md',
                  radioSize: 'small',
                  label: t(
                    'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.radioButtons.label1',
                  ),
                  dataTestId: '10-year-term-radio',
                },
                {
                  value: 20,
                  labelSize: 'md',
                  radioSize: 'small',
                  // If user is 64 or older, disable 20-year-term radio button
                  disabled: age >= 64,
                  label: t(
                    'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.radioButtons.label2',
                  ),
                  dataTestId: '20-year-term-radio',
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={baseRadioLabelWrapperStyles}>
              <RadioLabel>
                {t(
                  'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.selectSmoker',
                )}
              </RadioLabel>
              <InfoTooltip
                sx={baseInfoTooltipStyles}
                copy={
                  <Box sx={{ p: '0.3rem' }}>
                    <Text size="xs" color={themeColorTypes.WHITE}>
                      {t(
                        'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.tooltip.text',
                      )}
                    </Text>
                    <Box
                      component="ul"
                      sx={{
                        listStyleType: 'disc',
                        pl: '1rem',
                        '& > li': { pt: '0.25rem' },
                      }}
                    >
                      <Box component="li">
                        <Text size="xs" color={themeColorTypes.WHITE}>
                          {t(
                            'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.tooltip.bullet1',
                          )}
                        </Text>
                      </Box>
                      <Box component="li">
                        <Text size="xs" color={themeColorTypes.WHITE}>
                          {t(
                            'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.tooltip.bullet2',
                          )}
                        </Text>
                      </Box>
                      <Box component="li">
                        <Text size="xs" color={themeColorTypes.WHITE}>
                          {t(
                            'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.tooltip.bullet3',
                          )}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                }
              />
            </Box>
            <RadioGroup
              name="smoker"
              defaultActive={insuranceQuotesParams.smoker}
              sx={{ display: 'flex', flexDirection: 'row' }}
              onChange={(value: string) =>
                setInsuranceQuotesParams({
                  ...insuranceQuotesParams,
                  smoker: value === 'true',
                })
              }
              radioButtons={[
                {
                  value: false,
                  labelSize: 'md',
                  radioSize: 'small',
                  label: t(
                    'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.radioButtons.label3',
                  ),
                  dataTestId: 'non-smoker-radio',
                },
                {
                  value: true,
                  label: t(
                    'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.radioButtons.label4',
                  ),
                  labelSize: 'md',
                  radioSize: 'small',
                  dataTestId: 'smoker-radio',
                },
              ]}
            />
          </Grid>
        </Grid>

        {/* Lower body */}
        <Box sx={{ mt: '2.2rem' }}>
          <Box
            sx={{
              display: 'flex',
              px: sectionPaddingX,
              justifyContent: isMobile ? 'center' : 'flex-start',
            }}
          >
            <Heading variant="h4" align={isMobile ? 'center' : 'left'}>
              {t(
                'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.label',
              )}
            </Heading>

            {!isMobile && isWorking && (
              <Box sx={{ ml: '1.5rem' }}>
                <LoadingSpinner delay="0" size="23px" />
              </Box>
            )}
          </Box>

          <Box sx={{ mt: '1rem', px: '3.5rem' }}>
            <QuoteCardCarousel
              quotes={quotes}
              isWorking={isWorking || _isEmpty(quotes)}
              selectedCoverageAmount={selectedQuote?.amount}
              handleSelectCoverageAmount={setSelectedCoverage}
            />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              px: sectionPaddingX,
              mt: isMobile ? '2.5rem' : '1.75rem',
              justifyContent: isMobile ? 'center' : 'flex-end',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Text
                size="xxxs"
                sx={{ width: '119px' }}
                color={themeColorTypes.GREY}
              >
                {t(
                  'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.logoDescription',
                )}
              </Text>
              <RbcInsuranceLogo />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mb: '2.5rem',
            width: '100%',
            display: 'flex',
            px: sectionPaddingX,
            mt: isMobile ? '2.5rem' : '3rem',
            alignItems: isMobile ? 'center' : 'flex-end',
            justifyContent: isMobile ? 'center' : 'flex-end',
            flexDirection: isMobile ? 'column-reverse' : 'row',
          }}
        >
          <Button
            size="xs"
            label={t(
              'documents:components.LifeInsuranceQuotes.components.LifeInsuranceMultiQuotes.buttons.button1Label',
            )}
            onClick={handlePageBackward}
            color={themeColorTypes.GREY}
            startIcon={<ArrowBackIcon />}
            colorVariant={themeColorVariants.DARK}
            sx={{ mt: isMobile ? '0.85rem' : '0' }}
          />

          <QuoteSubmissionForm
            term={term}
            userId={userId}
            gender={gender}
            smoker={smoker}
            setShowModal={setShowModal}
            quote={selectedQuote?.quote}
            coverageAmount={selectedCoverage}
            disabled={isWorking || _isEmpty(quotes) || !selectedQuote}
          />
        </Box>
      </Box>
    </>
  )
}

export default LifeInsuranceMultiQuotes
