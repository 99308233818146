import { CONSTANTS } from '@epilogue/common'

import { getCookie, removeCookie, setCookie } from '../../lib/cookies'
import queryString from '../../lib/queryString'

export const setQidCookies = () => {
  const { qid, token } = queryString.parseUrl(window.location.href)

  // query params must include both qid + token to store as cookies
  if (qid && token) {
    setCookie(CONSTANTS.cookieNames.QID, qid)
    setCookie(CONSTANTS.cookieNames.TOKEN, token)
  }
}

export const getQidCookies = () => {
  try {
    // both qid + token cookies must exist in order to return both
    if (
      getCookie(CONSTANTS.cookieNames.QID) &&
      getCookie(CONSTANTS.cookieNames.TOKEN)
    ) {
      const qid = getCookie(CONSTANTS.cookieNames.QID)
      const token = getCookie(CONSTANTS.cookieNames.TOKEN)

      return { qid, token }
    }
    return {}
  } catch {
    // If something goes wrong, just fallback to sending an empty object
    return {}
  }
}

export const deleteQidCookies = () => {
  removeCookie(CONSTANTS.cookieNames.QID)
  removeCookie(CONSTANTS.cookieNames.TOKEN)
}
