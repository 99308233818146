import React, { Dispatch } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import { PROVINCES } from '../../../utils/provinces'
import Select from '../../atoms/Select'
import TextInput from '../../atoms/TextInput'
import {
  CategorySelectValue,
  ProvinceSelectValue,
  QueryAction,
  QueryActionType,
  QueryState,
} from '../hooks/useCharityFinderQueryReducer'

interface SearchBarProps {
  queryState: QueryState
  dispatchUpdateQuery: Dispatch<QueryAction>
}

const CharityFinderSearchBar = (props: SearchBarProps) => {
  const {
    queryState: { queryInputValue, provinceInputValue, categoryInputValue },
    dispatchUpdateQuery,
  } = props

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: '1rem',
        alignItems: 'end',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          top: '0.2rem',
          width: '100%',

          '& div': {
            mb: '0',
          },
        }}
      >
        <TextInput
          name="charity-search-query"
          value={queryInputValue}
          placeholder={t(`components:CharityFinder.searchPlaceholder`)}
          variant="outlined"
          data
          onInputChange={(value: string) => {
            dispatchUpdateQuery({
              type: QueryActionType.UpdateSearchQuery,
              value,
            })
            dispatchUpdateQuery({
              type: QueryActionType.UpdateCategory,
              value: 'all',
            })
          }}
          sx={{ pr: '2.75rem' }}
        />
        {queryInputValue && (
          <IconButton
            sx={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}
            onClick={() => {
              dispatchUpdateQuery({
                type: QueryActionType.UpdateSearchQuery,
                value: '',
              })
            }}
          >
            <ClearIcon />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          width: '100%',
        }}
      >
        <Select
          sx={{ flex: isMobile ? '0 50%' : undefined }}
          placeholder={t(`components:CharityFinder.locationLabel`)}
          selectId="province-select"
          value={provinceInputValue}
          onInputChange={(value: ProvinceSelectValue) => {
            dispatchUpdateQuery({
              type: QueryActionType.UpdateProvince,
              value,
            })
          }}
          options={[
            {
              value: 'all',
              label: t(`components:CharityFinder.selectAll`),
            },
            {
              value: 'canada',
              label: t(`components:CharityFinder.selectCanada`),
            },
            ...Object.values(PROVINCES).map(({ value, tKey }) => ({
              value,
              label: t(tKey),
            })),
          ]}
        />

        <Select
          disabled={!!queryInputValue}
          sx={{ flex: isMobile ? '0 50%' : undefined }}
          selectId="category-select"
          placeholder={t(`components:CharityFinder.categoryLabel`)}
          value={categoryInputValue}
          onInputChange={(value: CategorySelectValue) => {
            dispatchUpdateQuery({
              type: QueryActionType.UpdateCategory,
              value,
            })
          }}
          options={[
            {
              value: 'all',
              label: t(`components:CharityFinder.selectAll`),
            },
            ...Object.values(CONSTANTS.charityCategories).map((option) => ({
              value: option,
              label: option,
            })),
          ]}
        />
      </Box>
    </Box>
  )
}

export default CharityFinderSearchBar
