import React, { useEffect } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import { InvestmentAccountTypes } from '../../../../../../Questionnaire/components/Question/InvestmentAccounts/utils/getInvestmentAccountsOptions'
import useStaticDocDownload from '../../hooks/useStaticDocDownload'

interface Props {
  show: boolean
  docType: any
  answerStore: any
  onClose: () => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const StaticDocDownloadModal = ({
  show,
  onClose,
  docType,
  answerStore,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { t } = useTranslation()

  const { DownloadStatus, requestDocDownload, status, setStatus } =
    useStaticDocDownload({
      docType,
      answerStore,
      dispatchSetGlobalErrorMessage,
    })

  const handleOnClose = () => {
    if (status !== DownloadStatus.IDLE) {
      setStatus(DownloadStatus.IDLE)
    }
    onClose()
  }

  useEffect(() => {
    /*
     * Auto-close modal if download is successful
     * or errors and reset status to idle
     */
    if (status === DownloadStatus.ERROR || status === DownloadStatus.SUCCESS) {
      handleOnClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useEffect(() => {
    if (show)
      amplitude.sendEvent('ViewedStaticDocDownloadModal', {
        docType,
      })
  }, [show, docType])

  return (
    <>
      {document && (
        <Modal
          show={show}
          maxWidth="sm"
          onClose={handleOnClose}
          data-testid="static-doc-download-modal"
        >
          <Box
            sx={{
              p: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <LightbulbOutlinedIcon sx={{ fontSize: '3.3rem' }} />

              <Box
                sx={{
                  mt: '1.2rem',
                  mb: '1.75rem',
                  textAlign: 'center',
                }}
              >
                <Heading variant="h3">
                  {t('components:modal.StaticDocDownloadModal.label')}
                </Heading>
              </Box>
            </Box>

            <Text
              size="md"
              align="center"
              display="initial"
              sx={{ lineHeight: '1.4', mb: '1.2rem' }}
            >
              {t('components:modal.StaticDocDownloadModal.subLabel.1')}
            </Text>

            <Text
              size="md"
              align="center"
              display="initial"
              sx={{ lineHeight: '1.4' }}
            >
              {t('components:modal.StaticDocDownloadModal.subLabel.2')}
            </Text>

            <Box
              sx={{
                mt: '2rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                label={t('common:continue')}
                variant="contained"
                onClick={() => {
                  const {
                    hasResps,
                    hasLifeInsurance,
                    hasForeignProperty,
                    canadianRealEstate,
                    investmentAccounts = [],
                  } = answerStore

                  amplitude.sendEvent('ClickedStaticDocDownloadButton', {
                    docType,
                    hasResps,
                    hasLifeInsurance,
                    hasForeignProperty,
                    canadianRealEstate,
                    hasRrsp: investmentAccounts.includes(
                      InvestmentAccountTypes.RRSP,
                    ),
                    hasFhsa: investmentAccounts.includes(
                      InvestmentAccountTypes.FHSA,
                    ),
                    hasTfsa: investmentAccounts.includes(
                      InvestmentAccountTypes.TFSA,
                    ),
                    hasRrif: investmentAccounts.includes(
                      InvestmentAccountTypes.RRIF,
                    ),
                  })

                  requestDocDownload()
                }}
                endIcon={<ArrowForwardIcon />}
                data-testid="static-doc-download-modal-primary-button"
                //
                // Spinner-related props
                //
                spinnerPadding="0.375rem 1.5rem"
                spinnerColor={themeColorTypes.GREY}
                spinnerColorVariant={themeColorVariants.DARK}
                working={status === DownloadStatus.WORKING}
              />
            </Box>
          </Box>
        </Modal>
      )}
    </>
  )
}

export default StaticDocDownloadModal
