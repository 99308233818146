import commonName from './commonName'

export default {
  CHECK_AUTH: `${commonName}/CHECK_AUTH`,
  SET_AUTH: `${commonName}/SET_AUTH`,
  AUTH_WORKING: `${commonName}/AUTH_WORKING`,
  SET_LOGIN_REDIRECT: `${commonName}/SET_LOGIN_REDIRECT`,
  SET_GLOBAL_ERROR_MESSAGE: `${commonName}/SET_GLOBAL_ERROR_MESSAGE`,
  SET_WORLD: `${commonName}/SET_WORLD`,
  SET_AVAILABLE_WORLDS: `${commonName}/SET_AVAILABLE_WORLDS`,
  UPDATE_TOAST: `${commonName}/UPDATE_TOAST`,
  SET_CHARITY_PARTNER: `${commonName}/SET_CHARITY_PARTNER`,
  UPDATE_SIDEBAR: `${commonName}/UPDATE_SIDEBAR`,
}
