import React from 'react'

import CreateIcon from '@mui/icons-material/Create'
import Diversity1Icon from '@mui/icons-material/Diversity1'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ForestIcon from '@mui/icons-material/Forest'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import PaletteIcon from '@mui/icons-material/Palette'
import PetsIcon from '@mui/icons-material/Pets'
import PublicIcon from '@mui/icons-material/Public'
import SchoolIcon from '@mui/icons-material/School'
import SportsIcon from '@mui/icons-material/Sports'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import { Avatar, Box, useMediaQuery } from '@mui/material'

import { CharityCategory } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'
import Text from '../../atoms/Text'

const mapCharityIcon = (category?: CharityCategory | 'custom') => {
  switch (category) {
    case 'Animals':
      return PetsIcon
    case 'Arts & Culture':
      return PaletteIcon
    case 'Community Benefit':
      return Diversity1Icon
    case 'Education':
      return SchoolIcon
    case 'Environment':
      return ForestIcon
    case 'Health':
      return LocalHospitalIcon
    case 'Indigenous Led':
      return Diversity3Icon
    case 'International':
      return PublicIcon
    case 'Religion':
      return MenuBookIcon
    case 'Social Service':
      return VolunteerActivismIcon
    case 'Sports & Recreation':
      return SportsIcon
    case 'custom':
      return CreateIcon
    default:
      return FavoriteIcon
  }
}

const CharityCategoryAvatar = ({
  category,
}: {
  category?: CharityCategory | 'custom'
}) => {
  const CharityIcon = mapCharityIcon(category)
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.down('xl'))

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '5rem',
        alignItems: 'center',
        mx: isTablet ? undefined : '2rem',
        mr: isTablet ? '1rem' : undefined,
        gap: '0.23rem',
        flexShrink: 0,
      }}
    >
      <Avatar
        alt={`${category} icon`}
        sx={{
          bgcolor:
            themeColors[themeColorTypes.ACCENT_3][themeColorVariants.MAIN],
        }}
      >
        <CharityIcon />
      </Avatar>
      {category && category !== 'custom' && (
        <Text align="center" size="xxs" variant="display">
          {t(`components:CharityFinder.categories.${category}`)}
        </Text>
      )}
    </Box>
  )
}

export default CharityCategoryAvatar
