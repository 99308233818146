import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../common/components/atoms/Button'
import questionnaireActions from '../../../../../../ducks/questionnaire/questionnaireActions'

const UserNamePasswordAgreeButton = ({
  email,
  password,
  closeModal,
  mailingList,
  dispatchStartCreateAccount,
}) => {
  const { t } = useTranslation()

  return (
    <Button
      type="button"
      label={t(
        'molecules:CreateAccountForm.components.AgreeModal.components.AgreeButton',
      )}
      onClick={() => {
        dispatchStartCreateAccount({ email, password, mailingList })
        closeModal()
      }}
      variant="contained"
      endIcon={<ArrowForwardRoundedIcon />}
      data-testid="username-password-agree-button"
    />
  )
}

UserNamePasswordAgreeButton.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  mailingList: PropTypes.bool.isRequired,
  dispatchStartCreateAccount: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchStartCreateAccount: ({ password, email, mailingList }) =>
    dispatch(
      questionnaireActions.startCreateAccount({ email, password, mailingList }),
    ),
})

export default connect(null, mapDispatchToProps)(UserNamePasswordAgreeButton)
