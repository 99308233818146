import _get from 'lodash/get'

import { useQuery } from '@tanstack/react-query'

import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../../../../common/utils/queryKeys'
import { Appointment } from '../../../../../../../../../types/appointment.types'

interface Props {
  appointmentType: Appointment['type']
  dispatchSetGlobalErrorMessage: (errorMessage: string) => void
}

export default ({ appointmentType, dispatchSetGlobalErrorMessage }: Props) =>
  useQuery({
    queryKey: queryKeys[queryKeysBase.appointments](),

    queryFn: () =>
      request({
        method: 'GET',
        url: `${PATHS.APPOINTMENT}/${appointmentType}`,
      }),

    select: (res) => _get(res, ['data', 'payload'], []),

    retry: process.env.REACT_APP_ENV === 'production' ? 2 : 0,

    onError: (error) =>
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to get appointments. Please refresh and try again.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      }),
  })
