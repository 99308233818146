import _dropRight from 'lodash/dropRight'

import navigationTypes from './navigationTypes'

const initialState = {
  working: false,
  questionStack: [],
  interceptModal: {},
  preventNext: false,
  preventPrevious: false,
  preventAnimation: false,
  navDirection: 'forward',
  nextUnansweredQuestion: {},
  sectionProgress: undefined,
  dashboardRedirect: undefined,
  questionnaireProgress: undefined,
  editQuestionnaireFromDashboard: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case navigationTypes.PUSH_QUESTION:
      return {
        ...state,
        navDirection: 'forward',
        questionStack: [
          ...action.payload.cleansedQuestionStack,
          action.payload.nextQuestion,
        ],
      }

    case navigationTypes.PREVIOUS_QUESTION:
      return {
        ...state,
        navDirection: 'backward',
        questionStack: _dropRight(state.questionStack),
      }

    case navigationTypes.UPDATE_QUESTION_STACK:
      return {
        ...state,
        navDirection: 'backward',
        questionStack: action.payload.questionStack,
      }

    case navigationTypes.UPDATE_SECTION_PROGRESS:
      return { ...state, sectionProgress: action.payload.progress }

    case navigationTypes.UPDATE_QUESTIONNAIRE_PROGRESS:
      return { ...state, questionnaireProgress: action.payload.progress }

    case navigationTypes.PREVENT_NEXT:
      return { ...state, preventNext: action.payload.prevent }

    case navigationTypes.PREVENT_PREVIOUS:
      return { ...state, preventPrevious: action.payload.prevent }

    case navigationTypes.DASHBOARD_REDIRECT:
      return { ...state, dashboardRedirect: action.payload.redirect }

    case navigationTypes.PREVENT_ANIMATION:
      return { ...state, preventAnimation: action.payload.prevent }

    case navigationTypes.WORKING:
      return { ...state, working: action.payload.working }

    case navigationTypes.SET_NEXT_UNANSWERED_QUESTION:
      return { ...state, nextUnansweredQuestion: action.payload.question }

    case navigationTypes.EDIT_QUESTIONNAIRE_FROM_DASHBOARD:
      return { ...state, editQuestionnaireFromDashboard: true }

    case navigationTypes.INTERCEPT_MODAL:
      return { ...state, interceptModal: action.payload }

    default:
      return state
  }
}
