import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Qu'est-ce qu'un Fonds enregistré de revenu de retraite (FERR) ?

Les gens utilisent les REER et d'autres comptes enregistrés pendant leurs "années de travail" pour épargner et investir de manière à minimiser les impôts et maximiser les économies.

À la fin de l'année où une personne atteint l'âge de 71 ans, elle doit convertir son REER en un Fonds enregistré de revenu de retraite (FERR). Les économies qu'une personne accumule dans son REER sont versées à partir de son FERR pendant le reste de sa vie.

Contrairement à un REER, une personne ne peut pas faire de nouvelles cotisations à un FERR.

#### Retraits d'un FERR

Une personne doit commencer à retirer des fonds de son FERR l'année suivant son ouverture. Il n'y a pas de montant maximal pouvant être retiré d'un FERR en une année, mais il y a un minimum basé sur l'âge de la personne. Le pourcentage du FERR devant être versé chaque année augmente progressivement avec le temps.

#### FERR et impôts

Les cotisations à un REER (ou un compte enregistré similaire) sont déductibles d'impôt. Cela signifie que l'impôt qui serait autrement payé sur l'argent que quelqu'un cotise à un REER n'est pas payé lorsque cet argent est gagné. L'impôt est plutôt reporté à une date ultérieure.

Ce report d'impôt prend fin lorsque des fonds sont retirés du FERR de la personne. Les retraits du FERR sont inclus dans le revenu imposable de l'année où ils sont reçus. Pendant ce temps, tous les actifs qui ne sont pas retirés du FERR continuent de croître et de générer des revenus de manière différée sur le plan fiscal.

#### Conversion d'un REER en FERR

Si vous approchez de l'âge de la retraite, vous devriez envisager quand et comment vous convertirez votre REER en FERR. Pour entamer la conversation, vous pouvez contacter un conseiller de votre institution financière.
    `
    : `
#### What is a RRIF?

People use RRSPs and other registered accounts during their "working years" to save and invest in a way that minimizes tax and maximizes savings.

By the end of the year in which someone turns 71, they must convert their RRSP to a Registered Retirement Income Fund (RRIF). The savings someone accumulates in their RRSP are paid out from their RRIF over the rest of their lifetime.

Unlike an RRSP, a person cannot make any new contributions to a RRIF.

#### RRIF Withdrawals

A person must start withdrawing from their RRIF the year after they open it. There is no maximum amount that can be withdrawn from a RRIF in any year, but there is a minimum that is based on the person's age. The percentage of the RRIF that needs to be paid out each year gradually increases over time.

#### RRIFs and Taxes

Contributions to an RRSP (or similar registered account) are tax deductible. This means the tax that would otherwise be paid on the money that someone contributes to an RRSP does not get paid when that money is earned. The tax is instead deferred to a later time.

This tax deferral ends as funds are withdrawn from the person's RRIF. RRIF withdrawals are included as taxable income in the year they are received. In the meantime, any assets that are not withdrawn from the RRIF continue to grow and earn income on a tax-deferred basis.

#### Converting an RRSP to a RRIF

If you are approaching retirement age, you should be considering when and how you will convert your RRSP to a RRIF. To get the conversation started, you can reach out to an advisor at your financial institution.
`
