import _isEmpty from 'lodash/isEmpty'

import argumentRequired from '../../../common/utils/argumentRequired'
import sectionIsValid from './sectionIsValid'

export default ({
  data,
  abTest,
  question,
  referrer,
  payments,
  isPartner,
  answerStore,
  accountCreated,
  charityPartner,
  documents = argumentRequired('documents'),
}) => {
  const {
    hideFromPartner,
    hideAfterAccount,
    hideAfterPayment,
    hideAfterDocumentGeneration,
  } = question

  const sectionValid = sectionIsValid({
    data,
    abTest,
    question,
    referrer,
    isPartner,
    answerStore,
    charityPartner,
  })

  const shouldHideFromPartner = isPartner && !!hideFromPartner
  const shouldHideAfterAccount = accountCreated && !!hideAfterAccount
  const shouldHideAfterPayment = !_isEmpty(payments) && !!hideAfterPayment
  const shouldHideAfterDocumentGeneration =
    !_isEmpty(documents) && !!hideAfterDocumentGeneration

  return (
    sectionValid &&
    !shouldHideFromPartner &&
    !shouldHideAfterAccount &&
    !shouldHideAfterPayment &&
    !shouldHideAfterDocumentGeneration
  )
}
