import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'

import questionnaireName from './questionnaireName'

export default {
  getQuestionnaire: (state) => _get(state, 'questionnaire'),
  getNewQuestionnaire: (state) =>
    _get(state[questionnaireName], 'newQuestionnaire'),
  getAnswerStore: (state) => _get(state[questionnaireName], 'answerStore'),
  getAnswerCache: (state) => _get(state[questionnaireName], 'answerCache'),
  getQuestionnaireId: (state) =>
    _get(state[questionnaireName], 'questionnaireId'),
  getAccountCreated: (state) =>
    _get(state[questionnaireName], 'accountCreated'),
  getQuestionFragments: (state) =>
    _get(state[questionnaireName], 'questionFragments'),
  getQuestionnaireValid: (state) =>
    _get(state[questionnaireName], 'questionnaireValid'),
  getQuestionnaireStarted: (state, world) => {
    const questionnaireStarted = _get(
      state[questionnaireName],
      'questionnairesStarted',
      [],
    )
    return questionnaireStarted.includes(
      CONSTANTS.worldTypes[world || CONSTANTS.worldTypes.will.type].type,
    )
  },
  getQuestionnairesStarted: (state) =>
    _get(state[questionnaireName], 'questionnairesStarted'),
  getAnswerStoreUpdatedSinceGenerate: (state) =>
    _get(state[questionnaireName], 'answerStoreUpdatedSinceGenerate'),
  getQuestionnaireFirstCompleted: (state) =>
    _get(state[questionnaireName], 'questionnaireFirstCompleted'),
  getNameStore: (state) => _get(state[questionnaireName], 'nameStore'),
  getToken: (state) => _get(state[questionnaireName], 'token'),
  getReferrer: (state) => _get(state[questionnaireName], 'referrer'),
  getAbTest: (state) => _get(state[questionnaireName], 'abTest'),
}
