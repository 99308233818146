import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import Text from '../../atoms/Text'

const useStyles = makeStyles({
  hozBar: {
    '&:first-of-type': {
      display: 'none',
    },
  },
})

const BlobProgress = ({ data, current }) => {
  const theme = useTheme()
  const classes = useStyles()

  const cleansedData = data.filter((item) => item.triggered)
  const currentIndex = cleansedData.findIndex((item) => item.id === current)

  return (
    <Box
      top={0}
      width="100%"
      display="flex"
      maxWidth="36rem"
      position="absolute"
      margin="1.5rem 0 3rem 1rem"
      justifyContent="space-between"
    >
      <Box
        width="100%"
        display="flex"
        maxWidth="36rem"
        position="absolute"
        justifyContent="space-between"
      >
        {cleansedData.map(({ label }, index) => {
          const isActive = index <= currentIndex

          return (
            <Fragment key={label}>
              <Box
                top="11px"
                width="100%"
                height="2px"
                margin="0 -22px"
                position="relative"
                className={classes.hozBar}
                bgcolor={
                  isActive
                    ? 'primary.main'
                    : theme.palette[themeColorTypes.GREY][
                        themeColorVariants.MAIN
                      ]
                }
              />
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                zIndex={theme.zIndex.custom[1]}
              >
                <Box
                  display="flex"
                  width="1.5rem"
                  height="1.5rem"
                  border="solid 2px"
                  alignItems="center"
                  marginBottom="0.25rem"
                  justifyContent="center"
                  borderRadius={theme.shape.borderRadiusFull}
                  bgcolor={
                    theme.palette[themeColorTypes.NEUTRAL][
                      themeColorVariants.LIGHTER
                    ]
                  }
                  borderColor={
                    isActive
                      ? 'primary.main'
                      : theme.palette[themeColorTypes.GREY][
                          themeColorVariants.MAIN
                        ]
                  }
                >
                  {isActive && (
                    <Box
                      width="0.75rem"
                      height="0.75rem"
                      bgcolor="primary.main"
                      borderRadius={theme.shape.borderRadiusFull}
                    />
                  )}
                </Box>

                <Text
                  size="xxs"
                  align="center"
                  color={isActive ? 'brand' : 'grey'}
                  data-testid={`blob-progress-label-${label}`}
                >
                  {label}
                </Text>
              </Box>
            </Fragment>
          )
        })}
      </Box>
    </Box>
  )
}

BlobProgress.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  current: PropTypes.string.isRequired,
}

export default BlobProgress
