import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { Box, SxProps, Theme } from '@mui/material'

interface ShadowBoxProps {
  children: ReactNode
  sx?: SxProps<Theme>
}

const ShadowBox = ({ children, sx }: ShadowBoxProps) => {
  const [showShadow, setShowShadow] = useState(false)
  const boxRef = useRef<HTMLDivElement>(null)

  const checkScroll = () => {
    if (boxRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = boxRef.current
      // Only show shadow if content is scrollable AND not at bottom
      const isScrollable = scrollHeight > clientHeight
      const isNotAtBottom =
        Math.abs(scrollHeight - clientHeight - scrollTop) > 1
      setShowShadow(isScrollable && isNotAtBottom)
    }
  }

  useEffect(() => {
    checkScroll()
    // Add resize observer to check scroll when container size changes
    const resizeObserver = new ResizeObserver(checkScroll)
    if (boxRef.current) {
      resizeObserver.observe(boxRef.current)
    }
    return () => resizeObserver.disconnect()
  }, [children])

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      <Box
        ref={boxRef}
        onScroll={checkScroll}
        sx={{ width: '100%', height: '100%', ...sx }}
      >
        {children}
      </Box>
      <Box
        sx={{
          left: 0,
          right: 0,
          bottom: 0,
          height: '16px',
          position: 'absolute',
          pointerEvents: 'none',
          opacity: showShadow ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
          background:
            'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2))',
        }}
      />
    </Box>
  )
}

export default ShadowBox
