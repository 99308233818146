import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import SidebarCaption from './SidebarCaption'

const SidebarImage = ({ element }) => (
  <Box sx={{ my: '1rem' }}>
    <img src={element.src} alt={element.alt} />
    {element.caption && <SidebarCaption text={element.caption} />}
  </Box>
)

SidebarImage.propTypes = {
  element: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    caption: PropTypes.string,
  }).isRequired,
}

export default SidebarImage
