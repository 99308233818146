import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { validators } from '../../../../../../../scenes/Questionnaire/utils/validation/validation'
import commonActions from '../../../../../../ducks/commonActions'
import useCheckValidEmail from '../../../../../../hooks/useCheckValidEmail'
import amplitude from '../../../../../../lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../../../request'
import { themeColorTypes } from '../../../../../../styles/muiTheme'
import Button from '../../../../../atoms/Button'
import Heading from '../../../../../atoms/Heading'
import Modal from '../../../../../atoms/Modal'
import TextInput from '../../../../../atoms/TextInput'

const ChangeEmailAddressModal = ({
  show,
  onClose,
  dispatchSetGlobalErrorMessage,
}) => {
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [requestWorking, setRequestWorking] = useState(false)

  const confirmEmailMatch = email.toLowerCase() === confirmEmail.toLowerCase()

  useEffect(() => {
    if (show) amplitude.sendEvent('ViewedChangeEmailAddressModal')
  }, [show])

  const { validationMessage, validationWorking, handleCheckValidEmail } =
    useCheckValidEmail({ email, dispatchSetGlobalErrorMessage })

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (validators.email(email) && confirmEmailMatch) {
      setRequestWorking(true)

      try {
        await request({
          method: 'PATCH',
          url: PATHS.USER_EMAIL,
          data: { newEmail: email },
        })

        window.location.href = '/login/?emailUpdate=true'
      } catch (error) {
        const { status } = error.response
        const statusIs400 = status === 400

        errorHandler({
          error,
          reportError: !statusIs400,
          fallbackErrorMessage:
            'Unable to send email update request. Please try again later.',
          onError: (resolvedErrorMessage) =>
            dispatchSetGlobalErrorMessage(resolvedErrorMessage),
        })
      } finally {
        setRequestWorking(false)
      }
    }
  }

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      maxWidth="md"
      data-testid="change-email-address-modal"
      onClose={(event, reason) => {
        amplitude.sendEvent('ClosedChangeEmailAddressModal', { reason })
        onClose(event, reason)
      }}
    >
      <Box sx={{ p: { xxs: '1rem', md: '2rem' } }}>
        <Heading align="center" variant="h3">
          {t(
            'pages:AccountSettings.changeEmailAddress.components.ChangeEmailAddressModal.label',
          )}
        </Heading>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '300px',
            display: 'flex',
            m: '1.8rem auto 0',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <TextInput
            autoFocus
            type="email"
            value={email}
            disabled={requestWorking}
            working={validationWorking}
            data-testid="change-email-address-email-input"
            placeholder={t(
              'pages:AccountSettings.changeEmailAddress.components.ChangeEmailAddressModal.textInput1.placeholder',
            )}
            isInvalid={!!validationMessage}
            validationMessage={validationMessage}
            onInputChange={(val) => {
              setEmail(val)
              if (val?.length > 6 && validators.email(val)) {
                handleCheckValidEmail(val)
              }
            }}
          />

          <TextInput
            type="email"
            value={confirmEmail}
            disabled={requestWorking}
            onInputChange={setConfirmEmail}
            data-testid="confirm-email-address-email-input"
            isInvalid={confirmEmail.length > 5 && !confirmEmailMatch}
            validationMessage={t('validation:validation.confirmEmailMustMatch')}
            placeholder={t(
              'pages:AccountSettings.changeEmailAddress.components.ChangeEmailAddressModal.textInput2.placeholder',
            )}
          />

          <Button
            type="submit"
            variant="contained"
            label={t('common:submit')}
            sx={{ mt: '0.5rem' }}
            working={requestWorking}
            spinnerPadding="0.375rem 2.909rem"
            spinnerColor={themeColorTypes.WHITE}
            data-testid="change-email-address-submit-button"
            disabled={
              !!(
                !validators.email(email) ||
                !confirmEmailMatch ||
                validationWorking ||
                validationMessage
              )
            }
          />
        </Box>
      </Box>
    </Modal>
  )
}

ChangeEmailAddressModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(null, mapDispatchToProps)(ChangeEmailAddressModal)
