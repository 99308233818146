import React, { cloneElement, Dispatch, SetStateAction, useRef } from 'react'

import Modal from '../../../../../../../common/components/atoms/Modal'
import { CustomModalData } from '../../types/button.types'

interface Props {
  show: boolean
  onClose: () => void
  data: CustomModalData
  setShowModal: Dispatch<SetStateAction<boolean>>
}

const CustomModal = ({
  show,
  onClose,
  setShowModal,
  data: { element, fullBleed = false },
}: Props) => {
  const modalContentRef = useRef(null)

  return (
    <Modal
      show={show}
      onClose={onClose}
      fullBleed={fullBleed}
      ref={{ modalContentRef }}
      setShowModal={setShowModal}
      data-testid="document-card-custom-modal"
    >
      {cloneElement(element, { modalContentRef, setShowModal })}
    </Modal>
  )
}

export default CustomModal
