import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'

import {
  PROVINCES,
  SUPPORTED_PROVINCES,
} from '../../../../common/utils/provinces'

const section = CONSTANTS.questionnaireSectionTypes.FAMILY.id

export default {
  id: section,
  description: { tKey: 'questionnaire.family:description' },
  trigger: {
    $or: [
      { hasPartner: 'yes' },
      { hasChildren: 'yes' },
      { hasStepChildren: 'yes' },
      { hasPets: 'yes' },
    ],
  },

  questions: [
    {
      id: 1572359419,
      pathname: 'intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.family:intro.highlightPillText',
      },
      label: { tKey: 'questionnaire.family:intro.label' },
      subLabel: { tKey: 'questionnaire.family:intro.subLabel' },
    },

    {
      id: 1572359420,
      pathname: 'partner-name',
      section,
      reviewLabel: { tKey: 'questionnaire.family:partner-name.reviewLabel' },
      label: { tKey: 'questionnaire.family:partner-name.label' },
      fields: [
        {
          name: 'partnerFirstName',
          type: 'text',
          optional: false,
          placeholder: {
            tKey: 'questionnaire.family:partner-name.fields.partnerFirstName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.family:partner-name.fields.partnerFirstName.reviewLabel',
          },
        },
        {
          name: 'partnerMiddleName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.family:partner-name.fields.partnerMiddleName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.family:partner-name.fields.partnerMiddleName.reviewLabel',
          },
          optional: true,
          hideOnReviewIfEmpty: true,
        },
        {
          name: 'partnerLastName',
          type: 'text',
          optional: false,
          placeholder: {
            tKey: 'questionnaire.family:partner-name.fields.partnerLastName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.family:partner-name.fields.partnerLastName.reviewLabel',
          },
        },
        {
          name: 'partnerReference',
          type: 'select',
          label: {
            tKey: 'questionnaire.family:partner-name.fields.partnerReference.label',
          },
          placeholder: {
            tKey: 'questionnaire.family:partner-name.fields.partnerReference.placeholder',
          },
          hideOnReview: true,
          options: [
            {
              value: CONSTANTS.partnerValues.MY_WIFE,
              label: {
                tKey: 'questionnaire.family:partner-name.fields.partnerReference.options.myWife',
              },
            },
            {
              value: CONSTANTS.partnerValues.MY_HUSBAND,
              label: {
                tKey: 'questionnaire.family:partner-name.fields.partnerReference.options.myHusband',
              },
            },
            {
              value: CONSTANTS.partnerValues.MY_SPOUSE,
              label: {
                tKey: 'questionnaire.family:partner-name.fields.partnerReference.options.mySpouse',
              },
            },
            {
              value: CONSTANTS.partnerValues.MY_PARTNER,
              label: {
                tKey: 'questionnaire.family:partner-name.fields.partnerReference.options.myPartner',
              },
            },
            {
              value: CONSTANTS.partnerValues.MY_FIANCEE,
              label: {
                tKey: 'questionnaire.family:partner-name.fields.partnerReference.options.myFiancee',
              },
            },
            {
              value: 'none',
              label: {
                tKey: 'questionnaire.family:partner-name.fields.partnerReference.options.none',
              },
            },
          ],
        },
      ],
      trigger: { hasPartner: 'yes' },
    },

    {
      id: 1572359422,
      pathname: 'partner-gender',
      section,
      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.family:partner-gender.label',
        options: { partnerFirstName },
      }),
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.family:partner-gender.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-gender.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'partnerGender',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.family:partner-gender.fields.partnerGender.reviewLabel',
          },
          options: [
            {
              value: 'male',
              label: {
                tKey: 'questionnaire.family:partner-gender.fields.partnerGender.options.male.label',
              },
            },
            {
              value: 'female',
              label: {
                tKey: 'questionnaire.family:partner-gender.fields.partnerGender.options.female.label',
              },
            },
            {
              value: 'other',
              label: {
                tKey: 'questionnaire.family:partner-gender.fields.partnerGender.options.other.label',
              },
            },
            {
              value: 'notSay',
              label: {
                tKey: 'questionnaire.family:partner-gender.fields.partnerGender.options.notSay.label',
              },
            },
          ],
        },
      ],
      trigger: {
        $and: [{ hasPartner: 'yes' }, { partnerFirstName: { $exists: true } }],
      },
    },

    {
      id: 1572280922,
      pathname: 'partner-will',
      section,
      hideFromPartner: true,
      hideAfterPayment: true,
      label: ({ answerStore: { province, product } }) => {
        const translatedProvince = _get(
          Object.values(PROVINCES).find(({ value }) => value === province),
          'tKey',
          province,
        )

        return product === 'will'
          ? {
              tKey: 'questionnaire.family:partner-will.label.case1',
              options: { province: translatedProvince },
            }
          : {
              tKey: 'questionnaire.family:partner-will.label.case2',
              options: { province: translatedProvince },
            }
      },

      label2: ({
        answerStore: { partnerFirstName, product, partnerGender },
      }) => {
        const partnerGenderKey =
          partnerGender === 'male'
            ? 'male'
            : partnerGender === 'female'
              ? 'female'
              : 'other'
        const productKey = product === 'will' ? 'willOnly' : 'willPoa'

        return {
          tKey: `questionnaire.family:partner-will.label2.${partnerGenderKey}.${productKey}`,
          options: { partnerFirstName },
        }
      },

      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.family:partner-will.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-will.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.family:partner-will.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-will.sidebar.item2.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-will.sidebar.item2.children.item2.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'includePartner',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.family:partner-will.fields.includePartner.reviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { product: { $exists: true } },
          { province: { $exists: true } },
          { partnerGender: { $exists: true } },
          { partnerFirstName: { $exists: true } },
          { $or: [{ product: 'will' }, { product: 'willPoa' }] },
        ],
      },
    },

    {
      id: 1573496244,
      pathname: 'partner-email',
      type: 'email',
      section,
      hideFromPartner: true,
      hideAfterPayment: true,
      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.family:partner-email.label',
        options: { partnerFirstName },
      }),
      label2: ({ answerStore: { partnerGender } }) => {
        const partnerGenderKey =
          partnerGender === 'male'
            ? 'male'
            : partnerGender === 'female'
              ? 'female'
              : 'other'

        return {
          tKey: `questionnaire.family:partner-email.label2.${partnerGenderKey}`,
        }
      },
      subLabel: ({
        answerStore: { partnerFirstName, product, partnerGender },
      }) => {
        const productKey = product === 'will' ? 'willOnly' : 'willPoa'
        const partnerGenderKey =
          partnerGender === 'male'
            ? 'male'
            : partnerGender === 'female'
              ? 'female'
              : 'other'

        return {
          tKey: `questionnaire.family:partner-email.subLabel.${partnerGenderKey}.${productKey}`,
          options: { partnerFirstName },
        }
      },

      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.family:partner-email.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-email.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-email.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.family:partner-email.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-email.sidebar.item2.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-email.sidebar.item2.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:partner-email.sidebar.item2.children.item3.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'partnerEmail',
          type: 'email',
          placeholder: {
            tKey: 'questionnaire.family:partner-email.fields.partnerEmail.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.family:partner-email.fields.partnerEmail.reviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { includePartner: 'yes' },
          { product: { $exists: true } },
          { partnerGender: { $exists: true } },
          { partnerFirstName: { $exists: true } },
        ],
      },
    },

    {
      id: 1572359423,
      pathname: 'children-details',
      type: 'children',
      section,
      label: { tKey: 'questionnaire.family:children-details.label' },
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.family:children-details.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:children-details.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.family:children-details.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'children',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.family:children-details.fields.children.reviewLabel',
          },
          clearAfterProvinceChange: true,
        },
        {
          name: 'stepChildren',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.family:children-details.fields.stepChildren.reviewLabel',
          },
          clearAfterProvinceChange: true,
        },
        {
          name: 'minorChildren',
          type: 'text',
          hideOnReview: true,
          clearAfterProvinceChange: true,
        },
        {
          name: 'hasStepChildren',
          type: 'text',
          hideOnReview: true,
          clearAfterProvinceChange: true,
        },
      ],
      trigger: {
        $and: [
          { $or: [{ hasChildren: 'yes' }, { hasStepChildren: 'yes' }] },
          // builds array of supported provinces object (ex. {province: 'ON})
          { $or: SUPPORTED_PROVINCES.map((p) => ({ province: p })) },
        ],
      },
    },

    {
      id: 1572359424,
      type: 'pets',
      pathname: 'pets',
      section,
      label: { tKey: 'questionnaire.family:pets.label' },
      fields: [
        {
          name: 'pets',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.family:pets.fields.pets.reviewLabel',
          },
        },
      ],
      trigger: { hasPets: 'yes' },
    },

    {
      id: 1572359425,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: { tKey: 'questionnaire.family:review.label' },
      subLabel: { tKey: 'questionnaire.family:review.subLabel' },
    },
  ],
}
