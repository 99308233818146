import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'

import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import featureFlag, { flagTypes } from '../featureFlag'
import {
  arrayToList,
  formatCharitableGiftDetails,
  formatCharityPartnerGiftAmount,
  formatChildren,
  formatGifts,
  formatInvestmentAccounts,
  formatPets,
  formatPredeceased,
  formatPredeceasedBackup,
  formatRange,
  formatRemoteDistribution,
  formatStepChildrenDistribution,
} from './utils/specialFormats'

const SPECIAL_FORMAT_FIELDS = new Set([
  'children',
  'stepChildren',
  'giftDetails',
  'pets',
  'investmentAccounts',
  'remoteDistribution',
  'predeceased',
  'predeceasedBackup',
  'charitableGiftDetails',
  'charityPartnerGiftAmount',
  'stepChildrenDistribution',
])

const getLabel = ({ field, val, resolveValue }) => {
  try {
    const theField = field.options.find((f) => f.value === val)
    return theField.reviewLabel
      ? resolveValue(theField.reviewLabel)
      : resolveValue(theField.label)
  } catch (error) {
    const message = `There is no label on the field: ${field.name}. The field may also be undefined.`

    if (process.env.REACT_APP_ENV === 'test') {
      return null
    }
    if (featureFlag(flagTypes.SENTRY)) {
      window.captureException(error, { message, field, val })
      return null
    }
    return undefined
  }
}

export default ({ field, answerStore, resolveValue }) => {
  const { t } = useTranslation()
  const languageType = i18n.language

  if (
    !_isEmpty(answerStore) &&
    !_isEmpty(field) &&
    !_isNil(answerStore[field.name]) &&
    answerStore[field.name] !== ''
  ) {
    try {
      const val = answerStore[field.name]

      const switchVal = SPECIAL_FORMAT_FIELDS.has(field.name)
        ? field.name
        : field.type

      switch (switchVal) {
        // special format fields //
        case 'children':
        case 'stepChildren':
          return formatChildren(val)

        case 'giftDetails':
          return formatGifts(val)

        case 'charitableGiftDetails':
          return formatCharitableGiftDetails(val, languageType)

        case 'pets':
          return formatPets(val)

        case 'investmentAccounts':
          return formatInvestmentAccounts(val, t)

        case 'remoteDistribution':
          return formatRemoteDistribution(val)

        case 'predeceased':
          return formatPredeceased(val, t)

        case 'predeceasedBackup':
          return formatPredeceasedBackup(val, t)

        case 'charityPartnerGiftAmount':
          return formatCharityPartnerGiftAmount(val, answerStore, languageType)

        case 'stepChildrenDistribution':
          return formatStepChildrenDistribution(val, t)

        // standard fields //
        case 'radio':
        case 'quickRadio':
        case 'select':
          return !_isEmpty(field.options)
            ? getLabel({ field, val, resolveValue })
            : null

        case 'multiSelect':
          return arrayToList(field.options, val)

        case 'range':
          return formatRange(field, val, languageType)

        default:
          return val
      }
    } catch (error) {
      const message =
        'This TYPICALLY occurs in the formattedAnswerStoreData.js file when a field doesn`t have a coresponding value in the answerStore. undefined is returned instead of the field value'

      if (featureFlag(flagTypes.SENTRY)) {
        window.captureException(error, { message, field, answerStore })
      }
      // returning an empty string will hopefully prevent a catastrophic error
      return ''
    }
  }

  if (process.env.REACT_APP_ENV === 'test') {
    return ''
  }
  if (featureFlag(flagTypes.SENTRY)) {
    window.captureException(
      new Error(
        'Attempt to format undefined field from answerStore in formattedAnswerStoreData.js',
      ),
      {
        message:
          'Attempt to format undefined field from answerStore in formattedAnswerStoreData.js',
      },
    )
    // returning an empty string will hopefully prevent a catastrophic error
    return ''
  }
  return undefined
}
