// DO NOT CHANGE the section string without updating
// SummaryCards.js, where this string is used.

import { CONSTANTS } from '@epilogue/common'

const section = CONSTANTS.questionnaireSectionTypes.SOCIAL_COMPLETE.id

export default {
  id: section,
  questions: [
    {
      id: 1615389526,
      pathname: 'social-complete',
      type: 'socialComplete',
      altProgressText: '',
      section,
    },
  ],
}
