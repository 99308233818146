import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { motion } from 'motion/react'

import InitialArea from '../../InitialArea'

import './feeDocStyles.css'

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.13 },
}

const FeeDoc = forwardRef((props, bottomRef) => {
  const {
    firstName,
    middleName,
    lastName,
    signatureDateStamp,
    hasSigned,
    initialsInfo,
    handleInitialsCheck,
  } = props

  return (
    <div className="feeDoc" style={{ position: 'relative' }}>
      <div className="id-number">33540 (08-2022)</div>
      <header>
        <div>
          <img
            alt="Royal Trust Logo"
            src="https://epilogue-assets-1.s3.ca-central-1.amazonaws.com/rbc-royal-trust-logo.png"
          />
        </div>
        <div className="title-wrapper">
          <h1>ESTATE AND TESTAMENTARY TRUST FEE AGREEMENT EPILOGUE</h1>
          <h2>ROYAL TRUST CORPORATION OF CANADA</h2>
        </div>
      </header>
      <main>
        <div>
          <p>
            I, {firstName} {middleName || ''} {lastName}, agree that the
            compensation to be paid to Royal Trust Corporation of Canada (“Royal
            Trust”) for its services as an executor <sup>1</sup> of my estate
            and as a trustee for any trust(s) established under my Will or any
            codicil thereto (my “Will”) will be as follows:{' '}
          </p>
        </div>
        <div>
          <h3>EXECUTOR FEES</h3>
          <p>
            For general estate administration services, an Executor Fee based on
            the market value of assets calculated at the date of my death and
            that pass through my estate at any time will be at the following
            rates:
          </p>
          <div className="table-wrapper">
            <table className="table-1" style={{ width: 345 }}>
              <tbody>
                <tr>
                  <td>On the first $ 3,000,000</td>
                  <td>3.75%</td>
                </tr>
                <tr>
                  <td>On the next $ 2,000,000</td>
                  <td>1.25%</td>
                </tr>
                <tr>
                  <td>On the next $ 5,000,000</td>
                  <td>0.75%</td>
                </tr>
                <tr>
                  <td>On the next $ 10,000,000</td>
                  <td>0.25%</td>
                </tr>
                <tr>
                  <td>On the balance</td>
                  <td>0.10%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            Subject to a minimum fee of $25,000. Five years after the date of
            this Agreement, the minimum Executor Fee ($25,000) shall be indexed
            to inflation, calculated in accordance with the Consumer Price Index
            (all items in Canada).
          </p>
          <InitialArea
            {...props}
            ref={initialsInfo[0].ref}
            isSigned={initialsInfo[0].isSigned}
            handleInitialsCheck={handleInitialsCheck(0)}
          />
        </div>
        <div>
          <h3>DISCOUNTS</h3>
          <h4>A. Where Royal Trust acted as my Attorney for Property</h4>
          <p>
            If, immediately prior to my death, Royal Trust was acting as my
            Attorney for Property and was paid a “Set Up Fee”, the Executor Fees
            shall be reduced by the greater of:
          </p>
          <ul>
            <li>
              the amount of the Power of Attorney Set Up Fee that was paid,
              subject to a maximum of 50% of the Executor Fees, or{' '}
            </li>
            <li>25% of the Executor Fees</li>
          </ul>
          <h4>B. RBC Employee or RBC Retiree and RBC Account</h4>
          <p>
            If immediately prior to my death, Royal Trust did not act as my
            attorney pursuant to a Power of Attorney and I am, or my spouse is,
            employed by a RBC member company, or was formerly employed by a RBC
            member company and I am, or my spouse is, receiving a pension from a
            RBC member company, the Executor Fees will be reduced by 25 %. In
            addition, the proportionate share of the Executor Fees for all
            assets passing through my estate held in a RBC member company
            account will be reduced by 10 %.
          </p>
          <h4>C. Only RBC Account</h4>
          <p>
            If immediately prior to my death, Royal Trust did not act as my
            attorney pursuant to a Power of Attorney, and neither my spouse nor
            I are either employed by or a retiree of a RBC company at my death,
            then the above discounts will not apply. Instead, the proportionate
            share of the Executor Fees for all assets passing through my estate
            held in a RBC member company account will be reduced by 25 %.
          </p>
          <p>
            The applicable discounts shall not apply to reduce the total
            Executor Fees below the minimum fee set out above.
          </p>
        </div>
        <div
          style={{
            pageBreakAfter: 'always',
            height: 94,
            position: 'relative',
          }}
        >
          <span
            style={{
              borderTop: '1px solid black',
              position: 'absolute',
              bottom: 0,
            }}
          >
            <sup>1</sup> “Estate Trustee with a Will” in Ontario{' '}
          </span>
        </div>
        <div className="id-number">33540 (08-2022)</div>
        <div>
          <h3>TRUSTEE FEES</h3>
          <p>
            If a testamentary trust or trusts are created by my Will (the
            “Trust”), the Trust shall be charged an annual fee for trustee
            services calculated in accordance with the applicable tiered rates
            set out below, payable monthly from the Trust, based on the market
            value of assets held under administration at the end of the previous
            month. If multiple trusts are created, the combined value of all
            trusts will be used to establish the appropriate fee. Depending on
            the asset size of the Trust (or each individual trust if multiple
            trusts are created), a minimum fee may be applied (to each Trust, if
            multiple trusts are created).
          </p>
        </div>
        <div className="table-wrapper">
          <table className="table-2" style={{ width: 600 }}>
            <thead>
              <tr>
                <th aria-label="space">&nbsp;</th>
                <th aria-label="space">&nbsp;</th>
                <th>Option A</th>
                <th>Option B</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td aria-label="space" />
                <td aria-label="space" />
                <td style={{ textAlign: 'center' }}>
                  Investment Management by RBC Member Company
                </td>
                <td style={{ textAlign: 'center' }}>
                  Investment Management by External Manager
                </td>
              </tr>
              <tr>
                <td>On the first $</td>
                <td>2,000,000</td>
                <td>0.70%</td>
                <td>1.00%</td>
              </tr>
              <tr>
                <td>On the next $</td>
                <td>3,000,000</td>
                <td>0.45%</td>
                <td>0.65%</td>
              </tr>
              <tr>
                <td>On the next $</td>
                <td>10,000,000</td>
                <td>0.35%</td>
                <td>0.50%</td>
              </tr>
              <tr>
                <td>On the next $</td>
                <td>50,000,000</td>
                <td>0.25%</td>
                <td>0.35%</td>
              </tr>
              <tr>
                <td>On the next $</td>
                <td>100,000,000</td>
                <td>0.10%</td>
                <td>0.15%</td>
              </tr>
              <tr>
                <td>On the balance</td>
                <td aria-label="space" />
                <td>0.05%</td>
                <td>0.05%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <InitialArea
          {...props}
          ref={initialsInfo[1].ref}
          isSigned={initialsInfo[1].isSigned}
          handleInitialsCheck={handleInitialsCheck(1)}
        />
        <div>
          <h3>INVESTMENT MANAGEMENT FEES</h3>
          <p>
            Investment Management Fees shall be payable as a disbursement of the
            estate/trust and will be charged at such frequency as determined by
            the Investment Manager at the then prevailing rate of the Investment
            Manager. All Investment Management Fees are in addition to the
            Trustee Fees set out in this agreement.
          </p>
        </div>
        <div>
          <h3>SEPARATE TRUSTS FOR INSURANCE AND/OR REGISTERED PLAN PROCEEDS</h3>
          <p>
            In the event that Royal Trust has been named, whether as a sole
            trustee or co-trustee, to receive proceeds from any one or more life
            insurance policies, registered plans or other assets for which
            trustee or beneficiary designations (the “Designated Proceeds”) are
            permitted, I agree that Royal Trust shall receive the same fees for
            services rendered by it in the settlement and administration of such
            Designated Proceeds as if such Designated proceeds were considered
            assets of my estate and subject to the Trustee Fees described herein
            and, where warranted, a set-up fee. For greater certainty, any
            reference to my estate or the assets of my estate described herein
            shall include Designated Proceeds and any reference to a Trust shall
            include any trusts established by such Designated Proceeds.
          </p>
        </div>
        <div>
          <h3>TAX SERVICES</h3>
          <p>
            For tax return preparation services, a fee based on Royal
            Trust&apos;s scale of fees for such services in effect from time to
            time will be charged.
          </p>
        </div>
        <div>
          <h3>SPECIAL FEES</h3>
          <p>
            If upon request, Royal Trust becomes involved in the administration
            or transfer of any assets that pass outside my estate including
            registered accounts and life insurance plans with designated
            beneficiaries, Royal Trust shall charge my estate a fee of 1.0 % of
            the market value of such assets.
          </p>
          <p>
            If Royal Trust is required to undertake exceptional effort which
            requires additional time, effort and responsibility, special fees
            may be charged. Circumstances where a special fee may be charged
            include, but are not limited to, litigation directly or indirectly
            involving my estate or Trust or exceptional services provided or
            responsibilities undertaken by Royal Trust in connection with the
            management or sale of real estate, business interests, foreign
            assets or other unusual assets. Any special fee will be based on the
            additional time, effort and responsibility in dealing with these
            assets or matters.
          </p>
        </div>
        <div style={{ pageBreakAfter: 'always' }}>
          <h3>OUT-OF-POCKET EXPENSES</h3>
          <p>
            In addition to its Executor Fees, Trustee Fees and any Special Fees,
            Royal Trust will be reimbursed from my estate or Trust, or shall be
            entitled to pay directly therefrom, for all costs, disbursements,
            other charges and reasonable out-of-pocket expenses.
          </p>
        </div>
        <div className="id-number">33540 (08-2022)</div>
        <div>
          <h3>FEE CHANGES</h3>
          <p>
            The Trustee Fees set out above will remain in force for 5 years
            following the creation of any Trust(s). At the end of the fifth
            year, Royal Trust may amend the Trustee Fees in accordance with its
            fees for these services in effect from time to time.
          </p>
        </div>
        <div>
          <h3>CO-EXECUTOR&apos;S/CO-TRUSTEE&apos;S FEES</h3>
          <p>
            The compensation of any co-executors or co-trustees will be in
            addition to the compensation payable to Royal Trust under this
            agreement.
          </p>
          <InitialArea
            {...props}
            ref={initialsInfo[2].ref}
            isSigned={initialsInfo[2].isSigned}
            handleInitialsCheck={handleInitialsCheck(2)}
          />
        </div>
        <div>
          <h3>PAYMENT OF COMPENSATION</h3>
          <p>
            I agree that Royal Trust&apos;s fees and expenses as described above
            (“Compensation”) are payable from the beginning of the
            administration of my estate, and shall be paid in priority to all
            other claims for fees and expenses by any co-executors or
            co-trustees. Where the administration of the estate exceeds one
            year, Executor Fees are payable no later than the end of the first
            year. The Trustee Fees are payable on the date which is one year
            after the date of my death or from the beginning of the
            administration of the Trust, whichever is earlier.
          </p>
          <p>
            Unless there is a contrary provision or intention in my Will, any
            Compensation shall be charged and paid in such manner and at such
            frequency as Royal Trust from time to time determines in its
            absolute discretion.{' '}
          </p>
          <p>
            I also acknowledge and agree that the payment of the above
            Compensation to which Royal Trust is entitled under this agreement
            does not include any applicable taxes, whether federal or
            provincial, including but not limited to goods and services tax, and
            harmonized sales tax which shall be an additional charge payable by
            the Estate/Trust.
          </p>
        </div>
        <div>
          <h3>ASSETS PLACED ON DEPOSIT</h3>
          <p>
            I acknowledge and agree that Royal Trust may deposit estate and
            trust assets, including cash, in or with Royal Trust, any financial
            institution affiliated or related to any of my trustees, or any
            agent of or advisor to my trustees (collectively, “Authorized
            Depositaries”) notwithstanding that any of my trustees and/or
            Authorized Depositaries may benefit therefrom, and my trustees or
            Authorized Depositaries shall not be required to account for, or to
            give up, any such benefit. In particular, it shall not be improper
            for my trustees to deposit moneys with, or give custody of the
            assets of my estate or any trust created by my Will to Royal Trust
            or any Authorized Depositaries, notwithstanding any benefit realized
            as a result, including retaining a profit in excess of interest paid
            (if any) on, or fees payable to any affiliated or related companies
            in respect of, such deposit or custody arrangement.
          </p>
        </div>
        <div>
          <h3>SUCCESSORS</h3>
          <p>
            This agreement is binding on and operates to the benefit of Royal
            Trust and its successors and assigns and is also binding on and
            operates to the benefit of me and my estate, heirs, personal
            representatives, successors and assigns. Any trust company resulting
            from the merger or amalgamation of Royal Trust with one or more
            trust companies and any trust company which succeeds to
            substantially all of the trust business of Royal Trust shall
            thereupon become the successor of Royal Trust hereunder without
            further act or formality. Royal Trust may assign its rights and
            obligations under this agreement to an affiliate qualified to
            provide similar services without prior written consent.{' '}
          </p>
        </div>
        <div style={{ marginTop: 20, fontStyle: 'italic' }}>
          <p>
            I acknowledge that there may be situations where Royal Trust is
            unable to accept the appointment as executor of my estate at the
            time of my death. This may occur because the appointment would no
            longer be appropriate or of benefit to my estate and beneficiaries
            based on the value, location or nature of the assets at that time.
          </p>
          <p style={{ marginTop: 20 }}>
            By signing below, I consent to Intelligent Estate Planning Solutions
            Inc. doing business as Epilogue sharing with Royal Trust my contact
            information for the purposes of enabling Royal Trust to maintain up
            to date client records and to contact me about its estate
            administration, trustee and other fiduciary services and related
            matters.
          </p>
          <InitialArea
            {...props}
            ref={initialsInfo[3].ref}
            isSigned={initialsInfo[3].isSigned}
            handleInitialsCheck={handleInitialsCheck(3)}
          />
        </div>
        <div className="date-wrapper">
          <div>
            Date:{' '}
            {hasSigned && (
              <motion.span {...animation} style={{ marginLeft: 3 }}>
                {signatureDateStamp}
              </motion.span>
            )}
          </div>
        </div>
        <div style={{ marginBottom: '3rem' }}>
          <div style={{ marginBottom: 25, position: 'relative' }}>
            Client&apos;s Signature:{' '}
            {hasSigned && (
              <motion.span {...animation} className="signature">
                {firstName} {middleName || ''} {lastName}
              </motion.span>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              whiteSpace: 'nowrap',
            }}
          >
            <span>Print Name:</span>
            {hasSigned && (
              <motion.span {...animation} className="writtenName">
                {firstName} {middleName || ''} {lastName}
              </motion.span>
            )}
          </div>
          <p style={{ marginTop: 30 }}>
            All collection, use and disclosure of your personal information will
            be in accordance with the RBC Global Privacy Notice and Digital
            Channel Privacy (available at{' '}
            <a
              target="_blank"
              style={{ textDecoration: 'underline' }}
              href="https://www.rbc.com/privacysecurity/ca/index.html"
              rel="noreferrer"
            >
              www.rbc.com/privacysecurity
            </a>
            ).
          </p>
        </div>
      </main>

      <div ref={bottomRef} />
    </div>
  )
})

FeeDoc.propTypes = {
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  signatureDateStamp: PropTypes.string,
  hasSigned: PropTypes.bool.isRequired,
  initialsInfo: PropTypes.arrayOf(
    PropTypes.shape({ isSigned: PropTypes.bool, ref: PropTypes.shape({}) }),
  ).isRequired,
  handleInitialsCheck: PropTypes.func.isRequired,
}

export default FeeDoc
