import strToKebab from '../../../../../../common/formatting/strToKebab'

interface BuildFilenameProps {
  firstName?: string
  lastName?: string
  docType: string
}

export default ({ firstName, lastName, docType }: BuildFilenameProps) => {
  // docType is converted from camelCase to kebab-case
  const docTypeLabel = docType
    .replace(/([\da-z]|(?=[A-Z]))([A-Z])/g, '$1-$2')
    .toLowerCase()

  return firstName && lastName
    ? `${strToKebab(firstName.toLowerCase())}-${strToKebab(
        lastName.toLowerCase(),
      )}-${docTypeLabel}.pdf`
    : `${docTypeLabel}.pdf`
}
