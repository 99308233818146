import React from 'react'
import PropTypes from 'prop-types'

import { Box, useMediaQuery } from '@mui/material'

import { PieChart as ReactPieChart } from 'react-minimal-pie-chart'

import PieLegend from './components/PieLegend'

const colors = [
  '#221C54', // navy
  '#E37645', // orange
  '#C8C3E8', // light purple
  '#DDC59C', // beige
  '#468F3B', // green
  '#AB8A53', // brown
  '#8D4EBF', // purple
  '#C22D2D', // red
  '#DEB241', // yellow
  '#EE9EBF', // pink
  '#74BFBB', // teal
  '#A6D12C', // light green
]

const PieChart = ({ data }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <>
      {data && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            margin: '0 auto',
            maxWidth: '650px',
            alignItems: 'center',
            px: isMobile ? '1.5rem' : '0',
            my: isMobile ? '1.5rem' : '0',
            flexDirection: isMobile ? 'column-reverse' : 'flex-row',
          }}
        >
          <ReactPieChart
            label={({ dataIndex }) =>
              `${data.find((item) => item.id === dataIndex).value}%`
            }
            labelPosition={70}
            labelStyle={{
              fill: '#ffffff',
              fontSize: isMobile ? '3.5px' : '3px',
              fontWeight: 'bold',
            }}
            data={data.map((pieSlice, i) => {
              const color = colors[Math.floor(i % colors.length)]
              return { ...pieSlice, color }
            })}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              width: isMobile ? '246px' : '435px',
              marginLeft: isMobile ? '0' : '2.8rem',
              marginBottom: isMobile ? '2rem' : '0',
            }}
          >
            <PieLegend data={data} colors={colors} />
          </Box>
        </Box>
      )}
    </>
  )
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default PieChart
