import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Qu'est-ce qu'un Compte d'épargne premier logement ?

Un Compte d'épargne premier logement (CEPL) est un nouveau type de compte d'investissement conçu pour aider les Canadiens à épargner de l'argent pour acheter leur premier logement.

#### Comment fonctionne un CEPL ?

Pour être admissible à l'ouverture d'un CEPL, vous devez être résident canadien, âgé de plus de 18 ans et être un acheteur de première maison.

Les personnes admissibles peuvent ouvrir un CEPL et peuvent effectuer des contributions déductibles d'impôt à leur CEPL pouvant atteindre 8 000 $ par an (avec un maximum de 40 000 $ sur leur vie), donc les contributions à un CEPL peuvent réellement réduire le montant d'impôt sur le revenu que vous payez !

De plus, les investissements dans votre CEPL croissent à l'abri de l'impôt. Cela signifie que plus de votre argent peut rester investi et peut croître plus rapidement que dans un compte d'investissement ordinaire.

Enfin, si vous effectuez un retrait de votre CEPL pour acheter une maison admissible, le retrait est également exempt d'impôt, ce qui fait du CEPL l'un des meilleurs moyens d'épargner pour votre premier logement.

#### En quoi un CEPL est-il différent d'un REER ?

Comme pour un REER, les cotisations à un CEPL sont déductibles d'impôt et les investissements croissent à l'abri de l'impôt. Mais avec un REER, l'impôt est payé si de l'argent est retiré du compte. Ce n'est pas le cas avec un CEPL tant que les fonds sont utilisés pour acheter une maison admissible.

Un CEPL peut être utilisé conjointement avec le Régime d'accession à la propriété (RAP), qui permet aux gens d'utiliser leur REER pour aider à financer l'achat d'une maison. Enfin, si le propriétaire d'un CEPL décide de ne pas acheter de maison, les fonds du CEPL peuvent être transférés dans leur REER sans affecter la marge de cotisation au REER disponible.

#### Commencez à investir dans un CEPL

Si vous envisagez d'acheter votre première maison dans le futur, un CEPL pourrait être un bon moyen d'atteindre ce rêve plus rapidement !
`
    : `
#### What is a First Home Savings Account?

A First Home Savings Account (FHSA) is a new type of investment account designed to help Canadians save money to purchase their first home.

#### How Does an FHSA Work?

To qualify for opening an FHSA you must be a Canadian resident, over the age of 18, and a first-time home buyer.

Individuals who qualify can open an FHSA and can make tax-deductible contributions to their FHSA of up to $8,000 per year (with a $40,000 lifetime maximum), so contributions to an FHSA can actually reduce the amount of income tax you pay!

Also, the investments in your FHSA grow tax-free. This means more of your money can stay invested and can grow faster than they would in a regular investment account.

Finally, if you make a withdrawal from your FHSA to purchase a qualifying home, the withdrawal is also tax-free, making the FHSA one of the best ways to save-up for your first home.

#### How is an FHSA Different from an RRSP?

Like an RRSP, contributions to an FHSA are tax-deductible and investments grow tax-free. But with an RRSP, tax is paid if money is taken out of the account. That's not the case with an FHSA as long as the funds are used to purchase a qualifying home.

An FHSA can be used together with the Home Buyers Plan (HBP), which allows people to use their RRSP to help fund the purchase of a home. Finally, if the owner of an FHSA decides not to buy a home, the FHSA funds can be transferred to their RRSP without affecting available RRSP contribution room.

#### Start Investing in an FHSA

If you are thinking about buying your first home in the future, an FHSA might be a good way to reach that dream faster!
`
