import { CONSTANTS } from '@epilogue/common'

export default ({
  packageType,
  productType,
}: {
  packageType: string
  productType: string
}) => {
  switch (true) {
    case packageType === 'individual' &&
      productType === CONSTANTS.productTypes.WILL_POA:
      return CONSTANTS.addOnTypes.GIFT_WILL_POA_INDIVIDUAL.type

    case packageType === 'couples' &&
      productType === CONSTANTS.productTypes.WILL:
      return CONSTANTS.addOnTypes.GIFT_WILL_ONLY_COUPLES.type

    case packageType === 'couples' &&
      productType === CONSTANTS.productTypes.WILL_POA:
      return CONSTANTS.addOnTypes.GIFT_WILL_POA_COUPLES.type

    // DEFAULT is packageType === 'individual' && productType === CONSTANTS.productTypes.WILL
    default:
      return CONSTANTS.addOnTypes.GIFT_WILL_ONLY_INDIVIDUAL.type
  }
}
