import { getHours } from 'date-fns'

export default () => {
  const currentHour = getHours(new Date())

  if (currentHour >= 3 && currentHour < 12) {
    return { tKey: 'dashboard:dynamicGreeting.morning' }
  }

  if (currentHour >= 12 && currentHour < 16) {
    return { tKey: 'dashboard:dynamicGreeting.afternoon' }
  }

  if (currentHour >= 16 || currentHour < 3) {
    return { tKey: 'dashboard:dynamicGreeting.evening' }
  }

  return { tKey: 'dashboard:dynamicGreeting.hello' }
}
