import React from 'react'
import PropTypes from 'prop-types'

import { Box, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    opacity: 0,
    animationName: '$delay',
    animationDuration: '0.2s',
    animationFillMode: 'both',
    animationTimingFunction: 'ease-in',
    animationDelay: ({ delay }) => `${delay}s`,
  },

  '@keyframes delay': { '0%': { opacity: 0 }, '100%': { opacity: 1 } },
})

const LoadingSpinner = ({ delay = '1', size = '42px', className }) => {
  const classes = useStyles({ delay })

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
      data-testid="loading-spinner-wrapper"
    >
      <CircularProgress size={size} className={className} />
    </Box>
  )
}

LoadingSpinner.propTypes = {
  size: PropTypes.string,
  delay: PropTypes.string,
  className: PropTypes.string,
}

export default LoadingSpinner
