import React from 'react'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'

import Button from '../../../../../common/components/atoms/Button'
import Heading from '../../../../../common/components/atoms/Heading'
import Modal from '../../../../../common/components/atoms/Modal'
import Text from '../../../../../common/components/atoms/Text'

const UpdateAnswersModal = ({
  show,
  handleSetShow,
  firstQuestion,
  dispatchToQuestion,
}) => {
  const navigate = useNavigate()

  return (
    <Modal show={show} onClose={() => handleSetShow(false)}>
      <Box
        data-testid="social-update-answers-modal"
        sx={{
          p: '1rem',
          display: 'flex',
          maxWidth: '38rem',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Heading sx={{ mb: '0.75rem' }} align="center" variant="h4">
          If you need to make an update, you should review all of your answers
          from the beginning.
        </Heading>

        <Text
          size="md"
          align="center"
          variant="paragraph"
          sx={{
            mb: '1rem',
            px: '0.25rem',
          }}
        >
          Changing one answer can affect others or trigger new questions that
          need answering.
        </Text>

        <Box sx={{ mt: '1rem' }}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            label="Take me to the beginning"
            data-testid="social-update-answers-modal-button"
            onClick={() => {
              if (firstQuestion) {
                // update questionStack
                dispatchToQuestion(firstQuestion)
                // send user to questionnaire
                navigate(`/questionnaire?w=${CONSTANTS.worldTypes.social.type}`)
              }
            }}
          />
        </Box>
      </Box>
    </Modal>
  )
}

UpdateAnswersModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleSetShow: PropTypes.func.isRequired,
  firstQuestion: PropTypes.shape({}).isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
}
export default UpdateAnswersModal
