import ReactGA from 'react-ga4'
import { createLogic } from 'redux-logic'

import commonActions from '../../../../../common/ducks/commonActions'
import amplitude from '../../../../../common/lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import questionnaireTypes from '../questionnaireTypes'

export default createLogic({
  name: 'createAccountSuccess',
  type: [questionnaireTypes.CREATE_ACCOUNT_SUCCESS],
  async process({ action }, dispatch, done) {
    const { mailingList, email } = action.payload

    amplitude.setUserId(email) // Update the user's amplitude userId to use their provided email address
    amplitude.sendEvent('CreatedAccount', { mailingList })

    try {
      await request({
        method: 'PATCH',
        url: PATHS.USER_MAILING_LIST,
        data: { mailingList },
      })
    } catch (error) {
      // Quietly report the error (No error message for user)
      errorHandler({
        error,
        reportError: true,
      })
    } finally {
      dispatch(commonActions.checkAuth({ fromQuestionnaire: true }))

      // Google Ads event for signup
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-652679184/B5EgCK6p1cwBEJCwnLcC',
        })
      }
      // Google Analytics signup event
      if (ReactGA) {
        ReactGA.event({
          category: 'lead',
          action: 'signup',
        })
      }
      // Facebook pixel event for signup
      if (window.fbq) {
        window.fbq('track', 'Lead')
      }
    }

    done()
  },
})
