import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _uniqueId from 'lodash/uniqueId'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useTranslation } from 'react-i18next'

import TextInput from '../../../../../common/components/atoms/TextInput'
import { PATHS, request } from '../../../../../common/request'
import featureFlag, { flagTypes } from '../../../../../common/utils/featureFlag'

const findCharityApi = (queryInput) => {
  if (queryInput.trim()) {
    return request({
      method: 'GET',
      url: `${PATHS.CHARITY}`,
      params: {
        page: 1,
        limit: 5,
        q: queryInput,
      },
    })
  }
  return undefined
}

const debouncedFindCharity = AwesomeDebouncePromise(findCharityApi, 480)

const queryLengthThreshold = 4

const CharitySearchInput = ({
  value = '',
  isInvalid,
  autoFocus,
  placeholder,
  validationMessage = 'Required',
  onCharitySearchInputChange,
}) => {
  const isInitialMount = useRef(true)

  const [input, setInput] = useState(value)
  const [working, setWorking] = useState(false)
  const [charities, setCharities] = useState([])
  const [selectedCharity, setSelectedCharity] = useState({})

  useEffect(() => {
    /*
     * This prevents useEffect callback from running on initial mount.
     * We only want to check for a charity number and location after
     * the input has been updated.
     */
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      onCharitySearchInputChange({
        name: input,
        number: _get(selectedCharity, 'number'),
        location: _get(selectedCharity, 'location'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, selectedCharity])

  const handleGetCharity = async (searchInput) => {
    const errMsg = 'Error during charity search'
    if (!working) {
      setWorking(true)
    }

    try {
      const res = await debouncedFindCharity(searchInput)

      if (res.status === 200) {
        setCharities(res.data.payload.items)
      } else {
        setCharities([])

        if (featureFlag(flagTypes.SENTRY)) {
          window.captureException(new Error(res.errorMessage || errMsg), {
            message: 'Error during charity search.',
          })
        }
      }
    } catch (error) {
      setCharities([])

      if (featureFlag(flagTypes.SENTRY)) {
        window.captureException(error, {
          message: errMsg,
        })
      }
    }
    setWorking(false)
  }

  const { t } = useTranslation()

  return (
    <TextInput
      maxRows={2}
      value={input}
      type="textarea"
      working={working}
      isInvalid={isInvalid}
      autoFocus={autoFocus}
      placeholder={placeholder}
      displayAllAutocompleteOptions
      validationMessage={validationMessage}
      helperText={t('molecules:CharitySearchInput.helperText')}
      autocompleteOptions={
        working || _isEmpty(charities) || input.length < queryLengthThreshold
          ? []
          : charities.map(({ name, location, number }) => ({
              id: _uniqueId(),
              label: name,
              subLabel: location,
              number,
              location,
            }))
      }
      onInputChange={(val, selectedOption = {}) => {
        if (val !== input) {
          setInput(val)
          if (val.length >= queryLengthThreshold) {
            handleGetCharity(val)
          }
        }
        setSelectedCharity(selectedOption)
      }}
    />
  )
}

CharitySearchInput.propTypes = {
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  isInvalid: PropTypes.bool,
  validationMessage: PropTypes.node,
  onCharitySearchInputChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default CharitySearchInput
