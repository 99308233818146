import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import MultiSelectField from '../../Field/MultiSelectField'
import getInvestmentAccountsOptions from './utils/getInvestmentAccountsOptions'

const InvestmentAccounts = ({
  question,
  answerCache,
  handleFieldFragmentUpdate,
}) => {
  const [field] = question.fields

  const translatedInvestmentAccountOptions = getInvestmentAccountsOptions(
    answerCache?.dateOfBirth,
  )

  return (
    <Box sx={{ mb: '1.5rem' }}>
      <MultiSelectField
        answerCache={answerCache}
        handleFieldFragmentUpdate={handleFieldFragmentUpdate}
        field={{
          ...field,
          options: translatedInvestmentAccountOptions,
        }}
      />
    </Box>
  )
}

InvestmentAccounts.propTypes = {
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  answerCache: PropTypes.shape({ dateOfBirth: PropTypes.string }).isRequired,
}

export default InvestmentAccounts
