import { CONSTANTS } from '@epilogue/common'

export default (t) => ({
  [CONSTANTS.worldTypes.will.type]: {
    dashboard: {
      id: 'dashboard',
      to: '/dashboard',
      label: t('navigation:dashboard'),
    },
    documents: {
      id: 'documents',
      to: '/dashboard/documents',
      label: t('navigation:documents'),
    },
    summary: {
      id: 'summary',
      to: '/dashboard/summary',
      label: t('navigation:summary'),
    },
  },

  [CONSTANTS.worldTypes.social.type]: {
    dashboard: {
      id: 'socialDashboard',
      to: '/social-dashboard',
      label: t('navigation:dashboard'),
    },
  },
})
