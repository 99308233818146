import allQuestions from './allQuestions'
import isTriggered from './isTriggered'
import shouldShowQuestion from './shouldShowQuestion'

export default ({
  abTest,
  theData,
  payments,
  referrer,
  isPartner,
  documents,
  answerStore,
  charityPartner,
  accountCreated,
}) =>
  allQuestions(theData).filter((question) => {
    const { trigger } = question

    const questionNotHidden = shouldShowQuestion({
      abTest,
      referrer,
      question,
      payments,
      isPartner,
      documents,
      answerStore,
      data: theData,
      charityPartner,
      accountCreated,
    })

    if (questionNotHidden) {
      return trigger
        ? isTriggered(trigger, {
            abTest,
            referrer,
            isPartner,
            answerStore,
            charityPartner,
          })
        : question
    }
    return false
  })
