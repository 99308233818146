import { CONSTANTS } from '@epilogue/common'

export default ({ province, languageType }) => {
  // TAG: province-specific
  switch (province) {
    case CONSTANTS.provinces.ALBERTA:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? 'un programme de soutien aux personnes handicapées en Alberta'
        : "Alberta's Assured Income for the Severely Handicapped (AISH) program"

    case CONSTANTS.provinces.BRITISH_COLUMBIA:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? 'un programme de soutien aux personnes handicapées en Colombie-Britannique'
        : "British Columbia's Employment and Assistance for Persons with Disabilities Act"

    case CONSTANTS.provinces.MANITOBA:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? "aide à l'emploi et au revenu"
        : "Manitoba's Employment and Income Assistance (EIA) Program"

    case CONSTANTS.provinces.NEW_BRUNSWICK:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? 'programme de soutien aux personnes ayant un handicap'
        : 'the New Brunswick Disability Support Program (NBDSP)'

    case CONSTANTS.provinces.NOVA_SCOTIA:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? 'un programme de soutien aux personnes handicapées en Nouvelle-Écosse'
        : "Nova Scotia's Income Assistance (IA) Program"

    case CONSTANTS.provinces.ONTARIO:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? 'programme ontarien de soutien aux personnes handicapées'
        : 'the Ontario Disability Support Program (ODSP)'

    case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? "programme de soutien à l'accessibilité de l'Île-du-Prince-Édouard"
        : 'the Prince Edward Island Disability Support Program (AccessAbility Supports)'

    case CONSTANTS.provinces.SASKATCHEWAN:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? 'un programme de soutien aux personnes handicapées en Saskatchewan'
        : 'the Saskatchewan Assured Income for Disability (SAID) program'

    default:
      return languageType === CONSTANTS.languageTypes.FR_CA.id
        ? `un programme de soutien aux personnes handicapées en ${province}`
        : `a disability support program in ${province}`
  }
}
