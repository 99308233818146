import React from 'react'

import { connect } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import _toString from 'lodash/toString'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ComputerWithDocument } from '../../../../../../../common/assets/images/computer-with-document.svg'
import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import addOnsActions from '../../../../../ducks/addOns/addOnsActions'
import addOnsSelectors from '../../../../../ducks/addOns/addOnsSelectors'
import paymentsSelectors from '../../../../../ducks/payments/paymentsSelectors'
import getAddOnsByCategory from '../../../../../utils/getAddOnsByCategory/getAddOnsByCategory'
import hasCoreProduct from '../../../../../utils/hasCoreProduct'

type AddOnType =
  | typeof CONSTANTS.addOnTypes.VIRTUAL_WITNESSING_WILL
  | typeof CONSTANTS.addOnTypes.VIRTUAL_WITNESSING_WILL_INCAPACITY

type AddOnCartType =
  (typeof CONSTANTS.addOnTypes)[keyof typeof CONSTANTS.addOnTypes]['type']

interface AddOnCartItem {
  type: AddOnCartType
  amount: number
}

interface Props {
  payments: any
  addOnCart: any
  answerStore: any
  dispatchSetAddOnsToCart: (addOns: AddOnCartItem[]) => void
}

const getAddOnType = (isWillOnly: boolean) =>
  isWillOnly
    ? CONSTANTS.addOnTypes.VIRTUAL_WITNESSING_WILL
    : CONSTANTS.addOnTypes.VIRTUAL_WITNESSING_WILL_INCAPACITY

const getAddOnPrice = (addOnType: AddOnType, isCouplesAddOn: boolean) =>
  addOnType.price[isCouplesAddOn ? 'couples' : 'individual']

const WitnessingAddOn = ({
  payments,
  addOnCart,
  answerStore,
  dispatchSetAddOnsToCart,
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { product, includePartner, province } = answerStore

  const coreProduct = hasCoreProduct(payments)
  const addOnType = getAddOnType(product === CONSTANTS.productTypes.WILL)

  const isCouplesAddOn =
    includePartner && includePartner === 'yes' && !coreProduct
  const addOnPrice = getAddOnPrice(addOnType, isCouplesAddOn)

  const allWitnessingAddOns = getAddOnsByCategory(
    CONSTANTS.addOnTypes.VIRTUAL_WITNESSING_WILL.category,
  )

  const nonWitnessingAddOns = addOnCart.filter((existingAddOn: AddOnCartItem) =>
    allWitnessingAddOns.every(
      (witnessAddOn) => witnessAddOn.type !== existingAddOn.type,
    ),
  )

  if (answerStore.province !== CONSTANTS.provinces.ONTARIO) {
    return <Navigate to="/login" replace />
  }

  return (
    <Box sx={{ maxWidth: '40rem', mx: 'auto', position: 'relative' }}>
      {!coreProduct && (
        <Button
          size="xxs"
          label={t('common:back')}
          startIcon={<ArrowBackIcon />}
          data-testid="witnessing-add-on-back-button"
          onClick={() => navigate('/dashboard/add-ons/printing')}
          sx={{ position: 'absolute', top: '-0.3rem', left: '0' }}
        />
      )}

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <ComputerWithDocument style={{ width: '18rem' }} />
      </Box>

      <Heading
        variant="h3"
        align="center"
        sx={{ mb: '1rem', lineHeight: '1.25' }}
      >
        {t('pages:WitnessingAddOn.label')}
      </Heading>

      <Heading
        variant="h3"
        align="center"
        sx={{ mb: '1.4rem', lineHeight: '1.25' }}
      >
        {t('pages:WitnessingAddOn.label2')}
      </Heading>

      <Text
        size="lg"
        variant="paragraph"
        align="center"
        // dangerouslySetInnerHTML used here to allow <b> tag from i18n copy
        dangerouslySetInnerHTML={{
          __html:
            province === CONSTANTS.provinces.ONTARIO
              ? t('pages:WitnessingAddOn.subLabel.case1')
              : t('pages:WitnessingAddOn.subLabel.case2'),
        }}
      />

      <Box
        sx={{
          mt: '3rem',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Button
          size="md"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          color={themeColorTypes.ACCENT_1}
          label={
            isCouplesAddOn
              ? t('pages:WitnessingAddOn.primaryButtonLabel.case1', {
                  amount: _toString(addOnPrice),
                })
              : t('pages:WitnessingAddOn.primaryButtonLabel.case2', {
                  amount: _toString(addOnPrice),
                })
          }
          data-testid="add-on-page-add-witnessing-button"
          onClick={() => {
            amplitude.sendEvent('SelectedWitnessing', {
              product,
              isCouplesAddOn,
              coreProduct: coreProduct || 'None',
            })
            /*
             * We want to prevent having 'will only' and 'will + incapacity' witnessing
             * add-ons in the cart at the same time. We filter out all witnessing add-ons
             * (leaving any other add-on types in the cart), and then we add the new
             * witnessing add-on to the end of the cart array.
             */
            dispatchSetAddOnsToCart([
              ...nonWitnessingAddOns,
              { type: addOnType.type, amount: 1 },
            ])

            navigate('/dashboard/payment')
          }}
        />

        <Button
          size="xs"
          sx={{ mt: '1rem' }}
          endIcon={<ArrowForwardIcon />}
          label={t('pages:WitnessingAddOn.secondaryButtonLabel')}
          data-testid="witnessing-add-on-no-thanks-button"
          onClick={() => {
            amplitude.sendEvent('SkippedWitnessing', {
              product,
              isCouplesAddOn,
              coreProduct: coreProduct || 'None',
            })
            /*
             * With this click, we want to remove any type of 'witnessing'
             * add-on while leaving the non-witnessing add-ons in the cart.
             */
            dispatchSetAddOnsToCart(nonWitnessingAddOns)

            if (coreProduct) {
              navigate('/dashboard/documents')
            } else {
              navigate('/dashboard/payment')
            }
          }}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  payments: paymentsSelectors.getPayments(state.dashboard),
  addOnCart: addOnsSelectors.getAddOnCart(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetAddOnsToCart: (addOns: AddOnCartItem[]) =>
    dispatch(addOnsActions.setAddOnsToCart(addOns)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WitnessingAddOn)
