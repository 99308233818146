import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'
import { format } from 'date-fns'

import { ageOfMajority, PROVINCES } from '../../../../common/utils/provinces'
import appointeeTypes from '../appointeeTypes'
import defaultCorpExec from '../corporateExecutors'
import corpTrusteeFeesOptions from '../options/corpTrusteeFeesOptions'
import corporateExecutorClientSidebar from '../sidebarContent/corporateExecutorClientSidebar'
import corporateExecutorDisqualifiersSidebar from '../sidebarContent/corporateExecutorDisqualifiersSidebar'
import corporateExecutorFeesSidebar from '../sidebarContent/corporateExecutorFeesSidebar'
import corporateExecutorIntroSidebar from '../sidebarContent/corporateExecutorIntroSidebar'
import trusteeSidebarFragments from '../sidebarContent/trusteeSidebarFragments'
import tKeyRelationshipOptions from '../utils/tKeyRelationshipOptions'

const adultSameProvinceExecutor = ({ answerStore }) => {
  const { province } = answerStore

  if (province === CONSTANTS.provinces.SASKATCHEWAN) {
    return {
      tKey: 'questionnaire.trustee:common.adultSameProvinceExecutor.saskatchewan',
      options: { ageOfMajority: ageOfMajority(province) },
    }
  }

  if (province === CONSTANTS.provinces.NEW_BRUNSWICK) {
    return {
      tKey: 'questionnaire.trustee:common.adultSameProvinceExecutor.newBrunswick',
      options: { ageOfMajority: ageOfMajority(province) },
    }
  }

  const translatedProvince = _get(
    Object.values(PROVINCES).find(({ value }) => value === province),
    'tKey',
    province,
  )

  return {
    tKey: 'questionnaire.trustee:common.adultSameProvinceExecutor.default',
    options: {
      ageOfMajority: ageOfMajority(province),
      province: translatedProvince,
    },
  }
}

const section = CONSTANTS.questionnaireSectionTypes.TRUSTEE.id

export default {
  id: section,
  description: { tKey: 'questionnaire.trustee:description' },
  trigger: { firstName: { $exists: true }, lastName: { $exists: true } },
  questions: [
    {
      id: 1572371591,
      pathname: 'intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.trustee:intro.highlightPillText',
      },
      label: { tKey: 'questionnaire.trustee:intro.label' },
      label2: { tKey: 'questionnaire.trustee:intro.label2' },
    },

    {
      id: 1572371939,
      pathname: 'partner-trustee',
      section,

      label: ({ answerStore: { partnerFirstName, partnerLastName } }) => ({
        tKey: 'questionnaire.trustee:partner-trustee.label',
        options: { partnerFirstName, partnerLastName },
      }),

      subLabel: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.trustee:partner-trustee.subLabel',
        options: { partnerFirstName },
      }),

      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.trustee:partner-trustee.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:partner-trustee.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:partner-trustee.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
        trusteeSidebarFragments.executorResponsibilities,
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.trustee:partner-trustee.sidebar.item3.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:partner-trustee.sidebar.item3.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:partner-trustee.sidebar.item3.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:partner-trustee.sidebar.item3.children.item3.body',
              },
            },
          ],
        },
        trusteeSidebarFragments.executorHireProfessional,
        trusteeSidebarFragments.executorCompensation,
      ],
      fields: [
        {
          name: 'partnerAsTrustee',
          type: 'quickRadio',

          reviewLabel: ({
            answerStore: { partnerFirstName, partnerLastName },
          }) => ({
            tKey: 'questionnaire.trustee:partner-trustee.fields.partnerAsTrustee.reviewLabel',
            options: { partnerFirstName, partnerLastName },
          }),

          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
          appointeeType: appointeeTypes.PARTNER_TRUSTEE,
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
        ],
      },
    },

    // 🐸🧀☎️🥶🚀🍉😈 Corporate Co-Trustee Starts //

    {
      id: 1657757305,
      pathname: 'corporate-co-trustee-info',
      section,
      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.trustee:corporate-co-trustee-info.label',
        options: { partnerFirstName },
      }),

      label2: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.trustee:corporate-co-trustee-info.label2',
        options: { partnerFirstName },
      }),

      subLabel: {
        tKey: 'questionnaire.trustee:corporate-co-trustee-info.subLabel',
      },

      fields: [
        {
          name: 'corpCoTrusteeInfo',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-co-trustee-info.fields.corpCoTrusteeInfo.reviewLabel',
          },
          options: [
            {
              value: 'no',
              label: ({ answerStore: { partnerFirstName } }) => ({
                tKey: 'questionnaire.trustee:corporate-co-trustee-info.fields.corpCoTrusteeInfo.options.no.label',
                options: { partnerFirstName },
              }),
              reviewLabel: { tKey: 'common:no' },
            },
            {
              value: 'continue',
              label: {
                tKey: 'questionnaire.trustee:corporate-co-trustee-info.fields.corpCoTrusteeInfo.options.continue.label',
              },
              reviewLabel: { tKey: 'common:yes' },
            },
            {
              value: 'skipCoExecutor',
              label: {
                tKey: 'questionnaire.trustee:corporate-co-trustee-info.fields.corpCoTrusteeInfo.options.skipCoExecutor.label',
              },
              reviewLabel: { tKey: 'common:no' },
            },
          ],
        },
      ],

      trigger: {
        $and: [
          // TAG: TD setup
          { 'referrer.ref': { $ne: 'td' } },
          { partnerAsTrustee: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'co-trustee' },
                ],
              },
            ],
          },
        ],
      },
      autoOpenSidebar: true,
      sidebar: corporateExecutorIntroSidebar,
    },

    {
      id: 1657757145,
      pathname: 'corporate-co-trustee-disqualifiers',
      section,
      type: 'corporateExecutorDisqualifiers',
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },

      label: {
        tKey: 'questionnaire.trustee:corporate-co-trustee-disqualifiers.label',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      label2: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.trustee:corporate-co-trustee-disqualifiers.label2',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
          partnerFirstName,
        },
      }),

      autoOpenSidebar: true,
      hideOnReview: true,
      corporateExecutorType: CONSTANTS.corporateExecutorTypes.CO_EXECUTOR,
      fields: [
        {
          name: 'corpCoTrusteeDisqualifiers',
          type: 'radio',
          options: [
            { value: 'continue', label: { tKey: 'common:continue' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { partnerAsTrustee: 'yes' },
          { corpCoTrusteeInfo: 'continue' },
          { partnerFirstName: { $exists: true } },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'co-trustee' },
                ],
              },
            ],
          },
        ],
      },
      sidebar: corporateExecutorDisqualifiersSidebar,
    },

    {
      id: 1657757183,
      pathname: 'corporate-co-trustee-fees',
      section,
      type: 'corporateExecutorFeeAgreement',
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },

      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.trustee:corporate-co-trustee-fees.label',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
          partnerFirstName,
        },
      }),

      label2: {
        tKey: 'questionnaire.trustee:corporate-co-trustee-fees.label2',
      },

      corporateExecutorType: CONSTANTS.corporateExecutorTypes.CO_EXECUTOR,
      fields: [
        {
          name: 'corpCoTrusteeFees',
          type: 'quickRadio',
          optional: true,
          options: corpTrusteeFeesOptions,
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-co-trustee-fees.fields.corpCoTrusteeFees.reviewLabel',
            options: {
              name: _get(
                defaultCorpExec.displayName,
                'tKey',
                defaultCorpExec.displayName,
              ),
            },
          },
        },
        {
          name: 'hasCheckedCorpExecFeeAgreement',
          type: 'checkbox',
          hideOnReview: true,
          optional: true,
        },
        {
          name: 'corpTrusteeDetails',
          type: 'preventCleanse',
          hideOnReview: true,
          optional: true,
        },
      ],
      sideEffects: [
        {
          trigger: { corpCoTrusteeFees: 'continue' },
          questionFragments: {
            corpTrusteeDetails: {
              ref: defaultCorpExec.ref,
              role: 'co-trustee',
              agreeDate: format(new Date(), 'MMMM d, yyyy'),
              legalName: defaultCorpExec.legalName,
            },
          },
        },
        {
          trigger: { corpCoTrusteeFees: 'no' },
          questionFragments: {
            corpTrusteeDetails: undefined,
            hasCheckedCorpExecFeeAgreement: false,
          },
        },
      ],
      trigger: {
        $and: [
          { partnerAsTrustee: 'yes' },
          { corpCoTrusteeInfo: 'continue' },
          { corpCoTrusteeDisqualifiers: 'continue' },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'co-trustee' },
                ],
              },
            ],
          },
        ],
      },

      sidebar: corporateExecutorFeesSidebar,
    },

    {
      id: 1663012863,
      pathname: 'corporate-co-trustee-client',
      section,
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },
      label: defaultCorpExec.isClientOf.label,
      label2: defaultCorpExec.isClientOf.label2,
      fields: [
        {
          name: 'isCorporateExecutorClient',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-co-trustee-client.fields.isCorporateExecutorClient.reviewLabel',
            options: {
              name: _get(
                defaultCorpExec.shortName,
                'tKey',
                defaultCorpExec.shortName,
              ),
            },
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
            },
            {
              value: 'no',
              label: { tKey: 'common:no' },
            },
          ],
        },
      ],

      sidebar: corporateExecutorClientSidebar,

      trigger: {
        $and: [
          { partnerAsTrustee: 'yes' },
          { corpCoTrusteeInfo: 'continue' },
          { corpCoTrusteeDisqualifiers: 'continue' },
          { corpCoTrusteeFees: 'continue' },
          { 'corpTrusteeDetails.role': 'co-trustee' },
        ],
      },
    },

    {
      id: 1572372343,
      pathname: 'co-trustee',
      section,
      optional: true,

      label: ({ answerStore: { partnerFirstName, partnerLastName } }) =>
        // TODO: partnerFirstName/partnerLastName may be undefined here due to corporate executor
        ({
          tKey: 'questionnaire.trustee:co-trustee.label',
          options: { partnerFirstName, partnerLastName },
        }),

      label2: { tKey: 'questionnaire.trustee:co-trustee.label2' },

      subLabel: adultSameProvinceExecutor,

      fields: [
        {
          name: 'coTrusteeName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.trustee:co-trustee.fields.coTrusteeName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.trustee:co-trustee.fields.coTrusteeName.reviewLabel',
          },
          appointeeType: appointeeTypes.CO_TRUSTEE,
        },
        {
          name: 'coTrusteeRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],

      trigger: {
        partnerAsTrustee: 'yes',
        province: { $exists: true },
        partnerFirstName: { $exists: true },
        partnerLastName: { $exists: true },
        $or: [
          {
            // TAG: TD setup
            'referrer.ref': 'td',
          },
          {
            $and: [
              { corpCoTrusteeInfo: { $ne: 'skipCoExecutor' } },
              {
                $or: [
                  { corpCoTrusteeInfo: 'no' },
                  { corpCoTrusteeDisqualifiers: 'no' },
                  { corpCoTrusteeFees: 'no' },
                  {
                    $and: [
                      { corpTrusteeDetails: { $exists: true } },
                      { 'corpTrusteeDetails.role': { $ne: 'co-trustee' } },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      sidebar: [
        trusteeSidebarFragments.executorResponsibilities,
        trusteeSidebarFragments.executorHireProfessional,
        trusteeSidebarFragments.executorCompensation,
        trusteeSidebarFragments.executorOutsideProvince,
      ],
    },

    // 🐸🧀☎️🥶🚀🍉😈 Corporate Alt-Trustee Starts //

    {
      id: 1657757483,
      pathname: 'corporate-alt-trustee-info',
      section,

      label: ({
        answerStore: {
          partnerFirstName,
          partnerLastName,
          partnerGender,
          coTrusteeName,
        },
      }) => {
        const partnerGenderKey =
          partnerGender === 'male'
            ? 'male'
            : partnerGender === 'female'
              ? 'female'
              : 'other'

        return coTrusteeName
          ? {
              tKey: 'questionnaire.trustee:corporate-alt-trustee-info.label.case1',
              options: { partnerFirstName, partnerLastName, coTrusteeName },
            }
          : {
              tKey: `questionnaire.trustee:corporate-alt-trustee-info.label.case2.${partnerGenderKey}`,
              options: { partnerFirstName, partnerLastName },
            }
      },

      label2: {
        tKey: 'questionnaire.trustee:corporate-alt-trustee-info.label2',
      },

      subLabel: {
        tKey: 'questionnaire.trustee:corporate-alt-trustee-info.subLabel',
      },

      fields: [
        {
          name: 'corpAltTrusteeInfo',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-alt-trustee-info.fields.corpAltTrusteeInfo.reviewLabel',
          },
          options: [
            {
              value: 'no',
              reviewLabel: { tKey: 'common:no' },
              label: {
                tKey: 'questionnaire.trustee:corporate-alt-trustee-info.fields.corpAltTrusteeInfo.options.no.label',
              },
            },
            {
              value: 'continue',
              reviewLabel: { tKey: 'common:yes' },
              label: {
                tKey: 'questionnaire.trustee:corporate-alt-trustee-info.fields.corpAltTrusteeInfo.options.continue.label',
              },
            },
            {
              value: 'skip',
              reviewLabel: { tKey: 'common:no' },
              label: {
                tKey: 'questionnaire.trustee:corporate-alt-trustee-info.fields.corpAltTrusteeInfo.options.skip.label',
              },
            },
          ],
        },
      ],

      trigger: {
        $and: [
          // TAG: TD setup
          { 'referrer.ref': { $ne: 'td' } },
          { partnerAsTrustee: 'yes' },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'alt-trustee' },
                ],
              },
            ],
          },
        ],
      },
      sidebar: corporateExecutorIntroSidebar,
    },

    {
      id: 1657757515,
      pathname: 'corporate-alt-trustee-disqualifiers',
      section,
      type: 'corporateExecutorDisqualifiers',
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },

      label: {
        tKey: 'questionnaire.trustee:corporate-alt-trustee-disqualifiers.label',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      label2: {
        tKey: 'questionnaire.trustee:corporate-alt-trustee-disqualifiers.label2',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      corporateExecutorType: CONSTANTS.corporateExecutorTypes.ALT_EXECUTOR,
      autoOpenSidebar: true,
      hideOnReview: true,
      fields: [
        {
          name: 'corpAltTrusteeDisqualifiers',
          type: 'radio',
          options: [
            { value: 'continue', label: { tKey: 'common:continue' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { partnerAsTrustee: 'yes' },
          { corpAltTrusteeInfo: 'continue' },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'alt-trustee' },
                ],
              },
            ],
          },
        ],
      },
      sidebar: corporateExecutorDisqualifiersSidebar,
    },

    {
      id: 1657757547,
      pathname: 'corporate-alt-trustee-fees',
      section,
      type: 'corporateExecutorFeeAgreement',
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },

      label: {
        tKey: 'questionnaire.trustee:corporate-alt-trustee-fees.label',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      label2: {
        tKey: 'questionnaire.trustee:corporate-alt-trustee-fees.label2',
      },

      corporateExecutorType: CONSTANTS.corporateExecutorTypes.ALT_EXECUTOR,

      fields: [
        {
          name: 'corpAltTrusteeFees',
          type: 'quickRadio',
          optional: true,
          options: corpTrusteeFeesOptions,
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-alt-trustee-fees.fields.corpAltTrusteeFees.reviewLabel',
            options: {
              name: _get(
                defaultCorpExec.displayName,
                'tKey',
                defaultCorpExec.displayName,
              ),
            },
          },
        },
        {
          name: 'hasCheckedCorpExecFeeAgreement',
          type: 'checkbox',
          hideOnReview: true,
          optional: true,
        },
        {
          name: 'corpTrusteeDetails',
          type: 'preventCleanse',
          hideOnReview: true,
          optional: true,
        },
      ],

      sideEffects: [
        {
          trigger: { corpAltTrusteeFees: 'continue' },
          questionFragments: {
            corpTrusteeDetails: {
              role: 'alt-trustee',
              ref: defaultCorpExec.ref,
              legalName: defaultCorpExec.legalName,
              agreeDate: format(new Date(), 'MMMM d, yyyy'),
            },
          },
        },
        {
          trigger: { corpAltTrusteeFees: 'no' },
          questionFragments: {
            corpTrusteeDetails: undefined,
            hasCheckedCorpExecFeeAgreement: false,
          },
        },
      ],
      trigger: {
        $and: [
          { partnerAsTrustee: 'yes' },
          { corpAltTrusteeInfo: 'continue' },
          { corpAltTrusteeDisqualifiers: 'continue' },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'alt-trustee' },
                ],
              },
            ],
          },
        ],
      },
      sidebar: corporateExecutorFeesSidebar,
    },

    {
      id: 1668465324,
      pathname: 'corporate-alt-trustee-client',
      section,
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },
      label: defaultCorpExec.isClientOf.label,
      label2: defaultCorpExec.isClientOf.label2,
      fields: [
        {
          name: 'isCorporateExecutorClient',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-alt-trustee-client.fields.isCorporateExecutorClient.reviewLabel',
            options: {
              name: _get(
                defaultCorpExec.shortName,
                'tKey',
                defaultCorpExec.shortName,
              ),
            },
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
            },
            {
              value: 'no',
              label: { tKey: 'common:no' },
            },
          ],
        },
      ],
      trigger: {
        $and: [
          { partnerAsTrustee: 'yes' },
          { corpAltTrusteeInfo: 'continue' },
          { corpAltTrusteeDisqualifiers: 'continue' },
          { corpAltTrusteeFees: 'continue' },
          { 'corpTrusteeDetails.role': 'alt-trustee' },
        ],
      },
      sidebar: corporateExecutorClientSidebar,
    },

    // Alt Trustee (with partner, without corporate executor)
    {
      id: 1572378146,
      pathname: 'alt-trustee',
      section,
      optional: true,

      label: ({
        answerStore: {
          corpTrusteeDetails,
          partnerFirstName,
          partnerLastName,
          partnerGender,
          coTrusteeName,
        },
      }) => {
        const partnerGenderKey =
          partnerGender === 'male'
            ? 'male'
            : partnerGender === 'female'
              ? 'female'
              : 'other'

        return coTrusteeName
          ? {
              tKey: 'questionnaire.trustee:alt-trustee.label.case1.a',
              options: { partnerFirstName, partnerLastName, coTrusteeName },
            }
          : corpTrusteeDetails?.role === 'co-trustee'
            ? {
                tKey: 'questionnaire.trustee:alt-trustee.label.case1.b',
                options: {
                  partnerFirstName,
                  partnerLastName,
                  name: _get(
                    defaultCorpExec.displayName,
                    'tKey',
                    defaultCorpExec.displayName,
                  ),
                },
              }
            : {
                tKey: `questionnaire.trustee:alt-trustee.label.case2.${partnerGenderKey}`,
                options: { partnerFirstName, partnerLastName },
              }
      },

      label2: { tKey: 'questionnaire.trustee:alt-trustee.label2' },
      subLabel: adultSameProvinceExecutor,
      fields: [
        {
          name: 'altTrusteeName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.trustee:alt-trustee.fields.altTrusteeName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.trustee:alt-trustee.fields.altTrusteeName.reviewLabel',
          },
          appointeeType: appointeeTypes.ALTERNATE_TRUSTEE,
        },
        {
          name: 'altTrusteeRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],

      trigger: {
        partnerAsTrustee: 'yes',
        province: { $exists: true },
        partnerGender: { $exists: true },
        partnerFirstName: { $exists: true },
        partnerLastName: { $exists: true },
        $or: [
          {
            // TAG: TD setup
            'referrer.ref': 'td',
          },
          {
            'referrer.reftag': 'royal-trusted',
            corpTrusteeDetails: { $exists: true },
            'corpTrusteeDetails.role': { $eq: 'co-trustee' },
          },
          {
            $and: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $or: [
                  { corpAltTrusteeInfo: 'no' },
                  { corpAltTrusteeDisqualifiers: 'no' },
                  { corpAltTrusteeFees: 'no' },
                ],
              },
            ],
          },
        ],
      },

      sidebar: [
        trusteeSidebarFragments.executorResponsibilities,
        trusteeSidebarFragments.executorHireProfessional,
        trusteeSidebarFragments.executorCompensation,
        trusteeSidebarFragments.executorOutsideProvince,
        trusteeSidebarFragments.multipleExecutors,
      ],
    },

    // Alt Trustee (with partner, with corporate executor)
    {
      id: 1661880069,
      pathname: 'alt-trustee-corporate-executor',
      section,

      label: ({
        answerStore: {
          coTrusteeName,
          partnerFirstName,
          partnerLastName,
          corpTrusteeDetails,
        },
      }) => {
        if (corpTrusteeDetails?.role === 'co-trustee') {
          return {
            tKey: 'questionnaire.trustee:alt-trustee-corporate-executor.label.case1',
            options: {
              partnerFirstName,
              partnerLastName,
              name: _get(
                defaultCorpExec.displayName,
                'tKey',
                defaultCorpExec.displayName,
              ),
            },
          }
        }

        return coTrusteeName
          ? {
              tKey: 'questionnaire.trustee:alt-trustee-corporate-executor.label.case2',
              options: {
                partnerFirstName,
                partnerLastName,
                coTrusteeName,
                name: _get(
                  defaultCorpExec.displayName,
                  'tKey',
                  defaultCorpExec.displayName,
                ),
              },
            }
          : {
              tKey: 'questionnaire.trustee:alt-trustee-corporate-executor.label.case3',
              options: {
                partnerFirstName,
                partnerLastName,
                name: _get(
                  defaultCorpExec.displayName,
                  'tKey',
                  defaultCorpExec.displayName,
                ),
              },
            }
      },

      label2: ({ answerStore: { corpTrusteeDetails } }) =>
        corpTrusteeDetails?.role === 'co-trustee'
          ? ''
          : {
              tKey: 'questionnaire.trustee:alt-trustee-corporate-executor.label2',
              options: {
                name: _get(
                  defaultCorpExec.displayName,
                  'tKey',
                  defaultCorpExec.displayName,
                ),
              },
            },

      subLabel: adultSameProvinceExecutor,
      fields: [
        {
          name: 'altTrusteeName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.trustee:alt-trustee-corporate-executor.fields.altTrusteeName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.trustee:alt-trustee-corporate-executor.fields.altTrusteeName.reviewLabel',
          },
          appointeeType: appointeeTypes.ALTERNATE_TRUSTEE,
        },
        {
          name: 'altTrusteeRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          { partnerAsTrustee: 'yes' },
          { province: { $exists: true } },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
          { corpTrusteeDetails: { $exists: true } },
          { 'referrer.reftag': { $ne: 'royal-trusted' } },
        ],
      },
      sidebar: [
        trusteeSidebarFragments.executorResponsibilities,
        trusteeSidebarFragments.executorHireProfessional,
        trusteeSidebarFragments.executorCompensation,
        trusteeSidebarFragments.executorOutsideProvince,
        trusteeSidebarFragments.multipleExecutors,
      ],
    },

    // 🐸🧀☎️🥶🚀🍉😈 Corporate Trustee Starts //

    {
      id: 1657742050,
      pathname: 'corporate-trustee-info',
      section,

      label: ({ answerStore: { hasPartner } }) =>
        hasPartner === 'yes'
          ? undefined
          : { tKey: 'questionnaire.trustee:corporate-trustee-info.label' },

      label2: ({ answerStore: { hasPartner } }) =>
        hasPartner === 'yes'
          ? {
              tKey: 'questionnaire.trustee:corporate-trustee-info.label2.case1',
            }
          : {
              tKey: 'questionnaire.trustee:corporate-trustee-info.label2.case2',
            },

      fields: [
        {
          name: 'corpTrusteeInfo',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-trustee-info.fields.corpTrusteeInfo.reviewLabel',
          },
          options: [
            {
              value: 'no',
              label: {
                tKey: 'questionnaire.trustee:corporate-trustee-info.fields.corpTrusteeInfo.options.no.label',
              },
              reviewLabel: { tKey: 'common:no' },
            },
            {
              value: 'continue',
              label: {
                tKey: 'questionnaire.trustee:corporate-trustee-info.fields.corpTrusteeInfo.options.continue.label',
              },
              reviewLabel: { tKey: 'common:yes' },
            },
          ],
        },
      ],

      autoOpenSidebar: true,
      trigger: {
        $and: [
          // TAG: TD setup
          { 'referrer.ref': { $ne: 'td' } },
          { $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }] },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'trustee' },
                ],
              },
            ],
          },
        ],
      },
      sidebar: corporateExecutorIntroSidebar,
    },

    {
      id: 1657742339,
      pathname: 'corporate-trustee-disqualifiers',
      type: 'corporateExecutorDisqualifiers',
      section,
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },

      label: {
        tKey: 'questionnaire.trustee:corporate-trustee-disqualifiers.label',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      label2: {
        tKey: 'questionnaire.trustee:corporate-trustee-disqualifiers.label2',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      autoOpenSidebar: true,
      hideOnReview: true,
      corporateExecutorType: CONSTANTS.corporateExecutorTypes.EXECUTOR,
      fields: [
        {
          name: 'corpTrusteeDisqualifiers',
          type: 'radio',
          options: [
            { value: 'continue', label: { tKey: 'common:continue' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { corpTrusteeInfo: 'continue' },
          { $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }] },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'trustee' },
                ],
              },
            ],
          },
        ],
      },
      sidebar: corporateExecutorDisqualifiersSidebar,
    },

    {
      id: 1657742484,
      pathname: 'corporate-trustee-fees',
      section,
      type: 'corporateExecutorFeeAgreement',
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },

      label: {
        tKey: 'questionnaire.trustee:corporate-trustee-fees.label',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      label2: { tKey: 'questionnaire.trustee:corporate-trustee-fees.label2' },

      corporateExecutorType: CONSTANTS.corporateExecutorTypes.EXECUTOR,
      fields: [
        {
          name: 'corpTrusteeFees',
          type: 'quickRadio',
          options: corpTrusteeFeesOptions,
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-trustee-fees.fields.corpTrusteeFees.reviewLabel',
            options: {
              name: _get(
                defaultCorpExec.displayName,
                'tKey',
                defaultCorpExec.displayName,
              ),
            },
          },
        },
        {
          name: 'hasCheckedCorpExecFeeAgreement',
          type: 'checkbox',
          hideOnReview: true,
          optional: true,
        },
        {
          name: 'corpTrusteeDetails',
          type: 'preventCleanse',
          hideOnReview: true,
          optional: true,
        },
      ],
      sideEffects: [
        {
          trigger: { corpTrusteeFees: 'continue' },
          questionFragments: {
            corpTrusteeDetails: {
              ref: defaultCorpExec.ref,
              role: 'trustee',
              agreeDate: format(new Date(), 'MMMM d, yyyy'),
              legalName: defaultCorpExec.legalName,
            },
          },
        },
        {
          trigger: { corpTrusteeFees: 'no' },
          questionFragments: {
            corpTrusteeDetails: undefined,
            hasCheckedCorpExecFeeAgreement: false,
          },
        },
      ],
      trigger: {
        $and: [
          { corpTrusteeInfo: 'continue' },
          { corpTrusteeDisqualifiers: 'continue' },
          { $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }] },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'trustee' },
                ],
              },
            ],
          },
        ],
      },
      sidebar: corporateExecutorFeesSidebar,
    },

    {
      id: 1663013382,
      pathname: 'corporate-trustee-client',
      section,
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },
      label: defaultCorpExec.isClientOf.label,
      label2: defaultCorpExec.isClientOf.label2,
      fields: [
        {
          name: 'isCorporateExecutorClient',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:corporate-trustee-client.fields.isCorporateExecutorClient.reviewLabel',
            options: {
              name: _get(
                defaultCorpExec.shortName,
                'tKey',
                defaultCorpExec.shortName,
              ),
            },
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
            },
            {
              value: 'no',
              label: { tKey: 'common:no' },
            },
          ],
        },
      ],

      sidebar: corporateExecutorClientSidebar,

      trigger: {
        $and: [
          { corpTrusteeInfo: 'continue' },
          { corpTrusteeDisqualifiers: 'continue' },
          { corpTrusteeFees: 'continue' },
          { $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }] },
          { 'corpTrusteeDetails.role': 'trustee' },
        ],
      },
    },

    {
      id: 1572378617,
      pathname: 'non-partner-trustee',
      section,
      label: { tKey: 'questionnaire.trustee:non-partner-trustee.label' },
      subLabel: adultSameProvinceExecutor,
      sidebar: [
        trusteeSidebarFragments.executorResponsibilities,
        trusteeSidebarFragments.executorHireProfessional,
        trusteeSidebarFragments.executorCompensation,
        trusteeSidebarFragments.executorOutsideProvince,
        trusteeSidebarFragments.multipleExecutors,
      ],

      interceptModal: [
        {
          label: {
            tKey: 'questionnaire.trustee:non-partner-trustee.interceptModal.item1.label',
          },
          subLabel: {
            tKey: 'questionnaire.trustee:non-partner-trustee.interceptModal.item1.subLabel',
          },
          primaryButtonLabel: {
            tKey: 'questionnaire.trustee:non-partner-trustee.interceptModal.item1.primaryButtonLabel',
          },
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.TO_QUESTION,
            questionId: 1572371939,
          },
          secondaryButtonLabel: { tKey: 'common:close' },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
          },
          trigger: {
            $and: [
              {
                nonPartnerTrusteeRelationship:
                  CONSTANTS.relationshipValues.MY_PARTNER,
              },
              { partnerAsTrustee: 'no' },
            ],
          },
        },
      ],

      fields: [
        {
          name: 'nonPartnerTrusteeName',
          type: 'text',
          optional: false,
          placeholder: {
            tKey: 'questionnaire.trustee:non-partner-trustee.fields.nonPartnerTrusteeName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.trustee:non-partner-trustee.fields.nonPartnerTrusteeName.reviewLabel',
          },
          appointeeType: appointeeTypes.TRUSTEE,
        },
        {
          name: 'nonPartnerTrusteeRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],
      trigger: {
        $and: [
          {
            $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }],
          },
          {
            $or: [
              {
                // TAG: TD setup
                'referrer.ref': 'td',
              },
              {
                $or: [
                  { corpTrusteeInfo: 'no' },
                  { corpTrusteeDisqualifiers: 'no' },
                  { corpTrusteeFees: 'no' },
                  {
                    $and: [
                      { corpTrusteeDetails: { $exists: true } },
                      { 'corpTrusteeDetails.role': { $ne: 'trustee' } },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    // 🐸🧀☎️🥶🚀🍉😈 Non-Partner Corporate Alt-Trustee Starts //
    {
      id: 1668462494,
      pathname: 'non-partner-corporate-alt-trustee-info',
      section,

      label: ({ answerStore: { nonPartnerTrusteeName } }) => ({
        tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-info.label',
        options: { nonPartnerTrusteeName },
      }),

      label2: {
        tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-info.label2',
      },

      subLabel: {
        tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-info.subLabel',
      },

      fields: [
        {
          name: 'nonPartnerCorpAltTrusteeInfo',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-info.fields.nonPartnerCorpAltTrusteeInfo.reviewLabel',
          },
          options: [
            {
              value: 'no',
              reviewLabel: { tKey: 'common:no' },
              label: {
                tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-info.fields.nonPartnerCorpAltTrusteeInfo.options.no.label',
              },
            },
            {
              value: 'continue',
              reviewLabel: { tKey: 'common:yes' },
              label: {
                tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-info.fields.nonPartnerCorpAltTrusteeInfo.options.continue.label',
              },
            },
            {
              value: 'skip',
              reviewLabel: { tKey: 'common:no' },
              label: {
                tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-info.fields.nonPartnerCorpAltTrusteeInfo.options.skip.label',
              },
            },
          ],
        },
      ],
      trigger: {
        $and: [
          {
            // TAG: TD setup
            'referrer.ref': { $ne: 'td' },
          },
          { nonPartnerTrusteeName: { $exists: true } },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'non-partner-alt-trustee' },
                ],
              },
            ],
          },
          { $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }] },
        ],
      },
      sidebar: corporateExecutorIntroSidebar,
    },

    {
      id: 1668462696,
      pathname: 'non-partner-corporate-alt-trustee-disqualifiers',
      section,
      type: 'corporateExecutorDisqualifiers',
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },

      label: {
        tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-disqualifiers.label',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      label2: {
        tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-disqualifiers.label2',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      corporateExecutorType: CONSTANTS.corporateExecutorTypes.ALT_EXECUTOR,
      autoOpenSidebar: true,
      hideOnReview: true,
      fields: [
        {
          name: 'nonPartnerCorpAltTrusteeDisqualifiers',
          type: 'radio',
          options: [
            { value: 'continue', label: { tKey: 'common:continue' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { nonPartnerTrusteeName: { $exists: true } },
          { nonPartnerCorpAltTrusteeInfo: 'continue' },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'non-partner-alt-trustee' },
                ],
              },
            ],
          },
          { $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }] },
        ],
      },
      sidebar: corporateExecutorDisqualifiersSidebar,
    },

    {
      id: 1668462698,
      pathname: 'non-partner-corporate-alt-trustee-fees',
      section,
      type: 'corporateExecutorFeeAgreement',
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },

      label: {
        tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-fees.label',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      label2: {
        tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-fees.label2',
      },

      corporateExecutorType: CONSTANTS.corporateExecutorTypes.ALT_EXECUTOR,

      fields: [
        {
          name: 'nonPartnerCorpAltTrusteeFees',
          type: 'quickRadio',
          optional: true,
          options: corpTrusteeFeesOptions,
          reviewLabel: {
            tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-fees.fields.nonPartnerCorpAltTrusteeFees.reviewLabel',
            options: {
              name: _get(
                defaultCorpExec.displayName,
                'tKey',
                defaultCorpExec.displayName,
              ),
            },
          },
        },
        {
          name: 'hasCheckedCorpExecFeeAgreement',
          type: 'checkbox',
          hideOnReview: true,
          optional: true,
        },
        {
          name: 'corpTrusteeDetails',
          type: 'preventCleanse',
          hideOnReview: true,
          optional: true,
        },
      ],
      sideEffects: [
        {
          trigger: { nonPartnerCorpAltTrusteeFees: 'continue' },
          questionFragments: {
            corpTrusteeDetails: {
              ref: defaultCorpExec.ref,
              role: 'non-partner-alt-trustee',
              agreeDate: format(new Date(), 'MMMM d, yyyy'),
              legalName: defaultCorpExec.legalName,
            },
          },
        },
        {
          trigger: { nonPartnerCorpAltTrusteeFees: 'no' },
          questionFragments: {
            corpTrusteeDetails: undefined,
            hasCheckedCorpExecFeeAgreement: false,
          },
        },
      ],
      trigger: {
        $and: [
          { nonPartnerTrusteeName: { $exists: true } },
          { nonPartnerCorpAltTrusteeInfo: 'continue' },
          { nonPartnerCorpAltTrusteeDisqualifiers: 'continue' },
          {
            $or: [
              { corpTrusteeDetails: { $exists: false } },
              {
                $and: [
                  { corpTrusteeDetails: { $exists: true } },
                  { 'corpTrusteeDetails.role': 'non-partner-alt-trustee' },
                ],
              },
            ],
          },
          { $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }] },
        ],
      },
      sidebar: corporateExecutorFeesSidebar,
    },

    {
      id: 1668465963,
      pathname: 'non-partner-corporate-alt-trustee-client',
      section,
      graphic: {
        image: defaultCorpExec.logoPrimary,
        maxHeight: defaultCorpExec.questionnaireLogoMaxHeight,
      },
      label: defaultCorpExec.isClientOf.label,
      label2: defaultCorpExec.isClientOf.label2,

      fields: [
        {
          name: 'isCorporateExecutorClient',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:non-partner-corporate-alt-trustee-client.fields.isCorporateExecutorClient.reviewLabel',
            options: {
              name: _get(
                defaultCorpExec.shortName,
                'tKey',
                defaultCorpExec.shortName,
              ),
            },
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
            },
            {
              value: 'no',
              label: { tKey: 'common:no' },
            },
          ],
        },
      ],
      trigger: {
        $and: [
          { nonPartnerTrusteeName: { $exists: true } },
          { nonPartnerCorpAltTrusteeInfo: 'continue' },
          { nonPartnerCorpAltTrusteeDisqualifiers: 'continue' },
          { nonPartnerCorpAltTrusteeFees: 'continue' },
          { 'corpTrusteeDetails.role': 'non-partner-alt-trustee' },
        ],
      },
      sidebar: corporateExecutorClientSidebar,
    },

    // Alt Trustee (without partner, without corporate executor)
    {
      id: 1572378837,
      pathname: 'non-partner-alt-trustee',
      section,
      optional: true,

      label: ({
        answerStore: { nonPartnerTrusteeName, corpTrusteeDetails },
      }) =>
        corpTrusteeDetails?.role === 'trustee'
          ? {
              tKey: 'questionnaire.trustee:non-partner-alt-trustee.label.b',
              options: {
                name: _get(
                  defaultCorpExec.displayName,
                  'tKey',
                  defaultCorpExec.displayName,
                ),
              },
            }
          : {
              tKey: 'questionnaire.trustee:non-partner-alt-trustee.label.a',
              options: { nonPartnerTrusteeName },
            },

      label2: { tKey: 'questionnaire.trustee:non-partner-alt-trustee.label2' },

      subLabel: adultSameProvinceExecutor,
      fields: [
        {
          name: 'altNonPartnerTrusteeName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.trustee:non-partner-alt-trustee.fields.altNonPartnerTrusteeName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.trustee:non-partner-alt-trustee.fields.altNonPartnerTrusteeName.reviewLabel',
          },
          appointeeType: appointeeTypes.ALTERNATE_TRUSTEE,
        },
        {
          name: 'altNonPartnerTrusteeRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],

      trigger: {
        $and: [
          { province: { $exists: true } },
          {
            $or: [
              {
                $and: [
                  { nonPartnerTrusteeName: { $exists: true } },
                  { corpTrusteeDetails: { $exists: false } },
                  { nonPartnerCorpAltTrusteeInfo: { $ne: 'skip' } },
                  {
                    $or: [
                      { nonPartnerCorpAltTrusteeInfo: 'no' },
                      { nonPartnerCorpAltTrusteeDisqualifiers: 'no' },
                      { nonPartnerCorpAltTrusteeFees: 'no' },
                    ],
                  },
                ],
              },

              {
                'referrer.reftag': 'royal-trusted',
                'corpTrusteeDetails.role': { $eq: 'trustee' },
              },

              {
                // TAG: TD setup
                'referrer.ref': 'td',
                nonPartnerTrusteeName: { $exists: true },
              },
            ],
          },
          {
            $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }],
          },
        ],
      },

      sidebar: [
        trusteeSidebarFragments.executorResponsibilities,
        trusteeSidebarFragments.executorHireProfessional,
        trusteeSidebarFragments.executorCompensation,
        trusteeSidebarFragments.executorOutsideProvince,
        trusteeSidebarFragments.multipleExecutors,
      ],
    },

    // Alt Trustee (without partner, with corporate executor)
    {
      id: 1661880375,
      pathname: 'non-partner-alt-trustee-corporate-executor',
      section,

      label: ({
        answerStore: { nonPartnerTrusteeName, corpTrusteeDetails },
      }) => {
        if (corpTrusteeDetails?.role === 'trustee') {
          return {
            tKey: 'questionnaire.trustee:non-partner-alt-trustee-corporate-executor.label.case1',
            options: {
              name: _get(
                defaultCorpExec.displayName,
                'tKey',
                defaultCorpExec.displayName,
              ),
            },
          }
        }

        return {
          tKey: 'questionnaire.trustee:non-partner-alt-trustee-corporate-executor.label.case2',
          options: {
            nonPartnerTrusteeName,
            name: _get(
              defaultCorpExec.displayName,
              'tKey',
              defaultCorpExec.displayName,
            ),
          },
        }
      },

      label2: ({ answerStore: { corpTrusteeDetails } }) => {
        if (corpTrusteeDetails?.role === 'trustee') {
          return {
            tKey: 'questionnaire.trustee:non-partner-alt-trustee-corporate-executor.label2.case1',
            options: {
              name: _get(
                defaultCorpExec.displayName,
                'tKey',
                defaultCorpExec.displayName,
              ),
            },
          }
        }

        return {
          tKey: 'questionnaire.trustee:non-partner-alt-trustee-corporate-executor.label2.case2',
          options: {
            name: _get(
              defaultCorpExec.displayName,
              'tKey',
              defaultCorpExec.displayName,
            ),
          },
        }
      },

      subLabel: adultSameProvinceExecutor,
      fields: [
        {
          name: 'altNonPartnerTrusteeName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.trustee:non-partner-alt-trustee-corporate-executor.fields.altNonPartnerTrusteeName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.trustee:non-partner-alt-trustee-corporate-executor.fields.altNonPartnerTrusteeName.reviewLabel',
          },
          appointeeType: appointeeTypes.ALTERNATE_TRUSTEE,
        },
        {
          name: 'altNonPartnerTrusteeRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],

      trigger: {
        $and: [
          { province: { $exists: true } },
          { corpTrusteeDetails: { $exists: true } },
          { 'referrer.reftag': { $ne: 'royal-trusted' } },
          {
            $or: [
              {
                $and: [
                  { 'corpTrusteeDetails.role': { $ne: 'trustee' } },
                  { nonPartnerTrusteeName: { $exists: true } },
                ],
              },
              { 'corpTrusteeDetails.role': { $eq: 'trustee' } },
            ],
          },
          {
            $or: [{ partnerAsTrustee: 'no' }, { hasPartner: 'no' }],
          },
        ],
      },

      sidebar: [
        trusteeSidebarFragments.executorResponsibilities,
        trusteeSidebarFragments.executorHireProfessional,
        trusteeSidebarFragments.executorCompensation,
        trusteeSidebarFragments.executorOutsideProvince,
        trusteeSidebarFragments.multipleExecutors,
      ],
    },

    {
      id: 1608050788,
      pathname: 'keep-resps-open',
      section,

      label: ({ answerStore: { corpTrusteeDetails } }) =>
        corpTrusteeDetails
          ? { tKey: 'questionnaire.trustee:keep-resps-open.label.case1' }
          : { tKey: 'questionnaire.trustee:keep-resps-open.label.case2' },

      label2: { tKey: 'questionnaire.trustee:keep-resps-open.label2' },

      subLabel: ({
        answerStore: { corpTrusteeDetails, altNonPartnerTrusteeName },
      }) => {
        if (
          corpTrusteeDetails &&
          (corpTrusteeDetails?.role !== 'trustee' ||
            (corpTrusteeDetails?.role === 'trustee' &&
              altNonPartnerTrusteeName))
        ) {
          return {
            tKey: 'questionnaire.trustee:keep-resps-open.subLabel',
            options: {
              name: _get(
                defaultCorpExec.displayName,
                'tKey',
                defaultCorpExec.displayName,
              ),
            },
          }
        }

        return undefined
      },

      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item1.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item1.children.item3.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item1.children.item4.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item2.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item2.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item2.children.item3.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item3.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item3.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item3.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.trustee:keep-resps-open.sidebar.item3.children.item3.body',
              },
            },
          ],
        },
      ],

      fields: [
        {
          name: 'respClause',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.trustee:keep-resps-open.fields.respClause.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: { hasResps: 'yes' },
    },

    {
      id: 1726001517,
      pathname: 'successor-subscriber',
      section,
      optional: true,

      label: {
        tKey: 'questionnaire.trustee:successor-subscriber.label',
      },

      subLabel: {
        tKey: 'questionnaire.trustee:successor-subscriber.subLabel',
        options: {
          name: _get(
            defaultCorpExec.displayName,
            'tKey',
            defaultCorpExec.displayName,
          ),
        },
      },

      fields: [
        {
          name: 'successorSubscriberName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.trustee:successor-subscriber.fields.successorSubscriberName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.trustee:successor-subscriber.fields.successorSubscriberName.reviewLabel',
          },
        },
        {
          name: 'successorSubscriberRelationship',
          type: 'select',
          label: { tKey: 'common:thisPersonIsMy' },
          hideOnReview: true,
          placeholder: { tKey: 'common:selectOne' },
          options: tKeyRelationshipOptions,
        },
      ],

      trigger: {
        respClause: 'yes',
        'referrer.reftag': 'royal-trusted',
        'corpTrusteeDetails.role': 'trustee',
        altNonPartnerTrusteeName: { $exists: false },
      },
    },

    {
      id: 1572371608,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: { tKey: 'questionnaire.trustee:review.label' },
      subLabel: { tKey: 'questionnaire.trustee:review.subLabel' },
    },
  ],
}
