import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'
import { useQuery } from '@tanstack/react-query'

import { errorHandler, PATHS, request } from '../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../common/utils/queryKeys'

export default () =>
  useQuery({
    queryKey: queryKeys[queryKeysBase.staticDocumentDownloadEvents](),
    queryFn: () =>
      request({
        method: 'GET',
        url: PATHS.DOCUMENT_DOCUMENT_DOWNLOADS,
        params: {
          type: CONSTANTS.documentDownloadEventTypes.STATIC_INDIVIDUAL,
        },
      }),

    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: process.env.REACT_APP_ENV === 'production' ? 1 : 0,

    select: (res): Set<string | undefined> => {
      const downloadDocuments = _get(res, ['data', 'payload'])

      if (_isEmpty(downloadDocuments)) return new Set()

      // Transform the payload to return a Set of static docTypes that have been downloaded
      return downloadDocuments.reduce(
        (
          acc: Set<string>,
          { documents: downloadEventDocuments }: { documents: any[] },
        ) => {
          downloadEventDocuments.forEach(({ docType }: { docType: string }) => {
            acc.add(docType)
          })
          return acc
        },
        new Set(),
      )
    },

    onError: (error) => errorHandler({ error, reportError: true }),
  })
