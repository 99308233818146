import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Text } from 'grommet'

import useInjectDynamic from '../../../../common/hooks/useInjectDynamic'
import questionnaireSelectors from '../../ducks/questionnaire/questionnaireSelectors'
import validationSelectors from '../../ducks/validation/validationSelectors'
import ValidationMessage from '../molecules/ValidationMessage/ValidationMessage'
import CheckboxField from './CheckboxField'
import ComboBoxField from './ComboBoxField'
import MultiSelectField from './MultiSelectField'
import QuickRadioField from './QuickRadioField/QuickRadioField'
import RadioField from './RadioField'
import RangeField from './RangeField/RangeField'
import SelectField from './SelectField'
import TextAreaField from './TextAreaField'
import TextField from './TextField'

const fieldTypes = {
  text: TextField,
  comboBox: ComboBoxField,
  textArea: TextAreaField,
  email: TextField,
  radio: RadioField,
  multiSelect: MultiSelectField,
  range: RangeField,
  select: SelectField,
  password: TextField,
  quickRadio: QuickRadioField,
  checkbox: CheckboxField,
  preventCleanse: () => <></>,
}

const Field = ({
  field,
  answerCache,
  answerStore,
  invalidFields,
  questionFragments,
  isInvalidOverride,
  displayValidation,
  answerStoreOverride,
  handleFieldFragmentUpdate,
}) => {
  const FieldComponent = fieldTypes[field.type]

  /*
   * Use different answerStore depending on the environmental context
   * (either questionnaire environment or bonusDoc environment) to supply to fields
   */
  const theAnswerStore = answerStoreOverride || answerStore

  const { resolveValue } = useInjectDynamic()

  return (
    <>
      {FieldComponent && (
        <>
          {field.label && (
            <div className="min-w-full mt-4 mb-5 flex flex-col items-center text-center">
              <Text weight="bold" size="smedium">
                {resolveValue(field.label)}
              </Text>
            </div>
          )}

          <FieldComponent
            field={field}
            validationMessage={
              <ValidationMessage
                field={field}
                displayValidationOverride={isInvalidOverride}
              />
            }
            isInvalid={
              (displayValidation &&
                invalidFields &&
                invalidFields.includes(field.name)) ||
              isInvalidOverride
            }
            answerStore={theAnswerStore}
            questionFragments={questionFragments}
            /*
             * in the bonusDoc environment, their is no answerCache, so we
             * use the answerStoreOverride, if it exists, which will have been
             * passed in by the bonusDocQuestionnaire
             */
            answerCache={answerStoreOverride || answerCache}
            handleFieldFragmentUpdate={handleFieldFragmentUpdate}
          />
        </>
      )}
    </>
  )
}

Field.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
  }).isRequired,
  isInvalidOverride: PropTypes.bool,
  answerStoreOverride: PropTypes.shape({}),
  answerStore: PropTypes.shape({}).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  questionFragments: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  displayValidation: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  questionFragments: questionnaireSelectors.getQuestionFragments(
    state.questionnaire,
  ),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  answerCache: questionnaireSelectors.getAnswerCache(state.questionnaire),
  invalidFields: validationSelectors.getInvalidFields(state.questionnaire),
  displayValidation: validationSelectors.getDisplayValidation(
    state.questionnaire,
  ),
})

export default connect(mapStateToProps)(Field)
