import React from 'react'
import PropTypes from 'prop-types'

import { Heading, Paragraph } from 'grommet'
import { useTranslation } from 'react-i18next'

import EverythingButton from '../../molecules/EverythingButton'

const SummaryHeading = ({ answerStore }) => {
  const { firstName } = answerStore

  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center text-center">
      <div className="pb-1.5 leading-relaxed sm:pb-0.5">
        <Heading level={5} size="large">
          {firstName
            ? t('summary:components.SummaryHeading.label.case1', {
                name: firstName,
              })
            : t('summary:components.SummaryHeading.label.case2')}
        </Heading>
      </div>

      <div className="pb-1.5">
        <Paragraph size="smedium">
          {t('summary:components.SummaryHeading.subLabel')}
        </Paragraph>
      </div>

      <div className="mt-6 mb-4">
        <EverythingButton />
      </div>
    </div>
  )
}

SummaryHeading.propTypes = {
  answerStore: PropTypes.shape({ firstName: PropTypes.string }).isRequired,
}

export default SummaryHeading
