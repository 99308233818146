import React from 'react'
import PropTypes from 'prop-types'

import { Box, useTheme } from '@mui/material'

import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import Text from '../../atoms/Text'

const HighlightPill = ({
  label,
  variant = 'primary',
  dataTestIdModifier,
  textColorTypeOverride,
  backgroundColorOverride,
  textColorVariantOverride,
}) => {
  const theme = useTheme()
  const isPrimary = variant === 'primary'
  const isLabelString = typeof label === 'string'

  return (
    <Box
      data-testid={`highlight-pill${
        dataTestIdModifier ? `-${dataTestIdModifier}` : ''
      }`}
      sx={{
        backgroundColor:
          backgroundColorOverride ||
          (isPrimary
            ? theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.LIGHT]
            : theme.palette[themeColorTypes.NEUTRAL][themeColorVariants.LIGHT]),
        pt: isPrimary ? '0.17rem' : '0.5rem',
        pb: isPrimary ? '0.17rem' : '0.4rem',
        px: isPrimary ? '0.5rem' : '1.75rem',
        borderRadius: theme.shape.borderRadius,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLabelString ? (
        <Text
          color={textColorTypeOverride || themeColorTypes.BRAND}
          colorVariant={textColorVariantOverride || themeColorVariants.MAIN}
          weight="medium"
          sx={{
            fontSize: '0.77rem',
            letterSpacing: '0.25px',
          }}
        >
          {label}
        </Text>
      ) : (
        label
      )}
    </Box>
  )
}

HighlightPill.propTypes = {
  label: PropTypes.node.isRequired,
  dataTestIdModifier: PropTypes.string,
  textColorTypeOverride: PropTypes.string,
  backgroundColorOverride: PropTypes.string,
  textColorVariantOverride: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
}

export default HighlightPill
