// returns array of names that can be used to autocomplete text inputs in questionnaire

import _get from 'lodash/get'
import _uniqBy from 'lodash/uniqBy'

import { CONSTANTS } from '@epilogue/common'

import toCapitalCase from '../../../common/formatting/toCapitalCase'
import relationshipOptions from '../data/options/relationshipOptions'
import triggeredFields from './triggeredFields'

export default ({
  data,
  abTest,
  referrer,
  isPartner,
  documents,
  answerStore,
  charityPartner,
}) => {
  const theTriggeredFields = triggeredFields({
    abTest,
    referrer,
    isPartner,
    documents,
    answerStore,
    theData: data,
    charityPartner,
  })

  const namesObj = Object.keys(answerStore).reduce((acc, key) => {
    const field = theTriggeredFields.find((field) => field.name === key)

    // Any field that has a non-partner appointeeType is one that we want to add to the nameStore
    if (field?.appointeeType && !field.appointeeType.isPartner) {
      const name = answerStore[key]
      // Find the relationship object for the corresponding relationship value in the answerStore
      const relationshipObj = relationshipOptions.find(
        ({ value }) =>
          /*
           * Because we are guaranteed that any question that has an appointeeType field
           * will have both a name field and a relationship field (see test in question.test.js),
           * we can take the name field, split the 'Name' ending off, and slap 'Relationship' on
           * the end of it, and we'll have the relationship field name. This will be sent to the
           * nameStore along with the appointee's name.
           */
          value === answerStore[`${key.split('Name')[0]}Relationship`],
      )

      // If the relationship value is 'none', we want the subLabel to be undefined in the nameStore
      const relationship =
        relationshipObj?.value === CONSTANTS.relationshipValues.NONE
          ? undefined
          : relationshipObj

      return [{ label: toCapitalCase(name), subLabel: relationship }, ...acc]
    }
    return acc
  }, [])

  // Return only items that are unique by label and subLabel.value
  return _uniqBy(
    namesObj,
    ({ label, subLabel }) => label.toLowerCase() + _get(subLabel, 'value'),
  )
}
