import _cloneDeep from 'lodash/cloneDeep'
import _dropRightWhile from 'lodash/dropRightWhile'
import _isEmpty from 'lodash/isEmpty'
import _set from 'lodash/set'

import { createLogic } from 'redux-logic'

import commonSelectors from '../../../../../common/ducks/commonSelectors'
import dashboardSelectors from '../../../../Dashboard/ducks/dashboard/dashboardSelectors'
import documentsSelectors from '../../../../Dashboard/ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../../Dashboard/ducks/payments/paymentsSelectors'
import data from '../../../data'
import { allQuestions, triggeredQuestions } from '../../../engine'
import questionnaireActions from '../../questionnaire/questionnaireActions'
import questionnaireSelectors from '../../questionnaire/questionnaireSelectors'
import navigationActions from '../navigationActions'
import navigationSelectors from '../navigationSelectors'
import navigationTypes from '../navigationTypes'

export default createLogic({
  name: 'toQuestion',
  type: navigationTypes.TO_QUESTION,

  transform({ action }, next) {
    const actionClone = _cloneDeep(action)
    const { question, questionId } = actionClone.payload

    const requestedQuestion =
      question ||
      // converts questionId into a question object
      allQuestions(data).find((q) => q.id === questionId)

    _set(actionClone, 'payload.question', requestedQuestion)
    next(actionClone)
  },

  process({ getState, action }, dispatch, done) {
    const state = getState()

    const { questionnaire } = state.questionnaire

    const abTest = questionnaire?.abTest
    const answerStore = questionnaire?.answerStore
    const accountCreated = questionnaire?.accountCreated

    const payments = paymentsSelectors.getPayments(state.dashboard)
    const charityPartner = commonSelectors.getCharityPartner(state)
    const isPartner = dashboardSelectors.getIsPartner(state.dashboard)
    const referrer = questionnaireSelectors.getReferrer(state.questionnaire)
    const interceptModalData = navigationSelectors.getInterceptModal(
      state.questionnaire,
    )
    const documents = documentsSelectors.getDocuments(state.dashboard)

    const { question, fromDashboard } = action.payload

    /*
     * When navigation to another question, the interceptModal
     * should always be an empty object
     */
    if (!_isEmpty(interceptModalData)) {
      dispatch(navigationActions.interceptModal({}))
    }

    // clear fragments if any magically built up (which can happen)
    dispatch(questionnaireActions.clearQuestionFragments())

    const questionStack = triggeredQuestions({
      abTest,
      referrer,
      payments,
      isPartner,
      documents,
      answerStore,
      theData: data,
      accountCreated,
      charityPartner,
    })

    const newQuestionStack = _dropRightWhile(
      questionStack,
      (q) => q.id !== question.id,
    )

    /*
     * only if the user is coming from the dashboard, we want
     * to prevent the question animation because there is a weird visual bug
     */
    if (fromDashboard) {
      dispatch(navigationActions.preventAnimation(true))
    }

    dispatch(
      navigationActions.updateQuestionStack({
        questionStack: newQuestionStack,
      }),
    )

    done()
  },
})
