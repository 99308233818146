import triggeredQuestions from './triggeredQuestions'

export default ({
  data,
  abTest,
  payments,
  referrer,
  isPartner,
  documents,
  answerStore,
  questionStack,
  charityPartner,
  accountCreated,
}) => {
  const theTriggeredQuestions = triggeredQuestions({
    abTest,
    referrer,
    payments,
    isPartner,
    documents,
    answerStore,
    theData: data,
    accountCreated,
    charityPartner,
  })

  // Remove untriggered questions from the questionStack
  return questionStack.filter(({ id: questionStackId }) =>
    theTriggeredQuestions.some(
      ({ id: triggeredQuestionId }) => triggeredQuestionId === questionStackId,
    ),
  )
}
