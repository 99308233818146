import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { Button, Heading, Text } from 'grommet'
import { LinkNext } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import LoadingSpinner from '../../../../../common/components/atoms/LoadingSpinner'
import StyledLabel from '../../../../../common/components/atoms/StyledLabel'
import TextInput from '../../../../../common/components/atoms/TextInput'
import commonActions from '../../../../../common/ducks/commonActions'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import tw from '../../../../../common/styles/tw'
import NavigationButtons from '../../molecules/NavigationButtons/NavigationButtons'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'

const PaddedButton = styled(Button)`
  padding: ${tw.spacing['1']} ${tw.spacing['3']};
`
const UnsupportedProvince = ({
  question,
  answerCache,
  invalidFields,
  handleFieldFragmentUpdate,
  dispatchSetGlobalErrorMessage,
}) => {
  const [email, setEmail] = useState()
  const [working, setWorking] = useState()
  const [success, setSuccess] = useState()

  const { t } = useTranslation()

  const [field] = question.fields

  const { unsupportedProvince } = answerCache

  const handleSubmit = async () => {
    setWorking(true)

    try {
      await request({
        method: 'POST',
        url: PATHS.UNSUPPORTED_PROVINCE,
        data: { email, province: unsupportedProvince },
      })

      setSuccess(true)
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to send. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    }
    setWorking(false)
  }

  return (
    <>
      {success ? (
        <div className="flex flex-col items-start mt-10">
          <div className="m-auto text-center">
            <Heading level={2}>
              {t('customQuestion:UnsupportedProvince.success.label')}
            </Heading>
          </div>
          <div className="mt-2 text-center mb-6">
            <Heading level={5}>
              {t('customQuestion:UnsupportedProvince.success.subLabel')}
            </Heading>
          </div>

          <div className="m-auto">
            <PaddedButton
              plain
              hoverIndicator
              reverse
              onClick={() => {
                window.location.href = 'https://www.epiloguewills.com'
              }}
              icon={<LinkNext color="grey-5" size="small" />}
              label={
                <Text color="grey-5" size="small">
                  {t('customQuestion:UnsupportedProvince.success.button.label')}
                </Text>
              }
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center max-w-xl">
          <div className="mb-3 min-w-full text-center">
            <StyledLabel>
              {t('customQuestion:UnsupportedProvince.main.label')}
            </StyledLabel>
          </div>

          <div className="mb-8 min-w-full text-center">
            <Heading level={4} size="small">
              {t('customQuestion:UnsupportedProvince.main.subLabel')}
            </Heading>
          </div>

          <ValidationMessage field={field} />

          <div className="max-w-sm w-full">
            <TextInput
              type={field.type}
              disabled={success}
              value={email}
              placeholder={t(
                _get(field, 'placeholder.tKey', field.placeholder),
              )}
              onInputChange={(val) => {
                setEmail(val)
                handleFieldFragmentUpdate({
                  [field.name]: val,
                })
              }}
            />
          </div>

          <>
            {working ? (
              <LoadingSpinner delay="0" />
            ) : (
              <NavigationButtons
                nextLabel={{ tKey: 'common:submit' }}
                handleButtonClick={() => {
                  if (_isEmpty(invalidFields)) {
                    handleSubmit()
                  }
                }}
              />
            )}
          </>
        </div>
      )}
    </>
  )
}

UnsupportedProvince.propTypes = {
  answerCache: PropTypes.shape({
    unsupportedProvince: PropTypes.string.isRequired,
  }).isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(null, mapDispatchToProps)(UnsupportedProvince)
