import bonusDocsTypes from './bonusDocsTypes'

const initialState = {
  bonusDocsAnswerStore: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case bonusDocsTypes.SET_BONUS_DOCS_ANSWER_STORE:
      return {
        ...state,
        bonusDocsAnswerStore: action.payload,
      }

    case bonusDocsTypes.UPDATE_BONUS_DOCS_ANSWER_STORE_TYPE:
      return {
        ...state,
        bonusDocsAnswerStore: {
          ...state.bonusDocsAnswerStore,
          [action.payload.type]: { ...action.payload.fragment },
        },
      }

    default:
      return state
  }
}
