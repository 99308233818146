import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import commonActions from '../../../../../common/ducks/commonActions'
import amplitude from '../../../../../common/lib/amplitude'

const SidebarTextToggle = ({ children, dispatchUpdateSidebar }) => (
  <Box
    component="span"
    aria-roledescription="Open Sidebar"
    sx={{
      textDecoration: 'underline',
      lineBreak: 'none',
      ':hover': { cursor: 'pointer' },
    }}
    onClick={() => {
      dispatchUpdateSidebar({ isOpen: true })
      amplitude.sendEvent('ClickedSidebarSublabel')
    }}
  >
    {children}
  </Box>
)

SidebarTextToggle.propTypes = {
  children: PropTypes.node.isRequired,
  dispatchUpdateSidebar: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateSidebar: (sidebarProperties) =>
    dispatch(commonActions.updateSidebar(sidebarProperties)),
})

export default connect(null, mapDispatchToProps)(SidebarTextToggle)
