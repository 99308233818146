import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const VideoPlayerWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0px;
`
const StyledVideoPlayer = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const YoutubePlayer = ({ youtubeId, autoplay = false }) => (
  <VideoPlayerWrapper>
    <StyledVideoPlayer
      title={youtubeId}
      type="text/html"
      src={`https://www.youtube.com/embed/${youtubeId}?rel=0&showinfo=0&controls=1&autoplay=${
        autoplay ? 1 : 0
      }`}
      frameBorder="0"
      allowFullScreen
    />
  </VideoPlayerWrapper>
)

YoutubePlayer.propTypes = {
  youtubeId: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
}

export default YoutubePlayer
