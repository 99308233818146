import _get from 'lodash/get'

export default ({ referrer }) => {
  const ref = _get(referrer, ['ref'])

  if (ref === 'march-of-dimes-canada') {
    return {
      tKey: 'questionnaire.distribution:common.charitableGiftsLabel.march-of-dimes-canada',
    }
  }

  // TAG: Will Power
  if (ref === 'willpower') {
    return {
      tKey: 'questionnaire.distribution:common.charitableGiftsLabel.willpower',
    }
  }

  return {
    tKey: 'questionnaire.distribution:common.charitableGiftsLabel.default',
  }
}
