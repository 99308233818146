import _isEmpty from 'lodash/isEmpty'

import { useQuery } from '@tanstack/react-query'

import { errorHandler, PATHS, request } from '../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../common/utils/queryKeys'

export default ({
  answerStore,
  addOnCart,
  promoCode,
  userHasCoreProduct,
  dispatchSetGlobalErrorMessage,
}) =>
  useQuery({
    queryKey: queryKeys[queryKeysBase.productCharge](
      promoCode,
      addOnCart,
      answerStore,
    ),
    queryFn: async () => {
      const res = await request({
        method: 'POST',
        url: PATHS.PAYMENT_PRODUCT_CHARGE,
        data: {
          promoCode,
          answerStore,
          addOns: addOnCart,
        },
      })

      return res.data.payload
    },

    keepPreviousData: true,
    /*
     * Only fetch charge if user has no core product or has add-ons
     * in the cart, otherwise, there is nothing to fetch anyway
     */
    enabled: !userHasCoreProduct || !_isEmpty(addOnCart),
    retry: process.env.REACT_APP_ENV === 'production' ? 1 : 0,

    onError: (error) => {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to retrieve product pricing. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    },
  })
