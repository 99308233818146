import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Box, Dialog, DialogContent, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import IconButton from '../IconButton'

const useStyles = makeStyles({
  content: {
    display: ({ contentWrapperDisplay }) => contentWrapperDisplay,
    padding: ({ fullBleed, isMobile }) =>
      fullBleed ? '0' : isMobile ? '1.5rem' : '2rem',
    /*
     * the first-child stuff below needs to be the be a duplicate
     * of the styles above, otherwise, Material UI adds their own
     * unecessary styles
     */
    '&:first-child': {
      padding: ({ fullBleed, isMobile }) =>
        fullBleed ? '0' : isMobile ? '1.5rem' : '2rem',
    },
  },

  closeButton: {
    zIndex: 9999,
    top: '0.1rem',
    right: '0.1rem',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
})

/** @type {React.ForwardRefRenderFunction<?, Modal.propTypes> */
const Modal = forwardRef(
  (
    {
      sx = {},
      show = false,
      onClose,
      children,
      maxWidth = false,
      className,
      fullBleed = false,
      keepMounted = false,
      preventClose = false,
      transitionDuration,
      contentWrapperDisplay = 'flex',
      'data-testid': dataTestId,
    },
    ref,
  ) => {
    const modalContentRef = ref?.modalContentRef

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
    const classes = useStyles({ fullBleed, isMobile, contentWrapperDisplay })

    return (
      <Dialog
        open={show}
        onClose={(event, reason) => {
          if (!preventClose) {
            onClose(event, reason)
          }
        }}
        PaperProps={{ sx: { position: 'relative', ...sx } }}
        maxWidth={maxWidth}
        fullScreen={isMobile}
        className={className}
        data-testid={dataTestId}
        transitionDuration={transitionDuration}
        keepMounted={keepMounted}
      >
        {!preventClose && (
          <IconButton
            onClick={onClose}
            data-testid="modal-close-button"
            className={classes.closeButton}
            IconComponent={<CloseRoundedIcon />}
          />
        )}
        <DialogContent
          ref={modalContentRef}
          classes={{ root: classes.content }}
        >
          <Box sx={{ mx: 'auto', width: '100%' }}>{children}</Box>
        </DialogContent>
      </Dialog>
    )
  },
)

Modal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  sx: PropTypes.shape({}),
  fullBleed: PropTypes.bool,
  className: PropTypes.string,
  keepMounted: PropTypes.bool,
  preventClose: PropTypes.bool,
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired,
  transitionDuration: PropTypes.shape({
    enter: PropTypes.number,
    exit: PropTypes.number,
  }),
  contentWrapperDisplay: PropTypes.oneOf(['flex', 'block']),
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', false]),
}

export default Modal
