import { CONSTANTS } from '@epilogue/common'
import { useQuery } from '@tanstack/react-query'

import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../../../../../common/utils/queryKeys'

export default ({ docId, dispatchSetGlobalErrorMessage, onSuccess }) =>
  useQuery({
    queryKey: queryKeys[queryKeysBase.previewDoc](docId),
    queryFn: () =>
      request({
        method: 'GET',
        responseType: 'arraybuffer',
        params: { type: CONSTANTS.documentDownloadEventTypes.UNPAID_PREVIEW },
        url: `${PATHS.DOCUMENT_DOWNLOAD_DOC_PREVIEW}/${docId}`,
      }),

    enabled: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,

    onSuccess,

    onError: (error) =>
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to download document preview. Please refresh and try again.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      }),
  })
