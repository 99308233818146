import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import paymentsSelectors from '../../../scenes/Dashboard/ducks/payments/paymentsSelectors'
import questionnaireSelectors from '../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import logo from '../../assets/images/logo.svg'
import commonSelectors from '../../ducks/commonSelectors'
import Button from '../atoms/Button'
import Heading from '../atoms/Heading'
import LoadingSpinner from '../atoms/LoadingSpinner'

type WorldTypes = keyof typeof CONSTANTS.worldTypes

interface Props {
  auth: boolean
  payments: any
  worldType: WorldTypes
  availableWorlds: WorldTypes[]
}

const FourOhFour = ({ auth, worldType, payments, availableWorlds }: Props) => {
  const navigate = useNavigate()
  const hasPayments = !_isEmpty(payments)
  const [working, setWorking] = useState(true)

  useEffect(() => {
    if (auth) {
      // If 'will' world is available, always favour it as the default re-direct
      if (availableWorlds.includes(CONSTANTS.worldTypes.will.type)) {
        window.location.href = `${CONSTANTS.worldTypes.will.dashboardPath}?w=${CONSTANTS.worldTypes.will.type}`
      } else if (availableWorlds.length === 1) {
        // Users with only one available world should be directed to that world
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { dashboardPath, type } = Object.values(
          CONSTANTS.worldTypes,
        ).find((w) => w.type === availableWorlds[0])!
        // Leave this as is - we want a full refresh here
        window.location.href = `${dashboardPath}?w=${type}`
      } else if (!hasPayments) {
        // Unpaid users should be redirected to dashboard
        navigate(CONSTANTS.worldTypes[worldType].dashboardPath)
      } else {
        setWorking(false)
      }
    } else {
      setWorking(false)
    }
  }, [auth, availableWorlds, hasPayments, navigate, worldType])

  const { t } = useTranslation()

  return (
    <>
      {working ? (
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingSpinner />
        </Box>
      ) : (
        <Box
          sx={{
            mt: '80px',
            px: '2rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: '100%', maxWidth: '900px' }}>
            <Heading
              variant="h1"
              align="center"
              sx={{ fontSize: '3.75rem', mt: '1.75rem', mb: '0.75rem' }}
            >
              404
            </Heading>

            <Heading
              variant="h4"
              align="center"
              sx={{ mt: '1.75rem', mb: '0.75rem' }}
            >
              {t('components:FourOhFour.label')}
            </Heading>
          </Box>

          <Box
            sx={{
              mb: '3rem',
              mt: '1.7rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link to="https://epiloguewills.com/">
              <Button size="lg" label={t('common:home')} variant="contained" />
            </Link>

            <Box sx={{ ml: '2.2rem' }}>
              <Link to="/login">
                <Button
                  size="lg"
                  label={t('common:logIn')}
                  variant="contained"
                />
              </Link>
            </Box>
          </Box>

          <Link to="https://epiloguewills.com/">
            <img src={logo} alt="Epilogue Logo" width="115px" />
          </Link>
        </Box>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  auth: commonSelectors.getAuth(state),
  world: commonSelectors.getWorld(state),
  payments: paymentsSelectors.getPayments(state.dashboard),
  availableWorlds: commonSelectors.getAvailableWorlds(state),
  answerCache: questionnaireSelectors.getAnswerCache(state.questionnaire),
})

export default connect(mapStateToProps)(FourOhFour)
