export default (arr) => {
  const str = arr.reduce(
    (acc, currentVal) =>
      `${acc}${currentVal.name} ${
        currentVal.distribution === 'likeChild'
          ? 'will receive an equal share'
          : 'will not be included'
      }, `,
    '',
  )
  return str.slice(0, -2)
}
