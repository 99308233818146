import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { useTranslation } from 'react-i18next'

import Select from '../../../../../common/components/atoms/Select'

// import navigationActions from '../../../ducks/navigation/navigationActions'

const SelectField = ({
  field,
  answerCache,
  questionFragments,
  handleFieldFragmentUpdate,
  isInvalid = false,
}) => {
  const { name, options, placeholder, defaultValue } = field

  const { t } = useTranslation()

  /*
   * Translate the placeholder if it's an object and has a tKey property,
   * otherwise use the placeholder as is
   */
  const translatedPlaceholder = t(_get(placeholder, 'tKey', placeholder))

  const translatedOptions = options.map((option) => ({
    value: option.value,
    // Translate label if it has tKey, otherwise use label as is
    label: t(_get(option, 'label.tKey', option.label)),
  }))

  const theDefaultValue = answerCache[name] || defaultValue

  const [fieldValue, setFieldValue] = useState(theDefaultValue)

  useEffect(() => {
    if (theDefaultValue) {
      handleFieldFragmentUpdate({ [name]: fieldValue })
    }

    /*
     * when selectField's value changes, dispatch the navNextAutomatic action,
     * the operations middleware will decide whether or not to follow through
     * on the auto advance, but it still gets triggered here
     */
    // if (fieldValue !== theDefaultValue) {
    //   navNextAutomatic({ fragment: { [name]: fieldValue } })
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue])

  useEffect(() => {
    /*
     * If the field value and question fragment value for this field are out of
     * sync, set the field value to match the question fragment value.
     * This can happen if a user uses the nameStore to auto-fill an input field that
     * also updates the relationship dropdown on that question.
     */
    if (questionFragments[name] && questionFragments[name] !== fieldValue) {
      setFieldValue(questionFragments[name])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionFragments])

  return (
    <div className="max-w-xxs w-full">
      <Select
        selectId={`select-${name}`}
        isInvalid={isInvalid}
        options={translatedOptions}
        value={fieldValue}
        placeholder={translatedPlaceholder}
        defaultValue={defaultValue}
        onInputChange={(value) => {
          setFieldValue(value)
          handleFieldFragmentUpdate({ [name]: value })
        }}
      />
    </div>
  )
}

SelectField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    defaultValue: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({ tKey: PropTypes.string }),
        ]),
      }),
    ),
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  questionFragments: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
}

export default SelectField
