import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import CheckIcon from '@mui/icons-material/Check'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Tooltip, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Heading from '../../../../../../common/components/atoms/Heading'
import IconButton from '../../../../../../common/components/atoms/IconButton'
import Text from '../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import dashboardSelectors from '../../../../ducks/dashboard/dashboardSelectors'

const ReferralLink = ({ customerReferralCode = undefined }) => {
  const { t } = useTranslation()

  const COPY_STATUS_TYPES = {
    initial: {
      type: 'INITIAL',
      label: t(
        'dashboard:components.ReferralLink.copyStatusTypes.initial.label',
      ),
    },
    success: {
      type: 'SUCCESS',
      label: t(
        'dashboard:components.ReferralLink.copyStatusTypes.success.label',
      ),
    },
    fail: {
      type: 'FAIL',
      label: t('dashboard:components.ReferralLink.copyStatusTypes.fail.label'),
    },
  }

  const [copyStatus, setCopyStatus] = useState(COPY_STATUS_TYPES.initial)

  const hasClipboard = !!navigator?.clipboard

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const referralLink = `https://epiloguewills.com/?ref=customer-referral&reftag=${customerReferralCode}`

  const handleCopyToClipboard = () =>
    navigator.clipboard.writeText(referralLink).then(
      () => {
        // ON SUCCESS
        setCopyStatus(COPY_STATUS_TYPES.success)
        setTimeout(() => setCopyStatus(COPY_STATUS_TYPES.initial), 1400)
        amplitude.sendEvent('CopiedReferralLink', {
          success: true,
        })
      },
      // ON FAIL
      () => {
        amplitude.sendEvent('CopiedReferralLink', {
          success: false,
        })
        setCopyStatus(COPY_STATUS_TYPES.fail)
      },
    )

  return (
    <>
      {customerReferralCode && (
        <Box
          data-testid="referral-link-wrapper"
          sx={{
            mt: '1.7rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0.5rem',
            flexDirection: 'column',
            p: isMobile ? '2rem 1.3rem' : '2.5rem 3.9rem',
            border: `2px solid ${
              themeColors[themeColorTypes.NEUTRAL][themeColorVariants.DARK]
            }`,
            backgroundColor:
              themeColors[themeColorTypes.WHITE][themeColorVariants.MAIN],
          }}
        >
          <Box sx={{ mb: '1.3rem' }}>
            <Heading align="center" variant="h4">
              {t('dashboard:components.ReferralLink.label')}
            </Heading>
          </Box>

          <Box sx={{ mb: '1.35rem' }}>
            <Text align="center" variant="paragraph" size="sm">
              {t('dashboard:components.ReferralLink.subLabel1')}
            </Text>
            <Text
              size="sm"
              align="center"
              variant="paragraph"
              sx={{ mt: '1rem', mb: '0.2rem' }}
            >
              {t('dashboard:components.ReferralLink.subLabel2.part1')}{' '}
              <Box component="span" sx={{ textDecoration: 'underline' }}>
                {t('dashboard:components.ReferralLink.subLabel2.part2')}
              </Box>
              .
            </Text>
          </Box>

          <Box
            sx={{
              cursor: 'pointer',
              pl: isMobile ? '0.6rem' : '1rem',
              pr: isMobile ? '0.1rem' : '0.4rem',
              py: '0.3rem',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: `2px dashed ${
                themeColors[themeColorTypes.ACCENT_2][themeColorVariants.MAIN]
              }`,
              backgroundColor:
                themeColors[themeColorTypes.ACCENT_2][
                  themeColorVariants.LIGHTER
                ],
            }}
            data-testid="referral-link-clickable-copy-area"
            onClick={hasClipboard && handleCopyToClipboard}
          >
            <Text
              align={
                copyStatus.label &&
                copyStatus.type === COPY_STATUS_TYPES.success.type
                  ? 'center'
                  : 'left'
              }
              noWrap
              size="md"
              sx={{ width: '100%' }}
              data-testid="referral-link-text"
            >
              {copyStatus.label &&
              copyStatus.type === COPY_STATUS_TYPES.success.type
                ? copyStatus.label
                : referralLink}
            </Text>

            {hasClipboard && (
              <Tooltip
                arrow
                placement="top"
                title={copyStatus.label || 'Click to copy'}
                onClose={() =>
                  setTimeout(
                    () => setCopyStatus(COPY_STATUS_TYPES.initial),
                    190,
                  )
                }
              >
                <IconButton
                  color={themeColorTypes.ACCENT_2}
                  IconComponent={
                    copyStatus.type === COPY_STATUS_TYPES.success.type ? (
                      <CheckIcon />
                    ) : (
                      <ContentCopyIcon />
                    )
                  }
                  data-testid="referral-link-copy-button"
                  colorVariant={themeColorVariants.DARKEST}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

ReferralLink.propTypes = { customerReferralCode: PropTypes.string }

const mapStateToProps = (state) => ({
  customerReferralCode: dashboardSelectors.getCustomerReferralCode(
    state.dashboard,
  ),
})

export default connect(mapStateToProps)(ReferralLink)
