export default {
  screens: {
    xs: '320px',
    '2xs': '375px',
    '3xs': '475px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },

  colors: {
    transparent: 'transparent',

    black: '#221C54',
    white: '#FFFFFF',

    background: '#FAF5EC',

    brand: '#221C54',

    'accent-1-lightest': '#F2C1AB',
    'accent-1-lighter': '#EA9771',
    'accent-1-light': '#E6865B',
    'accent-1': '#E37645',
    'accent-1-dark': '#E0662F',
    'accent-1-darker': '#D55820',
    'accent-1-darkest': '#BF4F1D',

    'accent-2-lighter': '#EAE8F6',
    'accent-2-light': '#D9D5EF',
    'accent-2': '#C8C3E8',
    'accent-2-dark': '#B7B1E1',
    'accent-2-darker': '#A69EDA',
    'accent-2-darkest': '#4C4679',

    'accent-3': '#FFFFFF',

    'neutral-1': '#FAF5EC',
    'neutral-2': '#EEDFC5',
    'neutral-3': '#DDC59C',

    'status-critical': '#c33b1c',
    'status-ok': '#27ae60',
    'status-warning': '#e49812',
    'status-unknown': '#dec69c',

    'grey-1': '#F3F3F3',
    'grey-2': '#CFCACE',
    'grey-3': '#B3ADBA',
    'grey-4': '#8E89A0',
    'grey-5': '#58517A',

    mustard: '#C5822B',
  },

  spacing: {
    px: '1px',
    0: '0',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    8: '2rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    18: '4.5rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    48: '12rem',
    56: '14rem',
    64: '16rem',
    76: '18rem',
    88: '20rem',
    104: '22rem',
    120: '24rem',
    132: '26rem',
    148: '28rem',
    164: '30rem',
  },

  backgroundPosition: {
    bottom: 'bottom',
    center: 'center',
    left: 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    right: 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    top: 'top',
  },

  backgroundSize: {
    auto: 'auto',
    cover: 'cover',
    contain: 'contain',
  },

  borderRadius: {
    none: '0',
    sm: '0.125rem',
    default: '0.25rem',
    lg: '0.5rem',
    xl: '0.75rem',
    full: '9999px',
  },

  borderWidth: {
    default: '1px',
    0: '0',
    2: '2px',
    4: '4px',
    8: '8px',
  },

  boxShadow: {
    sm: '0 1px 2px -1px rgba(0, 0, 0, 0.05)',
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    none: 'none',
  },

  container: {},

  cursor: {
    auto: 'auto',
    default: 'default',
    pointer: 'pointer',
    wait: 'wait',
    text: 'text',
    move: 'move',
    'not-allowed': 'not-allowed',
  },

  fill: {
    current: 'currentColor',
  },

  flex: {
    1: '1 1 0%',
    auto: '1 1 auto',
    initial: '0 1 auto',
    none: 'none',
  },

  flexGrow: {
    0: '0',
    default: '1',
  },

  flexShrink: {
    0: '0',
    default: '1',
  },

  fontFamily: {
    sans: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
    mono: [
      'Menlo',
      'Monaco',
      'Consolas',
      '"Liberation Mono"',
      '"Courier New"',
      'monospace',
    ],
  },

  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },

  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },

  inset: {
    0: '0',
    auto: 'auto',
  },

  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },

  lineHeight: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
  },

  listStyleType: {
    none: 'none',
    disc: 'disc',
    decimal: 'decimal',
  },

  maxHeight: {
    full: '100%',
    screen: '100vh',
  },

  maxWidth: {
    xxs: '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    full: '100%',
  },

  minHeight: {
    0: '0',
    full: '100%',
    screen: '100vh',
  },

  minWidth: {
    0: '0',
    full: '100%',
  },

  objectPosition: {
    bottom: 'bottom',
    center: 'center',
    left: 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    right: 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    top: 'top',
  },

  opacity: {
    0: '0',
    10: '.1',
    20: '.2',
    30: '.3',
    40: '.4',
    50: '.5',
    60: '.6',
    70: '.7',
    80: '.8',
    90: '.9',
    100: '1',
  },

  zIndex: {
    auto: 'auto',
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
  },
}
