import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../common/components/atoms/Button'
import commonActions from '../../../../../../../common/ducks/commonActions'
import event from '../../../../../../../common/lib/event'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import { Appointment } from '../../../../../../../types/appointment.types'
import addOnsActions from '../../../../../ducks/addOns/addOnsActions'
import appointmentsActions from '../../../../../ducks/appointments/appointmentsActions'
import appointmentsSelectors from '../../../../../ducks/appointments/appointmentsSelectors'
import dashboardSelectors from '../../../../../ducks/dashboard/dashboardSelectors'
import useCancelAppointment from '../modals/BookWitnessingModal/hooks/useCancelAppointment'

interface Props {
  userId: string
  onSuccess: () => void
  dispatchSetAddOns: (addOns: any) => void
  pendingWitnessingAppointments: Appointment[]
  dispatchSetGlobalErrorMessage: (msg: string) => void
  dispatchSetAppointments: (appointments: Appointment[]) => void
}

const CancelAppointmentButton = ({
  userId,
  onSuccess,
  dispatchSetAddOns,
  dispatchSetAppointments,
  pendingWitnessingAppointments,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { isLoading: working, mutate: handleCancelAppointment } =
    useCancelAppointment({ dispatchSetGlobalErrorMessage })

  const [witnessingAppointment] = pendingWitnessingAppointments
  const appointmentId = _get(witnessingAppointment, '_id')

  const [confirmCancel, setConfirmCancel] = useState(false)

  useEffect(() => () => setConfirmCancel(false), [])

  const { t } = useTranslation()

  return (
    <Button
      working={working}
      variant="contained"
      spinnerPadding="0.375rem 4.203rem"
      color={confirmCancel ? themeColorTypes.RED : themeColorTypes.BRAND}
      label={
        confirmCancel
          ? t(
              'documents:components.buttons.CancelAppointmentButton.label.case1',
            )
          : t(
              'documents:components.buttons.CancelAppointmentButton.label.case2',
            )
      }
      data-testid={
        confirmCancel
          ? 'cancel-appointment-confirm-button'
          : 'cancel-appointment-button'
      }
      onClick={() => {
        if (confirmCancel && appointmentId) {
          handleCancelAppointment(
            { appointmentId },
            {
              onSuccess: ({ data }) => {
                const addOns = _get(data, 'payload.addOns')
                const appointments = _get(data, 'payload.appointments')

                dispatchSetAddOns(addOns)
                dispatchSetAppointments(appointments)

                event.send({
                  name: CONSTANTS.eventNames.WITNESSING_APPOINTMENT,
                  action: CONSTANTS.eventActionTypes.CANCELLED,
                  metadata: { userId, appointmentId },
                })

                onSuccess()
              },
            },
          )
        } else {
          setConfirmCancel(true)
        }
      }}
    />
  )
}

const mapStateToProps = (state: any) => ({
  userId: dashboardSelectors.getUserId(state.dashboard),
  pendingWitnessingAppointments:
    appointmentsSelectors.getPendingWitnessingAppointments(state.dashboard),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
  dispatchSetAppointments: (appointments: Appointment[]) =>
    dispatch(appointmentsActions.setAppointments({ appointments })),
  dispatchSetAddOns: (addOns: any) => dispatch(addOnsActions.setAddOns(addOns)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelAppointmentButton)
