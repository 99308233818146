import _flattenDeep from 'lodash/flattenDeep'
import _isEmpty from 'lodash/isEmpty'

import triggeredQuestions from './triggeredQuestions'

export default ({
  abTest,
  theData,
  referrer,
  payments,
  isPartner,
  documents,
  answerStore,
  charityPartner,
  accountCreated,
  includeOptionalFieldsAndQuestions = true,
}) => {
  const triggeredQuestionsThatHaveFields = triggeredQuestions({
    abTest,
    theData,
    payments,
    referrer,
    isPartner,
    documents,
    answerStore,
    charityPartner,
    accountCreated,
  }).filter(
    (q) =>
      !_isEmpty(q.fields) && (includeOptionalFieldsAndQuestions || !q.optional),
  )

  return _flattenDeep(
    triggeredQuestionsThatHaveFields.map((q) => q.fields),
  ).filter((f) => includeOptionalFieldsAndQuestions || !f.optional)
}
