import { CONSTANTS } from '@epilogue/common'

// eslint-disable-next-line import/prefer-default-export
export const STATES = [
  {
    label: CONSTANTS.states.ALABAMA,
    value: CONSTANTS.states.ALABAMA,
  },
  {
    label: CONSTANTS.states.ALASKA,
    value: CONSTANTS.states.ALASKA,
  },
  {
    label: CONSTANTS.states.ARIZONA,
    value: CONSTANTS.states.ARIZONA,
  },
  {
    label: CONSTANTS.states.ARKANSAS,
    value: CONSTANTS.states.ARKANSAS,
  },
  {
    label: CONSTANTS.states.CALIFORNIA,
    value: CONSTANTS.states.CALIFORNIA,
  },
  {
    label: CONSTANTS.states.COLORADO,
    value: CONSTANTS.states.COLORADO,
  },
  {
    label: CONSTANTS.states.CONNECTICUT,
    value: CONSTANTS.states.CONNECTICUT,
  },
  {
    label: CONSTANTS.states.DELAWARE,
    value: CONSTANTS.states.DELAWARE,
  },
  {
    label: CONSTANTS.states.FLORIDA,
    value: CONSTANTS.states.FLORIDA,
  },
  {
    label: CONSTANTS.states.GEORGIA,
    value: CONSTANTS.states.GEORGIA,
  },
  {
    label: CONSTANTS.states.HAWAII,
    value: CONSTANTS.states.HAWAII,
  },
  {
    label: CONSTANTS.states.IDAHO,
    value: CONSTANTS.states.IDAHO,
  },
  {
    label: CONSTANTS.states.ILLINOIS,
    value: CONSTANTS.states.ILLINOIS,
  },
  {
    label: CONSTANTS.states.INDIANA,
    value: CONSTANTS.states.INDIANA,
  },
  {
    label: CONSTANTS.states.IOWA,
    value: CONSTANTS.states.IOWA,
  },
  {
    label: CONSTANTS.states.KANSAS,
    value: CONSTANTS.states.KANSAS,
  },
  {
    label: CONSTANTS.states.KENTUCKY,
    value: CONSTANTS.states.KENTUCKY,
  },
  {
    label: CONSTANTS.states.LOUISIANA,
    value: CONSTANTS.states.LOUISIANA,
  },
  {
    label: CONSTANTS.states.MAINE,
    value: CONSTANTS.states.MAINE,
  },
  {
    label: CONSTANTS.states.MARYLAND,
    value: CONSTANTS.states.MARYLAND,
  },
  {
    label: CONSTANTS.states.MASSACHUSETTS,
    value: CONSTANTS.states.MASSACHUSETTS,
  },
  {
    label: CONSTANTS.states.MICHIGAN,
    value: CONSTANTS.states.MICHIGAN,
  },
  {
    label: CONSTANTS.states.MINNESOTA,
    value: CONSTANTS.states.MINNESOTA,
  },
  {
    label: CONSTANTS.states.MISSISSIPPI,
    value: CONSTANTS.states.MISSISSIPPI,
  },
  {
    label: CONSTANTS.states.MISSOURI,
    value: CONSTANTS.states.MISSOURI,
  },
  {
    label: CONSTANTS.states.MONTANA,
    value: CONSTANTS.states.MONTANA,
  },
  {
    label: CONSTANTS.states.NEBRASKA,
    value: CONSTANTS.states.NEBRASKA,
  },
  {
    label: CONSTANTS.states.NEVADA,
    value: CONSTANTS.states.NEVADA,
  },
  {
    label: CONSTANTS.states.NEW_HAMPSHIRE,
    value: CONSTANTS.states.NEW_HAMPSHIRE,
  },
  {
    label: CONSTANTS.states.NEW_JERSEY,
    value: CONSTANTS.states.NEW_JERSEY,
  },
  {
    label: CONSTANTS.states.NEW_MEXICO,
    value: CONSTANTS.states.NEW_MEXICO,
  },
  {
    label: CONSTANTS.states.NEW_YORK,
    value: CONSTANTS.states.NEW_YORK,
  },
  {
    label: CONSTANTS.states.NORTH_CAROLINA,
    value: CONSTANTS.states.NORTH_CAROLINA,
  },
  {
    label: CONSTANTS.states.NORTH_DAKOTA,
    value: CONSTANTS.states.NORTH_DAKOTA,
  },
  {
    label: CONSTANTS.states.OHIO,
    value: CONSTANTS.states.OHIO,
  },
  {
    label: CONSTANTS.states.OKLAHOMA,
    value: CONSTANTS.states.OKLAHOMA,
  },
  {
    label: CONSTANTS.states.OREGON,
    value: CONSTANTS.states.OREGON,
  },
  {
    label: CONSTANTS.states.PENNSYLVANIA,
    value: CONSTANTS.states.PENNSYLVANIA,
  },
  {
    label: CONSTANTS.states.RHODE_ISLAND,
    value: CONSTANTS.states.RHODE_ISLAND,
  },
  {
    label: CONSTANTS.states.SOUTH_CAROLINA,
    value: CONSTANTS.states.SOUTH_CAROLINA,
  },
  {
    label: CONSTANTS.states.SOUTH_DAKOTA,
    value: CONSTANTS.states.SOUTH_DAKOTA,
  },
  {
    label: CONSTANTS.states.TENNESSEE,
    value: CONSTANTS.states.TENNESSEE,
  },
  {
    label: CONSTANTS.states.TEXAS,
    value: CONSTANTS.states.TEXAS,
  },
  {
    label: CONSTANTS.states.UTAH,
    value: CONSTANTS.states.UTAH,
  },
  {
    label: CONSTANTS.states.VERMONT,
    value: CONSTANTS.states.VERMONT,
  },
  {
    label: CONSTANTS.states.VIRGINIA,
    value: CONSTANTS.states.VIRGINIA,
  },
  {
    label: CONSTANTS.states.WASHINGTON,
    value: CONSTANTS.states.WASHINGTON,
  },
  {
    label: CONSTANTS.states.WEST_VIRGINIA,
    value: CONSTANTS.states.WEST_VIRGINIA,
  },
  {
    label: CONSTANTS.states.WISCONSIN,
    value: CONSTANTS.states.WISCONSIN,
  },
  {
    label: CONSTANTS.states.WYOMING,
    value: CONSTANTS.states.WYOMING,
  },
]
