import { CONSTANTS } from '@epilogue/common'

export default {
  ref: 'rbc',
  logoPrimary:
    'https://epilogue-assets-1.s3.ca-central-1.amazonaws.com/rbc-royal-trust-logo.png',
  questionnaireLogoMaxHeight: '4.7rem',
  shortName: { tKey: 'corporateExecutors:rbc.shortName' },
  displayName: { tKey: 'corporateExecutors:rbc.displayName' },
  legalName: 'Royal Trust Corporation of Canada',
  contact: { phone: '1-833-404-2692', email: 'rbctru@rbc.com' },

  disqualifiers: (corporateExecutorType, partnerFirstName, { reftag }) => {
    const item1 =
      corporateExecutorType === CONSTANTS.corporateExecutorTypes.EXECUTOR
        ? { tKey: 'corporateExecutors:rbc.disqualifiers.item1.case1' }
        : corporateExecutorType === CONSTANTS.corporateExecutorTypes.CO_EXECUTOR
          ? {
              tKey: 'corporateExecutors:rbc.disqualifiers.item1.case2',
              options: { partnerFirstName },
            }
          : { tKey: 'corporateExecutors:rbc.disqualifiers.item1.case3' }

    const item2 =
      corporateExecutorType === CONSTANTS.corporateExecutorTypes.EXECUTOR
        ? { tKey: 'corporateExecutors:rbc.disqualifiers.item2.executor' }
        : corporateExecutorType === CONSTANTS.corporateExecutorTypes.CO_EXECUTOR
          ? {
              tKey: 'corporateExecutors:rbc.disqualifiers.item2.coExecutor',
              options: { partnerFirstName },
            }
          : { tKey: 'corporateExecutors:rbc.disqualifiers.item2.altExecutor' }

    const item3 =
      corporateExecutorType === CONSTANTS.corporateExecutorTypes.ALT_EXECUTOR
        ? { tKey: 'corporateExecutors:rbc.disqualifiers.item3.case2' }
        : { tKey: 'corporateExecutors:rbc.disqualifiers.item3.case1' }

    // If user has reftag of 'royal-trusted', we do not want to show item2
    return reftag === 'royal-trusted' ? [item1, item3] : [item1, item2, item3]
  },

  isClientOf: {
    label: { tKey: 'corporateExecutors:rbc.isClientOf.label' },
    label2: { tKey: 'corporateExecutors:rbc.isClientOf.label2' },
  },
}
