import _flatten from 'lodash/flatten'
import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'

const globallySharedAnswerCacheValues = new Set(['email', 'password'])

export default (world, data) => {
  const { namespaceId } = CONSTANTS.worldTypes[world]

  const allWorldQuestions = _flatten(data.map((sections) => sections.questions))

  /*
   * When iterating over all the questions of a given 'world', if the world has
   * a designated namespace, like 'social_' for the social world, assume
   * that any field-name that is NOT namespaced (social_) is a shared value
   * and should be included in the world's answerStore on authentication.
   *
   * If the world does NOT have a designated namespace, like the 'will' world,
   * assume that only the field names in 'globallySharedAnswerCacheValues' are
   * shared values that should be included in the world's answerStore on authentication.
   */
  return allWorldQuestions.reduce((acc, val) => {
    if (_isEmpty(val.fields)) return acc

    const fieldNames = val.fields
      .filter((field) =>
        namespaceId
          ? !field.name.includes(namespaceId)
          : globallySharedAnswerCacheValues.has(field.name),
      )
      .map((field) => field.name)

    return [...acc, ...fieldNames]
  }, [])
}
