import React, { useMemo } from 'react'

import { alpha, useTheme } from '@mui/material'

import i18n from 'i18next'

import 'react-day-picker/dist/style.css'

import { CONSTANTS } from '@epilogue/common'
// eslint-disable-next-line import/no-duplicates
import { add, format, getDaysInMonth, getMonth, getYear } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { enCA, frCA } from 'date-fns/locale'
import { DayPicker } from 'react-day-picker'

import { SelectedDateInfo, Timeslot } from './datePicker.types'
import availableDates from './utils/availableDates'
import sortTimeslots from './utils/sortTimeslots'

interface Props {
  timezone: string
  maxDaysInFuture: number
  availableTimes: Timeslot[]
  selectedDate: Date | undefined
  onSelect: (selectedDateInfo: SelectedDateInfo) => void
}

const DatePicker = ({
  onSelect,
  timezone,
  selectedDate,
  availableTimes,
  maxDaysInFuture,
}: Props) => {
  const theme: any = useTheme()

  const sortedAvailableDates = sortTimeslots(availableTimes)
  // Timezoneify the available dates
  const theAvailableDates = availableDates(sortedAvailableDates, timezone)

  const today = new Date()

  const currentMonth = getMonth(today)
  const currentYear = getYear(today)

  const nextMonth = getMonth(add(today, { months: 1 }))
  const nextMonthYear = getYear(add(today, { months: 1 }))

  const unavailableDays: Date[] = []

  const availableMonths = [
    new Date(currentYear, currentMonth),
    new Date(nextMonthYear, nextMonth),
  ]

  /*
   * Populate unavailableDays array by comparing all days of a given month,
   * for example, 30 for September and 31 for October, with the available dates.
   * If a date in a given month does not match a corresponding date in the available
   * dates, it is considered unavailable and added to the unavailableDays array.
   */
  availableMonths.forEach((month) => {
    const daysOfMonth = getDaysInMonth(month)
    /*
     * Incoming timeslots are passed in with traditional month numbers (9 for September, 10 for October),
     * not zero-indexed, so we need to convert to zero-indexed because JavaScript months are zero-indexed.
     */
    const zeroIndexMonth = getMonth(month)
    /*
     * We then need to ensure that all months are two digits, so we padStart with
     * a zero if necessary. For example, 9 becomes 09, 10 remains as 10, etc.
     */
    const normalizedTwoDigitMonth = (zeroIndexMonth + 1)
      .toString()
      .padStart(2, '0')

    for (let i = 1; i < daysOfMonth + 1; i += 1) {
      /*
       * Incoming timeslot days are two digits, for example, 01, 02, 03, etc., so
       * we need to ensure that all days are two digits in order to compare to the
       * availableDates. We padStart with a zero if necessary.
       */
      const twoDigitIndexDay = i.toString().padStart(2, '0')

      // Check if iterated date of the month is NOT in the availableDates array
      if (
        !theAvailableDates[
          `${getYear(month)}-${normalizedTwoDigitMonth}-${twoDigitIndexDay}`
        ]
      ) {
        // If the date is NOT in the availableDates array, add that Date to the unavailableDays array
        unavailableDays.push(new Date(getYear(month), zeroIndexMonth, i))
      }
    }
  }, [])

  const disabledDays = [
    ...unavailableDays,
    {
      // Disable today and any dates in the past
      before: add(new Date(), {
        days: process.env.REACT_APP_ENV === 'production' ? 1 : 0,
      }),
      // Disable any dates beyond 31 days into the future
      after: add(new Date(), { days: maxDaysInFuture }),
    },
  ]

  const css = useMemo(
    () => `
    .rdp-cell {
      padding: 0.15rem;
    }
    
    .rdp-day:not([disabled]):not(.rdp-day_today):not(.rdp-day_selected) { 
      font-weight: bold; 
      background-color: ${alpha(theme.palette.brand.main, 0.115)};
    }
    
    .rdp-day:not([disabled]):not(.rdp-day_today):not(.rdp-day_selected):hover { 
      background-color: ${alpha(theme.palette.brand.main, 0.25)};
    }
    
    .rdp-day_selected {
      font-weight: bold; 
      background-color: ${theme.palette.brand.main};
    }
    
    .rdp-day_selected:hover {
      cursor: default;
      background-color: ${theme.palette.brand.main};
    }
    
    .rdp-day_today:not(.rdp-day_outside) {
      font-weight: normal; 
      text-decoration: underline;
    }
  `,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <>
      <style>{css}</style>
      <DayPicker
        locale={
          i18n.language === CONSTANTS.languageTypes.FR_CA.id ? frCA : enCA
        }
        // Can only select one date at a time
        mode="single"
        // Calendar starts from month of first available appointment
        fromMonth={
          new Date(
            theAvailableDates[
              Object.keys(theAvailableDates)[0]
            ].availableTimeslots[0].start,
          )
        }
        disabled={disabledDays}
        defaultMonth={selectedDate}
        // Prevent calendar from displaying dates beyond maxDaysInFuture days in the future
        toDate={add(new Date(), { days: maxDaysInFuture })}
        onSelect={(date) => {
          if (date) {
            const formattedDateValue = format(date, 'yyyy-MM-dd')
            onSelect({
              selected: date,
              availableTimeslots:
                theAvailableDates[formattedDateValue].availableTimeslots,
            })
          }
        }}
      />
    </>
  )
}

export default DatePicker
