// TAG: Will Power
import React, { useState } from 'react'

import { alpha, Box, Slider } from '@mui/material'

import { utils } from '@epilogue/common'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import NumberInput from '../../../../../../common/components/atoms/NumberInput'
import Text from '../../../../../../common/components/atoms/Text'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'

const formatCurrency = (value) =>
  value >= 0
    ? utils.toDollar(value.toString(), 0, i18n.language)
    : `(${utils.toDollar(Math.abs(value).toString(), 0, i18n.language)})`

export const calculateEstateValues = (
  assets = 0,
  liabilities = 0,
  percentToCharity = 0,
) => {
  const estateValue = assets - liabilities
  const charityValue =
    percentToCharity > 0 && estateValue > 0
      ? Math.floor(estateValue * (percentToCharity / 100))
      : 0
  const familyValue = estateValue >= 0 ? estateValue - charityValue : 0

  return {
    estateValue: formatCurrency(estateValue),
    charityValue: formatCurrency(charityValue, 2),
    familyValue: formatCurrency(familyValue),
  }
}

const WillPowerCalculator = () => {
  const [assets, setAssets] = useState()
  const [liabilities, setLiabilities] = useState()
  const [percentToCharity, setPercentToCharity] = useState(1)

  const { estateValue, charityValue, familyValue } = calculateEstateValues(
    assets,
    liabilities,
    percentToCharity,
  )

  const { t } = useTranslation()

  return (
    <Box
      data-testid="will-power-calculator"
      sx={{
        mt: '1rem',
        rowGap: '1.25rem',
        p: '1.25rem',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: (theme) => theme.shape.borderRadius,
        backgroundColor: (theme) =>
          alpha(
            theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.LIGHTER],
            0.3,
          ),
      }}
    >
      <Text size="lg" variant="display">
        {t('sidebar:components.WillPowerCalculator.label')}
      </Text>

      <NumberInput
        name="assets"
        placeholder={t(
          'sidebar:components.WillPowerCalculator.placeholder.estValueAssets',
        )}
        format="currency"
        value={assets}
        onInputChange={setAssets}
        data-testid="willpower-calculator-assets"
      />

      <NumberInput
        name="liabilities"
        placeholder={t(
          'sidebar:components.WillPowerCalculator.placeholder.estValueDebtsLiabilities',
        )}
        format="currency"
        value={liabilities}
        onInputChange={setLiabilities}
        data-testid="willpower-calculator-liabilities"
      />

      <Text size="sm">
        {t('sidebar:components.WillPowerCalculator.sliderLabel')}
      </Text>
      <Slider
        min={0}
        step={1}
        max={100}
        value={Number.isNaN(percentToCharity) ? 0 : percentToCharity}
        track={false}
        onChange={(_, newValue) => {
          setPercentToCharity(newValue)
        }}
        sx={{
          '& .MuiSlider-rail': {
            height: '0.5rem',
            backgroundColor: (theme) =>
              theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.LIGHT],
          },
          mt: '0.9rem',
        }}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => `${value}%`}
      />

      <Box
        sx={{
          pt: '1.5rem',
          borderTop: (theme) =>
            `solid 1px ${
              theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.MAIN]
            }`,
        }}
      >
        <Text size="md">
          {t('sidebar:components.WillPowerCalculator.valueLabel.estateValue')}{' '}
          <Text
            size="md"
            weight="bold"
            display="inline"
            elementType="span"
            data-testid="estimated-fees"
          >
            {estateValue}
          </Text>
        </Text>

        <Text size="md">
          {t('sidebar:components.WillPowerCalculator.valueLabel.donation')}{' '}
          <Text
            size="md"
            weight="bold"
            display="inline"
            elementType="span"
            data-testid="estimated-fees"
          >
            {charityValue}
          </Text>
        </Text>

        <Text size="md">
          {t(
            'sidebar:components.WillPowerCalculator.valueLabel.availableForLovedOnes',
          )}{' '}
          <Text
            size="md"
            weight="bold"
            display="inline"
            elementType="span"
            data-testid="estimated-fees"
          >
            {familyValue}
          </Text>
        </Text>
      </Box>

      <Text size="xs" sx={{ fontStyle: 'italic', my: '0rem' }}>
        {t('sidebar:components.WillPowerCalculator.disclaimer')}
      </Text>
    </Box>
  )
}

export default WillPowerCalculator
