import questionnaireName from './questionnaireName'

export default {
  BEFORE_QUESTIONNAIRE: `${questionnaireName}/BEFORE_QUESTIONNAIRE`,

  UPDATE_ANSWER_STORE: `${questionnaireName}/UPDATE_ANSWER_STORE`,

  SET_ANSWER_STORE_UPDATED_SINCE_GENERATE: `${questionnaireName}/SET_ANSWER_STORE_UPDATED_SINCE_GENERATE`,

  SET_QUESTIONNAIRE_PROPERTIES: `${questionnaireName}/SET_QUESTIONNAIRE_PROPERTIES`,

  START_CREATE_ACCOUNT: `${questionnaireName}/START_CREATE_ACCOUNT`,
  CREATE_ACCOUNT_SUCCESS: `${questionnaireName}/CREATE_ACCOUNT_SUCCESS`,
  OAUTH_SUCCESS: `${questionnaireName}/OAUTH_SUCCESS`,

  CREATE_QUESTIONNAIRE: `${questionnaireName}/CREATE_QUESTIONNAIRE`,
  UPDATE_QUESTIONNAIRE: `${questionnaireName}/UPDATE_QUESTIONNAIRE`,
  UPDATE_QUESTIONNAIRES_STARTED: `${questionnaireName}/UPDATE_QUESTIONNAIRES_STARTED`,

  SET_QUESTION_FRAGMENTS: `${questionnaireName}/SET_QUESTION_FRAGMENTS`,
  CLEAR_QUESTION_FRAGMENTS: `${questionnaireName}/CLEAR_QUESTION_FRAGMENTS`,

  SET_QUESTIONNAIRE_VALID: `${questionnaireName}/SET_QUESTIONNAIRE_VALID`,

  SET_NAME_STORE: `${questionnaireName}/SET_NAME_STORE`,
}
