import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import _pick from 'lodash/pick'

import styled from 'styled-components'

import LoadingSpinner from '../../common/components/atoms/LoadingSpinner'
import Footer from '../../common/components/molecules/Footer'
import Header from '../../common/components/molecules/Header'
import amplitude from '../../common/lib/amplitude'
import toPascalCase from '../../common/utils/toPascalCase'
import NativeNavHoc from './components/Hoc/NativeNavHoc'
import Question from './components/Question'
import navigationSelectors from './ducks/navigation/navigationSelectors'
import questionnaireActions from './ducks/questionnaire/questionnaireActions'
import questionnaireSelectors from './ducks/questionnaire/questionnaireSelectors'

const HiddenScrollableDiv = styled.div`
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`

const Questionnaire = ({
  currentQuestion = {},
  questionnaireId,
  dashboardRedirect,
  beforeQuestionnaire,
}) => {
  const [questionnaireCreated, setQuestionnaireCreated] =
    useState(!!questionnaireId)

  useEffect(() => {
    if (dashboardRedirect !== true) {
      beforeQuestionnaire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardRedirect])

  // every time question changes, scroll to top of window
  useEffect(() => {
    window.scrollTo(0, 0)

    // When user views a question, send an Amplitude Event
    amplitude.sendEvent(
      currentQuestion?.type === 'dropOff'
        ? `HitDropOff${toPascalCase(
            currentQuestion?.pathname || 'UnknownPathname',
          )}`
        : currentQuestion?.type === 'partnerDropOff'
          ? `HitSpouseDropOff${toPascalCase(
              currentQuestion?.pathname || 'UnknownPathname',
            )}`
          : `ViewedQuestion${toPascalCase(
              currentQuestion?.section || 'UnknownSection',
            )}${toPascalCase(currentQuestion?.pathname || 'UnknownPathname')}`,
      _pick(currentQuestion, ['id', 'pathname', 'section', 'dropOffType']),
    )
  }, [currentQuestion])

  useEffect(() => {
    if (questionnaireId) {
      setQuestionnaireCreated(true)
    }
  }, [questionnaireId])

  return (
    <div>
      {dashboardRedirect ? (
        <Navigate to="/login" replace />
      ) : (
        <>
          {currentQuestion && questionnaireCreated ? (
            <HiddenScrollableDiv className="flex flex-col items-center">
              <Header />

              <Question question={currentQuestion} />

              <div className="fixed bottom-0 w-full">
                <Footer />
              </div>
            </HiddenScrollableDiv>
          ) : (
            <div className="w-full flex justify-center mt-32">
              <LoadingSpinner />
            </div>
          )}
        </>
      )}
    </div>
  )
}

Questionnaire.propTypes = {
  questionnaireId: PropTypes.string,
  dashboardRedirect: PropTypes.bool,
  currentQuestion: PropTypes.shape(),
  beforeQuestionnaire: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  questionnaireId: questionnaireSelectors.getQuestionnaireId(
    state.questionnaire,
  ),
  dashboardRedirect: navigationSelectors.getDashboardRedirect(
    state.questionnaire,
  ),
  currentQuestion: navigationSelectors.getCurrentQuestion(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  beforeQuestionnaire: () =>
    dispatch(questionnaireActions.beforeQuestionnaire()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NativeNavHoc(Questionnaire))
