import { useSelector } from 'react-redux'

import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import paymentsSelectors from '../../../scenes/Dashboard/ducks/payments/paymentsSelectors'
import hasCoreProduct from '../../../scenes/Dashboard/utils/hasCoreProduct'
import questionnaireSelectors from '../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import {
  ForbiddenKeys,
  HoverDefinitionArray,
  Translation,
} from '../../../types/questionnaire.types'
import commonSelectors from '../../ducks/commonSelectors'
import isHoverDefinitionArray from './utils/isHoverDefinitionArray'
import isTranslateObj from './utils/isTranslateObj'
import translateHoverDefinitionArray from './utils/translateHoverDefinitionArray'

type Additional = { [Key in string]: any } & ForbiddenKeys

export default (additionalValuesObj: Additional = {}) => {
  const { t } = useTranslation()

  const injectObj: Record<string, any> = useSelector((state: any) => ({
    charityPartner: commonSelectors.getCharityPartner(state),
    referrer: questionnaireSelectors.getReferrer(state.questionnaire),
    languageType: _get(i18n, 'language', CONSTANTS.languageTypes.EN_CA.id),
    answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
    accountCreated: questionnaireSelectors.getAccountCreated(
      state.questionnaire,
    ),
    meta: {
      hasCoreProductPayment: hasCoreProduct(
        paymentsSelectors.getPayments(state.dashboard),
      ),
    },
    ...additionalValuesObj,
  }))

  return {
    injectObj,

    resolveValue: (
      incoming:
        | string
        | Translation
        | HoverDefinitionArray
        | ((
            dynamicValuesObj: Record<string, any>,
          ) => string | Translation | HoverDefinitionArray),
    ) => {
      // If the incoming value is a translation object, translate it
      if (isTranslateObj(incoming)) {
        return t(
          (incoming as Translation).tKey,
          (incoming as Translation)?.options,
        )
      }

      if (isHoverDefinitionArray(incoming)) {
        return translateHoverDefinitionArray(
          incoming as HoverDefinitionArray,
          t,
        )
      }

      if (typeof incoming === 'function') {
        const result = incoming(injectObj)

        if (isHoverDefinitionArray(result)) {
          return translateHoverDefinitionArray(
            result as HoverDefinitionArray,
            t,
          )
        }

        // If the return value of the function is a translation object, translate it
        if (isTranslateObj(result)) {
          return t(
            (result as Translation).tKey,
            (result as Translation)?.options,
          )
        }

        // Otherwise, return the result
        return result
      }

      // Otherwise, return the incoming value
      return incoming
    },
  }
}
