import _isEmpty from 'lodash/isEmpty'

import { createLogic } from 'redux-logic'

import commonActions from '../../../../../common/ducks/commonActions'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import addOnsActions from '../addOnsActions'
import addOnsSelectors from '../addOnsSelectors'
import addOnsTypes from '../addOnsTypes'

export default createLogic({
  name: 'fetchAddOns',
  type: addOnsTypes.FETCH_ADD_ONS,
  async process({ getState, action }, dispatch, done) {
    const { clearAddOnCart } = action.payload
    const addOnCart = addOnsSelectors.getAddOnCart(getState().dashboard)

    dispatch(addOnsActions.fetchAddOnsWorking(true))

    try {
      const res = await request({ method: 'GET', url: PATHS.ADD_ON_ADD_ONS })

      const { addOns } = res.data.payload

      dispatch(addOnsActions.setAddOns(addOns))

      if (clearAddOnCart && !_isEmpty(addOnCart)) {
        dispatch(addOnsActions.clearAddOnCart())
      }
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to retrieve paid add-ons. Please refresh your browser and try again',
        onError: (resolvedErrorMessage) =>
          dispatch(commonActions.setGlobalErrorMessage(resolvedErrorMessage)),
      })
    }

    dispatch(addOnsActions.fetchAddOnsWorking(false))

    done()
  },
})
