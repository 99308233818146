/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

import ReactMarkdown from 'markdown-to-jsx'

import styleTypes, { MarkdownStyleTypes } from './styleTypes'

interface Props {
  children: string
  styleType?: MarkdownStyleTypes
}

const Markdown = ({
  children,
  styleType = MarkdownStyleTypes.DEFAULT,
}: Props) => (
  <ReactMarkdown options={{ overrides: styleTypes[styleType] }}>
    {children}
  </ReactMarkdown>
)

export default Markdown
