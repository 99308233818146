import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import dashboardSelectors from '../../../../../scenes/Dashboard/ducks/dashboard/dashboardSelectors'
import Button from '../../../atoms/Button'
import Text from '../../../atoms/Text'
import ChangeEmailAddressModal from './components/ChangeEmailAddressModal'

const ChangeEmailAddress = ({ emailAddress }) => {
  const [showModal, setShowModal] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Text
          size="xl"
          align="center"
          variant="paragraph"
          sx={{ mt: '1.3rem', mb: '2.4rem' }}
        >
          {emailAddress}
        </Text>

        <Button
          variant="contained"
          label={t('pages:AccountSettings.changeEmailAddress.button')}
          onClick={() => setShowModal(true)}
          data-testid="change-email-address-button"
        />
      </Box>

      <ChangeEmailAddressModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

ChangeEmailAddress.propTypes = { emailAddress: PropTypes.string.isRequired }

const mapStateToProps = (state) => ({
  emailAddress: dashboardSelectors.getEmail(state.dashboard),
})

export default connect(mapStateToProps)(ChangeEmailAddress)
