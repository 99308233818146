import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Que se passe-t-il avec un CELI en cas de décès ?

Le propriétaire d'un Compte d'épargne libre d'impôt (CELI) désigne généralement un "titulaire successeur" ou des "bénéficiaires désignés" de leur CELI au moment de l'ouverture du compte. Lorsque la personne décède, le compte est distribué directement au titulaire successeur ou aux bénéficiaires désignés. C'est pourquoi votre CELI n'est pas mentionné dans votre testament.

S'il n'y a pas de titulaire successeur ou de bénéficiaires désignés (ou s'ils sont tous décédés avant le propriétaire du CELI), alors le CELI est versé à la succession du propriétaire et est distribué selon les termes de leur testament, avec les autres actifs de la personne.

#### Qui peut être un titulaire successeur ?

Seul un conjoint ou un partenaire de fait survivant peut être un titulaire successeur d'un CELI. Si quelqu'un décède et a désigné son conjoint/partenaire comme titulaire successeur de leur CELI, ce conjoint/partenaire devient le nouveau propriétaire de ce CELI.

Les investissements du CELI peuvent continuer à croître à l'abri de l'impôt dans ce compte, ou peuvent être combinés avec le propre CELI du conjoint/partenaire. Il n'y a aucun impact sur les impôts ou la marge de cotisation au CELI du conjoint/partenaire.

#### Qui peut être un bénéficiaire désigné ?

N'importe qui peut être un bénéficiaire désigné d'un CELI. S'il n'y a pas de titulaire successeur du CELI, les produits du compte d'un individu décédé sont répartis entre les bénéficiaires désignés.

Les taxes n'ont pas à être payées sur les produits du CELI. Mais, contrairement à un titulaire successeur, les bénéficiaires désignés ne peuvent pas ajouter ces fonds à leurs propres CELI à moins d'avoir suffisamment de marge de cotisation au CELI.

#### Quelles démarches puis-je entreprendre ?

Si vous n'êtes pas sûr de qui vous avez désigné comme titulaire successeur ou bénéficiaires désignés sur votre CELI, vous pouvez contacter votre institution financière pour vous renseigner.
    `
    : `
#### What Happens to a TFSA on Death?

The owner of a Tax-Free Savings Account (TFSA) usually names a "successor holder" or "designated beneficiaries" of their TFSA at the time when the account is opened. When the person dies, the account is distributed directly to the successor holder or designated beneficiaries. That is why your TFSA is not referenced in your Will.

If there is no successor holder or designated beneficiaries (or if they all died before the TFSA owner), then the TFSA is paid to the owner's estate and is distributed according to the terms of their Will, along with the person's other assets.

#### Who Can be a Successor Holder?

Only a surviving spouse or common-law partner can be a successor holder of a TFSA. If someone dies and has named their spouse/partner as successor holder of their TFSA, that spouse/partner becomes the new owner of that TFSA.

The TFSA's investments can continue to grow tax-free in that account, or can be combined with the spouse/partner's own TFSA. There is no impact on the spouse/partner's taxes or TFSA contribution room.

#### Who Can be a Designated Beneficiary?

Anyone can be a designated beneficiary of a TFSA. If there is no successor holder of the TFSA, the proceeds from the account of a deceased individual are distributed among the designated beneficiaries.

Taxes do not need to be paid on the proceeds from the TFSA. But, unlike a successor holder, designated beneficiaries cannot add these funds to their own TFSAs unless they have sufficient TFSA contribution room.

#### What Steps Can I Take?

If you are unsure who you have named as successor holder or designated beneficiaries on your TFSA, you can contact your financial institution to inquire.
`
