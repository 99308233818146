import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, Checkbox as MaterialCheckbox } from '@mui/material'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
  themeTextSizes,
} from '../../styles/muiTheme'
import Text from './Text'

const Checkbox = ({
  checked,
  disabled = false,
  onChange,
  size = 'medium',
  label,
  isInvalid = false,
  labelSize = 'md',
  labelAlign = 'left',
  'data-testid': dataTestId,
}) => (
  <FormControlLabel
    label={
      <Text
        size={labelSize}
        align={labelAlign}
        color={isInvalid ? themeColorTypes.RED : undefined}
      >
        {label}
      </Text>
    }
    control={
      <MaterialCheckbox
        size={size}
        disableRipple
        color="primary"
        sx={{
          color: isInvalid
            ? themeColors[themeColorTypes.RED][themeColorVariants.MAIN]
            : undefined,
        }}
        checked={checked}
        inputProps={{ 'data-testid': dataTestId }}
        onChange={(event) => {
          if (!disabled) {
            onChange(event.target.checked)
          }
        }}
      />
    }
  />
)

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  'data-testid': PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  labelSize: PropTypes.oneOf(Object.keys(themeTextSizes)),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  labelAlign: PropTypes.oneOf([
    'inherit',
    'left',
    'center',
    'right',
    'justify',
  ]),
}

export default Checkbox
