import React from 'react'
import PropTypes from 'prop-types'

import { Box, useMediaQuery } from '@mui/material'

import Text from '../../../../../common/components/atoms/Text'

const HelpWithThisQuestionSublabel = ({ children }) => {
  // This breakpoint is used to check if the sidebar is in mobile/tablet or
  // desktop view for animations and autoOpenSidebar. Make sure this value
  // is updated in all: Drawer.js, Question.js, and HelpWithThisQuestionSublabel.js
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('3xl'))

  return (
    <>
      {!isDesktop && (
        <Box
          sx={{
            mb: '2rem',
          }}
          data-testid="help-with-this-question-sublabel"
        >
          <Text
            variant="paragraph"
            align="center"
            sx={{
              lineHeight: '1.5',
              fontSize: '1.07rem',
              mx: 'auto',
              maxWidth: '35rem',
            }}
          >
            {children}
          </Text>
        </Box>
      )}
    </>
  )
}

HelpWithThisQuestionSublabel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HelpWithThisQuestionSublabel
