import React, { useState } from 'react'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as WarningIcon } from '../../../../../../../../common/assets/images/warning-icon.svg'
import Button from '../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'
import CancelAppointmentButton from '../../buttons/CancelAppointmentButton'
import CancelWitnessingSuccess from '../PendingWitnessingAppointmentModal/components/CancelWitnessingSuccess'

interface Props {
  show: boolean
  onClose: () => void
}

const WitnessingPreventGenerateModal = ({ show, onClose }: Props) => {
  const [cancelAppointmentSuccess, setCancelAppointmentSuccess] =
    useState(false)

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      maxWidth="sm"
      onClose={onClose}
      transitionDuration={{ enter: 200, exit: 0 }}
    >
      <>
        {cancelAppointmentSuccess ? (
          <CancelWitnessingSuccess />
        ) : (
          <Box
            sx={{
              p: '1rem 0',
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            data-testid="witnessing-prevent-generate-view"
          >
            <WarningIcon />

            <Box sx={{ pt: '1.4rem' }}>
              <Heading sx={{ lineHeight: '1.24' }} variant="h4">
                {t('components:modal.WitnessingPreventGenerateModal.label')}
              </Heading>

              <Box sx={{ pt: '0.9rem' }}>
                <Text
                  sx={{
                    '& a': {
                      color:
                        themeColors[themeColorTypes.ACCENT_1][
                          themeColorVariants.MAIN
                        ],
                      '&:hover': { textDecoration: 'underline' },
                    },
                  }}
                  size="md"
                >
                  {t(
                    'components:modal.WitnessingPreventGenerateModal.subLabel',
                  )}
                </Text>
              </Box>

              <Box sx={{ pt: '2.5rem', pb: '0.5rem' }}>
                <CancelAppointmentButton
                  onSuccess={() => setCancelAppointmentSuccess(true)}
                />
              </Box>

              <Button
                size="xs"
                onClick={onClose}
                label={t('common:close')}
                color={themeColorTypes.GREY}
              />
            </Box>
          </Box>
        )}
      </>
    </Modal>
  )
}

export default WitnessingPreventGenerateModal
