import store from 'store2'

export default {
  get(key) {
    return store.get(key)
  },

  set(key, value) {
    return store.set(key, value)
  },

  remove(key) {
    if (typeof key === 'string') {
      return store.remove(key)
    }
    return key.map((value) => this.remove(value))
  },

  clear() {
    return store.clear()
  },
}
