import React, { ReactNode } from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Tooltip } from '@mui/material'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'

interface Props {
  copy: ReactNode
  sx?: any
}

const InfoTooltip = ({ copy, sx = {} }: Props) => (
  <Tooltip
    enterTouchDelay={0}
    title={copy}
    placement="top"
    sx={sx}
    componentsProps={{
      tooltip: { sx: { backgroundColor: 'rgba(70, 70, 70, 0.98)' } },
    }}
    PopperProps={{
      modifiers: [{ name: 'offset', options: { offset: [0, -10] } }],
    }}
  >
    <IconButton>
      <InfoOutlinedIcon
        sx={{
          fontSize: '1.4rem',
          fill: themeColors[themeColorTypes.GREY][themeColorVariants.DARK],
        }}
      />
    </IconButton>
  </Tooltip>
)

export default InfoTooltip
