import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import dashboardSelectors from '../../../../scenes/Dashboard/ducks/dashboard/dashboardSelectors'
import questionnaireSelectors from '../../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { themeColorTypes } from '../../../styles/muiTheme'
import featureFlag, { flagTypes } from '../../../utils/featureFlag'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Text from '../../atoms/Text'
import TextInput from '../../atoms/TextInput'

const CatastrophicError = ({ questionnaire = {}, dashboard = {} }) => {
  const [working, setWorking] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState()
  const [nameVal, setNameVal] = useState('')
  const [contactVal, setContactVal] = useState('')

  useEffect(() => {
    if (featureFlag(flagTypes.SENTRY)) {
      window.captureException(new Error('Catastrophic Error!'), {
        message: `Questionnaire ID: ${questionnaire.questionnaireId} encountered a catastrophic error.`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setWorking(true)

    const dataObj = fetch('https://formcarry.com/s/ohdQnRwOFPW', {
      method: 'POST',
      body: JSON.stringify({
        name: nameVal,
        contact: contactVal,
        dashboard: JSON.stringify(dashboard),
        questionnaire: JSON.stringify(questionnaire),
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status >= 400 && res.status < 600) {
          throw new Error('Unable to submit form.')
        } else {
          setSubmitSuccess(true)
        }
      })
      .catch((error) => {
        setSubmitSuccess(false)
        if (featureFlag(flagTypes.SENTRY)) {
          window.captureException(error, dataObj)
        }
      })
      .finally(() => setWorking(false))
  }

  const { t } = useTranslation()

  return (
    <Box sx={{ maxWidth: '525px', mx: 'auto', p: '1.5rem', mt: '3rem' }}>
      <Heading sx={{ mb: '0.7rem' }} variant="h2">
        {t('components:CatastrophicError.heading')}
      </Heading>
      <Text size="lg">{t('components:CatastrophicError.label')}</Text>
      <Box sx={{ my: '1rem' }}>
        <Text size="lg">{t('components:CatastrophicError.label2')}</Text>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box>
          <TextInput
            value={nameVal}
            name="name"
            placeholder={t('components:CatastrophicError.textInput.input1')}
            onInputChange={(val) => {
              setNameVal(val)
            }}
          />
        </Box>

        <Box sx={{ mt: '0.75rem', mb: '1.5rem' }}>
          <TextInput
            value={contactVal}
            name="contact"
            placeholder={t('components:CatastrophicError.textInput.input2')}
            onInputChange={(val) => {
              setContactVal(val)
            }}
          />
        </Box>

        <>
          {!submitSuccess ? (
            <Button
              working={working}
              variant="contained"
              label="Submit"
              type="submit"
            />
          ) : (
            <Text color={themeColorTypes.GREEN}>
              {t('components:CatastrophicError.submit.success')}
            </Text>
          )}
        </>

        {submitSuccess === false && (
          <Text sx={{ mt: '1.5rem' }} color={themeColorTypes.RED}>
            {t('components:CatastrophicError.submit.fail')}
          </Text>
        )}
      </form>
    </Box>
  )
}

CatastrophicError.propTypes = {
  questionnaire: PropTypes.shape({ questionnaireId: PropTypes.string }),
  dashboard: PropTypes.shape({}),
}

const mapStateToProps = (state) => ({
  questionnaire: questionnaireSelectors.getQuestionnaire(state.questionnaire),
  dashboard: dashboardSelectors.getDashboard(state.dashboard),
})

export default connect(mapStateToProps)(CatastrophicError)
