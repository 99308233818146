import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import cn from 'classnames'
import { Text } from 'grommet'
import styled from 'styled-components'

import validationSelectors from '../../../../scenes/Questionnaire/ducks/validation/validationSelectors'

const StyledValidation = styled.div`
  bottom: -2rem;

  &.show {
    animation: slideUp 0.5s forwards;
  }

  @keyframes slideUp {
    to {
      bottom: 0;
    }
  }
`

const Footer = ({ displayValidation, invalidFields = undefined }) => {
  const displayValidationWarning = displayValidation && !_isEmpty(invalidFields)

  return (
    <div data-testid="footer-wrapper" className="flex justify-center">
      {displayValidationWarning && (
        <StyledValidation
          className={cn(
            { show: displayValidationWarning },
            'rounded-t w-full max-w-lg mx-auto font-bold text-white bg-status-critical absolute flex justify-center items-center h-10 sm:h-8',
          )}
        >
          <Text data-testid="footer-error-bar" size="small">
            There is incomplete/missing information above
          </Text>
        </StyledValidation>
      )}
    </div>
  )
}

Footer.propTypes = {
  displayValidation: PropTypes.bool.isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = (state) => ({
  displayValidation: validationSelectors.getDisplayValidation(
    state.questionnaire,
  ),
  invalidFields: validationSelectors.getInvalidFields(state.questionnaire),
})

export default connect(mapStateToProps)(Footer)
