import addOnsTypes from './addOnsTypes'

const initialState = {
  paidAddOns: [],
  addOnCart: [],
  fetchAddOnsWorking: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case addOnsTypes.FETCH_ADD_ONS_WORKING:
      return { ...state, fetchAddOnsWorking: action.payload.working }

    case addOnsTypes.SET_ADD_ONS:
      return { ...state, paidAddOns: action.payload.addOnsArr }

    case addOnsTypes.ADD_ADD_ON_TO_CART:
      return {
        ...state,
        // Overwrite existing add-on with incoming add-on if it's already in the cart
        addOnCart: [
          ...state.addOnCart.filter(({ type }) => type !== action.payload.type),
          { ...action.payload },
        ],
      }

    case addOnsTypes.SET_ADD_ONS_TO_CART:
      return { ...state, addOnCart: action.payload.addOnsArr }

    case addOnsTypes.REMOVE_ADD_ON_FROM_CART:
      return {
        ...state,
        addOnCart: state.addOnCart.filter(
          (a) => a.type !== action.payload.type,
        ),
      }

    case addOnsTypes.CLEAR_ADD_ON_CART:
      return {
        ...state,
        addOnCart: [],
      }

    default:
      return state
  }
}
