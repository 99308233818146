import { useState } from 'react'

import { CONSTANTS } from '@epilogue/common'
import { useQueryClient } from '@tanstack/react-query'
import download from 'downloadjs'

import useProvinceDictionary from '../../../../../../common/hooks/useProvinceDictionary'
import amplitude from '../../../../../../common/lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../../../common/request'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../common/utils/queryKeys'
import buildFilename from '../utils/buildFilename'

export enum DownloadStatus {
  IDLE = 'idle',
  ERROR = 'error',
  WORKING = 'working',
  SUCCESS = 'success',
}

interface Props {
  docType: string
  answerStore: any
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

export default ({
  docType,
  dispatchSetGlobalErrorMessage,
  answerStore: { firstName, lastName, province },
}: Props) => {
  const queryClient = useQueryClient()

  const [status, setStatus] = useState(DownloadStatus.IDLE)

  const { provinceDictionary } = useProvinceDictionary()

  const requestDocDownload = async () => {
    setStatus(DownloadStatus.WORKING)

    try {
      const res = await request({
        method: 'GET',
        responseType: 'arraybuffer',
        params: {
          type: CONSTANTS.documentDownloadEventTypes.STATIC_INDIVIDUAL,
        },
        url: `${PATHS.DOCUMENT_DOWNLOAD_STATIC_DOC}/${docType}`,
      })

      download(
        res.data,
        buildFilename({
          firstName,
          lastName,
          docType: provinceDictionary(docType, province),
        }),
      )
      // eslint-disable-next-line no-console
      console.log(`${docType} download success ✅`)
      setStatus(DownloadStatus.SUCCESS)
      amplitude.sendEvent('DownloadedDocument', { docType })
      /*
       * Invalidating the staticDocumentDownloadEvents query causes
       * a refetch of the query, which will update the document-card
       * status of any newly downloaded static documents.
       */
      queryClient.invalidateQueries(
        queryKeys[queryKeysBase.staticDocumentDownloadEvents](),
      )
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to download document. Please refresh and try again.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
      setStatus(DownloadStatus.ERROR)
    }
  }

  return { requestDocDownload, status, setStatus, DownloadStatus }
}
