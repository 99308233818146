// returns version of answerStore containing only valid fields from triggered questions

import _pickBy from 'lodash/pickBy'

import { fieldValidation } from '../utils/validation/validation'
import clearFieldsAfterProvinceChange from './clearFieldsAfterProvinceChange'
import triggeredFields from './triggeredFields'

export default ({
  data,
  abTest,
  referrer,
  documents,
  charityPartner,
  prevAnswerStore,
  currentAnswerStore,
}) => {
  const theTriggeredFields = triggeredFields(
    /*
     * WARNING: This might be slightly confusing, but it works well for now.
     *
     * The reason we're hard-coding the values for payments, isPartner, and
     * accountCreated is because this function, cleansedAnswerStore, uses
     * the triggeredFields function, which uses the triggeredQuestions function.
     * The triggeredQuestions function is used in other functions too, but when
     * we're using it to determine the cleansedAnswerStore, we do not want to
     * cleanse fields with questions that use the flags hideFromPartner,
     * hideAfterAccount, or hideAfterPayment. These flags are used for determining
     * whether or not to display a question to the user, as opposed to, whether
     * or not to cleanse it from the answerStore. To prevent those three flags
     * from affecting the cleansedAnswerStore, we hard-code falsy values.
     */
    {
      abTest,
      referrer,
      payments: [], // do not change this hard-coded falsy value
      theData: data,
      charityPartner,
      isPartner: false, // do not change this hard-coded falsy value
      documents,
      accountCreated: false, // do not change this hard-coded falsy value
      answerStore: currentAnswerStore,
    },
  )

  const nextAnswerStore = clearFieldsAfterProvinceChange({
    prevAnswerStore,
    currentAnswerStore,
    fields: theTriggeredFields,
  })

  return _pickBy(nextAnswerStore, (fieldValue, key) => {
    const field = theTriggeredFields.find((f) => f.name === key)
    return field ? !!fieldValidation(field, fieldValue) : false
  })
}
