import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'

import argumentRequired from '../../../common/utils/argumentRequired'
import { provinceDictionary } from '../../../common/utils/provinceDictionary'
import triggeredFields from './triggeredFields'

export default ({
  data,
  abTest,
  referrer,
  isPartner,
  documents,
  answerStore,
  charityPartner,
  t = argumentRequired('t'),
}) => {
  const fields = triggeredFields({
    abTest,
    referrer,
    isPartner,
    documents,
    answerStore,
    theData: data,
    charityPartner,
  })

  return fields.reduce((acc, { name, appointeeType }) => {
    /*
     * When the appointeeType.isPartner is true, it means that the
     * answerStore value for that field must be 'yes' to proceed. A value
     * of 'no' or anything other than 'yes' means we do NOT want to
     * include that field in the appointees array.
     */
    if (
      appointeeType &&
      answerStore[name] &&
      answerStore.province &&
      (!appointeeType.isPartner ||
        (appointeeType.isPartner &&
          answerStore[name] === 'yes' &&
          answerStore.partnerFirstName &&
          answerStore.partnerLastName))
    ) {
      const { label, isPartner: appointeeIsPartner } = appointeeType

      const appointeeValue = appointeeIsPartner
        ? `${answerStore.partnerFirstName} ${answerStore.partnerLastName}`
        : answerStore[name]

      let appointeeRelationship

      if (appointeeIsPartner) {
        appointeeRelationship = CONSTANTS.relationshipValues.MY_PARTNER
      } else if (name.endsWith('Name')) {
        /*
         * This feels slightly hacky here, but there is a test in question.test.js
         * that ensures that every non-partner appointee question has a field that
         * captures an appointee's name (with the field.name ending in 'Name') and a
         * field that captures an appointee's relationship (with the field.name ending
         * in 'Relationship'). So, if we're dealing with a non-partner appointee, we can
         * be sure that we'll have a corresponding relationship for each appointee name.
         */
        const [appointeeFieldNameWithoutNameEnding] = name.split('Name')

        appointeeRelationship =
          answerStore[`${appointeeFieldNameWithoutNameEnding}Relationship`]
      }

      const labelTKey = t(_get(label, 'tKey', label))
      const category = provinceDictionary(
        _get(label, 'options.category'),
        answerStore.province,
      )

      const hydratedAppointee = {
        category,
        value: appointeeValue,
        relationship: appointeeRelationship,
        label: t(labelTKey, { category }),
      }

      return [...acc, hydratedAppointee]
    }
    return [...acc]
  }, [])
}
