import amplitude from '../../../../lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../request'

export default async (dispatchSetGlobalErrorMessage) => {
  try {
    await request({ method: 'DELETE', url: PATHS.USER_LOGOUT })

    if (window.Intercom) {
      window.Intercom('shutdown')
    }

    amplitude.sendEvent('LoggedOut')
    amplitude.setUserId(null) // Unset email as Amplitude userId

    window.location.href = '/login'
  } catch (error) {
    errorHandler({
      error,
      reportError: true,
      fallbackErrorMessage:
        'Unable to log out. Please refresh your browser and try again.',
      onError: (resolvedErrorMessage) =>
        dispatchSetGlobalErrorMessage(resolvedErrorMessage),
    })
  }
}
