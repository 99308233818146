import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import LockIcon from '@mui/icons-material/Lock'
import { Box, Grid } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../common/components/atoms/Button'
import StyledLabel from '../../../../../common/components/atoms/StyledLabel'
import Text from '../../../../../common/components/atoms/Text'
import TextInput from '../../../../../common/components/atoms/TextInput'
import OauthProviderButton from '../../../../../common/components/molecules/OauthProviderButton'
import commonActions from '../../../../../common/ducks/commonActions'
import useKeyPress from '../../../../../common/hooks/useKeyPress'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import navigationActions from '../../../ducks/navigation/navigationActions'
import navigationSelectors from '../../../ducks/navigation/navigationSelectors'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import { validators } from '../../../utils/validation/validation'
import AgreeModal from './components/AgreeModal'
import agreeModalTypes from './components/AgreeModal/utils/agreeModalTypes'
import GoogleReview from './components/GoogleReview'

const CreateAccountForm = ({
  label,
  fields,
  working,
  context,
  subLabel,
  onSuccess,
  accountCreated,
  questionnaireId,
  dispatchUpdateToast,
  dispatchNextQuestion,
  dispatchPreviousQuestion,
  handleFieldFragmentUpdate,
}) => {
  const [emailField, passwordField] = fields
  const { current: initialAccountCreated } = useRef(accountCreated)

  const enterKeyPressed = useKeyPress('Enter')

  const [emailInput, setEmailInput] = useState()
  const [passwordInput, setPasswordInput] = useState()
  const [activeModalType, setActiveModalType] = useState()
  const [displayValidation, setDisplayValidation] = useState(false)
  const [mailingListChecked, setMailingListChecked] = useState(false)

  const oauthTypeCreateAccount = CONSTANTS.oauth.types.CREATE_ACCOUNT

  const isFormInvalid =
    !validators.email(emailInput) || !validators.password(passwordInput)

  const handleCreateAccount = (e) => {
    e?.preventDefault()

    if (isFormInvalid) {
      setDisplayValidation(true)
    } else {
      setActiveModalType(agreeModalTypes.USERNAME_PASSWORD)
    }
  }

  useEffect(() => {
    /*
     * Allows users, who are filling in the create account form, as part
     * of the questionnaire flow, to press the 'Enter' key to submit the form.
     *
     * In the 'flow' context, the CreateAccountForm component becomes a <div>
     * as opposed to a <form> to avoid nested <form> tags. Only <form> tags
     * automatically capture the 'Enter' key press event.
     */
    if (
      context === 'flow' &&
      !activeModalType &&
      enterKeyPressed &&
      !accountCreated
    ) {
      handleCreateAccount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterKeyPressed])

  useEffect(() => {
    /*
     * initialAccountCreated holds the value of accountCreated when the
     * component mounted. We only want to run the code in the block below
     * if the accountCreated value changes from false to true while the
     * component is mounted (AKA: the user created an account)
     */
    if (!initialAccountCreated && accountCreated) {
      onSuccess?.()

      if (context === 'modal') {
        setTimeout(() => {
          dispatchUpdateToast({
            type: CONSTANTS.toastTypes.SUCCESS,
            label: 'Success!',
            message: 'Your account has been created.',
          })
        }, 700)
      }

      if (context === 'flow') {
        dispatchNextQuestion()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountCreated])

  const { t } = useTranslation()

  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
        onSubmit={handleCreateAccount}
        py={context === 'modal' ? '2rem' : '0.6rem'}
        /*
         * When the CreateAccountForm is nested in the flow it would
         * be nested inside of the main Questionnaire form. To prevent this,
         * we need to make the parent element of this component a div.
         *
         * If the CreateAccountForm is not nested in the flow, we can set the
         * parent element of this component as a form element.
         */
        component={context === 'flow' ? 'div' : 'form'}
      >
        <Box
          pb="1.2rem"
          width="100%"
          display="flex"
          maxWidth="494px"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Box sx={{ textAlign: 'center', pb: '1.85rem' }}>
              <StyledLabel>{label}</StyledLabel>

              {subLabel && (
                <Box pt="0.5rem">
                  <Text size="sm" variant="paragraph">
                    {subLabel}
                  </Text>
                </Box>
              )}
            </Box>

            {!accountCreated && (
              <>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item>
                    <OauthProviderButton
                      type={oauthTypeCreateAccount}
                      provider={CONSTANTS.oauth.providers.GOOGLE}
                      onClick={() =>
                        setActiveModalType(
                          agreeModalTypes[CONSTANTS.oauth.providers.GOOGLE],
                        )
                      }
                    />
                  </Grid>
                </Grid>

                <Box
                  pt="2.2rem"
                  pb="1.1rem"
                  display="flex"
                  justifyContent="center"
                >
                  <Text
                    sx={{
                      position: 'relative',
                      width: '202px',

                      '&::before': {
                        content: "''",
                        display: 'block',
                        backgroundColor:
                          themeColors[themeColorTypes.GREY][
                            themeColorVariants.LIGHT
                          ],
                        position: 'absolute',
                        height: '1px',
                        width: '78px',
                        top: '50%',
                        left: 0,
                      },

                      '&::after': {
                        content: "''",
                        display: 'block',
                        backgroundColor:
                          themeColors[themeColorTypes.GREY][
                            themeColorVariants.LIGHT
                          ],
                        position: 'absolute',
                        height: '1px',
                        width: '78px',
                        top: '50%',
                        right: 0,
                      },
                    }}
                    size="xs"
                    align="center"
                    display="initial"
                  >
                    {t('common:or')}
                  </Text>
                </Box>
              </>
            )}
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              maxWidth: '21.5rem',
              flexDirection: 'column',
            }}
          >
            <TextInput
              value={emailInput}
              type={emailField.type}
              disabled={accountCreated}
              placeholder={t(
                _get(emailField, 'placeholder.tKey', emailField.placeholder),
              )}
              validationMessage={t('validation:validation.email')}
              onInputChange={(val) => {
                setEmailInput(val)
                handleFieldFragmentUpdate({ [emailField.name]: val })
              }}
              isInvalid={displayValidation && !validators.email(emailInput)}
            />

            <TextInput
              passwordVisibility
              value={passwordInput}
              type={passwordField.type}
              disabled={accountCreated}
              placeholder={t(
                _get(
                  passwordField,
                  'placeholder.tKey',
                  passwordField.placeholder,
                ),
              )}
              helperText={t('validation:helpers.password')}
              isInvalid={
                displayValidation && !validators.password(passwordInput)
              }
              onInputChange={(val) => {
                setPasswordInput(val)
                handleFieldFragmentUpdate({
                  [passwordField.name]: '*'.repeat(val.length),
                })
              }}
              validationMessage={t('validation:validation.password')}
            />
          </Box>
        </Box>

        {(context === 'flow' || !accountCreated) && (
          <Button
            sx={{
              width: '200px',
              padding: '0.52rem 2.4rem',
              cursor:
                !accountCreated && isFormInvalid ? 'not-allowed' : 'pointer',

              '&:hover': {
                backgroundColor:
                  isFormInvalid &&
                  themeColors[themeColorTypes.GREY][themeColorVariants.MAIN],
              },
            }}
            size="lg"
            variant="contained"
            iconFontSize="1.15rem"
            data-testid="create-account-form-button"
            endIcon={!working && <ArrowForwardIcon />}
            type={context === 'flow' ? 'button' : 'submit'}
            label={t(
              accountCreated ? 'common:saveAndContinue' : 'common:continue',
            )}
            onClick={(event) => {
              if (context === 'flow' && accountCreated) {
                dispatchNextQuestion()
              } else if (context === 'flow' && !accountCreated) {
                handleCreateAccount(event)
              }
            }}
            color={
              !accountCreated && isFormInvalid
                ? themeColorTypes.GREY
                : themeColorTypes.BRAND
            }
            working={working}
            spinnerSize="1.2rem"
            spinnerPadding="0.3301rem"
            spinnerColor={themeColorTypes.WHITE}
          />
        )}

        {/*
         * The 'back' button is only necessary when the Create Account
         * form is used in the flow and not the modal.
         */}
        {context === 'flow' && (
          <Box sx={{ mt: '0.9rem' }}>
            <Button
              size="xxs"
              label={t('common:back')}
              startIcon={<ArrowBackIcon />}
              onClick={dispatchPreviousQuestion}
              data-testid="create-account-form-back-button"
            />
          </Box>
        )}

        <Box sx={{ mt: '2.7rem' }}>
          <GoogleReview />
        </Box>

        <Box
          sx={{
            maxWidth: '21rem',
            display: 'flex',
            flexDirection: 'column',
            mt: '2.7rem',
            rowGap: '0.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '1.8rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <LockIcon
                sx={{
                  color: (theme) =>
                    theme.palette[themeColorTypes.GREY][
                      themeColorVariants.MAIN
                    ],
                }}
              />
              <Text
                sx={{
                  mb: '0.2rem',
                  ml: '0.15rem',
                  alignSelf: 'flex-end',
                }}
                size="xxxs"
                variant="display"
                color={themeColorTypes.GREY}
              >
                {t('molecules:CreateAccountForm.security.icons.item1')}
              </Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <GppGoodOutlinedIcon
                sx={{
                  color: (theme) =>
                    theme.palette[themeColorTypes.GREY][
                      themeColorVariants.MAIN
                    ],
                }}
              />
              <Text
                sx={{
                  mb: '0.2rem',
                  ml: '0.15rem',
                  alignSelf: 'flex-end',
                }}
                size="xxxs"
                variant="display"
                color={themeColorTypes.GREY}
              >
                {t('molecules:CreateAccountForm.security.icons.item2')}
              </Text>
            </Box>
          </Box>
          <Text size="xxxs" align="center" color={themeColorTypes.GREY}>
            {t('molecules:CreateAccountForm.security.body')}
          </Text>
        </Box>
      </Box>

      <AgreeModal
        email={emailInput}
        type={activeModalType}
        password={passwordInput}
        checked={mailingListChecked}
        questionnaireId={questionnaireId}
        onMailingListCheckUpdate={setMailingListChecked}
        onClose={useCallback(() => setActiveModalType(), [])}
      />
    </>
  )
}

CreateAccountForm.propTypes = {
  onSuccess: PropTypes.func,
  subLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
  working: PropTypes.bool.isRequired,
  questionFragments: PropTypes.shape({}),
  accountCreated: PropTypes.bool.isRequired,
  questionnaireId: PropTypes.string.isRequired,
  dispatchUpdateToast: PropTypes.func.isRequired,
  dispatchNextQuestion: PropTypes.func.isRequired,
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  context: PropTypes.oneOf(['flow', 'modal']).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  questionnaireId: questionnaireSelectors.getQuestionnaireId(
    state.questionnaire,
  ),
  questionFragments: questionnaireSelectors.getQuestionFragments(
    state.questionnaire,
  ),
  working: navigationSelectors.getWorking(state.questionnaire),
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
  dispatchUpdateToast: (toastProperties) =>
    dispatch(commonActions.updateToast(toastProperties)),
  dispatchNextQuestion: (questionFragments = {}) =>
    dispatch(navigationActions.nextQuestion({ questionFragments })),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm)
