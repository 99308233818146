import React, { useRef, useState } from 'react'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import _isEmpty from 'lodash/isEmpty'

import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import { Badge, Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../common/components/atoms/Button'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import { Appointment } from '../../../../../../../types/appointment.types'
import appointmentsSelectors from '../../../../../ducks/appointments/appointmentsSelectors'
import BookWitnessingModal from '../modals/BookWitnessingModal'
import PendingWitnessingAppointmentModal from '../modals/PendingWitnessingAppointmentModal/PendingWitnessingAppointmentModal'

interface Props {
  autoOpen: boolean
  hasPrintCredit: boolean
  hasWitnessingCredit: boolean
  pendingWitnessingAppointments: Appointment[]
}

const WitnessingButton = ({
  autoOpen,
  hasPrintCredit,
  hasWitnessingCredit,
  pendingWitnessingAppointments,
}: Props) => {
  const { current: initialHasWitnessingCreditStatus } =
    useRef(hasWitnessingCredit)

  const [showBookModal, setShowBookModal] = useState(
    !!(hasWitnessingCredit && initialHasWitnessingCreditStatus && autoOpen),
  )
  const [showPendingModal, setShowPendingModal] = useState(
    !!(!hasWitnessingCredit && !initialHasWitnessingCreditStatus && autoOpen),
  )

  const { t } = useTranslation()

  return (
    <>
      {!_isEmpty(pendingWitnessingAppointments) ? (
        <Button
          variant="contained"
          color={themeColorTypes.NEUTRAL}
          startIcon={<GroupOutlinedIcon />}
          label={t(
            'documents:components.buttons.WitnessingButton.buttonLabel.case1',
          )}
          data-testid="pending-witnessing-button"
          onClick={() => setShowPendingModal(true)}
        />
      ) : hasWitnessingCredit ? (
        <Box sx={{ position: 'relative' }}>
          <Badge
            badgeContent="1"
            color="secondary"
            data-testid="witnessing-badge"
            sx={{ position: 'absolute', top: '5.5px', right: '5.5px' }}
          />
          <Button
            variant="contained"
            label={t(
              'documents:components.buttons.WitnessingButton.buttonLabel.case2',
            )}
            color={themeColorTypes.NEUTRAL}
            startIcon={<GroupOutlinedIcon />}
            onClick={() => setShowBookModal(true)}
            data-testid="book-witnessing-button"
          />
        </Box>
      ) : (
        <Link to="/dashboard/add-ons/witnessing">
          <Button
            variant="contained"
            label={t(
              'documents:components.buttons.WitnessingButton.buttonLabel.case3',
            )}
            color={themeColorTypes.NEUTRAL}
            startIcon={<GroupOutlinedIcon />}
            data-testid="add-witnessing-button"
          />
        </Link>
      )}

      <BookWitnessingModal
        show={showBookModal}
        hasPrintCredit={hasPrintCredit}
        onClose={() => setShowBookModal(false)}
      />

      <PendingWitnessingAppointmentModal
        show={showPendingModal}
        onClose={() => setShowPendingModal(false)}
        pendingWitnessingAppointments={pendingWitnessingAppointments}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  pendingWitnessingAppointments:
    appointmentsSelectors.getPendingWitnessingAppointments(state.dashboard),
})

export default connect(mapStateToProps)(WitnessingButton)
