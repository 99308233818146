import React from 'react'
import PropTypes from 'prop-types'

import Text from '../../../../../../common/components/atoms/Text'

const SidebarCaption = ({ text }) => (
  <Text size="xs" sx={{ mt: '0.2rem', ml: '0.2rem', fontStyle: 'italic' }}>
    {text}
  </Text>
)

SidebarCaption.propTypes = {
  text: PropTypes.string.isRequired,
}

export default SidebarCaption
