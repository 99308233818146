import React from 'react'
import PropTypes from 'prop-types'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'

const ToggleButtons = ({
  value,
  buttons,
  onChange,
  'data-testid': dataTestId,
}) => (
  <ToggleButtonGroup
    exclusive
    value={value}
    data-testid={dataTestId}
    onChange={(_e, newValue) => {
      if (newValue && value !== newValue) {
        return onChange(newValue)
      }
      return undefined
    }}
    sx={{
      mt: '0.5rem',
      mr: '1rem',
      '& > .MuiToggleButton-root': {
        border: (theme) =>
          `solid 1px ${
            theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.MAIN]
          }`,
        color: (theme) =>
          theme.palette[themeColorTypes.BRAND][themeColorVariants.MAIN],
      },
      '& > .MuiToggleButton-root.Mui-selected': {
        bgcolor: (theme) =>
          theme.palette[themeColorTypes.ACCENT_3][themeColorVariants.LIGHTEST],
        borderLeft: (theme) =>
          `solid 1px ${
            theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.MAIN]
          }`,
      },
      '& > .MuiToggleButton-root:hover, & > .MuiToggleButton-root.Mui-selected:hover':
        {
          bgcolor: (theme) =>
            theme.palette[themeColorTypes.ACCENT_2][themeColorVariants.MAIN],
        },
    }}
  >
    {buttons.map(({ value: buttonValue, label }) => (
      <ToggleButton key={buttonValue} value={buttonValue}>
        {label}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)

ToggleButtons.propTypes = {
  value: PropTypes.string,
  'data-testid': PropTypes.string,
  onChange: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
}

export default ToggleButtons
