import _get from 'lodash/get'

import paymentsName from './paymentsName'

export default {
  getPayments: (state) => _get(state[paymentsName], 'payments'),
  getPartnerPaidFor: (state) => {
    const { payments } = state[paymentsName]
    return payments.some((p) => p.partnerPaidFor)
  },
}
