import { useState } from 'react'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useTranslation } from 'react-i18next'

import { errorHandler, PATHS, request } from '../request'

interface Props {
  email: string
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const checkEmailApi = (emailInput: string) =>
  request({
    method: 'POST',
    url: PATHS.USER_EMAIL_AVAIL,
    data: { email: emailInput },
  })

const debouncedCheckEmail = AwesomeDebouncePromise(checkEmailApi, 450)

export default ({ email, dispatchSetGlobalErrorMessage }: Props) => {
  const [validationMessage, setValidationMessage] = useState('')
  const [validationWorking, setValidationWorking] = useState(false)

  const { t } = useTranslation()

  const handleCheckValidEmail = async (input: string) => {
    setValidationWorking(true)

    try {
      const res = await debouncedCheckEmail(input)

      const { tldValid, emailAvailable } = res.data.payload

      if (!emailAvailable) {
        setValidationMessage(t('validation:validation.emailExists'))
      } else if (!tldValid) {
        setValidationMessage(t('validation:validation.notValidEmail'))
      } else {
        setValidationMessage('')
      }
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    } finally {
      setValidationWorking(false)
    }
  }

  return {
    validationWorking,
    validationMessage,
    handleCheckValidEmail,
  }
}
