import distributionSidebarFragments from './distributionSidebarFragments'

export default [
  distributionSidebarFragments.listAllAssets,
  {
    type: 'accordion',
    header: {
      tKey: 'sidebar:distributionSpecificGiftDetailsSidebar.item2.header',
    },
    children: [
      distributionSidebarFragments.residueDefinition,
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSpecificGiftDetailsSidebar.item2.children.item2.body',
        },
      },
    ],
  },
  distributionSidebarFragments.describeAssets,
  distributionSidebarFragments.noLongerOwnedAssets,
  {
    type: 'accordion',
    header: {
      tKey: 'sidebar:distributionSpecificGiftDetailsSidebar.item5.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSpecificGiftDetailsSidebar.item5.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSpecificGiftDetailsSidebar.item5.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSpecificGiftDetailsSidebar.item5.children.item3.body',
        },
      },
    ],
  },
]
