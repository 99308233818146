import React, { useState } from 'react'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../../atoms/Button'
import Text from '../../../atoms/Text'
import DeleteAccountModal from './components/DeleteAccountModal'

const DeleteAccount = () => {
  const [showModal, setShowModal] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Text size="lg" align="center" variant="paragraph" sx={{ my: '2rem' }}>
          {t('pages:AccountSettings.deleteAccount.description')}
        </Text>

        <Button
          variant="contained"
          label={t('pages:AccountSettings.deleteAccount.button.label')}
          onClick={() => setShowModal(true)}
          data-testid="delete-account-button"
        />
      </Box>

      <DeleteAccountModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

export default DeleteAccount
