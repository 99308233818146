import React, { useEffect, useRef, useState } from 'react'

import { Box } from '@mui/material'

import { Page } from 'react-pdf'

import useResponsive from '../../../hooks/useResponsive'

interface Props {
  pageNum: number
  maxWidth: number
}

const PdfPage = ({ pageNum, maxWidth }: Props) => {
  const pageRef = useRef<HTMLDivElement>()
  const [pageWidth, setPageWidth] = useState<number>()

  /*
   * This useResponsive() needs to be here because it updates every time
   * the screen-width window event changes and that triggers the useEffect() to
   * update as well, which is what allows us to make this component responsive
   */
  const [viewportWidth] = useResponsive()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (pageRef.current) {
      setPageWidth(pageRef.current.clientWidth)
    }
  })

  return (
    <Box ref={pageRef}>
      <Page
        loading=""
        renderMode="canvas"
        pageNumber={pageNum}
        width={
          viewportWidth && viewportWidth <= maxWidth ? pageWidth : maxWidth
        }
      />
    </Box>
  )
}

export default PdfPage
