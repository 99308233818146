import React, { Children, Dispatch, SetStateAction, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import _Capitalize from 'lodash/capitalize'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { CONSTANTS, Province } from '@epilogue/common'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../common/components/atoms/Button'
import commonActions from '../../../../../../../../common/ducks/commonActions'
import event from '../../../../../../../../common/lib/event'
import questionnaireSelectors from '../../../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { InsuranceQuotesParams, Quote } from '../quote.types'

const rbcInsuranceBridge =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://www.rbcinsurance.com/cgi-bin/life-insurance/embedded-insurance/bridge.cgi?utm_source=EpilogueWills&utm_medium=embeddded&utm_campaign=ins_life_embeddedSIT'
    : 'https://www.sterbcinsurance.com/cgi-bin/life-insurance/embedded-insurance/bridge.cgi'

interface QuoteSubmissionFormParams
  extends Pick<InsuranceQuotesParams, 'smoker' | 'term' | 'gender'> {
  quote?: Quote
  userId: string
  disabled: boolean
  coverageAmount?: number
  setShowModal?: Dispatch<SetStateAction<boolean>>
}

const QuoteSubmissionForm = (params: QuoteSubmissionFormParams) => {
  const {
    term,
    gender,
    userId,
    smoker,
    disabled,
    coverageAmount = 0,
    setShowModal = undefined,
    quote = { monthly: 0, annual: 0 },
  } = params
  const { monthly, annual } = quote

  const dispatch = useDispatch()

  const {
    answerStore: { dateOfBirth, province },
  } = useSelector<
    any,
    { answerStore: { dateOfBirth: string; province: Province } }
  >((state: any) => ({
    answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire) as {
      dateOfBirth: string
      province: Province
    },
  }))

  const quoteData = useMemo(
    () => ({
      coverageAmount,
      annualPremium: annual,
      productType: `T${term}`,
      monthlyPremium: monthly,
      gender: _Capitalize(gender),
      smokerStatus: smoker.toString(),
      dob: format(new Date(dateOfBirth), 'MM/dd/yyyy'),
      province: CONSTANTS.provinceAlphaCodes[province],
    }),
    [
      term,
      annual,
      gender,
      smoker,
      monthly,
      province,
      dateOfBirth,
      coverageAmount,
    ],
  )

  const { t } = useTranslation()

  return (
    <form action={rbcInsuranceBridge} method="post" target="_blank">
      {Children.toArray(
        Object.entries(quoteData).map(([key, value]) => (
          <input type="hidden" name={key} value={value} />
        )),
      )}

      <Button
        variant="contained"
        type="submit"
        sx={{ ml: '0.8rem' }}
        endIcon={<OpenInNewIcon />}
        label={t(
          'documents:components.LifeInsuranceQuotes.components.QuoteSubmissionForm.buttonLabel',
        )}
        data-testid="submit-quote-button"
        disabled={disabled}
        onClick={() => {
          event.send({
            metadata: {
              userId,
              term,
              annual,
              smoker,
              monthly,
              coverageAmount,
            },
            name: CONSTANTS.eventNames.LIFE_INSURANCE_SUBMIT,
            action: CONSTANTS.eventActionTypes.CLICKED,
          })
          setShowModal?.(false)
        }}
        onDisabledClick={() =>
          dispatch(
            commonActions.setGlobalErrorMessage(
              t(
                'documents:components.LifeInsuranceQuotes.components.QuoteSubmissionForm.errorMessage',
              ),
            ),
          )
        }
      />
    </form>
  )
}

export default QuoteSubmissionForm
