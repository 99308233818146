import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../common/components/atoms/Button'
import Heading from '../../../../../common/components/atoms/Heading'
import Text from '../../../../../common/components/atoms/Text'
import navigationActions from '../../../ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'
import LawyerReferral from '../../molecules/LawyerReferral'

const DropOff = ({ question, dispatchPreviousQuestion }) => {
  const { paragraphs, dropOffLabel, dropOffType } = question

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  const { t } = useTranslation()

  return (
    <>
      <Box
        alignSelf="flex-start"
        marginBottom="1.4rem"
        marginTop={isMobile ? '-1.5rem' : '-2.4rem'}
      >
        <Button
          label={t('common:back')}
          size="xxs"
          startIcon={<ArrowBackIcon />}
          data-testid="upper-back-button"
          onClick={dispatchPreviousQuestion}
        />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Box marginBottom="2rem">
          <Heading variant="h3" align="center">
            {/* If dropOffLabel has tKey, translate it, otherwise return as is */}
            {t(_get(dropOffLabel, 'tKey', dropOffLabel))}
          </Heading>
        </Box>

        <Box width="100%">
          {!_isEmpty(paragraphs) && (
            <>
              {paragraphs.map(({ id, text }) => (
                <Box key={id} marginBottom="2rem">
                  <Text variant="paragraph" align="center" size="md">
                    {text}
                  </Text>
                </Box>
              ))}
            </>
          )}

          <LawyerReferral dropOffType={dropOffType} />

          <Box marginTop="4rem" display="flex" justifyContent="center">
            <Button
              align="center"
              label={t('common:back')}
              size="xxs"
              startIcon={<ArrowBackIcon />}
              data-testid="lower-back-button"
              onClick={dispatchPreviousQuestion}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

DropOff.propTypes = {
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  question: PropTypes.shape({
    dropOffLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]).isRequired,
    paragraphs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
    dropOffType: PropTypes.oneOf(Object.values(CONSTANTS.dropOffTypes))
      .isRequired,
  }).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
})

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropOff)
