import React, { useState } from 'react'

import MailOutlinedIcon from '@mui/icons-material/MailOutlined'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../common/components/atoms/Button'
import amplitude from '../../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import { InvestmentAccountTypes } from '../../../../../../Questionnaire/components/Question/InvestmentAccounts/utils/getInvestmentAccountsOptions'
import EmailDocumentsModal from '../modals/EmailDocumentsModal'

interface Props {
  userId: string
  answerStore: any
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const EmailDocumentsButton = ({
  userId,
  answerStore,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const [showModal, setShowModal] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      <Button
        variant="contained"
        label={t('documents:components.buttons.EmailDocumentsButton.label')}
        color={themeColorTypes.NEUTRAL}
        startIcon={<MailOutlinedIcon />}
        onClick={() => {
          setShowModal(true)

          const {
            hasResps,
            hasLifeInsurance,
            hasForeignProperty,
            canadianRealEstate,
            investmentAccounts = [],
          } = answerStore

          amplitude.sendEvent('ClickedEmailDocumentsButton', {
            hasResps,
            hasLifeInsurance,
            hasForeignProperty,
            canadianRealEstate,
            hasRrsp: investmentAccounts.includes(InvestmentAccountTypes.RRSP),
            hasFhsa: investmentAccounts.includes(InvestmentAccountTypes.FHSA),
            hasTfsa: investmentAccounts.includes(InvestmentAccountTypes.TFSA),
            hasRrif: investmentAccounts.includes(InvestmentAccountTypes.RRIF),
          })
        }}
      />

      {showModal && (
        <EmailDocumentsModal
          userId={userId}
          show={showModal}
          answerStore={answerStore}
          onClose={() => setShowModal(false)}
          dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
        />
      )}
    </>
  )
}

export default EmailDocumentsButton
