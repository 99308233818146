"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EvironmentTypes = void 0;
var EvironmentTypes;
(function (EvironmentTypes) {
    EvironmentTypes["TEST"] = "test";
    EvironmentTypes["CYPRESS"] = "cypress";
    EvironmentTypes["STAGING"] = "staging";
    EvironmentTypes["PRODUCTION"] = "production";
    EvironmentTypes["DEVELOPMENT"] = "development";
})(EvironmentTypes || (exports.EvironmentTypes = EvironmentTypes = {}));
