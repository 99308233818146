import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Text from '../../../../../../common/components/atoms/Text'
import commonActions from '../../../../../../common/ducks/commonActions'
import commonSelectors from '../../../../../../common/ducks/commonSelectors'
import amplitude from '../../../../../../common/lib/amplitude'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import SidebarQuestionmark from './SidebarQuestionmark'

const SidebarToggleTag = ({
  sx = {},
  sidebarData,
  sidebarProperties,
  dispatchUpdateSidebar,
}) => {
  const isMobileOrTablet = useMediaQuery((theme) =>
    theme.breakpoints.down('3xl'),
  )

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        height: '3.5rem',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'flex-start',
        top: isMobileOrTablet ? undefined : '10rem',
        left: isMobileOrTablet ? '1rem' : undefined,
        bottom: isMobileOrTablet ? '1rem' : undefined,
        right: isMobileOrTablet
          ? undefined
          : sidebarProperties.isOpen
            ? '-200px'
            : '-10px', // for animation on non-mobile
        zIndex: (theme) => theme.zIndex.appBar - 1,
        borderTopLeftRadius: (theme) => theme.shape.borderRadiusFull,
        borderBottomLeftRadius: (theme) => theme.shape.borderRadiusFull,
        borderRadius: (theme) =>
          isMobileOrTablet ? theme.shape.borderRadiusFull : undefined,
        border: (theme) =>
          `solid 2px ${
            theme.palette[themeColorTypes.ACCENT_3][themeColorVariants.MAIN]
          }`,
        bgcolor: (theme) =>
          theme.palette[themeColorTypes.ACCENT_3][themeColorVariants.LIGHTEST],
        ':hover': {
          cursor: 'pointer',
          right: isMobileOrTablet
            ? undefined
            : sidebarProperties.isOpen
              ? undefined
              : '-2px', // for animation on non-mobile
        },
        transition: (theme) =>
          isMobileOrTablet
            ? undefined
            : theme.transitions.create('right', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
        ...sx,
      }}
      onClick={() => {
        amplitude.sendEvent('SidebarDisplayToggle', {
          isOpening: !sidebarProperties.isOpen,
          topLevelQuestionCount: sidebarData.length,
        })
        dispatchUpdateSidebar({ isOpen: !sidebarProperties.isOpen })
      }}
      data-testid="sidebar-toggle"
      id="trigger-sidebar"
    >
      <SidebarQuestionmark />
      <Text
        size="xs"
        align={isMobileOrTablet ? 'left' : 'center'}
        weight="bold"
        sx={{
          width: '6rem',
          lineHeight: 1.1,
          mr: isMobileOrTablet ? '0.5rem' : '15px', // for animation on non-mobile
        }}
      >
        {t('questionnaire.misc:sidebar.helpWithThisQuestion')}
      </Text>
    </Box>
  )
}

SidebarToggleTag.propTypes = {
  sx: PropTypes.shape({}),
  dispatchUpdateSidebar: PropTypes.func.isRequired,
  sidebarData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sidebarProperties: PropTypes.shape({ isOpen: PropTypes.bool }).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateSidebar: (sidebarProperties) =>
    dispatch(commonActions.updateSidebar(sidebarProperties)),
})

const mapStateToProps = (state) => ({
  sidebarProperties: commonSelectors.getSidebar(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarToggleTag)
