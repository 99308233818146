import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Qu'est-ce que l'assurance-vie ?

Les gens souscrivent une assurance-vie pour protéger leurs proches en cas de décès. En échange d'un paiement mensuel appelé "prime", la compagnie d'assurance-vie accepte de verser un paiement exonéré d'impôt à la personne que vous spécifiez dans le contrat d'assurance (ce sont les "bénéficiaires" de la police d'assurance).

#### À quoi sert l'assurance-vie ?

Les gens souscrivent une assurance-vie pour diverses raisons. Dans la plupart des cas, c'est un moyen pour les gens de garantir que les personnes qui dépendent financièrement d'eux (comme un conjoint/partenaire et de jeunes enfants) continuent à bénéficier d'un soutien financier en cas de décès.

Les paiements d'assurance-vie peuvent être utilisés par les bénéficiaires pour répondre à leurs besoins, y compris les dépenses quotidiennes, les paiements hypothécaires, les frais funéraires, les frais de scolarité des enfants et les impôts sur la succession.

#### Deux types d'assurance-vie

Il existe deux types d'assurance-vie de base - l'assurance temporaire et l'assurance permanente.

Avec l'assurance temporaire, le contrat d'assurance expire après une certaine période de temps - généralement 10, 20 ou 30 ans. Les primes sont payées pendant la durée du contrat d'assurance, et si la personne décède pendant la période, la compagnie d'assurance verse la valeur de la prestation de décès aux bénéficiaires. Si la personne est toujours en vie à la fin de la période, aucune prestation de décès n'est versée et le contrat d'assurance expire.

L'assurance permanente n'expire pas, ce qui signifie que la prestation de décès sera versée aux bénéficiaires lorsque vous décéderez. Comme une prestation de décès sera éventuellement versée, les primes de l'assurance-vie permanente sont généralement beaucoup plus élevées que celles de l'assurance temporaire.

#### Coût de l'assurance-vie

Le montant de vos primes - c'est-à-dire le coût de votre assurance-vie - dépendra de plusieurs facteurs, notamment le type d'assurance que vous souhaitez, la durée du contrat d'assurance (pour l'assurance temporaire), le montant de la prestation de décès que vous souhaitez, votre âge, votre sexe, votre état de santé et que vous soyez fumeur ou non.

    `
    : `
#### What is Life Insurance?

People get life insurance to protect their loved ones in case they pass away. In exchange for a monthly payment called a “premium” the life insurance company agrees to make a tax-free payment to whoever you specify in the insurance agreement (these are the “beneficiaries” of the insurance policy).

#### What is Life Insurance Used For?

People get life insurance for a variety of reasons. In most cases, it is a way for people to ensure that the people that depend on them financially (such as a spouse/partner and young children) continue to have financial support in case they pass away.

Life insurance payments can be used by the beneficiaries for whatever their needs are, including day-to-day expenses, mortgage payments, paying funeral expenses, paying for children's education, and paying the estate's taxes.

#### Two Types of Life Insurance

There are two basic types of life insurance  — term insurance and permanent insurance.

With term insurance, the insurance agreement expires after a certain amount of time  — usually 10, 20, or 30 years. Premiums are paid while the insurance agreement is in place, and if the person dies during the term, the insurance company pays the value of the death benefit to the beneficiaries. If the person is still alive at the end of the term, no death benefit is paid and the insurance contract expires.

Permanent insurance does not expire, which means that the death benefit will be paid to the beneficiaries whenever you pass away. Because a death benefit will eventually be paid, the premiums for permanent life insurance are usually much higher than they are for term insurance.

#### Cost of Life Insurance

The amount of your premiums  — that is, the cost of your life insurance  — will depend on a number of factors, including the type of insurance you want, the length of the insurance agreement (for term insurance), the amount of death benefit you want, your age, gender, health status, and whether or not you are a smoker.
`
