import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import paymentsSelectors from '../../../../../../../scenes/Dashboard/ducks/payments/paymentsSelectors'
import hasCoreProduct from '../../../../../../../scenes/Dashboard/utils/hasCoreProduct'
import commonActions from '../../../../../../ducks/commonActions'
import amplitude from '../../../../../../lib/amplitude'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../styles/muiTheme'
import Button from '../../../../../atoms/Button'
import Checkbox from '../../../../../atoms/Checkbox'
import Heading from '../../../../../atoms/Heading'
import Modal from '../../../../../atoms/Modal'
import Text from '../../../../../atoms/Text'
import useDeleteAccount from './hooks/useDeleteAccount'

const DeleteAccountModal = ({
  show,
  onClose,
  payments,
  dispatchSetGlobalErrorMessage,
}) => {
  const [handleDeleteAccount, working] = useDeleteAccount({
    dispatchSetGlobalErrorMessage,
  })
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const isPaidUser = hasCoreProduct(payments)

  useEffect(() => {
    amplitude.sendEvent('ViewedDeleteAccountModal', { isPaidUser })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      maxWidth="sm"
      onClose={onClose}
      data-testid="delete-account-modal"
    >
      <Box
        sx={{
          p: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Heading align="center" variant="h3">
          {t(
            'pages:AccountSettings.deleteAccount.components.DeleteAccountModal.label',
          )}
        </Heading>

        <Text
          size="lg"
          align="center"
          variant="paragraph"
          sx={{ pt: '1.85rem' }}
        >
          {isPaidUser
            ? t(
                'pages:AccountSettings.deleteAccount.components.DeleteAccountModal.subLabel.isPaidUser',
              )
            : t(
                'pages:AccountSettings.deleteAccount.components.DeleteAccountModal.subLabel.isNotPaidUser',
              )}
        </Text>

        {!isPaidUser && (
          <>
            <Box sx={{ pt: '1.65rem' }}>
              <Checkbox
                checked={deleteConfirmation}
                label={t(
                  'pages:AccountSettings.deleteAccount.components.DeleteAccountModal.checkbox.label',
                )}
                onChange={() => {
                  amplitude.sendEvent('CheckedDeleteAccountConfirmation')
                  setDeleteConfirmation((prev) => !prev)
                }}
              />
            </Box>

            <Box
              sx={{
                pt: '1.65rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                fullWidth
                working={working}
                variant="contained"
                label={t(
                  'pages:AccountSettings.deleteAccount.components.DeleteAccountModal.button.label',
                )}
                color={themeColorTypes.RED}
                onClick={handleDeleteAccount}
                disabled={!deleteConfirmation}
                spinnerPadding="0.375rem 2.827rem"
                spinnerColor={themeColorTypes.WHITE}
                colorVariant={themeColorVariants.MAIN}
                data-testid="delete-account-modal-button"
              />
            </Box>
          </>
        )}
      </Box>
    </Modal>
  )
}

DeleteAccountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  payments: paymentsSelectors.getPayments(state.dashboard),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal)
