import { CONSTANTS } from '@epilogue/common'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'

import { PATHS, request } from '../request'
import featureFlag, { flagTypes } from '../utils/featureFlag'

if (featureFlag(flagTypes.SENTRY)) {
  Sentry.init({
    // Send errors to GlitchTip instead of Sentry, which was becoming flakey
    dsn: 'https://74049058003b4a6a9edd458ecdbf98b1@app.glitchtip.com/6815',
    // dsn: 'https://1d85703ffd7c490bb6a778467b0b8282@o335218.ingest.sentry.io/1875349',
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENV === 'staging' ? 'staging' : 'prod',
  })
}

LogRocket.getSessionURL((sessionURL) => {
  if (window.Intercom) {
    window.Intercom('trackEvent', 'LogRocket', { sessionURL })
  }

  Sentry.getCurrentScope().setExtras({ logRocketURL: sessionURL })
})

window.captureException = (error, customErrMsg) => {
  if (customErrMsg) {
    // eslint-disable-next-line no-param-reassign
    error.message = customErrMsg
  }

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.getCurrentScope().setExtras({ logRocketURL: sessionURL })
  })

  Sentry.captureException(error)
  LogRocket.captureException(error)

  // Error codes we do not need to report to Slack
  const doNotReportCodes = [
    'ERR_BAD_RESPONSE',
    'ERR_BAD_REQUEST',
    'ECONNABORTED',
    'ERR_NETWORK',
  ]
  if (!doNotReportCodes.includes(error?.code)) {
    request({
      method: 'POST',
      url: PATHS.NOTIFY,
      data: {
        type: CONSTANTS.notifierTypes.ERROR,
        message: `${error.message}
      ${JSON.stringify(error, null, 2)}`,
      },
    })
  }

  // eslint-disable-next-line no-console
  console.error('🚨captureException 🚨:', error.message)
}
