import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import _get from 'lodash/get'
import _has from 'lodash/has'

import { Box } from '@mui/material'

import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet'

import '../styles/output.css'

import { CONSTANTS } from '@epilogue/common'

import Dashboard from '../../scenes/Dashboard'
import dashboardSelectors from '../../scenes/Dashboard/ducks/dashboard/dashboardSelectors'
import Questionnaire from '../../scenes/Questionnaire'
import navigationActions from '../../scenes/Questionnaire/ducks/navigation/navigationActions'
import questionnaireSelectors from '../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import SocialDashboard from '../../scenes/SocialDashboard'
import AccountSettings from '../components/AccountSettings'
import LoadingSpinner from '../components/atoms/LoadingSpinner'
import ForgotPassword from '../components/ForgotPassword'
import FourOhFour from '../components/FourOhFour'
import GetStarted from '../components/GetStarted'
import Login from '../components/Login'
import CatastrophicError from '../components/molecules/CatastrophicError'
import GenerateDocsModal from '../components/molecules/GenerateDocsModal'
import Toast from '../components/molecules/Toast'
import ResetPassword from '../components/ResetPassword/ResetPassword'
import commonActions from '../ducks/commonActions'
import commonSelectors from '../ducks/commonSelectors'
import { PATHS, request } from '../request'
import { setQidCookies } from '../utils/qidCookies/qidCookies'
import { queryKeys, queryKeysBase } from '../utils/queryKeys'
import { setReferrerCookies } from '../utils/referrer'
import { setUtmCookies } from '../utils/utms'
import LocationHandler from './LocationHandler'
import WorldAuthRedirectRoute from './WorldAuthRedirectRoute'

const mapStateToProps = (state) => ({
  auth: commonSelectors.getAuth(state),
  world: commonSelectors.getWorld(state),
  authWorking: commonSelectors.getAuthWorking(state),
  userId: dashboardSelectors.getUserId(state.dashboard),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
  newQuestionnaire: questionnaireSelectors.getNewQuestionnaire(
    state.questionnaire,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchDashboardRedirect: (redirect) =>
    dispatch(navigationActions.dashboardRedirect(redirect)),
  dispatchCheckAuth: () =>
    dispatch(commonActions.checkAuth({ fromQuestionnaire: false })),
  dispatchSetWorld: () => dispatch(commonActions.setWorld()),
})

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <LocationHandler>
        <Outlet />
      </LocationHandler>
    ),
    errorElement: <CatastrophicError />,
    children: [
      {
        path: 'reset-password/:id/:resetToken',
        element: (
          <WorldAuthRedirectRoute authType="forbidden">
            <ResetPassword />
          </WorldAuthRedirectRoute>
        ),
      },
      {
        path: 'login',
        element: (
          <WorldAuthRedirectRoute authType="forbidden">
            <Login />
          </WorldAuthRedirectRoute>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <WorldAuthRedirectRoute authType="forbidden">
            <ForgotPassword />
          </WorldAuthRedirectRoute>
        ),
      },
      {
        path: 'dashboard/*',
        element: (
          <WorldAuthRedirectRoute
            authType="required"
            exactWorldType={CONSTANTS.worldTypes.will.type}
          >
            <Dashboard />
          </WorldAuthRedirectRoute>
        ),
      },
      {
        path: 'questionnaire/*',
        element: <Questionnaire />,
      },
      {
        path: 'social-dashboard',
        element: (
          <WorldAuthRedirectRoute
            authType="required"
            exactWorldType={CONSTANTS.worldTypes.social.type}
          >
            <SocialDashboard />
          </WorldAuthRedirectRoute>
        ),
      },
      {
        path: 'account-settings',
        element: (
          <WorldAuthRedirectRoute authType="required">
            <AccountSettings />
          </WorldAuthRedirectRoute>
        ),
      },
      {
        path: 'get-started',
        element: (
          <WorldAuthRedirectRoute authType="forbidden">
            <GetStarted />
          </WorldAuthRedirectRoute>
        ),
      },
      { path: '*', element: <FourOhFour /> },
    ],
  },
])

const SwitchedRoutes = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ auth, referrer, newQuestionnaire, dispatchDashboardRedirect }) => {
  useEffect(() => {
    /*
     * If user is authenticated and on /questionnaire, send to /dashboard
     * Otherwise, that means they're already on the dashboard page
     */
    if (
      auth &&
      window.location.pathname.includes('/questionnaire') &&
      !newQuestionnaire
    ) {
      dispatchDashboardRedirect(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        {/* RBC GTM script only runs when user ref === 'rbc' */}
        {_get(referrer, 'ref', '').toLowerCase() === 'rbc' &&
          _has(referrer, 'reftag') &&
          process.env.REACT_APP_ENV === 'production' && (
            <script>
              {`var dataLayer=window.dataLayer=window.dataLayer||[];
                  dataLayer.push({"LOB":"epilogue"});
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-KWH99G8');`}
            </script>
          )}
      </Helmet>

      <RouterProvider router={router} fallbackElement={<LoadingSpinner />} />

      {/*
       * This component is here because it's required by all
       * scenes (questionnaire and dashboard). It is triggered by a Redux property
       * and would otherwise cause one modal to be on top of another in the
       * StaleDocsModal.js file, which was causing UI issues (mainly, overflow:hidden)
       * wasn't being removed from the body element after the docs generated.
       * I know it's weird, but just leave it here until there is time to create
       * a more elegant solution.
       */}
      <GenerateDocsModal />
      <Toast />
    </>
  )
})

const Router = ({
  userId,
  authWorking,
  dispatchSetWorld,
  newQuestionnaire,
  dispatchCheckAuth,
}) => {
  /*
   * This handles two scenarios:
   *
   * 1. When the user has a userId (authenticated as far as the front-end is concerned)
   * but has an expired or non-existent session, we refresh the page.
   *
   * 2. When the user has a userId (authenticated as far as the front-end is concerned)
   * but it doesn't match the active session, we refresh the page. This can happen if
   * two users are switching back and forth between tabs and logging in and out of more
   * than one account.
   *
   */
  useQuery({
    queryKey: queryKeys[queryKeysBase.userId](userId),
    queryFn: () => request({ method: 'GET', url: PATHS.USER_ID }),
    onSuccess({ data }) {
      const sessionUserId = _get(data, ['payload', 'userId'])

      if (userId && sessionUserId !== userId) {
        window.location.href = '/login'
      }
    },
    retry: false,
    refetchOnWindowFocus: true,
  })

  useEffect(() => {
    // 🚀 These are the first things that happen on app initialization 🚀 //

    // if there is a ref and reftag, store in cookies
    setReferrerCookies()

    // If there are any utm values, store them in cookies
    setUtmCookies()

    // if qid and token exist in query params, store in cookies
    setQidCookies()

    dispatchSetWorld()

    dispatchCheckAuth()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* only show loading if it's before questionnaire begins */}
      {authWorking && !newQuestionnaire ? (
        <Box sx={{ mt: '8rem' }}>
          <LoadingSpinner />
        </Box>
      ) : (
        <SwitchedRoutes />
      )}
    </>
  )
}

Router.propTypes = {
  userId: PropTypes.string,
  newQuestionnaire: PropTypes.bool,
  authWorking: PropTypes.bool.isRequired,
  dispatchSetWorld: PropTypes.func.isRequired,
  dispatchCheckAuth: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Router)
