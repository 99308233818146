import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import _toLower from 'lodash/toLower'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReactComponent as Coins } from '../../assets/images/coins.svg'
import familyPhoto from '../../assets/images/family-photo.svg'
import { ReactComponent as Glasses } from '../../assets/images/glasses.svg'
import team from '../../assets/images/team.png'
import amplitude from '../../lib/amplitude'
import { themeColorTypes } from '../../styles/muiTheme'
import { getReferrerFromCookies } from '../../utils/referrer'
import Button from '../atoms/Button'
import Heading from '../atoms/Heading'
import Text from '../atoms/Text'
import Header from '../molecules/Header'
import CharityValueProposition from './components/CharityValueProposition'
import RbcValueProposition from './components/RbcValueProposition'
import ValueProposition from './components/ValueProposition'

const StyledGlasses = styled(Glasses)({
  height: '37.53px',
  width: '84px',
})

const StyledCoins = styled(Coins)({
  height: '50.79px',
  width: '56px',
})

const GetStarted = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.down('2xl')) && !isMobile
  const isXlDesktop = useMediaQuery('(min-width:1577px)')

  /*
   * Any reftag in this array, when paired with a ref of 'rbc', will NOT
   * result in the Get Started component displaying RBC branding. Instead
   * it will simply fallback to the default styling.
   */
  const rbcReftagBlacklist = ['ampli-march-22']

  const displayRbcValueProposition =
    _toLower(getReferrerFromCookies()?.ref) === 'rbc' &&
    !rbcReftagBlacklist.includes(_toLower(getReferrerFromCookies()?.reftag))

  const displayTtcValueProposition =
    _toLower(getReferrerFromCookies()?.ref) === 'ttc'

  const displayCharityValueProposition =
    !displayRbcValueProposition &&
    _toLower(getReferrerFromCookies()?.ref) === 'charity'

  useEffect(() => {
    amplitude.sendEvent('ViewedGetStarted', {
      displayRbcBranding: displayRbcValueProposition,
      displayCharityValueProposition,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Box
        sx={{
          px: '1rem',
          zIndex: '-1',
          width: '100%',
          display: 'flex',
          maxWidth: '1577px',
          position: 'absolute',
          bottom: isMobile || isTablet ? undefined : 0,
          top: isXlDesktop ? '15px' : isMobile ? '4.5rem' : '23px',
        }}
      >
        <Box
          sx={{
            flex: 1,
            p: '2.2rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            // Prevent squishing and overlap when people resize the screen to odd sizes
            minWidth: isMobile ? undefined : '570px',
            minHeight: isMobile || isTablet ? undefined : '700px',
          }}
        >
          <img
            src={familyPhoto}
            alt=""
            loading="lazy"
            style={{
              display: isTablet ? 'unset' : 'none',
              width: '300px',
              height: 'auto',
              marginBottom: '1.3rem',
            }}
          />
          <Heading
            sx={{ mb: isMobile ? '0.3rem' : '0.4rem' }}
            variant={isMobile ? 'h2' : 'h1'}
            color={themeColorTypes.ACCENT_1}
            align="center"
          >
            {t('components:GetStarted.label')}
          </Heading>
          <Text
            sx={{
              mb: isXlDesktop ? '1.8rem' : '1.4rem',
              maxWidth: isMobile ? '28rem' : '33rem',
            }}
            variant="paragraph"
            size="md"
            align="center"
          >
            {t('components:GetStarted.subLabel')}
          </Text>

          <ValueProposition
            icon={<StyledGlasses />}
            label={t(
              'components:GetStarted.valueProps.default.valueProp1.label',
            )}
            description={t(
              'components:GetStarted.valueProps.default.valueProp1.description',
            )}
          />
          <ValueProposition
            icon={<img src={team} alt="" height="76px" width="69px" />}
            label={t(
              'components:GetStarted.valueProps.default.valueProp2.label',
            )}
            description={t(
              'components:GetStarted.valueProps.default.valueProp2.description',
            )}
          />

          {displayRbcValueProposition ? (
            <RbcValueProposition />
          ) : displayCharityValueProposition ? (
            <CharityValueProposition />
          ) : displayTtcValueProposition ? (
            <ValueProposition
              hideBottomBorder
              icon={<StyledCoins />}
              label={t('components:GetStarted.valueProps.ttc.valueProp3.label')}
              description={t(
                'components:GetStarted.valueProps.ttc.valueProp3.description',
              )}
            />
          ) : (
            <ValueProposition
              hideBottomBorder
              icon={<StyledCoins />}
              label={t(
                'components:GetStarted.valueProps.default.valueProp3.label',
              )}
              description={t(
                'components:GetStarted.valueProps.default.valueProp3.description',
              )}
            />
          )}

          <Box
            sx={{
              mt: isXlDesktop ? '2rem' : isMobile ? '0.5rem' : '1.6rem',
              mb: isMobile ? '3rem' : 'initial',
              width: '100%',
              maxWidth: isMobile ? '28rem' : '33rem',
            }}
          >
            <Link to="/questionnaire">
              <Button
                fullWidth
                data-testid="get-started-button"
                label={
                  <Box
                    sx={{
                      my: '0.5rem',
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: '0.4rem',
                    }}
                  >
                    <Text size="lg" color={themeColorTypes.WHITE}>
                      {t('common:continue')}
                    </Text>
                    <ArrowForwardIcon />
                  </Box>
                }
                variant="contained"
              />
            </Link>
          </Box>
        </Box>
        <Box
          data-testid="get-started-image"
          sx={{
            display: isMobile || isTablet ? 'none' : undefined,
            flex: 1,
            p: '2.2rem',
            backgroundImage: `url(${familyPhoto})`,
            backgroundSize: isXlDesktop ? '44rem' : '88%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
        />
      </Box>
    </Box>
  )
}

export default GetStarted
