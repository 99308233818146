import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Modal from '../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../common/lib/amplitude'

const MixedCharitableGiftsModal = ({ show, onClose }) => {
  useEffect(() => {
    amplitude.sendEvent('ViewedMixedCharitableGiftsModal')
  }, [])

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      maxWidth="sm"
      onClose={(event, reason) => {
        amplitude.sendEvent('ClosedMixedCharitableGiftsModal', { reason })
        onClose(event, reason)
      }}
      data-testid="mixed-charitable-gifts-modal"
    >
      <Box>
        <Text gutterBottom size="lg" weight="bold" sx={{ pb: '0.5rem' }}>
          {t(
            'customQuestion:CharitableGiftDetails.components.MixedCharitableGiftsModal.label',
          )}
        </Text>

        <Text gutterBottom variant="paragraph" sx={{ pb: '0.4rem' }}>
          {t(
            'customQuestion:CharitableGiftDetails.components.MixedCharitableGiftsModal.paragraph1',
          )}
        </Text>

        <Text gutterBottom variant="paragraph" sx={{ pb: '0.4rem' }}>
          {t(
            'customQuestion:CharitableGiftDetails.components.MixedCharitableGiftsModal.paragraph2',
          )}
        </Text>

        <Text variant="paragraph">
          {t(
            'customQuestion:CharitableGiftDetails.components.MixedCharitableGiftsModal.paragraph3',
          )}
        </Text>
      </Box>
    </Modal>
  )
}

MixedCharitableGiftsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MixedCharitableGiftsModal
