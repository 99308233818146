import { CONSTANTS } from '@epilogue/common'

import { PROVINCES } from '../../../../common/utils/provinces'
import { STATES } from '../../../../common/utils/states/states'
import { gmailRegex } from '../../utils/validation/patterns'
import countryOptions from '../options/countryOptions'

const section = CONSTANTS.questionnaireSectionTypes.SOCIAL.id

export default {
  id: section,
  questions: [
    {
      id: 1614805105,
      pathname: 'country',
      section,
      label: 'Where do you currently live?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}country`,
          type: 'comboBox',
          reviewLabel: 'Country',
          placeholder: 'Choose a country',
          options: countryOptions,
        },
      ],
    },

    {
      id: 1616416897,
      pathname: 'province',
      section,
      label: 'Where in Canada do you live?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}province`,
          type: 'select',
          placeholder: 'Select your province',
          reviewLabel: 'Province',
          options: PROVINCES,
        },
      ],
      trigger: { social_country: 'Canada' },
    },

    {
      id: 1617757528,
      pathname: 'state',
      section,
      label: 'Which state do you live in?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}state`,
          type: 'select',
          placeholder: 'Select your state',
          reviewLabel: 'State',
          options: STATES,
        },
      ],
      trigger: { social_country: 'United States' },
    },

    {
      id: 1616416971,
      pathname: 'email',
      type: 'email',
      section,
      label: "What's your email address?",
      hideAfterAccount: true,
      hideOnReview: true,
      fields: [
        {
          name: 'email', // name must remain as 'email'
          type: 'email',
          placeholder: 'Email Address',
        },
      ],
    },

    {
      id: 1616417008,
      pathname: 'facebook',
      section,
      label: 'Do you have a Facebook account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}includeFacebook`,
          type: 'quickRadio',
          reviewLabel: 'Facebook Account?',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
    },

    {
      id: 1616417076,
      pathname: 'confirm-facebook-email',
      section,

      label: ({ answerStore }) => {
        const { email } = answerStore

        return `Is ${email} the email address you use for your Facebook account?`
      },

      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}emailIsFacebook`,
          type: 'quickRadio',
          reviewLabel: ({ answerStore }) => {
            const { email } = answerStore
            return `Facebook Login is ${email}?`
          },
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
      trigger: {
        $and: [{ social_includeFacebook: 'yes' }, { email: { $exists: true } }],
      },
    },

    {
      id: 1616376527,
      pathname: 'facebook-email',
      section,
      label: 'What email address do you use for your Facebook account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}facebookEmail`,
          type: 'text',
          reviewLabel: 'Facebook Login',
          placeholder: 'Email Address',
        },
      ],
      trigger: { social_emailIsFacebook: 'no' },
    },

    {
      id: 1616376531,
      pathname: 'facebook-wishes',
      section,
      label:
        'Facebook lets you choose what happens to your account when you pass away.',
      label2: 'Which of these options would you prefer?',
      subLabel:
        'A memorialized account is a place for loved ones to gather and share memories. When a Facebook account is deleted, all messages, photos, posts, comments, and reactions are permanently removed.',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}facebookWishes`,
          type: 'quickRadio',
          reviewLabel: 'Instructions for Facebook Account',
          options: [
            {
              value: 'memorialize',
              label: 'Memorialize my account',
              reviewLabel: 'Memorialize',
            },
            {
              value: 'delete',
              label: 'Delete my account',
              reviewLabel: 'Delete',
            },
          ],
        },
      ],
      trigger: { social_includeFacebook: 'yes' },
    },

    {
      id: 1616376537,
      pathname: 'facebook-settings',
      section,
      type: 'quickRadioWithLink',
      label:
        'Have you updated your Facebook account settings to confirm that you want your account deleted if you are not alive?',
      linkLabel: "I'd like to do this now. Show me how.",
      linkHref: 'https://www.facebook.com/help/103897939701143/',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}facebookWishesSet`,
          type: 'quickRadio',
          reviewLabel: 'Facebook Settings Updated?',
          options: [
            {
              value: 'yes',
              label: 'Yes, I have',
              reviewLabel: 'Yes',
            },
            {
              value: 'no',
              label: 'No, I will do it later',
              reviewLabel: 'No',
            },
          ],
        },
      ],
      trigger: { social_facebookWishes: 'delete' },
    },

    {
      id: 1616376544,
      pathname: 'facebook-legacy-contact',
      section,
      type: 'quickRadioWithLink',
      label:
        'Facebook lets you name a "legacy contact" to manage your memorialized account.',
      label2: 'Have you done this yet?',
      subLabel:
        'A legacy contact can do things like accept friend requests and pin a tribute post.',
      linkLabel: "I'd like to do this now. Show me how.",
      linkHref: 'https://www.facebook.com/help/103897939701143/',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}facebookLegacyContact`,
          type: 'quickRadio',
          reviewLabel: 'Facebook Legacy Contact?',
          options: [
            {
              value: 'yes',
              label: 'Yes, I have',
              reviewLabel: 'Yes',
            },
            {
              value: 'no',
              label: 'No, I will do it later',
              reviewLabel: 'No',
            },
          ],
        },
      ],
      trigger: { social_facebookWishes: 'memorialize' },
    },

    {
      id: 1616376550,
      pathname: 'facebook-instructions',
      section,
      optional: true,
      label:
        'Here you can write any personal wishes you have for your Facebook account and its contents.',
      subLabel:
        "You can write any wishes you want, but just know that they may not be possible to carry out under Facebook's terms and conditions.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}facebookInstructions`,
          type: 'textArea',
          reviewLabel: 'Additional Facebook Wishes',
          placeholder: 'Personal Wishes',
          placeholder2:
            'e.g. Please update my profile picture to be a photo of me and all of my grandchildren.',
          maxLength: 500,
          characterCount: true,
          rows: '5',
        },
      ],
      trigger: { social_includeFacebook: 'yes' },
    },

    {
      id: 1617163401,
      pathname: 'instagram',
      section,
      label: 'Do you have an Instagram account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}includeInstagram`,
          type: 'quickRadio',
          reviewLabel: 'Instagram Account?',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
    },

    {
      id: 1617163409,
      pathname: 'confirm-instagram-email',
      section,

      label: ({ answerStore }) => {
        const { email } = answerStore

        return `Is ${email} the email address you use for your Instagram account?`
      },

      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}emailIsInstagram`,
          type: 'quickRadio',
          reviewLabel: ({ answerStore }) => {
            const { email } = answerStore
            return `Instagram Login is ${email}?`
          },
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
      trigger: {
        $and: [
          { social_includeInstagram: 'yes' },
          { email: { $exists: true } },
        ],
      },
    },

    {
      id: 1617163418,
      pathname: 'instagram-email',
      section,
      label: 'What email address do you use for your Instagram account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}instagramEmail`,
          type: 'text',
          reviewLabel: 'Instagram Login',
          placeholder: 'Email Address',
        },
      ],
      trigger: { social_emailIsInstagram: 'no' },
    },

    {
      id: 1617163423,
      pathname: 'instagram-wishes',
      section,
      label:
        'You can let your loved ones know what to do with your Instagram account when you pass away.',
      label2: 'Which of these options would you prefer?',
      subLabel:
        'A memorialized account is a place for loved ones to gather and share memories. When an Instagram account is deleted, all messages, photos, posts, comments, and reactions are permanently removed.',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}instagramWishes`,
          type: 'quickRadio',
          reviewLabel: 'Instructions for Instagram Account',
          options: [
            {
              value: 'memorialize',
              label: 'Memorialize my account',
            },
            {
              value: 'delete',
              label: 'Delete my account',
            },
          ],
        },
      ],
      trigger: { social_includeInstagram: 'yes' },
    },

    {
      id: 1617163429,
      pathname: 'instagram-instructions',
      section,
      optional: true,
      label:
        'Here you can write any personal wishes you have for your Instagram account and its contents.',
      subLabel:
        "You can write any wishes you want, but just know that they may not be possible to carry out under Instagram's terms and conditions.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}instagramInstructions`,
          type: 'textArea',
          reviewLabel: 'Additional Instagram Wishes',
          placeholder: 'Personal Wishes',
          placeholder2:
            'e.g. Please create a photo album using all of the pictures on my Instagram account.',
          maxLength: 500,
          characterCount: true,
          rows: '5',
        },
      ],
      trigger: { social_includeInstagram: 'yes' },
    },

    {
      id: 1617163650,
      pathname: 'twitter',
      section,
      label: 'Do you have a Twitter account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}includeTwitter`,
          type: 'quickRadio',
          reviewLabel: 'Twitter Account?',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
    },

    {
      id: 1617163658,
      pathname: 'confirm-twitter-email',
      section,

      label: ({ answerStore }) => {
        const { email } = answerStore

        return `Is ${email} the email address you use for your Twitter account?`
      },

      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}emailIsTwitter`,
          type: 'quickRadio',
          reviewLabel: ({ answerStore }) => {
            const { email } = answerStore
            return `Twitter Login is ${email}?`
          },
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
      trigger: {
        $and: [{ social_includeTwitter: 'yes' }, { email: { $exists: true } }],
      },
    },

    {
      id: 1617163769,
      pathname: 'twitter-email',
      section,
      label: 'What email address do you use for your Twitter account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}twitterEmail`,
          type: 'text',
          reviewLabel: 'Twitter Login',
          placeholder: 'Email Address',
        },
      ],
      trigger: { social_emailIsTwitter: 'no' },
    },

    {
      id: 1617163804,
      pathname: 'twitter-wishes',
      section,
      label: 'Do you want your Twitter account deleted after you pass away?',
      subLabel:
        'Twitter can delete the account of someone who has passed away if requested by their family.',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}twitterWishes`,
          type: 'quickRadio',
          reviewLabel: 'Instructions for Twitter Account',
          options: [
            {
              value: 'deactivate',
              label: 'Yes',
              reviewLabel: 'Delete',
            },
            {
              value: 'leave',
              label: 'No',
              reviewLabel: 'Do not delete',
            },
          ],
        },
      ],
      trigger: { social_includeTwitter: 'yes' },
    },

    {
      id: 1617164106,
      pathname: 'twitter-instructions',
      section,
      optional: true,
      label:
        'Here you can write any personal wishes you have for your Twitter account and its contents.',
      subLabel:
        "You can leave any wishes you want, but just know that they may not be possible to carry out under Twitter's terms and conditions.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}twitterInstructions`,
          type: 'textArea',
          reviewLabel: 'Additional Twitter Wishes',
          placeholder: 'Personal Wishes',
          placeholder2: 'e.g. Compile a book of all my tweets for my family.',
          maxLength: 500,
          characterCount: true,
          rows: '5',
        },
      ],
      trigger: { social_includeTwitter: 'yes' },
    },

    {
      id: 1642108222,
      pathname: 'tiktok',
      section,
      label: 'Do you have a TikTok account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}includeTiktok`,
          type: 'quickRadio',
          reviewLabel: 'TikTok Account?',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
    },

    {
      id: 1642108270,
      pathname: 'confirm-tiktok-email',
      section,

      label: ({ answerStore }) => {
        const { email } = answerStore

        return `Is ${email} the email address you use for your TikTok account?`
      },

      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}emailIsTiktok`,
          type: 'quickRadio',
          reviewLabel: ({ answerStore }) => {
            const { email } = answerStore
            return `TikTok Login is ${email}?`
          },
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
      trigger: {
        $and: [{ social_includeTiktok: 'yes' }, { email: { $exists: true } }],
      },
    },

    {
      id: 1642108337,
      pathname: 'tiktok-email',
      section,
      label: 'What email address do you use for your TikTok account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}tiktokEmail`,
          type: 'text',
          reviewLabel: 'TikTok Login',
          placeholder: 'Email Address',
        },
      ],
      trigger: { social_emailIsTiktok: 'no' },
    },

    {
      id: 1642108381,
      pathname: 'tiktok-wishes',
      section,
      label: 'Do you want your TikTok account deleted after you pass away?',
      subLabel:
        'TikTok does not have an official policy regarding accounts of deceased users, but your family and friends can request deletion of your account if you want.',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}tiktokWishes`,
          type: 'quickRadio',
          reviewLabel: 'Instructions for TikTok Account',
          options: [
            {
              value: 'delete',
              label: 'Yes',
              reviewLabel: 'Delete',
            },
            {
              value: 'leave',
              label: 'No',
              reviewLabel: 'Do not delete',
            },
          ],
        },
      ],
      trigger: { social_includeTiktok: 'yes' },
    },

    {
      id: 1642108625,
      pathname: 'tiktok-instructions',
      section,
      optional: true,
      label:
        'Here you can write any personal wishes you have for your TikTok account and its contents.',
      subLabel:
        "You can leave any wishes you want, but just know that they may not be possible to carry out under TikTok's terms and conditions.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}tiktokInstructions`,
          type: 'textArea',
          reviewLabel: 'Additional TikTok Wishes',
          placeholder: 'Personal Wishes',
          placeholder2:
            'e.g. Gather all my friends together for a group showing of all my TikTok videos.',
          maxLength: 500,
          characterCount: true,
          rows: '5',
        },
      ],
      trigger: { social_includeTiktok: 'yes' },
    },

    {
      id: 1617164266,
      pathname: 'linkedin',
      section,
      label: 'Do you have a LinkedIn account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}includeLinkedin`,
          type: 'quickRadio',
          reviewLabel: 'LinkedIn Account?',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
    },

    {
      id: 1617280183,
      pathname: 'confirm-linkedin-email',
      section,

      label: ({ answerStore }) => {
        const { email } = answerStore
        return `Is ${email} the email address you use for your LinkedIn account?`
      },

      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}emailIsLinkedin`,
          type: 'quickRadio',
          reviewLabel: ({ answerStore }) => {
            const { email } = answerStore
            return `LinkedIn Login is ${email}?`
          },
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
      trigger: {
        $and: [{ social_includeLinkedin: 'yes' }, { email: { $exists: true } }],
      },
    },

    {
      id: 1617281001,
      pathname: 'linkedin-email',
      section,
      label: 'What email address do you use for your LinkedIn account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}linkedinEmail`,
          type: 'text',
          reviewLabel: 'LinkedIn Login',
          placeholder: 'Email Address',
        },
      ],
      trigger: { social_emailIsLinkedin: 'no' },
    },

    {
      id: 1617164443,
      pathname: 'linkedin-wishes',
      section,
      label:
        'You can let your loved ones know what to do with your LinkedIn account when you pass away.',
      label2: 'Which of these options would you prefer?',
      subLabel:
        "When a LinkedIn account is memorialized, it can no longer send messages or receive connection requests. However, that person's posts and articles can still be viewed.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}linkedinWishes`,
          type: 'quickRadio',
          reviewLabel: 'Instructions for LinkedIn Account',
          options: [
            {
              value: 'memorialize',
              label: 'Memorialize my account',
              reviewLabel: 'Memorialize',
            },
            {
              value: 'delete',
              label: 'Delete my account',
              reviewLabel: 'Delete',
            },
          ],
        },
      ],
      trigger: { social_includeLinkedin: 'yes' },
    },

    {
      id: 1617164518,
      pathname: 'linkedin-instructions',
      section,
      optional: true,
      label:
        'Here you can write any personal wishes you have for your LinkedIn account and its contents.',
      subLabel:
        "You can write any wishes you want, but just know that they may not be possible to carry out under LinkedIn's terms and conditions.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}linkedinInstructions`,
          type: 'textArea',
          reviewLabel: 'Additional LinkedIn Wishes',
          placeholder: 'Personal Wishes',
          placeholder2:
            'e.g. Please ask my connections to post fond memories of me.',
          maxLength: 500,
          characterCount: true,
          rows: '5',
        },
      ],
      trigger: { social_includeLinkedin: 'yes' },
    },

    {
      id: 1617164726,
      pathname: 'google',
      section,
      label:
        'Do you have a Google/Gmail account that you want to include in your Social Media Will?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}includeGoogle`,
          type: 'quickRadio',
          reviewLabel: 'Google/Gmail Account?',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
    },

    {
      id: 1617281018,
      pathname: 'confirm-google-email',
      section,
      label: ({ answerStore }) => {
        const { email } = answerStore
        return `Is ${email} the email address you use for the Google/Gmail account you want to include?`
      },
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}emailIsGoogle`,
          type: 'quickRadio',
          reviewLabel: ({ answerStore }) => {
            const { email } = answerStore
            return `Google/Gmail Login is ${email}?`
          },
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],

      trigger: {
        $and: [
          { social_includeGoogle: 'yes' },
          { email: { $exists: true } },
          { email: { $regex: gmailRegex, $options: 'i' } },
        ],
      },
    },

    {
      id: 1617281026,
      pathname: 'google-email',
      section,
      label:
        'What email address do you use for the Google/Gmail account you want to include?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}googleEmail`,
          type: 'text',
          reviewLabel: 'Google/Gmail Login',
          placeholder: 'Email Address',
        },
      ],
      trigger: {
        $and: [
          { social_includeGoogle: 'yes' },
          {
            $or: [
              { social_emailIsGoogle: 'no' },
              {
                $and: [
                  { email: { $exists: true } },
                  { email: { $not: { $regex: gmailRegex, $options: 'i' } } },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1617165679,
      pathname: 'google-settings',
      section,
      type: 'quickRadioWithLink',
      label:
        'You can decide what should happen to this account after you pass away by using Google\'s "Inactive Account Manager".',
      label2: 'Have you done this yet?',
      linkLabel: "I'd like to do this now. Show me how.",
      linkHref: 'https://myaccount.google.com/inactive',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}googleManagerSet`,
          type: 'quickRadio',
          reviewLabel: 'Inactive Account Manager Set?',
          options: [
            {
              value: 'yes',
              label: 'Yes, I have',
              reviewLabel: 'Yes',
            },
            {
              value: 'no',
              label: 'No, I will do it later',
              reviewLabel: 'No',
            },
          ],
        },
      ],
      trigger: { social_includeGoogle: 'yes' },
    },

    {
      id: 1617166067,
      pathname: 'google-wishes',
      section,
      label:
        'If you pass away before setting up Google Inactive Account Manager, what should your loved ones do?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}tellGoogle`,
          type: 'quickRadio',
          reviewLabel: 'Instructions for Google/Gmail Account',
          options: [
            {
              value: 'accessInfo',
              label: 'Contact Google to ACCESS my account',
              reviewLabel: 'Request account access',
            },
            {
              value: 'deleteAccount',
              label: 'Contact Google to DELETE my account',
              reviewLabel: 'Request account deletion',
            },
            {
              value: 'doNotContact',
              label: 'Do not contact Google',
            },
          ],
        },
      ],
      trigger: { social_googleManagerSet: 'no' },
    },

    {
      id: 1617166383,
      pathname: 'google-instructions',
      section,
      optional: true,
      label:
        'Here you can write any personal wishes you have for your Google/Gmail account and its contents.',
      subLabel:
        "You can write any wishes you want, but just know that they may not be possible to carry out under Google's terms and conditions.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}googleInstructions`,
          type: 'textArea',
          reviewLabel: 'Additional Google/Gmail Wishes',
          placeholder: 'Personal Wishes',
          placeholder2:
            'e.g. I\'ve written letters to each of my closest friends, which are saved in my Google Drive in a folder called "Letters to My Friends". Please distribute these accordingly.',
          maxLength: 500,
          characterCount: true,
          rows: '5',
        },
      ],
      trigger: { social_includeGoogle: 'yes' },
    },

    {
      id: 1642096422,
      pathname: 'apple',
      section,
      label:
        'Do you have an account with Apple (e.g. Apple ID or iCloud) that you want to include in your Social Media Will?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}includeApple`,
          type: 'quickRadio',
          reviewLabel: 'Apple Account?',
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
    },

    {
      id: 1642096427,
      pathname: 'confirm-apple-email',
      section,

      label: ({ answerStore }) => {
        const { email } = answerStore

        return `Is ${email} the email address associated with your Apple account?`
      },

      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}emailIsApple`,
          type: 'quickRadio',
          reviewLabel: ({ answerStore }) => {
            const { email } = answerStore
            return `Apple ID Login is ${email}?`
          },
          options: [
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            },
          ],
        },
      ],
      trigger: {
        $and: [{ social_includeApple: 'yes' }, { email: { $exists: true } }],
      },
    },

    {
      id: 1642096434,
      pathname: 'apple-email',
      section,
      label: 'What email address is associated with your Apple account?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}appleEmail`,
          type: 'text',
          reviewLabel: 'Apple ID Login',
          placeholder: 'Email Address',
        },
      ],
      trigger: { social_emailIsApple: 'no' },
    },

    {
      id: 1642107391,
      pathname: 'apple-legacy-contact',
      section,
      type: 'quickRadioWithLink',
      label:
        'Apple lets you name a trusted person as a "legacy contact" for your Apple account.',
      label2: 'Have you done this yet?',
      subLabel:
        'If you pass away, your legacy contact can request access to your iCloud photos, messages, notes, files, and other data stored in your Apple account.',
      linkLabel: "I'd like to do this now. Show me how.",
      linkHref: 'https://support.apple.com/en-us/HT212360/',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}appleLegacyContact`,
          type: 'quickRadio',
          reviewLabel: 'Apple Legacy Contact?',
          options: [
            {
              value: 'yes',
              label: 'Yes, I have',
              reviewLabel: 'Yes',
            },
            {
              value: 'no',
              label: 'No, I will do it later',
              reviewLabel: 'No',
            },
          ],
        },
      ],
      trigger: { social_includeApple: 'yes' },
    },

    {
      id: 1642097579,
      pathname: 'apple-wishes',
      section,
      label:
        'If you pass away before naming an Apple legacy contact, what should your loved ones do?',
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}tellApple`,
          type: 'quickRadio',
          reviewLabel: 'Instructions for Apple Account',
          options: [
            {
              value: 'accessInfo',
              label: 'Contact Apple to ACCESS my account',
              reviewLabel: 'Request account access',
            },
            {
              value: 'deleteAccount',
              label: 'Contact Apple to DELETE my account',
              reviewLabel: 'Request account deletion',
            },
            {
              value: 'doNotContact',
              label: 'Do not contact Apple',
            },
          ],
        },
      ],
      trigger: { social_appleLegacyContact: 'no' },
    },

    {
      id: 1642097058,
      pathname: 'apple-instructions',
      section,
      optional: true,
      label:
        'Here you can write any personal wishes you have for your Apple account and its contents.',
      subLabel:
        "You can write any wishes you want, but just know that they may not be possible to carry out under Apple's terms and conditions.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}appleInstructions`,
          type: 'textArea',
          reviewLabel: 'Additional Apple Wishes',
          placeholder: 'Personal Wishes',
          placeholder2:
            'e.g. Please create a photo album using the photos stored in my iCloud account.',
          maxLength: 500,
          characterCount: true,
          rows: '5',
        },
      ],
      trigger: { social_includeApple: 'yes' },
    },

    // {
    //   id: 1616377124,
    //   pathname: 'manager',
    //   section: section,
    //   optional: true,
    //   label:
    //     'After you pass away, someone (called your "executor") will be put in charge of managing all of your affairs. But they may need help when it comes to your digital identity wishes.',
    //   label2:
    //     "If you'd like, you can recommend someone to help your executor with this.",
    //   subLabel:
    //     'The person you choose might be a partner, a close friend, or a sibling.',
    //   fields: [
    //     {
    //       name: `${CONSTANTS.worldTypes.social.namespaceId}managerName`,
    //       type: 'text',
    //       placeholder: 'Full Name',
    //       reviewLabel: 'Manager',
    //     },
    //   ],
    // },

    {
      id: 1617281044,
      pathname: 'name',
      section,
      label: "What's your name?",
      subLabel: "We can't make your Social Media Will without it.",
      fields: [
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}firstName`,
          type: 'text',
          placeholder: 'First Name',
          reviewLabel: 'First Name',
        },
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}middleName`,
          type: 'text',
          placeholder: 'Middle Name',
          reviewLabel: 'Middle Name',
          optional: true,
        },
        {
          name: `${CONSTANTS.worldTypes.social.namespaceId}lastName`,
          type: 'text',
          placeholder: 'Last Name',
          reviewLabel: 'Last Name',
        },
      ],
    },

    {
      id: 1617281064,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: "That's it!",
      subLabel:
        'Please review this summary to make sure we have everything right.',
    },

    {
      id: 1617281052,
      pathname: 'password',
      type: 'socialCreateAccount',
      section,
      hideAfterAccount: true,
      hideOnReview: true,

      label: "It's time to create a password.",
      subLabel: 'You can log back in to update your Social Media Will anytime.',
      fields: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password',
        },
      ],
    },
  ],
}
