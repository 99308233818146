import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'

import { CheckBox, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import PieChart from '../../../../../common/components/PieChart'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'
import formatField from './utils/formatField'

const StepChildrenDistribution = ({
  handleFieldFragmentUpdate,
  answerCache,
  question,
}) => {
  const [field] = question.fields

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const children = answerCache.children || []

  const [stepChildren, setStepChildren] = useState(
    answerCache.stepChildren || [],
  )

  const [pieData, setPieData] = useState([])

  // set fragments immediately upon mounting
  useEffect(() => {
    handleFieldFragmentUpdate({
      stepChildren,
      [field.name]: formatField(stepChildren),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // only use legal children and stepChildren who are distributed 'likeChild' in graph
    const distributedChildren = [...stepChildren, ...children].filter(
      (c) => !c.isStep || (c.isStep && c.distribution === 'likeChild'),
    )

    const thePieData = distributedChildren.map((c, i) => ({
      id: i,
      title: c.name,
      value: Math.round((1 / distributedChildren.length) * 100 * 100) / 100,
    }))

    setPieData(thePieData)
  }, [children, stepChildren])

  const handleUpdate = (id, value) => {
    const clonedStepChildren = _cloneDeep(stepChildren)
    const theStepChildren = clonedStepChildren.map((stepChild) => {
      if (stepChild.id === id) {
        return { ...stepChild, distribution: value }
      }
      return stepChild
    })

    setStepChildren(theStepChildren)
    handleFieldFragmentUpdate({
      stepChildren: theStepChildren,
      [field.name]: formatField(theStepChildren),
    })
  }

  const { t } = useTranslation()

  return (
    <div className="mt-6 w-full">
      <ValidationMessage question={question} field={field} />

      {!_isEmpty(stepChildren) &&
        stepChildren.map((stepChild) => (
          <div
            key={stepChild.id}
            className="mb-8 flex justify-around w-full flex-col items-center"
          >
            <CheckBox
              checked={stepChild.distribution === 'likeChild'}
              label={
                <div className="ml-1 w-64 sm:w-120">
                  <Text>
                    {stepChild.name}{' '}
                    {t('customQuestion:StepChildrenDistribution.checkboxLabel')}
                  </Text>
                </div>
              }
              onChange={(e) =>
                handleUpdate(
                  stepChild.id,
                  e.target.checked ? 'likeChild' : 'exclude',
                )
              }
            />
          </div>
        ))}

      {children.length > 0 && (
        <div className="my-12 sm:my-18">
          <PieChart data={pieData} />
        </div>
      )}
    </div>
  )
}

StepChildrenDistribution.propTypes = {
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  answerCache: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
    stepChildren: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default StepChildrenDistribution
