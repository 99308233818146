import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Box, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import { emailRegex } from '../../../scenes/Questionnaire/utils/validation/patterns'
import commonActions from '../../ducks/commonActions'
import commonSelectors from '../../ducks/commonSelectors'
import amplitude from '../../lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../styles/muiTheme'
import Button from '../atoms/Button'
import Heading from '../atoms/Heading'
import Text from '../atoms/Text'
import TextInput from '../atoms/TextInput'
import withGoogleAuth from '../hoc/withGoogleAuth/withGoogleAuth'
import Header from '../molecules/Header'
import OauthProviderButton from '../molecules/OauthProviderButton/OauthProviderButton'
import useLogin from './hooks/useLogin'
import isEmailUpdate from './utils/isEmailUpdate'

const useStyles = makeStyles({
  logInButton: {
    minWidth: '154px',
    minHeight: '43px',

    '&.Mui-disabled': {
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      backgroundColor:
        themeColors[themeColorTypes.GREY][themeColorVariants.MAIN],
    },
  },

  whiteBox: {
    backgroundColor:
      themeColors[themeColorTypes.WHITE][themeColorVariants.MAIN],
  },

  divider: {
    position: 'relative',
    width: '196px',

    '&::before': {
      content: "''",
      display: 'block',
      backgroundColor:
        themeColors[themeColorTypes.GREY][themeColorVariants.LIGHT],
      position: 'absolute',
      height: '1px',
      width: '78px',
      top: '50%',
      left: 0,
    },

    '&::after': {
      content: "''",
      display: 'block',
      backgroundColor:
        themeColors[themeColorTypes.GREY][themeColorVariants.LIGHT],
      position: 'absolute',
      height: '1px',
      width: '78px',
      top: '50%',
      right: 0,
    },
  },
})

const GoogleLoginButton = withGoogleAuth(({ onClick, working, ...props }) => (
  <OauthProviderButton {...props} working={working} onClick={onClick} />
))

const Login = ({ loginRedirect, dispatchUpdateToast }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { statusStates, status, handleSubmit } = useLogin({
    email,
    password,
    loginRedirect,
    onError: () => setPassword(''),
  })

  const validEmail = emailRegex.test(email)

  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const isEmailUpdateRedirect = isEmailUpdate()
  const oauthType = CONSTANTS.oauth.types.LOGIN

  const { t } = useTranslation()

  useEffect(() => {
    amplitude.sendEvent('ViewedLogin')

    if (isEmailUpdateRedirect) {
      dispatchUpdateToast({
        hideDuration: 12000,
        type: CONSTANTS.toastTypes.SUCCESS,
        label: t('pages:Login.toastProperties.label'),
        message: t('pages:Login.toastProperties.message'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />

      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        maxWidth="37rem"
        mx="auto"
        px={isDesktop ? '1.5rem' : '0.9rem'}
        my={isDesktop ? '3rem' : '2.5rem'}
      >
        <Heading align="center" variant="h2">
          {t('pages:Login.label')}
        </Heading>

        <Box
          mt="2rem"
          width="100%"
          borderRadius="12px"
          p={isDesktop ? '2.7rem' : '1.7rem'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.whiteBox}
        >
          <Grid container spacing={1} justifyContent="center">
            <Box p="0.4125rem">
              <Grid item>
                <GoogleLoginButton
                  type={oauthType}
                  provider={CONSTANTS.oauth.providers.GOOGLE}
                />
              </Grid>
            </Box>
          </Grid>

          <Box pt="2rem" pb="1.1rem" display="flex" justifyContent="center">
            <Text size="xs" align="center" className={classes.divider}>
              {t('common:or')}
            </Text>
          </Box>

          <Box
            width="100%"
            component="form"
            onSubmit={handleSubmit}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            {status === statusStates.ERROR && (
              <Box
                mb="0.5rem"
                width="100%"
                display="flex"
                justifyContent="center"
              >
                <Text align="center" color={themeColorTypes.RED}>
                  {t('validation:validation.incorrectEmailPassword')}
                </Text>
              </Box>
            )}

            <Box width="100%">
              <TextInput
                type="email"
                placeholder={t('common:emailAddress')}
                value={email}
                validationMessage=""
                onInputChange={(val) => setEmail(val)}
                isInvalid={!validEmail && email.length > 5}
              />
            </Box>

            <Box mt="0.25rem" mb="1.1rem" width="100%">
              <TextInput
                type="password"
                value={password}
                placeholder={t('common:password')}
                passwordVisibility
                onInputChange={(val) => setPassword(val)}
              />
            </Box>

            <Box>
              <Button
                working={status === statusStates.WORKING}
                size="lg"
                type="submit"
                label={
                  <Text size="lg" weight="medium" color={themeColorTypes.WHITE}>
                    {t('common:logIn')}
                  </Text>
                }
                variant="contained"
                disabled={
                  !validEmail ||
                  password.length < 8 ||
                  status === statusStates.WORKING
                }
                className={classes.logInButton}
              />
            </Box>
          </Box>
        </Box>

        <Box mt={isDesktop ? '1.5rem' : '1.4rem'}>
          <Link to="/forgot-password">
            <Button size="sm" label={t('pages:Login.forgotPassword')} />
          </Link>
        </Box>

        <Box
          display="flex"
          width="100%"
          flexDirection={isDesktop ? 'row' : 'column'}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          mt={isDesktop ? '2.9rem' : '2.4rem'}
        >
          <Box>
            <Text size="xs" weight="bold">
              {t('pages:Login.noAccount')}
            </Text>
          </Box>

          <Box
            component="span"
            mt={isDesktop ? '0' : '0.75rem'}
            ml={isDesktop ? '0.9rem' : '0'}
          >
            <Link to="/questionnaire">
              <Button
                color={themeColorTypes.NEUTRAL}
                colorVariant={themeColorVariants.DARK}
                data-testid="login-page-get-started-button"
                variant="contained"
                label={
                  <Box py="0.16rem" component="span">
                    <Text size="xs" weight="bold">
                      {t('pages:Login.getStarted')}
                    </Text>
                  </Box>
                }
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  )
}

Login.propTypes = {
  loginRedirect: PropTypes.string,
  dispatchUpdateToast: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  loginRedirect: commonSelectors.getLoginRedirect(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateToast: (toastProperties) =>
    dispatch(commonActions.updateToast(toastProperties)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
