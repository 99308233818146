export default {
  corporateExecutorBenefits: {
    type: 'accordion',
    header: { tKey: 'sidebar:corporateExecutorBenefits.header' },
    children: [
      {
        type: 'youtube',
        youtubeId: 'k7_6riQ5yFw',
        caption: {
          tKey: 'sidebar:corporateExecutorBenefits.children.item1.caption',
        },
      },
    ],
  },
  whyChargeFees: {
    type: 'accordion',
    header: { tKey: 'sidebar:whyChargeFees.header' },
    children: [
      {
        type: 'text',
        body: { tKey: 'sidebar:whyChargeFees.children.item1.body' },
      },
      {
        type: 'text',
        body: { tKey: 'sidebar:whyChargeFees.children.item2.body' },
      },
    ],
  },
  howMuchWillTrustCompanyCharge: {
    type: 'accordion',
    header: { tKey: 'sidebar:howMuchWillTrustCompanyCharge.header' },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:howMuchWillTrustCompanyCharge.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:howMuchWillTrustCompanyCharge.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:howMuchWillTrustCompanyCharge.children.item3.body',
        },
      },
      {
        type: 'rtCalculator',
      },
    ],
  },
  whenDoesTrustCompanyGetPaid: {
    type: 'accordion',
    header: { tKey: 'sidebar:whenDoesTrustCompanyGetPaid.header' },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:whenDoesTrustCompanyGetPaid.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:whenDoesTrustCompanyGetPaid.children.item2.body',
        },
      },
    ],
  },
  changeMindOnTrustCompany: {
    type: 'accordion',
    header: { tKey: 'sidebar:changeMindOnTrustCompany.header' },
    children: [
      {
        type: 'text',
        body: { tKey: 'sidebar:changeMindOnTrustCompany.children.item1.body' },
      },
    ],
  },
  contact: {
    type: 'corporateExecutorContactForm',
    header: { tKey: 'sidebar:contact.header' },
    body: { tKey: 'sidebar:contact.body' },
  },
}
