import React from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { useTranslation } from 'react-i18next'

import QuickRadioField from '../../Field/QuickRadioField/QuickRadioField'
import Link from './components/Link'

const QuickRadioWithLink = ({
  question,
  answerStore,
  answerCache,
  handleFieldFragmentUpdate,
}) => {
  const theQuickRadioField = question.fields.find(
    (field) => field.type === 'quickRadio',
  )

  const { t } = useTranslation()
  const { linkLabel, linkIcon, linkHref } = question

  return (
    <div className="flex flex-col items-center">
      <div className="mb-8" data-testid="quick-radio-with-link-anchor">
        <Link
          /*
           * If linkLabel is an objec with a tKey property, translate it.
           * Otherwise, use the string as is.
           */
          label={t(_get(linkLabel, 'tKey', linkLabel))}
          icon={linkIcon}
          href={linkHref}
        />
      </div>

      <QuickRadioField
        field={theQuickRadioField}
        answerStore={answerStore}
        answerCache={answerCache}
        handleFieldFragmentUpdate={handleFieldFragmentUpdate}
      />
    </div>
  )
}

QuickRadioWithLink.propTypes = {
  answerCache: PropTypes.shape({}).isRequired,
  answerStore: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  question: PropTypes.shape({
    linkIcon: PropTypes.bool,
    linkHref: PropTypes.string,
    linkLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
}

export default QuickRadioWithLink
