import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../../../../../common/components/atoms/TextInput'

const TextAreaField = ({
  field,
  answerCache,
  handleFieldFragmentUpdate,
  isInvalid = false,
  validationMessage,
}) => {
  const {
    name,
    placeholder,
    placeholder2,
    optional,
    rows,
    maxLength,
    characterCount,
  } = field

  const [fieldValue, setFieldValue] = useState(answerCache[name] || '')

  return (
    <TextInput
      validationMessage={validationMessage}
      optional={optional}
      characterCount={characterCount}
      value={fieldValue}
      maxLength={maxLength}
      variant="outlined"
      type="textarea"
      rows={rows}
      isInvalid={isInvalid}
      placeholder={placeholder || ''}
      placeholder2={placeholder2 || ''}
      onInputChange={(value) => {
        setFieldValue(value)
        handleFieldFragmentUpdate({ [name]: value.trim() })
      }}
    />
  )
}

TextAreaField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    placeholder2: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
    optional: PropTypes.bool,
    characterCount: PropTypes.bool,
    rows: PropTypes.string,
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  validationMessage: PropTypes.node.isRequired,
}

export default TextAreaField
