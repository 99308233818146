import React, { useEffect, useState } from 'react'

import { Box, useMediaQuery } from '@mui/material'

import Modal from '../../../../../../../common/components/atoms/Modal'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import BonusDocQuestionnaire from '../BonusDocQuestionnaire'

interface Props {
  docType: any
  show: boolean
  onClose: () => void
}

const BonusDocQuestionnaireModal = ({ show, onClose, docType }: Props) => {
  const [preventClose, setPreventClose] = useState(false)

  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.down('xl'))
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  useEffect(() => {
    if (show) amplitude.sendEvent('ViewedBonusDocQuestionnaireModal')
  }, [show])

  return (
    <Modal
      fullBleed
      show={show}
      onClose={onClose}
      preventClose={preventClose}
      data-testid="bonus-doc-questionnaire-modal"
    >
      <Box
        data-testid="bonus-doc-questionnaire"
        sx={{
          pt: '5rem',
          pb: '10rem',
          px: isMobile ? '1.5rem' : isTablet ? '3rem' : '8.5rem',
          backgroundColor:
            themeColors[themeColorTypes.NEUTRAL][themeColorVariants.LIGHTER],
        }}
      >
        <BonusDocQuestionnaire
          onClose={onClose}
          docType={docType}
          onWorking={setPreventClose}
        />
      </Box>
    </Modal>
  )
}

export default BonusDocQuestionnaireModal
