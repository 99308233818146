import React, { useState } from 'react'
import PropTypes from 'prop-types'

import _orderBy from 'lodash/orderBy'

import { Box, Grid } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as GlassesIcon } from '../../../../../../../../common/assets/images/glasses-icon.svg'
import Text from '../../../../../../../../common/components/atoms/Text'
import PreviewDocButton from './PreviewDocButton'

const PreviewDocuments = ({
  docTypes,
  documents,
  dispatchSetGlobalErrorMessage,
  answerStore: { firstName, province },
}) => {
  const [display, setDisplay] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      {display ? (
        <Grid
          container
          spacing={2.5}
          initial="hidden"
          animate="visible"
          rowSpacing={1.75}
          component={motion.div}
          data-testid="preview-documents-active-wrapper"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
          }}
        >
          {_orderBy(documents, ['docType'], ['desc'])
            // filter to only show preview of docs being paid for
            .filter((doc) => docTypes.includes(doc.docType) && doc.hasPreview)
            .map((d) => (
              <Grid
                item
                key={d._id}
                component={motion.div}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: -20 },
                }}
              >
                <PreviewDocButton
                  document={d}
                  province={province}
                  dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
                />
              </Grid>
            ))}
        </Grid>
      ) : (
        <Box
          onClick={() => setDisplay(true)}
          data-testid="preview-documents-trigger"
          sx={{
            display: 'flex',
            cursor: 'pointer',
            width: 'fit-content',
            alignItems: 'center',
          }}
        >
          <Box sx={{ minWidth: '32px' }}>
            <GlassesIcon />
          </Box>

          <Text
            weight="medium"
            sx={{
              ml: '0.5rem',
              display: 'initial',
              fontSize: '0.93rem',
              letterSpacing: '0.2px',
              textDecoration: 'underline',
            }}
          >
            {firstName
              ? t(
                  'payment:components.DocumentsDetails.components.PreviewDocuments.initial.label.case1',
                  { name: firstName },
                )
              : t(
                  'payment:components.DocumentsDetails.components.PreviewDocuments.initial.label.case2',
                )}
          </Text>
        </Box>
      )}
    </>
  )
}

PreviewDocuments.propTypes = {
  answerStore: PropTypes.shape({
    firstName: PropTypes.string,
    province: PropTypes.string,
  }).isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  docTypes: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(CONSTANTS.docTypes)),
  ).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default PreviewDocuments
