import _has from 'lodash/has'
import _isEmpty from 'lodash/isEmpty'
import _partition from 'lodash/partition'
import _take from 'lodash/take'

import featureFlag, {
  flagTypes,
} from '../../../../../../common/utils/featureFlag'

export default (theData, triggeredQuestions, answerStore) => {
  const sectionsObj = {}

  try {
    // iterate over each section
    for (const section of theData) {
      /*
       * Get the section's triggered 'intro' question object if exists.
       * Otherwise, return
       */

      const sectionIntroQuestion = triggeredQuestions.find(
        (q) => q.section === section.id && q.type === 'intro',
      )

      const sectionDescription = section.description

      // only if the section doesn't already exist on the object
      if (!_has(sectionsObj, section.id)) {
        /*
         * Split the triggered section questions that have fields and are required
         * versus those that are optional
         */
        const [requiredQuestions, optionalQuestions] = _partition(
          triggeredQuestions.filter(
            (q) => q.section === section.id && !_isEmpty(q.fields),
          ),
          (q) => !q.optional,
        )

        let unansweredRequiredQuestions
        let unansweredOptionalQuestions
        // only if the section has triggered questions (required or optional)
        if (!_isEmpty(requiredQuestions) || !_isEmpty(optionalQuestions)) {
          /*
           * Iterates over each triggered question in the section and
           * splits the unanswered fields between those that are
           * required versus those that are optional
           */
          const [
            theUnansweredRequiredQuestions,
            theUnansweredOptionalQuestions,
          ] = _partition(
            [...requiredQuestions, ...optionalQuestions].filter((q) =>
              q.fields.find((f) => !_has(answerStore, f.name) && !f.optional),
            ),
            (f) => !f.optional,
          )

          unansweredRequiredQuestions = theUnansweredRequiredQuestions
          unansweredOptionalQuestions = theUnansweredOptionalQuestions
        }

        // if there are unanswered required questions, return the length
        const unansweredRequired = unansweredRequiredQuestions
          ? unansweredRequiredQuestions.length
          : null

        // if there are unanswered optional questions, return the length
        const unansweredOptional = unansweredRequiredQuestions
          ? unansweredOptionalQuestions.length
          : null

        /*
         * If there are only optionalQuestions, take first item in optionalQuestions array.
         * If there are unanswered questions, take the first item in that array.
         * If the requiredQuestions array is not empty, we assume the section is complete
         * and take the first question, otherwise nextQuestion will be undefined
         */
        const [nextQuestion] =
          _isEmpty(requiredQuestions) && !_isEmpty(optionalQuestions)
            ? _take(optionalQuestions)
            : !_isEmpty(unansweredRequiredQuestions)
              ? _take(unansweredRequiredQuestions)
              : _take(requiredQuestions)

        // return the section's intro question if it has a highlightPillText property
        const sectionFirstQuestion = section.questions.find(
          (q) => q.type === 'intro' && q.highlightPillText,
        )

        // adds new section to the sectionsObj
        sectionsObj[section.id] = {
          totalRequired: requiredQuestions.length,
          totalOptional: optionalQuestions.length,
          unansweredRequired,
          unansweredOptional,
          nextQuestion,
          sectionIntroQuestion,
          description: sectionDescription || undefined,
          sectionTime: sectionFirstQuestion
            ? sectionFirstQuestion.highlightPillText
            : undefined,
        }
      }
    }
    return sectionsObj
  } catch (error) {
    if (featureFlag(flagTypes.SENTRY)) {
      window.captureException(error, {
        message: 'Something blew up in the sectionGrouping.js file',
        sectionsObj,
        theData,
        triggeredQuestions,
        answerStore,
      })
      return {}
    }
    return undefined
  }
}
