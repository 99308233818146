import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { IconButton as MuiIconButton } from '@mui/material'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'

const useStyles = makeStyles((theme) => ({
  root: {
    color: ({ color, colorVariant }) =>
      _get(theme, ['palette', color, colorVariant]) ||
      themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],

    '&:hover': {
      backgroundColor: ({ color, colorVariant }) =>
        alpha(
          _get(theme, ['palette', color, colorVariant]) ||
            themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
          0.08,
        ),
    },
  },
}))

const IconButton = forwardRef(
  (
    {
      color = themeColorTypes.BRAND,
      onClick,
      className,
      colorVariant = themeColorVariants.MAIN,
      IconComponent,
      'data-testid': dataTestId,
      ...restProps
    },
    ref,
  ) => {
    const classes = useStyles({ color, colorVariant })

    return (
      <MuiIconButton
        {...restProps}
        ref={ref}
        disableRipple
        disableFocusRipple
        onClick={onClick}
        className={className}
        data-testid={dataTestId}
        classes={{ root: classes.root }}
      >
        {IconComponent}
      </MuiIconButton>
    )
  },
)

IconButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  IconComponent: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(themeColorTypes)),
  colorVariant: PropTypes.oneOf(Object.values(themeColorVariants)),
}

export default IconButton
