import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import Header from '../../common/components/molecules/Header'
import navigationActions from '../Questionnaire/ducks/navigation/navigationActions'
import navigationSelectors from '../Questionnaire/ducks/navigation/navigationSelectors'
import questionnaireSelectors from '../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import dashboardActions from './ducks/dashboard/dashboardActions'
import Router from './Router'

const Dashboard = ({
  dashboardRedirect,
  questionnaireValid,
  dispatchBeforeDashboard,
  dispatchDashboardRedirect,
  editQuestionnaireFromDashboard,
  dispatchEditQuestionnaireFromDashboard,
}) => {
  useEffect(() => {
    if (dashboardRedirect) {
      dispatchDashboardRedirect(false)
    }

    dispatchBeforeDashboard()

    // prevent back button from anywhere in the dashboard
    window.history.pushState(null, null, '')
    window.addEventListener('popstate', () => {
      window.history.pushState(null, null, '')
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    /*
     * When the user has seen the dashboard AND they have a
     * valid questionnaire, we flip this flag to true
     */
    if (!editQuestionnaireFromDashboard && questionnaireValid) {
      dispatchEditQuestionnaireFromDashboard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireValid])

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: '0', width: '100%' }}>
        <Header />
      </Box>

      <Router />
    </Box>
  )
}

Dashboard.propTypes = {
  dashboardRedirect: PropTypes.bool,
  editQuestionnaireFromDashboard: PropTypes.bool.isRequired,
  questionnaireValid: PropTypes.bool,
  dispatchDashboardRedirect: PropTypes.func.isRequired,
  dispatchEditQuestionnaireFromDashboard: PropTypes.func.isRequired,
  dispatchBeforeDashboard: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  editQuestionnaireFromDashboard:
    navigationSelectors.getEditQuestionnaireFromDashboard(state.questionnaire),
  dashboardRedirect: navigationSelectors.getDashboardRedirect(
    state.questionnaire,
  ),
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchEditQuestionnaireFromDashboard: () =>
    dispatch(navigationActions.editQuestionnaireFromDashboard()),
  dispatchDashboardRedirect: (redirect) =>
    dispatch(navigationActions.dashboardRedirect(redirect)),
  dispatchBeforeDashboard: () => dispatch(dashboardActions.beforeDashboard()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
