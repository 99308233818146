import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { CONSTANTS } from '@epilogue/common'

import commonActions from '../ducks/commonActions'
import commonSelectors from '../ducks/commonSelectors'

const WorldAuthRedirectRoute = ({
  auth,
  world,
  authType,
  children,
  exactWorldType,
  availableWorlds,
  dispatchSetLoginRedirect,
}) => {
  const location = useLocation()
  // Auth required, but user not authed, send to /login
  if (authType === 'required' && !auth) {
    dispatchSetLoginRedirect(location.pathname + location.search)

    return <Navigate to="/login" replace />
  }

  const worldMatch =
    world &&
    (!exactWorldType ||
      (exactWorldType === world && availableWorlds.includes(world)))

  if (
    worldMatch &&
    ((authType === 'forbidden' && !auth) || (authType === 'required' && auth))
  ) {
    return children
  }

  return <Navigate to="/redirect" replace />
}

WorldAuthRedirectRoute.propTypes = {
  world: PropTypes.string,
  auth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  dispatchSetLoginRedirect: PropTypes.func.isRequired,
  authType: PropTypes.oneOf(['required', 'forbidden']).isRequired,
  exactWorldType: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.type),
  ),
  availableWorlds: PropTypes.arrayOf(
    PropTypes.oneOf(
      Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.type),
    ),
  ).isRequired,
}

const mapStateToProps = (state) => ({
  auth: commonSelectors.getAuth(state),
  world: commonSelectors.getWorld(state),
  availableWorlds: commonSelectors.getAvailableWorlds(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSetLoginRedirect: (loginRedirect) =>
    dispatch(commonActions.setLoginRedirect(loginRedirect)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorldAuthRedirectRoute)
