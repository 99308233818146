export default (arr) => {
  const str = arr.reduce(
    (acc, currentVal) =>
      `${acc}${currentVal.name} (${
        currentVal.predeceasedSplit === 'splitChildren'
          ? 'split among children'
          : 'split among beneficiaries'
      }), `,
    '',
  )
  return str.slice(0, -2)
}
