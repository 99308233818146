import { useState } from 'react'

import { CONSTANTS } from '@epilogue/common'

import { errorHandler, PATHS, request } from '../../../request'

export enum DownloadStatus {
  IDLE = 'idle',
  ERROR = 'error',
  WORKING = 'working',
  SUCCESS = 'success',
}

export default ({
  onSuccess,
  dispatchSetGlobalErrorMessage,
}: {
  onSuccess?: () => void
  dispatchSetGlobalErrorMessage: (message: string) => void
}) => {
  const [status, setStatus] = useState<DownloadStatus>(DownloadStatus.IDLE)
  const [file, setFile] = useState<any>()

  const handleDownloadPdfPreviewDoc = async ({
    docId,
    docType,
  }: {
    docId?: string
    docType?: string
  }) => {
    setStatus(DownloadStatus.WORKING)

    try {
      const res = await request({
        method: 'GET',
        responseType: 'arraybuffer',
        params: { type: CONSTANTS.documentDownloadEventTypes.PAID_PREVIEW },
        url: docId
          ? `${PATHS.DOCUMENT_DOWNLOAD_DOC}/${docId}`
          : `${PATHS.DOCUMENT_DOWNLOAD_STATIC_DOC}/${docType}`,
      })

      setFile(res)
      onSuccess?.()
      setStatus(DownloadStatus.SUCCESS)
    } catch (error) {
      setStatus(DownloadStatus.ERROR)
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to download preview. Please refresh and try again.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    }
  }

  return { DownloadStatus, status, file, handleDownloadPdfPreviewDoc }
}
