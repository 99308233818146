import { useMutation } from '@tanstack/react-query'

import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../../common/request'
import { Appointment } from '../../../../../../../../../types/appointment.types'

interface Props {
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

interface MailingAddress {
  [key: string]: string
}

interface RequestProps {
  end: string
  start: string
  mailingAddress?: MailingAddress
  appointmentType: Appointment['type']
  timezone: Appointment['userTimezone']
}

export default ({ dispatchSetGlobalErrorMessage }: Props) =>
  useMutation({
    mutationFn: ({
      start,
      end,
      timezone,
      mailingAddress,
      appointmentType,
    }: RequestProps) =>
      request({
        method: 'POST',
        data: { start, end, timezone, metadata: { mailingAddress } },
        url: `${PATHS.APPOINTMENT}/${appointmentType}`,
      }),
    onError: (error) =>
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to schedule appointment. Please try again later.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      }),
  })
