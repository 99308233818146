import navigationName from './navigationName'

export default {
  NEXT_QUESTION: `${navigationName}/NEXT_QUESTION`,
  PREVENT_NEXT: `${navigationName}/PREVENT_NEXT`,
  PREVENT_PREVIOUS: `${navigationName}/PREVENT_PREVIOUS`,
  PREVIOUS_QUESTION: `${navigationName}/PREVIOUS_QUESTION`,
  PUSH_QUESTION: `${navigationName}/PUSH_QUESTION`,

  TO_QUESTION: `${navigationName}/TO_QUESTION`,

  UPDATE_QUESTION_STACK: `${navigationName}/UPDATE_QUESTION_STACK`,

  NAV_NEXT_AUTOMATIC: `${navigationName}/NAV_NEXT_AUTOMATIC`,

  UPDATE_SECTION_PROGRESS: `${navigationName}/UPDATE_SECTION_PROGRESS`,
  UPDATE_QUESTIONNAIRE_PROGRESS: `${navigationName}/UPDATE_QUESTIONNAIRE_PROGRESS`,

  DASHBOARD_REDIRECT: `${navigationName}/DASHBOARD_REDIRECT`,

  PREVENT_ANIMATION: `${navigationName}/PREVENT_ANIMATION`,

  WORKING: `${navigationName}/WORKING`,

  SET_NEXT_UNANSWERED_QUESTION: `${navigationName}/SET_NEXT_UNANSWERED_QUESTION`,

  EDIT_QUESTIONNAIRE_FROM_DASHBOARD: `${navigationName}/EDIT_QUESTIONNAIRE_FROM_DASHBOARD`,

  INTERCEPT_MODAL: `${navigationName}/INTERCEPT_MODAL`,
}
