import { useMutation } from '@tanstack/react-query'

import amplitude from '../../../../../../../lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../../../../request'

export default ({ dispatchSetGlobalErrorMessage }) => {
  const { mutate: handleDeleteAccount, isLoading: working } = useMutation({
    mutationFn: () => request({ method: 'DELETE', url: PATHS.USER }),
    onSuccess: () => {
      amplitude.sendEvent('DeletedAccount')
      amplitude.setUserId(null) // Unset email as Amplitude userId

      window.location.href = '/login'
    },
    onError: (error) => {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to delete account. Please try again later.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    },
  })
  return [handleDeleteAccount, working]
}
