"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ({ addOns, addOnType, addOnCategory, }) => {
    // One of addOnType or addOnCategory must exist
    if (!addOnType && !addOnCategory)
        return false;
    // Both addOnType and addOnCategory cannot exist
    if (addOnType && addOnCategory)
        return false;
    if (addOnType) {
        return addOns.some((addOn) => addOn.type === addOnType);
    }
    return addOns.some((addOn) => addOn.category === addOnCategory);
};
