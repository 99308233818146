import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'
import { useMutation } from '@tanstack/react-query'
import cn from 'classnames'
import { Button, Text } from 'grommet'
import { FormNextLink } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import UpdateDocumentsModal from '../../../../scenes/Dashboard/components/views/Dashboard/components/UpdateDocumentsModal'
import WitnessingPreventGenerateModal from '../../../../scenes/Dashboard/components/views/Documents/components/modals/WitnessingPreventGenerateModal'
import appointmentsSelectors from '../../../../scenes/Dashboard/ducks/appointments/appointmentsSelectors'
import dashboardSelectors from '../../../../scenes/Dashboard/ducks/dashboard/dashboardSelectors'
import documentsActions from '../../../../scenes/Dashboard/ducks/documents/documentsActions'
import documentsSelectors from '../../../../scenes/Dashboard/ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../../scenes/Dashboard/ducks/payments/paymentsSelectors'
import hasCoreDocument from '../../../../scenes/Dashboard/utils/hasCoreDocument'
import data from '../../../../scenes/Questionnaire/data'
import navigationActions from '../../../../scenes/Questionnaire/ducks/navigation/navigationActions'
import navigationSelectors from '../../../../scenes/Questionnaire/ducks/navigation/navigationSelectors'
import questionnaireActions from '../../../../scenes/Questionnaire/ducks/questionnaire/questionnaireActions'
import questionnaireSelectors from '../../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import commonActions from '../../../ducks/commonActions'
import amplitude from '../../../lib/amplitude'
import { errorHandler, PATHS, request } from '../../../request'
import { witnessingProvinces } from '../../../utils/provinces'
import LoadingSpinner from '../../atoms/LoadingSpinner'

const EverythingButton = ({
  payments,
  documents,
  answerStore,
  questionnaireValid,
  dispatchUpdateToast,
  dispatchToQuestion,
  howDidYouHearAboutUs,
  fetchDocumentsWorking,
  nextUnansweredQuestion = {},
  dispatchFetchDocuments,
  willQuestionnaireStarted,
  dispatchSetGlobalErrorMessage,
  pendingWitnessingAppointments,
  answerStoreUpdatedSinceGenerate,
  dispatchGenerateDocumentsWorking,
  dispatchSetAnswerStoreUpdatedSinceGenerate,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const coreDocument = hasCoreDocument(documents)
  const hasPayments = !_isEmpty(payments)
  const hasPendingWitnessingAppointments = !_isEmpty(
    pendingWitnessingAppointments,
  )
  const isUpToDate =
    hasPayments &&
    coreDocument &&
    questionnaireValid &&
    !answerStoreUpdatedSinceGenerate

  const { province } = answerStore

  const [showUpdateDocumentsModal, setShowUpdateDocumentsModal] =
    useState(false)

  const [
    showWitnessingPreventGenerateModal,
    setShowWitnessingPreventGenerateModal,
  ] = useState(false)

  const [firstQuestion, setFirstQuestion] = useState()

  useEffect(() => {
    if (!_isEmpty(data[0].questions)) {
      // The first question that gets asked in the questionnaire
      setFirstQuestion(data[0].questions[0])
    }
  }, [])

  const { mutate: handleGenerate } = useMutation({
    mutationFn: () =>
      request({
        method: 'POST',
        timeout: 30000, // 30 second timeout
        url: PATHS.DOCUMENT_GENERATE_DOCS,
        data: { answerStore },
      }),
    retry: 2,
    onMutate: () => dispatchGenerateDocumentsWorking(true),
    onSettled: () => dispatchGenerateDocumentsWorking(false),
    onError: (error) => {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to generate documents. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) => {
          // eslint-disable-next-line no-console
          console.error('Generation of documents failed 🚫')
          dispatchSetGlobalErrorMessage(resolvedErrorMessage)
        },
      })
    },
    onSuccess: () => {
      dispatchUpdateToast({
        type: CONSTANTS.toastTypes.SUCCESS,
        label: t('molecules:EverythingButton.toast.label'),
        message: t('molecules:EverythingButton.toast.message'),
      })

      dispatchSetAnswerStoreUpdatedSinceGenerate()
      // fetch the NEWLY created documents
      dispatchFetchDocuments()
      // eslint-disable-next-line no-console
      console.log('Generation of documents success ✅')
      amplitude.sendEvent('GeneratedWillDocuments')

      if (hasPayments) {
        navigate('/dashboard/documents')
      } else {
        /*
         * We only want the user to see the 'how did you hear about us' question
         * once, so if they already have an answer for it, we send to /add-ons/printing.
         * If not, we send to /how-did-you-hear-about-us
         */
        navigate(
          `/dashboard${
            howDidYouHearAboutUs
              ? '/add-ons/printing'
              : '/how-did-you-hear-about-us'
          }`,
        )
      }
    },
  })

  return (
    <>
      {fetchDocumentsWorking ? (
        <LoadingSpinner size="34px" />
      ) : (
        <div className="flex flex-col items-center">
          <Button
            primary
            reverse
            data-testid="everything-button"
            color={isUpToDate ? 'neutral-2' : 'accent-1'}
            icon={
              <div className={cn({ 'hidden 2xs:block': isUpToDate }, '-ml-5')}>
                <FormNextLink color={isUpToDate ? 'brand' : 'white'} />
              </div>
            }
            label={
              <div
                className={cn(
                  'py-1',
                  isUpToDate ? '-mr-3 px-0 2xs:mr-0 2xs:px-3' : 'px-3',
                )}
              >
                <Text
                  weight={500}
                  size="smedium"
                  color={isUpToDate ? 'brand' : 'white'}
                >
                  {!willQuestionnaireStarted
                    ? t('molecules:EverythingButton.getStarted')
                    : !questionnaireValid
                      ? t('molecules:EverythingButton.continueQuestionnaire')
                      : !coreDocument
                        ? t('molecules:EverythingButton.generateDocuments')
                        : coreDocument && answerStoreUpdatedSinceGenerate
                          ? t('molecules:EverythingButton.reGenerateDocuments')
                          : !hasPayments
                            ? t('molecules:EverythingButton.completePayment')
                            : t('molecules:EverythingButton.updateMyDocuments')}
                </Text>
              </div>
            }
            onClick={() => {
              if (!willQuestionnaireStarted) {
                // send user to first question of questionnaire
                dispatchToQuestion(firstQuestion)

                amplitude.sendEvent('ClickedEverythingButton', {
                  state: 'Get Started',
                })

                navigate('/questionnaire')
              }

              // go to next unanswered question
              else if (!questionnaireValid) {
                amplitude.sendEvent('ClickedEverythingButton', {
                  state: 'Continue Questionnaire',
                })

                // update questionStack to point at nextUnansweredQuestion
                dispatchToQuestion(nextUnansweredQuestion)
                // send user to questionnaire
                navigate('/questionnaire')

                // generate documents here
              } else if (
                !coreDocument ||
                (coreDocument && answerStoreUpdatedSinceGenerate)
              ) {
                amplitude.sendEvent('ClickedEverythingButton', {
                  state: !coreDocument
                    ? 'Generate Documents'
                    : 'Re-Generate Documents',
                })

                if (
                  witnessingProvinces.includes(province) &&
                  hasPendingWitnessingAppointments
                ) {
                  setShowWitnessingPreventGenerateModal(true)
                } else {
                  handleGenerate()
                }
              } else if (!hasPayments) {
                amplitude.sendEvent('ClickedEverythingButton', {
                  state: 'Complete Payment',
                })

                navigate('/dashboard/add-ons/printing')
              } else {
                amplitude.sendEvent('ClickedEverythingButton', {
                  state: 'Update My Documents',
                })

                setShowUpdateDocumentsModal(true)
              }
            }}
          />
        </div>
      )}

      {/* 
        TODO: Fix this so that we don't need this conditional based on the 
        react environment. The reason this is here is because it was breaking
        tests and this was the fastest solution. This should be handled properly,
        although this solution should definitely work long-term, if necessary.
      */}
      {(window.Cypress || process.env.REACT_APP_ENV !== 'test') && (
        <UpdateDocumentsModal
          show={showUpdateDocumentsModal}
          handleOptionClick={() => {
            setShowUpdateDocumentsModal(false)
          }}
          handleSetShow={(bool) => {
            setShowUpdateDocumentsModal(bool)
          }}
        />
      )}

      <WitnessingPreventGenerateModal
        show={showWitnessingPreventGenerateModal}
        onClose={() => setShowWitnessingPreventGenerateModal(false)}
      />
    </>
  )
}

EverythingButton.propTypes = {
  questionnaireValid: PropTypes.bool,
  howDidYouHearAboutUs: PropTypes.string,
  nextUnansweredQuestion: PropTypes.shape({}),
  dispatchUpdateToast: PropTypes.func.isRequired,
  answerStoreUpdatedSinceGenerate: PropTypes.bool,
  dispatchToQuestion: PropTypes.func.isRequired,
  fetchDocumentsWorking: PropTypes.bool.isRequired,
  dispatchFetchDocuments: PropTypes.func.isRequired,
  willQuestionnaireStarted: PropTypes.bool.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  dispatchSetAnswerStoreUpdatedSinceGenerate: PropTypes.func,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatchGenerateDocumentsWorking: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pendingWitnessingAppointments: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
}

const mapStateToProps = (state) => ({
  payments: paymentsSelectors.getPayments(state.dashboard),
  pendingWitnessingAppointments:
    appointmentsSelectors.getPendingWitnessingAppointments(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  documents: documentsSelectors.getDocuments(state.dashboard),
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
  fetchDocumentsWorking: documentsSelectors.getFetchDocumentsWorking(
    state.dashboard,
  ),
  nextUnansweredQuestion: navigationSelectors.getNextUnansweredQuestion(
    state.questionnaire,
  ),
  answerStoreUpdatedSinceGenerate:
    questionnaireSelectors.getAnswerStoreUpdatedSinceGenerate(
      state.questionnaire,
    ),
  willQuestionnaireStarted: questionnaireSelectors.getQuestionnaireStarted(
    state.questionnaire,
  ),
  howDidYouHearAboutUs: dashboardSelectors.getHowDidYouHearAboutUs(
    state.dashboard,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchDocuments: () => dispatch(documentsActions.fetchDocuments()),
  dispatchGenerateDocumentsWorking: (bool) =>
    dispatch(documentsActions.generateDocumentsWorking(bool)),
  dispatchToQuestion: (question) =>
    dispatch(navigationActions.toQuestion({ question, fromDashboard: true })),
  dispatchSetAnswerStoreUpdatedSinceGenerate: () =>
    dispatch(
      questionnaireActions.setAnswerStoreUpdatedSinceGenerate({
        bool: false,
      }),
    ),
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
  dispatchUpdateToast: (toastProperties) =>
    dispatch(commonActions.updateToast(toastProperties)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EverythingButton)
