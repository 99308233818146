export const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/

export const postalOrZipCodeRegex =
  /(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$)|(^\d{5}$)|(^\d{5}-\d{4}$)/

// matches formatting style: September 14, 2012
export const dateRegex = /^([A-Z][A-z]{2,8}) ([1-9]|[12]\d|3[01]), (19|20)\d{2}/

export const phoneRegex = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im

export const emailRegex =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/

export const promoCodeRegex = /^[\d&A-Za-z-]+$/

export const camelCaseRegex = /^[a-z]+(?:[A-Z][a-z]*)*$/

// field-names to be prepended with the following pattern: [namespace]_
export const fieldNameRegex = /^([A-z]+_)?[a-z]+(?:[A-Z][a-z]*)*$/

// bonus document field-names to follow the pattern: [namespace]_[fieldName]
export const bonusDocFieldNameRegex = /^([A-z]+_)[a-z]+(?:[A-Z][a-z]*)*$/

export const pathnameRegex = /^[a-z-]+$/

export const onlyNumbersRegex = /^\d+$/

export const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\da-z]+([.-][\da-z]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/

export const gmailRegex = /@(gmail|googlemail).com$/i

export const paymentPagePathRegex = /^\/dashboard\/payment(\/?)$/
