import React, { useContext } from 'react'

import { Box, Drawer } from '@mui/material'

import { FormClose, Menu } from 'grommet-icons'

import HeaderContext from '../context/HeaderContext'
import NavItems from './NavItems'

const MobileNav = () => {
  const { accountCreated, mobileNavActive, setMobileNavActive } =
    useContext(HeaderContext)

  return (
    <>
      {accountCreated && (
        <>
          <span
            data-testid="hamburger-menu"
            tabIndex={0}
            role="button"
            className="cursor-pointer"
            onClick={() => setMobileNavActive(true)}
            onKeyDown={() => setMobileNavActive(true)}
            aria-label="Open mobile navigation menu"
          >
            <Menu />
          </span>

          <Drawer
            anchor="right"
            open={mobileNavActive}
            onClose={() => setMobileNavActive(false)}
          >
            <span
              aria-label="Close Menu Button"
              data-testid="close-mobile-nav-button"
              className="cursor-pointer absolute top-0 left-0 p-3"
              onClick={() => setMobileNavActive(false)}
              onKeyDown={() => setMobileNavActive(false)}
              role="button"
              tabIndex={0}
            >
              <FormClose color="brand" />
            </span>

            <Box
              sx={{
                pt: '5rem',
                pr: '2.5rem',
                pl: '0.5rem',
                display: 'flex',
                minWidth: '155px',
                flexDirection: 'column',
              }}
            >
              <NavItems isMobile />
            </Box>
          </Drawer>
        </>
      )}
    </>
  )
}

export default MobileNav
