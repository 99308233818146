import _get from 'lodash/get'
import _isArray from 'lodash/isArray'

import { CONSTANTS } from '@epilogue/common'

import dashboardName from './dashboardName'

export default {
  getDashboard: (state) => _get(state, 'dashboard'),
  getCodes: (state) => _get(state[dashboardName], 'codes'),
  getEmail: (state) => _get(state[dashboardName], 'email'),
  getUserId: (state) => _get(state[dashboardName], 'userId'),
  getIsPartner: (state) => _get(state[dashboardName], 'isPartner'),
  getMailingList: (state) => _get(state[dashboardName], 'mailingList'),
  getHowDidYouHearAboutUs: (state) =>
    _get(state[dashboardName], 'howDidYouHearAboutUs'),
  getCustomerReferralCode: (state) => {
    const { codes } = state[dashboardName]
    return _isArray(codes)
      ? codes.find(
          (codeObj) => codeObj.label === CONSTANTS.codeLabels.CUSTOMER_REFERRAL,
        )?.code
      : undefined
  },
  getNoticeConnectCode: (state) => {
    const { codes } = state[dashboardName]
    return _isArray(codes)
      ? codes.find(
          (codeObj) => codeObj.label === CONSTANTS.codeLabels.NOTICE_CONNECT,
        )?.code
      : undefined
  },
}
