import mingo from 'mingo'

import argumentRequired from '../../../common/utils/argumentRequired'

export default (
  trigger,
  {
    abTest,
    isPartner,
    referrer = argumentRequired('referrer'),
    answerStore = argumentRequired('answerStore'),
    charityPartner = argumentRequired('charityPartner'),
  },
) =>
  new mingo.Query(trigger).test({
    ...answerStore,
    charityPartner,
    isPartner,
    referrer,
    abTest,
  })
