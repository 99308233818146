import React from 'react'
import PropTypes from 'prop-types'

import { Anchor } from 'grommet'

import { ReactComponent as NewTab } from '../../../../../../common/assets/images/new-tab.svg'

const Link = ({ label, icon = true, href }) => (
  <div className="font-ff-mark tracking-tight flex items-end text-center">
    <Anchor
      reverse
      href={href}
      size="large"
      label={label}
      weight="normal"
      target="_blank"
      color="accent-1"
      rel="noopener noreferrer"
      icon={
        <>
          {icon && (
            <div className="-ml-1.5 w-6 h-6">
              <NewTab />
            </div>
          )}
        </>
      }
    />
  </div>
)

Link.propTypes = {
  icon: PropTypes.bool,
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default Link
