/*
 * This function finds the EARLIEST unanswered question in the data — which
 * is different from nextTriggeredQuestion because
 * nextTriggeredQuestion returns whatever triggered question comes soonest
 * after the current question.
 *
 * A good use-case for this function would be to determine whether or not
 * the questionnaire is complete.
 *
 * A good use-case for nextTriggeredQuestion is to determine
 * which question to display to a user when they click "Save & Continue" while in
 * the middle of the questionnaire.
 */

import currentQuestionIndex from './currentQuestionIndex'
import firstTriggeredQuestion from './firstTriggeredQuestion'
import isTriggeredAndAnsweredQuestion from './isTriggeredAndAnsweredQuestion'
import nextTriggeredQuestion from './nextTriggeredQuestion'

export default ({
  data,
  abTest,
  referrer,
  payments,
  isPartner,
  documents,
  answerStore,
  charityPartner,
  accountCreated,
}) => {
  let theNextQuestion

  do {
    /*
     * If we are checking the very first question in the data, theNextQuestion
     * will not exist, so we use firstTriggeredQuestion. For all subsequent
     * questions when theNextQuestion does exist, we use nextTriggeredQuestion.
     */
    theNextQuestion = theNextQuestion
      ? nextTriggeredQuestion({
          data,
          abTest,
          referrer,
          payments,
          isPartner,
          documents,
          answerStore,
          charityPartner,
          accountCreated,
          theCurrentQuestionIndex: currentQuestionIndex(theNextQuestion, data),
        })
      : firstTriggeredQuestion({
          data,
          abTest,
          referrer,
          payments,
          isPartner,
          documents,
          answerStore,
          charityPartner,
          accountCreated,
        })
  } while (
    theNextQuestion &&
    isTriggeredAndAnsweredQuestion({
      abTest,
      referrer,
      isPartner,
      answerStore,
      charityPartner,
      question: theNextQuestion,
    })
  )

  return theNextQuestion
}
