import React from 'react'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { enCA, frCA } from 'date-fns/locale'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../../common/components/atoms/Text'
import { SelectedTimeslot } from '../../../../../../../../../common/components/DatePicker/datePicker.types'
import commonActions from '../../../../../../../../../common/ducks/commonActions'
import event from '../../../../../../../../../common/lib/event'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../../common/styles/muiTheme'
import { Appointment } from '../../../../../../../../../types/appointment.types'
import addOnsActions from '../../../../../../../ducks/addOns/addOnsActions'
import appointmentsActions from '../../../../../../../ducks/appointments/appointmentsActions'
import dashboardSelectors from '../../../../../../../ducks/dashboard/dashboardSelectors'
import useScheduleAppointment from '../hooks/useScheduleAppointment'

interface MailingAddress {
  [key: string]: string
}

interface Props {
  userId: string
  onSuccess: () => void
  timeslot: SelectedTimeslot
  mailingAddress: MailingAddress
  dispatchSetAddOns: (addOns: any) => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
  dispatchSetAppointments: (appointments: Appointment[]) => void
}

const BookWitnessingConfirmView = ({
  userId,
  timeslot,
  onSuccess,
  mailingAddress,
  dispatchSetAddOns,
  dispatchSetAppointments,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const {
    timezone,
    timezoneAdjustedTimeslot,
    isoTimeslot: { start, end },
  } = timeslot

  const { isLoading: working, mutate: handleScheduleAppointment } =
    useScheduleAppointment({ dispatchSetGlobalErrorMessage })

  const { t } = useTranslation()

  return (
    <Box
      data-testid="book-witnessing-confirm-view"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Heading align="center" variant="h3" sx={{ mb: '2.2rem' }}>
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingConfirmView.label',
        )}
      </Heading>

      <Box>
        <Text
          size="xs"
          color={themeColorTypes.GREY}
          colorVariant={themeColorVariants.DARK}
          sx={{ display: 'block', mb: '0.35rem' }}
        >
          {t(
            'documents:components.modals.BookWitnessingModal.components.BookWitnessingConfirmView.date',
          )}
        </Text>
        <Text weight="medium">
          {format(
            new Date(timezoneAdjustedTimeslot.start),
            'EEEE, MMMM d, yyyy',
            {
              locale:
                i18n.language === CONSTANTS.languageTypes.FR_CA.id
                  ? frCA
                  : enCA,
            },
          )}
        </Text>
      </Box>

      <Box sx={{ my: '1.6rem' }}>
        <Text
          size="xs"
          color={themeColorTypes.GREY}
          colorVariant={themeColorVariants.DARK}
          sx={{ display: 'block', mb: '0.35rem' }}
        >
          {t(
            'documents:components.modals.BookWitnessingModal.components.BookWitnessingConfirmView.time',
          )}
        </Text>
        <Text weight="medium">
          {format(new Date(timezoneAdjustedTimeslot.start), 'h:mmaaa')} -{' '}
          {format(new Date(timezoneAdjustedTimeslot.end), 'h:mmaaa')}
        </Text>
      </Box>

      <Box>
        <Text
          size="xs"
          color={themeColorTypes.GREY}
          colorVariant={themeColorVariants.DARK}
          sx={{ display: 'block', mb: '0.35rem' }}
        >
          {t(
            'documents:components.modals.BookWitnessingModal.components.BookWitnessingConfirmView.mailingAddress',
          )}
        </Text>
        <Text weight="medium">{mailingAddress.name}</Text>
        <Text weight="medium">{mailingAddress.address1}</Text>
        {mailingAddress.address2 && (
          <Text weight="medium">{mailingAddress.address2}</Text>
        )}
        <Text weight="medium">
          {mailingAddress.city}, {mailingAddress.province}{' '}
          {mailingAddress.postalCode}
        </Text>
      </Box>

      <Box sx={{ mt: '2.9rem', mx: 'auto' }}>
        <Button
          working={working}
          variant="contained"
          label={t(
            'documents:components.modals.BookWitnessingModal.components.BookWitnessingConfirmView.buttonLabel',
          )}
          spinnerPadding="0.375rem 2.5977rem"
          data-testid="book-witnessing-confirm-view-submit-button"
          onClick={() =>
            handleScheduleAppointment(
              {
                end,
                start,
                timezone,
                mailingAddress,
                appointmentType: CONSTANTS.appointmentTypes.WITNESSING,
              },
              {
                onSuccess: ({ data }) => {
                  const addOns = _get(data, 'payload.addOns')
                  const appointments = _get(data, 'payload.appointments')

                  dispatchSetAddOns(addOns)
                  dispatchSetAppointments(appointments)

                  event.send({
                    name: CONSTANTS.eventNames.WITNESSING_APPOINTMENT,
                    action: CONSTANTS.eventActionTypes.BOOKED,
                    metadata: {
                      userId,
                      appointmentId: appointments[appointments.length - 1]._id,
                    },
                  })

                  onSuccess()
                },
              },
            )
          }
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  userId: dashboardSelectors.getUserId(state.dashboard),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
  dispatchSetAppointments: (appointments: Appointment[]) =>
    dispatch(appointmentsActions.setAppointments({ appointments })),
  dispatchSetAddOns: (addOns: any) => dispatch(addOnsActions.setAddOns(addOns)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookWitnessingConfirmView)
