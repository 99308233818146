import React from 'react'

import { connect } from 'react-redux'

import _sortBy from 'lodash/sortBy'

import { Box, Grid } from '@mui/material'

import { CONSTANTS, Document } from '@epilogue/common'
import { getCoreDocuments } from '@epilogue/common/dist/utils'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../../common/components/atoms/Heading'
import Text from '../../../../../../../../../common/components/atoms/Text'
import useProvinceDictionary from '../../../../../../../../../common/hooks/useProvinceDictionary'
import { ONTARIO_TERMS } from '../../../../../../../../../common/utils/provinceDictionary'
import questionnaireSelectors from '../../../../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import documentsSelectors from '../../../../../../../ducks/documents/documentsSelectors'

interface Props {
  documents: Document[]
  answerStore: { [key: string]: any }
  onSelection: (selection: 'yes' | 'no') => void
}

const docTypeMap = (t: any) => ({
  [CONSTANTS.docTypes.WILL]: t('common:will'),
  [CONSTANTS.docTypes.PERSONAL_POA]:
    ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PERSONAL_CARE,
  [CONSTANTS.docTypes.PROPERTY_POA]:
    ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PROPERTY,
})

const BookWitnessingAgreeView = ({
  onSelection,
  documents,
  answerStore: { province },
}: Props) => {
  const { t } = useTranslation()
  const { provinceDictionary } = useProvinceDictionary()

  const coreDocumentReferenceIds = getCoreDocuments(documents).map(
    ({ reference, docType }) => ({
      reference,
      documentLabel: provinceDictionary(
        docTypeMap(t)[docType] || docType,
        province,
      ),
    }),
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      data-testid="book-witnessing-agree-view"
    >
      <Heading align="center" variant="h3">
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingAgreeView.label',
        )}
      </Heading>

      <Text sx={{ my: '1rem' }} align="center">
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingAgreeView.subLabel',
        )}
      </Text>

      {/* Ensure that 'Will' comes first in the array */}
      {_sortBy(coreDocumentReferenceIds, 'documentLabel')
        .reverse()
        .map(({ reference, documentLabel }) => (
          <Box key={reference} sx={{ mb: '0.9rem' }}>
            <Text weight="medium" align="center">
              {documentLabel}
            </Text>
            <Text align="center">{reference}</Text>
          </Box>
        ))}

      <Grid container spacing={8} sx={{ mt: '-2rem' }}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            label={t('common:yes')}
            onClick={() => onSelection('yes')}
            data-testid="book-witnessing-agree-view-yes-button"
          />
        </Grid>

        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            label={t('common:no')}
            onClick={() => onSelection('no')}
            data-testid="book-witnessing-agree-view-no-button"
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  documents: documentsSelectors.getDocuments(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps)(BookWitnessingAgreeView)
