export default {
  stepChildDefinition: {
    type: 'text',
    body: {
      tKey: 'sidebar:distributionSidebarFragments.stepChildDefinition.body',
    },
  },
  stepChildrenRequired: {
    type: 'accordion',
    header: {
      tKey: 'sidebar:distributionSidebarFragments.stepChildrenRequired.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.stepChildrenRequired.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.stepChildrenRequired.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.stepChildrenRequired.children.item3.body',
        },
      },
    ],
  },
  listAllAssets: {
    type: 'accordion',
    header: {
      tKey: 'sidebar:distributionSidebarFragments.listAllAssets.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.listAllAssets.children.item1.body',
        },
      },
    ],
  },
  residueDefinition: {
    type: 'text',
    body: {
      tKey: 'sidebar:distributionSidebarFragments.residueDefinition.body',
    },
  },
  describeAssets: {
    type: 'accordion',
    header: {
      tKey: 'sidebar:distributionSidebarFragments.describeAssets.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.describeAssets.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.describeAssets.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.describeAssets.children.item3.body',
        },
      },
    ],
  },
  noLongerOwnedAssets: {
    type: 'accordion',
    header: {
      tKey: 'sidebar:distributionSidebarFragments.noLongerOwnedAssets.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.noLongerOwnedAssets.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.noLongerOwnedAssets.children.item2.body',
        },
      },
    ],
  },
  doNotIncludeAssets: {
    type: 'accordion',
    header: {
      tKey: 'sidebar:distributionSidebarFragments.doNotIncludeAssets.header',
    },
    children: [
      {
        type: 'list',
        variant: 'unordered',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.doNotIncludeAssets.children.item1.body',
        },
        children: [
          {
            tKey: 'sidebar:distributionSidebarFragments.doNotIncludeAssets.children.item1.children.item1',
          },
          {
            tKey: 'sidebar:distributionSidebarFragments.doNotIncludeAssets.children.item1.children.item2',
          },
          {
            tKey: 'sidebar:distributionSidebarFragments.doNotIncludeAssets.children.item1.children.item3',
          },
        ],
      },
    ],
  },
  giftTaxAccountsAndLifeInsurance: {
    type: 'accordion',
    header: {
      tKey: 'sidebar:distributionSidebarFragments.giftTaxAccountsAndLifeInsurance.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.giftTaxAccountsAndLifeInsurance.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionSidebarFragments.giftTaxAccountsAndLifeInsurance.children.item2.body',
        },
      },
    ],
  },
}
