import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Heading, Image, Text } from 'grommet'
import { LinkNext } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import HighlightPill from '../../../../../../../../common/components/molecules/HighlightPill'
import LawyerModal from './LawyerModal'

const StyledImage = styled(Image)`
  border: 3px solid white;
`
const LawyerCard = ({ lawyer }) => {
  const { t } = useTranslation()

  const { firm, imageUrl, worksRemote, lawyerLastName, lawyerFirstName } =
    lawyer

  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div
        data-testid="lawyer-card"
        className="bg-neutral-2 rounded-xl flex flex-col justify-between w-full px-4 py-6 shadow"
      >
        <div className="flex flex-col items-center text-center">
          <div className="w-32 mb-5">
            <StyledImage
              fit="contain"
              src={imageUrl}
              className="rounded-full"
            />
          </div>

          <Heading level={3} size="medium">
            {lawyerFirstName ? `${lawyerFirstName} ${lawyerLastName}` : firm}
          </Heading>

          {lawyerFirstName && firm && (
            <div className="text-accent-1-darker mt-1">
              <Text size="smedium">{firm}</Text>
            </div>
          )}

          {worksRemote && (
            <div className="mt-3">
              <HighlightPill
                label={t('molecules:LawyerReferral.virtualMeetingPillLabel')}
              />
            </div>
          )}
        </div>

        <div className="mt-8 mb-6 w-full flex justify-center">
          <Button
            primary
            reverse
            onClick={() => setShowModal(true)}
            label={
              <div className="px-3 py-1">
                <Text
                  data-testid="lawyer-card-learn-more-button"
                  size="smedium"
                >
                  {t('common:learnMore')}
                </Text>
              </div>
            }
            icon={<LinkNext size="small" className="-ml-4" color="white" />}
          />
        </div>
      </div>

      {showModal && (
        <LawyerModal
          lawyer={lawyer}
          show={showModal}
          handleSetShow={setShowModal}
        />
      )}
    </>
  )
}

LawyerCard.propTypes = {
  lawyer: PropTypes.shape({
    _id: PropTypes.string,
    firm: PropTypes.string,
    imageUrl: PropTypes.string,
    worksRemote: PropTypes.bool,
    lawyerLastName: PropTypes.string,
    lawyerFirstName: PropTypes.string,
  }).isRequired,
}

export default LawyerCard
