import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { Image, Paragraph } from 'grommet'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FeatherDone } from '../../../../../common/assets/images/feather-done.svg'
import Button from '../../../../../common/components/atoms/Button'
import StyledLabel from '../../../../../common/components/atoms/StyledLabel'
import EverythingButton from '../../../../../common/components/molecules/EverythingButton'
import documentsSelectors from '../../../../Dashboard/ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../../Dashboard/ducks/payments/paymentsSelectors'
import hasCoreDocument from '../../../../Dashboard/utils/hasCoreDocument'
import hasCoreProduct from '../../../../Dashboard/utils/hasCoreProduct'
import navigationActions from '../../../ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../ducks/questionnaire/questionnaireSelectors'

const Complete = ({
  payments,
  documents,
  questionnaireValid,
  dispatchPreviousQuestion,
  answerStoreUpdatedSinceGenerate,
}) => {
  const { t } = useTranslation()

  const isPaidUser = hasCoreProduct(payments)
  const hasGeneratedCoreDocument = hasCoreDocument(documents)

  // User has valid questionnaire, generated core docs, and has not updated their answers since generating
  const freshState =
    questionnaireValid &&
    !answerStoreUpdatedSinceGenerate &&
    hasGeneratedCoreDocument

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-center">
        {questionnaireValid ? (
          <>
            <div>
              <StyledLabel>
                {t('customQuestion:Complete.questionnaireValid.label')}
              </StyledLabel>
            </div>
            <StyledLabel>
              {t(
                `customQuestion:Complete.questionnaireValid.label2.case${freshState ? '2' : '1'}`,
              )}
            </StyledLabel>
            <div className="my-4 max-w-2xl">
              <Paragraph size="smedium">
                {t('customQuestion:Complete.questionnaireValid.subLabel')}
              </Paragraph>
            </div>
          </>
        ) : (
          <div>
            <StyledLabel>
              {t('customQuestion:Complete.questionnaireInvalid.label')}
            </StyledLabel>
            <div className="mt-3 max-w-2xl">
              <Paragraph size="smedium">
                {t('customQuestion:Complete.questionnaireInvalid.subLabel')}
              </Paragraph>
            </div>
          </div>
        )}
      </div>
      <div className="mt-8 mb-14 w-48 sm:w-56">
        {questionnaireValid ? (
          <FeatherDone />
        ) : (
          <Image
            src="https://epilogue-assets-1.s3.ca-central-1.amazonaws.com/puzzle.svg"
            alt="Puzzle with one missing piece"
          />
        )}
      </div>
      <div className="flex flex-col items-center">
        <Box sx={{ mb: '0.9rem' }}>
          {freshState && isPaidUser ? (
            <Link to="/dashboard/documents">
              <Button
                size="lg"
                variant="contained"
                iconFontSize="1.15rem"
                endIcon={<ArrowForwardIcon />}
                label={t(
                  'customQuestion:Complete.questionnaireValid.freshStateButtonLabel',
                )}
              />
            </Link>
          ) : (
            <EverythingButton />
          )}
        </Box>

        <Link to="/dashboard/summary">
          <Button
            size="xxs"
            iconFontSize="1rem"
            endIcon={<ArrowForwardIcon />}
            label={t('customQuestion:Complete.button')}
          />
        </Link>

        <Button
          size="xxs"
          label={t('common:back')}
          iconFontSize="1rem"
          startIcon={<ArrowBackIcon />}
          onClick={dispatchPreviousQuestion}
          data-testid="create-account-form-back-button"
        />
      </div>
    </div>
  )
}

Complete.propTypes = {
  questionnaireValid: PropTypes.bool,
  answerStoreUpdatedSinceGenerate: PropTypes.bool,
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  payments: paymentsSelectors.getPayments(state.dashboard),
  documents: documentsSelectors.getDocuments(state.dashboard),
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
  answerStoreUpdatedSinceGenerate:
    questionnaireSelectors.getAnswerStoreUpdatedSinceGenerate(
      state.questionnaire,
    ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Complete)
