import React from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    color: ({ color, colorVariant }) =>
      _get(theme, ['palette', color, colorVariant]) ||
      themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
  },
}))

const Heading = ({
  sx,
  align = 'inherit',
  color = themeColorTypes.BRAND,
  noWrap = false,
  variant,
  display = 'block',
  children,
  className,
  elementType,
  gutterBottom = false,
  colorVariant = themeColorVariants.MAIN,
  'data-testid': dataTestId,
}) => {
  const classes = useStyles({ color, colorVariant })

  return (
    <Typography
      sx={sx}
      align={align}
      noWrap={noWrap}
      variant={variant}
      display={display}
      className={className}
      data-testid={dataTestId}
      gutterBottom={gutterBottom}
      classes={{ root: classes.root }}
      component={elementType || variant}
    >
      {children}
    </Typography>
  )
}

Heading.propTypes = {
  noWrap: PropTypes.bool,
  sx: PropTypes.shape({}),
  className: PropTypes.string,
  gutterBottom: PropTypes.bool,
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(themeColorTypes)),
  display: PropTypes.oneOf(['initial', 'block', 'inline']),
  colorVariant: PropTypes.oneOf(Object.values(themeColorVariants)),
  elementType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
}

export default Heading
