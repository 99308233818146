import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import { motion } from 'motion/react'

const MountTransition = ({ children, id, sx = {} }) => {
  // Scroll to top of page on mount
  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <Box
      sx={{ ...sx }}
      exit={{ opacity: 0 }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      key={`routeAnimation_${id}`}
    >
      {children}
    </Box>
  )
}

MountTransition.propTypes = {
  sx: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default MountTransition
