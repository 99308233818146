import React, { useCallback, useEffect, useState } from 'react'

import { connect } from 'react-redux'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import DocumentPageSkeleton from '../../../../../../../../common/assets/images/document-page-skeleton-purple-background.svg'
import ManualPrint from '../../../../../../../../common/assets/images/manual-print-purple-background.svg'
import PrintRequest from '../../../../../../../../common/assets/images/print-request-purple-background.svg'
import { ReactComponent as PurpleStar } from '../../../../../../../../common/assets/images/purple-star.svg'
import SignWill from '../../../../../../../../common/assets/images/sign-will-purple-background.svg'
import WillDocument from '../../../../../../../../common/assets/images/will-document-card-purple-background.svg'
import Button from '../../../../../../../../common/components/atoms/Button'
import Checkbox from '../../../../../../../../common/components/atoms/Checkbox'
import Heading from '../../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../../common/components/atoms/Text'
import StepDots from '../../../../../../../../common/components/molecules/StepDots'
import commonActions from '../../../../../../../../common/ducks/commonActions'
import usePreloadImages from '../../../../../../../../common/hooks/usePreloadImages'
import useUpdateToggles from '../../../../../../../../common/hooks/useUpdateToggles'
import amplitude from '../../../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'
import useEmailDocuments from '../../../hooks/useEmailDocuments'
import NotifyAppointees from '../../NotifyAppointees'
import PrimaryButton from './components/PrimaryButton'
import SecondaryButton from './components/SecondaryButton'
import ShadowBox from './components/ShadowBox'

const animateDistance = 350
const variants = {
  start: (direction: number) => ({
    opacity: 0,
    x: direction > 0 ? animateDistance : -animateDistance,
  }),
  enter: {
    x: 0,
    zIndex: 1,
    opacity: 1,
  },
  leave: (direction: number) => ({
    zIndex: 0,
    opacity: 0,
    x: direction < 0 ? animateDistance : -animateDistance,
  }),
}

const preloadedImages = [
  WillDocument,
  PrintRequest,
  ManualPrint,
  SignWill,
  DocumentPageSkeleton,
]

interface Buttons {
  Primary: React.FC
  Secondary?: React.FC
}

interface Step {
  id: number
  img: string
  label: string
  buttons: Buttons
  interceptView?: React.FC
  body: string[] | undefined
  interceptCheckboxLabel?: string
}

interface Steps {
  t: any
  token: string
  userId: string
  answerStore: any
  onClose: () => void
  handleNext: () => void
  questionnaireId: string
  hasPrintCredit: boolean
  isInterceptView: boolean
  handleUpdateToggles: any
  checkboxIsInvalid: boolean
  triggerInterceptView: () => void
  interceptCheckboxesChecked: number[]
  setCheckboxIsInvalid: (boolUpdate: boolean) => void
  dispatchUpdateToast: (toastProperties: any) => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const ModalSteps = ({
  t,
  token,
  userId,
  onClose,
  handleNext,
  answerStore,
  hasPrintCredit,
  isInterceptView,
  questionnaireId,
  checkboxIsInvalid,
  dispatchUpdateToast,
  handleUpdateToggles,
  triggerInterceptView,
  setCheckboxIsInvalid,
  interceptCheckboxesChecked,
  dispatchSetGlobalErrorMessage,
}: Steps): Step[] => {
  const [allAppointeesNotified, setAllAppointeesNotified] = useState(false)

  const { product } = answerStore
  return [
    // Step 1
    {
      id: 1731616341,
      label: t('components:modal.DocumentsGuideModal.steps.1.label'),
      body:
        product === 'willPoa'
          ? t('components:modal.DocumentsGuideModal.steps.1.body.willPoa', {
              returnObjects: true,
              email: answerStore.email,
            })
          : t('components:modal.DocumentsGuideModal.steps.1.body.willOnly', {
              returnObjects: true,
              email: answerStore.email,
            }),
      img: WillDocument,
      buttons: {
        Primary: () => {
          const { EmailStatusTypes, status, handleSubmit } = useEmailDocuments({
            userId,
            answerStore,
            dispatchSetGlobalErrorMessage,
          })

          useEffect(() => {
            if (status === EmailStatusTypes.SUCCESS) {
              dispatchUpdateToast({
                type: CONSTANTS.toastTypes.SUCCESS,
                hideDuration: 4000,
                label: t(
                  'components:modal.DocumentsGuideModal.steps.1.toast.label',
                ),
              })

              handleNext()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [status])

          return (
            <PrimaryButton
              onClick={handleSubmit}
              working={status === EmailStatusTypes.WORKING}
              label={t(
                'components:modal.DocumentsGuideModal.steps.1.buttons.primary.label',
              )}
            />
          )
        },
        Secondary: () => (
          <SecondaryButton
            onClick={handleNext}
            label={t(
              'components:modal.DocumentsGuideModal.steps.1.buttons.secondary.label',
            )}
          />
        ),
      },
    },

    // Step 2
    {
      id: 1731616333,
      label: t('components:modal.DocumentsGuideModal.steps.2.label'),
      body: isInterceptView
        ? undefined
        : t('components:modal.DocumentsGuideModal.steps.2.body', {
            returnObjects: true,
          }),
      img: DocumentPageSkeleton,
      interceptView: useCallback(
        () => (
          <NotifyAppointees
            isEmbedded
            onAllAppointeesNotified={() => setAllAppointeesNotified(true)}
          />
        ),
        [],
      ),
      buttons: {
        Primary: () => (
          <PrimaryButton
            disabled={isInterceptView && !allAppointeesNotified}
            onClick={() => {
              if (isInterceptView) {
                handleNext()
              } else {
                triggerInterceptView()
              }
            }}
            label={t(
              isInterceptView
                ? 'common:continue'
                : 'components:modal.DocumentsGuideModal.steps.2.buttons.primary.label',
            )}
          />
        ),
        Secondary: () => (
          <SecondaryButton
            onClick={handleNext}
            label={t(
              'components:modal.DocumentsGuideModal.steps.2.buttons.secondary.label',
            )}
          />
        ),
      },
    },

    // Step 3
    {
      id: 1731616349,
      label: t('components:modal.DocumentsGuideModal.steps.3.label'),
      body: hasPrintCredit
        ? product === 'willPoa'
          ? t(
              'components:modal.DocumentsGuideModal.steps.3.body.hasPrintCredit.willPoa',
              { returnObjects: true },
            )
          : t(
              'components:modal.DocumentsGuideModal.steps.3.body.hasPrintCredit.willOnly',
              { returnObjects: true },
            )
        : product === 'willPoa'
          ? t(
              'components:modal.DocumentsGuideModal.steps.3.body.noPrintCredit.willPoa',
              { returnObjects: true },
            )
          : t(
              'components:modal.DocumentsGuideModal.steps.3.body.noPrintCredit.willOnly',
              { returnObjects: true },
            ),
      img: hasPrintCredit ? PrintRequest : ManualPrint,
      buttons: {
        Primary: () => (
          <PrimaryButton
            onClick={handleNext}
            label={t(
              'components:modal.DocumentsGuideModal.steps.3.buttons.primary.label',
            )}
          />
        ),
      },
    },

    // Step 4
    {
      id: 1731616352,
      label: t('components:modal.DocumentsGuideModal.steps.4.label'),
      body:
        product === 'willPoa'
          ? t('components:modal.DocumentsGuideModal.steps.4.body.willPoa', {
              returnObjects: true,
            })
          : t('components:modal.DocumentsGuideModal.steps.4.body.willOnly', {
              returnObjects: true,
            }),
      interceptCheckboxLabel: t('common:iUnderstand'),
      img: SignWill,
      buttons: {
        Primary: () => (
          <PrimaryButton
            forwardArrow={false}
            label={t('common:gotIt')}
            disabled={!interceptCheckboxesChecked.includes(1731616352)}
            onClick={() => {
              if (!checkboxIsInvalid) {
                amplitude.sendEvent('ClickedAcceptDocumentsGuideModal')
                onClose()
                handleUpdateToggles({
                  token,
                  questionnaireId,
                  toggleOn: [
                    CONSTANTS.toggleTypes.HAS_ACCEPTED_STEPPED_DOCUMENTS_MODAL,
                  ],
                })
              }
            }}
            onDisabledClick={() => {
              if (!checkboxIsInvalid) {
                setCheckboxIsInvalid(true)
              }
            }}
          />
        ),
      },
    },
  ]
}

interface Props {
  token: string
  show: boolean
  userId: string
  answerStore: any
  onClose: () => void
  hasPrintCredit: boolean
  questionnaireId: string
  dispatchUpdateToast: (toastProperties: any) => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const DocumentsGuideModal = ({
  show,
  token,
  userId,
  onClose,
  answerStore,
  hasPrintCredit,
  questionnaireId,
  dispatchUpdateToast,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { t } = useTranslation()

  const { mutate: handleUpdateToggles } = useUpdateToggles()

  usePreloadImages({ imageUrls: preloadedImages })

  const [direction, setDirection] = useState(1)
  const [activeStep, setActiveStep] = useState(0)
  const [isInterceptView, setIsInterceptView] = useState(false)
  const [checkboxIsInvalid, setCheckboxIsInvalid] = useState(false)
  const [interceptCheckboxesChecked, setInterceptCheckboxesChecked] = useState<
    number[]
  >([])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setDirection(1)
    setIsInterceptView(false)
    amplitude.sendEvent('ClickedDocumentsGuideModalForwardButton')
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    amplitude.sendEvent('ClickedDocumentsGuideModalBackButton')
    setDirection(-1)
    setIsInterceptView(false)
    if (checkboxIsInvalid) {
      setCheckboxIsInvalid(false)
    }
  }

  const triggerInterceptView = () => setIsInterceptView(true)

  const resolvedSteps = ModalSteps({
    t,
    token,
    userId,
    onClose,
    handleNext,
    answerStore,
    hasPrintCredit,
    isInterceptView,
    questionnaireId,
    checkboxIsInvalid,
    handleUpdateToggles,
    dispatchUpdateToast,
    triggerInterceptView,
    setCheckboxIsInvalid,
    interceptCheckboxesChecked,
    dispatchSetGlobalErrorMessage,
  })

  const currentStep = resolvedSteps[activeStep]
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))

  useEffect(() => {
    if (show)
      amplitude.sendEvent('ViewedDocumentsGuideModal', {
        hasPrintCredit,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const imageSectionHeight = isMobile ? 237 : 315
  const contentSectionHeight = isMobile && isInterceptView ? 405 : 340
  const modalHeight = imageSectionHeight + contentSectionHeight
  const buttonSectionHeight = currentStep.buttons?.Secondary ? 105 : 67

  return (
    <Modal
      fullBleed
      show={show}
      preventClose
      maxWidth="md"
      onClose={onClose}
      data-testid="documents-guide-modal"
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          height: `${modalHeight}px`,
          width: isMobile ? '100%' : (theme) => theme.breakpoints.values.md,
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            width: '100%',
            height: `${imageSectionHeight}px`,
            backgroundColor:
              themeColors[themeColorTypes.ACCENT_2][
                themeColorVariants.LIGHTEST
              ],
          }}
        >
          {!isInterceptView && (
            <AnimatePresence initial={false} custom={direction} mode="wait">
              <motion.img
                exit="leave"
                initial="start"
                animate="enter"
                key={activeStep}
                custom={direction}
                variants={variants}
                src={currentStep.img}
                transition={{
                  opacity: { duration: 0.3 },
                  x: { type: 'spring', stiffness: 280, damping: 30 },
                }}
              />
            </AnimatePresence>
          )}
        </Box>

        {/* Content Section */}
        <motion.div
          initial={{ y: imageSectionHeight }}
          animate={{
            y: isInterceptView ? 0 : imageSectionHeight,
            transition: { damping: 30, type: 'spring', stiffness: 215 },
          }}
          style={{
            top: 0,
            width: '100%',
            position: 'absolute',
            paddingBottom: `${buttonSectionHeight}px`,
            height: isInterceptView
              ? `${modalHeight - buttonSectionHeight}px`
              : `${contentSectionHeight}px`,
            backgroundColor: themeColors[themeColorTypes.WHITE][
              themeColorVariants.MAIN
            ] as string,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              height: isInterceptView
                ? `${modalHeight - buttonSectionHeight}px`
                : '100%',
            }}
          >
            <ShadowBox
              sx={{
                pt: '1.5rem',
                display: 'flex',
                overflow: 'auto',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                px: { xs: '1.2rem', lg: '0' },
              }}
            >
              {/* Step Dots */}
              <Box sx={{ zIndex: 2, mb: '0.9rem' }}>
                <StepDots
                  preventDotClick
                  activeStep={activeStep}
                  steps={resolvedSteps.length}
                />
              </Box>

              {/* Header */}
              <Box>
                <Heading
                  align="center"
                  sx={{ fontSize: '1.375rem' }}
                  variant={isMobile ? 'h4' : 'h3'}
                >
                  <PurpleStar
                    style={{
                      width: '19px',
                      bottom: '3px',
                      display: 'inline',
                      position: 'relative',
                      marginRight: '0.4rem',
                    }}
                  />
                  {currentStep.label}
                </Heading>

                {currentStep.body && (
                  <Box sx={{ maxWidth: '445px', mx: 'auto', mt: '0.7rem' }}>
                    {React.Children.toArray(
                      currentStep.body.map((text) => (
                        <Text
                          size="sm"
                          align="center"
                          sx={{ mt: '0.4rem', lineHeight: '1.5' }}
                        >
                          {text}
                        </Text>
                      )),
                    )}
                  </Box>
                )}
              </Box>

              {/* InterceptView Content */}
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  transition: 'opacity 0.35s',
                  justifyContent: 'flex-start',
                  opacity: isInterceptView ? 1 : 0,
                }}
              >
                {isInterceptView && currentStep.interceptView && (
                  <currentStep.interceptView />
                )}
              </Box>

              {/* Checkbox */}
              {!isInterceptView && currentStep.interceptCheckboxLabel && (
                <Box
                  sx={{
                    mt: '0.6rem',
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox
                    isInvalid={checkboxIsInvalid}
                    checked={interceptCheckboxesChecked.includes(
                      currentStep.id,
                    )}
                    label={currentStep.interceptCheckboxLabel}
                    onChange={(checkValue: boolean) => {
                      amplitude.sendEvent('CheckedSignReminder')
                      if (checkboxIsInvalid) {
                        setCheckboxIsInvalid(false)
                      }
                      if (checkValue) {
                        setInterceptCheckboxesChecked([
                          ...interceptCheckboxesChecked,
                          currentStep.id,
                        ])
                      } else {
                        setInterceptCheckboxesChecked(
                          interceptCheckboxesChecked.filter(
                            (step) => step !== currentStep.id,
                          ),
                        )
                      }
                    }}
                  />
                  {checkboxIsInvalid && (
                    <Text
                      sx={{
                        top: '-5px',
                        textAlign: 'center',
                        position: 'relative',
                      }}
                      size="xxs"
                      color={themeColorTypes.RED}
                      data-testid="documents-guide-modal-checkbox-error"
                    >
                      *{t('validation:validation.checkBoxAbove')}
                    </Text>
                  )}
                </Box>
              )}
            </ShadowBox>
          </Box>
        </motion.div>

        {/* Buttons */}
        <Box
          sx={{
            bottom: 0,
            left: 0,
            right: 0,
            px: '2rem',
            pb: '1rem',
            pt: '0.2rem',
            display: 'flex',
            position: 'absolute',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            height: `${buttonSectionHeight}px`,
            backgroundColor:
              themeColors[themeColorTypes.WHITE][themeColorVariants.MAIN],
          }}
        >
          <Button
            size="xxs"
            onClick={handleBack}
            iconFontSize="0.9rem"
            label={t('common:back')}
            color={themeColorTypes.GREY}
            colorVariant={themeColorVariants.DARK}
            data-testid="documents-guide-modal-back-button"
            startIcon={<ArrowBackIcon sx={{ mr: '-0.2rem' }} />}
            sx={{
              visibility: [1731616341, 1731616333].includes(currentStep.id)
                ? 'hidden'
                : 'visible',
            }}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <currentStep.buttons.Primary />
            {currentStep.buttons?.Secondary && (
              <Box sx={{ mt: '0.6rem' }}>
                <currentStep.buttons.Secondary />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  dispatchUpdateToast: (toastProperties: any) =>
    dispatch(commonActions.updateToast(toastProperties)),
})

export default connect(null, mapDispatchToProps)(DocumentsGuideModal)
