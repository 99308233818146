import addOnsTypes from './addOnsTypes'

export default {
  fetchAddOns: ({ clearAddOnCart }) => ({
    type: addOnsTypes.FETCH_ADD_ONS,
    payload: { clearAddOnCart },
  }),

  fetchAddOnsWorking: (working) => ({
    type: addOnsTypes.FETCH_ADD_ONS_WORKING,
    payload: { working },
  }),

  setAddOns: (addOnsArr) => ({
    type: addOnsTypes.SET_ADD_ONS,
    payload: { addOnsArr },
  }),

  addAddOnToCart: ({ type, amount = 1 }) => ({
    type: addOnsTypes.ADD_ADD_ON_TO_CART,
    payload: { type, amount },
  }),

  setAddOnsToCart: (addOnsArr) => ({
    type: addOnsTypes.SET_ADD_ONS_TO_CART,
    payload: { addOnsArr },
  }),

  removeAddOnFromCart: ({ type }) => ({
    type: addOnsTypes.REMOVE_ADD_ON_FROM_CART,
    payload: { type },
  }),

  clearAddOnCart: () => ({
    type: addOnsTypes.CLEAR_ADD_ON_CART,
  }),
}
