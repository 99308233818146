import { CONSTANTS } from '@epilogue/common'

export default {
  label: { tKey: 'interceptModals:familyLegacyAllToPartnerModal.label' },

  subLabel: ({
    answerStore: { partnerFirstName },
    charityPartner: { displayName: charityPartnerDisplayName },
  }) => ({
    tKey: 'interceptModals:familyLegacyAllToPartnerModal.subLabel',
    options: { partnerFirstName, charityPartnerDisplayName },
  }),

  primaryButtonLabel: ({ answerStore: { partnerFirstName } }) => ({
    tKey: 'interceptModals:familyLegacyAllToPartnerModal.primaryButtonLabel',
    options: { partnerFirstName },
  }),

  primaryButtonAction: {
    type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
  },

  secondaryButtonIcon: true,

  secondaryButtonLabel: ({
    charityPartner: { displayName: charityPartnerDisplayName },
  }) => ({
    tKey: 'interceptModals:familyLegacyAllToPartnerModal.secondaryButtonLabel',
    options: { charityPartnerDisplayName },
  }),

  secondaryButtonAction: {
    type: CONSTANTS.interceptActionTypes.TO_QUESTION,
    questionId: ({ answerStore: { charityPartnerMirrorFamilyGift } }) => {
      const charityPartnerGiftTypeQuestionId = 1642619114
      const charityPartnerMirrorFamilyGiftQuestionId = 1644267723
      /*
       * When a user is mirroring a Family Legacy gift, in this case, we want
       * to bring them to that 'charityPartnerMirrorFamilyGift' question because
       * they will not have a triggered 'charityPartnerGiftType' question. If they don't
       * have a charityPartnerMirrorFamilyGift question, we can assume that they do have
       * a 'charityPartnerGiftType' question, and we can safely bring the user there
       */
      return charityPartnerMirrorFamilyGift === 'yes'
        ? charityPartnerMirrorFamilyGiftQuestionId
        : charityPartnerGiftTypeQuestionId
    },
  },

  trigger: {
    allToPartner: 'no',
    $or: [
      {
        charityPartnerMirrorFamilyGift: 'yes',
        'charityPartnerGiftDetails.isPercent': true,
      },
      {
        charityPartnerGiftType: '%',
        charityPartnerGiftTiming: 'familyLegacy',
        charityPartnerMirrorFamilyGift: { $ne: 'yes' },
      },
    ],
  },
}
