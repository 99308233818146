import _get from 'lodash/get'

import { createLogic } from 'redux-logic'

import commonActions from '../../../../../common/ducks/commonActions'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import data from '../../../../Questionnaire/data'
import navigationActions from '../../../../Questionnaire/ducks/navigation/navigationActions'
import questionnaireSelectors from '../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { triggeredQuestions } from '../../../../Questionnaire/engine'
import documentsActions from '../../documents/documentsActions'
import documentsSelectors from '../../documents/documentsSelectors'
import paymentsSelectors from '../../payments/paymentsSelectors'
import dashboardSelectors from '../dashboardSelectors'
import dashboardTypes from '../dashboardTypes'

export default createLogic({
  name: 'beforeDashboard',
  type: dashboardTypes.BEFORE_DASHBOARD,
  async process({ getState }, dispatch, done) {
    const auth = commonSelectors.getAuth(getState())

    // only run the code below if the user is authenticated
    if (auth) {
      const { questionnaire } = getState().questionnaire

      const isPartner = dashboardSelectors.getIsPartner(getState().dashboard)

      const accountCreated =
        questionnaireSelectors.getAccountCreated(questionnaire)
      const abTest = _get(questionnaire, 'abTest')
      const payments = paymentsSelectors.getPayments(getState().dashboard)
      const charityPartner = commonSelectors.getCharityPartner(getState())
      const documents = documentsSelectors.getDocuments(getState().dashboard)
      const referrer = questionnaireSelectors.getReferrer(
        getState().questionnaire,
      )

      const questionStack = triggeredQuestions({
        abTest,
        referrer,
        payments,
        isPartner,
        documents,
        theData: data,
        charityPartner,
        accountCreated,
        answerStore: questionnaire.answerStore,
      })

      dispatch(navigationActions.updateQuestionStack({ questionStack }))

      dispatch(
        commonActions.setAvailableWorlds({
          answerCache: questionnaire.answerCache,
        }),
      )

      dispatch(documentsActions.fetchDocuments())
    }

    done()
  },
})
