// TAG: province-specific
import { CONSTANTS, Province } from '@epilogue/common'
import { t } from 'i18next'

export const ONTARIO_TERMS = {
  POWER_OF_ATTORNEY: 'Power of Attorney',
  POWERS_OF_ATTORNEY: 'Powers of Attorney',
  _AND_POWERS_OF_ATTORNEY: ' and Powers of Attorney',

  PROPERTY_POA: 'Property POA',
  POA_FOR_PROPERTY: 'POA for Property',
  PROPERTYPOA: CONSTANTS.docTypes.PROPERTY_POA,
  ATTORNEY_FOR_PROPERTY: 'Attorney for Property',
  POWER_OF_ATTORNEY_FOR_PROPERTY: 'Power of Attorney for Property',
  A_POWER_OF_ATTORNEY_FOR_PROPERTY: 'a Power of Attorney for Property',

  ATTORNEY: 'attorney',
  POA_FOR_CARE: 'POA for Care',
  PERSONALPOA: CONSTANTS.docTypes.PERSONAL_POA,
  ATTORNEY_FOR_PERSONAL_CARE: 'Attorney for Personal Care',
  POWER_OF_ATTORNEY_FOR_PERSONAL_CARE: 'Power of Attorney for Personal Care',
} as const

export type OntarioTerm = (typeof ONTARIO_TERMS)[keyof typeof ONTARIO_TERMS]

export const translate: {
  [key in OntarioTerm]: (province: Province) => string | OntarioTerm
} = {
  /*
   *
   * term: 'Power of Attorney'
   *
   */
  [ONTARIO_TERMS.POWER_OF_ATTORNEY]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.BRITISH_COLUMBIA:
      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
      case CONSTANTS.provinces.NOVA_SCOTIA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:POWER_OF_ATTORNEY-case1')

      default:
        return t('provinceDictionary:POWER_OF_ATTORNEY-default')
    }
  },

  /*
   *
   * term: 'Powers of Attorney'
   *
   */
  [ONTARIO_TERMS.POWERS_OF_ATTORNEY]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.BRITISH_COLUMBIA:
      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
      case CONSTANTS.provinces.NOVA_SCOTIA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:POWERS_OF_ATTORNEY-case1')

      default:
        return t('provinceDictionary:POWERS_OF_ATTORNEY-default')
    }
  },

  /*
   *
   * term: 'and Powers of Attorney'
   *
   */
  [ONTARIO_TERMS._AND_POWERS_OF_ATTORNEY]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.NOVA_SCOTIA:
        return t('provinceDictionary:_AND_POWERS_OF_ATTORNEY-case1')

      case CONSTANTS.provinces.BRITISH_COLUMBIA:
        return t('provinceDictionary:_AND_POWERS_OF_ATTORNEY-case2')

      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:_AND_POWERS_OF_ATTORNEY-case3')

      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
        return t('provinceDictionary:_AND_POWERS_OF_ATTORNEY-case4')

      default:
        return t('provinceDictionary:_AND_POWERS_OF_ATTORNEY-default')
    }
  },

  /*
   *
   * term: 'Property POA'
   *
   */
  [ONTARIO_TERMS.PROPERTY_POA]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.BRITISH_COLUMBIA:
      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.NEW_BRUNSWICK:
      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
      case CONSTANTS.provinces.NOVA_SCOTIA:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:PROPERTY_POA-case1')

      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
        return t('provinceDictionary:PROPERTY_POA-case2')

      default:
        return t('provinceDictionary:PROPERTY_POA-default')
    }
  },

  /*
   *
   * term: 'POA for Property'
   *
   */
  [ONTARIO_TERMS.POA_FOR_PROPERTY]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.BRITISH_COLUMBIA:
      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.NEW_BRUNSWICK:
      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
      case CONSTANTS.provinces.NOVA_SCOTIA:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:POA_FOR_PROPERTY-case1')

      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
        return t('provinceDictionary:POA_FOR_PROPERTY-case2')

      default:
        return t('provinceDictionary:POA_FOR_PROPERTY-default')
    }
  },

  /*
   *
   * term: 'propertyPoa'
   *
   */
  [ONTARIO_TERMS.PROPERTYPOA]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.BRITISH_COLUMBIA:
      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.NEW_BRUNSWICK:
      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
      case CONSTANTS.provinces.NOVA_SCOTIA:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:PROPERTYPOA-case1')

      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
        return t('provinceDictionary:PROPERTYPOA-case2')

      default:
        return t('provinceDictionary:PROPERTYPOA-default')
    }
  },

  /*
   *
   * term: 'Attorney for Property'
   *
   */
  [ONTARIO_TERMS.ATTORNEY_FOR_PROPERTY]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.BRITISH_COLUMBIA:
      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.NEW_BRUNSWICK:
      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
      case CONSTANTS.provinces.NOVA_SCOTIA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:ATTORNEY_FOR_PROPERTY-case1')

      default:
        return t('provinceDictionary:ATTORNEY_FOR_PROPERTY-default')
    }
  },

  /*
   *
   * term: 'Power of Attorney for Property'
   *
   */
  [ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PROPERTY]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.BRITISH_COLUMBIA:
      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.NEW_BRUNSWICK:
      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
      case CONSTANTS.provinces.NOVA_SCOTIA:
        return t('provinceDictionary:POWER_OF_ATTORNEY_FOR_PROPERTY-case1')

      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
        return t('provinceDictionary:POWER_OF_ATTORNEY_FOR_PROPERTY-case2')

      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:POWER_OF_ATTORNEY_FOR_PROPERTY-case3')

      default:
        return t('provinceDictionary:POWER_OF_ATTORNEY_FOR_PROPERTY-default')
    }
  },

  /*
   *
   * term: 'a Power of Attorney for Property'
   *
   */
  [ONTARIO_TERMS.A_POWER_OF_ATTORNEY_FOR_PROPERTY]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.BRITISH_COLUMBIA:
      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.NEW_BRUNSWICK:
      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
      case CONSTANTS.provinces.NOVA_SCOTIA:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:A_POWER_OF_ATTORNEY_FOR_PROPERTY-case1')

      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
        return t('provinceDictionary:A_POWER_OF_ATTORNEY_FOR_PROPERTY-case2')

      default:
        return t('provinceDictionary:A_POWER_OF_ATTORNEY_FOR_PROPERTY-default')
    }
  },

  /*
   *
   * term: 'attorney'
   *
   */
  [ONTARIO_TERMS.ATTORNEY]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
        return t('provinceDictionary:ATTORNEY-case1')

      case CONSTANTS.provinces.BRITISH_COLUMBIA:
        return t('provinceDictionary:ATTORNEY-case2')

      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:ATTORNEY-case3')

      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
        return t('provinceDictionary:ATTORNEY-case4')

      case CONSTANTS.provinces.NOVA_SCOTIA:
        return t('provinceDictionary:ATTORNEY-case5')

      default:
        return t('provinceDictionary:ATTORNEY-default')
    }
  },

  /*
   *
   * term: 'POA for Care'
   *
   */
  [ONTARIO_TERMS.POA_FOR_CARE]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.NOVA_SCOTIA:
        return t('provinceDictionary:POA_FOR_CARE-case1')

      case CONSTANTS.provinces.BRITISH_COLUMBIA:
        return t('provinceDictionary:POA_FOR_CARE-case2')

      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:POA_FOR_CARE-case3')

      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
        return t('provinceDictionary:POA_FOR_CARE-case4')

      default:
        return t('provinceDictionary:POA_FOR_CARE-default')
    }
  },

  /*
   *
   * term: 'personalPoa'
   *
   */
  [ONTARIO_TERMS.PERSONALPOA]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.NOVA_SCOTIA:
        return t('provinceDictionary:PERSONALPOA-case1')

      case CONSTANTS.provinces.BRITISH_COLUMBIA:
        return t('provinceDictionary:PERSONALPOA-case2')

      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:PERSONALPOA-case3')

      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
        return t('provinceDictionary:PERSONALPOA-case4')

      default:
        return t('provinceDictionary:PERSONALPOA-default')
    }
  },

  /*
   *
   * term: 'Attorney for Personal Care'
   *
   */
  [ONTARIO_TERMS.ATTORNEY_FOR_PERSONAL_CARE]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
        return t('provinceDictionary:ATTORNEY_FOR_PERSONAL_CARE-case1')

      case CONSTANTS.provinces.BRITISH_COLUMBIA:
        return t('provinceDictionary:ATTORNEY_FOR_PERSONAL_CARE-case2')

      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:ATTORNEY_FOR_PERSONAL_CARE-case3')

      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
        return t('provinceDictionary:ATTORNEY_FOR_PERSONAL_CARE-case4')

      case CONSTANTS.provinces.NOVA_SCOTIA:
        return t('provinceDictionary:ATTORNEY_FOR_PERSONAL_CARE-case5')

      default:
        return t('provinceDictionary:ATTORNEY_FOR_PERSONAL_CARE-default')
    }
  },

  /*
   *
   * term: 'Power of Attorney for Personal Care'
   *
   */
  [ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PERSONAL_CARE]: (province) => {
    switch (province) {
      case CONSTANTS.provinces.ALBERTA:
      case CONSTANTS.provinces.NOVA_SCOTIA:
        return t('provinceDictionary:POWER_OF_ATTORNEY_FOR_PERSONAL_CARE-case1')

      case CONSTANTS.provinces.BRITISH_COLUMBIA:
        return t('provinceDictionary:POWER_OF_ATTORNEY_FOR_PERSONAL_CARE-case2')

      case CONSTANTS.provinces.MANITOBA:
      case CONSTANTS.provinces.PRINCE_EDWARD_ISLAND:
      case CONSTANTS.provinces.SASKATCHEWAN:
        return t('provinceDictionary:POWER_OF_ATTORNEY_FOR_PERSONAL_CARE-case3')

      case CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR:
        return t('provinceDictionary:POWER_OF_ATTORNEY_FOR_PERSONAL_CARE-case4')

      default:
        return t(
          'provinceDictionary:POWER_OF_ATTORNEY_FOR_PERSONAL_CARE-default',
        )
    }
  },
}

const isOntarioTerm = (term: any): term is OntarioTerm =>
  Object.values(ONTARIO_TERMS).includes(term)

export const provinceDictionary = (
  term: OntarioTerm | string,
  province: Province = CONSTANTS.provinces.ONTARIO,
) => {
  if (
    isOntarioTerm(term) &&
    Object.values(CONSTANTS.provinces).includes(province)
  ) {
    return translate[term](province)
  }
  /*
   * If we make it this far, simply return the incoming term
   * because we know it's not meant to be translated.
   */
  return term
}
