import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import navigationActions from '../../ducks/navigation/navigationActions'
import navigationSelectors from '../../ducks/navigation/navigationSelectors'
import questionnaireActions from '../../ducks/questionnaire/questionnaireActions'

const NativeNavHoc = (WrappedComponent) => {
  const Navigation = ({
    questionStack,
    dispatchPreviousQuestion,
    dispatchClearQuestionFragments,
    ...props
  }) => {
    useEffect(() => {
      const handlePopState = () => {
        if (questionStack.length > 1) {
          dispatchClearQuestionFragments()
          dispatchPreviousQuestion({ clearFrags: false })
        }
      }

      window.history.pushState({ prevent: true }, '')
      window.addEventListener('popstate', handlePopState)

      return () => {
        window.removeEventListener('popstate', handlePopState)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionStack])

    return <WrappedComponent {...{ ...props, questionStack }} />
  }

  Navigation.propTypes = {
    dispatchPreviousQuestion: PropTypes.func.isRequired,
    dispatchClearQuestionFragments: PropTypes.func.isRequired,
    questionStack: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }

  const mapStateToProps = (state) => ({
    questionStack: navigationSelectors.getQuestionStack(state.questionnaire),
  })

  const mapDispatchToProps = (dispatch) => ({
    dispatchClearQuestionFragments: () =>
      dispatch(questionnaireActions.clearQuestionFragments()),
    dispatchPreviousQuestion: ({ clearFrags }) =>
      dispatch(navigationActions.previousQuestion({ clearFrags })),
  })

  return connect(mapStateToProps, mapDispatchToProps)(Navigation)
}

export default NativeNavHoc
