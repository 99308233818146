import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'

import Text from '../../../../../../common/components/atoms/Text'
import useInjectDynamic from '../../../../../../common/hooks/useInjectDynamic'
import { fontFamilies } from '../../../../../../common/styles/muiTheme'

const SidebarList = ({ element }) => {
  const { resolveValue } = useInjectDynamic()
  const { children, variant, body } = element
  const isOrdered = variant !== CONSTANTS.sidebar.listTypes.UNORDERED

  return (
    <Box sx={{ mb: '1rem' }}>
      {body && (
        <Text
          sx={{
            lineHeight: '1.5',
          }}
          variant="paragraph"
        >
          {resolveValue(body)}
        </Text>
      )}
      <Box
        component={isOrdered ? 'ol' : 'ul'}
        sx={{
          ml: isOrdered ? '1.2rem' : '1.5rem',
          listStyleType: isOrdered ? 'decimal' : 'disc',
          '& > li::marker': {
            fontFamily: fontFamilies.paragraph,
            fontSize: isOrdered ? '1rem' : undefined,
            position: isOrdered ? undefined : 'absolute',
          },
        }}
      >
        {React.Children.toArray(
          children.map((listItem) => (
            <li>
              <Text
                sx={{
                  lineHeight: '1.5',
                  ml: isOrdered ? undefined : '-0.3rem',
                }}
                variant="paragraph"
              >
                {resolveValue(listItem)}
              </Text>
            </li>
          )),
        )}
      </Box>
    </Box>
  )
}

SidebarList.propTypes = {
  element: PropTypes.shape({
    children: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.shape({ tKey: PropTypes.string }),
      ]),
    ).isRequired,
    variant: PropTypes.oneOf(Object.values(CONSTANTS.sidebar.listTypes)),
    body: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.shape({ tKey: PropTypes.string }),
    ]),
  }).isRequired,
}

export default SidebarList
