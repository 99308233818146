import dashboardTypes from './dashboardTypes'

const initialState = {
  codes: [],
  userId: undefined,
  isPartner: undefined,
  email: undefined,
  mailingList: undefined,
  howDidYouHearAboutUs: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case dashboardTypes.SET_USER_PROPERTIES:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
