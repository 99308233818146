import React from 'react'

import { Box, Grid } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../../../common/components/atoms/Heading'

interface Props {
  onSelection: (selection: 'yes' | 'no') => void
}

const BookWitnessingSwitchboardView = ({ onSelection }: Props) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      data-testid="book-witnessing-switchboard-view"
    >
      <Heading align="center" variant="h3" sx={{ mb: '3rem' }}>
        {t(
          'documents:components.modals.BookWitnessingModal.components.BookWitnessingSwitchboardView.label',
        )}
      </Heading>

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            label={t('common:yes')}
            onClick={() => onSelection('yes')}
            data-testid="book-witnessing-switchboard-yes-button"
          />
        </Grid>

        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            label={t('common:no')}
            onClick={() => onSelection('no')}
            data-testid="book-witnessing-switchboard-no-button"
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default BookWitnessingSwitchboardView
