import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import _isEmpty from 'lodash/isEmpty'

import SectionCard from './SectionCard'

const SectionCards = ({ sectionGroups }) => (
  <div>
    {!_isEmpty(sectionGroups) &&
      Object.keys(sectionGroups).map((sec) => {
        const sectionLabel = sec
        const section = sectionGroups[sec]
        return (
          <Fragment key={sectionLabel}>
            {sec !== 'Complete' && (
              <SectionCard label={sectionLabel} data={section} />
            )}
          </Fragment>
        )
      })}
  </div>
)

SectionCards.propTypes = {
  sectionGroups: PropTypes.shape({}).isRequired,
}

export default SectionCards
