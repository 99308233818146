import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'

import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useTranslation } from 'react-i18next'

import questionnaireSelectors from '../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { errorHandler, PATHS, request } from '../../request'
import { themeColorTypes } from '../../styles/muiTheme'
import { PROVINCE_CODES } from '../../utils/provinces'
import Button from '../atoms/Button'
import Select from '../atoms/Select'
import TextInput from '../atoms/TextInput'
import fieldTypes from './data/mailingAddressFieldTypes'

interface FormData {
  [key: string]: string
}

interface AddressSuggestion {
  label: string
  placeId: string
}

interface Props {
  answerStore: any
  working?: boolean
  initialFormData?: FormData
  onSubmit: (formData: FormData) => void
}

const getAddressSuggestionsApi = (input: string) =>
  request({
    method: 'GET',
    params: { q: input },
    url: PATHS.LOCATION_SUGGESTIONS,
  })

const debouncedGetAddressSuggestions = AwesomeDebouncePromise(
  getAddressSuggestionsApi,
  365,
)

const queryLengthThreshold = 5

const MailingAddressForm = ({
  onSubmit,
  answerStore,
  working = false,
  initialFormData = undefined,
}: Props) => {
  const [hideAutocomplete, setHideAutocomplete] = useState(true)
  const [addressSuggestions, setAddressSuggestions] = useState<
    AddressSuggestion[]
  >([])
  const [displayValidation, setDisplayValidation] = useState(false)
  const [invalidFields, setInvalidFields] = useState(Object.keys(fieldTypes))
  const [formData, setFormData] = useState<FormData>(
    initialFormData || {
      name: `${answerStore.firstName} ${answerStore.lastName}`,
    },
  )

  useEffect(() => {
    const newInvalidFields = Object.keys(fieldTypes).reduce(
      (acc: Array<any>, field: string) => {
        if (
          !fieldTypes[field].optional &&
          (!formData[field] ||
            // If field has a pattern, it's invalid if it fails the regex
            (fieldTypes[field].pattern &&
              !fieldTypes[field].pattern?.test(formData[field])))
        ) {
          return [...acc, field]
        }
        return acc
      },
      [],
    )

    setInvalidFields(newInvalidFields)
  }, [formData])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (_isEmpty(invalidFields)) {
      onSubmit(formData)
    } else {
      setDisplayValidation(true)
    }
  }

  const handleInputChange = (fragment: any) => {
    setFormData({ ...formData, ...fragment })
  }

  const handleGetAddressSelection = async (placeId: string) => {
    try {
      const res = await request({
        method: 'GET',
        url: `${PATHS.LOCATION_PLACE}/${placeId}`,
      })

      const {
        street,
        region,
        address2,
        postalCode,
        municipality,
        addressNumber,
      } = _get(res, ['data', 'payload'], {})

      handleInputChange({
        [fieldTypes.province.id]:
          // Converts province full name to province code
          PROVINCE_CODES.find(
            ({ fullLabel }) => fullLabel.toLowerCase() === region.toLowerCase(),
          )?.value || '',
        [fieldTypes.city.id]: municipality || '',
        [fieldTypes.postalCode.id]: postalCode || '',
        [fieldTypes.address1.id]: `${
          addressNumber ? `${addressNumber} ` : ''
          // Removes double spaces from string
        }${street || ''}`.replace(/  +/g, ' '),
        [fieldTypes.address2.id]: address2 || '',
      })

      setAddressSuggestions([])
      setHideAutocomplete(true)
    } catch (error) {
      errorHandler({ error, reportError: true })
    }
  }

  const handleGetAddressSuggestions = async (input: string) => {
    if (input) {
      try {
        const res = await debouncedGetAddressSuggestions(input)

        setAddressSuggestions(
          res.data.payload.map(
            ({ placeId, text }: { placeId: string; text: string }) => ({
              placeId,
              label: text
                // Removes 'CAN' from end of string
                .replace(/, CAN$/g, '')
                // Removes postal code from end of string
                .replace(/, [A-Z]\d[A-Z]( \d[A-Z]\d)?$/, ''),
            }),
          ),
        )
      } catch (error) {
        setAddressSuggestions([])
        errorHandler({ error, reportError: true })
      }
    }
  }

  const { t } = useTranslation()

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        pt: '1.5rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        pb: { min: '2rem', sm: '0.75rem' },
      }}
    >
      <TextInput
        disabled
        value={formData[fieldTypes.name.id]}
        placeholder={fieldTypes.name.placeholder}
        isInvalid={
          invalidFields.includes(fieldTypes.name.id) && displayValidation
        }
      />
      <TextInput
        displayAllAutocompleteOptions
        value={formData?.[fieldTypes.address1.id]}
        placeholder={fieldTypes.address1.placeholder}
        isInvalid={
          invalidFields.includes(fieldTypes.address1.id) && displayValidation
        }
        autocompleteOptions={
          hideAutocomplete ||
          _isEmpty(addressSuggestions) ||
          formData?.[fieldTypes.address1.id].length < queryLengthThreshold
            ? []
            : addressSuggestions
        }
        onInputChange={(value: string, selectedOption: AddressSuggestion) => {
          if (hideAutocomplete) setHideAutocomplete(false)
          if (selectedOption) {
            handleGetAddressSelection(selectedOption?.placeId)
          } else if (value !== formData?.[fieldTypes.address1.id]) {
            handleInputChange({ [fieldTypes.address1.id]: value })
            if (value.length >= queryLengthThreshold) {
              handleGetAddressSuggestions(value)
            }
          }
        }}
      />
      <TextInput
        value={formData?.[fieldTypes.address2.id]}
        placeholder={fieldTypes.address2.placeholder}
        helperText={t('components:MailingAddressForm.address2HelperText')}
        onInputChange={(value: string) => {
          handleInputChange({ [fieldTypes.address2.id]: value })
        }}
        isInvalid={
          invalidFields.includes(fieldTypes.address2.id) && displayValidation
        }
      />
      <TextInput
        value={formData?.[fieldTypes.city.id]}
        placeholder={fieldTypes.city.placeholder}
        onInputChange={(value: string) => {
          handleInputChange({ [fieldTypes.city.id]: value })
        }}
        isInvalid={
          invalidFields.includes(fieldTypes.city.id) && displayValidation
        }
      />
      <Box sx={{ my: '1.25rem', width: '100%' }}>
        <Select
          options={PROVINCE_CODES}
          selectId={fieldTypes.province.id}
          placeholder={fieldTypes.province.placeholder}
          value={formData?.[fieldTypes.province.id]}
          onInputChange={(value) => {
            handleInputChange({ [fieldTypes.province.id]: value })
          }}
          isInvalid={
            invalidFields.includes(fieldTypes.province.id) && displayValidation
          }
        />
      </Box>
      <TextInput
        placeholder={fieldTypes.postalCode.placeholder}
        value={formData?.[fieldTypes.postalCode.id]}
        validationMessage={t(
          'components:MailingAddressForm.validation.postalCode',
        )}
        onInputChange={(value: string) => {
          handleInputChange({ [fieldTypes.postalCode.id]: value })
        }}
        isInvalid={
          invalidFields.includes(fieldTypes.postalCode.id) && displayValidation
        }
      />

      <Box sx={{ mt: '1.5rem', width: '170px' }}>
        <Button
          fullWidth
          type="submit"
          label={t('common:submit')}
          working={working}
          variant="contained"
          spinnerPadding="0.429rem 6.829rem"
          spinnerColor={themeColorTypes.GREY}
          data-testid="mailing-address-form-submit-button"
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps)(MailingAddressForm)
