import { isAfter } from 'date-fns'
import { createLogic } from 'redux-logic'

import { errorHandler, PATHS, request } from '../../../../../common/request'
import mongoIdToDate from '../../../../../common/utils/mongoIdToDate'
import questionnaireActions from '../questionnaireActions'
import questionnaireSelectors from '../questionnaireSelectors'
import questionnaireTypes from '../questionnaireTypes'

export default createLogic({
  name: 'questionnaireFirstCompleted',
  type: [questionnaireTypes.SET_QUESTIONNAIRE_VALID],

  // eslint-disable-next-line consistent-return
  validate({ getState, action }, allow, reject) {
    const state = getState()

    const questionnaireFirstCompleted =
      questionnaireSelectors.getQuestionnaireFirstCompleted(state.questionnaire)

    if (!questionnaireFirstCompleted) {
      const questionnaireValid = action.payload
      const questionnaireId = questionnaireSelectors.getQuestionnaireId(
        state.questionnaire,
      )

      const questionnaireCreatedDate = mongoIdToDate(questionnaireId)

      /*
       * This is the date that this feature went live. Because legacy users
       * will not have a 'questionnaireFirstCompleted' property/value set when they
       * log back into their accounts, if we didn't create this cut-off date, legacy
       * users could be sending false information to the server and muddying up the data.
       */
      const dateWeStartedTrackingQuestionnaireFirstCompleted = new Date(
        'September 2, 2021',
      )

      const questionnaireCreatedAfterFeatureWentLive = isAfter(
        questionnaireCreatedDate,
        dateWeStartedTrackingQuestionnaireFirstCompleted,
      )

      if (questionnaireValid && questionnaireCreatedAfterFeatureWentLive) {
        return allow(action)
      }
    }

    reject(action)
  },

  async process({ getState }, dispatch, done) {
    const state = getState()
    const questionnaireId = questionnaireSelectors.getQuestionnaireId(
      state.questionnaire,
    )
    const token = questionnaireSelectors.getToken(state.questionnaire)

    try {
      await request({
        method: 'POST',
        params: { token },
        url: `${PATHS.QUESTIONNAIRE_QUESTIONNAIRE_FIRST_COMPLETED}/${questionnaireId}`,
      })

      dispatch(
        questionnaireActions.setQuestionnaireProperties({
          questionnaireFirstCompleted: new Date(),
        }),
      )
    } catch (error) {
      errorHandler({ error, reportError: true })
    }

    done()
  },
})
