/*
 * TODO: This question was slapped together very quickly the day before our launch
 * in BC. I had to figure out how to make the first option of the inheritance age question
 * dynamic based on the age of majority in the user's selected province. This component
 * may not even make sense. If not, I apologize! Please feel free to clean it all up or
 * figure out how to get rid of it entirely.
 * - Adam
 */

import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

import { Button, Text } from 'grommet'
import { LinkPrevious } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import tw from '../../../../../common/styles/tw'
import navigationActions from '../../../ducks/navigation/navigationActions'
import QuickRadioField from '../../Field/QuickRadioField/QuickRadioField'
import inheritanceAgeOptions from './utils/inheritanceAgeOptions'

const PaddedButton = styled(Button)`
  padding: ${tw.spacing['2']} ${tw.spacing['3']};
`
const InheritanceAge = ({
  question,
  answerStore,
  answerCache,
  dispatchPreviousQuestion,
  handleFieldFragmentUpdate,
}) => {
  const { province } = answerStore

  const [field] = question.fields

  // prevents mutation of the field object
  const clonedField = _cloneDeep(field)

  /*
   * adds an 'options' property to the field object and injects the
   * user's province to receive a dynamic set of inheritance age options
   */
  _set(clonedField, 'options', inheritanceAgeOptions({ province }))

  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center">
      <QuickRadioField
        field={clonedField}
        answerStore={answerStore}
        answerCache={answerCache}
        handleFieldFragmentUpdate={handleFieldFragmentUpdate}
      />

      <div className="mt-8">
        <PaddedButton
          plain
          hoverIndicator
          onClick={dispatchPreviousQuestion}
          icon={<LinkPrevious className="-mr-2" color="grey-5" size="small" />}
          label={
            <Text color="grey-5" size="small">
              {t('common:back')}
            </Text>
          }
        />
      </div>
    </div>
  )
}

InheritanceAge.propTypes = {
  dispatchPreviousQuestion: PropTypes.func.isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
  answerCache: PropTypes.shape({ province: PropTypes.string }).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchPreviousQuestion: () =>
    dispatch(navigationActions.previousQuestion()),
})

export default connect(null, mapDispatchToProps)(InheritanceAge)
