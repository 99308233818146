// TAG: notify-appointees
import React from 'react'

import { Box, Divider, useMediaQuery } from '@mui/material'

import balloon from '../../../../../../../../common/assets/images/balloon.svg'
import logo from '../../../../../../../../common/assets/images/logo.svg'
import Link from '../../../../../../../../common/components/atoms/Link'
import Text from '../../../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'
import { BatchedAppointee } from '../utils/batchAppointees'

interface PreviewTextProps {
  children: React.ReactNode
  isMobile: boolean
  weight?: 'bold'
}

const PreviewText = ({
  weight = undefined,
  isMobile,
  children,
}: PreviewTextProps) => (
  <Text
    weight={weight}
    sx={{
      mt: '1.1rem',
      lineHeight: '1.5',
      fontSize: isMobile ? '0.92rem' : '0.97rem',
    }}
  >
    {children}
  </Text>
)

interface Props {
  userLastName: string
  userFirstName: string
  appointeeRoles: BatchedAppointee['roles']
  customerReferralCode?: string
}

const NotifyAppointeePreview = ({
  userFirstName,
  userLastName,
  appointeeRoles,
  customerReferralCode = undefined,
}: Props) => {
  const referralUrl = `https://epiloguewills.com/?ref=customer-referral&reftag=${customerReferralCode}`

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        px: '1.4rem',
        py: '2.2rem',
        borderRadius: '9px',
        border: `1px dashed ${
          themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN]
        }`,
        backgroundColor:
          themeColors[themeColorTypes.NEUTRAL][themeColorVariants.LIGHTER],
      }}
    >
      <Box sx={{ width: '35%', mx: 'auto', minWidth: '125px' }}>
        <img src={logo} alt="Epilogue logo" />
      </Box>

      <Divider
        sx={{
          my: '1.5rem',
          borderBottom: `1px solid ${
            themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN]
          }`,
        }}
      />

      <PreviewText isMobile={isMobile}>
        {userFirstName} {userLastName} has completed their estate planning with
        Epilogue and has assigned you the role(s) of {appointeeRoles}.
      </PreviewText>

      <PreviewText isMobile={isMobile} weight="bold">
        Why are you receiving this email?
      </PreviewText>

      <PreviewText isMobile={isMobile}>
        {userFirstName} sent you this email through the Epilogue platform. They
        wanted to let you know about the responsibility you&apos;ll have in
        managing their estate.
      </PreviewText>

      <PreviewText isMobile={isMobile} weight="bold">
        What should you do next?
      </PreviewText>

      <PreviewText isMobile={isMobile}>
        If you want to learn more about the role(s) of {appointeeRoles}, you can
        visit the{' '}
        <Link openNewTab href="https://epiloguewills.com/learn">
          Epilogue Learn Centre
        </Link>
        . You can also talk to {userFirstName} if you have specific questions
        for them.
      </PreviewText>

      {customerReferralCode && (
        <>
          <PreviewText isMobile={isMobile} weight="bold">
            Have you made your Will yet?
          </PreviewText>

          <PreviewText isMobile={isMobile}>
            If you&apos;d like to create your own Will with Epilogue,{' '}
            <Link openNewTab href={referralUrl}>
              click here
            </Link>{' '}
            to get started and <b>save $20</b> at checkout.
          </PreviewText>
        </>
      )}

      <Box sx={{ width: '225px', mx: 'auto', mt: '2.1rem' }}>
        <img src={balloon} alt="Balloon" />
      </Box>

      <Divider
        sx={{
          my: '1.5rem',
          borderBottom: `1px solid ${
            themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN]
          }`,
        }}
      />

      <Text size="xxxs" align="center" sx={{ lineHeight: '1.4' }}>
        Epilogue
      </Text>
      <Text size="xxxs" align="center" sx={{ lineHeight: '1.4' }}>
        c/o The Legal Innovation Zone
      </Text>
      <Text size="xxxs" align="center" sx={{ lineHeight: '1.4' }}>
        10 Dundas Street East, Suite 1002
      </Text>
      <Text size="xxxs" align="center" sx={{ lineHeight: '1.4' }}>
        Toronto, ON M5B 2G9
      </Text>
      <Text size="xxxs" align="center" sx={{ lineHeight: '1.4' }}>
        (289) 678-1689
      </Text>
    </Box>
  )
}

export default NotifyAppointeePreview
