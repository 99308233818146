import { CONSTANTS } from '@epilogue/common'

export default (languageType: string) =>
  CONSTANTS.languageTypes.FR_CA.id === languageType
    ? `
#### Qui est Eirène ?

Eirène propose une approche moderne et attentionnée aux services de crémation. Les arrangements peuvent être pris confortablement de votre domicile avec le soutien de conseillers funéraires, en ligne ou par téléphone.

#### Pourquoi planifier votre crémation à l'avance?

Avec plus de 75 % des Canadiens choisissant la crémation, la planification à l'avance est un moyen simple d'aider votre famille au moment où elle en a le plus besoin.

Vous pouvez tout planifier en ligne en moins de 20 minutes, aucun rendez-vous n'est requis. Vous signez le contrat en verrouillant le prix du jour sans aucune pression, ce qui vous protège, vous et votre famille. Eirène garantit que, le moment venu, un simple coup de fil suffit pour lancer le processus, facilitant ainsi cette période difficile pour vos proches.

#### Combien ça coûte ?
Pour un coût tout inclus de 2 500 $ + taxes, Eirène offre un service de crémation transparent et complet.`
    : `
#### Who is Eirene?

Eirene provides a modern and caring approach to cremation services. Arrangements can be made from the comfort of your home with the support of funeral directors — online or over the phone.

#### Why pre-plan your cremation?

With more than 75% of Canadians choosing cremation, planning ahead is a simple way to help your family when they need it most.

You can plan online in less than 20 minutes, no appointments are required. You sign the contract locking in today's price without pressure, which protects you and your family. Eirene ensures that when the time comes, one phone call is all that's needed to start the process, making that challenging time for those you love, much easier.

#### How much does it cost?

At an all-inclusive cost of $2,500 + tax, Eirene provides a transparent, comprehensive cremation service.`
