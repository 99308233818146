import { CONSTANTS } from '@epilogue/common'

import amplitude from '../lib/amplitude'
import sessionStore from '../lib/sessionStore'

interface Props {
  loginRedirect?: string
  preventRedirect?: boolean
  loginType: 'username' | 'reset' | 'google'
}

export default ({
  loginType,
  loginRedirect,
  preventRedirect = false,
}: Props) => {
  // If and when we need to remove additional keys from session
  // storage pass an array of keys to sessionStore.remove() as
  // opposed to multiple calls to sessionStore.remove()
  sessionStore.remove(
    CONSTANTS.sessionStorageKeys.DISMISSED_QUESTIONS_CHANGED_MODAL,
  )

  // Send Amplitude login event
  amplitude.sendEvent('LoggedIn', { loginType })

  /*
   * TODO: fix this terrible solution to a ridiculously inconsistent bug.
   * Randomly and rarely, after a user logs in, the site would crash due to
   * the React (Maximum update depth exceeded) error.
   * I really couldn't isolate the issue, but this window reload seems to solve it.
   * SORRY!!
   * - Adam
   */
  if (!preventRedirect) {
    window.location.href = loginRedirect || '/login'
  }
}
