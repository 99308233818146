import { useState } from 'react'

import { errorHandler, PATHS, request } from '../../../request'
import afterLogin from '../../../utils/afterLogin'

const statusStates = {
  ERROR: 'error',
  WORKING: 'working',
  INACTIVE: 'inactive',
}

interface Props {
  email: string
  password: string
  onError: () => void
  loginRedirect?: string
}

export default ({ email, password, onError, loginRedirect }: Props) => {
  const [status, setStatus] = useState(statusStates.INACTIVE)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setStatus(statusStates.WORKING)

    try {
      await request({
        method: 'POST',
        url: PATHS.USER_LOGIN,
        data: { email, password },
      })

      afterLogin({ loginType: 'username', loginRedirect })
    } catch (error) {
      errorHandler({
        error,
        reportError: false,
        onError: () => {
          onError()
          setStatus(statusStates.ERROR)
        },
      })
    }
  }

  return { statusStates, status, handleSubmit }
}
