import { CONSTANTS } from '@epilogue/common'

import getWorldFromUrl from '../../../common/utils/getWorldFromUrl'
import basics from './sections/basics'
import charities from './sections/charities'
import complete from './sections/complete'
import distribution from './sections/distribution'
import family from './sections/family'
import guardians from './sections/guardians'
import personalPoa from './sections/personalPoa'
import propertyPoa from './sections/propertyPoa'
import social from './sections/social'
import socialComplete from './sections/socialComplete'
import trustee from './sections/trustee'

export const dataSets = {
  [CONSTANTS.worldTypes.will.type]: [
    basics,
    family,
    guardians,
    charities,
    distribution,
    trustee,
    propertyPoa,
    personalPoa,
    complete,
  ],
  [CONSTANTS.worldTypes.social.type]: [social, socialComplete],
}

const worldType = getWorldFromUrl()

export default dataSets[worldType]
