import React, { useState } from 'react'
import PropTypes from 'prop-types'

import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'

import cn from 'classnames'
import { Button, Text } from 'grommet'
import { FormClose } from 'grommet-icons'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import listItemAnimation from '../../../../../common/animations/listItemAnimation'
import Select from '../../../../../common/components/atoms/Select'
import TextInput from '../../../../../common/components/atoms/TextInput'
import tKeyRelationshipOptions from '../../../data/utils/tKeyRelationshipOptions'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'

const StyledButton = styled(Button)`
  height: 38px;
`
const newGift = () => ({ id: Date.now().toString() })
const Gifts = ({
  handleFieldFragmentUpdate,
  answerCache,
  question,
  displayValidation,
}) => {
  const { fields, forbiddenTerms } = question
  const [field] = fields

  const [gifts, setGifts] = useState(answerCache.giftDetails || [newGift()])

  const handleUpdate = (theGifts) => {
    setGifts(theGifts)
    handleFieldFragmentUpdate({ [field.name]: theGifts })
  }

  const handleAddGift = () => {
    handleUpdate([...gifts, newGift()])
  }

  const handleRemoveGift = (index) => {
    const giftsClone = _cloneDeep(gifts)
    giftsClone.splice(index, 1)
    handleUpdate(giftsClone)
  }

  const { t } = useTranslation()

  return (
    <>
      {!_isEmpty(gifts) && (
        <div className="w-full max-w-lg">
          <div className="text-center">
            <ValidationMessage question={question} field={field} />
          </div>

          <AnimatePresence initial={false}>
            {gifts.map((gift, index) => (
              <motion.div
                data-testid="gift-details-item"
                className="flex justify-between items-center"
                key={gift.id}
                {...listItemAnimation()}
              >
                {gifts.length > 1 && (
                  <div className="mr-3">
                    <Button
                      data-testid="gift-details-item-close"
                      plain
                      hoverIndicator={{ color: 'grey-2', opacity: 0.3 }}
                      onClick={() => handleRemoveGift(index)}
                      label={
                        <div className="p-1">
                          <FormClose color="grey-3" />
                        </div>
                      }
                    />
                  </div>
                )}

                <div className="flex flex-col justify-between w-full py-3">
                  {/* description */}
                  <div className="w-full">
                    <TextInput
                      value={gift.description}
                      placeholder={t('customQuestion:Gifts.input1.placeholder')}
                      placeholder2={t(
                        'customQuestion:Gifts.input1.placeholder2',
                      )}
                      isInvalid={displayValidation && !gift.description}
                      validationMessage=""
                      forbiddenTerms={forbiddenTerms}
                      onInputChange={(value) => {
                        const giftsClone = _cloneDeep(gifts)
                        giftsClone[index].description = value
                        handleUpdate(giftsClone)
                      }}
                    />
                  </div>

                  <div className="flex flex-col w-full flex-wrap sm:flex-row sm:justify-between">
                    {/* recipient */}
                    <div className="w-full sm:w-1/2">
                      <TextInput
                        value={gift.recipient}
                        placeholder={t(
                          'customQuestion:Gifts.input2.placeholder',
                        )}
                        isInvalid={displayValidation && !gift.recipient}
                        validationMessage=""
                        onInputChange={(value) => {
                          const giftsClone = _cloneDeep(gifts)
                          giftsClone[index].recipient = value
                          handleUpdate(giftsClone)
                        }}
                      />
                    </div>

                    {/* relationship */}
                    <div
                      className={cn(
                        'ml-0 mt-3 w-full mb-8',
                        'sm:w-2/5 sm:ml-2 sm:mt-0 sm:mb-4',
                      )}
                    >
                      <Select
                        value={gift.relationship}
                        selectId={gift.id}
                        isInvalid={displayValidation && !gift.relationship}
                        validationMessage=""
                        options={tKeyRelationshipOptions}
                        placeholder={t('common:thisPersonIsMy')}
                        onInputChange={(value) => {
                          const giftsClone = _cloneDeep(gifts)
                          giftsClone[index].relationship = value
                          handleUpdate(giftsClone)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}

      <div className="mt-8 mb-6 w-full flex justify-center">
        <StyledButton
          primary
          color="neutral-3"
          onClick={handleAddGift}
          data-testid="add-another-gift-button"
          label={
            <div className="text-brand">
              <Text size="small" weight="bold">
                <span className="mr-1">+</span>{' '}
                {t('customQuestion:Gifts.button.label')}
              </Text>
            </div>
          }
        />
      </div>
    </>
  )
}

Gifts.propTypes = {
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  answerCache: PropTypes.shape({
    giftDetails: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    forbiddenTerms: PropTypes.arrayOf(
      PropTypes.shape({
        errMsg: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({ tKey: PropTypes.string }),
        ]).isRequired,
        terms: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ),
  }).isRequired,
  displayValidation: PropTypes.bool.isRequired,
}

export default Gifts
