/* eslint-disable consistent-return */

/*
 * This recursive function finds the next triggered question based on the
 * current question's index. This function is different from
 * nextUnansweredQuestion because nextUnansweredQuestion
 * starts from the beginning of the data and finds the earliest unanswered
 * question, whereas nextTriggeredQuestion starts from
 * the current index and returns whatever triggered question comes soonest
 * after the current question.
 *
 * A good use-case for the nextUnansweredQuestion method
 * would be to determine whether or not the questionnaire is complete.
 *
 * A good use-case for this funtion is to determine which question to display to a
 * user when they click "Save & Continue" while in the middle of the questionnaire.
 */

import allQuestions from './allQuestions'
import isTriggered from './isTriggered'
import shouldShowQuestion from './shouldShowQuestion'

const nextTriggeredQuestion = (
  {
    abTest,
    payments,
    referrer,
    isPartner,
    documents,
    answerStore,
    data: theData,
    accountCreated,
    charityPartner,
    theCurrentQuestionIndex,
  },
  incrementIndex = 1,
) => {
  const nextIndex = theCurrentQuestionIndex + incrementIndex
  const theNextQuestion = allQuestions(theData)[nextIndex]

  if (!theNextQuestion) return

  const { trigger: nextQTrigger } = theNextQuestion

  const questionNotHidden = shouldShowQuestion({
    abTest,
    referrer,
    payments,
    isPartner,
    documents,
    answerStore,
    data: theData,
    accountCreated,
    charityPartner,
    question: theNextQuestion,
  })

  if (questionNotHidden) {
    if (nextQTrigger) {
      if (
        isTriggered(nextQTrigger, {
          abTest,
          referrer,
          isPartner,
          answerStore,
          charityPartner,
        })
      ) {
        return theNextQuestion
      }
    } else {
      return theNextQuestion
    }
  }

  return nextTriggeredQuestion(
    {
      abTest,
      referrer,
      payments,
      isPartner,
      documents,
      answerStore,
      data: theData,
      charityPartner,
      accountCreated,
      theCurrentQuestionIndex,
    },
    incrementIndex + 1,
  )
}

export default nextTriggeredQuestion
