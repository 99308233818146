import React from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import Button from '../../../../../../../../../common/components/atoms/Button'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../../common/styles/muiTheme'

interface PrimaryButtonProps {
  label: string
  working?: boolean
  disabled?: boolean
  forwardArrow?: boolean
  onClick: (e?: any) => any
  onDisabledClick?: () => void
}

const PrimaryButton = ({
  label,
  working,
  onClick,
  disabled,
  onDisabledClick,
  forwardArrow = true,
}: PrimaryButtonProps) => (
  <Button
    label={label}
    working={working}
    disabled={disabled}
    variant="contained"
    iconFontSize="1.15rem"
    spinnerPadding="0.375rem 2rem"
    onDisabledClick={onDisabledClick}
    color={themeColorTypes.ACCENT_2}
    spinnerColor={themeColorTypes.GREY}
    colorVariant={themeColorVariants.MAIN}
    data-testid="documents-guide-modal-primary-button"
    endIcon={
      !working && forwardArrow && <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />
    }
    onClick={(arg) => {
      onClick(arg)
    }}
  />
)

export default PrimaryButton
