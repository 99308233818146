import React from 'react'

import { Box, Fade } from '@mui/material'

import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import growAnimation from '../../../../../../../../../common/animations/growAnimation'
import { ReactComponent as SuccessCheck } from '../../../../../../../../../common/assets/images/success-check.svg'
import Heading from '../../../../../../../../../common/components/atoms/Heading'

const CancelWitnessingSuccess = () => {
  const { t } = useTranslation()

  return (
    <Fade in>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          px: '1.2rem',
          py: { xxs: '1rem', md: '2rem' },
        }}
        data-testid="cancel-witnessing-success"
      >
        <Box {...growAnimation} component={motion.div}>
          <Box
            width="55px"
            display="flex"
            sx={{ mb: '0.5rem' }}
            justifyContent="center"
          >
            <SuccessCheck />
          </Box>
        </Box>

        <Heading align="center" variant="h3">
          {t(
            'documents:components.modals.PendingWitnessingAppointmentModal.components.CancelWitnessingSuccess.label',
          )}
        </Heading>
      </Box>
    </Fade>
  )
}

export default CancelWitnessingSuccess
