import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Text from '../../../../../../common/components/atoms/Text'

const NamePreview = ({ nameValues, hasCommonName }) => {
  const { t } = useTranslation()

  const {
    first: firstName,
    middle: middleName = '',
    last: lastName,
    common: commonName = '',
  } = nameValues

  return (
    <Box data-testid="name-preview" width="100%">
      <Box pb="0.2rem">
        <Text align="center" color="grey" size="xs">
          {t('customQuestion:Name.components.NamePreview.title')}
        </Text>
      </Box>
      <Text weight="bold" align="center">
        {`${`${firstName} ${middleName} ${lastName}`.toUpperCase()} ${
          hasCommonName && commonName
            ? `(${t('customQuestion:Name.components.NamePreview.parens')} ${commonName.toUpperCase()})`
            : ''
        }`}
      </Text>
    </Box>
  )
}

NamePreview.propTypes = {
  nameValues: PropTypes.shape({
    first: PropTypes.string,
    middle: PropTypes.string,
    last: PropTypes.string,
    common: PropTypes.string,
  }).isRequired,
  hasCommonName: PropTypes.bool.isRequired,
}

export default NamePreview
