import _get from 'lodash/get'

import commonName from './commonName'

export default {
  getAuthWorking: (state) => _get(state[commonName], 'authWorking'),
  getAuth: (state) => _get(state[commonName], 'auth'),
  getGlobalErrorMessage: (state) =>
    _get(state[commonName], 'globalErrorMessage'),
  getWorld: (state) => _get(state[commonName], 'world'),
  getAvailableWorlds: (state) => _get(state[commonName], 'availableWorlds'),
  getToast: (state) => _get(state[commonName], 'toast'),
  getCharityPartner: (state) => _get(state[commonName], 'charityPartner', {}),
  getSidebar: (state) => _get(state[commonName], 'sidebar'),
  getLoginRedirect: (state) => _get(state[commonName], 'loginRedirect'),
}
