import _pickBy from 'lodash/pickBy'

import questionnaireTypes from './questionnaireTypes'

const initialState = {
  nameStore: [],
  answerStore: {},
  answerCache: {},
  token: undefined,
  abTest: undefined,
  referrer: undefined,
  accountCreated: false,
  questionFragments: {},
  questionnairesStarted: [],
  questionnaireId: undefined,
  newQuestionnaire: undefined,
  questionnaireValid: undefined,
  questionnaireFirstCompleted: undefined,
  answerStoreUpdatedSinceGenerate: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case questionnaireTypes.BEFORE_QUESTIONNAIRE:
      return { ...state, newQuestionnaire: true }

    case questionnaireTypes.UPDATE_ANSWER_STORE:
      return {
        ...state,
        /*
         * the payload below (cleansedAnswerStore) has already been cleansed in the
         * operations middleware, so we don't need to spread the old answerStore state
         */
        answerStore: action.payload.cleansedAnswerStore,
        answerCache: {
          ...state.answerCache,
          /*
           * Removes properties with a value of undefined from the
           * object because this would remove the property from the
           * answerCache, which is not what we want to do.
           */
          ..._pickBy(
            action.payload.answerStoreFragment,
            (value) => value !== undefined,
          ),
        },
      }

    case questionnaireTypes.SET_QUESTIONNAIRE_PROPERTIES:
      return { ...state, ...action.payload }

    case questionnaireTypes.UPDATE_QUESTIONNAIRES_STARTED:
      return {
        ...state,
        questionnairesStarted: [
          ...state.questionnairesStarted,
          action.payload.world,
        ],
      }

    case questionnaireTypes.CREATE_ACCOUNT_SUCCESS:
      return { ...state, accountCreated: true }

    case questionnaireTypes.SET_QUESTION_FRAGMENTS:
      return { ...state, questionFragments: action.payload.fragments }

    case questionnaireTypes.CLEAR_QUESTION_FRAGMENTS:
      return { ...state, questionFragments: {} }

    case questionnaireTypes.SET_QUESTIONNAIRE_VALID:
      return { ...state, questionnaireValid: action.payload }

    case questionnaireTypes.SET_ANSWER_STORE_UPDATED_SINCE_GENERATE:
      return { ...state, answerStoreUpdatedSinceGenerate: action.payload.bool }

    case questionnaireTypes.SET_NAME_STORE:
      return { ...state, nameStore: action.payload.nameStore }

    default:
      return state
  }
}
