import React from 'react'
import PropTypes from 'prop-types'

import { Paragraph } from 'grommet'
import { useTranslation } from 'react-i18next'

import StyledLabel from '../../../../../../common/components/atoms/StyledLabel'

const FamilyWillContent = ({ answerStore }) => {
  const { t } = useTranslation()
  const { hasPartner } = answerStore

  return (
    <div className="text-center">
      <StyledLabel>
        {t('customQuestion:FamilyWill.components.FamilyWillContent.label')}
      </StyledLabel>

      <div className="my-5">
        <StyledLabel>
          {hasPartner === 'yes'
            ? t(
                'customQuestion:FamilyWill.components.FamilyWillContent.label2.hasPartner.yes',
              )
            : t(
                'customQuestion:FamilyWill.components.FamilyWillContent.label2.hasPartner.no',
              )}
        </StyledLabel>
      </div>

      {hasPartner === 'yes' && (
        <Paragraph size="smedium">
          {t('customQuestion:FamilyWill.components.FamilyWillContent.subLabel')}
        </Paragraph>
      )}
    </div>
  )
}

FamilyWillContent.propTypes = {
  answerStore: PropTypes.shape({
    hasPartner: PropTypes.oneOf(['yes', 'no']),
  }).isRequired,
}

export default FamilyWillContent
