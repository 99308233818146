import validationTypes from './validationTypes'

const initialState = {
  displayValidation: false,
  invalidFields: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case validationTypes.DISPLAY_VALIDATION:
      return { ...state, displayValidation: action.payload.displayValidation }

    case validationTypes.UPDATE_VALIDATION:
      return { ...state, invalidFields: action.payload.invalidFields }

    default:
      return state
  }
}
