import { CONSTANTS } from '@epilogue/common'
import LogRocket from 'logrocket'

import { errorHandler, PATHS, request } from '../request'
import featureFlag, { flagTypes } from '../utils/featureFlag'

type NameTypes =
  (typeof CONSTANTS.eventNames)[keyof typeof CONSTANTS.eventNames]

type ActionTypes =
  (typeof CONSTANTS.eventActionTypes)[keyof typeof CONSTANTS.eventActionTypes]

interface Metadata {
  [key: string]: unknown
}

interface EventData {
  name: NameTypes
  metadata: Metadata
  action: ActionTypes
}
const send = async ({ name, action, metadata }: EventData) => {
  const sessionRecording =
    featureFlag(flagTypes.LOG_ROCKET) && LogRocket.sessionURL
      ? LogRocket.sessionURL
      : undefined

  try {
    await request({
      method: 'POST',
      url: PATHS.EVENT,
      data: {
        name,
        action,
        metadata,
        sessionRecording,
      },
    })
  } catch (error) {
    errorHandler({ error, reportError: true })
  }
}

export default { send }
