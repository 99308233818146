import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CONSTANTS } from '@epilogue/common'

import navigationActions from '../../../../scenes/Questionnaire/ducks/navigation/navigationActions'
import commonSelectors from '../../../ducks/commonSelectors'
import CardButton from './CardButton'
import CardField from './CardField'

const useStyles = makeStyles((theme) => ({
  questionWrapper: {
    padding: '0.53rem 0',

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.neutral.main}`,
    },

    '&:first-of-type': {
      marginTop: '-0.75rem',
    },

    '&:last-of-type': {
      marginBottom: '-0.75rem',
    },
  },
}))

const SummarySection = ({
  world,
  section,
  answerStore,
  dispatchToQuestion,
}) => {
  const classes = useStyles()

  return (
    <Box>
      {section.map((question) => {
        const { id, fields, optional } = question

        return (
          <Box
            key={id}
            display="flex"
            justifyContent="space-between"
            className={classes.questionWrapper}
          >
            <Box alignSelf="center" width="78%">
              {!_isEmpty(fields) &&
                fields.map((field) => (
                  <React.Fragment key={field.name}>
                    {!field.hideOnReview &&
                      !(
                        field.hideOnReviewIfEmpty &&
                        _isEmpty(answerStore[field.name])
                      ) && (
                        <CardField
                          field={field}
                          answerStore={answerStore}
                          optionalQuestion={optional}
                        />
                      )}
                  </React.Fragment>
                ))}
            </Box>

            <Box width="22%">
              <CardButton
                world={world}
                question={question}
                answerStore={answerStore}
                dispatchToQuestion={dispatchToQuestion}
              />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

SummarySection.propTypes = {
  answerStore: PropTypes.shape({}).isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
  section: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  world: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.type),
  ).isRequired,
}
const mapStateToProps = (state) => ({ world: commonSelectors.getWorld(state) })

const mapDispatchToProps = (dispatch) => ({
  dispatchToQuestion: ({ question, fromDashboard }) =>
    dispatch(navigationActions.toQuestion({ question, fromDashboard })),
})

export default connect(mapStateToProps, mapDispatchToProps)(SummarySection)
