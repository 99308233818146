import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import { RadioButton } from 'grommet'

import navigationActions from '../../../ducks/navigation/navigationActions'

const RadioField = ({
  field,
  answerCache,
  handleFieldFragmentUpdate,
  navNextAutomatic,
}) => {
  const { name, options } = field

  const [fieldValue, setFieldValue] = useState(answerCache[name] || undefined)

  useEffect(() => {
    /*
     * when radioField's value changes, dispatch navNextAutomatic action
     * the operations middleware decides whether or not to follow through
     * on the auto advance, but it still gets triggered here
     */
    if (fieldValue !== answerCache[name]) {
      setTimeout(() => {
        navNextAutomatic({ fragment: { [name]: fieldValue } })
      }, 125)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue])

  const handleSetFieldValue = (val) => {
    setFieldValue(val)
    handleFieldFragmentUpdate({ [name]: val })
  }

  return (
    <>
      {!_isEmpty(options) &&
        options.map(({ value, label }) => (
          <div key={value} className="mx-3 my-3">
            <RadioButton
              id={`${name}_${value}`}
              value={value}
              label={label}
              name={name}
              checked={fieldValue === value}
              onChange={(e) => handleSetFieldValue(e.target.checked && value)}
            />
          </div>
        ))}
    </>
  )
}

RadioField.propTypes = {
  field: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  navNextAutomatic: PropTypes.func.isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  navNextAutomatic: ({ fragment }) =>
    dispatch(navigationActions.navNextAutomatic({ fragment })),
})

export default connect(null, mapDispatchToProps)(RadioField)
