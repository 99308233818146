import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Box, useTheme } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'

import i18n from '../../../../i18n'
import questionnaireSelectors from '../../../../scenes/Questionnaire/ducks/questionnaire/questionnaireSelectors'
import commonActions from '../../../ducks/commonActions'
import commonSelectors from '../../../ducks/commonSelectors'
import useResponsive, { breakpoints } from '../../../hooks/useResponsive'
import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import featureFlag, { flagTypes } from '../../../utils/featureFlag'
import Button from '../../atoms/Button'
import Logo from './components/Logo'
import MobileNav from './components/MobileNav'
import NavItems from './components/NavItems'
import HeaderContext from './context/HeaderContext'

const Header = ({
  auth,
  world,
  accountCreated,
  availableWorlds,
  dispatchSetGlobalErrorMessage,
}) => {
  const [language, setLanguage] = useState(i18n.language)
  const [mobileNavActive, setMobileNavActive] = useState(false)
  const [worldMenuActive, setWorldMenuActive] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const [viewportWidth] = useResponsive()
  const isDesktop = viewportWidth > breakpoints.md

  const { pathname } = useLocation()

  const onDashboard = pathname.includes('dashboard')
  const onGetStarted = pathname.includes('/get-started')
  // const onSwitchboard = pathname.includes('/switchboard')
  const onQuestionnaire = pathname.includes('/questionnaire')
  const onAccountSettings = pathname.includes('/account-settings')
  const onPaymentOrAddons =
    pathname.includes('/dashboard/payment') ||
    pathname.includes('/dashboard/add-ons')

  const worldSwitcherAvailable =
    accountCreated &&
    onDashboard &&
    !onPaymentOrAddons &&
    availableWorlds.length > 1

  const headerContextData = useMemo(
    () => ({
      auth,
      world,
      isDesktop,

      onDashboard,
      onGetStarted,
      onQuestionnaire,
      onAccountSettings,

      accountCreated,
      availableWorlds,
      dispatchSetGlobalErrorMessage,

      worldMenuActive,
      setWorldMenuActive,

      mobileNavActive,
      setMobileNavActive,

      worldSwitcherAvailable,
    }),
    [
      auth,
      world,
      isDesktop,
      onDashboard,
      onGetStarted,
      accountCreated,
      onQuestionnaire,
      availableWorlds,
      worldMenuActive,
      mobileNavActive,
      onAccountSettings,
      worldSwitcherAvailable,
      dispatchSetGlobalErrorMessage,
    ],
  )

  const theme = useTheme()

  return (
    <HeaderContext.Provider value={headerContextData}>
      <Box
        sx={{
          width: '100vw',
          display: 'flex',
          height: '3.5rem',
          position: 'relative',
          justifyContent: 'center',
          zIndex: theme.zIndex.appBar,
          backgroundColor:
            theme.palette[themeColorTypes.NEUTRAL][themeColorVariants.MAIN],
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: isDesktop ? '1.5rem' : '0.75rem',
          }}
        >
          <Logo />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isDesktop ? <NavItems /> : <MobileNav />}

            {featureFlag(flagTypes.QUEBEC) && (
              <>
                <Button
                  sx={{
                    ml: '0.6rem',
                    width: '40px',
                    minWidth: '40px',
                    color: 'white',
                  }}
                  data-testid="language-button"
                  onClick={() => {
                    const nextLanguage =
                      language === CONSTANTS.languageTypes.EN_CA.id
                        ? CONSTANTS.languageTypes.FR_CA.id
                        : CONSTANTS.languageTypes.EN_CA.id

                    setLanguage(nextLanguage)
                  }}
                  label={
                    language === CONSTANTS.languageTypes.EN_CA.id
                      ? CONSTANTS.languageTypes.FR_CA.shortLabel
                      : CONSTANTS.languageTypes.EN_CA.shortLabel
                  }
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </HeaderContext.Provider>
  )
}

Header.propTypes = {
  auth: PropTypes.bool.isRequired,
  accountCreated: PropTypes.bool.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  availableWorlds: PropTypes.arrayOf(PropTypes.string).isRequired,
  world: PropTypes.oneOf(
    Object.values(CONSTANTS.worldTypes).map((worldType) => worldType.type),
  ),
}

const mapStateToProps = (state) => ({
  auth: commonSelectors.getAuth(state),
  world: commonSelectors.getWorld(state),
  availableWorlds: commonSelectors.getAvailableWorlds(state),
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
