import { useMutation } from '@tanstack/react-query'

import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../../../common/request'

interface Props {
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

interface RequestProps {
  appointmentId: string
}

export default ({ dispatchSetGlobalErrorMessage }: Props) =>
  useMutation({
    mutationFn: ({ appointmentId }: RequestProps) =>
      request({
        method: 'DELETE',
        url: `${PATHS.APPOINTMENT}/${appointmentId}`,
      }),
    onError: (error) =>
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to cancel appointment. Please try again later.',
        onError: (resolvedErrorMessage: string) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      }),
  })
