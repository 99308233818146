import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import Text from '../../../../../../common/components/atoms/Text'
import useInjectDynamic from '../../../../../../common/hooks/useInjectDynamic'
import linkStyle from './helpers/linkStyle'

const SidebarText = ({ element }) => {
  const { resolveValue } = useInjectDynamic()

  return (
    <Box data-testid="sidebar-text" sx={{ mb: '1rem' }}>
      {element.header && (
        <Text sx={{ mb: '0.5rem', lineHeight: 1.4 }} size="lg">
          {element.header}
        </Text>
      )}
      <Text
        sx={{
          lineHeight: '1.5',
          ...linkStyle,
        }}
        variant="paragraph"
      >
        {/* eslint-disable react/no-danger */}
        <span
          dangerouslySetInnerHTML={{
            __html: resolveValue(element.body),
          }}
        />
        {/* eslint-enable */}
      </Text>
    </Box>
  )
}

SidebarText.propTypes = {
  element: PropTypes.shape({
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    body: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.shape({
        tKey: PropTypes.string,
      }),
    ]),
  }).isRequired,
}

export default SidebarText
