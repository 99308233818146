import commonTypes from './commonTypes'

export default {
  checkAuth: ({ fromQuestionnaire }) => ({
    type: commonTypes.CHECK_AUTH,
    payload: { fromQuestionnaire },
  }),

  authWorking: (working) => ({
    type: commonTypes.AUTH_WORKING,
    payload: { working },
  }),

  setAuth: (auth) => ({
    type: commonTypes.SET_AUTH,
    payload: { auth },
  }),

  setGlobalErrorMessage: (errorMsg) => ({
    type: commonTypes.SET_GLOBAL_ERROR_MESSAGE,
    payload: { errorMsg },
  }),

  setWorld: () => ({ type: commonTypes.SET_WORLD }),

  setAvailableWorlds: ({ answerCache }) => ({
    type: commonTypes.SET_AVAILABLE_WORLDS,
    payload: { answerCache },
  }),

  setLoginRedirect: (loginRedirect) => ({
    type: commonTypes.SET_LOGIN_REDIRECT,
    payload: { loginRedirect },
  }),

  updateToast: (toastProperties) => ({
    type: commonTypes.UPDATE_TOAST,
    payload: {
      toastProperties: {
        isOpen: true,
        type: undefined,
        label: undefined,
        message: undefined,
        ...toastProperties,
      },
    },
  }),

  setCharityPartner: ({ payload }) => ({
    type: commonTypes.SET_CHARITY_PARTNER,
    payload,
  }),

  updateSidebar: (sidebarProperties) => ({
    type: commonTypes.UPDATE_SIDEBAR,
    payload: {
      sidebarProperties: {
        isOpen: false,
        ...sidebarProperties,
      },
    },
  }),
}
