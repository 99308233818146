import React from 'react'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'

import { Button, Text } from 'grommet'
import { DocumentVerified, LinkNext } from 'grommet-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import strToKebab from '../../../../../../common/formatting/strToKebab'
import tw from '../../../../../../common/styles/tw'

const StyledButton = styled(Button)`
  border: ${tw.borderWidth.default} ${tw.colors['accent-2-lighter']} solid;
  border-radius: ${tw.borderRadius.default};
  height: ${tw.spacing['8']};
  width: 7rem;
  font-weight: 500;
`
const ButtonInfo = (status, nextQuestion, sectionIntroQuestion) => {
  const { t } = useTranslation()

  switch (status) {
    case 'unstarted':
    case 'optional':
      return {
        text: t('dashboard:components.SectionCardButton.optional.label'),
        Icon: <LinkNext color="brand" size="small" />,
        to: sectionIntroQuestion,
      }

    case 'inProgress':
      return {
        text: t('dashboard:components.SectionCardButton.inProgress.label'),
        Icon: <LinkNext color="brand" size="small" />,
        to: nextQuestion,
      }

    case 'complete':
      return {
        text: t('dashboard:components.SectionCardButton.complete.label'),
        Icon: <DocumentVerified color="brand" size="small" />,
        to: sectionIntroQuestion,
      }

    default:
      return {}
  }
}

const SectionCardButton = ({
  dispatchToQuestion,
  sectionIntroQuestion = {},
  nextQuestion,
  status,
  label,
}) => {
  const { text, Icon, to } = ButtonInfo(
    status,
    nextQuestion,
    sectionIntroQuestion,
  )

  const navigate = useNavigate()

  return (
    <StyledButton
      data-testid={`section-card-button-${strToKebab(label)}`}
      primary
      reverse
      color="grey-1"
      label={
        <Text className="-mr-1" color="brand" size="small">
          {text}
        </Text>
      }
      icon={Icon}
      onClick={() => {
        if (status === 'complete') {
          navigate(`/dashboard/summary#${strToKebab(label)}`)
        } else {
          // update questionStack
          dispatchToQuestion({ question: to })
          // send user to questionnaire
          navigate('/questionnaire')
        }
      }}
    />
  )
}

SectionCardButton.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  sectionIntroQuestion: PropTypes.shape({}),
  nextQuestion: PropTypes.shape({}).isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
}

export default SectionCardButton
