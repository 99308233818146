import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FeatherSunrise } from '../../../../../../../common/assets/images/feather-sunrise.svg'
import Checkbox from '../../../../../../../common/components/atoms/Checkbox'
import Link from '../../../../../../../common/components/atoms/Link'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../../common/lib/amplitude'
import AgreeButton from './components/AgreeButton'
import agreeModalTypes from './utils/agreeModalTypes'

const AgreeModal = ({
  type,
  email,
  checked,
  onClose,
  password,
  questionnaireId,
  onMailingListCheckUpdate,
}) => {
  useEffect(() => {
    amplitude.sendEvent('ViewedAgreeModal')
  }, [])

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const oauthType = CONSTANTS.oauth.types.CREATE_ACCOUNT

  const { t } = useTranslation()

  return (
    <Modal
      maxWidth="sm"
      show={!!type}
      onClose={onClose}
      data-testid={`agree-modal-${type}`}
    >
      <Box
        display="flex"
        maxWidth="380px"
        alignItems="center"
        flexDirection="column"
      >
        <FeatherSunrise />

        <Box pt="1.8rem" textAlign="center">
          <Text size="xl" display="initial">
            {t('molecules:AgreeModal.label.part1')}
            <Link
              openNewTab
              underline="always"
              href="https://www.epiloguewills.com/user-agreement"
            >
              <Text display="initial" size="xl" weight="bold">
                {t('molecules:AgreeModal.label.part2')}
              </Text>
            </Link>
            .
          </Text>
        </Box>

        <Box
          lineHeight={0.8}
          p={isDesktop ? '1.35rem 2rem 0' : '1.35rem 0 0'}
          textAlign="center"
        >
          <Text size="xxs">{t('molecules:AgreeModal.subLabel')}</Text>
        </Box>

        <Box sx={{ textAlign: 'center', py: '1rem' }}>
          <Checkbox
            size="small"
            labelSize="xs"
            checked={checked}
            label={
              <>
                <Link
                  openNewTab
                  underline="always"
                  href="https://www.epiloguewills.com/casl"
                >
                  <Text display="inline" size="xs" weight="bold">
                    {t('molecules:AgreeModal.checkbox.part1')}
                  </Text>
                </Link>
                {t('molecules:AgreeModal.checkbox.part2')}
              </>
            }
            onChange={onMailingListCheckUpdate}
            data-testid="agree-modal-mailing-list-checkbox"
          />
        </Box>

        <AgreeButton
          type={type}
          email={email}
          password={password}
          oauthType={oauthType}
          closeModal={onClose}
          mailingList={checked}
          questionnaireId={questionnaireId}
        />
      </Box>
    </Modal>
  )
}

AgreeModal.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  questionnaireId: PropTypes.string.isRequired,
  onMailingListCheckUpdate: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(agreeModalTypes)),
}

export default React.memo(AgreeModal)
