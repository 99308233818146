import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'

import { styled } from '@mui/material'
import { alpha } from '@mui/material/styles'

import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../styles/muiTheme'
import Text from '../../../../atoms/Text'
import HeaderContext from '../../context/HeaderContext'

const StyledNavItem = styled(NavLink)(({ theme }) => ({
  transition: 'all',
  position: 'relative',
  width: 'fit-content',
  padding: '1rem 0.85rem',

  span: {
    fontWeight: 500,
    fontSize: '0.98rem',
    position: 'relative',
    color: alpha(
      theme.palette[themeColorTypes.BRAND][themeColorVariants.MAIN],
      0.61,
    ),
    transition: 'color 0.25s ease-out',

    '&::before': {
      content: "''",
      left: 0,
      height: '2px',
      right: '100%',
      bottom: '-3px',
      position: 'absolute',
      transition: 'right 0.25s ease-out',
      backgroundColor:
        theme.palette[themeColorTypes.BRAND][themeColorVariants.MAIN],
    },
  },

  '&.active span, &:hover span': {
    color: theme.palette[themeColorTypes.BRAND][themeColorVariants.MAIN],
    '&::before': { right: 0 },
  },
}))

const NavItem = ({ id, to, label }) => {
  const { world, setMobileNavActive } = useContext(HeaderContext)

  return (
    <StyledNavItem
      end
      id={id}
      to={`${to}?w=${world}`}
      onClick={() => setMobileNavActive(false)}
    >
      <Text>{label}</Text>
    </StyledNavItem>
  )
}

NavItem.propTypes = {
  id: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default NavItem
