import React from 'react'
import PropTypes from 'prop-types'

import _truncate from 'lodash/truncate'

import { Box } from '@mui/material'

import Text from '../../atoms/Text'

const PieLegend = ({ data, colors }) => (
  <>
    {React.Children.toArray(
      data.map((d, i) => {
        const whichColor = colors[Math.floor(i % colors.length)]

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', pb: '0.25rem' }}>
            <Box
              as="span"
              sx={{
                mr: '0.5rem',
                width: '0.75rem',
                height: '0.75rem',
                display: 'inline-block',
                backgroundColor: whichColor,
              }}
            />

            <Text size="xs" weight="bold">
              ({d.value}%) {_truncate(d.title, { length: 21 })}
            </Text>
          </Box>
        )
      }),
    )}
  </>
)

PieLegend.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PieLegend
