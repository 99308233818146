import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import commonActions from '../../../../ducks/commonActions'
import amplitude from '../../../../lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../request'
import { themeColorTypes } from '../../../../styles/muiTheme'
import Button from '../../../atoms/Button'
import TextInput from '../../../atoms/TextInput'

const ChangePassword = ({
  dispatchUpdateToast,
  dispatchSetGlobalErrorMessage,
}) => {
  const { t } = useTranslation()

  const [working, setWorking] = useState(false)

  const [newPassword, setNewPassword] = useState('')
  const [newPasswordErrMsg, setNewPasswordErrMsg] = useState()

  const [currentPassword, setCurrentPassword] = useState('')
  const [currentPasswordErrMsg, setCurrentPasswordErrMsg] = useState()

  const newPasswordTooShort = !newPassword || newPassword.length < 8
  const newPasswordTooShortMsg = t('validation:validation:password')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setWorking(true)

    try {
      await request({
        method: 'POST',
        url: PATHS.USER_UPDATE_PASSWORD,
        data: { currentPassword, newPassword },
      })

      amplitude.sendEvent('ChangedPassword')

      setTimeout(() => {
        setNewPassword()
        setCurrentPassword()
        dispatchUpdateToast({
          label: t('pages:AccountSettings.changePassword.toast.label'),
          type: CONSTANTS.toastTypes.SUCCESS,
          message: t('pages:AccountSettings.changePassword.toast.message'),
        })
        setWorking(false)
      }, 1650)
    } catch (error) {
      setWorking(false)
      const { status } = error.response
      const statusIs400 = status === 400
      errorHandler({
        error,
        /*
         * 400 means that the entered 'current password' was incorrect.
         * We do not need to report that.
         *
         * Send error report for any other error status.
         */
        reportError: !statusIs400,
        fallbackErrorMessage:
          'Unable to change password. Please refresh your browser and try again.',
        onError: (resolvedErrorMessage) => {
          setCurrentPassword()
          if (statusIs400) {
            return setCurrentPasswordErrMsg(
              resolvedErrorMessage || 'Incorrect current password',
            )
          }
          return dispatchSetGlobalErrorMessage(resolvedErrorMessage)
        },
      })
    }
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 'xs',
        display: 'flex',
        margin: '0 auto',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ width: '100%', mt: '1.15rem' }}>
        <TextInput
          type="password"
          passwordVisibility
          value={currentPassword}
          placeholder={t(
            'pages:AccountSettings.changePassword.textInput.input1.placeholder',
          )}
          onInputChange={(val) => {
            if (!working) {
              if (currentPasswordErrMsg) {
                // Clears error message when user starts to type
                setCurrentPasswordErrMsg()
              }
              setCurrentPassword(val)
            }
          }}
          isInvalid={!!currentPasswordErrMsg}
          validationMessage={currentPasswordErrMsg}
        />
      </Box>

      <Box sx={{ width: '100%' }}>
        <TextInput
          type="password"
          passwordVisibility
          value={newPassword}
          placeholder={t(
            'pages:AccountSettings.changePassword.textInput.input2.placeholder',
          )}
          onInputChange={(val) => {
            if (!working) {
              if (newPasswordErrMsg) {
                // Clears error message when user starts to type
                setNewPasswordErrMsg()
              }
              setNewPassword(val)
            }
          }}
          isInvalid={
            !!(newPasswordErrMsg || (newPassword && newPasswordTooShort))
          }
          validationMessage={
            newPasswordErrMsg ||
            (newPasswordTooShort ? newPasswordTooShortMsg : undefined)
          }
          helperText={t('validation:helpers.password')}
        />
      </Box>

      <Box sx={{ mt: '0.7rem' }}>
        <Button
          type="submit"
          working={working}
          variant="contained"
          label={t('pages:AccountSettings.changePassword.button.label')}
          spinnerPadding="0.375rem"
          spinnerColor={themeColorTypes.WHITE}
          disabled={!currentPassword || newPasswordTooShort}
        />
      </Box>
    </Box>
  )
}

ChangePassword.propTypes = {
  dispatchUpdateToast: PropTypes.func.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
  dispatchUpdateToast: (toastProperties) =>
    dispatch(commonActions.updateToast(toastProperties)),
})

export default connect(null, mapDispatchToProps)(ChangePassword)
