import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'

const SidebarQuestionmark = ({ sx = {} }) => (
  <Box
    sx={{
      mx: '0.7rem',
      width: '25px',
      height: '25px',
      display: 'flex',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: (theme) => theme.shape.borderRadiusFull,
      color: (theme) =>
        theme.palette[themeColorTypes.WHITE][themeColorVariants.MAIN],
      bgcolor: (theme) =>
        theme.palette[themeColorTypes.ACCENT_3][themeColorVariants.MAIN],
      ...sx,
    }}
  >
    ?
  </Box>
)

SidebarQuestionmark.propTypes = {
  sx: PropTypes.shape({}),
}

export default SidebarQuestionmark
