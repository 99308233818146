export default [
  {
    type: 'accordion',
    header: { tKey: 'sidebar:distributionRemoteSidebar.item1.header' },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionRemoteSidebar.item1.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionRemoteSidebar.item1.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionRemoteSidebar.item1.children.item3.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: { tKey: 'sidebar:distributionRemoteSidebar.item2.header' },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionRemoteSidebar.item2.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:distributionRemoteSidebar.item2.children.item2.body',
        },
      },
    ],
  },
]
