import React from 'react'

import { Box, ListItem } from '@mui/material'

import { MarkdownToJSX } from 'markdown-to-jsx'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../styles/muiTheme'
import Heading from '../../atoms/Heading'
import Link from '../../atoms/Link'
import Text from '../../atoms/Text'

export enum MarkdownStyleTypes {
  DEFAULT = 'default',
}

type StyleTypes = {
  [key in MarkdownStyleTypes]?: MarkdownToJSX.Overrides
}

const DEFAULT_LINE_HEIGHT = '1.5'
const DEFAULT_LIST_STYLING = {
  pl: '2rem',
  mb: '1.35rem',
  mt: '-0.3rem',
  listStylePosition: 'outside',
}

const styleTypes: StyleTypes = {
  [MarkdownStyleTypes.DEFAULT]: {
    h4: {
      component: ({ children: value }) => (
        <Heading variant="h4" sx={{ pt: '0.9rem' }}>
          {value}
        </Heading>
      ),
    },
    p: {
      component: ({ children: value }) => (
        <Box component="p" sx={{ my: '1rem' }}>
          <Text sx={{ lineHeight: DEFAULT_LINE_HEIGHT }}>{value}</Text>
        </Box>
      ),
    },
    ol: {
      component: ({ children: value }) => (
        <Box
          component="ol"
          sx={{ ...DEFAULT_LIST_STYLING, listStyleType: 'decimal' }}
        >
          {value}
        </Box>
      ),
    },
    ul: {
      component: ({ children: value }) => (
        <Box
          component="ul"
          sx={{ ...DEFAULT_LIST_STYLING, listStyleType: 'disc' }}
        >
          {value}
        </Box>
      ),
    },
    li: {
      component: ({ children: value }) => (
        <ListItem disablePadding sx={{ display: 'list-item' }}>
          <Box component="p" sx={{ my: '1rem' }}>
            <Text variant="text" sx={{ lineHeight: DEFAULT_LINE_HEIGHT }}>
              {value}
            </Text>
          </Box>
        </ListItem>
      ),
    },
    a: {
      component: ({ children: value, href }) => (
        <Link
          sx={{
            color:
              themeColors[themeColorTypes.ACCENT_1][themeColorVariants.DARK],
            textDecorationColor:
              themeColors[themeColorTypes.ACCENT_1][themeColorVariants.DARK],
          }}
          href={href}
          openNewTab
        >
          {value}
        </Link>
      ),
    },
  },
}

export default styleTypes
