import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '@mui/icons-material/Close'
import {
  Backdrop,
  Box,
  IconButton,
  Drawer as MuiDrawer,
  useMediaQuery,
} from '@mui/material'

import useScrollPosition from '../../../hooks/useScrollPosition'
import { themeColorTypes, themeColorVariants } from '../../../styles/muiTheme'
import Heading from '../Heading'

const Drawer = forwardRef(
  (
    {
      open,
      anchor = 'right',
      onClose,
      children,
      headerText,
      transitionDuration,
    },
    ref,
  ) => {
    const { sidebarRef, containerRef } = ref

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

    // This breakpoint is used to check if the sidebar is in mobile/tablet or
    // desktop view for animations and autoOpenSidebar. Make sure this value
    // is updated in all: Drawer.js, Question.js, and HelpWithThisQuestionSublabel.js
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('3xl'))

    const { y: scrollY } = useScrollPosition()

    return (
      <>
        {/* 
          Using a custom backdrop in combination with a drawer as opposed to the drawer's built
          in backdrop to allow for graceful changes between tablet and desktop views 
        */}
        <Backdrop
          sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.appBar - 2 }}
          open={open && !isDesktop}
          onClick={onClose}
          data-testid="backdrop"
        />
        <MuiDrawer
          open={open}
          anchor={anchor}
          ref={sidebarRef}
          onClose={onClose}
          variant="persistent"
          transitionDuration={transitionDuration}
          sx={{
            // Keep the z-index 1 under the header so it doesn't overlap
            zIndex: (theme) => theme.zIndex.appBar - 1,
          }}
          PaperProps={{
            'data-testid': 'drawer-content-wrapper',
            sx: {
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              zIndex: 'inherit',
              boxShadow: 'none',
              backgroundColor: (theme) =>
                theme.palette[themeColorTypes.ACCENT_3][
                  themeColorVariants.MAIN
                ],
              [anchor === 'right' ? 'borderLeft' : 'borderRight']: !isMobile
                ? (theme) =>
                    `3px solid ${
                      theme.palette[themeColorTypes.ACCENT_3][
                        themeColorVariants.MAIN
                      ]
                    }`
                : undefined,
            },
            ref: containerRef,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              // Only offset the top by the amount the header is covering
              marginTop: 56 - scrollY > 0 ? `${56 - scrollY}px` : 0,
              width: isMobile ? '100vw' : '23rem',
              position: 'relative',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <IconButton
              size="small"
              sx={{
                color: (theme) =>
                  theme.palette[themeColorTypes.WHITE][themeColorVariants.MAIN],
                top: '5px',
                position: 'absolute',
                // Set the close button to the side closest to the edge of the screen
                [anchor]: '5px',
                zIndex: (theme) => theme.zIndex.appBar,
              }}
              aria-label="Close Drawer"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>

            {headerText && (
              <Box
                sx={{
                  width: '100%',
                  height: '95px',
                  display: 'flex',
                  flexShrink: 0,
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  bgcolor: (theme) =>
                    theme.palette[themeColorTypes.ACCENT_3][
                      themeColorVariants.MAIN
                    ],
                }}
              >
                <Heading
                  variant="h4"
                  align="center"
                  color={themeColorTypes.WHITE}
                >
                  {headerText}
                </Heading>
              </Box>
            )}

            <Box
              sx={{
                padding: isMobile ? '1.2rem' : '2rem',
                paddingBottom: '7rem', // Leave space for intercom icon
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette[themeColorTypes.WHITE][themeColorVariants.MAIN],
                borderTopLeftRadius:
                  isMobile || anchor === 'right'
                    ? (theme) => theme.shape.borderRadiusLarge
                    : undefined,
                borderTopRightRadius:
                  isMobile || anchor === 'left'
                    ? (theme) => theme.shape.borderRadiusLarge
                    : undefined,
              }}
            >
              {children}
            </Box>
          </Box>
        </MuiDrawer>
      </>
    )
  },
)

Drawer.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  headerText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.oneOf(['left', 'right']),
  transitionDuration: PropTypes.number,
}

export default Drawer
