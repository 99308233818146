import _get from 'lodash/get'

import { utils } from '@epilogue/common'
import i18n from 'i18next'

import { Appointee } from '../../../../../../ducks/appointees/appointees.types'

export interface BatchedAppointee {
  id: string
  name: string
  roles: string
}

interface BatchedAppointeeObjValue {
  id: string
  name: string
  roles: string[]
}

interface BatchedAppointeeObj {
  [key: string]: BatchedAppointeeObjValue
}

// Batch appointees by name and relationship, and create roles array
export default (appointees: Appointee[] = []): BatchedAppointee[] => {
  const bactchedAppointeesObj: BatchedAppointeeObj = appointees?.reduce(
    (acc, appointee, index) => {
      const nameRelationshipValue =
        appointee.value.toLowerCase() + appointee.relationship

      const roles = [
        ..._get(acc, [nameRelationshipValue, 'roles'], []),
        appointee.label,
      ]

      return {
        ...acc,
        [nameRelationshipValue]: {
          roles,
          id: String(index),
          name: appointee.value,
        },
      }
    },
    {},
  )

  /*
   * Returns an array instead of an object. The reason we're
   * using an object above, is because it's easier to batch the
   * appointees using an object, but the components using this
   * util function expect an array.
   */
  return Object.values(bactchedAppointeesObj).map(({ roles, ...rest }) => ({
    ...rest,
    // Convert the roles array of strings into one comma-separated string
    roles: utils.arrayToCommaSeparated(roles, i18n.language),
  }))
}
