import React from 'react'
import PropTypes from 'prop-types'

import _isEmpty from 'lodash/isEmpty'

import Field from '.'

const Fields = ({ question, handleFieldFragmentUpdate }) => (
  <>
    {!_isEmpty(question.fields) &&
      question.fields.map((field) => (
        <Field
          key={field.name}
          field={field}
          handleFieldFragmentUpdate={handleFieldFragmentUpdate}
        />
      ))}
  </>
)

Fields.propTypes = {
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

export default Fields
