import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _get from 'lodash/get'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { useTranslation } from 'react-i18next'

import navigationActions from '../../../ducks/navigation/navigationActions'
import NavigationButtons from '../NavigationButtons'

const MultiOptionNavButton = ({
  primaryLabel,
  primaryQuestionFragment,
  secondaryLabel,
  secondaryQuestionFragment,
  hideSecondary = false,
  dispatchNextQuestion,
}) => {
  const { t } = useTranslation()

  return (
    <NavigationButtons
      nextLabel={t(_get(primaryLabel, 'tKey', primaryLabel))}
      handleButtonClick={() => dispatchNextQuestion(primaryQuestionFragment)}
      sandwichButtonData={{
        hidden: hideSecondary,
        label: t(_get(secondaryLabel, 'tKey', secondaryLabel)),
        icon: <ArrowForwardIcon />,
        onClick: () => dispatchNextQuestion(secondaryQuestionFragment),
      }}
    />
  )
}

MultiOptionNavButton.propTypes = {
  dispatchNextQuestion: PropTypes.func.isRequired,
  primaryLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ tKey: PropTypes.string }),
  ]).isRequired,
  primaryQuestionFragment: PropTypes.shape({}).isRequired,
  secondaryLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ tKey: PropTypes.string }),
  ]).isRequired,
  secondaryQuestionFragment: PropTypes.shape({}).isRequired,
  hideSecondary: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => ({
  dispatchNextQuestion: (questionFragments) =>
    dispatch(navigationActions.nextQuestion({ questionFragments })),
})

export default connect(null, mapDispatchToProps)(MultiOptionNavButton)
