import _isFunction from 'lodash/isFunction'

export default (label, injectedObj) => {
  if (!label) {
    return false
  }

  if (_isFunction(label)) {
    return !!label(injectedObj)
  }

  return !!label
}
