import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../common/components/atoms/Text'
import amplitude from '../../../../../../common/lib/amplitude'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'

const useStyles = makeStyles(() => ({
  bodyParagraph: {
    lineHeight: 1.5,
  },
}))

const QuestionnaireChangedModal = ({
  show,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (show) {
      amplitude.sendEvent('ViewedQuestionnaireChangedModal')
    }
  }, [show])

  const { t } = useTranslation()

  return (
    <Modal
      maxWidth="sm"
      show={show}
      onClose={(event, reason) => {
        amplitude.sendEvent('ClosedQuestionnaireChangedModal', { reason })
        onClose(event, reason)
      }}
    >
      <Box
        sx={{
          my: '2rem',
          mx: '1rem',
          rowGap: '2rem',
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        data-testid="questionnaire-changed-modal"
      >
        <Heading variant="h4">
          {t('dashboard:components.QuestionnaireChangedModal.label')}
        </Heading>

        <Text size="md" className={classes.bodyParagraph}>
          {t('dashboard:components.QuestionnaireChangedModal.subLabel')}
        </Text>

        <Box
          sx={{
            display: 'flex',
            rowGap: '0.4rem',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Button
            size="md"
            variant="contained"
            onClick={onPrimaryButtonClick}
            endIcon={<ArrowForwardIcon />}
            label={t(
              'dashboard:components.QuestionnaireChangedModal.button1Label',
            )}
            data-testid="questionnaire-changed-modal-primary-button"
          />

          <Button
            size="xs"
            iconFontSize="1rem"
            color={themeColorTypes.GREY}
            label={t(
              'dashboard:components.QuestionnaireChangedModal.button2Label',
            )}
            onClick={onSecondaryButtonClick}
            colorVariant={themeColorVariants.DARK}
            data-testid="questionnaire-changed-modal-secondary-button"
          />
        </Box>
      </Box>
    </Modal>
  )
}

QuestionnaireChangedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired,
}

export default QuestionnaireChangedModal
