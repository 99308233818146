import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import BlobProgress from '../../../../../common/components/molecules/BlobProgress'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import useInjectDynamic from '../../../../../common/hooks/useInjectDynamic'

const Intro = ({
  question,
  answerStore,
  charityPartner,
  handleFieldFragmentUpdate,
}) => {
  const { resolveValue } = useInjectDynamic()
  const { t } = useTranslation()

  // if there is a fields array, use the first index OR just use empty object
  const field = (!_isEmpty(question.fields) && question.fields[0]) || {}

  useEffect(() => {
    /*
     * If there is a field name and that field has a property called immutableValue
     * with a truthy value, immediately add this as the field fragment.
     * This question will not be updatable by the user, we set this by default
     * to show something of value in the summary
     */
    if (field.name && field.immutableValue) {
      handleFieldFragmentUpdate({
        [field.name]: resolveValue(field.immutableValue),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { hasChildren, hasPartner, minorChildren, hasStepChildren, hasPets } =
    answerStore

  const { section } = question

  // this must be updated if a section is added or a section name is changed
  const blobProgressData = [
    {
      id: CONSTANTS.questionnaireSectionTypes.BASICS.id,
      label: t(CONSTANTS.questionnaireSectionTypes.BASICS.tKey),
      triggered: true,
    },
    {
      id: CONSTANTS.questionnaireSectionTypes.FAMILY.id,
      label: t(CONSTANTS.questionnaireSectionTypes.FAMILY.tKey),
      triggered:
        hasChildren === 'yes' ||
        hasPets === 'yes' ||
        hasStepChildren === 'yes' ||
        hasPartner === 'yes',
    },
    {
      id: CONSTANTS.questionnaireSectionTypes.GUARDIANS.id,
      label: t(CONSTANTS.questionnaireSectionTypes.GUARDIANS.tKey),
      triggered:
        (hasChildren === 'yes' && !minorChildren) ||
        (minorChildren && minorChildren === 'yes') ||
        hasPets === 'yes',
    },
    {
      id: CONSTANTS.questionnaireSectionTypes.CHARITIES.id,
      label: t(CONSTANTS.questionnaireSectionTypes.CHARITIES.tKey),
      triggered: !!charityPartner.name,
    },
    {
      id: CONSTANTS.questionnaireSectionTypes.DISTRIBUTION.id,
      label: t(CONSTANTS.questionnaireSectionTypes.DISTRIBUTION.tKey),
      triggered: true,
    },
    {
      id: CONSTANTS.questionnaireSectionTypes.TRUSTEE.id,
      label: t(CONSTANTS.questionnaireSectionTypes.TRUSTEE.tKey),
      triggered: true,
    },
  ]

  return (
    <>
      {section !== 'POA for Property' && section !== 'POA for Care' && (
        <BlobProgress data={blobProgressData} current={section} />
      )}
    </>
  )
}

Intro.propTypes = {
  answerStore: PropTypes.shape({
    hasChildren: PropTypes.string,
    hasPartner: PropTypes.string,
    minorChildren: PropTypes.string,
    hasStepChildren: PropTypes.string,
    hasPets: PropTypes.string,
    product: PropTypes.string,
  }).isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        immutableValue: PropTypes.oneOfType([
          PropTypes.func,
          PropTypes.string,
          PropTypes.shape({ tKey: PropTypes.string }),
        ]),
      }),
    ),
    section: PropTypes.string,
  }).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  charityPartner: PropTypes.shape({ name: PropTypes.string }),
}

const mapStateToProps = (state) => ({
  charityPartner: commonSelectors.getCharityPartner(state),
})

export default connect(mapStateToProps)(Intro)
