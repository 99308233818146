import React from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../styles/muiTheme'

const AutocompleteItem = ({ option, onSelect, highlighted }) => {
  const { t } = useTranslation()

  return (
    <Box
      tabIndex={0}
      role="button"
      data-testid="autocomplete-item"
      /*
       * onMouseDown being used instead of onClick so it
       * fires before the onBlur event on TextField
       * (there is probably a better way to do this)
       */
      onMouseDown={() => onSelect(option)}
      sx={{
        cursor: 'pointer',
        px: '1rem',
        py: '0.53rem',
        backgroundColor: highlighted
          ? themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTEST]
          : 'initial',

        '&:hover': {
          backgroundColor:
            themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTEST],
        },
      }}
    >
      <Box
        data-testid="autocomplete-item-label"
        sx={{
          fontSize: '0.96rem',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
        }}
      >
        {option?.label}
      </Box>
      {option?.subLabel && (
        <Box
          data-testid="autocomplete-item-subLabel"
          sx={{
            fontSize: '0.75rem',
            color: themeColors[themeColorTypes.GREY][themeColorVariants.MAIN],
          }}
        >
          {/*
           * If the subLabel is an object with a tKey property, use it to translate the label.
           * Otherwise, use the subLabel as is, because we can assume it's a string.
           */}
          {t(
            _get(
              option.subLabel,
              'tKey',
              option.subLabel?.label || option.subLabel,
            ),
          )}
        </Box>
      )}
    </Box>
  )
}

AutocompleteItem.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    subLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        tKey: PropTypes.string,
      }),
    ]),
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  highlighted: PropTypes.bool.isRequired,
}

export default AutocompleteItem
