import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import { Box, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../atoms/Button'
import Text from '../../atoms/Text'

export interface CharityLike {
  name: string
  number?: string
  location?: string
}

interface AddCharityButtonProps {
  charity: CharityLike
  handleAddCharity: (charity: CharityLike) => void
}

const AddCharityButton = ({
  handleAddCharity,
  charity,
}: AddCharityButtonProps) => {
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.down('xl'))

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        my: isTablet ? '1rem' : undefined,
        pl: isTablet ? undefined : '1rem',
        width: isTablet ? '15rem' : '11rem',
        mx: isTablet ? 'auto' : undefined,
        flexShrink: 0,
        justifySelf: 'flex-end',
      }}
    >
      <Button
        fullWidth={isTablet}
        variant="outlined"
        label={
          <Box
            sx={{
              display: 'flex',
              gap: '0.2rem',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <AddIcon sx={{ fontSize: '1.3rem' }} />
            <Text size="sm" sx={{ mt: '0.1rem' }}>
              {t('components:CharityFinder.addCharity')}
            </Text>
          </Box>
        }
        onClick={() => {
          handleAddCharity(charity)
        }}
      />
    </Box>
  )
}

export default AddCharityButton
