import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Button from '../../../../../common/components/atoms/Button'
import Heading from '../../../../../common/components/atoms/Heading'
import LoadingSpinner from '../../../../../common/components/atoms/LoadingSpinner'
import commonActions from '../../../../../common/ducks/commonActions'
import amplitude from '../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import addOnsActions from '../../../ducks/addOns/addOnsActions'
import addOnsSelectors from '../../../ducks/addOns/addOnsSelectors'
import documentsSelectors from '../../../ducks/documents/documentsSelectors'
import paymentsActions from '../../../ducks/payments/paymentsActions'
import paymentsSelectors from '../../../ducks/payments/paymentsSelectors'
import hasCoreProduct from '../../../utils/hasCoreProduct'
import AddOnDetails from './components/AddOnDetails/AddOnDetails'
import DocumentsDetails from './components/DocumentsDetails'
import PaymentBlock from './components/PaymentBlock'
import PaymentBreakdown from './components/PaymentBreakdown/PaymentBreakdown'
import PaymentContext from './context/PaymentContext'
import useFetchCharge from './hooks/useFetchCharge'

const Payment = ({
  payments,
  documents,
  addOnCart,
  answerStore,
  questionnaireValid,
  dispatchSetPayments,
  dispatchRemoveAddOnFromCart,
  dispatchSetGlobalErrorMessage,
}) => {
  const initialRender = useRef(true)
  const [promoCode, setPromoCode] = useState()

  const userHasCoreProduct = hasCoreProduct(payments)
  const isAddOnOnly = userHasCoreProduct && !_isEmpty(addOnCart)

  const { isInitialLoading, data } = useFetchCharge({
    addOnCart,
    promoCode,
    answerStore,
    userHasCoreProduct,
    dispatchSetGlobalErrorMessage,
  })

  const chargeInfo = data?.charge
  const paymentsInfo = data?.payments

  const navigate = useNavigate()

  const paymentContextData = useMemo(
    () => ({
      addOnCart,
      documents,
      promoCode,
      chargeInfo,
      answerStore,
      setPromoCode,
      dispatchSetPayments,
      dispatchRemoveAddOnFromCart,
      dispatchSetGlobalErrorMessage,
    }),
    [
      addOnCart,
      documents,
      promoCode,
      chargeInfo,
      answerStore,
      dispatchSetPayments,
      dispatchRemoveAddOnFromCart,
      dispatchSetGlobalErrorMessage,
    ],
  )

  const mostRecentPaymentProductId = (thePayments) =>
    _get(thePayments[thePayments.length - 1], 'productId')

  useEffect(() => {
    /*
     * Redirect user away from payment page if they are not in a position
     * to purchase a core-product or an add-on only product.
     */
    if (
      !(
        (questionnaireValid && !userHasCoreProduct && !_isEmpty(documents)) ||
        (isAddOnOnly && answerStore.province)
      )
    ) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOnCart])

  useEffect(() => {
    /*
     * If promo code gives user 100% off or more, the user has paid.
     * Redirect them to the success page.
     */
    if (_get(chargeInfo, ['amount']) <= 0 && !_isEmpty(paymentsInfo)) {
      dispatchSetPayments(paymentsInfo)
      navigate(
        `/dashboard/payment/success/${mostRecentPaymentProductId(
          paymentsInfo,
        )}`,
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeInfo])

  useEffect(() => {
    /*
     * flips initialRender to false on the initial run so that when this useEffect
     * runs for a second time, we know it's because the payments dependency
     * has updated, meaning that a payment was successful.
     */
    if (initialRender.current === false) {
      navigate(
        `/dashboard/payment/success/${mostRecentPaymentProductId(payments)}`,
      )
    } else {
      initialRender.current = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments])

  useEffect(() => {
    amplitude.sendEvent('ViewedPaymentPage')
  }, [])

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  return (
    <>
      {isInitialLoading || _isEmpty(chargeInfo) ? (
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingSpinner />
        </Box>
      ) : (
        <PaymentContext.Provider value={paymentContextData}>
          <Box
            sx={{
              mx: 'auto',
              display: 'flex',
              maxWidth: '1450px',
              justifyContent: 'center',
              flexDirection: { min: 'column', '2xl': 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: { md: '683px' },
                width: { min: '100%', '2xl': '54%' },
                margin: { min: '0 auto', xl: '0 auto' },
                padding: {
                  min: '5.7rem 1rem 2rem',
                  xs: '6rem 1.2rem 2.2rem',
                  sm: '6.5rem 2rem 2.2rem',
                  '2xl': '6.8rem 3.3rem 2.7rem',
                  '3xl': '6.8rem 4rem 3.5rem',
                },
              }}
            >
              <Box
                sx={{
                  bottom: '0.5rem',
                  position: 'relative',
                  alignSelf: 'flex-start',
                }}
              >
                <Button
                  label={t('common:back')}
                  size="xxs"
                  onClick={() => navigate(-1)}
                  startIcon={<ArrowBackIcon />}
                  data-testid="back-to-addons-button"
                />
              </Box>
              <Box sx={{ pb: '2.2rem', pt: { min: '0.5rem', sm: '0.2rem' } }}>
                <Heading
                  align="center"
                  elementType="h1"
                  variant={isMobile ? 'h2' : 'h1'}
                >
                  {t('payment:label')}
                </Heading>
              </Box>

              {!_isEmpty(_get(chargeInfo, ['docTypes'], [])) &&
                !userHasCoreProduct && (
                  <Box mx="auto" width="100%">
                    <DocumentsDetails />
                  </Box>
                )}

              {!_isEmpty(_get(chargeInfo, ['addOns'], [])) && (
                <Box sx={{ mx: 'auto', mt: '1.7rem', width: '100%' }}>
                  <AddOnDetails
                    sendToDashboardPage={() => navigate('/dashboard')}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                width: { '2xl': '46%' },
                height: { '2xl': '100%' },
                maxWidth: { '3xl': '570px' },
                borderRadius: { '3xl': '4px' },
                margin: { min: '0', '3xl': '6rem 3.5rem 6rem 0' },
                padding: {
                  min: '2rem 1rem 4rem',
                  xs: '2.2rem 1.2rem 4rem',
                  sm: '2.22rem 2rem 4rem',
                  '2xl': '7.1rem 3.3rem 2.7rem',
                  '3xl': '3.5rem 3.3rem 2.7rem',
                },
                backgroundColor:
                  themeColors[themeColorTypes.NEUTRAL][
                    themeColorVariants.LIGHT
                  ],
              }}
            >
              <Box
                sx={{
                  mx: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: { min: '450px', '2xl': '570px' },
                }}
              >
                <Box sx={{ mb: '1.7rem' }}>
                  <PaymentBreakdown />
                </Box>

                <PaymentBlock />
              </Box>
            </Box>
          </Box>
        </PaymentContext.Provider>
      )}
    </>
  )
}

Payment.propTypes = {
  questionnaireValid: PropTypes.bool,
  dispatchSetPayments: PropTypes.func.isRequired,
  dispatchRemoveAddOnFromCart: PropTypes.func.isRequired,
  dispatchSetGlobalErrorMessage: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addOnCart: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  answerStore: PropTypes.shape({ province: PropTypes.string }).isRequired,
}

const mapStateToProps = (state) => ({
  payments: paymentsSelectors.getPayments(state.dashboard),
  addOnCart: addOnsSelectors.getAddOnCart(state.dashboard),
  documents: documentsSelectors.getDocuments(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSetGlobalErrorMessage: (msg) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
  dispatchRemoveAddOnFromCart: ({ type }) =>
    dispatch(addOnsActions.removeAddOnFromCart({ type })),
  dispatchSetPayments: (payments) =>
    dispatch(paymentsActions.setPayments(payments)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
