import React, { useState } from 'react'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { validators } from '../../../../../../scenes/Questionnaire/utils/validation/validation'
import growAnimation from '../../../../../animations/growAnimation'
import { ReactComponent as SuccessCheck } from '../../../../../assets/images/success-check.svg'
import commonActions from '../../../../../ducks/commonActions'
import useCheckValidEmail from '../../../../../hooks/useCheckValidEmail'
import { errorHandler, PATHS, request } from '../../../../../request'
import { themeColorTypes } from '../../../../../styles/muiTheme'
import Button from '../../../../atoms/Button'
import Heading from '../../../../atoms/Heading'
import Modal from '../../../../atoms/Modal'
import Text from '../../../../atoms/Text'
import TextInput from '../../../../atoms/TextInput'

interface Props {
  show: boolean
  onClose: () => void
  partnerEmail: string
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const ChangePartnerEmailAddressModal = ({
  show,
  onClose,
  partnerEmail,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [requestWorking, setRequestWorking] = useState(false)

  const { validationMessage, validationWorking, handleCheckValidEmail } =
    useCheckValidEmail({ email, dispatchSetGlobalErrorMessage })

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (validators.email(email)) {
      setRequestWorking(true)

      try {
        await request({
          method: 'POST',
          url: PATHS.CONTACT,
          data: {
            metadata: {
              currentPartnerEmail: partnerEmail,
              newPartnerEmail: email,
            },
          },
          params: { type: CONSTANTS.contactTypes.CHANGE_PARTNER_EMAIL.type },
        })

        setSuccess(true)
      } catch (error) {
        errorHandler({
          error,
          reportError: true,
          onError: () =>
            dispatchSetGlobalErrorMessage(
              'Unable to send partner email address change request. Please try again later.',
            ),
        })
      } finally {
        setRequestWorking(false)
      }
    }
  }

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      maxWidth="md"
      onClose={onClose}
      data-testid="change-partner-email-address-modal"
    >
      {success ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: { xxs: '1rem', md: '2rem' },
          }}
        >
          <Box {...growAnimation} component={motion.div}>
            <Box
              width="55px"
              display="flex"
              sx={{ mb: '0.5rem' }}
              justifyContent="center"
            >
              <SuccessCheck />
            </Box>
          </Box>

          <Heading align="center" variant="h3">
            {t(
              'pages:AccountSettings.changePartnerEmailAddress.components.ChangePartnerEmailAddressModal.success.label',
            )}
          </Heading>

          <Text
            size="lg"
            align="center"
            variant="paragraph"
            sx={{ mt: '0.9rem' }}
          >
            {t(
              'pages:AccountSettings.changePartnerEmailAddress.components.ChangePartnerEmailAddressModal.success.subLabel',
            )}
          </Text>
        </Box>
      ) : (
        <Box sx={{ p: { xxs: '1rem', md: '2rem' } }}>
          <Heading align="center" variant="h3">
            {t(
              'pages:AccountSettings.changePartnerEmailAddress.components.ChangePartnerEmailAddressModal.default.label',
            )}
          </Heading>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: '300px',
              display: 'flex',
              m: '1.7rem auto 0',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <TextInput
              autoFocus
              type="email"
              value={email}
              disabled={requestWorking}
              working={validationWorking}
              isInvalid={!!validationMessage}
              validationMessage={validationMessage}
              placeholder={t(
                'pages:AccountSettings.changePartnerEmailAddress.components.ChangePartnerEmailAddressModal.default.textInput.placeholder',
              )}
              onInputChange={(val: string) => {
                setEmail(val)
                if (val?.length > 6 && validators.email(val)) {
                  handleCheckValidEmail(val)
                }
              }}
            />

            <Button
              type="submit"
              variant="contained"
              label={t(
                'pages:AccountSettings.changePartnerEmailAddress.components.ChangePartnerEmailAddressModal.default.button.label',
              )}
              sx={{ mt: '0.25rem' }}
              working={requestWorking}
              spinnerPadding="0.375rem 2.909rem"
              spinnerColor={themeColorTypes.WHITE}
              data-testid="change-partner-email-address-submit-button"
              disabled={
                !validators.email(email) ||
                validationWorking ||
                !!validationMessage
              }
            />
          </Box>
        </Box>
      )}
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(null, mapDispatchToProps)(ChangePartnerEmailAddressModal)
