import _isEmpty from 'lodash/isEmpty'

import { createLogic } from 'redux-logic'

import data from '../../../data'
import { sectionProgress } from '../../../engine'
import navigationActions from '../../navigation/navigationActions'
import navigationSelectors from '../../navigation/navigationSelectors'
import questionnaireActions from '../questionnaireActions'
import questionnaireTypes from '../questionnaireTypes'

export default createLogic({
  name: 'beforeQuestionnaire',
  type: questionnaireTypes.BEFORE_QUESTIONNAIRE,

  validate({ getState, action }, allow, reject) {
    const questionStack = navigationSelectors.getQuestionStack(
      getState().questionnaire,
    )
    /* If there's an empty questionStack, it's a new questionnaire. Pass to process().
     * Else, the user is coming from the dashboard to edit.
     * Allow the user to resume wherever the questionStack
     * is pointing to and skip the rest of beforeQuestionnaire logic
     */
    if (_isEmpty(questionStack)) {
      allow(action)
    } else {
      const currentQuestion = navigationSelectors.getCurrentQuestion(
        getState().questionnaire,
      )
      const theSectionProgress = sectionProgress(currentQuestion, data)

      // skip beforeQuestionnaire logic, but set progress
      reject(
        navigationActions.updateSectionProgress({
          progress: theSectionProgress,
        }),
      )
    }
  },

  async process(_, dispatch, done) {
    dispatch(questionnaireActions.createQuestionnaire())

    done()
  },
})
