import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _toString from 'lodash/toString'

import { RadioButton, Text } from 'grommet'
import { useTranslation } from 'react-i18next'

import Select from '../../../../../common/components/atoms/Select'
import TextInput from '../../../../../common/components/atoms/TextInput'
import tKeyRelationshipOptions from '../../../data/utils/tKeyRelationshipOptions'
import CharitySearchInput from '../../molecules/CharitySearchInput/CharitySearchInput'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'
import formatField from './utils/formatField'

const PredeceasedBackup = ({
  question,
  answerCache,
  displayValidation,
  handleFieldFragmentUpdate,
}) => {
  const [field] = question.fields

  const [beneficiary, setBeneficiary] = useState(
    answerCache.remoteDistribution[0] || {},
  )
  const [predeceasedSplit, setPredeceasedSplit] = useState(
    beneficiary.predeceasedSplit || undefined,
  )
  const [altName, setAltName] = useState(beneficiary.alternateName || undefined)
  const [altNumber, setAltNumber] = useState(
    beneficiary.alternateNumber || undefined,
  )
  const [altLocation, setAltLocation] = useState(
    beneficiary.alternateLocation || undefined,
  )
  const [altRelationship, setAltRelationship] = useState(
    beneficiary.alternateRelationship || undefined,
  )

  useEffect(() => {
    setBeneficiary({
      ...beneficiary,
      predeceasedSplit,
      alternateName: altName,
      alternateNumber: altNumber,
      alternateLocation: altLocation,
      alternateRelationship: altRelationship,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predeceasedSplit, altName, altNumber, altLocation, altRelationship])

  useEffect(() => {
    handleFieldFragmentUpdate({
      remoteDistribution: [beneficiary],
      [field.name]: formatField(beneficiary),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiary])

  const handleRadioUpdate = (val) => {
    setAltName()
    setAltNumber()
    setPredeceasedSplit(val)
    setAltLocation()
    setAltRelationship(val === 'alternateCharity' ? 'charitableOrg' : undefined)
  }

  const { t } = useTranslation()

  return (
    <div className="flex flex-col max-w-xl w-full items-center">
      <div className="flex justify-center mb-3">
        <ValidationMessage question={question} />
      </div>

      {!_isEmpty(beneficiary) && (
        <div>
          {/* splitChildren Radio */}
          <div className="mb-5">
            <RadioButton
              name="split-children-radio-button"
              checked={predeceasedSplit === 'splitChildren'}
              id={_toString(`${beneficiary.id}-splitChildren`)}
              label={
                <Text size="smedium">
                  {t(
                    'customQuestion:PredeceasedBackup.radioButtonLabel.radio1',
                    { name: beneficiary.name },
                  )}
                </Text>
              }
              onChange={() => handleRadioUpdate('splitChildren')}
            />
          </div>

          {/* alternatePerson radio */}
          <div className="mb-5">
            <RadioButton
              name="alternate-person-radio-button"
              checked={predeceasedSplit === 'alternatePerson'}
              id={_toString(`${beneficiary.id}-alternatePerson`)}
              label={
                <Text size="smedium">
                  {t(
                    'customQuestion:PredeceasedBackup.radioButtonLabel.radio2',
                  )}
                </Text>
              }
              onChange={() => handleRadioUpdate('alternatePerson')}
            />
          </div>

          {predeceasedSplit === 'alternatePerson' && (
            <div className="mb-12 max-w-sm">
              {/* name */}
              <div className="w-full">
                <TextInput
                  autoFocus
                  value={altName}
                  isInvalid={displayValidation && !altName}
                  validationMessage=""
                  placeholder={t(
                    'customQuestion:PredeceasedBackup.placeholder.indivFullName',
                  )}
                  onInputChange={setAltName}
                />
              </div>

              {/* relationship */}
              <div className="w-48">
                <Select
                  value={altRelationship}
                  isInvalid={displayValidation && !altRelationship}
                  validationMessage=""
                  selectId="altRelationship"
                  options={tKeyRelationshipOptions.map(({ value, label }) => ({
                    value,
                    // Translate label if it has tKey, otherwise use label as is
                    label: t(_get(label, 'tKey', label)),
                  }))}
                  placeholder={t(
                    'customQuestion:PredeceasedBackup.placeholder.relationship',
                  )}
                  onInputChange={setAltRelationship}
                />
              </div>
            </div>
          )}

          {/* alternateCharity radio */}
          <div className="mb-5">
            <RadioButton
              name="alternate-charity-radio-button"
              checked={predeceasedSplit === 'alternateCharity'}
              id={_toString(`${beneficiary.id}-alternateCharity`)}
              label={
                <Text size="smedium">
                  {t(
                    'customQuestion:PredeceasedBackup.radioButtonLabel.radio3',
                  )}
                </Text>
              }
              onChange={() => handleRadioUpdate('alternateCharity')}
            />
          </div>

          {predeceasedSplit === 'alternateCharity' && (
            <div className="mb-12 max-w-sm">
              <CharitySearchInput
                autoFocus
                value={altName}
                validationMessage=""
                placeholder={t(
                  'customQuestion:PredeceasedBackup.placeholder.charityName',
                )}
                isInvalid={displayValidation && !altName}
                onCharitySearchInputChange={({ name, number, location }) => {
                  setAltName(name)
                  setAltNumber(number)
                  setAltLocation(location)
                }}
              />
            </div>
          )}

          {/* skip radio */}
          <RadioButton
            name="skip-radio-button"
            checked={predeceasedSplit === 'skip'}
            id={_toString(`${beneficiary.id}-skip`)}
            label={
              <Text size="smedium">
                {t('customQuestion:PredeceasedBackup.radioButtonLabel.radio4')}
              </Text>
            }
            onChange={() => handleRadioUpdate('skip')}
          />
        </div>
      )}
    </div>
  )
}

PredeceasedBackup.propTypes = {
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  displayValidation: PropTypes.bool.isRequired,
  answerCache: PropTypes.shape({
    remoteDistribution: PropTypes.arrayOf(
      PropTypes.shape({ predeceasedSplit: PropTypes.string }),
    ),
    predeceased: PropTypes.string,
  }).isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default PredeceasedBackup
