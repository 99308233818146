import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Paragraph } from 'grommet'

import StyledLabel from '../../../../common/components/atoms/StyledLabel/StyledLabel'
import useInjectDynamic from '../../../../common/hooks/useInjectDynamic'
import HoverDefinition from '../molecules/HoverDefinition'
import checkLabelTruthy from './utils/checkLabelTruthy'

const Label = memo(({ question }) => {
  const { label, label2, subLabel } = question

  const { resolveValue, injectObj } = useInjectDynamic()

  return (
    <>
      {(label || label2 || subLabel) && (
        <div className="mb-5">
          {checkLabelTruthy(label, injectObj) && (
            <div
              data-testid="label-label"
              className="mb-5 min-w-full flex justify-center text-center"
            >
              <StyledLabel data-testid="label">
                <HoverDefinition incoming={resolveValue(label)} />
              </StyledLabel>
            </div>
          )}

          {checkLabelTruthy(label2, injectObj) && (
            <div
              data-testid="label-label2"
              className="mb-5 min-w-full flex justify-center text-center"
            >
              <StyledLabel>
                <HoverDefinition incoming={resolveValue(label2)} />
              </StyledLabel>
            </div>
          )}

          {checkLabelTruthy(subLabel, injectObj) && (
            <div
              data-testid="label-subLabel"
              className="mb-5 min-w-full flex justify-center text-center"
            >
              <Paragraph size="smedium">
                <HoverDefinition incoming={resolveValue(subLabel)} />
              </Paragraph>
            </div>
          )}
        </div>
      )}
    </>
  )
})

const labelVariantPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({ tKey: PropTypes.string, options: PropTypes.shape({}) }),
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        term: PropTypes.oneOfType([
          PropTypes.shape({
            tKey: PropTypes.string,
            options: PropTypes.shape({}),
          }),
          PropTypes.string,
        ]),
        definition: PropTypes.oneOfType([
          PropTypes.shape({
            tKey: PropTypes.string,
            options: PropTypes.shape({}),
          }),
          PropTypes.string,
        ]),
      }),
    ]),
  ),
])

Label.propTypes = {
  question: PropTypes.shape({
    label: labelVariantPropType,
    label2: labelVariantPropType,
    subLabel: labelVariantPropType,
  }).isRequired,
}

export default Label
