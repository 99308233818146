import corporateExecutorSidebarFragments from './corporateExecutorSidebarFragments'

export default [
  corporateExecutorSidebarFragments.corporateExecutorBenefits,
  {
    type: 'accordion',
    header: {
      tKey: 'sidebar:corporateExecutorDisqualifiersSidebar.item2.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorDisqualifiersSidebar.item2.children.item1.body',
        },
      },
    ],
  },
  corporateExecutorSidebarFragments.whyChargeFees,
  corporateExecutorSidebarFragments.howMuchWillTrustCompanyCharge,
  corporateExecutorSidebarFragments.whenDoesTrustCompanyGetPaid,
  corporateExecutorSidebarFragments.changeMindOnTrustCompany,
  {
    type: 'accordion',
    header: {
      tKey: 'sidebar:corporateExecutorDisqualifiersSidebar.item7.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorDisqualifiersSidebar.item7.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorDisqualifiersSidebar.item7.children.item2.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: {
      tKey: 'sidebar:corporateExecutorDisqualifiersSidebar.item8.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'sidebar:corporateExecutorDisqualifiersSidebar.item8.children.item1.body',
        },
      },
    ],
  },
  corporateExecutorSidebarFragments.contact,
]
