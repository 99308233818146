/*
 * firstTriggeredQuestion simply calls nextTriggeredQuestion, however,
 * we hard-code theCurrentQuestionIndex at -1 because we want to act as though
 * we are one question before the first question of the questionnaire in order
 * to ensure that the actual first question of the questionnaire (index 0)
 * should be triggered and displayed. We want to determine whether or not
 * to display it first or to skip it.
 */

import nextTriggeredQuestion from './nextTriggeredQuestion'

export default ({
  data,
  abTest,
  payments,
  referrer,
  isPartner,
  documents,
  answerStore,
  accountCreated,
  charityPartner,
}) =>
  nextTriggeredQuestion({
    data,
    abTest,
    payments,
    referrer,
    isPartner,
    documents,
    answerStore,
    accountCreated,
    charityPartner,
    theCurrentQuestionIndex: -1, // -1 must remain hard-coded
  })
