import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useQueryClient } from '@tanstack/react-query'
import { motion } from 'motion/react'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import growAnimation from '../../../../../../../common/animations/growAnimation'
import { ReactComponent as SuccessCheck } from '../../../../../../../common/assets/images/success-check.svg'
import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import amplitude from '../../../../../../../common/lib/amplitude'
import { themeColorTypes } from '../../../../../../../common/styles/muiTheme'
import { queryKeysBase } from '../../../../../../../common/utils/queryKeys'
import questionnaireSelectors from '../../../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import addOnsActions from '../../../../../ducks/addOns/addOnsActions'
import addOnsSelectors from '../../../../../ducks/addOns/addOnsSelectors'
import documentsActions from '../../../../../ducks/documents/documentsActions'
import paymentsSelectors from '../../../../../ducks/payments/paymentsSelectors'
import isGiftPayment from './utils/isGiftPayment'

const PaymentSuccess = ({
  payments,
  addOnCart = [],
  answerStore,
  dispatchFetchAddOns,
  dispatchFetchDocuments,
}) => {
  const queryClient = useQueryClient()

  const {
    product,
    partnerEmail,
    partnerGender,
    includePartner,
    partnerFirstName,
  } = answerStore

  const mostRecentPayment = payments[payments.length - 1]
  const mostRecentPaymentPrice = _get(mostRecentPayment, 'price')
  const isGiftPaymentSuccess = isGiftPayment(mostRecentPayment)

  const theme = useTheme()

  useEffect(() => {
    /*
     * Remove the cached product-charge from react-query
     * to prevent a quick 'flash' of the previously
     * cached charge when a user visits the payment page during
     * the same session. It's really an innocuous UI bug, but nice
     * to fix nonetheless
     */
    queryClient.removeQueries(queryKeysBase.productCharge)

    /*
     * Fetch the documents here because the documents objects will now
     * return paid:true. The Payment Success page is displayed whether
     * the user pays with Stripe or has uses a 100% OFF promo code.
     */
    dispatchFetchDocuments()

    // after payment, fetch paid add-ons from backend + clear Redux addOnCart
    if (!_isEmpty(addOnCart)) {
      dispatchFetchAddOns({ clearAddOnCart: true })
    }

    if (ReactGA) {
      // Google Analytics purchase event
      ReactGA.event({
        category: 'customer',
        action: 'purchase',
        value: mostRecentPaymentPrice || 0,
      })
    }

    amplitude.sendEvent('MadePayment', {
      mostRecentPaymentPrice,
      includePartner,
      product,
    })
    amplitude.sendRevenueEvent(mostRecentPayment)
    amplitude.setUserProperties({ isPaying: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const firstPayment = payments.length === 1

  let partnerEmailSent
  try {
    partnerEmailSent =
      partnerFirstName &&
      partnerGender &&
      product &&
      includePartner &&
      includePartner === 'yes' &&
      payments.filter((p) => p.partnerPaidFor).length === 1
  } catch {
    partnerEmailSent = false
  }

  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        {/* Event snippet for Purchase conversion page */}
        {window.gtag && (
          <script>
            {`window.gtag('event', 'conversion', { 
              'send_to': 'AW-652679184/2BjgCLLTuMwBEJCwnLcC',
              'value': ${mostRecentPaymentPrice || 0},
              'currency': 'CAD',
              'transaction_id': ''
            })`}
          </script>
        )}

        {/* Fire Facebook pixel event after purchase */}
        {window.fbq && (
          <script>
            {`window.fbq('track', 'Purchase', { 
              'value': ${mostRecentPaymentPrice || 0},
              'currency': 'CAD'
            })`}
          </script>
        )}
      </Helmet>

      <Box
        mx="auto"
        maxWidth={theme.breakpoints.values.md}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Heading align="center" variant="h2">
          {t('pages:PaymentSuccess.label')}
        </Heading>

        <motion.div {...growAnimation}>
          <Box
            pt="2rem"
            pb="2.4rem"
            width="68px"
            display="flex"
            justifyContent="center"
          >
            <SuccessCheck />
          </Box>
        </motion.div>

        <Heading align="center" variant="h4">
          {partnerEmail && partnerEmailSent && firstPayment ? (
            t(
              `pages:PaymentSuccess.label2.case1.${partnerGender === 'male' ? 'male' : partnerGender === 'female' ? 'female' : 'other'}.${product === 'willPoa' ? 'willPoa' : 'will'}`,
              { partnerFirstName, partnerEmail },
            )
          ) : isGiftPaymentSuccess ? (
            <Box component="span" data-testid="gift-payment-success-copy">
              {t('pages:PaymentSuccess.label2.case2')}
            </Box>
          ) : (
            t('pages:PaymentSuccess.label2.case3')
          )}
        </Heading>

        <Box pt="2.5rem">
          <Link to="/dashboard/documents">
            <Button
              variant="contained"
              label={t('pages:PaymentSuccess.buttonLabel')}
              endIcon={<ArrowForwardIcon />}
              color={themeColorTypes.ACCENT_1}
            />
          </Link>
        </Box>
      </Box>
    </>
  )
}

PaymentSuccess.propTypes = {
  dispatchFetchAddOns: PropTypes.func.isRequired,
  addOnCart: PropTypes.arrayOf(PropTypes.shape({})),
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      addOns: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  answerStore: PropTypes.shape({
    product: PropTypes.string,
    partnerEmail: PropTypes.string,
    partnerGender: PropTypes.string,
    includePartner: PropTypes.string,
    partnerFirstName: PropTypes.string,
  }).isRequired,
  dispatchFetchDocuments: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  addOnCart: addOnsSelectors.getAddOnCart(state.dashboard),
  payments: paymentsSelectors.getPayments(state.dashboard),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchAddOns: (options) =>
    dispatch(addOnsActions.fetchAddOns(options)),
  dispatchFetchDocuments: () => dispatch(documentsActions.fetchDocuments()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)
