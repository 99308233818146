import _get from 'lodash/get'

import { createLogic } from 'redux-logic'

import commonActions from '../../../../../common/ducks/commonActions'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import { errorHandler, PATHS, request } from '../../../../../common/request'
import navigationActions from '../../navigation/navigationActions'
import questionnaireActions from '../questionnaireActions'
import questionnaireSelectors from '../questionnaireSelectors'
import questionnaireTypes from '../questionnaireTypes'

export default createLogic({
  name: 'createAccount',
  type: [
    questionnaireTypes.OAUTH_SUCCESS,
    questionnaireTypes.START_CREATE_ACCOUNT,
  ],
  async process({ getState, action }, dispatch, done) {
    const state = getState()
    const world = commonSelectors.getWorld(state)
    const questionnaireId = questionnaireSelectors.getQuestionnaireId(
      state.questionnaire,
    )

    const { email, password, mailingList } = action.payload

    try {
      dispatch(navigationActions.working(true))
      /*
       * If action type is START_CREATE_ACCOUNT, that means we're creating an
       * account with good ol' fashioned email and password.
       *
       * Otherwise, we assume that if a user made it this far, they've
       * successfully created an account using OAuth.
       */
      if (action.type === questionnaireTypes.START_CREATE_ACCOUNT) {
        await request({
          method: 'POST',
          url: PATHS.USER_REGISTER,
          data: {
            world,
            email,
            password,
            questionnaire: questionnaireId,
          },
        })
      }

      dispatch(
        questionnaireActions.createAccountSuccess({ mailingList, email }),
      )
      dispatch(
        questionnaireActions.updateAnswerStore({
          answerStoreFragment: {
            email,
            password: '*'.repeat(8),
          },
        }),
      )
    } catch (error) {
      const is400 = _get(error, ['response', 'status']) === 400
      errorHandler({
        error,
        reportError: !is400,
        fallbackErrorMessage:
          'Unable to create your account. Please refresh you browser and try again.',
        onError: (resolvedErrorMessage) =>
          dispatch(commonActions.setGlobalErrorMessage(resolvedErrorMessage)),
      })
    } finally {
      dispatch(navigationActions.working(false))
    }

    done()
  },
})
