import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ComboBox from '../../../../../common/components/atoms/ComboBox/ComboBox'

const ComboBoxField = ({
  field,
  isInvalid = false,
  answerCache,
  handleFieldFragmentUpdate,
}) => {
  const { name, options, placeholder } = field

  const defaultValue = answerCache[name] || undefined

  const [fieldValue, setFieldValue] = useState(defaultValue)

  return (
    <div className="max-w-xxs w-full">
      <ComboBox
        options={options}
        isInvalid={isInvalid}
        value={fieldValue}
        placeholder={placeholder}
        onInputChange={(value) => {
          setFieldValue(value)
          handleFieldFragmentUpdate({ [name]: value })
        }}
      />
    </div>
  )
}

ComboBoxField.propTypes = {
  isInvalid: PropTypes.bool,
  field: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  answerCache: PropTypes.shape({}).isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

export default ComboBoxField
