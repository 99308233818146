import { CONSTANTS } from '@epilogue/common'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorHandler, PATHS, request } from '../../../../../common/request'
import { queryKeys, queryKeysBase } from '../../../../../common/utils/queryKeys'

export default ({
  dispatchSetGlobalErrorMessage,
  dispatchGenerateDocumentsWorking,
}) => {
  const queryClient = useQueryClient()

  const { mutate: handleGenerateSocialDoc } = useMutation({
    mutationFn: ({ answerStore }) =>
      request({
        method: 'POST',
        url: PATHS.SOCIAL_DOCUMENT,
        data: { answerStore, docType: CONSTANTS.worldTypes.social.type },
      }),
    onMutate: () => dispatchGenerateDocumentsWorking(true),
    onSettled: () => dispatchGenerateDocumentsWorking(false),
    onSuccess: () =>
      queryClient.invalidateQueries(queryKeys[queryKeysBase.socialDocuments]()),
    onError: (error) => {
      errorHandler({
        error,
        reportError: true,
        fallbackErrorMessage:
          'Unable to generate document. Please try again later.',
        onError: (resolvedErrorMessage) =>
          dispatchSetGlobalErrorMessage(resolvedErrorMessage),
      })
    },
  })

  return [handleGenerateSocialDoc]
}
