export default [
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item1.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item1.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item1.children.item2.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item1.children.item3.body',
        },
      },
    ],
  },
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item2.header',
    },
    children: [
      {
        type: 'list',
        variant: 'unordered',
        body: {
          tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item2.children.item1.body',
        },
        children: [
          {
            tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item2.children.item1.children.item1',
          },
          {
            tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item2.children.item1.children.item2',
          },
          {
            tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item2.children.item1.children.item3',
          },
        ],
      },
    ],
  },
  {
    type: 'accordion',
    header: {
      tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item3.header',
    },
    children: [
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item3.children.item1.body',
        },
      },
      {
        type: 'text',
        body: {
          tKey: 'questionnaire.distribution:common.distributionCharitableGiftDetailsSidebar.item3.children.item2.body',
        },
      },
    ],
  },
]
