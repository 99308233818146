import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'grommet'
import { FormClose } from 'grommet-icons'
import { useTranslation } from 'react-i18next'

import TextInput from '../../../../../../common/components/atoms/TextInput'

const Pet = ({ pet, displayValidation, update, remove, multiplePets }) => {
  const { t } = useTranslation()

  return (
    <div className="mb-8">
      <div className="flex">
        {multiplePets && (
          <div className="flex items-center mt-4 mr-2">
            <Button
              plain
              hoverIndicator={{ color: 'grey-2', opacity: 0.3 }}
              onClick={() => remove(pet.id)}
              label={
                <div className="p-1">
                  <FormClose color="grey-3" />
                </div>
              }
            />
          </div>
        )}

        <div className="w-full">
          {/* name */}
          <div className="-mb-1">
            <TextInput
              value={pet.name}
              placeholder={t(
                'customQuestion:Pets.components.Pet.petsNamePlaceholder',
              )}
              isInvalid={displayValidation && !pet.name}
              validationMessage=""
              onInputChange={(value) => {
                update({ ...pet, name: value })
              }}
            />
          </div>

          {/* type */}
          <div>
            <TextInput
              value={pet.type}
              placeholder={t(
                'customQuestion:Pets.components.Pet.typeOfAnimalPlaceholder',
              )}
              isInvalid={displayValidation && !pet.type}
              validationMessage=""
              onInputChange={(value) => {
                update({ ...pet, type: value })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Pet.propTypes = {
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  multiplePets: PropTypes.bool.isRequired,
  displayValidation: PropTypes.bool.isRequired,
  pet: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
}

export default Pet
