import React from 'react'
import PropTypes from 'prop-types'

import Fields from '../../Field/Fields'

const StandardQuestion = ({ question = {}, handleFieldFragmentUpdate }) => (
  <>
    {question.fields && (
      <Fields
        question={question}
        handleFieldFragmentUpdate={handleFieldFragmentUpdate}
      />
    )}
  </>
)

StandardQuestion.propTypes = {
  question: PropTypes.shape({ fields: PropTypes.arrayOf(PropTypes.shape({})) }),
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
}

export default StandardQuestion
