import addOnsOperations from './addOns/addOnsOperations'
import appointeesOperations from './appointees/appointeesOperations'
import bonusDocsOperations from './bonusDocs/bonusDocsOperations'
import dashboardOperations from './dashboard/dashboardOperations'
import documentsOperations from './documents/documentsOperations'
import paymentsOperations from './payments/paymentsOperations'

export default [
  ...dashboardOperations,
  ...documentsOperations,
  ...paymentsOperations,
  ...bonusDocsOperations,
  ...addOnsOperations,
  ...appointeesOperations,
]
