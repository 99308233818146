import { createTheme } from '@mui/material/styles'

export const themeColorTypes = {
  BRAND: 'brand',
  WHITE: 'white',
  ACCENT_1: 'accent-1',
  ACCENT_2: 'accent-2',
  ACCENT_3: 'accent-3',
  NEUTRAL: 'neutral',
  GREY: 'grey',
  RED: 'red',
  GREEN: 'green',
  YELLOW: 'yellow',
}

export const themeColorVariants = {
  LIGHTEST: 'lightest',
  LIGHTER: 'lighter',
  LIGHT: 'light',
  MAIN: 'main',
  DARK: 'dark',
  DARKER: 'darker',
  DARKEST: 'darkest',

  BUTTON_HOVER: 'buttonHover',
}

export const themeColors = {
  [themeColorTypes.BRAND]: {
    [themeColorVariants.LIGHTEST]: '#a6a4ba',
    [themeColorVariants.LIGHTER]: '#7a7698',
    [themeColorVariants.LIGHT]: '#4e4976',
    [themeColorVariants.MAIN]: '#221C54',

    [themeColorVariants.BUTTON_HOVER]: {
      [themeColorVariants.MAIN]: '#3f396e',
    },
  },

  [themeColorTypes.WHITE]: {
    [themeColorVariants.MAIN]: '#FFFFFF',

    [themeColorVariants.BUTTON_HOVER]: {
      [themeColorVariants.MAIN]: '#F9F9F9',
    },
  },

  [themeColorTypes.ACCENT_1]: {
    [themeColorVariants.LIGHTEST]: '#F2C1AB',
    [themeColorVariants.LIGHTER]: '#EA9771',
    [themeColorVariants.LIGHT]: '#E6865B',
    [themeColorVariants.MAIN]: '#E37645',
    [themeColorVariants.DARK]: '#E0662F',
    [themeColorVariants.DARKER]: '#D55820',
    [themeColorVariants.DARKEST]: '#BF4F1D',

    [themeColorVariants.BUTTON_HOVER]: {
      [themeColorVariants.MAIN]: '#E0662F',
    },
  },

  [themeColorTypes.ACCENT_2]: {
    [themeColorVariants.LIGHTEST]: '#F1EFFF',
    [themeColorVariants.LIGHTER]: '#EAE8F6',
    [themeColorVariants.LIGHT]: '#D9D5EF',
    [themeColorVariants.MAIN]: '#C8C3E8',
    [themeColorVariants.DARK]: '#B7B1E1',
    [themeColorVariants.DARKER]: '#A69EDA',
    [themeColorVariants.DARKEST]: '#4C4679',

    [themeColorVariants.BUTTON_HOVER]: {
      [themeColorVariants.LIGHTER]: '#EEEDF8',
      [themeColorVariants.MAIN]: '#B7B1E1',
    },
  },

  [themeColorTypes.ACCENT_3]: {
    [themeColorVariants.LIGHTEST]: '#F1EFFF',
    [themeColorVariants.MAIN]: '#6156BB',
  },

  [themeColorTypes.NEUTRAL]: {
    [themeColorVariants.LIGHTER]: '#FAF5EC',
    [themeColorVariants.LIGHT]: '#F1E7D5',
    [themeColorVariants.MAIN]: '#EEDFC5',
    [themeColorVariants.DARK]: '#DDC59C',

    [themeColorVariants.BUTTON_HOVER]: {
      [themeColorVariants.MAIN]: '#EAD7B6',
      [themeColorVariants.DARK]: '#D0B890',
    },
  },

  [themeColorTypes.GREY]: {
    [themeColorVariants.LIGHTEST]: '#F3F3F3',
    [themeColorVariants.LIGHTER]: '#E3E3E3',
    [themeColorVariants.LIGHT]: '#B3ADBA',
    [themeColorVariants.MAIN]: '#8E89A0',
    [themeColorVariants.DARK]: '#58517A',

    [themeColorVariants.BUTTON_HOVER]: {
      [themeColorVariants.LIGHTER]: '#D6D6D6',
      [themeColorVariants.LIGHT]: '#A69FAE',
      [themeColorVariants.MAIN]: '#807b95',
    },
  },

  [themeColorTypes.GREEN]: {
    [themeColorVariants.DARK]: '#288e53',
    [themeColorVariants.MAIN]: '#33B268',
    [themeColorVariants.LIGHT]: '#5bc186',
  },

  [themeColorTypes.RED]: {
    [themeColorVariants.LIGHT]: '#D57560',
    [themeColorVariants.MAIN]: '#c33b1c',

    [themeColorVariants.BUTTON_HOVER]: {
      [themeColorVariants.MAIN]: '#af3519',
    },
  },

  [themeColorTypes.YELLOW]: {
    [themeColorVariants.LIGHT]: '#FFF4B8',
    [themeColorVariants.MAIN]: '#e49812',
  },
}

export const themeTextSizes = {
  xxxs: '0.625rem', // 10px
  xxs: '0.75rem', // 12px
  xs: '0.875rem', // 14px
  sm: '0.9375rem', // 15px
  md: '1.0rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.25rem', // 20px
  '2xl': '1.375rem', // 22px
  '3xl': '1.5rem', // 24px
  '4xl': '1.625rem', // 26px
  '5xl': '1.75rem', // 28px
  '6xl': '1.875rem', // 30px
}

export const zIndices = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900]

export const fontFamilies = {
  heading: ['ff-mark', 'sans-serif'].join(','),
  text: ['roboto', 'sans-serif'].join(','),
  paragraph: ['freight', 'serif'].join(','),
}

export default createTheme({
  zIndex: {
    custom: zIndices,
  },

  shape: {
    borderRadius: 2,
    borderRadiusMedium: 3,
    borderRadiusLarge: 4,
    borderRadiusFull: 99,
  },

  breakpoints: {
    keys: ['min', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl'],
    values: {
      min: 0,
      xxs: 320,
      xs: 370,
      sm: 475,
      md: 600,
      lg: 750,
      xl: 900,
      '2xl': 1100,
      '3xl': 1281,
      '4xl': 1450,
      '5xl': 1600,
    },
  },

  palette: {
    ...themeColors,

    primary: themeColors[themeColorTypes.BRAND],

    secondary: themeColors[themeColorTypes.ACCENT_1],

    action: {
      active: '#8E89A0', // select active triangle color
      selected: '#CFCACE', // color selected select option
      hover: '#F3F3F3', // color hover select options
      disabled: '#CFCACE', // border color when disabled
    },

    common: {
      black: themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
      white: themeColors[themeColorTypes.WHITE][themeColorVariants.MAIN],
    },

    text: {
      // input text and hover border color
      primary: themeColors[themeColorTypes.BRAND][themeColorVariants.MAIN],
      secondary: '#58517A', // placeholder and label color
      disabled: '#a5a1a8', // color of text and label when disabled
    },

    error: themeColors[themeColorTypes.RED],
    success: themeColors[themeColorTypes.GREEN],
    warning: themeColors[themeColorTypes.RED],
  },

  typography: {
    fontFamily: fontFamilies.text,

    h1: {
      fontSize: '2.2rem',
      letterSpacing: '-0.045rem',
      fontFamily: fontFamilies.heading,
    },
    h2: {
      fontSize: '1.875rem',
      letterSpacing: '-0.045rem',
      fontFamily: fontFamilies.heading,
    },
    h3: {
      fontSize: '1.5rem',
      letterSpacing: '-0.042rem',
      fontFamily: fontFamilies.heading,
    },
    h4: {
      fontSize: '1.375rem',
      letterSpacing: '-0.042rem',
      fontFamily: fontFamilies.heading,
    },
    h5: {
      fontSize: '1.15rem',
      letterSpacing: '-0.04rem',
      fontFamily: fontFamilies.heading,
    },
    h6: {
      fontSize: '1rem',
      letterSpacing: '-0.04rem',
      fontFamily: fontFamilies.heading,
    },

    body1: {
      fontFamily: fontFamilies.text,
      lineHeight: 1.3,
    },
    body2: {
      fontFamily: fontFamilies.text,
      lineHeight: 1.3,
    },

    subtitle1: {
      fontFamily: fontFamilies.paragraph,
      lineHeight: 1.3,
    },
    subtitle2: {
      fontFamily: fontFamilies.paragraph,
      lineHeight: 1.3,
    },

    button: {
      fontFamily: fontFamilies.text,
      textTransform: 'none',
    },
  },

  props: {
    MuiTypography: {
      variantMapping: {
        body1: 'span',
        body2: 'span',
        subtitle1: 'p',
        subtitle2: 'p',
      },
    },
  },
})
