import React, { useContext } from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Grid, Paper } from '@mui/material'

import { utils } from '@epilogue/common'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import Text from '../../../../../../../common/components/atoms/Text'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import PaymentContext from '../../context/PaymentContext'
import CheckedList from './components/CheckedList'
import PreviewDocuments from './components/PreviewDocuments'

const beigeDivider = {
  borderBottom: `1px solid ${
    themeColors[themeColorTypes.NEUTRAL][themeColorVariants.MAIN]
  }`,
}

const alsoIncludedItems = (t) => [
  t('payment:components.DocumentsDetails.alsoIncluded.items.stepByStep'),
  t('payment:components.DocumentsDetails.alsoIncluded.items.freeUpdates'),
  t('payment:components.DocumentsDetails.alsoIncluded.items.funeralWishes'),
  t('payment:components.DocumentsDetails.alsoIncluded.items.registryCode'),
]

const DocmentsDetails = () => {
  const {
    documents,
    answerStore,
    dispatchSetGlobalErrorMessage,
    chargeInfo: { product, docTypes },
  } = useContext(PaymentContext)

  const { productId, price } = product
  const isCouplePayment = [3, 4].includes(productId)
  const hasDocsWithPreview = documents.some((d) => d.hasPreview)
  const { province, firstName, partnerEmail, partnerGender, partnerFirstName } =
    answerStore

  const { t } = useTranslation()

  return (
    <Paper elevation={0}>
      <Box
        sx={{
          py: '0.7rem',
          borderBottom: `1px solid ${
            themeColors[themeColorTypes.GREY][themeColorVariants.LIGHTER]
          }`,
        }}
      >
        <Text size="xl" align="center" sx={{ display: 'block' }}>
          {t('payment:components.DocumentsDetails.label')}
        </Text>
      </Box>

      <Box
        sx={{
          padding: {
            min: '2rem 1rem',
            xs: '2rem 1.4rem',
            md: '2rem 3rem 2.5rem',
          },
        }}
      >
        <Box
          sx={{
            pb: '0.6rem',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            ...beigeDivider,
          }}
        >
          <Text weight="bold" size="xl" color={themeColorTypes.ACCENT_1}>
            {isCouplePayment
              ? t('payment:components.DocumentsDetails.productLabel.couples')
              : t(
                  'payment:components.DocumentsDetails.productLabel.individual',
                )}
          </Text>

          <Text size="lg" weight="bold" sx={{ pl: '1.2rem' }}>
            {utils.toDollar(price, 2, i18n.language)}
          </Text>
        </Box>

        <Box sx={{ py: '1.5rem', ...beigeDivider }}>
          <Grid container spacing={{ min: 3, sm: 2 }} sx={{ display: 'flex' }}>
            <Grid item xs={12} sm={6} sx={{ pb: '0.45rem' }}>
              <Text sx={{ mb: '0.6rem', fontSize: '0.95rem' }} weight="medium">
                {firstName
                  ? t(
                      'payment:components.DocumentsDetails.userDocuments.label.case1',
                      { name: firstName },
                    )
                  : t(
                      'payment:components.DocumentsDetails.userDocuments.label.case2',
                    )}
              </Text>

              <CheckedList
                items={docTypes}
                checkType="filled"
                province={province}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Text sx={{ mb: '0.6rem', fontSize: '0.95rem' }} weight="medium">
                {t('payment:components.DocumentsDetails.alsoIncluded.label')}
              </Text>

              <CheckedList checkType="standard" items={alsoIncludedItems(t)} />
            </Grid>
          </Grid>

          {hasDocsWithPreview && (
            <Box sx={{ mt: { min: '1.8rem', sm: '1.35rem' } }}>
              <PreviewDocuments
                docTypes={docTypes}
                documents={documents}
                answerStore={answerStore}
                dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
              />
            </Box>
          )}
        </Box>

        {isCouplePayment && (
          <Box sx={{ py: '1.5rem', ...beigeDivider }}>
            <Grid
              container
              sx={{ display: 'flex' }}
              spacing={{ min: 3, sm: 2 }}
            >
              <Grid item xs={12} sm={6} sx={{ pb: '0.45rem' }}>
                <Box sx={{ mb: '0.6rem' }}>
                  <Text
                    weight="medium"
                    sx={{ mb: '0.1rem', fontSize: '0.95rem' }}
                  >
                    {partnerFirstName
                      ? t(
                          'payment:components.DocumentsDetails.partnerDocuments.label.case1',
                          { name: partnerFirstName },
                        )
                      : t(
                          'payment:components.DocumentsDetails.partnerDocuments.label.case2',
                        )}
                  </Text>
                  <Text size="xs">
                    (
                    {t(
                      'payment:components.DocumentsDetails.partnerDocuments.toBeCompleted',
                    )}
                    )
                  </Text>
                </Box>

                <CheckedList
                  partnerDocs // for cypress testing
                  items={docTypes}
                  checkType="filled"
                  province={province}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Text
                  weight="medium"
                  sx={{ mb: '0.6rem', fontSize: '0.95rem' }}
                >
                  {t('payment:components.DocumentsDetails.alsoIncluded.label')}
                </Text>

                <CheckedList
                  partnerDocs // for cypress testing
                  checkType="standard"
                  items={alsoIncludedItems(t)}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                mt: '1.9rem',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <InfoOutlinedIcon sx={{ mr: '0.5rem', fontSize: '1.25rem' }} />
              <Text size="sm" weight="medium">
                {partnerGender === 'male'
                  ? t(
                      `payment:components.DocumentsDetails.partnerDocuments.infoDescription.male.${partnerFirstName && !partnerEmail ? 'hasPartnerFirstName' : !partnerFirstName && partnerEmail ? 'hasPartnerEmail' : partnerFirstName && partnerEmail ? 'hasNameEmail' : 'fallback'}`,
                      { name: partnerFirstName, email: partnerEmail },
                    )
                  : partnerGender === 'female'
                    ? t(
                        `payment:components.DocumentsDetails.partnerDocuments.infoDescription.female.${partnerFirstName && !partnerEmail ? 'hasPartnerFirstName' : !partnerFirstName && partnerEmail ? 'hasPartnerEmail' : partnerFirstName && partnerEmail ? 'hasNameEmail' : 'fallback'}`,
                        { name: partnerFirstName, email: partnerEmail },
                      )
                    : t(
                        `payment:components.DocumentsDetails.partnerDocuments.infoDescription.other.${partnerFirstName && !partnerEmail ? 'hasPartnerFirstName' : !partnerFirstName && partnerEmail ? 'hasPartnerEmail' : partnerFirstName && partnerEmail ? 'hasNameEmail' : 'fallback'}`,
                        { name: partnerFirstName, email: partnerEmail },
                      )}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default DocmentsDetails
