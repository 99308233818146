import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CloseIcon from '@mui/icons-material/Close'
import PercentIcon from '@mui/icons-material/Percent'
import { Box, IconButton } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import NumberInput from '../../../../../../common/components/atoms/NumberInput/NumberInput'
import Text from '../../../../../../common/components/atoms/Text'
import ToggleButtons from '../../../../../../common/components/atoms/ToggleButtons'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import questionnaireSelectors from '../../../../ducks/questionnaire/questionnaireSelectors'
import CharitySearchInput from '../../../molecules/CharitySearchInput/CharitySearchInput'

const Charity = ({
  update,
  remove,
  charity,
  answerStore,
  displayValidation,
  percentExceedsMaximum,
}) => {
  const { hasChildren } = answerStore

  const isInitialMount = useRef(true)
  const [theCharity, setTheCharity] = useState({
    ...charity,
    isPercent: !!charity.isPercent,
  })

  useEffect(() => {
    /*
     * This prevents useEffect callback from running on initial mount.
     */
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      update(theCharity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theCharity])

  const { t } = useTranslation()

  return (
    <Box data-testid="charity-input" sx={{ mt: '1rem', mb: '2rem' }}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ mt: '1.1rem', mr: '0.5rem' }}>
          <IconButton
            size="small"
            aria-label="remove"
            onClick={() => remove(theCharity.id)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box sx={{ width: '100%', mt: '-0.5rem' }}>
          {/* name */}
          <Box sx={{ mb: '-0.25rem' }}>
            <CharitySearchInput
              validationMessage=""
              value={theCharity.name}
              placeholder={t(
                'customQuestion:CharitableGiftDetails.components.Charity.charityName',
              )}
              isInvalid={displayValidation && !theCharity.name}
              onCharitySearchInputChange={({ name, number, location }) => {
                setTheCharity({
                  ...theCharity,
                  name,
                  number,
                  location,
                })
              }}
            />
          </Box>

          {/* amount */}
          <Box sx={{ display: 'flex' }}>
            {hasChildren === 'yes' && (
              <ToggleButtons
                data-testid="dollar-percent-toggle"
                value={theCharity.isPercent ? 'percent' : 'dollars'}
                onChange={(value) => {
                  setTheCharity({
                    ...theCharity,
                    // clear amount when amount-type ($/%) changes
                    amount: undefined,
                    isPercent: value === 'percent',
                  })
                }}
                buttons={[
                  {
                    value: 'dollars',
                    label: <AttachMoneyIcon fontSize="inherit" />,
                  },
                  {
                    value: 'percent',
                    label: <PercentIcon fontSize="inherit" />,
                  },
                ]}
              />
            )}

            <Box
              sx={{ flex: 5, mt: theCharity.isPercent ? '1rem' : undefined }}
            >
              <NumberInput
                maxLength={
                  theCharity.isPercent
                    ? undefined
                    : i18n.language === CONSTANTS.languageTypes.FR_CA.id
                      ? 10
                      : 9
                }
                format={theCharity.isPercent ? 'percent' : 'currency'}
                validationMessage=""
                name="donation-amount"
                value={theCharity.amount}
                placeholder={
                  theCharity.isPercent ? undefined : t('common:donationAmount')
                }
                placeholder2={theCharity.isPercent ? undefined : '$'}
                isInvalid={
                  displayValidation &&
                  (!theCharity.amount ||
                    (!!theCharity.isPercent && percentExceedsMaximum))
                }
                onInputChange={(value) => {
                  setTheCharity({ ...theCharity, amount: value })
                }}
              />
            </Box>

            {theCharity.isPercent && (
              <Text
                color={
                  displayValidation && !theCharity.amount
                    ? themeColorTypes.RED
                    : themeColorTypes.GREY
                }
                colorVariant={
                  displayValidation && !theCharity.amount
                    ? themeColorVariants.MAIN
                    : themeColorVariants.DARK
                }
                sx={{
                  flex: 4,
                  ml: '0.5rem',
                  display: 'flex',
                  alignItems: 'flex-end',
                  minWidth: 'fit-content',
                }}
              >
                {t('common:percentageOfEstate')}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Charity.propTypes = {
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  displayValidation: PropTypes.bool.isRequired,
  charity: PropTypes.shape({
    name: PropTypes.string,
    amount: PropTypes.string,
    isPercent: PropTypes.bool,
  }).isRequired,
  answerStore: PropTypes.shape({
    hasChildren: PropTypes.string,
  }).isRequired,
  percentExceedsMaximum: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
})

export default connect(mapStateToProps)(Charity)
