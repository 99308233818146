import _difference from 'lodash/difference'
import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'

interface ObjectWithLanguageTypes {
  [key: string]: string
}

export default (obj: ObjectWithLanguageTypes) =>
  obj[CONSTANTS.languageTypes.EN_CA.id]
    ? /*
       * If the object contains all supported language types,
       * return the object as is.
       */
      _isEmpty(
        _difference(
          Object.values(CONSTANTS.languageTypes).map(({ id }) => id),
          Object.keys(obj),
        ),
      )
      ? obj
      : /*
         * If the object is missing any supported language types,
         * fill them in with the en-CA value as a fallback.
         */
        {
          ...obj,
          ..._difference(
            Object.values(CONSTANTS.languageTypes).map(({ id }) => id),
            Object.keys(obj),
          ).reduce(
            (acc, key) => ({
              ...acc,
              [key]: obj[CONSTANTS.languageTypes.EN_CA.id],
            }),
            {},
          ),
        }
    : // If the object is missing the en-CA value, return the object as is.
      obj
