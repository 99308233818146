import { ageOfMajority } from '../../../../../../common/utils/provinces'

export default ({ province }) => {
  const theAgeOfMajority = ageOfMajority(province).toString()

  const theOptions = [
    // the first option should always be the province's age of majority
    {
      value: theAgeOfMajority,
      label: {
        tKey: 'customQuestion:InheritanceAge.utils.inheritanceAgeOptions.label',
        options: { age: theAgeOfMajority },
      },
    },
    {
      value: '21',
      label: {
        tKey: 'customQuestion:InheritanceAge.utils.inheritanceAgeOptions.label',
        options: { age: '21' },
      },
    },
    {
      value: '25',
      label: {
        tKey: 'customQuestion:InheritanceAge.utils.inheritanceAgeOptions.label',
        options: { age: '25' },
      },
    },
    {
      value: '30',
      label: {
        tKey: 'customQuestion:InheritanceAge.utils.inheritanceAgeOptions.label',
        options: { age: '30' },
      },
    },
    {
      value: '35',
      label: {
        tKey: 'customQuestion:InheritanceAge.utils.inheritanceAgeOptions.label',
        options: { age: '35' },
      },
    },
    {
      value: '40',
      label: {
        tKey: 'customQuestion:InheritanceAge.utils.inheritanceAgeOptions.label',
        options: { age: '40' },
      },
    },
  ]

  return theOptions
}
