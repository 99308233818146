export default (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return state.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        return item
      })

    case 'ADD':
      return [...state, action.payload]

    case 'REMOVE':
      return state.filter((item) => item.id !== action.payload)

    default:
      return state
  }
}
