export default (str) => {
  if (typeof str !== 'string' || !str || !/\S/.test(str)) {
    return ''
  }

  try {
    return str
      .trim()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-')
  } catch {
    return str
  }
}
