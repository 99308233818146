import React from 'react'

import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _startCase from 'lodash/startCase'

import { CONSTANTS, utils } from '@epilogue/common'

import { investmentAccountOptions } from '../../../../scenes/Questionnaire/components/Question/InvestmentAccounts/utils/getInvestmentAccountsOptions'
import toCapitalCase from '../../../formatting/toCapitalCase/toCapitalCase'

export const formatChildren = (arr) => {
  if (_isEmpty(arr)) {
    return ''
  }

  return arr.map((item) => (
    <div style={{ marginBottom: '7px' }} key={item.id}>
      {item.name}
    </div>
  ))
}

export const formatGifts = (arr) => {
  if (_isEmpty(arr)) {
    return ''
  }

  return arr.map((item) => (
    <div style={{ marginBottom: '7px' }} key={item.id}>
      {item.description} ({item.recipient})
    </div>
  ))
}

export const formatCharitableGiftDetails = (arr, languageType) => {
  if (_isEmpty(arr)) {
    return ''
  }

  return arr.map((item) => {
    const { isPercent, name, amount, id } = item

    const formattedName = toCapitalCase(name)

    const formattedAmount = isPercent
      ? `${amount}%`
      : utils.toDollar(amount, 0, languageType)

    return (
      <div style={{ marginBottom: '7px' }} key={id}>
        {formattedName} ({formattedAmount})
      </div>
    )
  })
}

export const formatPets = (arr) => {
  if (_isEmpty(arr)) {
    return ''
  }

  return arr.map((item) => (
    <div style={{ marginBottom: '7px' }} key={item.id}>
      {item.name} ({item.type})
    </div>
  ))
}

export const formatInvestmentAccounts = (arr, t) => {
  if (_isEmpty(arr)) return 'N/A'

  return arr.map((value) => {
    const translatedSummaryLabel = t(
      _get(
        investmentAccountOptions.find((option) => option.value === value)
          ?.reviewLabel,
        'tKey',
        _startCase(value),
      ),
    )

    return (
      <div style={{ marginBottom: '7px' }} key={value}>
        {translatedSummaryLabel}
      </div>
    )
  })
}

export const formatRemoteDistribution = (arr) => {
  if (_isEmpty(arr)) {
    return ''
  }

  return arr.map((item) => (
    <div style={{ marginBottom: '7px' }} key={item.id}>
      {item.name} ({item.percentage}%)
    </div>
  ))
}

export const formatPredeceased = (str, t) => {
  const arr = str.split(',')

  if (_isEmpty(arr)) {
    return str
  }

  return arr.map((item, index) => {
    let translatedItem = item

    if (item.includes('split among children')) {
      translatedItem = item.replace(
        'split among children',
        t('customQuestion:Predeceased.splitAmongChildren'),
      )
    }

    if (item.includes('split among beneficiaries')) {
      translatedItem = item.replace(
        'split among beneficiaries',
        t('customQuestion:Predeceased.splitAmongBeneficiaries'),
      )
    }

    return (
      // eslint-disable-next-line react/no-array-index-key
      <div style={{ marginBottom: '8px' }} key={index}>
        {translatedItem}
      </div>
    )
  })
}

export const formatPredeceasedBackup = (val, t) => {
  let translatedValue = val

  if (val.startsWith('Split among ') && val.endsWith("'s children")) {
    const nameRegex = /(?:Split among\s)([a-zA-Z\s]+)/
    const [, name] = val.match(nameRegex)

    translatedValue = t(
      'customQuestion:PredeceasedBackup.formatting.splitAmongChildren',
      {
        name,
      },
    )
  }

  if (val === 'None') {
    translatedValue = t('customQuestion:PredeceasedBackup.formatting.none')
  }

  return translatedValue
}

export const formatStepChildrenDistribution = (val, t) => {
  const splitStepChildren = val.split(', ')

  return splitStepChildren
    .map((val) => {
      if (val.includes(' will receive an equal share')) {
        return t(
          'customQuestion:StepChildrenDistribution.formatting.willReceive',
          // isolates step child's name to be used in the translation
          { name: val.split(' will receive an equal share')[0] },
        )
      }

      if (val.includes(' will not be included')) {
        return t(
          'customQuestion:StepChildrenDistribution.formatting.willNotReceive',
          // isolates step child's name to be used in the translation
          { name: val.split(' will not be included')[0] },
        )
      }

      return val
    })
    .join(', ')
}

export const formatRange = (
  field,
  val,
  languageType = CONSTANTS.languageTypes.EN_CA.id,
) => {
  switch (field.format) {
    case '$':
      return utils.toDollar(val, 0, languageType)

    default:
      return val
  }
}

export const formatCharityPartnerGiftAmount = (
  val,
  answerStore,
  languageType = CONSTANTS.languageTypes.EN_CA.id,
) =>
  answerStore?.charityPartnerGiftType === '%'
    ? `${val}%`
    : utils.toDollar(val, 0, languageType)

export const arrayToList = (fieldOptions, val) => {
  if (!_isArray(val) || _isEmpty(val)) {
    return 'N/A'
  }

  const validOptions = fieldOptions.filter((opt) => val.includes(opt.value))

  return _isEmpty(validOptions)
    ? ''
    : validOptions.map((opt) => (
        // eslint-disable-next-line react/jsx-indent
        <div style={{ marginBottom: '7px' }} key={opt.value}>
          {opt.label}
        </div>
      ))
}
