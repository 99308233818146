import addOnsName from './addOnsName'

export default {
  FETCH_ADD_ONS: `${addOnsName}/FETCH_ADD_ONS`,
  FETCH_ADD_ONS_WORKING: `${addOnsName}/FETCH_ADD_ONS_WORKING`,
  SET_ADD_ONS: `${addOnsName}/SET_ADD_ONS`,
  ADD_ADD_ON_TO_CART: `${addOnsName}/ADD_ADD_ON_TO_CART`,
  SET_ADD_ONS_TO_CART: `${addOnsName}/SET_ADD_ONS_TO_CART`,
  REMOVE_ADD_ON_FROM_CART: `${addOnsName}/REMOVE_ADD_ON_FROM_CART`,
  CLEAR_ADD_ON_CART: `${addOnsName}/CLEAR_ADD_ON_CART`,
}
