import _get from 'lodash/get'
import _takeRight from 'lodash/takeRight'

import navigationName from './navigationName'

export default {
  getQuestionStack: (state) => _get(state[navigationName], 'questionStack'),
  getCurrentQuestion: (state) =>
    _takeRight(state[navigationName].questionStack)[0],
  getCurrentSection: (state) =>
    _get(_takeRight(state[navigationName].questionStack)[0], 'section'),
  getSectionProgress: (state) => _get(state[navigationName], 'sectionProgress'),
  getQuestionnaireProgress: (state) =>
    _get(state[navigationName], 'questionnaireProgress'),
  getPreventNext: (state) => _get(state[navigationName], 'preventNext'),
  getPreventPrevious: (state) => _get(state[navigationName], 'preventPrevious'),
  getDashboardRedirect: (state) =>
    _get(state[navigationName], 'dashboardRedirect'),
  getNavDirection: (state) => _get(state[navigationName], 'navDirection'),
  getPreventAnimation: (state) =>
    _get(state[navigationName], 'preventAnimation'),
  getWorking: (state) => _get(state[navigationName], 'working'),
  getNextUnansweredQuestion: (state) =>
    _get(state[navigationName], 'nextUnansweredQuestion'),
  getEditQuestionnaireFromDashboard: (state) =>
    _get(state[navigationName], 'editQuestionnaireFromDashboard'),
  getInterceptModal: (state) => _get(state[navigationName], 'interceptModal'),
}
