import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _upperFirst from 'lodash/upperFirst'

import { CONSTANTS } from '@epilogue/common'
import cn from 'classnames'
import { Button, Heading, Meter, Paragraph, Text } from 'grommet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ReactComponent as Oranges } from '../../../../../common/assets/images/oranges.svg'
import EverythingButton from '../../../../../common/components/molecules/EverythingButton'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import amplitude from '../../../../../common/lib/amplitude'
import sessionStore from '../../../../../common/lib/sessionStore'
import tw from '../../../../../common/styles/tw'
import data from '../../../../Questionnaire/data'
import navigationActions from '../../../../Questionnaire/ducks/navigation/navigationActions'
import navigationSelectors from '../../../../Questionnaire/ducks/navigation/navigationSelectors'
import questionnaireSelectors from '../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import { triggeredQuestions } from '../../../../Questionnaire/engine'
import dashboardSelectors from '../../../ducks/dashboard/dashboardSelectors'
import documentsSelectors from '../../../ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../ducks/payments/paymentsSelectors'
import hasCoreDocument from '../../../utils/hasCoreDocument'
import PartnerModal from './components/PartnerModal'
import PartnerOnOriginalAccountModal from './components/PartnerOnOriginalAccountModal'
import QuestionnaireChangedModal from './components/QuestionnaireChangedModal'
import ReferralLink from './components/ReferralLink'
import SectionCards from './components/SectionCards'
import dynamicGreeting from './utils/dynamicGreeting'
import isPartnerFirstVisit from './utils/isPartnerFirstVisit'
import sectionGrouping from './utils/sectionGrouping'

const StyledHeading = styled(Heading)`
  font-size: 32px;
  @media (max-width: 700px) {
    font-size: 30px;
  }
`
const StyledButton = styled(Button)`
  height: ${tw.spacing['48']};
  border: ${tw.borderWidth['2']} ${tw.colors['accent-2-lighter']} solid;
  border-radius: ${tw.borderRadius.lg};
  color: ${tw.colors.brand};
  width: 100%;
  &:hover {
    box-shadow: none;
    border: ${tw.borderWidth['2']} ${tw.colors['accent-2-dark']} solid;
  }
`

const Dashboard = ({
  abTest,
  referrer,
  answerStore,
  charityPartner,
  questionnaireValid,
  dispatchToQuestion,
  answerStoreUpdatedSinceGenerate,
  nextUnansweredQuestion = {},
  questionnaireProgress,
  willQuestionnaireStarted,
  isPartner,
  documents,
  accountCreated,
  payments,
}) => {
  const { t } = useTranslation()

  // if partner is visiting their dashboard for the first time
  const partnerFirstVisit = isPartnerFirstVisit()

  const { firstName } = answerStore

  const theTriggeredQuestions = triggeredQuestions({
    abTest,
    referrer,
    payments,
    isPartner,
    documents,
    answerStore,
    theData: data,
    accountCreated,
    charityPartner,
  })

  const sectionGroups = sectionGrouping(
    data,
    theTriggeredQuestions,
    answerStore,
  )

  const hasPayments = !_isEmpty(payments)
  const hasDocuments = !_isEmpty(documents)
  const userHasCoreDocument = hasCoreDocument(documents)

  const partnerOnOriginalAccount =
    partnerFirstVisit && !isPartner && hasPayments

  const [showQuestionnaireChangedModal, setShowQuestionnaireChangedModal] =
    useState(false)

  useEffect(() => {
    /*
      We explicity check the variables here as opposed to truthiness or falsy-ness
      because we need these values to be explicity set to false before we show the 
      modal. A value of 'undefined' would cause problems otherwise.

      This is also in a use effect as it the values for hasPayments, questionnaireValid,
      and answerStoreUpdatedSinceGenerate sometimes return undefined on initial load, 
      and then return their proper value
    */
    setShowQuestionnaireChangedModal(
      sessionStore.get(
        CONSTANTS.sessionStorageKeys.DISMISSED_QUESTIONS_CHANGED_MODAL,
      ) !== true &&
        answerStoreUpdatedSinceGenerate === false &&
        questionnaireValid === false &&
        userHasCoreDocument &&
        hasPayments,
    )
  }, [
    hasPayments,
    questionnaireValid,
    userHasCoreDocument,
    answerStoreUpdatedSinceGenerate,
  ])

  const dismissQuestionnaireChangedModal = () => {
    setShowQuestionnaireChangedModal(false)

    sessionStore.set(
      CONSTANTS.sessionStorageKeys.DISMISSED_QUESTIONS_CHANGED_MODAL,
      true,
    )
  }

  useEffect(() => {
    amplitude.sendEvent('ViewedWillDashboard')
  }, [])

  const navigate = useNavigate()

  const translatedDynamicGreeting = t(
    _get(dynamicGreeting(), 'tKey', dynamicGreeting()),
  )

  return (
    <>
      {partnerOnOriginalAccount && <PartnerOnOriginalAccountModal />}

      {partnerFirstVisit && !partnerOnOriginalAccount && (
        <PartnerModal
          answerStore={answerStore}
          nextUnansweredQuestion={nextUnansweredQuestion}
          dispatchToQuestion={dispatchToQuestion}
        />
      )}

      <QuestionnaireChangedModal
        show={showQuestionnaireChangedModal}
        onClose={dismissQuestionnaireChangedModal}
        onPrimaryButtonClick={() => {
          amplitude.sendEvent('ClickedQuestionnaireChangedModalButton', {
            state: 'To Questionnaire',
          })
          dismissQuestionnaireChangedModal()
          dispatchToQuestion(data[0]?.questions[0])
          navigate('/questionnaire')
        }}
        onSecondaryButtonClick={() => {
          amplitude.sendEvent('ClickedQuestionnaireChangedModalButton', {
            state: 'To Summary',
          })
          dismissQuestionnaireChangedModal()
          navigate('/dashboard/summary')
        }}
      />

      <div className="flex flex-col justify-between max-w-6xl m-auto flex-wrap md:flex-row">
        {/* LEFT COLUMN */}
        <div
          className={cn(
            { 'md:w-7/12 md:pr-3 lg:pr-8': willQuestionnaireStarted },
            'w-full flex flex-col items-center p-0',
          )}
        >
          <div className="pb-1.5 flex items-center text-center">
            <StyledHeading data-testid="dashboard-greeting">
              {firstName
                ? `${translatedDynamicGreeting}, ${_upperFirst(firstName)}`
                : `${translatedDynamicGreeting}.`}
            </StyledHeading>
          </div>
          <div className="max-w-sm text-center">
            <Paragraph size="smedium">
              {!willQuestionnaireStarted
                ? t('dashboard:greetingSubLabel.case1')
                : !questionnaireValid
                  ? t('dashboard:greetingSubLabel.case2')
                  : !hasDocuments ||
                      (hasDocuments && answerStoreUpdatedSinceGenerate)
                    ? t('dashboard:greetingSubLabel.case3')
                    : !hasPayments
                      ? t('dashboard:greetingSubLabel.case4')
                      : t('dashboard:greetingSubLabel.case5')}
            </Paragraph>
          </div>
          {willQuestionnaireStarted && (
            <div className="flex flex-col w-full px-4 mt-6 sm:px-8 lg:px-16">
              <div className="flex justify-between w-full mb-1">
                <Text size="xsmall">{t('common:progress')}</Text>
                <Text size="xsmall">
                  {questionnaireValid
                    ? '100'
                    : questionnaireProgress === 100
                      ? questionnaireProgress - 1
                      : questionnaireProgress}
                  %
                </Text>
              </div>

              <Meter
                type="bar"
                background="grey-2"
                size="full"
                round
                max={100}
                thickness="small"
                values={[{ value: questionnaireProgress }]}
              />
            </div>
          )}
          <div className={cn(willQuestionnaireStarted ? 'my-10' : 'mt-6')}>
            <EverythingButton />
          </div>
          {willQuestionnaireStarted ? (
            <div
              className={cn(
                'flex flex-col w-full justify-center items-center',
                'sm:flex-row',
              )}
            >
              <div
                className={cn(
                  'mr-0 mb-2 w-full',
                  'sm:w-1/2 sm:mr-1 sm:mb-0 sm:max-w-xs',
                )}
              >
                <Link to="/dashboard/documents">
                  <StyledButton
                    data-testid="big-dashboard-documents-button"
                    primary
                    color="white"
                    label={
                      <>
                        <div className="mb-2">
                          <Heading level={4} size="smedium">
                            {t('dashboard:bigNavButtons.button1.label')}
                          </Heading>
                        </div>

                        <div className="mt-2">
                          <Paragraph size="smedium" fill>
                            {t('dashboard:bigNavButtons.button1.subLabel')}
                          </Paragraph>
                        </div>
                      </>
                    }
                  />
                </Link>
              </div>

              <div
                className={cn(
                  'mr-0 mb-2 w-full',
                  'sm:w-1/2 sm:mr-1 sm:mb-0 sm:max-w-xs',
                )}
              >
                <Link to="/dashboard/summary">
                  <StyledButton
                    data-testid="big-dashboard-summary-button"
                    primary
                    color="white"
                    label={
                      <>
                        <div className="mb-2">
                          <Heading level={4} size="smedium">
                            {t('dashboard:bigNavButtons.button2.label')}
                          </Heading>
                        </div>

                        <div className="mt-2">
                          <Paragraph size="smedium" fill>
                            {t('dashboard:bigNavButtons.button2.subLabel')}
                          </Paragraph>
                        </div>
                      </>
                    }
                  />
                </Link>
              </div>
            </div>
          ) : (
            <div className={cn('w-full mt-18 px-4', 'sm:w-48')}>
              <Oranges />
            </div>
          )}

          {willQuestionnaireStarted && <ReferralLink />}
        </div>

        {/* RIGHT COLUMN */}
        {willQuestionnaireStarted && (
          <div className="w-full mt-8 md:mt-0 md:w-5/12">
            <SectionCards sectionGroups={sectionGroups} />

            <div className="mt-5 flex justify-center">
              <EverythingButton />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

Dashboard.propTypes = {
  answerStore: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
  abTest: PropTypes.string.isRequired,
  referrer: PropTypes.shape({}).isRequired,
  dispatchToQuestion: PropTypes.func.isRequired,
  charityPartner: PropTypes.shape({}).isRequired,
  questionnaireValid: PropTypes.bool,
  nextUnansweredQuestion: PropTypes.shape({}),
  questionnaireProgress: PropTypes.number,
  isPartner: PropTypes.bool.isRequired,
  accountCreated: PropTypes.bool.isRequired,
  willQuestionnaireStarted: PropTypes.bool.isRequired,
  answerStoreUpdatedSinceGenerate: PropTypes.bool.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = (state) => ({
  abTest: questionnaireSelectors.getAbTest(state.questionnaire),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  accountCreated: questionnaireSelectors.getAccountCreated(state.questionnaire),
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
  nextUnansweredQuestion: navigationSelectors.getNextUnansweredQuestion(
    state.questionnaire,
  ),
  questionnaireProgress: navigationSelectors.getQuestionnaireProgress(
    state.questionnaire,
  ),
  charityPartner: commonSelectors.getCharityPartner(state),
  isPartner: dashboardSelectors.getIsPartner(state.dashboard),
  documents: documentsSelectors.getDocuments(state.dashboard),
  payments: paymentsSelectors.getPayments(state.dashboard),
  answerStoreUpdatedSinceGenerate:
    questionnaireSelectors.getAnswerStoreUpdatedSinceGenerate(
      state.questionnaire,
    ),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
  willQuestionnaireStarted: questionnaireSelectors.getQuestionnaireStarted(
    state.questionnaire,
  ),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchToQuestion: (question) =>
    dispatch(navigationActions.toQuestion({ question, fromDashboard: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
