import { CONSTANTS } from '@epilogue/common'

export const PROVINCES = [
  {
    value: CONSTANTS.provinces.ALBERTA,
    label: CONSTANTS.provinces.ALBERTA,
    tKey: 'common:province.ALBERTA',
  },
  {
    value: CONSTANTS.provinces.BRITISH_COLUMBIA,
    label: CONSTANTS.provinces.BRITISH_COLUMBIA,
    tKey: 'common:province.BRITISH_COLUMBIA',
  },
  {
    value: CONSTANTS.provinces.MANITOBA,
    label: CONSTANTS.provinces.MANITOBA,
    tKey: 'common:province.MANITOBA',
  },
  {
    value: CONSTANTS.provinces.NEW_BRUNSWICK,
    label: CONSTANTS.provinces.NEW_BRUNSWICK,
    tKey: 'common:province.NEW_BRUNSWICK',
  },
  {
    value: CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR,
    label: CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR,
    tKey: 'common:province.NEWFOUNDLAND_AND_LABRADOR',
  },
  {
    value: CONSTANTS.provinces.NORTHWEST_TERRITORIES,
    label: CONSTANTS.provinces.NORTHWEST_TERRITORIES,
    tKey: 'common:province.NORTHWEST_TERRITORIES',
  },
  {
    value: CONSTANTS.provinces.NOVA_SCOTIA,
    label: CONSTANTS.provinces.NOVA_SCOTIA,
    tKey: 'common:province.NOVA_SCOTIA',
  },
  {
    value: CONSTANTS.provinces.NUNAVUT,
    label: CONSTANTS.provinces.NUNAVUT,
    tKey: 'common:province.NUNAVUT',
  },
  {
    value: CONSTANTS.provinces.ONTARIO,
    label: CONSTANTS.provinces.ONTARIO,
    tKey: 'common:province.ONTARIO',
  },

  {
    value: CONSTANTS.provinces.PRINCE_EDWARD_ISLAND,
    label: CONSTANTS.provinces.PRINCE_EDWARD_ISLAND,
    tKey: 'common:province.PRINCE_EDWARD_ISLAND',
  },
  {
    value: CONSTANTS.provinces.QUEBEC,
    label: CONSTANTS.provinces.QUEBEC,
    tKey: 'common:province.QUEBEC',
  },
  {
    value: CONSTANTS.provinces.SASKATCHEWAN,
    label: CONSTANTS.provinces.SASKATCHEWAN,
    tKey: 'common:province.SASKATCHEWAN',
  },
  {
    value: CONSTANTS.provinces.YUKON,
    label: CONSTANTS.provinces.YUKON,
    tKey: 'common:province.YUKON',
  },
]

export const PROVINCE_CODES = [
  { value: 'AB', label: 'AB', fullLabel: CONSTANTS.provinces.ALBERTA },
  { value: 'BC', label: 'BC', fullLabel: CONSTANTS.provinces.BRITISH_COLUMBIA },
  { value: 'MB', label: 'MB', fullLabel: CONSTANTS.provinces.MANITOBA },
  { value: 'NB', label: 'NB', fullLabel: CONSTANTS.provinces.NEW_BRUNSWICK },
  {
    value: 'NL',
    label: 'NL',
    fullLabel: CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR,
  },
  {
    value: 'NT',
    label: 'NT',
    fullLabel: CONSTANTS.provinces.NORTHWEST_TERRITORIES,
  },
  { value: 'NS', label: 'NS', fullLabel: CONSTANTS.provinces.NOVA_SCOTIA },
  { value: 'NU', label: 'NU', fullLabel: CONSTANTS.provinces.NUNAVUT },
  { value: 'ON', label: 'ON', fullLabel: CONSTANTS.provinces.ONTARIO },
  {
    value: 'PE',
    label: 'PE',
    fullLabel: CONSTANTS.provinces.PRINCE_EDWARD_ISLAND,
  },
  { value: 'QC', label: 'QC', fullLabel: CONSTANTS.provinces.QUEBEC },
  { value: 'SK', label: 'SK', fullLabel: CONSTANTS.provinces.SASKATCHEWAN },
  { value: 'YT', label: 'YT', fullLabel: CONSTANTS.provinces.YUKON },
]

export const AGE_OF_MAJORITY = {
  [CONSTANTS.provinces.ALBERTA]: 18,
  [CONSTANTS.provinces.BRITISH_COLUMBIA]: 19,
  [CONSTANTS.provinces.MANITOBA]: 18,
  [CONSTANTS.provinces.NEW_BRUNSWICK]: 19,
  [CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR]: 19,
  [CONSTANTS.provinces.NORTHWEST_TERRITORIES]: 19,
  [CONSTANTS.provinces.NOVA_SCOTIA]: 19,
  [CONSTANTS.provinces.NUNAVUT]: 19,
  [CONSTANTS.provinces.ONTARIO]: 18,
  [CONSTANTS.provinces.PRINCE_EDWARD_ISLAND]: 18,
  [CONSTANTS.provinces.QUEBEC]: 18,
  [CONSTANTS.provinces.SASKATCHEWAN]: 18,
  [CONSTANTS.provinces.YUKON]: 19,
}

// TAG: province-specific
export const SUPPORTED_PROVINCES = [
  CONSTANTS.provinces.ALBERTA,
  CONSTANTS.provinces.BRITISH_COLUMBIA,
  CONSTANTS.provinces.MANITOBA,
  CONSTANTS.provinces.NEW_BRUNSWICK,
  CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR,
  CONSTANTS.provinces.NOVA_SCOTIA,
  CONSTANTS.provinces.ONTARIO,
  CONSTANTS.provinces.PRINCE_EDWARD_ISLAND,
  CONSTANTS.provinces.SASKATCHEWAN,
]

export const supportedProvinces = PROVINCES.filter((p) =>
  SUPPORTED_PROVINCES.includes(p.value),
)

export const unsupportedProvinces = PROVINCES.filter(
  (p) => !SUPPORTED_PROVINCES.includes(p.value),
)

export const ageOfMajority = (province) =>
  AGE_OF_MAJORITY[province] || Math.max(...Object.values(AGE_OF_MAJORITY))

// TAG: province-specific
export const nonHensonTrustProvinces = []

export const witnessingProvinces = [CONSTANTS.provinces.ONTARIO]
